import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../../../../../services/api";
import { useHistory } from "react-router-dom";
//@ts-ignore
import classnames from "classnames";
import { Empresa } from "../../../../../../entities/Common";
import EmpresaContext from "../../../../../../contexts/Empresa";
import { ApiAccess } from "../../../../../../entities/Security";
// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// @ts-ignore
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "../../../../../../components/Headers/SimpleHeader";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Row,
  PopoverBody,
  UncontrolledPopover,
  Button,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
  ListGroup,
  ListGroupItem,
  NavItem,
  NavLink,
} from "reactstrap";


interface FBAdsProps {
  fbStatus: boolean;
  connectedPages: any;
}

const FacebookAds: React.FC<FBAdsProps> = ({ fbStatus, connectedPages }) => {
  const [showEmailForm, setShowEmalForm] = useState(false);
  const [apiAccess, setApiAccess] = useState<ApiAccess>({} as ApiAccess);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [showSmsForm, setShowSmsForm] = useState(false);
  const [textCopiar, setTextCopiar] = useState("Copiar token");
  const notificationAlert = useRef<any>();
  // const [fbStatus, setFbStatus] = useState<any>(false);
  const [modal, setModal] = useState<boolean>(false);
  // const [connectedPages, setConnectedPages] = useState([]);
  const [tabActive, setTabActive] = useState("emails");
  const [empresa, setEmpresa] = useState({});
  const history = useHistory()

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (empresaSelecionada && empresaSelecionada.id) loadApiAccess();
  }, []);

  useEffect(() => {
    console.log({
      TOKEN_FB: new URLSearchParams(window.location.search).get("code"),
    });
  }, []);

  function onEmpresaChange(empresa: any) {
    setEmpresa(empresa ? empresa : {});
  }

  async function onFacebookResponse({ accessToken }: any) {
    console.log({ accessToken });
    if (!accessToken) {
      notify("danger", "Não conseguimos concluir a operação");
      return;
    }
    try {
      await api.patch(
        `common/empresas/${empresaSelecionada?.id}/updatefacebooktoken`,
        {
          token: accessToken,
        }
      );
      await getMyPages(accessToken);
      notify("success", "Operação realizada");
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível concluir a operação.");
    }
  }

  async function getMyPages(accessToken: any) {
    try {
      const data = await fetch(
        `https://graph.facebook.com/me/accounts?access_token=${accessToken}&fields=access_token,id,%20name&limit=10000`
      ).then((e) => e.json().then((r) => r.data));
      for (const page of data) {
        fetch(
          `https://graph.facebook.com/${page.id}/subscribed_apps?subscribed_fields=leadgen&access_token=${page.access_token}`,
          {
            method: "POST",
          }
        )
          .then((e) => console.log(`listen ${page.name}`))
          .catch(console.error);
      }
    } catch (error) {
      console.log(error);
    }
  }
  function hanleNewEmail(e: React.MouseEvent) {
    e.preventDefault();
    setShowEmalForm(true);
  }

  function handleNewSms(e: React.MouseEvent) {
    e.preventDefault();
    setShowSmsForm(true);
  }

  async function loadApiAccess() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/api-access`
      );
      setApiAccess(response?.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar empresa");
    }
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  const CustomNavItem = ({ children, disabled, name }: any) => {
    return (
      <>
        <NavItem>
          <NavLink
            href="#"
            role="tab"
            className={classnames("mb-sm-3 mb-md-0 mt-2", {
              active: tabActive === name,
            })}
            onClick={() => setTabActive(name)}
            disabled={disabled}
          >
            {children}
          </NavLink>
        </NavItem>
      </>
    );
  };

  const ButtonHelp = () => (
    <>
      <Button
        color="link"
        id="tooltip-99882211"
        outline
        size="sm"
        onClick={() => window.open('https://ajuda.playnee.com/artigos/como-integrar-minha-conta-do-facebook-ads-ao-playnee', '_blank')}
        type="button"
      >
        ?
      </Button>
    </>
  );

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Row>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "end",
          }}
        >
          <h2>Facebook Ads</h2>
        <ButtonHelp />
        </div>
      </Row>
      <Row className="pt-5 mb-5">
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <FacebookLogin
            appId="735161440956284"
            autoLoad={false}
            icon={<i className="fab fa-facebook mr-4"></i>}
            size="small"
            version="3.1"
            onFailure={(e: any) => {
              console.log({ onFailure: e }, alert(`Falhou`));
            }}
            textButton="Login com Facebook"
            // whatsapp perm
            scope="pages_show_list,instagram_basic,leads_retrieval,pages_read_engagement,ads_management,pages_manage_ads,pages_manage_metadata,business_management"
            // whatsapp_business_management,whatsapp_business_messaging
            // prod branch
            // scope="pages_show_list,instagram_basic,pages_read_engagement,ads_management,pages_manage_ads"
            // dev branch
            // scope="ads_management,leads_retrieval, pages_show_list,pages_read_engagement,pages_manage_ads"
            fields="public_profile"
            render={(renderProps: any) => (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Button
                  size="md"
                  className="btn-icon"
                  color="facebook"
                  type="button"
                  onClick={renderProps.onClick}
                >
                  <span className="btn-inner--icon">
                    <i className="fab fa-facebook" />
                  </span>
                  <span className="btn-inner--text">
                    Conceder acesso ao Facebook
                  </span>
                </Button>
                <Button
                  color="secondary"
                  id="tooltip-998822"
                  outline
                  size="sm"
                  type="button"
                >
                  ?
                </Button>
                <UncontrolledPopover placement="top" target="tooltip-998822">
                  <PopoverBody>
                    Precisamos que você autorize o Playnee a acessar sua conta
                    do Facebook.
                    <br />
                    Isso permite que nossas integrações com a plataforma
                    funcionem sem problemas.
                    <br />
                    Se deseja usar a <b>captação de oportunidades</b> e/ou{" "}
                    <b>publicações automáticas</b> no Facebook, você precisa
                    conceder este acesso.
                  </PopoverBody>
                </UncontrolledPopover>
                <div className="d-flex align-items-center">
                  <Badge color={fbStatus ? "success" : "danger"}>
                    {fbStatus ? "Conectado" : "Não conectado"}
                  </Badge>
                </div>
                <div className="ml-4">
                  {fbStatus && (
                    <Button color="primary" onClick={toggle}>
                      Páginas Conectadas
                    </Button>
                  )}
                  <Modal toggle={toggle} isOpen={modal}>
                    <ModalHeader toggle={toggle}>
                      Páginas Conectadas
                    </ModalHeader>
                    <ModalBody>
                      <ListGroup
                        style={{
                          height: "60vh",
                          overflowY: "scroll",
                        }}
                      >
                        {connectedPages.map((element) => (
                          <ListGroupItem key={element}>{element}</ListGroupItem>
                        ))}
                      </ListGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={toggle}>
                        Voltar
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            )}
            callback={(response: any) => {
              console.log({ facebookLoginResponse: response });
              onFacebookResponse(response);
            }}
          />
        </div>
      </Row>
    </>
  );
};

export default FacebookAds;
