import React, { useState, useEffect, useRef, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";

import CurrencyInput from 'react-currency-input';
import NotificationAlert from "react-notification-alert";
import Select2 from "react-select2-wrapper";
import {
    Spinner,
    Button,
    Row,
    InputGroupAddon,
    InputGroup,
    InputGroupText,
    Col,
    Input,
    FormGroup,
} from "reactstrap";

export default ({ oportunidadeProduto = {}, close, onSuccess, produtosAdicionados = [], oportunidadeId, ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [tipoProduto, setTipoProduto] = useState(null)
    const [produto, setProduto] = useState(null)
    const [quantidade, setQuantidade] = useState(1)
    const [preco, setPreco] = useState(null)

    const [tiposProdutos, setTiposProdutos] = useState([])
    const [produtos, setProdutos] = useState([])
    const [erros, setErros] = useState({})
    const [loading, setLoading] = useState(false)
    const notificationAlert = useRef()

    useEffect(() => {
        if (tiposProdutos.length === 0) loadTiposProdutos()
    }, [])

    useEffect(() => {
        setQuantidade(oportunidadeProduto.quantidade || 1)
        setPreco(oportunidadeProduto.preco)
        setTipoProduto(oportunidadeProduto.produto?.tipo_produto_id)
        setProduto(oportunidadeProduto.produto_id)
    }, [oportunidadeProduto])

    useEffect(() => {
        if (tipoProduto) loadProdutos(tipoProduto)
    }, [tipoProduto])

    useEffect(() => {
        if (produto && produtos) {
            const produtoSelecionado = produtos.find(item => item.id == produto)
            if (produtoSelecionado) {
                setPreco(produtoSelecionado.preco)
            } else {
                setPreco(0)
            }
        } else {
            setPreco(0)
        }
    }, [produto, produtos])

    const notify = (type, msg) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            autoDismiss: 3
        };
        if (notificationAlert) notificationAlert.current.notificationAlert(options);
    };


    async function loadTiposProdutos() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/tipos-produtos?situacao=A`)
            setTiposProdutos(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    async function loadProdutos(tipoProduto) {
        try {
            setProdutos([])
            let array = []
            if (!oportunidadeProduto.produto) {
                const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/produtos?limit=1000&situacao=A&tipoProduto=${tipoProduto}`)
                const produtos = response.data.data
                //Adicionando produtos filtrados pelos que já existem na tela
                array = produtos.filter(item => !produtosAdicionados.find(p => p.id == item.id))
            } else {
                const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/produtos/${oportunidadeProduto.produto_id}`)
                const produto = response.data
                produto.preco = oportunidadeProduto.preco
                array = [produto]
            }
            setProdutos(array)
        } catch (error) {
            console.log(error)
        }
    }

    async function insert() {
        try {
            await api.post(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/produtos`, {
                quantidade,
                produto_id: produto,
                preco,
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível salvar produto')
            if (error.response) updateErros(error.response.data)
        }
    }


    async function update() {
        try {
            await api.put(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/produtos/${oportunidadeProduto.id}`, {
                quantidade,
                preco,
                produto_id: oportunidadeProduto.produto_id
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível salvar produto')
            if (error.response) updateErros(error.response.data)
        }
    }

    function updateErros(error) {
        if(!error) return
        if (error && error instanceof Array) {
            const errors = {}
            for (let e of error) {
                errors[e.field] = e.message
            }
            setErros(errors)
        }
        else if (error.message) {
            notify('danger', error.message)
            setErros({})
        }
        else {
            setErros({})
        }
    }

    async function handleSave() {
        if (oportunidadeId) {
            setLoading(true)
            if (oportunidadeProduto.produto) {
                await update()
            } else {
                await insert()
            }
            setLoading(false)
        } else {
            if (valid()) {
                const produtoSelecionado = produtos.find(item => item.id == produto)
                onSuccess({
                    produto: { ...produtoSelecionado },
                    quantidade,
                    preco: preco,
                    produto_id: produto
                }
                )
            }
        }
    }

    function valid() {
        const error = {}
        if (!produto) {
            error['produto_id'] = 'Selecione o produto'
        }
        if (!quantidade || quantidade <= 0) {
            error['quantidade'] = 'Selecione a quantidade'
        }
        setErros(error)
        return !error.produto_id && !error.quantidade
    }

    return (
        <>
            <div className="rna-wrapper" style={{ position: 'absolute', width: '100%' }}>
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Row>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Tipo Produto*
                        </label>
                        <Select2
                            disabled={oportunidadeProduto.produto}
                            defaultValue="A"
                            onSelect={(e) => setTipoProduto(e.target.value)}
                            options={{
                                placeholder: "Selecione o tipo produto..."
                            }}
                            value={tipoProduto}
                            data={tiposProdutos.map((item) => ({ id: item.id, text: item.nome }))}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Produto*
                        </label>
                        <Select2
                            disabled={oportunidadeProduto.produto}
                            onSelect={(e) => setProduto(e.target.value + "")}
                            options={{
                                placeholder: "Selecione o produto..."
                            }}
                            value={produto}
                            data={produtos.map(item => ({ id: item.id, text: item.nome }))}
                        />
                        <small class="text-danger">
                            {erros.produto_id || ''}
                        </small>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label className="form-control-label">
                            Preço*
                        </label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>R$</InputGroupText>
                            </InputGroupAddon>
                            <CurrencyInput
                                className='form-control'
                                value={preco}
                                placeholder="Valor do produto..."
                                onChangeEvent={(e, maskedvalue, floatvalue) => setPreco(floatvalue)}
                            />
                        </InputGroup>
                        <small class="text-danger">
                            {erros.preco || ''}
                        </small>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Quantidade*
                        </label>
                        <Input
                            className="form-control"
                            type="number"
                            min={1}
                            placeholder="Quantidade do produto..."
                            value={quantidade}
                            onChange={(e) => setQuantidade(e.target.value)}
                        />
                        <small class="text-danger">
                            {erros.quantidade || ''}
                        </small>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label className="form-control-label">
                            Total*
                        </label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>R$</InputGroupText>
                            </InputGroupAddon>
                            <CurrencyInput
                                readOnly
                                className='form-control'
                                value={quantidade * preco || 0.0}
                                placeholder="Total..."
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="float-right ">
                        <Button
                            disabled={loading}
                            color="primary"
                            onClick={handleSave}
                        >
                            {
                                <Spinner
                                    hidden={!loading}
                                    className="mr-2"
                                    color="light"
                                    size="sm"
                                />
                            }
                            Salvar
                          </Button>
                        <Button
                            className="ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => close()}
                        >
                            Fechar
                          </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
}
