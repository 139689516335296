import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";


import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Button,
    InputGroup,
    InputGroupAddon,
    Input,
    Badge
} from "reactstrap";

export default ({ notify, publicacaoId, onChangeLinkList, alert }, ...props) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [link, setLink] = useState(null)

    const [links, setLinks] = useState([])
    const [erros, setErros] = useState({})


    useState(() => {
        if (onChangeLinkList) onChangeLinkList(links)
    }, [links, onChangeLinkList])

    useEffect(() => {
        loadLinks(publicacaoId)
    }, [publicacaoId])


    function createMock(link) {
        return {
            path: link,
            is_local: false,
            created_at: new Date(),
            tamanho: 0,
        };
    }

    function handleRemove(link) {
        const callback = () => {
            if (publicacaoId) {
                remove(link)
            } else {
                const filter = links.filter(item => item.path != link.path)
                console.log(filter, links, link)
                setLinks(filter)
            }
            alert(null)
        }
        alert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="Remover Link"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => callback()}
                onCancel={() => alert(null)}
                confirmBtnBsStyle="default"
                confirmBtnText="Sim"
                cancelBtnText="Cancelar"
            >
                Deseja realmente remover esse link?
          </ReactBSAlert>
        )
    }


    function handleSave() {
        const exist = links.find(item => item.path == link)
        if (exist) {
            //Caso já exista na lista, limpa o campo e cancela o resto
            setLink('')
            return
        }
        const mock = createMock(link)
        if (publicacaoId) {
            save(mock)
        } else {
            setLink('')
            const l = links
            l.push(mock)

            setLinks(l)
        }
    }

    async function remove(item) {
        try {
            await api.delete(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}/links/${item.id}`)
            loadLinks(publicacaoId)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível remover link')
        }
    }

    function handleAskToUpdateTipoImagem(imagem, is_criacao) {
        if (!publicacaoId) return
        const descricao = `Ao confirmar, imagem ${imagem.path} será do tipo ${is_criacao ? 'criação' : 'referência'}`
        alert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={is_criacao ? 'Alterar tipo da imagem para criação?' : 'Alterar tipo da imagem para referência?'}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleAlterarImagem(imagem, is_criacao)}
                onCancel={() => alert(null)}
                confirmBtnBsStyle="default"
                confirmBtnText="Sim"
                cancelBtnText="Não"
            >
                {descricao}
            </ReactBSAlert>
        )
    }

    async function handleAlterarImagem(imagem, is_criacao) {
        if (!publicacaoId) return
        try {
            await api.put(`common/empresas/${empresaSelecionada.id}/publicacoes/${publicacaoId}/imagens/${imagem.id}`,
                {
                    is_criacao
                })
            alert(null)
            await loadLinks(imagem.publicacao_id)
            notify('success', 'Link alterado')
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos realizar esta alteração')
        }

    }


    async function save(item) {
        try {
            await api.post(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}/links`, item)
            loadLinks(publicacaoId)
            setLink('')
            setErros({})
            notify('success', 'Link adicionado')
        } catch (error) {
            console.error(error)
            notify('danger',
                'Não foi possível adicionar link')
            if (error.response) {
                const response = error.response
                notify('danger', 'Alguns campos estão inválidos')
                if (response.data.length) {
                    const responseError = {}
                    for (let e of response.data) {
                        responseError[e.field] = e.message
                    }
                    setErros(responseError)
                }
            } else {
                notify('danger', 'Não foi possível salvar oportunidade.')
            }
        }
    }


    async function loadLinks(publicacaoId) {
        try {
            if (!publicacaoId) {
                return
            }
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}/imagens`)
            setLinks(response.data.filter(item => !item.is_local))
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar links')
        }
    }

    return (
        <>
            <div>
                <InputGroup className="mb-3">
                    <Input
                        placeholder="Links de refência"
                        type="text"
                        id="linkRef"
                        value={link}
                        onChange={e => setLink(e.target.value)} />
                    <InputGroupAddon addonType="append">
                        <Button
                            disabled={!link}
                            onClick={e => {
                                e.preventDefault();
                                handleSave();
                            }}
                            color="primary" outline><i class="fas fa-plus" ></i></Button>
                    </InputGroupAddon>
                </InputGroup>
                <small class="text-danger">
                    {erros.path || ""}
                </small>
            </div>
            <ListGroup className="list my--3" flush>
                {links.map((item, key) => (
                    <ListGroupItem
                        key={key}
                        className="px-0"
                        style={{ 'margin-top': '10px', "border-top": 'none' }}>

                        <Row className="align-items-center">
                            <Col className="col-auto">
                                <a
                                    className="avatar "
                                    style={{ color: 'white' }}
                                    onClick={e => e.preventDefault()}>
                                    <i class="fas fa-link"></i>
                                </a>
                            </Col>
                            <div className="col ml--2" style={{
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                'text-overflow': 'ellipsis',
                            }}>
                                <h4 className="mb-0"
                                    style={{
                                        cursor: 'pointer',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        'text-overflow': 'ellipsis',
                                    }}>
                                    <a href="#" onClick={e => { e.preventDefault(); window.open(item.path, '_blank'); }}>
                                        {item.path}
                                    </a>
                                    <span className=" text-muted mb-0" style={{ float: "right" }}>
                                        <Badge
                                            className="badge-primary"
                                            size="sm"
                                            href='#'
                                            onClick={e => { e.preventDefault(); handleAskToUpdateTipoImagem(item, !item.is_criacao) }}
                                            style={{ float: "right" }}
                                        >
                                            {
                                                item.is_criacao ? 'Criação' : 'Referência'
                                            }
                                        </Badge>
                                    </span>
                                </h4>
                            </div>
                            <Col className="col-auto pl-0">
                                <Button color="danger"
                                    size="sm" type="button"
                                    onClick={e => { handleRemove(item) }}>
                                    <i class="fas fa-trash"></i>
                                </Button>
                            </Col>
                        </Row>

                    </ListGroupItem>
                ))}
            </ListGroup>
        </>
    );
}
