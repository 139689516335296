import React, { useState, useEffect } from 'react';

import Form from '../index'
import {
    Modal,
    ModalBody
} from 'reactstrap'

interface IReadOnly {
    show?: boolean,
    onClose(): void,
    oportunidadeId?: number
}

const ReadOnly: React.FC<IReadOnly> = ({ show, onClose, oportunidadeId }) => {
    return (
        <Modal
            className="modal-dialog-centered"
            size='lg'
            isOpen={show}
            toggle={onClose}
        >
            <div className="modal-body p-0">
                <Form
                    oportunidadeId={oportunidadeId}
                    readOnly={true}
                    oportunidadeIdToCrossSelling={null}
                    tipoCrossSelling={null}
                    coordenadas={null}
                />
            </div>

        </Modal>
    )
}

export default ReadOnly;