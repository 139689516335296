import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label } from 'reactstrap';
import api from '../../../../../../../services/api';
import { PlanoItem } from './item';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input';

export interface ParcelaProps {
  id?: number;
  plano_pagamento_id?: any;
  nome: string;
  descricao: string;
  quantidade: number;
  valor: number;
}

export function PlanosPagamento({
  produtoId,
  notify,
  parcelas,
  onReload,
}: {
  produtoId: number;
  notify: (type: string, message: string) => void;
  parcelas: any;
  onReload: () => void;
}) {
  const [items, setItems] = useState<ParcelaProps[]>([]);
  const [planos, setPlanos] = useState<any>([]);
  const [planoSelecionado, setPlanoSelecionado] = useState<any>(null);

  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [quantidade, setQuantidade] = useState(1);
  const [valor, setValor] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  function valid() {
    if (!planoSelecionado || quantidade <= 0 || valor <= 0) {
      notify('danger', 'Verifique todos os campos do plano.');
      return false;
    }
    return true;
  }

  function limparCampos() {
    setDescricao('');
    setPlanoSelecionado(null);
    setValor(0);
    setQuantidade(0);
  }

  async function handleSubmit() {
    const isValid = valid();
    if (!isValid) return;
    try {
      setIsLoading(true);

      if (!produtoId) {
        notify('success', 'Plano salvo com sucesso!');
        limparCampos();

        // setItems((prevState) => [
        //   ...prevState,
        //   { descricao,
        //     valor,
        //     quantidade,
        //     plano_pagamento_id: planoSelecionado.value, },
        // ]);
        return;
      }

      // mandar call para salvar as parcelas
      await api.post('/common/parcelas', {
        descricao,
        valor,
        quantidade,
        produto_id: produtoId,
        plano_pagamento_id: planoSelecionado.value,
      });

      onReload();
      limparCampos();
      notify('success', 'Plano salvo com sucesso!');
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  //   function removeContatoLocal(contatoToUpdate: PlanoPagamentoProps) {
  //     const updatedContatos = contatos.filter(
  //       (contato) =>
  //         contato.ddd !== contatoToUpdate.ddd ||
  //         contato.numero !== contatoToUpdate.numero ||
  //         contato.cargo !== contatoToUpdate.cargo ||
  //         contato.nome !== contatoToUpdate.nome
  //     );
  //     setContatos(updatedContatos);
  //   }

  //   function updateContatoLocal(contatoToUpdate: PlanoPagamentoProps) {
  //     const updatedContatos = contatos.filter(
  //       (contato) =>
  //         contato.ddd !== contatoToUpdate.ddd ||
  //         contato.numero !== contatoToUpdate.numero ||
  //         contato.cargo !== contatoToUpdate.cargo ||
  //         contato.nome !== contatoToUpdate.nome
  //     );
  //     setContatos([...updatedContatos, contatoToUpdate]);
  //   }

  // Planos que vão aparecer no dropdown de seleção dos planos
  async function carregarPlanos() {
    const { data } = await api.get(`/common/planos-pagamento`);
    setPlanos(data);
  }

  //   useEffect(() => {
  //     onPlanosChange(planos);
  //   }, [planos]);

  useEffect(() => {
    if (!produtoId) return;
    carregarPlanos();
  }, [produtoId]);

  const customStyles = {
    container: (provided) => ({ ...provided, flex: 1 }),
    control: (provided) => ({
      ...provided,
      minHeight: '46px',
    }),
  };

  return (
    <Fragment>
      <div className="w-100 d-flex">
        <Col lg="3" className="p-1">
          <FormGroup>
            <Label style={{ fontSize: '12px' }}>Plano</Label>
            <Select
              placeholder="Planos"
              styles={customStyles}
              value={planoSelecionado}
              onChange={(item) => setPlanoSelecionado(item)}
              options={planos.map((item) => ({
                value: item.id,
                label: item.nome,
              }))}
            />
          </FormGroup>
        </Col>
        <Col lg="2" className="p-1">
          <FormGroup>
            <Label style={{ fontSize: '12px' }}>Parcelas</Label>
            <Input
              placeholder="Quantidade"
              min={1}
              type='number'
              className="form-control"
              value={quantidade}
              onChange={(e) => setQuantidade(+e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="2" className="p-1">
          <FormGroup>
            <Label style={{ fontSize: '12px' }}>Valor</Label>
            {/* <Input
              placeholder="Valor"
              className="form-control"
              value={valor}
              onChange={(e) => setValor(+e.target.value)}
            /> */}
            <CurrencyInput
              className="form-control"
              value={valor}
              placeholder="Valor"
              onChangeEvent={(e, maskedvalue, floatvalue) =>
                setValor(floatvalue)
              }
            />
          </FormGroup>
        </Col>
        <Col className="p-1">
          <FormGroup>
            <Label style={{ fontSize: '12px' }}>Descrição</Label>
            <Input
              placeholder="Descrição"
              className="form-control"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col className="p-1" style={{ flexGrow: 0, display: 'flex' }}>
          <FormGroup
            className="d-flex align-items-center"
            style={{ marginBottom: '10px' }}
          >
            <Button
              type="button"
              disabled={isLoading}
              outline
              color="primary"
              size="sm"
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </FormGroup>
        </Col>
      </div>
      {parcelas?.map((plano) => (
        <PlanoItem
          key={`${plano.nomePlano}${plano.quantidade}${plano.valor}`}
          notify={notify}
          plano={plano}
        />
      ))}
    </Fragment>
  );
}
