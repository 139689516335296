import './style.css';

import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import NotificationAlert from 'react-notification-alert';
import { DropdownItem } from 'reactstrap';

import playneeIcon from '../../../../../assets/icon.png';
import EmpresaContext from '../../../../../contexts/Empresa';
import { useWhatsappContext } from '../../../../../contexts/Whatsapp';
import { Oportunidade } from '../../../../../entities/Marketing';
import api from '../../../../../services/api';
import OportunidadeCard, {
  OportunidadeProps,
} from '../PanelChat/OportunidadeCard';
import Form from './Form';
import LoadingIcon from './LoadingIcon';

interface OffcanvasProps {
  isOpen: boolean;
  onClose: () => void;
  toggle: () => void;
}

const Offcanvas: React.FC<OffcanvasProps> = ({ isOpen, toggle }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [isLoading, setIsLoading] = useState(true);
  const [oportunidades, setOportunidades] = useState<Oportunidade[]>([]);

  const { oportunidadeSelecionada, selectedContact, selecionarOportunidade } =
  useWhatsappContext();

  const notificationAlert = useRef(null);

  useEffect(() => {
    if (!selectedContact) return;
      selecionarOportunidade(null);

    const { conversas } = selectedContact;

    if (!conversas) return;

    const oportunidadeIDs = conversas
      .filter((conversa) => conversa.oportunidade_id != null)
      .map((conversa) => conversa.oportunidade_id);

    const uniqueIDs = Array.from(new Set(oportunidadeIDs));

    if (uniqueIDs.length > 0) {
      buscarOportunidadesDoContato(uniqueIDs);
    } else {
      setOportunidades([]);
    }
  }, [selectedContact]);

  function notify(type: string, msg: string) {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    if (notificationAlert?.current)
      //@ts-ignore
      notificationAlert.current.notificationAlert(options);
  }

  async function buscarOportunidadesDoContato(IDs: number[]) {
    try {
      if (IDs.length === 0) return;
      const oportunidades: Oportunidade[] = [];
      setIsLoading(true);
      for (let id of IDs) {
        const { data } = await api.get<Oportunidade>(
          `/common/empresas/${empresaSelecionada!.id}/oportunidades/${id}`
        );

        if (data) oportunidades.push(data);
      }

      setOportunidades(oportunidades);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  // FIXME: evitar esse tipo de 'loading'
  useLayoutEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1250);

    return () => clearTimeout(timer);
  }, [oportunidadeSelecionada, selectedContact]);

  const offCanvasContent = () => {
    if (!selectedContact) {
      return (
        <div className="pt-4 text-center">
          <span>Selecione um contato.</span>
        </div>
      );
    }

    if (oportunidades.length === 0) {
      return (
        <div>
          <Form empresaSelecionada={empresaSelecionada} notify={notify} />
        </div>
      );
    }

    if (!oportunidadeSelecionada) {
      return (
        <div>
          {oportunidades?.map((oportunidade) => {
            return (
              <DropdownItem
                key={oportunidade.id}
                onClick={() => selecionarOportunidade(oportunidade)}
              >
                <OportunidadeCard
                  oportunidade={oportunidade as OportunidadeProps}
                />
              </DropdownItem>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        <Form empresaSelecionada={empresaSelecionada} notify={notify} />
      </div>
    );
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <div style={{ maxHeight: '100%' }}>
        <div className={`offcanvas-container ${isOpen ? 'open' : ''}`}>
          <div className="offcanvas">
            <button
              className="offcanvas-toggle"
              onClick={toggle}
              color="primary"
            >
              <img src={playneeIcon} alt="Playnee" />
            </button>
            <div className="offcanvas-header">
              <h3 className="mb-0">Oportunidades</h3>
              <button className="close" onClick={toggle}>
                &times;
              </button>
            </div>
            {isLoading && (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: '80%' }}
              >
                <LoadingIcon />
              </div>
            )}
            {!isLoading && offCanvasContent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Offcanvas;
