import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../contexts/Empresa'
import api from "../../../services/api";
import ReactQuill from "react-quill";
import ComentariosPanel from "./Comentarios";

import {
  Button,
  Badge,
  Modal,
} from "reactstrap";


export default function ComentarioButton({ publicacao, className, ...props }) {

  const { empresaSelecionada } = useContext(EmpresaContext)
  const [comentarios, setComentarios] = useState([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (publicacao && publicacao.id) loadComentarios()
  }, [publicacao])

  async function loadComentarios() {
    try {
      const response = await api.get(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacao.id}/comentarios`);
      const data = response.data
      setComentarios(data)
    } catch (err) {
      throwError("Não foi possível carregar as ponderações.")
    }
  }

  function throwError(err) {
    if (props.onError) {
      props.onError(err)
    }
  }

  function badgeColor() {
    if (comentarios.length > 0) {
      return comentarios[0].tipo != 'atividade' ? 'warning' : 'primary'
    }
    return 'primary'
  }


  return (
    <>
      {
        (publicacao && publicacao.id) &&
        <div
          style={props.style}
        >
          <Button color="default" className={`mr-3 ${className}`} size="sm"
            onClick={(e) => setShowModal(true)} >
            <span>Comentários</span>
            <Badge
              color={badgeColor()}
              className="badge-md badge-circle badge-floating border-white"
              style={{
                marginLeft: '0.1rem',
                top: '-76%'
              }}
            >
              {comentarios.length}
            </Badge>
          </Button>
          <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={showModal}
            toggle={() => setShowModal(!showModal)}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Comentários
            </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setShowModal(!showModal)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <ComentariosPanel
                publicacao={publicacao}
                onError={throwError}
              />
            </div>
          </Modal>
        </div>
      }
    </>
  );
}
