import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";

import { SmsContrudoContainer } from './styles'

import {
  FormGroup,
  Row,
  Col,
  Input,
  Badge,
  Button,
  PopoverBody,
  UncontrolledPopover
} from "reactstrap";

export default ({
  notify,
  conteudo,
  setConteudo,
  isNps,  
  npsText,
  setNpsText,
  erros
}) => {

  const { empresaSelecionada } = useContext(EmpresaContext)
  const [empresa, setEmpresa] = useState(undefined);
  const [linkNPS, setLinkNps] = useState('')

  useEffect(() => {
    loadEmpresa()
  }, [])

  async function loadEmpresa() {
    try {
      const response = await api.get(`/common/empresas/${empresaSelecionada.id}`);
      setEmpresa(response.data);
    } catch (error) {
      notify('danger', 'Houve um problema ao carregar os dados da empresa.');
    }
  }

  useEffect(() => {
    var urlNps = empresa?.url_nps;
    if (urlNps) {
      setLinkNps(' ' + urlNps);
      setNpsText(' ' + urlNps);
    }
  }, [empresa])

  return (
    <>
      <Row>
        <Col lg="12" sm="12" md="12">
          <FormGroup>
            <SmsContrudoContainer
            >
              <label className="form-control-label">
                Mensagem*
                {isNps &&

                  <>
                    <Button
                      color="secondary"
                      id="url_nps_9923"
                      outline
                      size="sm"
                      type="button">
                      <i className="fas fa-info-circle"></i>
                    </Button>


                    <UncontrolledPopover placement="top" target="url_nps_9923">
                      <PopoverBody>
                        Por se tratar de uma Mala Direta do tipo NPS, adicionaremos ao final do texto um hiperlink,
                        que redirecionará o cliente para a página da Pesquisa de Satisfação da sua Empresa.
                        Clique em Visualizar para ver como ficará o texto do sms.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                }
              </label>
              <Badge
                size='sm'
                color={(conteudo?.length > (isNps ? (160-npsText?.length) : 160) ? 'danger' : 'secondary')}
              >
                {`${conteudo?.length} caracteres`}
              </Badge>
            </SmsContrudoContainer>
            <Input
              value={conteudo}
              type='textarea'
              style={{ height: 100 }}
              onChange={({ target }) => setConteudo(target.value)}
            />
            <small class="text-danger">
              {erros.sms_conteudo || ''}
            </small>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}