import React from 'react';

export default function WhatsAppUserIcon() {
  return (
    <svg
      width="22px"
      height="22px"
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
    >
      <path d="M41.28,1037.02c5.33-19.69,10.28-38.13,15.32-56.55,17.38-63.51,34.93-126.98,51.95-190.59,1.3-4.85,.44-11.43-1.89-15.93-81.6-157.75-85.41-317-1.56-473.85C178.98,161.89,295.76,74.66,450.68,48.64c206.93-34.75,377.5,34.61,496.87,206.85,203.43,293.56,36.79,695.94-316,764.83-111.15,21.7-217.85,4.9-318.8-46.68-6.87-3.51-12.84-4-20.16-2.06-80.29,21.27-160.66,42.26-241.02,63.3-2.83,.74-5.73,1.2-10.29,2.14Zm119.06-117.26c48.47-12.71,94.58-24.45,140.45-37.08,10.65-2.93,18.78-1.48,28.25,4.09,90.8,53.48,188.43,71.22,291.68,50.36,291.58-58.92,427.25-396.64,254.42-639.08-99.17-139.12-238.95-195.67-407.4-167.72-193.47,32.1-333.38,198.13-338.59,394.44-2.25,84.77,20.65,162.9,66.69,234.12,4.15,6.41,5.41,12.01,3.19,19.68-10.23,35.35-19.74,70.91-29.46,106.41-2.99,10.93-5.82,21.91-9.23,34.77Z" />
      <g>
        <path d="M533.54,792.89c-63.21,0-126.42-.03-189.63,.01-24.78,.02-43.61-10.08-54.09-32.94-3.05-6.66-4.58-14.51-4.81-21.87-.63-20.33-.33-40.69-.18-61.04,.45-62.66,50.96-121.61,112.88-131.43,10.41-1.65,21.07-2.49,31.61-2.52,71.43-.21,142.88-.83,214.3,.11,59.89,.79,103.1,29.3,128.65,83.63,7.9,16.8,11.4,34.81,11.34,53.45-.05,17.75-.88,35.55,.19,53.24,1.72,28.64-20.12,59.8-59.33,59.49-63.64-.51-127.28-.14-190.93-.14Z" />
        <path d="M409.5,363.16c0-69.18,55.9-125.09,124.86-124.87,69.24,.22,124.57,55.84,124.54,125.18-.03,68.65-55.9,124.59-124.54,124.67-68.89,.08-124.85-55.94-124.86-124.99Z" />
      </g>
    </svg>
  );
}
