import React from 'react';
import { EquipeAnuncio } from '../../../../../entities/Marketing'


import {
    Button,
    Row,
    Col,
    ListGroupItem,
    ListGroup,
    Modal,
    Spinner
} from "reactstrap";

interface IList {
    equipes: Array<EquipeAnuncio>
    handleDelete(equipeAnuncio: EquipeAnuncio): void
}

const List: React.FC<IList> = ({ equipes, handleDelete }) => {

    return <ListGroup className="list mt-4" flush>
        {
            equipes.map((item, key) => (
                <ListGroupItem
                    className="list-group-item-action px-0"
                    style={{ flexDirection: 'row', display: 'flex', flex: 1, alignItems: 'center' }}
                    key={key}>
                    <div className="col-auto">
                        <Button
                            className="btn-sm"
                            color="danger"
                            onClick={(e) => {
                                e.preventDefault()
                                handleDelete(item)
                            }}
                        >
                            <i className="fas fa-trash"></i>
                        </Button>
                    </div>
                    <Row
                        style={{ flex: 1 }}
                        className="align-items-center">
                        <div className="col">
                            <h4 className="mb-0">
                                <a href="#" onClick={e => e.preventDefault()}>
                                    {item?.equipe?.nome}
                                </a>
                            </h4>
                            <small>{`${item?.equipe?.__meta__ ? item.equipe.__meta__.membros_count : item?.equipe?.membros?.length} colaboradores`}</small><br />
                        </div>

                    </Row>
                </ListGroupItem>
            ))
        }
    </ListGroup>
}

export default List;