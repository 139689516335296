import React, { useEffect, useState, useRef, useContext } from 'react';
import api from "../../../../services/api";
import moment from 'moment';
import AuthContext from '../../../../contexts/Auth'
import EmpresaContext from "../../../../contexts/Empresa";
import { useHistory } from 'react-router-dom'
import { Image } from '../../../common/Empresas/Logo/styles'

//@ts-ignore
import NotificationAlert from "react-notification-alert";
import SimpleHeader from '../../../../components/Headers/SimpleHeader'

import {
    Spinner,
    Badge,
    Modal,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Container,
    Row,
    Col
} from "reactstrap";

import ReactToPrint from 'react-to-print';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';

interface IPrint {
    oportunidadeId: number,
    readOnly: boolean
}

const Print: React.FC<IPrint> = ({ oportunidadeId, readOnly }) => {
    // export default ({ oportunidadeId, readOnly, oportunidadeIdToCrossSelling, tipoCrossSelling }, ...props) => {

    const printRef = useRef<CardBody>(null)
    const divRef = useRef<HTMLDivElement>(null)

    const history = useHistory()
    const { empresaSelecionada } = useContext(EmpresaContext)
    const { auth, hasPermission } = useContext(AuthContext)
    const [id, setId] = useState()
    const [oportunidade, setOportunidade] = useState({})
    const [comentarios, setComentarios] = useState(null)
    const [tags, setTags] = useState([])
    const [userId, setUserId] = useState(undefined)

    const [telefones, setTelefones] = useState([])
    const [produtos, setProdutos] = useState([])

    const [erros, setErros] = useState({})
    const [alert, setAlert] = useState(null)
    const [saving, setSaving] = useState(false)

    const notificationAlert = useRef<NotificationAlert>(null);

    // não apagar
    useEffect(() => {
        if (oportunidadeId && oportunidadeId != id) {
            //@ts-ignore
            setId(oportunidadeId)
            loadOportunidade(oportunidadeId)
            loadProdutos(oportunidadeId)
            loadComentarios(oportunidadeId)
        }
    }, [oportunidadeId])

    useEffect(() => {
        //@ts-ignore
        if (!userId) setTimeout(() => setUserId(null), 1000)
    }, [userId])

    //Carrega os dados na tela
    useEffect(() => {
        if (isOportunidadeLoaded()) {
            fillFields()
        }
    }, [oportunidade])


    function isOportunidadeLoaded() {
        //@ts-ignore
        return (oportunidade && oportunidade.id)
    }

    const notify = (type: any, msg: any) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3
        };
        //@ts-ignore 
        if (notificationAlert) notificationAlert.current.notificationAlert(options);
    };

    async function fillFields() {
        //@ts-ignore
        await fillCliente(oportunidade.cliente)
    }

    async function fillCliente(cliente: any) {
        setTags(cliente.tags_array || [])
        await loadTelefonesCliente(cliente.id)
    }

    //NÃO APAGAR
    async function loadTelefonesCliente(clienteId: any) {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/clientes/${clienteId}/telefonesbycliente`)
            const data = await response.data
            setTelefones(data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não é possível carregar telefones')
        }
    }

    //NÃO APAGAR
    async function loadProdutos(oportunidadeId: any) {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/produtos`)
            setProdutos(response.data)
        } catch (error) {
            notify('danger', 'Não foi possivel carregar produtos')
        }
    }

    //NÃO APAGAR
    async function loadComentarios(oportunidadeId: any) {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/comentarios`, {
                params: {
                    page: 1,
                    limit: 100
                }
            })
            setComentarios(response.data.data)
        } catch (error) {
            notify('danger', 'Não foi possivel carregar comentarios')
        }
    }

    async function loadOportunidade(id: any) {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${id}`)
            const op = await response.data
            setOportunidade(op)
        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível carregar oportunidade')
        }
    }

    function handlerPrint() {
        notify('success', 'Imprimindo Oportunidade')
    }

    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>

            <Container className={"mt--6"} fluid>
                <Card>
                    <CardHeader
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                        }}>
                        <>
                            <h1 className='d-flex' style={{ justifyContent: 'space-between' }}>
                                <span>Imprimir Oportunidade</span>
                            </h1>
                        </>
                    </CardHeader>

                    <div ref={divRef} >

                        <CardBody

                            outline
                            body
                            color={'danger'}
                        >
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <Row>
                                        <Col sm="12" md="12" lg="12">

                                            {/* LOGOMARCA - NOME EMPRESA */}
                                            {
                                                empresaSelecionada ?
                                                    (empresaSelecionada.logo && empresaSelecionada.logo.length > 0)
                                                        ?
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                                                                <a href="#" >
                                                                    <Image
                                                                        alt="logo"
                                                                        style={{ maxHeight: 50 }}
                                                                        src={empresaSelecionada.logo_url}
                                                                    />
                                                                </a>
                                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>

                                                                    <h2 style={{ marginBottom: -5 }}>
                                                                        <span>{empresaSelecionada.nome}</span>
                                                                    </h2>
                                                                    <h4>
                                                                        {/* @ts-ignore */}
                                                                        <span>Oportunidade #1{oportunidade.id}</span>
                                                                    </h4>

                                                                </div>
                                                            </div>

                                                            <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, justifyContent: 'flex-end' }}>
                                                                <Image
                                                                    alt="playnne"
                                                                    style={{ maxHeight: 25}}
                                                                    src={'/static/media/full_logo_ligth.f35457ab.svg'}
                                                                />
                                                            </div>

                                                        </div>
                                                        :
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>

                                                                    <h2 style={{ marginBottom: -5 }}>
                                                                        <span>{empresaSelecionada.nome}</span>
                                                                    </h2>
                                                                    <h4>
                                                                        {/* @ts-ignore */}
                                                                        <span>Oportunidade #1{oportunidade.id}</span>
                                                                    </h4>

                                                                </div>
                                                            </div>

                                                            <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, justifyContent: 'flex-end' }}>
                                                                <Image
                                                                    alt="playnne"
                                                                    style={{ maxHeight: 25}}
                                                                    src={'/static/media/full_logo_ligth.f35457ab.svg'}
                                                                />
                                                            </div>

                                                        </div>
                                                    :
                                                    <></>
                                            }

                                            {/* DADOS CLIENTE */}
                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>&nbsp;&nbsp;</span>
                                                </h3>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>Cliente:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade?.cliente?.nome}</span>
                                                </h3>
                                                <h3>
                                                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    <span>Tipo:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade?.cliente?.tipo_pessoa ? (oportunidade.cliente.tipo_pessoa == 'J' ? 'Pessoa Jurídica' : 'Pessoa Física') : ''}</span>
                                                </h3>
                                            </div>

                                            {/* @ts-ignore */}
                                            {(oportunidade?.cliente?.tipo_pessoa == 'J' && oportunidade?.cliente?.pessoa_contato) ?

                                                <div style={{ display: 'flex' }}>
                                                    <h3>
                                                        <span>Contato:&nbsp;&nbsp;</span>
                                                    </h3>
                                                    <h3>
                                                        {/* @ts-ignore */}
                                                        <span>{oportunidade?.cliente?.pessoa_contato}</span>
                                                    </h3>
                                                    <h3>
                                                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                    </h3>
                                                    {/* @ts-ignore */}
                                                    {oportunidade?.cliente?.email &&
                                                        <>
                                                            <h3>
                                                                <span>Email:&nbsp;&nbsp;</span>
                                                            </h3>

                                                            <h3>
                                                                {/* @ts-ignore */}
                                                                <span>{oportunidade.cliente.email}</span>
                                                            </h3>
                                                        </>
                                                    }

                                                </div>
                                                :
                                                <>
                                                    {/* @ts-ignore */}
                                                    {oportunidade?.cliente?.email &&
                                                        <div style={{ display: 'flex' }}>
                                                            <h3>
                                                                <span>Email:&nbsp;&nbsp;</span>
                                                            </h3>

                                                            <h3>
                                                                {/* @ts-ignore */}
                                                                <span>{oportunidade.cliente.email}</span>
                                                            </h3>
                                                        </div>
                                                    }
                                                </>
                                            }

                                            <div style={{ display: 'flex' }}>
                                                {(telefones && telefones.length > 0) &&
                                                    <>
                                                        <h3>
                                                            <span>Telefones:&nbsp;&nbsp;</span>
                                                        </h3>

                                                        {telefones.map((item, key) => (
                                                            <>
                                                                <h3>
                                                                    {/* @ts-ignore */}
                                                                    <span>{`(${item.ddd}) ${item.numero}`}</span>
                                                                </h3>

                                                                {(key < (telefones.length - 1)) &&
                                                                    <h3>
                                                                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                                    </h3>
                                                                }

                                                            </>
                                                        ))}
                                                    </>
                                                }
                                            </div>


                                            {/* DADOS OPORTUNIDADE */}
                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>&nbsp;&nbsp;</span>
                                                </h3>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>Colaborador Responsável:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade?.user?.name}</span>
                                                </h3>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>Campanha:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade?.anuncio?.campanha?.nome}</span>
                                                </h3>
                                                <h3>
                                                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    <span>Anúncio:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade?.anuncio?.nome}</span>
                                                </h3>

                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>Criada em:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade?.created_at ? moment(oportunidade.created_at).format('LLL') : ''}</span>
                                                </h3>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>Agendada para:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade?.data_agendamento ? moment(oportunidade.data_agendamento).format('LLL') : ''}</span>
                                                </h3>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>Estágio:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade?.estagio?.nome}</span>
                                                </h3>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>Expectativa:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade?.expectativa?.nome}</span>
                                                </h3>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>Situação:&nbsp;&nbsp;</span>
                                                </h3>
                                                <h3>
                                                    {/* @ts-ignore */}
                                                    <span>{oportunidade.data_encerramento ? (oportunidade.deleted_at ? 'Encerrada (Excluída)' : 'Encerrada') : (oportunidade.deleted_at ? 'Aberta (Excluída)' : 'Aberta')}</span>
                                                </h3>

                                                {/* @ts-ignore */}
                                                {oportunidade.data_encerramento &&
                                                    <>
                                                        <h3>
                                                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                        </h3>
                                                        <h3>
                                                            <span>Motivo Encerramento:&nbsp;&nbsp;</span>
                                                        </h3>
                                                        <h3>
                                                            {/* @ts-ignore */}
                                                            <span>{oportunidade?.motivoEncerramento?.nome}</span>
                                                        </h3>
                                                        <h3>
                                                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                        </h3>
                                                        <h3>
                                                            <span>Encerramenta em:&nbsp;&nbsp;</span>
                                                        </h3>
                                                        <h3>
                                                            {/* @ts-ignore */}
                                                            <span>{moment(oportunidade.data_encerramento).format('LLL')}</span>
                                                        </h3>

                                                    </>
                                                }


                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                {(tags && tags.length > 0) &&
                                                    <>
                                                        <h3>
                                                            <span>Tags:&nbsp;&nbsp;</span>
                                                        </h3>

                                                        {tags.map((item, key) => (
                                                            <>
                                                                <h3>
                                                                    <span>{item}</span>
                                                                </h3>

                                                                {(key < (tags.length - 1)) &&
                                                                    <h3>
                                                                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                                    </h3>
                                                                }

                                                            </>
                                                        ))}
                                                    </>
                                                }
                                            </div>

                                            {/* DADOS PRODUTOS */}
                                            <div style={{ display: 'flex' }}>
                                                <h3>
                                                    <span>&nbsp;&nbsp;</span>
                                                </h3>
                                            </div>
                                            {(produtos && produtos.length > 0) &&
                                                <>
                                                    <div style={{ display: 'flex' }}>
                                                        <h3>
                                                            <span>Produtos:&nbsp;&nbsp;</span>
                                                        </h3>
                                                    </div>
                                                    {produtos.map((item, key) => (
                                                        <div style={{ display: 'flex' }}>
                                                            <h3>
                                                                {/* @ts-ignore */}
                                                                <span>{item.produto.nome}</span>
                                                            </h3>
                                                            <h3>
                                                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                            </h3>
                                                            <h3>
                                                                <span>Qtd:&nbsp;&nbsp;</span>
                                                            </h3>
                                                            <h3>
                                                                {/* @ts-ignore */}
                                                                <span>{item.quantidade}</span>
                                                            </h3>
                                                            <h3>
                                                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                            </h3>
                                                            <h3>
                                                                <span>Preço Unitário:&nbsp;&nbsp;</span>
                                                            </h3>
                                                            <h3>
                                                                {/* @ts-ignore */}
                                                                <span>R$&nbsp;{item.preco}</span>
                                                            </h3>
                                                            <h3>
                                                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                            </h3>
                                                            <h3>
                                                                <span>Total:&nbsp;&nbsp;</span>
                                                            </h3>
                                                            <h3>
                                                                {/* @ts-ignore */}
                                                                <span>R$&nbsp;{parseFloat(item.quantidade * item.preco).toFixed(2)}</span>
                                                            </h3>

                                                            {/* @ts-ignore */}
                                                            {item.data_declinacao &&
                                                                <h3>
                                                                    <span className='text-danger'>&nbsp;&nbsp;(DECLINADO)</span>
                                                                </h3>
                                                            }

                                                        </div>
                                                    ))}
                                                </>
                                            }

                                            {/* DADOS DESCRIÇÂO E COMENTÀRIOS */}

                                            {/* @ts-ignore */}
                                            {oportunidade.descricao &&
                                                <>
                                                    <div style={{ display: 'flex' }}>
                                                        <h3>
                                                            <span>&nbsp;&nbsp;</span>
                                                        </h3>
                                                    </div>

                                                    <div style={{ display: 'flex' }}>
                                                        <h3>
                                                            <span>Observação:&nbsp;&nbsp;</span>
                                                        </h3>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>

                                                        <h3>
                                                            {/* @ts-ignore */}
                                                            <span>{oportunidade.descricao}</span>
                                                        </h3>
                                                    </div>
                                                </>
                                            }

                                            {/* @ts-ignore */}
                                            {comentarios && comentarios.length > 0 &&
                                                <>
                                                    <div style={{ display: 'flex' }}>
                                                        <h3>
                                                            <span>&nbsp;&nbsp;</span>
                                                        </h3>
                                                    </div>

                                                    <div style={{ display: 'flex' }}>
                                                        <h3>
                                                            <span>Comentários:&nbsp;&nbsp;</span>
                                                        </h3>
                                                    </div>

                                                    {/* @ts-ignore */}
                                                    {comentarios.map((item, key) => (
                                                        <>
                                                            <div style={{ height: 1, backgroundColor: 'black', borderRadius: 3, marginBottom: 5 }} />
                                                            <div style={{ display: 'flex' }}>
                                                                <h3>
                                                                    <span>Autor:&nbsp;&nbsp;{item.user.name}</span>
                                                                </h3>
                                                                <h3>
                                                                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                                </h3>
                                                                <h3>
                                                                    <span>Data:&nbsp;&nbsp;</span>
                                                                </h3>
                                                                <h3>
                                                                    <span>{moment(item.created_at).format('LLL')}</span>
                                                                </h3>
                                                            </div>
                                                            <div style={{ marginBottom: -8 }}>
                                                                <div dangerouslySetInnerHTML={{ __html: item.descricao }} />
                                                            </div>
                                                            {/* @ts-ignore */}
                                                            {(key == (comentarios.length - 1)) &&
                                                                <div style={{ height: 1, backgroundColor: 'black', borderRadius: 3 }} />
                                                            }
                                                        </>



                                                    ))}

                                                </>
                                            }


                                        </Col>
                                    </Row>



                                </Col>

                            </Row>

                        </CardBody>
                    </div>

                    {
                        !readOnly &&
                        <CardFooter
                            style={{
                                position: "sticky",
                                bottom: 0,
                                border: "2 solid",
                                boxShadow: "0px 0px 2px 2px #f5f5f5",
                                // @ts-ignore
                                zIndex: "9"
                            }}
                        >
                            <Row>
                                <Col>
                                    <div className="float-left ">
                                    </div>
                                    <div className="float-right ">
                                        <Button
                                            className="ml-auto"
                                            color="link"
                                            data-dismiss="modal"
                                            disabled={saving}
                                            type="button"
                                            onClick={(e) => { e.preventDefault(); history.goBack() }}
                                        >
                                            Voltar
                                        </Button>

                                        <ReactToPrint
                                            trigger={() => (
                                                <Button
                                                    outline
                                                    className='btn-icon'
                                                    color='primary'
                                                >
                                                    <span className="btn-inner--icon">
                                                        <i className="fas fa-print"></i>
                                                    </span>
                                                    <span className="btn-inner--text">Imprimir</span>
                                                </Button>
                                            )}
                                            content={() => divRef?.current as React.ReactInstance}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardFooter>
                    }
                </Card>

            </Container>
        </>
    );
}

export default Print;
