import styled from "styled-components";

export const FABConversas = styled.button`
  background: #0847d6;
  border-radius: 8px;
  color: white;
  position: sticky;
  top: 16px;
  z-index: 2;
  display: grid;
  place-items: center;
  font-size: 14px;
  padding: 8px;
  display: flex;
  gap: 0.5rem;
  float: right;
  margin-bottom: -36px;
`;

export const ClienteInputContainer = styled.div`
  @media (max-width: 575.98px) {
    width: 58%;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 80%;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 80%;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 77%;
  }

  @media (min-width: 1200px) {
    width: 80%;
  }
`;

export const AnuncioInputContainer = styled.div`
  width: calc(100% - 58px);
`;
