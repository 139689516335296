import React, { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import api from "../../../../../services/api";
import ReactBSAlert from "react-bootstrap-sweetalert";

interface EstagioHandlerProps {
  empresa: any;
  oportunidade: any;
}

interface Estagio {
  id: number;
  nome: string;
  created_at: string;
  cor: string;
  estagio_id: number;
  funil_id: number;
  ordem: number;
}

const AlterarEstagioBtn: React.FC<EstagioHandlerProps> = ({
  empresa,
  oportunidade,
}) => {
  const [estagio, setEstagio] = useState<Estagio | null>(
    oportunidade?.estagio || null
  );
  const [estagios, setEstagios] = useState<Estagio[]>([]);
  const [novoEstagio, setNovoEstagio] = useState<Estagio | null>(null);
  const [alert, setAlert] = useState<any>(null);

  useEffect(() => {
    if (estagios.length === 0) {
      loadEstagios();
    }
  }, []);

  async function loadEstagios() {
    try {
      const url = `/common/empresas/${empresa?.id}/funilpadraoanuncio/${oportunidade.anuncio_id}`;

      const { data: estagiosData } = await api.get(url);

      setEstagios(estagiosData);

      if (estagiosData.length > 0) {
        if (!estagio || !estagio.id) {
          // @ts-ignore
          setEstagio(estagiosData[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function onChangeEstagio(proximoEstagio: Estagio) {
    setAlert(
      <ReactBSAlert
        warning
        title="Alterar Estágio?"
        onConfirm={() => changeToEstagio(proximoEstagio)}
        onCancel={() => setAlert(null)}
        showCancel
        // disabled={isLoading}
        confirmBtnBsStyle="primary"
        confirmBtnText="Sim"
        cancelBtnBsStyle="link"
        cancelBtnText="Não"
        btnSize=""
      />
    );
  }

  async function changeToEstagio(nextEstagio: Estagio) {
    try {
      await api.put(
        `/common/empresas/${empresa?.id}/oportunidades/${oportunidade.id}/avancar-oportunidades`,
        {
          estagio_id: nextEstagio.id,
          data_agendamento: oportunidade.data_agendamento,
        }
      );
      setEstagio(nextEstagio);
      setNovoEstagio(nextEstagio)
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block" }}
          title="Estágio Alterado!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          O estágio foi alterado com sucesso.
        </ReactBSAlert>
      );
    } catch (error) {
      console.error("Não foi possível alterar o estágio.", error);
    }
  }

  return (
    <>
      {alert}
      <UncontrolledDropdown
        style={{
          paddingRight: 4,
        }}
        group
        size="sm"
        direction="down"
      >
        <DropdownToggle
          caret
        //   color={estagio?.cor?.includes("#") ? "primary" : estagio?.cor}
          style={
            { backgroundColor: novoEstagio?.cor ?? estagio?.cor, border: "none", color: '#fff' }
            // estagio?.cor?.includes("#") ? { backgroundColor: estagio?.cor } : {}
          }
        >
          {estagio?.nome || "Estágio"}
        </DropdownToggle>
        <DropdownMenu right>
          {estagios?.map((item: Estagio, key: any) => {
            if (item.id === estagio?.id) return null;
            return (
              <DropdownItem
                key={key}
                onClick={(e) => {
                  e.preventDefault();
                  onChangeEstagio(item);
                }}
              >
                {item.nome}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default AlterarEstagioBtn;
