import styled from 'styled-components';
import {
    Container as NativeContainer,
    Jumbotron
} from "reactstrap";

export const Container = styled(Jumbotron)`
  display: flex;
  flex: 1;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const FormContainer = styled(NativeContainer)`
    background-color: var(--white);
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
`


