import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../contexts/Empresa";
import api from "../../../services/api";
import Select2 from "react-select2-wrapper";
import {
    Button,
    Modal,
    FormGroup,
} from "reactstrap";

export default ({ oportunidade, onEncerrado, notify }, ...props) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [showModal, setShowModal] = useState(false)
    const [encerramentos, setEncerramentos] = useState([])
    const [encerramento, setEncerramento] = useState(null)

    useEffect(() => {
        if (encerramentos.length === 0) {
            loadEncerramentos()
        }
    }, [])

    useEffect(() => {
        setEncerramento(null)
    }, [showModal])

    function isNew() {
        return !(oportunidade && oportunidade.id)
    }

    async function loadEncerramentos() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/motivos-encerramentos`,
                {
                    params: {
                        aplicacoes: ['encerramento', 'null']
                    }
                })
            setEncerramentos(response.data)
        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível carregar motivos de encerramentos')
        }
    }

    async function handlerEncerrar() {
        if (!encerramento) return
        try {
            await api.post(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidade.id}/encerrar-oportunidades`,
                {
                    motivo_encerramento_id: encerramento
                })
            if (notify) notify('success', 'Oportunidade encerrada')
            if (onEncerrado) onEncerrado(encerramento)
        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível encerrar oportunidade')
        }
    }

    return (
        <>
            {
                !isNew() &&
                <Button
                    color="danger"
                    type="submit"
                    onClick={() => setShowModal(true)}>
                    Encerrar
                </Button>
            }
            <Modal
                className="modal-dialog-centered modal-danger"
                size="md"
                isOpen={showModal}
                toggle={() => setShowModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Encerrar oportunidade
                </h6>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="fas fa-exclamation-circle ni-3x" />
                        <h4 className="heading mt-4"></h4>
                        <p>
                            Você está prestes a encerrar está oportunidade. Selecione um motivo de encerramento para prosseguir.
                  </p>
                        <FormGroup>
                            <Select2
                                className="form-control"
                                onSelect={(e) => setEncerramento(e.target.value)}
                                options={{
                                    placeholder: 'Selecione um motivo encerramento...'
                                }}
                                value={encerramento}
                                data={encerramentos.map((item) => ({ id: item.id, text: item.nome }))}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setShowModal(false)}
                    >
                        Fechar
                </Button>
                    <Button
                        onClick={() => handlerEncerrar()}
                        className="btn-white"
                        color="default"
                        type="button">
                        Encerrar
                </Button>
                </div>
            </Modal>
        </>
    );
}
