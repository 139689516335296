import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { usePersistedState } from "../../../../hooks";
import ReactDatetime from "react-datetime";

interface Props {
  onSearch(): void;
  isLoading: boolean;
  dataCadastroInicial: any;
  dataCadastroFinal: any;
  setDataCadastroInicial(value: any): void;
  setDataCadastroFinal(value: any): void;
  ClienteIndicadorId?: any;
}

const FiltersComp: React.FC<Props> = ({
  onSearch,
  isLoading,
  dataCadastroInicial,
  dataCadastroFinal,
  setDataCadastroInicial,
  setDataCadastroFinal,
}) => {
  console.log("------------FILTERS-------------");

  function handleLimparDataCadastro() {
    // @ts-ignore
    setDataCadastroInicial(null);
    // @ts-ignore
    setDataCadastroFinal(null);
  }
  var yesterday = moment().subtract(1, "day");
  var valid = function (current: any) {
    return dataCadastroInicial != dataCadastroFinal;
  };
  return (
    <>
      <div>
        <Row style={{ padding: 4 }} className={"align-items-center"}>
          <Col xs="12" lg="12" sm="12" md="12">
            <div
              style={{
                border: "1px solid",
                borderRadius: "4px",
                borderColor: "#eee",
                display: "flex",
                flex: 1,
                justifyContent: "space-around",
                alignItems: "center",
                padding: 13,
              }}
            >
              <FormGroup style={{ flex: 1, paddingRight: 4 }}>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Cadastro inicial
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Data inicial",
                      style: {
                        width: "100px",
                      },
                    }}
                    value={moment(dataCadastroInicial)}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        dataCadastroInicial &&
                        dataCadastroFinal &&
                        dataCadastroInicial._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        dataCadastroInicial &&
                        dataCadastroFinal &&
                        new Date(dataCadastroInicial._d + "") <
                          new Date(currentDate._d + "") &&
                        new Date(dataCadastroFinal._d + "") >
                          new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        dataCadastroFinal &&
                        dataCadastroFinal._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={(e: any) => {
                      setDataCadastroInicial(e);
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup style={{ flex: 1, paddingLeft: 4 }}>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Cadastro final
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Data final",
                      style: {
                        width: "100px",
                      },
                    }}
                    //   style={{ width: "100px" }}
                    value={moment(dataCadastroFinal)}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        dataCadastroInicial &&
                        dataCadastroFinal &&
                        dataCadastroInicial._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        dataCadastroInicial &&
                        dataCadastroFinal &&
                        new Date(dataCadastroInicial._d + "") <
                          new Date(currentDate._d + "") &&
                        new Date(dataCadastroFinal._d + "") >
                          new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        dataCadastroFinal &&
                        dataCadastroFinal._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={(e) => setDataCadastroFinal(e)}
                  />
                </InputGroup>
              </FormGroup>
              <Button
                className="ml-2"
                color="secondary"
                outline
                title="Limpar datas de cadastro"
                size="sm"
                onClick={handleLimparDataCadastro}
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-trash" />
                </span>
              </Button>
            </div>
          </Col>
          <Col xs="12" lg="12" sm="12" md="12">
            <div className="pt-3">
              <Button
                color="secondary"
                disabled={isLoading}
                onClick={() => {
                  onSearch();
                }}
                outline
                size="sm"
                href="#"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-search" />
                </span>
                <span className="btn-inner--text"> Pesquisar</span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FiltersComp;
