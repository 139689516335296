import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../../../../services/api";
import EmpresaContext from "../../../../../contexts/Empresa";

import Empresa from "../../../../../entities/Common/Empresa";
import Pagamento from "../../../../../entities/Common/Pagamento";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
import List from "./List";
import Form from "./Form";
// @ts-ignore
import NotificationAlert from "react-notification-alert";

import { Button, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

const Pagamentos: React.FC = () => {
  const [pagamentos, setPagamentos] = useState(new Array<Pagamento>());
  const [showForm, setShowForm] = useState(false);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const notificationAlert = useRef<any>();

  async function load() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/pagamentos`
      );
      setPagamentos(await response.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar os pagamentos da empresa");
    }
  }

  function hideForm() {
    setShowForm(false);
  }

  function handleShowForm() {
    setShowForm(true);
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Pagamentos" parentName="Minha empresa" />
      <div className="container-fluid mt--6">
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <h1>Pagamentos</h1>
              </CardHeader>
              <CardBody>
                {empresaSelecionada && (
                  <Form
                    empresa={empresaSelecionada}
                    hide={hideForm}
                    notify={notify}
                    success={load}
                    show={showForm}
                    pagamento={{} as Pagamento}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    color="primary"
                    type="button"
                    className="btn-icon btn-3"
                    onClick={handleShowForm}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Novo Pagamento</span>
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    className="btn-icon btn-3"
                    onClick={load}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-sync"></i>
                    </span>
                    <span className="btn-inner--text">Atualizar</span>
                  </Button>
                </div>
                {empresaSelecionada && (
                  <List
                    pagamentos={pagamentos}
                    empresa={empresaSelecionada}
                    notify={notify}
                    reLoad={load}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Pagamentos;
