import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../contexts/Empresa";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import api from "../../../services/api";
import { usePersistedState } from '../../../hooks/index'
import moment from 'moment'

import Filters from "../../../components/Headers/Filters";
import {
    Button,
    CardBody,
    Row,
    Col,
    Input,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

export default ({ title, load, children, filters = {}, clearFiltersPrioridades, byanuncio, anuncio }, ...props) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [dataAgendamentoInicial, setDataAgendamentoInicial] = usePersistedState('dataAgendamentoInicial', getDataAgendamentoInicial())
    const [dataAgendamentoFinal, setDataAgendamentoFinal] = usePersistedState('dataAgendamentoFinal', getDataAgendamentoFinal())
    const [dataCadastroInicial, setDataCadastroInicial] = usePersistedState('dataCadastroInicial', null)
    const [dataCadastroFinal, setDataCadastroFinal] = usePersistedState('dataCadastroFinal', null)
    const [titulo, setTitulo] = usePersistedState('titulo', null)
    const [pilares, setPilares] = usePersistedState('pilares', null)
    const [trafegos, setTrafegos] = usePersistedState('trafegos', null)
    const [situacoes, setSituacoes] = usePersistedState('situacoes', ['A', 'P'])
    const [fases, setFases] = usePersistedState('fases', getFases())
    const [destinos, setDestinos] = usePersistedState('destinos', [])
    const [naoVinculadas, setNaoVinculadas] = usePersistedState('naoVinculadas', false)

    const [pilaresList, setPilaresList] = useState([])
    const [trafegosList, setTrafegosList] = useState([])
    const [situacoesList, setSituacoesList] = useState([])
    const [fasesList, setFasesList] = useState([])
    const [destinosList, setDestinosList] = useState([])

    const [runLoad, setRunLoad] = useState(props.search ? props.search : true)
    const [monitorClearFilters, setMonitorClearFilters] = useState(undefined)

    useEffect(() => {
        if (monitorClearFilters) search()
    }, [monitorClearFilters])

    useEffect(() => {
        if (trafegosList.length === 0) loadTrafegos()
        if (pilaresList.length === 0) loadPilares()
        if (situacoesList.length === 0) loadSituacoes()
        if (fasesList.length === 0) loadFases()
        if (destinosList.length === 0) loadDestinos()
    }, [])

    useEffect(() => {
        if (runLoad) {
            search()
            setRunLoad(false)
        }
    }, [runLoad])

    async function loadPilares() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/pilares`)
            if (response.data) {
                setPilaresList(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function loadFases() {
        try {
            const response = await api.get("/common/fluxos/vigente");
            if (response.data) {
                setFasesList(response.data.fases)
            }
        } catch (err) {
            console.error(err)
        }
    }

    async function loadDestinos() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/destinos/`)
            if (response.data) {
                setDestinosList(response.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function loadSituacoes() {
        try {
            const response = await api.get("/marketing/situacoes/publicacoes");
            if (response.data) {
                setSituacoesList(response.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function loadTrafegos() {
        try {
            const response = await api.get('/marketing/trafegos')
            if (response.data) {
                setTrafegosList(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function getDataAgendamentoInicial() {
        if ('dataAgendamentoInicial' in filters) {
            return filters.dataAgendamentoInicial
        }
        if (byanuncio) {
            return null;
        }

        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth());
    }

    function getDataAgendamentoFinal() {
        if (filters.dataAgendamentoFinal) return filters.dataAgendamentoFinal
        if (byanuncio) return null;
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    function getFases() {
        if ('fases' in filters) return filters.fases
        return null
    }

    async function handleClearFilter() {
        if (clearFiltersPrioridades) {
            await clearFiltersPrioridades()
        }
        clearFilters();
    }

    function clearFilters() {
        setDataAgendamentoInicial(getDataAgendamentoInicial)
        setDataAgendamentoFinal(getDataAgendamentoFinal)
        setDataCadastroInicial(null)
        setDataCadastroFinal(null)
        setTitulo('')
        setPilares(null)
        setTrafegos(null)
        if (byanuncio) {
            setSituacoes([])
            setNaoVinculadas(false)
        } else {
            setSituacoes(['A', 'P'])
        }

        setFases(null)
        setDestinos([])
    }

    function handleMultipleSelect(target, state) {
        const array = Array.from(target.selectedOptions)
        state(array.map((item) => item.value))
    }

    async function handlePesquisar() {
        await search(true)
    }

    function search(clearPagitation) {
        load({
            dataAgendamentoInicial,
            dataAgendamentoFinal,
            dataCadastroInicial,
            dataCadastroFinal,
            titulo,
            pilares,
            trafegos,
            situacoes,
            fases,
            destinos,
            naoVinculadas
        }, clearPagitation)
    }

    return (
        <>
            <Filters
                onSearch={handlePesquisar}
                title={title}
                clearFilters={handleClearFilter}
                onFiltersClead={setMonitorClearFilters}
            >
                <CardBody>
                    <Row>
                        <Col xs="auto" lg="6" sm="6" md="6">
                            <fieldset class="border p-2">
                                <legend class="w-auto" style={{ margin: 0 }}>
                                    <label
                                        className="form-control-label"
                                    >
                                        Postagem
                                    </label>
                                </legend>
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example-number-input"
                                            >
                                                Início
                                            </label>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-calendar-grid-58" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder: "Agendamento inicial",
                                                        style: {
                                                            width: '100px'
                                                        }
                                                    }}
                                                    value={moment(dataAgendamentoInicial)}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat={false}
                                                    renderDay={(props, currentDate, selectedDate) => {
                                                        let classes = props.className;
                                                        if (
                                                            dataAgendamentoInicial &&
                                                            dataAgendamentoFinal &&
                                                            dataAgendamentoInicial._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " start-date";
                                                        } else if (
                                                            dataAgendamentoInicial &&
                                                            dataAgendamentoFinal &&
                                                            new Date(dataAgendamentoInicial._d + "") <
                                                            new Date(currentDate._d + "") &&
                                                            new Date(dataAgendamentoFinal._d + "") >
                                                            new Date(currentDate._d + "")
                                                        ) {
                                                            classes += " middle-date";
                                                        } else if (
                                                            dataAgendamentoFinal &&
                                                            dataAgendamentoFinal._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " end-date";
                                                        }
                                                        return (
                                                            <td {...props} className={classes}>
                                                                {currentDate.date()}
                                                            </td>
                                                        );
                                                    }}
                                                    onChange={e => setDataAgendamentoInicial(e)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example-number-input"
                                            >
                                                Fim
                                            </label>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-calendar-grid-58" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder: "Agendamento final",
                                                        style: {
                                                            width: '100px'
                                                        }
                                                    }}
                                                    value={moment(dataAgendamentoFinal)}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat={false}
                                                    renderDay={(props, currentDate, selectedDate) => {
                                                        let classes = props.className;
                                                        if (
                                                            dataAgendamentoInicial &&
                                                            dataAgendamentoFinal &&
                                                            dataAgendamentoInicial._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " start-date";
                                                        } else if (
                                                            dataAgendamentoInicial &&
                                                            dataAgendamentoFinal &&
                                                            new Date(dataAgendamentoInicial._d + "") <
                                                            new Date(currentDate._d + "") &&
                                                            new Date(dataAgendamentoFinal._d + "") >
                                                            new Date(currentDate._d + "")
                                                        ) {
                                                            classes += " middle-date";
                                                        } else if (
                                                            dataAgendamentoFinal &&
                                                            dataAgendamentoFinal._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " end-date";
                                                        }
                                                        return (
                                                            <td {...props} className={classes}>
                                                                {currentDate.date()}
                                                            </td>
                                                        );
                                                    }}
                                                    onChange={e => setDataAgendamentoFinal(e)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                        <Col xs="auto" lg="6" sm="6" md="6" >
                            <fieldset class="border p-2">
                                <legend class="w-auto" style={{ margin: 0 }}>
                                    <label
                                        className="form-control-label"
                                    >
                                        Cadastro
                                    </label>
                                </legend>
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example-number-input"
                                            >
                                                Início
                                            </label>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-calendar-grid-58" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder: "Data inicial",
                                                        style: {
                                                            width: '100px'
                                                        }
                                                    }}
                                                    value={moment(dataCadastroInicial)}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat={false}
                                                    renderDay={(props, currentDate, selectedDate) => {
                                                        let classes = props.className;
                                                        if (
                                                            dataCadastroInicial &&
                                                            dataCadastroFinal &&
                                                            dataCadastroInicial._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " start-date";
                                                        } else if (
                                                            dataCadastroInicial &&
                                                            dataCadastroFinal &&
                                                            new Date(dataCadastroInicial._d + "") <
                                                            new Date(currentDate._d + "") &&
                                                            new Date(dataCadastroFinal._d + "") >
                                                            new Date(currentDate._d + "")
                                                        ) {
                                                            classes += " middle-date";
                                                        } else if (
                                                            dataCadastroFinal &&
                                                            dataCadastroFinal._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " end-date";
                                                        }
                                                        return (
                                                            <td {...props} className={classes}>
                                                                {currentDate.date()}
                                                            </td>
                                                        );
                                                    }}
                                                    onChange={e => setDataCadastroInicial(e)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example-number-input"
                                            >
                                                Fim
                                            </label>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-calendar-grid-58" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder: "Data final",
                                                        style: {
                                                            width: '100px'
                                                        }
                                                    }}
                                                    value={moment(dataCadastroFinal)}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat={false}
                                                    renderDay={(props, currentDate, selectedDate) => {
                                                        let classes = props.className;
                                                        if (
                                                            dataCadastroInicial &&
                                                            dataCadastroFinal &&
                                                            dataCadastroInicial._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " start-date";
                                                        } else if (
                                                            dataCadastroInicial &&
                                                            dataCadastroFinal &&
                                                            new Date(dataCadastroInicial._d + "") <
                                                            new Date(currentDate._d + "") &&
                                                            new Date(dataCadastroFinal._d + "") >
                                                            new Date(currentDate._d + "")
                                                        ) {
                                                            classes += " middle-date";
                                                        } else if (
                                                            dataCadastroFinal &&
                                                            dataCadastroFinal._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " end-date";
                                                        }
                                                        return (
                                                            <td {...props} className={classes}>
                                                                {currentDate.date()}
                                                            </td>
                                                        );
                                                    }}
                                                    onChange={e => setDataCadastroFinal(e)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col xs="12" lg="4" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Título
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    placeholder="Título da publicação..."
                                    type="text"
                                    value={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12" lg="2" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input"
                                >
                                    Situações
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        multiple
                                        defaultValue="A"
                                        onSelect={(e) => handleMultipleSelect(e.target, setSituacoes)}
                                        onUnselect={(e) => handleMultipleSelect(e.target, setSituacoes)}
                                        options={{
                                            placeholder: "Selecione uma situação...."
                                        }}
                                        value={situacoes}
                                        data={
                                            situacoesList.map((item) => ({ id: item.id, text: item.label }))
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="12" lg="3" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input" >
                                    Fases
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        multiple
                                        defaultValue="-1"
                                        onSelect={(e) => handleMultipleSelect(e.target, setFases)}
                                        onUnselect={(e) => handleMultipleSelect(e.target, setFases)}
                                        options={{
                                            placeholder: "Selecione a fase..."
                                        }}
                                        value={fases}
                                        data={
                                            fasesList.map((item) => ({ id: item.id, text: item.nome }))
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="12" lg="3" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input" >
                                    Destinos
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        multiple
                                        onSelect={(e) => handleMultipleSelect(e.target, setDestinos)}
                                        onUnselect={(e) => handleMultipleSelect(e.target, setDestinos)}
                                        options={{
                                            placeholder: "Selecione o destino..."
                                        }}
                                        value={destinos}
                                        data={
                                            destinosList.map((item) => ({ id: item.id, text: item.nome }))
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" lg="3" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input"
                                >
                                    Tráfegos
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        multiple
                                        onSelect={(e) => handleMultipleSelect(e.target, setTrafegos)}
                                        onUnselect={(e) => handleMultipleSelect(e.target, setTrafegos)}
                                        options={{
                                            placeholder: "Selecione o tráfego..."
                                        }}
                                        value={trafegos}
                                        data={
                                            trafegosList.map((item) => ({ id: item.id, text: item.nome }))
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="12" lg="3" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input" >
                                    Pilares
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        multiple
                                        defaultValue="-1"
                                        onSelect={(e) => handleMultipleSelect(e.target, setPilares)}
                                        onUnselect={(e) => handleMultipleSelect(e.target, setPilares)}
                                        options={{
                                            placeholder: "Selecione o pilar..."
                                        }}
                                        value={pilares}
                                        data={
                                            pilaresList.map((item) => ({ id: item.id, text: item.nome }))
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        {byanuncio &&
                            <Col xs="12" lg="3" sm="6" md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input" >
                                    </label>
                                    <div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="customCheck1" checked={naoVinculadas} onChange={({ target }) => setNaoVinculadas(target.checked)}></input>
                                            <label class="custom-control-label" for="customCheck1">Não Vinculadas</label>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        }
                        {children}
                    </Row>
                </CardBody>
            </Filters>

        </>
    );
}
