import React, { useState, useEffect, useRef, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
import FasesNavigation from '../FasesNavigation'
import ComentarioButton from '../ComentarioButton'
import PonderarButton from '../PonderarButton';
import NotificationAlert from "react-notification-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Historico from "../Historico";
import ReactHtmlParser from 'react-html-parser';
import { TextContainer, ButtonHistorico } from './styles'
import {
    InputGroupAddon,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    InputGroup,
    Card,
    Badge,
    CardHeader,
    CardBody,
    CardTitle,
    Modal,
    Row,
    Col,
    UncontrolledTooltip,
    Button
} from "reactstrap";


export default function Publicacoes({
    isOpen,
    publicacaoId,
    readOnly = false,
    showHistorico = false,
    ...props
}) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [copyLink, setCopyLink] = useState(false)
    const [showModalHistorico, setShowModalHistorico] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [items, setItems] = useState([])

    const [publicacao, setPublicacao] = useState({})
    const [alert, setAlert] = useState(null)

    const notification = useRef()

    useEffect(() => {
        setCopyLink(false)
        if (publicacao?.imagens) setItems(publicacao.imagens.filter(item => item.is_criacao))
        else setItems([])
    }, [publicacao])

    useEffect(() => {
        if (publicacaoId) findPublicacao(publicacaoId)
    }, [publicacaoId])


    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }



    async function findPublicacao(publicacaoId) {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}`)
            setPublicacao(await response.data)
        } catch (err) {
            console.error(`Não foi possível carregar a publicação ${publicacaoId}`)
        }
    }

    function onError(msg) {
        showNotify('danger', msg)
    }

    function showNotify(type, msg, place = 'tc') {
        let options = {
            place,
            message: (
                <div className="alert-text">
                    <span data-notify="message">{msg}</span>
                </div>
            ),
            type,
            autoDismiss: 7
        };
        notification.current.notificationAlert(options);
    }

    function situacaoRenderer(publicacao) {
        let situacao = ''
        let color = 'secondary'
        switch (publicacao.situacao) {
            case 'A':
                situacao = 'Ativo'
                color = 'success'
                break;
            case 'C':
                situacao = 'Cancelada'
                color = 'danger'
                break;
            case 'P':
                situacao = 'Ponderada'
                color = 'warning'
                break;
        }
        return (
            <>
                <Badge
                    style={{
                        marginLeft: 4
                    }}
                    title='Situação'
                    color={color}
                    className="badge-lg"
                >
                    {situacao}
                </Badge>
            </>
        )
    }

    async function navigate(where) {
        try {
            const url = `/common/empresas/${empresaSelecionada?.id}/publicacoes/operacao/navegar?where=${where}&current=${publicacao.id}`
            const response = await api.get(url)
            const newPublicacao = response.data
            if (newPublicacao) {
                findPublicacao(newPublicacao.id)
            } else {
                if (where === '') {
                    //Se navegou avulso...
                    showNotify('success', 'Não existem publicações para visualizar no momento')
                    props.close()
                } else {
                    showNotify('warning', 'Nenhuma publicação encontrada')
                }
            }
        } catch (err) {
            onError('Não foi possível carregar outra publicação')
        }
    }

    function onChangePublicacao() {
        findPublicacao(publicacao.id)
        if (props.onChangePublicacao) {
            props.onChangePublicacao()
        }
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                {
                    item.is_local
                        ? <img src={item.is_local ? item.url : item.path} class="d-block w-100" />
                        :
                        <div style={{
                            display: 'flex',
                            minHeight: 200,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 50
                        }}>
                            <InputGroup style={{ justifyContent: 'center' }}>

                                <InputGroupAddon addonType="append">
                                    <Button
                                        href={item.path}
                                        target="_blank"
                                        color='primary'
                                        size={'sm'}
                                    >
                                        <i class="fas fa-link mr-2"></i>
                                        Abrir link externo
                                    </Button>
                                    <CopyToClipboard
                                        text={item.path.replace(/<br\s*\\?>/g, '\r\n').replace(/<[^>]*>/g, '')}
                                        onCopy={() => setCopyLink(!copyLink)}
                                    >
                                        <Button
                                            id='button-copy-to-clipboard'
                                            color='primary'
                                            outline
                                            size={'sm'}
                                        >
                                            <i class="fas fa-paste"></i>
                                        </Button>

                                    </CopyToClipboard>
                                    <UncontrolledTooltip
                                        delay={0}
                                        trigger="hover focus"
                                        target='button-copy-to-clipboard'
                                    >
                                        {copyLink
                                            ? "Copiado"
                                            : "Copiar link"}
                                    </UncontrolledTooltip>
                                </InputGroupAddon>
                            </InputGroup>

                        </div>
                }
            </CarouselItem>
        );
    });

    function handleCopyToClipboard() {
        showNotify('success', 'Copiado')
    }

    function handleClosePreview() {
        props.close()
        setPublicacao({})
    }

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={showModalHistorico}
                toggle={() => setShowModalHistorico(!showModalHistorico)}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Histórico
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setShowModalHistorico(!showModalHistorico)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Historico
                        publicacaoId={publicacao.id}
                        notify={(type, msg) => onError(msg)}
                    />
                </div>
            </Modal>
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={isOpen}
                toggle={() => handleClosePreview()}
            >
                {alert}
                <NotificationAlert ref={notification} />
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader
                            style={{
                                border: "1 solid",
                                boxShadow: "0px 2px #f5f5f5",
                                background: '#F7FAFC',
                            }}
                        >
                            <Row>
                                <Col xs="12" >
                                    <button
                                        style={{
                                            float: "right"
                                        }}
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={() => handleClosePreview()}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>
                                </Col>
                            </Row>

                            <CardTitle
                                className='text-center'
                            >
                                <Row>
                                    <Col xs="2">
                                        <Button
                                            hidden={readOnly}
                                            color="secondary"
                                            onClick={() => navigate('back')}
                                            outline type="button">
                                            <i class="fas fa-chevron-left"></i>
                                        </Button>
                                    </Col>
                                    <Col xs="8">
                                        {publicacao.titulo}
                                    </Col>
                                    <Col xs="2">
                                        <Button
                                            hidden={readOnly}
                                            color="secondary"
                                            onClick={() => navigate('next')}
                                            outline type="button">
                                            <i class="fas fa-chevron-right"></i>
                                        </Button>
                                    </Col>
                                </Row>

                            </CardTitle>
                            {
                                !readOnly &&
                                <>
                                    <Row>
                                        <Col md={12} className="text-center mb-4">
                                            {
                                                publicacao.id &&
                                                <FasesNavigation
                                                    publicacao={publicacao}
                                                    hideBack={true}
                                                    nextColor='success'
                                                    onChangeSuccess={(publicacao) => {
                                                        onChangePublicacao()
                                                        showNotify('success', 'Publicação aprovada')
                                                        navigate('')
                                                    }}
                                                />
                                            }
                                            {
                                                showHistorico &&
                                                <ButtonHistorico
                                                    color="link"
                                                    title="Histórico"
                                                    onClick={() => setShowModalHistorico(!showModalHistorico)}
                                                >
                                                    <i class="far fa-clock"></i>
                                                </ButtonHistorico>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} className='text-center'>
                                            <PonderarButton
                                                publicacao={publicacao}
                                                buttonSize='sm'
                                                onSuccess={() => {
                                                    onChangePublicacao()
                                                    showNotify('success', 'Ponderação adicionada')
                                                    navigate('')
                                                }}
                                                onError={(msg) => onError(msg)}
                                                alert={(alert) => setAlert(alert)}
                                            />
                                        </Col>
                                        <Col xs={4} className='text-center'>
                                            {situacaoRenderer(publicacao)}

                                        </Col>
                                        <Col xs={4} className='text-center'>
                                            <ComentarioButton
                                                style={{
                                                    paddingRight: 4
                                                }}
                                                onSuccess={(msg) => onChangePublicacao()}
                                                publicacao={publicacao}
                                                onError={(msg) => onError(msg)}
                                            />

                                        </Col>
                                    </Row>
                                </>
                            }
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Row>
                                <Col>
                                    <Carousel
                                        activeIndex={activeIndex}
                                        next={next}
                                        previous={previous}
                                    >
                                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                                        {slides}
                                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                                        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                                    </Carousel>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    padding: 10
                                }}
                            >
                                <Col>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}
                                    >

                                        {
                                            publicacao.destinos ?
                                                publicacao.destinos.map((item) => {
                                                    return <div
                                                        key={item.id}
                                                        style={{
                                                            padding: 4
                                                        }}
                                                    >
                                                        <i
                                                            id={`preview_destino_${item.id}`}
                                                            class={`${item.icone} btn-icon-only rounded-circle btn btn-primary`}
                                                            color='primary'
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        ></i>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            placement="top"
                                                            target={`preview_destino_${item.id}`}
                                                        >
                                                            {item.nome}
                                                        </UncontrolledTooltip>
                                                    </div>
                                                })
                                                :
                                                ''
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {empresaSelecionada && empresaSelecionada.id && empresaSelecionada.cabecalho_publicacao && publicacao?.show_cabecalho_padrao &&
                                <Row>
                                    <Col>
                                        <CopyToClipboard
                                            text={empresaSelecionada?.cabecalho_publicacao?.replace(/<[^>]*>?/gm, '')}
                                            onCopy={handleCopyToClipboard}
                                        >
                                            <TextContainer
                                                style={{
                                                    paddingTop: 10
                                                }}
                                                title='Copiar texto do cabeçalho'
                                                className='text-body'
                                            >
                                                {
                                                    ReactHtmlParser(empresaSelecionada.cabecalho_publicacao)
                                                }
                                            </TextContainer>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                            }

                            <Row>
                                <Col>
                                    <CopyToClipboard
                                        text={publicacao?.corpo_texto?.replace(/<[^>]*>?/gm, '')}
                                        onCopy={handleCopyToClipboard}
                                    >
                                        <TextContainer
                                            style={{
                                                paddingTop: 10
                                            }}
                                            title='Copiar texto da legenda'
                                            className='text-body'
                                        >
                                            {
                                                ReactHtmlParser(publicacao.corpo_texto)
                                            }
                                        </TextContainer>
                                    </CopyToClipboard>
                                </Col>
                            </Row>

                            {empresaSelecionada && empresaSelecionada.id && empresaSelecionada.rodape_publicacao && publicacao?.show_rodape_padrao &&
                                <Row>
                                    <Col>
                                        <CopyToClipboard
                                            text={empresaSelecionada?.rodape_publicacao?.replace(/<[^>]*>?/gm, '')}
                                            onCopy={handleCopyToClipboard}
                                        >
                                            <TextContainer
                                                style={{
                                                    paddingTop: 10
                                                }}
                                                title='Copiar texto do rodapé'
                                                className='text-body'
                                            >
                                                {
                                                    ReactHtmlParser(empresaSelecionada.rodape_publicacao)
                                                }
                                            </TextContainer>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                            }

                            <Row>
                                <Col>
                                    <CopyToClipboard
                                        text={publicacao.marcacoes}
                                        onCopy={handleCopyToClipboard}
                                    >
                                        <TextContainer
                                            style={{
                                                paddingTop: 10
                                            }}
                                            title='Copiar marcações'
                                            className={'text-primary'}
                                        >
                                            {
                                                publicacao.marcacoes
                                            }
                                        </TextContainer>
                                    </CopyToClipboard>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </>
    );
}
