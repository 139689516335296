import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../../../../contexts/Auth";

import api from "../../../../../../services/api";

import RestricaoEquipe from "./RestricaoEquipe";
import { MenuComportamento } from "../../../../../../components/Menus";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Badge, Button, ButtonGroup, Row, Col, Modal } from "reactstrap";

import classnames from "classnames";

import Users from "./Users";
import Perfis from "../../../../../security/Users/empresas/perfis";
import EmpresaContext from "../../../../../../contexts/Empresa";
import { EquipeTrabalho } from "./EquipeTrabalho";

export default ({ notify, empresa, equipes, loadEquipes, ...props }) => {
  const { auth } = useContext(AuthContext);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [colaboradores, setColaboradores] = useState([]);
  const [colaborador, setColaborador] = useState(null);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const [situacao, setSituacao] = useState("ativo");
  const [situacaoNaEmpresa, setSituacaoNaEmpresa] = useState("A");

  const [colaboradorSelecionado, setColaboradorSelecionado] = useState(null);

  const [showRestricaoEquipe, setShowRestricaoEquipe] = useState(undefined);
  const [showEquipesTrabalho, setShowEquipesTrabalho] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showPerfis, setShowPerfis] = useState(false);
  // const [equipes, setEquipes] = useState([]);

  const [columns] = useState([
    {
      dataField: "acoes",
      formatter: (cell, row) => acoesFormatter(cell, row),
    },
    {
      dataField: "name",
      text: "Nome",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "userEmpresa",
      text: "Situação",
      sort: true,
      formatter: (cell, row) => situacaoFormatter(cell, row),
    },
    {
      dataField: "roles",
      text: "Perfis",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => rolesFormaters(cell, row),
    },
  ]);

  // useEffect(() => {
  //   if (colaboradores.length === 0) loadColaboradores();
  // }, []);

  useEffect(() => {
    loadColaboradores();
  }, [situacaoNaEmpresa]);

  // useEffect(() => {
  //   if (equipes.length === 0) loadEquipes();
  // }, [empresa]);

  // async function loadEquipes() {
  //   try {
  //     const response = await api.get(`/common/empresas/${empresa.id}/equipes`);
  //     const equipesCaptacao = response.data.filter(
  //       (equipe) => equipe.captadora == "I"
  //     );
  //     setEquipes(equipesCaptacao);
  //   } catch (error) {
  //     console.error(error);
  //     notify("danger", "Não foi possível carregar equipes");
  //   }
  // }

  async function loadColaboradores() {
    try {
      let url = new URLSearchParams();
      situacao && url.set("situacao", situacao);
      situacaoNaEmpresa && url.set("situacaoNaEmpresa", situacaoNaEmpresa);
      const { data } = await api.get(
        `/common/empresas/${
          empresaSelecionada?.id
        }/colaboradores?${url.toString()}`
      );

      if (data) {
        setColaboradores(data);
      }
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar os colaboradores");
    }
  }

  function rolesFormaters(cell, row) {
    return (
      <>
        {row.roles.map((item, key) => (
          <>
            <Badge key={key} className="badge-default" pill>
              {item.name}
            </Badge>
          </>
        ))}
      </>
    );
  }

  function situacaoFormatter(cell, row) {
    return row.userEmpresas[0].situacao == "I" ? "Inativo" : "Ativo";
  }

  async function updateColaboradorSituacao(userId, situacaoAtual) {
    setLoading(true);
    try {
      await api.patch(
        `/common/empresas/${empresa.id}/colaboradores/${userId}/${situacaoAtual}`
      );
      loadColaboradores();

      notify("success", "Colaborador removido");
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível remover colaborador");
    }
    setLoading(false);
    setAlert(null);
  }

  function handleSituacaoColaboradorNaEmpresa(colaborador) {
    const isActive = colaborador.userEmpresas[0].situacao == "A";

    confirmAlert(
      `Deseja realmente inativar ${colaborador.name} na empresa ${empresa.nome}? Antes de prosseguir, certifique-se de que ${colaborador.name} não está atualmente associado(a) a nenhuma equipe, programa de indicação ou possui oportunidades em aberto no sistema. Isso nos ajudará a garantir que o processo de remoção seja realizado corretamente, evitando impactos indesejados.`,
      "danger",
      () => updateColaboradorSituacao(colaborador.id, isActive ? "I" : "A")
    );
  }

  function handleEditPerfil(colaborador) {
    setColaboradorSelecionado(colaborador);
    setShowPerfis(true);
  }

  function handleEditEquipe(colaborador) {
    setColaboradorSelecionado(colaborador);
    setShowEquipesTrabalho(true);
  }

  function confirmAlert(message, alertColor, onConfirm) {
    setAlert(
      <Modal
        className={`modal-dialog-centered modal-${alertColor}`}
        contentClassName={`bg-gradient-${alertColor}`}
        isOpen={true}
        toggle={() => setAlert(null)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Atenção
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={setAlert(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="fas fa-question-circle ni-3x" />
            <br />
            <br />
            <p>{message}</p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            type="button"
            onClick={onConfirm}
          >
            Sim
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setAlert(null)}
          >
            Fechar
          </Button>
        </div>
      </Modal>
    );
  }

  function acoesFormatter(cell, row) {
    const isActive = row.userEmpresas[0].situacao == "A";
    const actions = [
      {
        label: "Editar Perfis",
        icon: "fas fa-user-tag",
        onClick: () => handleEditPerfil(row),
      },
      {
        label: "Equipe de Trabalho",
        icon: "fas fa-users",
        onClick: () => handleEditEquipe(row),
      },
      {
        label: "Restrição de equipes",
        icon: "fas fa-times",
        onClick: () => setShowRestricaoEquipe(row),
      },
      {
        label: isActive ? "Inativar" : "Ativar",
        icon: isActive ? "fas fa-trash" : "fas fa-plus",
        onClick: () => handleSituacaoColaboradorNaEmpresa(row),
      },
    ];

    return (
      <>
        <MenuComportamento direction="right" actions={actions} />
      </>
    );
  }

  function clienteOptions() {
    const option = {
      placeholder: "Selecione um usuário para ser um colaborador...",
      width: 300,
    };
    option["ajax"] = {
      url: `${process.env.REACT_APP_API_URL}/common/empresas/${empresa.id}/colaboradores?withNotEmpresa=true`,
      dataType: "json",
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
      processResults: function (data) {
        return {
          results: data.map((item) => ({
            id: item.id,
            text: `${item.name}, ${item.email}`,
          })),
        };
      },
    };
    return option;
  }

  function openAddUsers() {
    setShowUsers(true);
  }

  function hideUsers() {
    setShowUsers(false);
  }

  function hidePerfis() {
    setColaboradorSelecionado(null);
    setShowPerfis(false);
  }

  const situacoes = [
    { id: "", text: "Todos" },
    { id: "A", text: "Ativos" },
    { id: "I", text: "Inativos" },
  ];

  return (
    <>
      <div className="py-0 table-responsive overflow-hidden">
        <RestricaoEquipe
          notify={notify}
          empresa={empresa}
          show={!!showRestricaoEquipe}
          hidde={() => setShowRestricaoEquipe(undefined)}
          user={showRestricaoEquipe}
        />

        <Users
          empresa={empresa}
          hide={hideUsers}
          notify={notify}
          show={showUsers}
          loadColaboradores={loadColaboradores}
        />

        <Perfis
          empresa={empresa}
          hide={hidePerfis}
          notify={notify}
          show={showPerfis}
          user={colaboradorSelecionado}
          naoMostrarInvisiveis={true}
          loadColaboradores={loadColaboradores}
        />

        <EquipeTrabalho
          isOpen={showEquipesTrabalho}
          colaborador={colaboradorSelecionado}
          loadEquipes={loadEquipes}
          equipes={equipes.filter(equipe => equipe.captadora == 'I')}
          onClose={() => setShowEquipesTrabalho(false)}
        />

        {alert}
        <div
          style={{
            display: "flex",
          }}
        >
          <Row>
            <Col lg={12} md={12}>
              <Button
                color="primary"
                type="button"
                // outline
                // onClick={showUsers(true)}
                onClick={openAddUsers}
                className="btn-icon btn-3"
                size="sm"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add"></i>
                </span>
                <span className="btn-inner--text">Novo</span>
              </Button>
            </Col>
          </Row>
        </div>

        {/* ================ */}
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            marginBottom: -10,
          }}
        >
          <ButtonGroup
            className="btn-group-toggle mt-4 py--4"
            size="sm"
            data-toggle="buttons"
          >
            {situacoes.map((item, key) => (
              <Button
                key={key}
                className={classnames({ active: situacaoNaEmpresa == item.id })}
                color="secondary"
                onClick={() => setSituacaoNaEmpresa(item.id)}
              >
                {item.text}
              </Button>
            ))}
          </ButtonGroup>
        </div>

        {/* =========================== */}
        <Row>
          <Col className="col-12">
            <ToolkitProvider
              data={colaboradores}
              keyField="name"
              columns={columns}
              search
            >
              {(props) => (
                <div className="py-4">
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
      </div>
    </>
  );
};
