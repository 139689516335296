import React from 'react';
import api from "../../../../../../../services/api";

import {
    Button,
    ListGroupItem,
    ListGroup,
    Row,
    Col
} from "reactstrap";

function List({ motivosEncerramentos, empresa, reload = () => { }, notify }) {

    async function handleDelete(motivoEncerramento) {
        if (window.confirm(`Deseja remover o motivo encerramento  ${motivoEncerramento.nome}?`)) {
            try {
                await api.delete(`/common/empresas/${empresa.id}/motivos-encerramentos/${motivoEncerramento.id}`);
                notify("success", "Motivo para encerramento removido");
                reload();
            } catch (error) {
                console.error(error);
                notify("danger", "Não foi possível remover o motivo");
            }
        }
    }

    return (
        <>
            <ListGroup className="list mt-4" flush>
                {
                    motivosEncerramentos.map((motivoEncerramento, key) => (
                        <ListGroupItem
                            className="list-group-item-action px-0"
                            style={{ flexDirection: 'row', display: 'flex', flex: 1, alignItems: 'center' }}
                            key={key}>
                            <div className="col-auto">
                                <Button
                                    className="btn-sm"
                                    color="danger"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleDelete(motivoEncerramento)
                                    }}
                                >
                                    <i class="fas fa-trash"></i>
                                </Button>
                            </div>
                            <Row
                                style={{ flex: 1 }}
                                className="align-items-center">
                                <div className="col">
                                    <h4 className="mb-0">
                                        <a href="#" onClick={e => e.preventDefault()}>
                                            {motivoEncerramento.nome}
                                        </a>
                                    </h4>
                                </div>
                                <Col className='col-12'>
                                    <small>
                                        {
                                            !motivoEncerramento.aplicacao ?
                                                'Exibido como motivo de encerramento e ponto de checagem das oportunidades.'
                                                :
                                                motivoEncerramento.aplicacao === 'encerramento' ?
                                                    'Exibido como motivo de encerramento das oportunidades.'
                                                    :
                                                    'Exibido como ponto de checagem nas oportunidades.'
                                        }
                                    </small>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    ))
                }
            </ListGroup>
        </>
    )
}

export default List;