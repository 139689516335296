import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'

import api from "../../../../../services/api";

import ModalObjetivos from "../../../../../components/timeLines/Pdca/Estrategias/Item/ModalObjetivos";

import { MenuComportamento } from "../../../../../components/Menus";
import { PlanoEstrategico } from '../../../../../entities/Mentoring';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import Form from './Form'
import Status from './Status'

import {
    Badge,
    Button,
    Row,
    UncontrolledPopover,
    PopoverBody,
    Col,
    Modal,
} from "reactstrap";


// import { Container } from './styles';

// import { Container } from './styles';
interface Props {
    planoEstrategico: PlanoEstrategico,
    notify(type: string, msg: string): void,
    history: any
}

const QuestoesAlvo: React.FC<Props> = ({ planoEstrategico, notify, history }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [questoesAlvo, setQuestoesAlvo] = useState([])
    const [status, setStatus] = useState('')

    const [questaoAlvoSelecionada, setQuestaoAlvoSelecionada] = useState(null)

    const [showQuestoesAlvo, setShowQuestoesAlvo] = useState(true)

    const [showModalFormQuestao, setShowModalFormQuestao] = useState(false)
    const [showModalObjetivo, setShowModalObjetivo] = useState(false)

    const [columns] = useState([
        {
            dataField: 'acoes',
            formatter: (cell: any, row: any) => acoesFormatter(cell, row)
        },
        {
            dataField: 'nome',
            text: 'Nome',
            sort: true
        },
        {
            dataField: 'created_at',
            text: 'Criação',
            sort: true,
            formatter: (cell: any, row: any) => dateFormatter(row.created_at),
            align: 'center',
            headerAlign: 'center'
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: (cell: any, row: any) => statusFormater(row),
            align: 'center',
            headerAlign: 'center'
        }
    ])

    useEffect(() => {
        if (planoEstrategico && planoEstrategico.id) loadQuestoesAlvo()
    }, [planoEstrategico, status])


    useEffect(() => {
        if (!showModalFormQuestao) setQuestaoAlvoSelecionada(null)
    }, [showModalFormQuestao])


    const dateFormatter = (date: any) => {
        const dt = new Date(date)
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return (
            <>
                {/* @ts-ignore */}
                {dt.toLocaleDateString('pt-br', options)}
            </>
        )
    }

    const statusFormater = (row: any) => (
        <>
            <Badge
                id={`denc-${row.id}`}
                color={getColorStatus(row)}
                className="badge-lg"
                pill
            >
                {getStatus(row)}
            </Badge>
        </>
    )

    function getStatus(row: any) {
        var status = 'Não identificado'

        if (row) {
            if (row.status == 'A') {
                status = 'Alcançado'
            } else {
                status = 'Não Alcançado'
            }
        }

        return status
    }

    function getColorStatus(row: any) {
        var color = 'secondary'

        if (row) {
            if (row.status == 'A') {
                color = 'success'
            } else {
                //color = 'danger'
                color = 'warning'
            }
        }

        return color
    }

    // function goTo({ opcao, anuncio }) {
    //     switch (opcao) {
    //         case 'publicacao':
    //             editPublicacao(anuncio)
    //             break;
    //         case 'produtos':
    //             //setAnuncioSelecionado(anuncio);
    //             setShowProdutos(true)
    //             break
    //         case 'equipes':
    //             setShowEquipes(anuncio)
    //             break
    //         default:
    //             break;
    //     }
    // }

    function handleNew(questao: any) {
        history.push(`/admin/pdcas/new`, { toQuestaoAlvo: questao })
    }

    function viewHistory(questao: any) {
        setQuestaoAlvoSelecionada(questao)
        setShowModalFormQuestao(false)
        setShowModalObjetivo(true)
    }

    function acoesFormatter(cell: any, row: any) {
        return (
            <MenuComportamento
                actions={[{
                    label: 'Alterar',
                    icon: 'far fa-edit',
                    onClick: () => edit(row)
                },
                //  {
                //     label: 'Objetivos',
                //     icon: 'ni ni-album-2',
                //     onClick: () => editObjetivos(row)
                // },
                {
                    label: ' Novo PDCA',
                    icon: 'far fa-plus-square',
                    onClick: () => handleNew(row)
                },
                {
                    label: 'Ver Histórico',
                    icon: 'far fa-eye',
                    onClick: () => viewHistory(row),
                }]}
            />
        )
    }

    function handleOnBack() {
        history.push(`/admin/campanhas`);
    }

    function editObjetivos(questao: any) {
        setQuestaoAlvoSelecionada(questao)
        setShowModalFormQuestao(false)
        setShowModalObjetivo(true)
    }

    function handlerGoBackEditPublicacao() {
        //setShowPublicacao(true)
        setShowQuestoesAlvo(true)
        setShowModalObjetivo(false)
        setQuestaoAlvoSelecionada(null)
    }

    function edit(questaoAlvo: any) {
        setQuestaoAlvoSelecionada(questaoAlvo)
        setShowModalFormQuestao(true)
    }


    function handleSaveQuestao() {
        notify('success', 'Questão Alvo Salva')
        loadQuestoesAlvo()
        setShowModalFormQuestao(false)
    }


    async function loadQuestoesAlvo() {
        try {
            const response = await api.get(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos/${planoEstrategico?.id}/questoes-alvo`,
                {
                    params: {
                        status: status || ''
                    }
                })
            if (response.data) {
                setQuestoesAlvo(response.data)
            }
        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível carregar as questões-alvos')
        }
    }

    return (
        <>

            <div
                style={{
                    display: (showQuestoesAlvo ? 'flex' : 'none'),
                    flex: 1,
                    justifyContent: 'center'
                }}
            >
                <Button
                    color="primary"
                    type="button"
                    className="btn-icon btn-3"
                    onClick={() => setShowModalFormQuestao(!showModalFormQuestao)}
                >
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Nova Questão-Alvo</span>
                </Button>

                <ModalObjetivos
                    isOpen={showModalObjetivo}
                    questaoAlvo={questaoAlvoSelecionada}
                    //@ts-ignore
                    objetivos={questaoAlvoSelecionada?.objetivos}
                    hide={() => setShowModalObjetivo(false)}
                />

                <Modal
                    size="lg"
                    className="modal-dialog-centered"
                    isOpen={showModalFormQuestao}
                    backdrop='static'
                    toggle={() => setShowModalFormQuestao(!showModalFormQuestao)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {questaoAlvoSelecionada ? 'Alterar Questão-Alvo' : 'Nova Questão-Alvo'}
                            <br />
                            <small className="text-muted"
                                title={`Código Questão-Alvo`}>
                                {/* @ts-ignore */}
                                {questaoAlvoSelecionada ? "#" + questaoAlvoSelecionada.id : ""}
                            </small>
                        </h5>

                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowModalFormQuestao(!showModalFormQuestao)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            notify={notify}
                            history={history}
                            //@ts-ignore
                            goTo={(opcao) => goTo({ opcao, anuncio: questaoAlvoSelecionada })}
                            //@ts-ignore
                            questaoId={questaoAlvoSelecionada?.id}
                            onSuccess={loadQuestoesAlvo}
                            close={() => setShowModalFormQuestao(false)}
                            planoEstrategicoId={planoEstrategico.id}
                        />
                    </div>
                </Modal>

            </div>
            <div
                style={{
                    display: (showQuestoesAlvo ? 'flex' : 'none'),
                    flex: 1,
                    justifyContent: 'flex-end',
                    marginBottom: -10
                }}
            >
                <Status
                    value={status}
                    onChange={setStatus}
                />
            </div>
            <Row
                style={{
                    display: (showQuestoesAlvo ? 'flex' : 'none'),
                }}>
                <Col>
                    <ToolkitProvider
                        data={questoesAlvo}
                        keyField="nome"
                        //@ts-ignorets
                        columns={columns}
                        search
                    >
                        {props => (
                            <div className="table-responsive py-4">
                                <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                </Col>
            </Row>
        </>
    );

}

export default QuestoesAlvo;