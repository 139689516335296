import React, { useEffect } from "react";
import { Col, FormGroup, InputGroup } from "reactstrap";
import Select2 from "react-select2-wrapper";

interface ColaboradorSelectProps {
  hasPermission: (permission: string) => boolean;
  equipes: any; // Defina o tipo apropriado para equipes
  setEquipes: (equipes: any) => void; // Defina o tipo apropriado para setEquipes
  equipesList: any[]; // Defina o tipo apropriado para equipesList
  handleMultipleSelect: (target: any, setter: (value: any) => void) => void;
  pesquisarColaboradorCriador: boolean;
  setPesquisarColaboradorCriador: (value: boolean) => void;
  colaboradores: any; // Defina o tipo apropriado para colaboradores
  setColaboradores: (colaboradores: any) => void; // Defina o tipo apropriado para setColaboradores
  users: any[]; // Defina o tipo apropriado para users
}

const ColaboradorEquipeSelect: React.FC<ColaboradorSelectProps> = ({
  hasPermission,
  equipes,
  setEquipes,
  equipesList,
  handleMultipleSelect,
  pesquisarColaboradorCriador,
  setPesquisarColaboradorCriador,
  colaboradores,
  setColaboradores,
  users,
}) => {
  if (hasPermission("ver-todas-oportunidades")) {
    return (
      <>
        <Col xs="12" lg="4" sm="12" md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example-number-input"
            >
              Equipes
            </label>
            <InputGroup className="input-group-alternative">
              <Select2
                multiple
                onSelect={({ target }) =>
                  handleMultipleSelect(target, setEquipes)
                }
                onUnselect={({ target }) =>
                  handleMultipleSelect(target, setEquipes)
                }
                options={{
                  placeholder: "Selecione...",
                }}
                value={equipes}
                data={equipesList.map((item) => ({
                  id: item.id,
                  text: item.nome,
                }))}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs="12" lg="4" sm="12" md="6">
          <FormGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <label className="form-control-label">Colaborador</label>
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="check-colaborador"
                  type="checkbox"
                  checked={pesquisarColaboradorCriador}
                  onChange={() =>
                    setPesquisarColaboradorCriador(!pesquisarColaboradorCriador)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="check-colaborador"
                >
                  Registrado Por
                </label>
              </div>
            </div>
            <InputGroup className="input-group-alternative">
              <Select2
                multiple
                onSelect={({ target }) =>
                  handleMultipleSelect(target, setColaboradores)
                }
                onUnselect={({ target }) =>
                  handleMultipleSelect(target, setColaboradores)
                }
                options={{
                  placeholder: "Selecione os colaboradores...",
                }}
                value={colaboradores}
                data={users.map((user) => ({ id: user.id, text: user.name }))}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </>
    );
  }
  return null;
};

export default ColaboradorEquipeSelect;
