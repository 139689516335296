import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../../../contexts/Auth'
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
// reactstrap components
import ReactHtmlParser from 'react-html-parser';
import Pagination from '../../../../../components/Pagination'
import Form from './Form'
import {
    Button,
    Row,
    Col,
    ListGroupItem,
    ListGroup,
    Modal,
} from "reactstrap";


export default ({notaId, notify, readOnly, onAnotacoesChanged, ...props }) => {

    const { user } = useContext(AuthContext)
    const { empresaSelecionada } = useContext(EmpresaContext)
    const [anotacoes, setAnotacoes] = useState([])
    const [anotacaoEmEdicao, setAnotacaoEmEdicao] = useState({})

    const [pageProperties, setPageProperties] = useState({})
    const [showModal, setShowModal] = useState(false)

    
    useEffect(() => {
        if (notaId) loadAnotacoes(pageProperties, notaId)
    }, [notaId])



    useEffect(() => {
        if (onAnotacoesChanged) {
            onAnotacoesChanged(anotacoes)
        }
    }, [anotacoes])

    async function loadAnotacoes({ page = 1, limit = 3 }, notaId) {
        try {
            const response = await api.get(`/mentoring/empresas/${empresaSelecionada?.id}/notas/${notaId}/anotacoes`, {
                params: {
                    page,
                    limit
                }
            })
            setAnotacoes(response.data.data)
            setPageProperties(response.data)
        } catch (error) {
            notify('danger', 'Não foi possivel carregar anotações')
        }
    }

    const formatDate = (date) => {
        const dt = new Date(date)
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        return dt.toLocaleDateString('pt-br', options)
    }

    function removeLocal(comentario) {
        const array = anotacoes.filter(item => item.created_at != comentario.created_at)
        setAnotacoes(array)
    }

    async function remove(item) {
        if (window.confirm("Deseja remover a anotação?")) {
            try {
                if (!notaId) {
                    //Se não estiver salvo, remove localmente
                    removeLocal(item)
                    return
                }
                await api.delete(`/mentoring/empresas/${empresaSelecionada?.id}/notas/${notaId}/anotacoes/${item.id}`);
                notify("success", "Anotação removida");
                loadAnotacoes(pageProperties, notaId)
            } catch (error) {
                console.error(error)
                notify("danger", "Não foi possível remover anotação");
            }
        }
    }

    function handleSave(anotacao) {
        notify('success', 'Anotação Salva')
        //Caso exita notaId, carrega a lista de anotações
        if (notaId) {
            loadAnotacoes(pageProperties, notaId)
            setShowModal(false)
            return
        }

        if (!notaId && anotacao) {
            const copy = anotacoes
            if (anotacaoEmEdicao.descricao) {
                //Se tiver uma anotação em edição, atualiza a mesma
                copy.forEach((item) => {
                    if (item.created_at == anotacaoEmEdicao.created_at) {
                        item.descricao = anotacao.descricao
                    }
                })
            } else {
                //Se nao cria um novo
                const obj = {
                    ...anotacao,
                    created_at: new Date(),
                    user: {
                        ...user
                    }
                }
                copy.push(obj)
            }
            setAnotacoes(copy)
        }
        setShowModal(false)
        setAnotacaoEmEdicao({})
    }

    const createAnotacaoPreview = (item, key) => (
        <>
            <ListGroupItem
                key={key}
                className="list-group-item-action flex-column align-items-start py-4 px-4"
                style={{
                    cursor: 'pointer'
                }}
                href="#"
            >
                <Row>
                    <Col
                        lg={1}
                        sm={2}
                        md={1}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            disabled={readOnly}
                            className="btn-sm"
                            color="danger"
                            onClick={() => remove(item)}
                        >
                            <i class="fas fa-trash"></i>
                        </Button>
                    </Col>
                    <Col
                        onClick={e => {
                            if (readOnly) return
                            if (item.user.id == user?.id) {
                                setAnotacaoEmEdicao(item)
                                setShowModal(true)
                            } else {
                                notify('danger', 'Você não pode alterar esta anotação')
                            }
                        }}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <div>
                                <div className="d-flex w-100 align-items-center">
                                    <i class="far fa-user mr-2"></i>
                                    <h5 className="mb-1">{item.user.name}</h5>
                                </div>
                            </div>
                            <small>{formatDate(item.created_at)}</small>
                        </div>
                        <p className="text-sm mb-0">
                            {
                                ReactHtmlParser(item.descricao)
                            }
                        </p>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    )

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center'
                }}
            >
                <Button
                    disabled={readOnly}
                    color="primary"
                    type="button"
                    className="btn-icon btn-3"
                    size="sm"
                    onClick={() => {
                        if (readOnly) return
                        setShowModal(!showModal)
                        setAnotacaoEmEdicao({})
                    }}
                >
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Nova</span>
                </Button>
                <Modal
                    className="modal-dialog-centered"
                    isOpen={showModal}
                    toggle={() => setShowModal(!showModal)}
                    backdrop="static"
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Adicionar Anotação
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowModal(!showModal)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            notify={notify}
                            notaId={notaId}
                            onSuccess={handleSave}
                            anotacao={anotacaoEmEdicao}
                            close={() => setShowModal(false)}
                        />
                    </div>
                </Modal>
            </div>
            {
                <ListGroup flush className=' py-4 px-4'>
                    {anotacoes?.map((item, key) => (
                        <>
                            {createAnotacaoPreview(item, key)}
                        </>
                    ))}
                </ListGroup>
            }
            {
                notaId && anotacoes && anotacoes.length > 0 &&
                <Pagination
                    pageProperties={pageProperties}
                    load={(pageProperties) => {
                        loadAnotacoes(pageProperties, notaId)
                    }}
                />
            }
        </>
    );
}
