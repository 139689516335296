import React, { useState, useEffect, useContext } from 'react';
import ReactDatetimeClass from 'react-datetime';

import EmpresaContext from "../../../contexts/Empresa";
import api from "../../../services/api";
import { usePersistedState } from '../../../hooks';
import moment from 'moment';

import Select2 from "react-select2-wrapper";
import Filters from '../../../components/Headers/Filters';
import {
    Input,
    Row,
    Col,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
} from "reactstrap";

export default ({ title, notify, load, loadInfo, ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)

    const [empresasList, setEmpresasList] = useState([])
    const [empresasMalasDiretas, setEmpresasMalasDiretas] = usePersistedState('empresasMalasDiretas', [])

    const [userMalasDiretas, setUsersMalasDiretas] = usePersistedState('usersMalasDiretas', [])
    const [usersComboBox, setUsersComboBox] = useState([])

    const [situacaoMalasDiretas, setSituacaoMalasDiretas] = usePersistedState('situacaoMalasDiretas', [])

    const [dataAgendamentoInicialMalasDiretas, setDataAgendamentoInicialMalasDiretas] = usePersistedState('dataAgendamentoInicialMalasDiretas', getDataAgendamentoInicialMalasDiretas())
    const [dataAgendamentoFinalMalasDiretas, setDataAgendamentoFinalMalasDiretas] = usePersistedState('dataAgendamentoFinalMalasDiretas', getDataAgendamentoFinalMalasDiretas())

    //Flag para definir tempo de execução
    const [runLoad, setRunLoad] = useState(props.search ? props.search : true)
    const [monitorClearFilters, setMonitorClearFilters] = useState(undefined)

    useEffect(() => {
        if (monitorClearFilters) search()
    }, [monitorClearFilters])

    useEffect(() => {
        if (runLoad) {
            search()
            setRunLoad(false)
        }
    }, [runLoad])

    useEffect(() => {
        if (empresasList && empresasList.length === 0) loadEmpresas()
    }, [])

    useEffect(() => {
        if (empresasMalasDiretas && empresasMalasDiretas.length > 0) {
            loadUserByEmpresaBox()
        } else {
            loadUser()
        }

    }, [empresasMalasDiretas])

    function getDataAgendamentoInicialMalasDiretas() {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth());
    }

    function getDataAgendamentoFinalMalasDiretas() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    async function loadEmpresas() {
        try {
            const response = await api.get('security/sessions/empresas')
            if (response.data) {
                setEmpresasList(response.data)
            }
        } catch (error) {
            console.log(error)
            throwError('Não foi possível carregar empresas')
        }
    }


    async function loadUser() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/colaboradores`)
            setUsersComboBox(await response.data)
        } catch (error) {
            console.error(error)
        }
    }

    async function loadUserByEmpresaBox() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/colaboradoresbyempresas`,
                {
                    params: {
                        empresa_id: empresasMalasDiretas,
                        empresas: empresasMalasDiretas
                    }
                }
            )
            setUsersComboBox(await response.data)
        } catch (error) {
            console.error(error)
        }
    }

    function throwError(text) {
        if (notify) notify('danger', text)
    }

    async function search() {
        await load({
            empresa_id: empresasMalasDiretas ? empresasMalasDiretas : [],
            colaboradores: userMalasDiretas ? userMalasDiretas : [],
            situacoes: situacaoMalasDiretas ? situacaoMalasDiretas : [],
            data_inicio: dataAgendamentoInicialMalasDiretas,
            data_fim: dataAgendamentoFinalMalasDiretas
        })
    }

    function handleMultipleSelect(target, state) {
        const array = Array.from(target.selectedOptions);
        state(array.map((item) => item.value));
    }

    async function clearFilters() {
        setDataAgendamentoInicialMalasDiretas(getDataAgendamentoInicialMalasDiretas);
        setDataAgendamentoFinalMalasDiretas(getDataAgendamentoFinalMalasDiretas);

        setEmpresasMalasDiretas([]);
        setUsersMalasDiretas([]);
        loadUser();
        setSituacaoMalasDiretas([]);
    }

    function viewInfo() {
        console.log('VIEW INFO FINTER FILHO')
        if(loadInfo){
            loadInfo()
        }
    }

    return (
        <>
            <Filters
                onSearch={search}
                title={title}
                clearFilters={clearFilters}
                onFiltersClead={setMonitorClearFilters}
                visibleButtonInfo={true}
                viewInfo={viewInfo}
            >
                <Row>
                    {/*<Col xs={4}>
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="example-number-input"
                            >
                                Empresas
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    multiple
                                    onSelect={(e) => handleMultipleSelect(e.target, setEmpresasMalasDiretas)}
                                    onUnselect={(e) => handleMultipleSelect(e.target, setEmpresasMalasDiretas)}
                                    options={{
                                        placeholder: "Selecione as Empresas"
                                    }}
                                    value={empresasMalasDiretas}
                                    data=
                                    {
                                        empresasList.sort(function (a, b) { return (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0) }).map((item) => (
                                            {
                                                id: item.id,
                                                text: item.nome
                                            }
                                        ))
                                    }
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>


                    <Col xs={4}>
                        <FormGroup>
                            <Label for="exampleSelect">Colaboradores</Label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    multiple
                                    onSelect={(e) => handleMultipleSelect(e.target, setUsersMalasDiretas)}
                                    onUnselect={(e) => handleMultipleSelect(e.target, setUsersMalasDiretas)}
                                    options={{
                                        placeholder: "Selecione colaboradores"
                                    }}
                                    value={userMalasDiretas}
                                    data={
                                        //usersComboBox.map((item) => ({ id: item.id, text: item.name }))
                                        usersComboBox.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0) }).map((item) => (
                                            {
                                                id: item.id,
                                                text: item.name
                                            }
                                        ))
                                    }
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col> */}

                    <Col xs={4}>
                        <FormGroup>
                            <Label for="exampleSelect">Situação</Label>
                            <Select2
                                className="form-control"
                                //@ts-ignore
                                //onSelect={(e) => setSituacao(e.target.value)}
                                multiple
                                onSelect={(e) => handleMultipleSelect(e.target, setSituacaoMalasDiretas)}
                                onUnselect={(e) => handleMultipleSelect(e.target, setSituacaoMalasDiretas)}
                                value={situacaoMalasDiretas}
                                options={{
                                    placeholder: "Selecione Situações"
                                }}
                                data={[
                                    { id: 'ativo', text: 'Ativo' },
                                    { id: 'inativo', text: 'Inativo' },
                                    { id: 'em-andamento', text: 'Em Andamento' },
                                    { id: 'cancelado', text: 'Cancelado' },
                                    { id: 'concluido', text: 'Concluído' }
                                ]}
                            />
                        </FormGroup>
                    </Col>

                </Row>

                <Row>


                    <Col xs="auto" lg="6" sm="6" md="6">
                        <fieldset className="border p-2">
                            <legend className="w-auto" style={{ margin: 0 }}>
                                <label
                                    className="form-control-label"
                                >
                                    Data Agendamento
                                </label>
                            </legend>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example-number-input"
                                        >
                                            Início
                                        </label>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: "Início",
                                                    style: {
                                                        width: '100px'
                                                    }
                                                }}
                                                //COMENTADO PORQUE É TESTE DE DATA
                                                value={moment(dataAgendamentoInicialMalasDiretas)}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat={false}
                                                renderDay={(props, currentDate, selectedDate) => {
                                                    let classes = props.className;
                                                    if (
                                                        dataAgendamentoInicialMalasDiretas &&
                                                        dataAgendamentoFinalMalasDiretas &&
                                                        dataAgendamentoInicialMalasDiretas._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " start-date";
                                                    } else if (
                                                        dataAgendamentoInicialMalasDiretas &&
                                                        dataAgendamentoFinalMalasDiretas &&
                                                        new Date(dataAgendamentoInicialMalasDiretas._d + "") <
                                                        new Date(currentDate._d + "") &&
                                                        new Date(dataAgendamentoFinalMalasDiretas._d + "") >
                                                        new Date(currentDate._d + "")
                                                    ) {
                                                        classes += " middle-date";
                                                    } else if (
                                                        dataAgendamentoFinalMalasDiretas &&
                                                        dataAgendamentoFinalMalasDiretas._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " end-date";
                                                    }
                                                    return (
                                                        <td {...props} className={classes}>
                                                            {currentDate.date()}
                                                        </td>
                                                    );
                                                }}
                                                onChange={e => setDataAgendamentoInicialMalasDiretas(e)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example-number-input"
                                        >
                                            Fim
                                        </label>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: "Fim",
                                                    style: {
                                                        width: '100px'
                                                    }
                                                }}
                                                value={moment(dataAgendamentoFinalMalasDiretas)}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat={false}
                                                renderDay={(props, currentDate, selectedDate) => {
                                                    let classes = props.className;
                                                    if (
                                                        dataAgendamentoInicialMalasDiretas &&
                                                        dataAgendamentoFinalMalasDiretas &&
                                                        dataAgendamentoInicialMalasDiretas._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " start-date";
                                                    } else if (
                                                        dataAgendamentoInicialMalasDiretas &&
                                                        dataAgendamentoFinalMalasDiretas &&
                                                        new Date(dataAgendamentoInicialMalasDiretas._d + "") <
                                                        new Date(currentDate._d + "") &&
                                                        new Date(dataAgendamentoFinalMalasDiretas._d + "") >
                                                        new Date(currentDate._d + "")
                                                    ) {
                                                        classes += " middle-date";
                                                    } else if (
                                                        dataAgendamentoFinalMalasDiretas &&
                                                        dataAgendamentoFinalMalasDiretas._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " end-date";
                                                    }
                                                    return (
                                                        <td {...props} className={classes}>
                                                            {currentDate.date()}
                                                        </td>
                                                    );
                                                }}
                                                onChange={e => setDataAgendamentoFinalMalasDiretas(e)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>

                </Row>

            </Filters>
        </>
    );

}