import React, { useState, useRef } from "react";
import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";

import Filters from "./Filters";
import Table from "./Table";
import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";
// core components
import SimpleHeader from "../../../components/Headers/SimpleHeader";

export default ({ ...props }) => {
  const [loading] = useState(false);
  const [alert] = useState(null);
  const [listaNps, setListaNps] = useState([]);
  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: false,
    sort: new Map([]),
  });
  const [lastSearch, setLastSearch] = useState({});
  const notificationAlert = useRef();

  function notify(type, msg) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function updatePageProperties(response) {
    const { total, perPage, page, lastPage } = await response.data;
    await setPageProperties({
      ...pageProperties,
      total,
      perPage,
      page,
      lastPage,
      loading: false,
    });
  }

  async function loadListaNps(
    page = 1,
    limit = 10,
    sortField = "created_at",
    sortOrder = "desc",
    filters = lastSearch
  ) {
    try {
      setPageProperties({
        ...pageProperties,
        loading: true,
      });
      const json = localStorage.getItem("@playnee-empresa");
      const empresaSelecionada = JSON.parse(json);
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/nps/`,
        {
          params: {
            page,
            limit,
            sortField,
            sortOrder,
            ...filters,
          },
        }
      );
      setListaNps(response.data);
      await updatePageProperties(response);
    } catch (err) {
      console.log(err.response);
      notify("danger", "Houve um problema ao carregar a Lista de NPS.");
    }
  }

  const handleTableChange = async (
    type,
    { page, sizePerPage, sortField, sortOrder, ...props }
  ) => {
    try {
      if (type == "sort") {
        const value = pageProperties.sort.get(sortField);
        const newOrder = (value || "asc") == "desc" ? "asc" : "desc";
        const map = pageProperties.sort;
        map.set(sortField, newOrder);
        sortOrder = newOrder;
      }
      await loadListaNps(
        page == 0 ? 1 : page,
        sizePerPage,
        sortField,
        sortOrder
      );
    } catch (error) {
      console.created_at(error);
      notify("danger", "Houve um problema ao carregar a Lista de NPS.");
    }
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Listagem de NPS's" parentName="NPS" {...props} />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 100,
                }}
              >
                <Filters
                  title={<h1>NPS's</h1>}
                  load={(filters) => {
                    setLastSearch(filters);
                    loadListaNps(1, 10, "created_at", "desc", filters);
                  }}
                  notify={notify}
                />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Table
                      listaNps={listaNps ? listaNps.data : []}
                      notify={notify}
                      onTableChange={handleTableChange}
                      history={props.history}
                      pageProperties={pageProperties}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
