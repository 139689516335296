
import React, { useMemo, useEffect, useState, useContext } from 'react';
import EmpresaContext from '../../../../contexts/Empresa'
import api from "../../../../services/api";

import ReactBSAlert from "react-bootstrap-sweetalert";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Button,
    Modal,
    Progress,
    Badge,
} from "reactstrap";
import { Dropzone } from '../../../../components/Dropzone';

export default ({ artigo = {},
    onFileListChange,
    notify,
    uploadProgress = {},
    clearAutoReloadList, //Metodo usado para limpar o auto reload da lista de imagens prara evitar loop da pesquisa 
    alert }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [files, setFiles] = useState([]);
    const [preview, setPreview] = useState({
        show: false,
        url: '',
        index: 0
    })

    useEffect(() => {
        if (onFileListChange) onFileListChange(files)
    }, [files, onFileListChange])

    useEffect(() => {
        if (artigo.imagens && !artigo.id) {
            let imagens = artigo.imagens.filter(item => item.is_local)
            setFiles(imagens)
        } else if (artigo.id) {
            loadImagens(artigo.id)
        }
    }, [artigo])

    useEffect(() => {
        if (uploadProgress?.reloadList == true) {
            if (artigo.id) loadImagens(artigo.id)
        }
    }, [uploadProgress])

    useEffect(() => {
        window.onbeforeunload = function () {
            if (uploadProgress.progress >= 0) {
                return "Você tem um upload em andamento, deseja realmente sair?";
            } else {
                return;
            }
        };
    }, [uploadProgress])

    function dzCallback(acceptedFiles) {
        let f = files
            const newFile = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            f = f.concat(newFile)
            setFiles(f)
    }

    async function loadImagens(artigoId) {
        try {
            if (!artigoId) {
                return
            }
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/artigos/${artigoId}/imagens`)
            setFiles(response.data.filter(item => item.is_local))
            if (clearAutoReloadList) clearAutoReloadList()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar arquivos do artigo')
        }
    }

    async function deleteImage(file) {
        try {
            if (artigo.id && file.id) {
                await api.delete(`/common/empresas/${empresaSelecionada?.id}/artigos/${file.artigo_id}/imagens/${file.id}`);
            }
            loadImagens(artigo.id)
            notify(
                "success",
                "Arquivo removido",
                `Arquivo ${file.path} removido`
            );
        } catch (err) {
            notify("danger", "Não foi possível remover imagem");
        }
    }


    function handleRemove(file) {
        const callback = () => {
            if ((artigo.id && file.id)) {
                deleteImage(file)
            } else {
                const newFiles = files.filter(item => item.path != file.path)
                setFiles(newFiles)
            }
            alert(null)
        }

        alert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="Remover Arquivo"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => callback()}
                onCancel={() => alert(null)}
                confirmBtnBsStyle="default"
                confirmBtnText="Sim"
                cancelBtnText="Cancelar"
            >
                Deseja realmente remover este arquivo?
            </ReactBSAlert>
        )
    }

    const formatFileSize = ({ id, size }) => {
        const dividendo = 1000000;
        return ((size) / dividendo).toFixed(1)
    }

    const nextPreview = currentIndex => {
        return currentIndex + 1 > files.length - 1 ? 0 : currentIndex + 1
    }

    const prevPreview = currentIndex => {
        return currentIndex - 1 < 0 ? (files.length - 1) : currentIndex - 1
    }

    function handleAskToUpdateTipoImagem(imagem, is_criacao) {
        if (!artigo.id) return
        const descricao = `Ao confirmar, imagem ${imagem.path} será do tipo ${is_criacao ? 'criação' : 'referência'}`
        alert(
            <ReactBSAlert
                custom
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={is_criacao ? 'Alterar tipo da imagem para criação?' : 'Alterar tipo da imagem para referência?'}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => handleAlterarImagem(imagem, is_criacao)}
                onCancel={() => alert(null)}
                confirmBtnBsStyle="default"
                confirmBtnText="Sim"
                cancelBtnText="Não"
            >
                {descricao}
            </ReactBSAlert>
        )
    }

    async function handleAlterarImagem(imagem, is_criacao) {
        if (!artigo.id) return
        try {
            await api.put(`common/empresas/${empresaSelecionada.id}/artigos/${artigo.id}/imagens/${imagem.id}`,
                {
                    is_criacao
                })
            alert(null)
            await loadImagens(imagem.artigo_id)
            notify('success', 'Imagem alterada')
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos realizar esta alteração')
        }

    }

    const lightboxPreview = () => {
        if (files.length === 0) return
        const file = files[preview.index]
        const nextFile = files[nextPreview(preview.index)]
        const prevFile = files[prevPreview(preview.index)]

        const urlNext = (artigo.id && nextFile.id) ? nextFile.url : nextFile.preview
        const urlPrev = (artigo.id && prevFile.id) ? prevFile.url : prevFile.preview
        return (<>
            {
                preview.show &&
                <Lightbox
                    mainSrc={(artigo.id && file.id) ? file.url : file.preview}
                    nextSrc={urlNext}
                    prevSrc={urlPrev}
                    onCloseRequest={() => setPreview({ ...preview, show: false })}
                    onMovePrevRequest={() =>
                        setPreview({
                            ...preview,
                            url: urlPrev,
                            index: prevPreview(preview.index)
                        })
                    }
                    discourageDownloads={false}
                    // reactModalStyle={{ zIndex: '300' }}
                    imageCaption={file.is_criacao ? 'Criação' : 'Referência'}
                    // reactModalStyle={{ zIndex: '100' }}
                    imageTitle={file.path || file.name}
                    onMoveNextRequest={() =>
                        setPreview({
                            ...preview,
                            url: urlNext,
                            index: nextPreview(preview.index)
                        })
                    }
                />
            }
        </>)
    }


    const thumbs = files.map((file, key) => (
        <ListGroupItem
            className=" px-0"
            style={{
                paddingTop: 0,
                border: 0,
            }}
        >

            {
                key == uploadProgress.index &&
                <Progress
                    animated
                    color="primary"
                    value={100}
                />
            }
            <Row className=" align-items-center" key={key}>
                <Col
                    onClick={() => setPreview({ show: true, index: key, url: (artigo.id && file.id) ? file.url : file.preview })}
                    style={{
                        cursor: "pointer",
                    }}
                    className=" col-auto"
                >
                    <div className=" avatar">
                        <img
                            alt={file.name}
                            className=" avatar-img rounded"
                            src={(artigo.id && file.id) ? file.url : file.preview}
                        />
                    </div>
                </Col>
                <Col
                    className="col ml-2"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingLeft: "0"
                    }}>
                    <div className="">
                        <h4 style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                            className=" mb-1"
                            data-dz-name
                        >
                            {artigo.id ? file.path : file.name}
                        </h4>
                        <span className="text-muted mb-0">
                            <a
                                download={(artigo.id && file.id)}
                                href={(artigo.id && file.id) ? file.url : null}
                                className="small btn-inner--text text-muted mb-0"
                                title={(artigo.id && file.id) ? 'Download' : null}
                            >
                                {
                                    (artigo.id && file.id) ?
                                        <>
                                            <span className="btn-inner--icon mr-2">
                                                <i class="fas fa-download text-muted"></i>
                                            </span>
                                            {formatFileSize({ id: file.id, size: file.tamanho })}
                                        </>
                                        : formatFileSize({ id: file.id, size: file.size })
                                }MB
                            </a>
                        </span>
                        <Badge
                            className="badge-primary"
                            size="sm"
                            href='#'
                            onClick={e => { e.preventDefault(); handleAskToUpdateTipoImagem(file, !file.is_criacao) }}
                            style={{ float: "right" }}
                        >
                            {
                                artigo.id ?
                                    file.is_criacao ? 'Criação' : 'Referência'
                                    : 'Referência'
                            }
                        </Badge>
                    </div>
                </Col>
                <Col className=" col-auto">
                    <Button size="sm" color="danger" onClick={e => handleRemove(file)}>
                        <i className="fas fa-trash" />
                    </Button>
                </Col>
            </Row>
        </ListGroupItem >
    ));

    return (
        <>
            {lightboxPreview()}
            <div className="container">
                    <Dropzone callback={dzCallback} accept='image/*' multiple>
                    <label style={{ cursor: 'pointer' }}>
                        'Solte seus arquivos aqui...'
                    </label>
                    </Dropzone>
            </div>
            <ListGroup
                className=" dz-preview dz-preview-multiple list-group-lg list"
                style={{
                    paddingTop: 12
                }}
                flush
            >
                {thumbs}
            </ListGroup>

            <Modal
                className={`modal-dialog-centered modal-primary`}
                contentClassName={uploadProgress?.progress > 99 ? 'bg-gradient-success' : ''}
                isOpen={(uploadProgress?.showModal && !artigo.id)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Fazendo upload...
                    </h6>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="ni ni-cloud-upload-96 ni-3x" />
                        <h4 className="heading mt-4">
                            Estamos subindo as imagens de referência do artigo...
                        </h4>
                        <p>
                            O artigo já foi salvo, só precisamos de alguns instantes
                            para fazer o upload das imagens de referência.
                        </p>
                    </div>
                    <div className="progress-wrapper">
                        {`${uploadProgress?.progress}%`}
                        <Progress
                            value='100'
                            animated
                            color="info"
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
}




