import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  CardText,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Badge,
} from 'reactstrap';

import api from '../../../../../../services/api';
import { Canal } from '../../../../../../entities/Marketing';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { NovoCanalModal } from './modals/NovoCanalModal';
import { useGoogleLogin } from '@react-oauth/google';
import { CanalCard } from './components/cards';
import AuthContext from '../../../../../../contexts/Auth';

interface ClientInfoResponse {
  success: boolean;
  sessionInfo: {
    pushname: string;
    wid: {
      server: string;
      user: string;
      _serialized: string;
    };
    me: {
      server: string;
      user: string;
      _serialized: string;
    };
    platform: string;
  };
}

export interface CanalVenda extends Canal {
  color: string;
  icon: string;
  is_canal_contato: boolean;
}

export interface CanalContato {
  canal: CanalVenda;
  identificador: string;
  is_canal_contato: boolean;
}

export interface ModeloSite {
  id: number;
  link: string;
  tipo_modelo: 'vendedor' | 'indicacao';
  params_map: string;
}

interface ParametroSite {
  id: number;
  chave: string;
  valor: string;
  descricao: string;
  usuario_modelo_site_id: string;
}

export interface UsuarioModeloSite {
  id: string;
  user_empresa_id: number;
  modelo_site_id: number;
  parametroSite: ParametroSite[];
  site: ModeloSite;
}

export function Canais() {
  const { user } = useContext(AuthContext);
  const [canaisDeVenda, setCanaisDeVenda] = useState<CanalVenda[] | null>(null);
  const [canaisContato, setCanaisContato] = useState<CanalContato[] | null>(
    null
  );

  const [canalSelecionado, setCanalSelecionado] = useState<string | undefined>(
    undefined
  );
  const [isEmailSync, setIsEmailSync] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<any>(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleCloseModal = () => setModal(false);

  const [modalWhatsApp, setModalWhatsApp] = useState(false);
  const [QRImage, setQRImage] = useState('');
  const [isSessionActive, setIsSessionActive] = useState(false);

  const canaisDisponiveis = useMemo(() => {
    return canaisDeVenda?.filter(
      (canal) => !canaisContato?.some((contato) => canal.id == contato.canal.id)
    );
  }, [canaisContato, canaisDeVenda]);

  async function carregarCanaisDeVenda() {
    try {
      setIsLoading(true);
      const { data } = await api.get<CanalVenda[]>(`/marketing/canais`);

      setCanaisDeVenda(data.filter((canal) => canal.is_canal_contato));
    } catch (error) {
      console.error(
        'Houve um erro ao tentar carregar os canais de venda.',
        error
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleSaveNovoCanal({ canalSelecionado, identificador }: any) {
    createCanalContato(canalSelecionado, identificador);
  }

  useEffect(() => {
    carregarCanaisDeVenda();
    getCanaisContato();
    checkIfEmailIsSynchronized();
  }, []);

  async function checkIfEmailIsSynchronized() {
    const { data } = await api.get<boolean>(
      `marketing/agendamentos/auth/google`
    );
    setIsEmailSync(data);
  }

  async function getCanaisContato() {
    try {
      setIsLoading(true);
      const { data } = await api.get(`/marketing/me/canais-contato`);

      setCanaisContato(data);
    } catch (error) {
      console.error(
        'Houve um erro ao tentar listar os canais de contato.',
        error
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function createCanalContato(
    canalId: string | number,
    identificador: string
  ) {
    try {
      setIsLoading(true);
      const { data } = await api.post(`/marketing/me/canais-contato`, {
        identificador:
          canalId == '25' ? identificador.replace(/\D/g, '') : identificador,
        canal_id: canalId,
      });

      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block' }}
          title="Sucesso!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Canal de contato criado com sucesso!
        </ReactBSAlert>
      );

      getCanaisContato();
      checkIfEmailIsSynchronized();
      handleCloseModal();

      return data;
    } catch (error) {
      console.error(
        'Houve um erro ao tentar criar um canal de contato.',
        error
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleVisibilidadeCanalContato(
    canalId: string | number,
    isVisible: boolean
  ) {
    console.log({ canalId, isVisible });
    // TODO send to db
  }

  function handleDeleteCanalContato(canalId: string | number) {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block' }}
        title="Tem certeza que deseja remover o contato?"
        onConfirm={() => deleteCanalContato(canalId)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Sim"
        btnSize=""
        showCancel
        focusCancelBtn
      />
    );
  }

  async function deleteCanalContato(canalId: string | number) {
    try {
      await api.delete(`/marketing/me/canais-contato/${canalId}`);

      if (canalId == 25) {
        terminateWhatsAppSession();
      }

      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block' }}
          title="Sucesso!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Canal de contato removido com sucesso!
        </ReactBSAlert>
      );

      getCanaisContato();
    } catch (error) {
      console.error(
        'Houve um erro ao tentar remover um canal de contato.',
        error
      );
    }
  }

  const temCanais = canaisDisponiveis && canaisDisponiveis.length > 0;

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length <= 10) {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    } else {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        7
      )}-${phoneNumber.slice(7, 11)}`;
    }
  };

  const formatSocialsLink = (text: string) => {
    return text.split('.com')[1];
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email',
    onSuccess: async (codeResponse) => {
      setIsLoading(true);
      const googleResponse = await api.post(
        'marketing/agendamentos/auth/google',
        {
          code: codeResponse.code,
        }
      );
      createCanalContato(12, googleResponse.data.userEmail);

      setIsEmailSync(true);
      setIsLoading(false);
      handleCloseModal();
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  function toggleWhatsAppModal() {
    setModalWhatsApp((currentState) => !currentState);
  }

  function handleModalClose() {
    setModalWhatsApp(false);
    setQRImage('');
  }

  async function fetchQRCode() {
    const { data } = await api.get(
      `/meta/qr/image?session=user&id=${user?.id}`,
      { responseType: 'blob' }
    );

    const url = URL.createObjectURL(data);
    setQRImage(url);
  }

  async function checkWhatsAppSessionStatus() {
    const { data } = await api.get(`/meta/status?session=user&id=${user?.id}`);

    if (data) {
      setIsSessionActive(data.state == 'CONNECTED' ? true : false);
    }
  }

  async function terminateWhatsAppSession() {
    const canalContatoWhatsApp = canaisContato?.find(
      (item) => item.canal.id == 25
    );
    let account = '';
    if (canalContatoWhatsApp) {
      const { identificador } = canalContatoWhatsApp;
      if (identificador.length >= 11) {
        // Remove o 9 após o DDD
        account = '55' + identificador.slice(0, 2) + identificador.slice(3);
      } else {
        account = '55' + identificador;
      }
    }

    await api.post(`/meta/terminate`, {
      session: 'user',
      id: user?.id,
      account_id: account,
    });

    checkWhatsAppSessionStatus();
  }

  useEffect(() => {
    checkWhatsAppSessionStatus();
  }, []);

  async function getClientInfo() {
    try {
      const { data } = await api.get<ClientInfoResponse>(
        `/meta/class-info?session=user&id=${user?.id}`
      );

      if (data) {
        const {
          sessionInfo: { pushname, wid },
        } = data;

        // Se os dados da sessão existirem, registra a conta no sistema
        await api.post(`/meta/accounts/web`, {
          account: {
            verified_name: pushname,
            code_verification_status: 'VERIFIED',
            display_phone_number: wid.user,
            quality_rating: 'GREEN',
            id: wid.user,
            user_id: user?.id,
          },
        });

        // Exibe o alerta de sucesso e fecha o modal
        window.alert('Conta sincronizada com sucesso!');
        checkWhatsAppSessionStatus();
        handleModalClose();
        // Retorna verdadeiro para parar o polling
        return true;
      }
    } catch (error) {
      console.error('Erro ao obter informações do cliente:', error);
    }

    // Retorna falso para continuar o polling
    return false;
  }

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout | undefined;

    if (QRImage) {
      // Quando a imagem do QR Code for exibida, inicia o polling
      pollingInterval = setInterval(async () => {
        const isClientSynced = await getClientInfo();

        if (isClientSynced) {
          // Se a conta for sincronizada, para o polling
          clearInterval(pollingInterval);
        }
      }, 3000);
    }

    // Limpa o polling se o componente for desmontado
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [QRImage]);

  return (
    <>
      <Modal isOpen={modalWhatsApp} toggle={handleModalClose}>
        <ModalHeader>Meu WhatsApp</ModalHeader>
        <ModalBody>{QRImage && <img src={QRImage} />}</ModalBody>
        {isSessionActive && <Badge color="success">Conectado</Badge>}
        {!isSessionActive && <Badge color="danger">Desconectado</Badge>}
        <ModalFooter>
          {!isSessionActive && (
            <Button color="primary" onClick={fetchQRCode}>
              Iniciar Sessão
            </Button>
          )}
          {isSessionActive && (
            <Button color="danger" onClick={terminateWhatsAppSession}>
              Desconectar
            </Button>
          )}
          <Button color="secondary" onClick={handleModalClose}>
            Voltar
          </Button>
        </ModalFooter>
      </Modal>

      {alert}
      {temCanais && (
        <NovoCanalModal
          isOpen={modal}
          onClose={handleCloseModal}
          onSave={handleSaveNovoCanal}
          canais={canaisDisponiveis}
          isLoading={isLoading}
          googleLogin={googleLogin}
        />
      )}

      <Row>
        <Col>
          <h2>Meus canais de contato</h2>
        </Col>
      </Row>
      <Row>
        {temCanais && (
          <Col xs={12} sm={6} md={4} lg={3}>
            <Card
              style={{
                background: '#eee',
                height: '400px',
                border: '2px dashed',
              }}
            >
              <CardBody
                className="d-flex align-items-center justify-content-center flex-column"
                onClick={toggle}
                role="button"
                style={{ cursor: 'pointer' }}
              >
                <CardText className="font-weight-bold">
                  <span>
                    <i className="fas fa-plus" />
                  </span>
                </CardText>
                <CardText className="font-weight-bold">Novo Canal</CardText>
              </CardBody>
            </Card>
          </Col>
        )}

        {canaisContato?.map((item) => (
          <CanalCard
            openWAModal={toggleWhatsAppModal}
            isConversasSync={isSessionActive}
            canal={item.canal}
            googleLogin={googleLogin}
            identificador={item.identificador}
            isEmailSync={isEmailSync}
            onChangePrivacy={handleVisibilidadeCanalContato}
            onDelete={handleDeleteCanalContato}
            key={item.canal.id}
            is_canal_contato
          />
        ))}
      </Row>
    </>
  );
}
