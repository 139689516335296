import React, { useEffect, useState } from "react";
import { Button, Input, InputGroup, InputGroupText, Spinner } from "reactstrap";
// @ts-ignore
import Axios from "axios";
import api from "../../../../services/api";

const Telefones: React.FC<{ numero: string; tipo: string }> = ({ numero }) => {
  const [telefone, setTelefone] = useState<any>(numero);
  const [externalKey, setExternalKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    checarIntegracaoJetSales();
  }, []);

  function handleOpenWhatsapp(num: string) {
    const win = window.open(
      `https://api.whatsapp.com/send?l=pt_BR&phone=55${num}`,
      "_blank"
    );
    if (win) win.focus();
  }

  async function checarIntegracaoJetSales() {
    try {
      const {
        data: { external_key_jetsales },
      } = await api.get(
        `/common/parametros-empresas/external_key_jetsales/pe-show`
      );
      if (external_key_jetsales) {
        setExternalKey(external_key_jetsales);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleOpenJetSalesChat(num: string) {
    try {
      if (!externalKey) return;
      setIsLoading(true);
      const {
        data: { token_jetsales },
      } = await api.get(`/common/parametros-empresas/token_jetsales/pe-show`);

      const {
        data: { url_jetsales },
      } = await api.get(`/common/parametros-empresas/url_jetsales/pe-show`);

      if (token_jetsales && url_jetsales) {
        const apiUrl = `${url_jetsales}/v1/api/external/${externalKey}?token=${token_jetsales}`;

        await Axios.post(apiUrl, {
          body: "",
          number: `55${num.replace(/[^0-9]/g, "")}`,
          externalKey,
        });

        const urlToOpen = `${url_jetsales.replace(/api/g, "")}/#/chats`;

        const newWindow = window.open(urlToOpen, "_blank");
        if (newWindow) {
          newWindow.focus();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handlePhoneChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, "");
    setTelefone(rawValue);
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length <= 2) {
      return phoneNumber;
    } else if (phoneNumber.length <= 7) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2)}`;
    } else {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
        2,
        7
      )}-${phoneNumber.slice(7, 11)}`;
    }
  };
  return (
    <InputGroup>
      <InputGroupText className="mr-1">
        <i className="fa fa-edit"></i>
      </InputGroupText>
      <Input onChange={handlePhoneChange} value={formatPhoneNumber(telefone)} />
      <Button
        className="ml-2 text-white"
        size="sm"
        onClick={() => handleOpenWhatsapp(telefone)}
        style={{
          cursor: "pointer",
          backgroundColor: "#128c7e",
        }}
      >
        <i className="fab fa-whatsapp mr-2"></i>
        WhatsApp
      </Button>
      {externalKey && (
        <Button
          className="ml-2 text-white"
          disabled={isLoading}
          size="sm"
          onClick={() => handleOpenJetSalesChat(telefone)}
          style={{
            cursor: isLoading ? "not-allowed" : "pointer",
            backgroundColor: "#5effda",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: ".5rem",
            minWidth: "90px",
          }}
        >
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              <img
                width={16}
                height={16}
                style={{ display: "block" }}
                src="https://www.jetsalesbrasil.com/wp-content/uploads/2024/03/JETSALES_SIMBOLO_BG_PRETO@3x.png"
                alt="JetSales"
              />
              <span className="text-dark">JetSales</span>
            </>
          )}
        </Button>
      )}
    </InputGroup>
  );
};

export default Telefones;
