import React, { useState, useRef, useEffect, useContext } from "react";
import "./styles.css";
import RecordingIcon from "./RecordingIcon";
import { useWhatsappContext } from "../../../../../contexts/Whatsapp";
import AuthContext from "../../../../../contexts/Auth";
import EmpresaContext from "../../../../../contexts/Empresa";

interface AudioRecorderProps {
  setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
  isRecording: boolean;
  handleSave: (audioBlob: File | Blob) => void;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({
  isRecording,
  setIsRecording,
  handleSave,
}) => {
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isCancelled, setIsCancelled] = useState(false);
  // @ts-ignore
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const { user } = useContext(AuthContext);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { selectedContact} = useWhatsappContext();

  const handleStartRecording = async () => {
    try {
      setIsCancelled(false);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Try to set mimeType to a common format across browsers
      const mimeType = "audio/webm;codecs=opus";

      // @ts-ignore
      const mediaRecorder = new MediaRecorder(stream, { mimeType });
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/mpeg",
        });
        setAudioBlob(audioBlob);
        audioChunksRef.current = [];
      };

      mediaRecorder.start();
      setIsRecording(true);
      setIsCancelled(false);
    } catch (error) {
      console.error("Error accessing microphone", error);
    }
  };

  function handleDataAvailable(event) {
    if (event.data.size > 0) {
      audioChunksRef.current.push(event.data);
    }
  }

  const handleStopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
      setIsRecording(false);
    }
  };

  const handleCancelRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      audioChunksRef.current = [];
      setAudioBlob(null);
      setIsCancelled(true);
      setIsRecording(false);
    }
  };

  useEffect(() => {
    if (audioBlob && !isCancelled) {
      const fileName = `${empresaSelecionada?.id}-${user?.id}-${selectedContact?.wa_id}`
      const audioFile = new File([audioBlob], `${fileName}.mp3`, {
        type: "audio/mpeg",
      });

      handleSave(audioFile);
      setAudioBlob(null);
    }
  }, [audioBlob]);

  return (
    <div className="recorder" style={{ width: isRecording ? "18%" : "9%" }}>
      {!isRecording && (
        <>
          <button
            onClick={handleStartRecording}
            style={{
              paddingInline: "12px",
              background: "#0747d6",
              borderRadius: "100px",
              cursor: "pointer",
            }}
          >
            <span style={{ color: "white" }}>
              <i className="fas fa-microphone"></i>
            </span>
          </button>
        </>
      )}

      {isRecording && (
        <button
          onClick={handleCancelRecording}
          style={{
            paddingInline: "12px",
            border: "1px solid red",
            borderRadius: "100px",
          }}
        >
          <span style={{ color: "red" }}>
            <i className="fas fa-trash"></i>
          </span>
        </button>
      )}

      {isRecording && (
        <div>
          <RecordingIcon />
        </div>
      )}

      {isRecording && (
        <button
          onClick={handleStopRecording}
          style={{
            paddingInline: "12px",
            background: "#0747d6",
            borderRadius: "100px",
          }}
        >
          <span style={{ color: "white" }}>
            <i className="fas fa-check-circle"></i>
          </span>
        </button>
      )}
    </div>
  );
};

export default AudioRecorder;
