import React, { useEffect, useState } from 'react';

import { Circle } from './styles';

export default ({
  estagio,
  width = 30,
  height = 30,
  handleSaveColor,
  ...props
}) => {
  const [cor, setCor] = useState(estagio?.cor);

  useEffect(() => {
    setCor(estagio?.cor);
  }, [estagio]);

  return (
    <Circle
      type="color"
      style={{ zIndex: 10, width, height }}
      onChange={(e) => setCor(e.target.value)}
      onBlur={(e) => handleSaveColor(e, estagio, cor)}
      color={cor}
      value={cor}
    />
  );
};
