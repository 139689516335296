import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "./styles.css"

const DefaultOptions = ({ options = [] }) => {
  return options.map((opt) => <option value={opt}>{opt}</option>);
};


export default ({
  data,
  pageProperties,
  notify,
  columns,
  rowEvents,
  paginationLengths=[],
  ...props
}) => {
  const NoDataIndication = () => (
    <div className="spinner">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
      <div className="rect5" />
    </div>
  );

  const RemotePagination = ({
    loading,
    data,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
    showSizePerPage = true,
    sort = {},
    paginationLengths = [],
  }) => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          alwaysShowAllBtns: true,
          showTotal: true,
          withFirstAndLast: true,
          paginationTotalRenderer: (from, to, size) => {
            const margin = {
              paddingLeft: "4px",
            };
            return (
              <span
                className="react-bootstrap-table-pagination-total"
                style={margin}
              >
                Exibindo {to == 0 ? 0 : from} a {to} do total de {totalSize}.
              </span>
            );
          },
          sizePerPageRenderer: ({
            options,
            currSizePerPage,
            onSizePerPageChange,
          }) => {
            if (!showSizePerPage) return null;
            return ( 
              <div className="dataTables_length" id="datatable-basic_length">
                <label>
                  {
                    <select
                      name="datatable-basic_length"
                      value={currSizePerPage}
                      aria-controls="datatable-basic"
                      className="form-control form-control-sm"
                      onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                      <DefaultOptions options={paginationLengths.length?paginationLengths : [10, 25, 50, 100]} />
                    </select>
                  }{" "}
                  itens.
                </label>
              </div>
            );
          },
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              remote
              loading={loading}
              keyField="id"
              //   noDataIndication={() => <NoDataIndication />}
              bootstrap4={true}
              bordered={false}
              data={data}
              columns={columns}
              noDataIndication="Nenhum item encontrado"
              onTableChange={onTableChange}
              rowEvents={rowEvents}
              overlay={overlayFactory({
                spinner: true,
                styles: {
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(150, 150, 150, 0.5)",
                  }),
                },
              })}
              sort={sort}
              {...props}
              {...paginationTableProps}
            />
          </div>
        )}
      </PaginationProvider>
    </div>
  );

  return (
    <>
      {data && pageProperties && (
        <RemotePagination
          data={data}
          page={pageProperties.page}
          sizePerPage={pageProperties.perPage}
          totalSize={parseInt(pageProperties.total)}
          onTableChange={props.onTableChange}
          loading={pageProperties.loading}
          showSizePerPage={pageProperties.showSizePerPage}
          sort={pageProperties.sort}
          paginationLengths={paginationLengths}
        />
      )}
    </>
  );
};
