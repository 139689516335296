import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../../../contexts/Empresa";
import api from "../../../../../services/api";

import NewEquipe from './New'
import EquipeList from "./ListGroup";
import {
    Button,
    Row,
    Col,
    Modal,
    Spinner
} from "reactstrap";

function Equipes({
    anuncio,
    notify,
    show = false,
    onClose = () => { }
}) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])

    const [erros, setErros] = useState({})

    useEffect(() => {
        if (anuncio) loadItens()
    }, [show, anuncio])

    async function loadItens() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada.id}/campanhas/${anuncio.campanha_id}/anuncios/${anuncio.id}/equipes`);
            setItens(await response.data)
        } catch (error) {
            console.error(error)
            notify("danger", "Não foi possível buscar equipes");
        }
    }

    async function handleDelete(item) {
        if (window.confirm(`Deseja remover a equipe ${item.equipe.nome} do anuncio?`)) {
            try {
                await api.delete(`/common/empresas/${empresaSelecionada.id}/campanhas/${anuncio.campanha_id}/anuncios/${anuncio.id}/equipes/${item.id}`);
                notify("success", "Equipe removida");
                await loadItens()
            } catch (error) {
                console.error(error)
                notify("danger", "Não foi possível remover Equipe");
            }
        }
    }



    function handleAddEquipe() {

    }

    async function onEquipeAdded() {
        await loadItens()
    }

    return (
        <>
            <Modal
                size="md"
                className="modal-dialog-centered"
                isOpen={show}
                toggle={onClose}
            >
                <div className="modal-header">
                    <h5 className="modal-title" >
                        Equipes
                        <br />
                        <small className='text-muted'>{anuncio?.nome}</small>
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onClose}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NewEquipe
                        equipesAdicionadas={itens}
                        anuncio={anuncio}
                        onAddedEquipe={loadItens}
                    />
                    <EquipeList
                        equipes={itens}
                        handleDelete={handleDelete}
                    />
                    <small class="text-danger">
                        {erros.equipe_id || ''}
                    </small>
                </div>

            </Modal>
        </>
    )
}

export default Equipes;