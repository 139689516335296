import React, { useEffect, useState } from 'react';
import api from "../../../../services/api";

import Encaminhar from './Encaminhar'
import Avatar from '../../../../components/Avatar'
import {
    Button,
} from "reactstrap";


export default ({ oportunidade, onColaboradorChanged, notify }) => {

    const [nome, setNome] = useState(null)
    const [showEncaminhar, setShowEncaminhar] = useState(false)

    function onEncaminhado() {
        setShowEncaminhar(false)
        onColaboradorChanged()
    }

    return (
        <>
            {
                (oportunidade && oportunidade.id) &&
                <>
                    <Button
                        title='Responsavel pela oportunidade'
                        style={{
                            borderRadius: 100,
                            padding: "0 2px 0 2px",
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'center'
                        }}
                        onMouseEnter={() => {
                            setNome(oportunidade?.user?.name)
                        }}
                        onMouseLeave={() => {
                            setNome('')
                        }}
                        onClick={() => {
                            setShowEncaminhar(true)
                        }}
                        size={'sm'}
                        color="secondary"
                    >
                        <Avatar user={oportunidade.user} className={` avatar-xs ${nome ? 'mr-2' : ''}`} />
                        <div style={{ alignSelf: 'center' }}>
                            {nome}
                        </div>
                    </Button>
                </>
            }

            <Encaminhar
                show={showEncaminhar}
                notify={notify}
                onHidden={() => setShowEncaminhar(false)}
                oportunidade={oportunidade}
                onConfirm={onEncaminhado}
            />
        </>
    );
}
