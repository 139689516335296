import React, { useState, useContext, useEffect } from 'react';
import EmpresaContex from "../../../../../contexts/Empresa";
import api from "../../../../../services/api";
import { Anuncio } from '../../../../../entities/Marketing';

import NewAnuncio from "./New";
import { Container } from './styles';
import List from "./ListGroup";
import {
    Button,
    PopoverBody,
    UncontrolledPopover
} from "reactstrap";

interface IAnuncio {
    esteiraId?: number,
    updateAnuncios(anuncios: Array<Anuncio>): void,
    notify(type: string, msg: string): void
}

const Anuncios: React.FC<IAnuncio> = ({ esteiraId, updateAnuncios, notify }) => {

    const { empresaSelecionada } = useContext(EmpresaContex)
    const [anuncios, setAnuncios] = useState(new Array<Anuncio>())
    const [showNewAnuncio, setShowNewAnuncio] = useState(false)

    useEffect(() => {
        loadAnuncios()
    }, [esteiraId])

    useEffect(() => {
        updateAnuncios(anuncios)
    }, [anuncios])

    async function handleDelete(anuncio: Anuncio) {
        if (window.confirm(`Deseja remover este anúncio?`)) {
            if (esteiraId) {
                await deleteAnuncio(anuncio)
            } else {
                setAnuncios(anuncios.filter(item => item.id !== anuncio.id))
            }
        }
    }

    async function loadAnuncios() {
        if (!esteiraId) return
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/esteiras/${esteiraId}/anuncios`)
            const data = await response.data
            setAnuncios(data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível buscar anúncios')
        }
    }

    async function deleteAnuncio(anuncio: Anuncio) {
        try {
            await api.delete(`/common/empresas/${empresaSelecionada?.id}/esteiras/${esteiraId}/anuncios/${anuncio.id}`)
            notify('success', 'Anúncio Removido')
            await loadAnuncios()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível remover anúncio')
        }
    }

    async function onAnuncioAdicionado(anuncio: Anuncio) {
        if (esteiraId) {
            notify('success', 'Anúncio adicionado')
            await loadAnuncios()
        } else {
            const anuncioCopy = anuncios
            anuncioCopy.push(anuncio)
            setAnuncios(anuncioCopy)
            notify('success', 'Anúncio adicionado')
        }
        setShowNewAnuncio(false)
    }

    return (
        <Container>
            <div
                className='text-center justify-content-center flex-column justify-content-center'>
                <h2>
                    Anúncios
                    <Button color="link" id="tooltip876279349" type="button">
                        ?
                    </Button>
                    <UncontrolledPopover placement="top" target="tooltip876279349">
                        <PopoverBody>
                            As oportunidades dos anúncios abaixo, serão alvo do processo da esteira
                        </PopoverBody>
                    </UncontrolledPopover>
                </h2>
                <Button
                    color='primary'
                    size='sm'
                    onClick={() => setShowNewAnuncio(true)}
                >
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Adicionar anúncio</span>
                </Button>
            </div>
            <NewAnuncio
                show={showNewAnuncio}
                notify={notify}
                esteiraId={esteiraId}
                hidde={() => setShowNewAnuncio(false)}
                onSuccess={onAnuncioAdicionado}
                anunciosAdicionados={anuncios}
            />
            <List
                anuncios={anuncios}
                handleDelete={handleDelete}
            />
        </Container>
    )
}

export default Anuncios;