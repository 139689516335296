import React, { useState, useEffect, useContext } from "react";
import EmpresaContext from "../../../contexts/Empresa";

// @ts-ignore
import Select2 from "react-select2-wrapper";

import {
  Row,
  Col,
  Input,
  FormGroup,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";

import ReactDatetime from "react-datetime";
import moment from "moment";
// @ts-ignore
import InputMask from "react-input-mask";

import Filters from "../../../components/Headers/Filters";
import { usePersistedState } from "../../../hooks";
import api from "../../../services/api";

// @ts-ignore
export default ({ title, notify, load, ...props }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [limpouDataAgendamento, setLimpouDataAgendamento] = usePersistedState(
    "limpouDataAgendamento",
    false
  );
  const [dataAgendamentoInicial, setDataAgendamentoInicial] = usePersistedState(
    "dataAgendamentoInicial",
    (() => {
      if (!limpouDataAgendamento) {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth());
      }
      return null;
    })()
  );
  const [dataAgendamentoFinal, setDataAgendamentoFinal] = usePersistedState(
    "dataAgendamentoFinal",
    (() => {
      if (!limpouDataAgendamento) return new Date();
      return null;
    })()
  );
  const [monitorClearFilters, setMonitorClearFilters] = useState(undefined);

  // Campos do formulário de 'filtros'
  const [numeroVenda, setNumeroVenda] = useState<string | undefined>('');
  const [numeroPessoa, setNumeroPessoa] = useState<string | undefined>(
    ''
  );
  const [vendedor, setVendedor] = useState<string | undefined>('');
  const [cliente, setCliente] = useState<string | undefined>('');

  // Flag para definir tempo de execução
  const [runLoad, setRunLoad] = useState(props.search ? props.search : true);

  useEffect(() => {
    if (runLoad) {
      search();
      setRunLoad(false);
    }
  }, [runLoad]);

  useEffect(() => {
    if (monitorClearFilters) {
      search();
    }
  }, [monitorClearFilters]);

  async function search() {
    // callback da função 'load' que é passada do parent component
    load({
      numeroPessoa,
      numeroVenda,
      vendedor,
      cliente,
    });
  }
  async function handleClearFilter() {
    setCliente('');
    setNumeroPessoa('');
    setVendedor('');
    setNumeroVenda('');
    handleLimparDataAgendamento();
  }

  function handleLimparDataAgendamento() {
    setDataAgendamentoFinal(null);
    setDataAgendamentoInicial(null);
    setLimpouDataAgendamento(true);
  }

  return (
    <>
      <Filters
        onSearch={search}
        title={<h1>Vendas</h1>}
        clearFilters={handleClearFilter}
        // @ts-ignore
        onFiltersClead={setMonitorClearFilters}
      >
        <Row>
          <Col xs="6" lg="6" sm="6" md="6">
            <div
              style={{
                border: "1px solid",
                borderRadius: "4px",
                borderColor: "#eee",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                padding: 13,
                marginBottom: 11,
              }}
            >
              <label
                className="form-control-label"
                htmlFor="example-number-input"
              >
                Faturamento
              </label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <FormGroup
                  style={{ flex: 1, paddingRight: 4, marginBottom: 0 }}
                >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Data inicial",
                        style: {
                          width: "100px",
                        },
                      }}
                      value={moment(dataAgendamentoInicial)}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          dataAgendamentoInicial &&
                          dataAgendamentoFinal &&
                          dataAgendamentoInicial._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          dataAgendamentoInicial &&
                          dataAgendamentoFinal &&
                          new Date(dataAgendamentoInicial._d + "") <
                            new Date(currentDate._d + "") &&
                          new Date(dataAgendamentoFinal._d + "") >
                            new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          dataAgendamentoFinal &&
                          dataAgendamentoFinal._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) => setDataAgendamentoInicial(e)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup style={{ flex: 1, paddingLeft: 4, marginBottom: 0 }}>
                  {/* <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Data final
                  </label> */}
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Data final",
                        style: {
                          width: "100px",
                        },
                      }}
                      // @ts-ignore
                      style={{ width: "100px" }}
                      value={moment(dataAgendamentoFinal)}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          dataAgendamentoInicial &&
                          dataAgendamentoFinal &&
                          dataAgendamentoInicial._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          dataAgendamentoInicial &&
                          dataAgendamentoFinal &&
                          new Date(dataAgendamentoInicial._d + "") <
                            new Date(currentDate._d + "") &&
                          new Date(dataAgendamentoFinal._d + "") >
                            new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          dataAgendamentoFinal &&
                          dataAgendamentoFinal._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) => setDataAgendamentoFinal(e)}
                    />
                  </InputGroup>
                </FormGroup>
                <Button
                  className="ml-2"
                  color="secondary"
                  outline
                  title="Limpar datas"
                  size="sm"
                  onClick={handleLimparDataAgendamento}
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-trash" />
                  </span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="4" lg="4" sm="4" md="4">
            <FormGroup className="">
              <Label className="form-control-label">Número da Venda</Label>
              <Input
                placeholder="Informe o Nº da venda..."
                value={numeroVenda}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNumeroVenda(event.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label className="form-control-label">CPF / CNPJ</Label>

              <InputMask
                placeholder={"Informe o CPF ou o CNPJ..."}
                className="form-control"
                maskPlaceholder={null}
                mask={
                  // @ts-ignore
                  numeroPessoa?.length < 14
                    ? "999.999.999-99"
                    : "99.999.999/9999-99"
                }
                value={numeroPessoa}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNumeroPessoa(e.target.value)
                }
              />
              {/* <Input placeholder="" /> */}
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label className="form-control-label">Vendedor</Label>
              <Input
                placeholder="Nome do vendedor"
                value={vendedor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setVendedor(event.target.value);
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label className="form-control-label">Cliente</Label>
              <Input
                placeholder="Nome/Razão Social"
                value={cliente}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCliente(event.target.value);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Filters>
    </>
  );
};
