import React, { useState, useEffect } from 'react';
import Tab from "./Tab"

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'


export default ({ match, location, ...props }) => {

    const [id, setId] = useState(null)
    const [anuncio, setAnuncio] = useState(null)

    useEffect(() => {
        const { params } = match
        if (params && params.id != id) {
            setId(params.id)
        }

        const { state } = location
        
        if (state?.toAnuncio) {
            setAnuncio(state?.toAnuncio)
        }
    }, [])

    return (
        <>
            <SimpleHeader
                name="Gerenciar Campanha"
                parentName="Campanhas"
                {...props} />
            <Tab
                {...props}
                campanhaId={id}
                anuncio={anuncio}
            />
        </>
    )

}
