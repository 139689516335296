import React, { useState } from 'react';
import { GrupoEstrategico } from "../../../entities/Mentoring";

import { Container } from './styles';
import Estrategias from "./Estrategias";
import {
    Badge, Collapse,
} from "reactstrap";
import {
    Estrategia,
} from '../../../entities/Mentoring';
type Props = {
    pdca: Array<GrupoEstrategico>
}
type PropsE = {
    item: any
}

const EstrategiaContainer: React.FC<PropsE> = ({ item }) => {

    const [isOpen, setIsOpen] = useState(false)


    return (
        <div className="timeline-content">
            <small onClick={() => setIsOpen(!isOpen)} className="text-muted font-weight-bold h2" 
             style={{cursor:'pointer'}}>
                {item.nome}
            </small>
            <Collapse isOpen={isOpen}>
                <Estrategias
                    estrategias={item.estrategias}
                />
            </Collapse>
        </div>
    )
}

const Pdca: React.FC<Props> = ({ pdca }) => {

    return (
        <Container
            className='timeline timeline-one-side'
            data-timeline-axis-style="dashed"
            data-timeline-content="axis"
        >
            {
                pdca.map((item, key) => {
                    let isOpen = false;
                    return (
                        <div
                            key={key}
                            className="timeline-block"
                        >
                            <div className="timeline-block">
                                <span className={`timeline-step ${key%2==0?'badge-primary':'badge-success' }`} style={{zIndex:0}}>
                                    <i className="fas fa-thumbtack" />
                                </span>
                                <EstrategiaContainer item={item} />
                            </div>
                        </div>
                    )
                })
            }
        </Container>
    )
}

export default Pdca;