import React, { useState, useEffect } from 'react';

import classnames from "classnames";
import {
  Button,
  ButtonGroup,
  Row,
  UncontrolledPopover,
  PopoverBody,
  Col,
  Modal,
} from "reactstrap";

// import { Container } from './styles';

interface Props {
  value: any,
  onChange: any
}

const StatusObjetivos: React.FC<Props> = ({ value, onChange }) => {

  const [status] = useState([
    { id: 'V', text: 'Vinculados' },
    { id: 'N', text: 'Não Vinculados' }
  ])

  return <>
    <ButtonGroup className="btn-group-toggle mt-4 py--4" size='sm' data-toggle="buttons">
      {
        status.map((item, key) => (
          <Button
            className={classnames({ active: value == item.id })}
            color="secondary"
            onClick={() => onChange(item.id)}>
            <input
              autoComplete="off"
              name="options"
              type="radio"
              //@ts-ignore
              value={value == item.id}
            />
            {item.text}
          </Button>
        ))
      }
    </ButtonGroup>
  </>

}

export default StatusObjetivos;