import React, { useState, useEffect } from 'react';
import api from "../../../../services/api";

import Select2 from "react-select2-wrapper";
import {
    Button,
    Row,
    Col,
    Input,
    FormGroup,
} from "reactstrap";

export default ({ notify, tipoProdutoId, empresaId, close, onSuccess, ...props }) => {

    const [nome, setNome] = useState(null)
    const [situacao, setSituacao] = useState('A')

    const [tipoProduto, setTipoProduto] = useState({})
    const [erros, setErros] = useState({})


    useEffect(() => {
        if (tipoProdutoId && tipoProdutoId != tipoProduto.id) {
            loadTipoProduto(tipoProdutoId)
        }
    }, [tipoProdutoId])

    useEffect(() => {
        setNome(tipoProduto.nome)
        setSituacao(tipoProduto.situacao ? tipoProduto.situacao : 'A')
    }, [tipoProduto])

    async function loadTipoProduto(tipoProdutoId) {
        try {
            const response = await api.get(`/common/empresas/${empresaId}/tipos-produtos/${tipoProdutoId}`)
            if (response.data) {
                setTipoProduto(response.data)
            }
            updateErros(null)
        } catch (error) {
            console.log(error)
        }
    }


    function handleSave() {
        if (tipoProdutoId) {
            update()
        } else {
            insert()
        }
    }

    async function insert() {
        try {
            console.log(situacao)
            await api.post(`/common/empresas/${empresaId}/tipos-produtos`, {
                nome,
                situacao
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível adicionar tipo de produto')
            if (error.response) updateErros(error.response.data)
        }
    }

    async function update() {
        try {
            await api.put(`/common/empresas/${empresaId}/tipos-produtos/${tipoProdutoId}`, {
                nome,
                situacao
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível salvar tipo de produto')
            if (error.response) updateErros(error.response.data)
        }
    }

    function updateErros(error) {
        if (error) {
            const errors = {}
            for (let e of error) {
                errors[e.field] = e.message
            }
            setErros(errors)
        } else {
            setErros({})
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Nome*
                        </label>
                        <Input
                            className="form-control"
                            placeholder="Nome..."
                            type="text"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />
                        <small class="text-danger">
                            {erros.nome ? erros.nome : ''}
                        </small>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Situação*
                        </label>
                        <Select2
                            defaultValue="A"
                            onSelect={(e) => setSituacao(e.target.value)}
                            options={{
                                placeholder: "Selecione uma situação..."
                            }}
                            value={situacao}
                            data={
                                [
                                    {
                                        id: 'A',
                                        text: 'Ativo'
                                    }, {
                                        id: 'I',
                                        text: 'Inativo'
                                    }
                                ]
                            }
                        />
                        <small class="text-danger">
                            {erros.situacao ? erros.situacao : ''}
                        </small>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="float-right ">
                        <Button
                            className="ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => close()}
                        >
                            Fechar
                          </Button>
                        <Button
                            color="primary"
                            onClick={handleSave}
                        >
                            Salvar
                          </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
}
