import React, { useState, useEffect, useRef } from 'react';
import api from "../../../../../../../services/api";

import Select2 from "react-select2-wrapper";
import NovoMotivo from "../New";
import NotificationAlert from "react-notification-alert";
import {
    Button,
    FormGroup,
    Modal,
    Row,
    Col,
    InputGroupAddon,
    InputGroup
} from "reactstrap";
import {
    SelectContainer
} from './styles'

export default ({ motivosEncerramentos, empresa, close, onSuccess, show, notify, ...props }) => {
    const [motivosEncerramentosPossiveis, setMotivosEncerramentosPossiveis] = useState([]);
    const [motivoEncerramento, setMotivoEncerramento] = useState(undefined);
    const [showNovoMotivo, setShowNovoMotivo] = useState(false)
    const notificationAlert = useRef()

    useEffect(() => {
        loadMotivosEncerramentosPossiveis();
    }, [motivosEncerramentos]);

    async function loadMotivosEncerramentosPossiveis() {
        try {
            const response = await api.get('/common/motivos-encerramentos');

            const motivos = response.data.filter(motivo => motivosEncerramentos.indexOf(motivo.id) === -1);
            setMotivosEncerramentosPossiveis(motivos);
        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível carregar os motivos para encerramentos');
        }
    }

    function handleAddColaborador() {
        if (!motivoEncerramento) {
            return;
        }
        addMotivoEncerramento();
    }

    function handleNovoMotivo(e) {
        e.preventDefault()
        setShowNovoMotivo(true)
    }

    function onAddedNovoMotivo() {
        close()
        onSuccess()
    }

    async function addMotivoEncerramento() {
        try {
            await api.post(`/common/empresas/${empresa.id}/motivos-encerramentos/${motivoEncerramento}`);
            setMotivoEncerramento(undefined);

            onSuccess();
            notify('success', 'Motivo para encerramento adicionado');
        } catch (error) {
            console.log(error);
            notify('danger', 'Não foi possível adicionar motivo para encerramento');
        }
    }


    return (
        <>
            <NovoMotivo
                show={showNovoMotivo}
                empresa={empresa}
                notify={notify}
                onHidde={() => setShowNovoMotivo(false)}
                onSuccess={onAddedNovoMotivo}
            />
            <Modal
                className="modal-dialog-centered"
                size='md'
                isOpen={show}
                toggle={close}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Adicionar Motivo de Checagem
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={close}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationAlert ref={notificationAlert} />
                    <>
                        <Row>

                            <Col lg={12} md={12} style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                flex: 1,
                                display: 'flex'
                            }}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Motivos de checagem
                                    </label>
                                    <InputGroup className="mb-3">
                                        <SelectContainer>
                                            <Select2
                                                maximumInputLength={10}
                                                className="form-control"
                                                style={{ width: '90%' }}
                                                data={motivosEncerramentosPossiveis.map((item) => ({ id: item.id, text: item.nome }))}
                                                onSelect={(e) => setMotivoEncerramento(e.target.value)}
                                                options={{
                                                    placeholder: 'Motivos...'
                                                }}
                                                value={motivoEncerramento}
                                            />
                                        </SelectContainer>
                                        <InputGroupAddon addonType="append">
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={handleNovoMotivo}
                                            >
                                                +
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <div>
                                    <Button
                                        color="primary"
                                        type="button"
                                        outline
                                        onClick={handleAddColaborador}
                                        className="btn-icon btn-3"
                                    >
                                        <span className="btn-inner--icon">
                                            <i className="ni ni-fat-add"></i>
                                        </span>
                                        <span className="btn-inner--text">Adicionar</span>
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </>
                </div>
                <div className="modal-footer">
                    <Row>
                        <Col>
                            <div className="float-right ">
                                <Button
                                    className="ml-auto"
                                    color="link"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={close}
                                >
                                    Fechar
                          </Button>
                            </div>
                        </Col>
                    </Row>
                </div>

            </Modal>
        </>
    );
}
