import React, { useState, useRef, useContext } from 'react';
import Axios from 'axios';
import EmpresaContext from '../../../../contexts/Empresa';
import api from '../../../../services/api';

import NotificationAlert from 'react-notification-alert';
import Table from './Table';
import Filters from './Filters';

// reactstrap components
import { Card, CardBody, CardHeader, Container, Row, Col } from 'reactstrap';
// core components
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
let cancelApi = undefined;

export default function PerformanceGrupo({ ...props }) {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [itens, setItens] = useState([]);
  const [pageProperties, setPageProperties] = useState({
    // total: "0",
    // perPage: 50,
    // page: 1,
    // lastPage: 1,
    loading: false,
    sort: new Map([]),
  });
  const [lastSearch, setLastSearch] = useState({});
  const notificationAlert = useRef();

  function notify(type, msg) {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function loadItens(
    page = 1,
    limit = 10,
    sortField = 'colaborador_nome',
    sortOrder = 'ASC',
    filters = lastSearch,
    empresaId
  ) {
    try {
      if (!empresaId) {
        setItens([]);
        return;
      }

      setLoading(true);
      const cancelSource = Axios.CancelToken.source();
      cancelApi = cancelSource;
      const response = await api.get(
        `common/empresas/${empresaId}/apuracaoencerramentos`,
        {
          cancelToken: cancelSource.token,
          params: {
            page,
            limit,
            sortField,
            sortOrder,
            ...filters,
          },
        }
      );
      if (response.data.length) {
        const map = new Map();
        response.data.forEach((e) => {
          if (map.get(e.colaborador)) {
            const obj = { [e.motivo]: e.count };
            map.set(e.colaborador, { ...map.get(e.colaborador), ...obj });
          } else {
            map.set(e.colaborador, {
              colaborador: e.colaborador,
              [e.motivo]: e.count,
            });
          }
        });

        const newData = [...map.values()];
        setItens(newData);
      } else {
        setItens([]);
      }
    } catch (err) {
      console.error(err);
      notify('danger', 'Houve um problema ao carregar lista.');
    } finally {
      setLoading(false);
    }
  }

  const handleTableChange = async (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    try {
      if (type == 'sort') {
        sortField = sortField.replace('__meta__.', '');
        const value = pageProperties.sort.get(sortField);
        const newOrder = (value || 'asc') == 'desc' ? 'asc' : 'desc';
        const map = pageProperties.sort;
        map.set(sortField, newOrder);
        sortOrder = newOrder;
      }
      if (cancelApi) {
        try {
          // cancelApi.cancel("Request canceled");
        } catch (error) {}
      }
      await loadItens(page == 0 ? 1 : page, sizePerPage, sortField, sortOrder);
    } catch (error) {
      notify('danger', 'Houve um problema ao carregar lista.');
    }
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader
        name="Apuração Encerramentos Grupos"
        parentName="Relatórios"
        {...props}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 100,
                }}
              >
                <Filters
                  {...props}
                  notify={notify}
                  title={<h1>Apuração Encerramentos Grupos</h1>}
                  data={itens}
                  load={(filters, empresaId) => {
                    setLastSearch(filters);
                    loadItens(
                      1,
                      10,
                      'colaborador_nome',
                      'asc',
                      filters,
                      empresaId
                    );
                  }}
                />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Table
                      loading={loading}
                      itens={itens.length ? itens : []}
                      notify={notify}
                      // onTableChange={handleTableChange}
                      // history={props.history}
                      pageProperties={{}}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
