import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../../../../../services/api";
//@ts-ignore
import classnames from "classnames";
import { Empresa } from "../../../../../../entities/Common";
import EmpresaContext from "../../../../../../contexts/Empresa";
import { ApiAccess } from "../../../../../../entities/Security";
// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// @ts-ignore
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "../../../../../../components/Headers/SimpleHeader";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { LabelToken } from "../styles";

const PaginaWeb: React.FC = ({ props }: any) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [apiAccess, setApiAccess] = useState<ApiAccess>({} as ApiAccess);
  const [textCopiar, setTextCopiar] = useState("Copiar token");

  useEffect(() => {
    if (empresaSelecionada && empresaSelecionada.id) loadApiAccess();
  }, []);

  async function loadApiAccess() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/api-access`
      );
      setApiAccess(response?.data);
    } catch (error) {
      console.log(error);
      // notify("danger", "Não foi possível carregar empresa");
    }
  }

  return (
    <>
      <Row>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "end",
          }}
        >
          <h2>Páginas web</h2>
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col lg={10} md={10}>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example-number-input"
            >
              Token
            </label>
            <InputGroup>
              <Input
                className="form-control"
                placeholder=""
                type="text"
                value={apiAccess?.token}
                // @ts-ignore
                onChange={({ target: any }) => setApiAccess(target.value)}
                disabled={true}
              />
              <InputGroupAddon addonType="append">
                <CopyToClipboard
                  text={apiAccess?.token}
                  onCopy={() => setTextCopiar("Copiado")}
                >
                  <Button
                    className="btn-icon-clipboard"
                    id='tooltip982655500'
                    type="button"
                    style={{
                      padding: '8px',
                      margin: '0'
                    }}
                  >
                    <div>
                      <i className="fas fa-copy" />
                    </div>
                  </Button>
                </CopyToClipboard>
                <UncontrolledTooltip
                  delay={0}
                  trigger="hover focus"
                  target='tooltip982655500'
                >
                  {textCopiar}
                </UncontrolledTooltip>
              </InputGroupAddon>
            </InputGroup>

            {/* <small className="text-danger">{erros.urlNps || ""}</small> */}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default PaginaWeb;
