import React, { useState, useRef, useContext, useEffect } from 'react';
import EmpresaContext from '../../../../../../contexts/Empresa';
import api from '../../../../../../services/api';
import NotificationAlert from 'react-notification-alert';

import Filters from './Filters';
import Table from './Table';
import { Button, CardBody, CardHeader, Row, Col } from 'reactstrap';
// core components
import EditarProdutoModal from './ProdutoModal/EditarProduto';
import NovoProdutoModal from './ProdutoModal/NovoProduto';
import ImportarProdutosModal from './ImportarProdutosModal';

export default ({ empresa, ...props }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [produtoSelecionadoId, setProdutoSelecionadoId] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [novoModal, setNovoModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [pageProperties, setPageProperties] = useState({
    total: '0',
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: false,
    sort: new Map([]),
  });
  const [lastSearch, setLastSearch] = useState({});
  const notificationAlert = useRef();
  const toggleImportModal = () => setImportModal(currentState => !currentState)

  useEffect(() => {
    if (!editModal) {
      setProdutos(null);
      loadProdutos();
    }
  }, [editModal]);
  useEffect(() => {
    if (produtoSelecionadoId) {
      setEditModal(true);
    }
  }, [produtoSelecionadoId]);

  function notify(type, msg) {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function updatePageProperties(response) {
    const { total, perPage, page, lastPage } = await response.data;
    await setPageProperties({
      ...pageProperties,
      total,
      perPage,
      page,
      lastPage,
      loading: false,
    });
  }

  async function loadProdutos(
    page = 1,
    limit = 10,
    sortField = 'nome',
    sortOrder = 'desc',
    filters = lastSearch
  ) {
    try {
      setPageProperties({
        ...pageProperties,
        loading: true,
      });
      const response = await api.get(
        `/common/empresas/${empresa?.id}/produtos`,
        {
          params: {
            page,
            limit,
            sortField,
            sortOrder,
            ...filters,
          },
        }
      );
      setProdutos(response.data);
      await updatePageProperties(response);
    } catch (err) {
      console.log(err.response);
      notify('danger', 'Houve um problema ao carregar os produtos.');
    }
  }

  const handleTableChange = async (
    type,
    { page, sizePerPage, sortField, sortOrder, ...props }
  ) => {
    try {
      if (type == 'sort') {
        const value = pageProperties.sort.get(sortField);
        const newOrder = (value || 'asc') == 'desc' ? 'asc' : 'desc';
        const map = pageProperties.sort;
        map.set(sortField, newOrder);
        sortOrder = newOrder;
      }
      await loadProdutos(
        page == 0 ? 1 : page,
        sizePerPage,
        sortField,
        sortOrder
      );
    } catch (error) {
      console.error(error);
      notify('danger', 'Houve um problema ao carregar as publicações.');
    }
  };

  const closeModal = () => {
    setProdutoSelecionadoId(null);
    setEditModal(false);
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>

      <EditarProdutoModal
        isVisible={editModal}
        toggleModal={closeModal}
        produtoId={produtoSelecionadoId}
        empresaId={empresaSelecionada.id}
      />

      <NovoProdutoModal
        isVisible={novoModal}
        toggleModal={() => setNovoModal((prev) => !prev)}
        empresaId={empresaSelecionada.id}
        onReload={loadProdutos}
      />

      <ImportarProdutosModal show={importModal} onClose={toggleImportModal} produtos={produtos}/>

      <div className="mt-5">
        <Row>
          <Col>
            <CardHeader
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 100,
              }}
            >
              <Filters
                load={(filters) => {
                  setLastSearch(filters);
                  loadProdutos(1, 10, 'nome', 'desc', filters);
                }}
              />
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Button
                    disabled={!empresa?.id}
                    color="primary"
                    type="button"
                    onClick={() =>
                      setNovoModal((currentState) => !currentState)
                    }
                    size="sm"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add" />
                    </span>
                    Novo produto
                  </Button>
                  <Button
                    disabled={!empresa?.id}
                    color="primary"
                    type="button"
                    onClick={toggleImportModal}
                    size="sm"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add" />
                    </span>
                    Importar de uma marca
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table
                    produtos={produtos ? produtos.data : []}
                    notify={notify}
                    onTableChange={handleTableChange}
                    history={props.history}
                    pageProperties={pageProperties}
                    loading={loading}
                    onSelect={setProdutoSelecionadoId}
                  />
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
      </div>
    </>
  );
};
