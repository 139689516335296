import React, { useState, useContext, useEffect } from 'react';
import EmpresaContext from "../../../../../../../../contexts/Empresa";
import { Acao } from "../../../../../../../../entities/Mentoring"
import api from "../../../../../../../../services/api"
import { User } from '../../../../../../../../entities/Security';

// import { Container } from './styles';

//@ts-ignore
import Select2 from "react-select2-wrapper"
import {
    Button,
    Row,
    Col,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup
} from "reactstrap";
import { SelectContainer } from "./styles";

type Props = {
    acao: Acao,
    onSuccessInsert(user: User): void,
    onSuccessDelete(user: User): void,
    usersAdded: Array<User>
}


const FieldQuem: React.FC<Props> = ({ acao, onSuccessInsert, onSuccessDelete, usersAdded }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)

    const [users, setUsers] = useState(new Array<User>())
    const [user, setUser] = useState<number | undefined>()
    const [allUsers, setAllUsers] = useState(new Array<User>())

    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        reloadUser()
    }, [usersAdded, allUsers])

    async function loadUser() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/colaboradores`)
            setUsers(await response.data)
            setAllUsers(await response.data)
        } catch (error) {
            console.error(error)
        }
    }

    async function reloadUser() {
        try {
            
            let newList = [...allUsers];
            newList = newList.filter((item)=>{return !usersAdded.find((user)=>{return user.id == item.id})})
            setUsers(newList)
            setUser(undefined)

        } catch (error) {
            console.error(error)
        }
    }
    async function handlerAddUser() {
        try {
            const newUser = users.find((item) => { return item.id == user })
            if (newUser) {
                onSuccessInsert(newUser)
            }
        } catch (error) {
            console.error(error)
        }
    }


    return (
        <Row>
            <Col>
                <FormGroup>
                    <legend className="w-auto" style={{ margin: 0 }}>
                        <label
                            className="form-control-label"
                        >
                            Quem*
                        </label>
                    </legend>
                    <SelectContainer>
                        <Select2
                            className="form-control"
                            //@ts-ignore       
                            onSelect={(e) => setUser(e.target.value)}
                            data={users.map(item => ({ id: item.id, text: item.name }))}
                            value={user}
                        />
                        <Button
                            color="primary"
                            onClick={handlerAddUser}
                        >
                            +
                            </Button>
                    </SelectContainer>
                </FormGroup>
            </Col>
        </Row>
    )
}

export default FieldQuem;