import styled from 'styled-components'

export const Container = styled.div`
  background-size: cover;
  background-position: center top;
  
`;

export const LoadingContainer = styled.div`
   flex: 1;
   display: flex;
   height: 100%;
   justify-content: center;
   align-items: center;
`