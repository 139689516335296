import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { EmpresaMarca } from '../..';
import api from '../../../../../../../services/api';

interface CardProps extends EmpresaMarca {
  onEdit: () => void;
  onReload: () => void;
}
export default function MarcaCard({
  id,
  catalogo,
  logo,
  logomarca_url,
  nome,
  onEdit,
  onReload,
}: CardProps) {
  async function handleRemove() {
    try {
      if (id) {
        await api.delete(`/common/empresas/minhas-marcas/${id}`);
        onReload();
      }
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Card className="d-relative">
      <CardBody
        className="d-flex flex-column align-items-center"
        style={{ gap: '10px' }}
      >
          <CardImg top width="100%" src={logomarca_url} alt="Logomarca da empresa" />

          {/* <img src={logomarca_url} style={{ maxWidth: '100%'}}  /> */}
        <CardTitle tag="h4">{nome}</CardTitle>
        <CardText>
          <div className="mb-1">
            {catalogo ? (
              <span className="text-success">
                <i className="fas fa-check"></i>
              </span>
            ) : (
              <span className="text-danger">
                <i className="fas fa-times"></i>
              </span>
            )}
            <span className="ml-2">Catálogo online</span>
          </div>
          <div>
            {logo ? (
              <span className="text-success">
                <i className="fas fa-check"></i>
              </span>
            ) : (
              <span className="text-danger">
                <i className="fas fa-times"></i>
              </span>
            )}
            <span className="ml-2">Marca nas páginas</span>
          </div>
        </CardText>
      </CardBody>
      <CardFooter className="d-flex justify-content-between">
        <Button size="sm" onClick={handleRemove}>
          Remover
        </Button>
        <Button color="primary" size="sm" onClick={onEdit}>
          Editar
        </Button>
      </CardFooter>
    </Card>
  );
}
