/* eslint-disable no-undef */
import React, { useState } from "react";

export const KpisContext = React.createContext<{
  requestAvaliable: boolean;
  handlerequestAvaliable(value: boolean): void;
}>({
  requestAvaliable: false,
  handlerequestAvaliable: () => null,
});

interface Props {
  children: any;
}

const KpisContextProvider: React.FC<Props> = ({ children }) => {
  const [requestAvaliable, setRequestAvaliable] = useState<boolean>(false);
  const handlerequestAvaliable = (value: boolean) => {
    setRequestAvaliable(value);
  };

  return (
    <KpisContext.Provider value={{ requestAvaliable, handlerequestAvaliable }}>
      {children}
    </KpisContext.Provider>
  );
};

export default KpisContextProvider;
