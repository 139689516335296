import styled from "styled-components";
import { Button } from "reactstrap";
import { getContrastYIQ } from "../../../../../utils";
interface SelectableButtonProps {
  active?: boolean;
  color?: string;
}
export const SelectableButton = styled.button<SelectableButtonProps>`
  ${(props) =>
    props.color &&
    (props.active
      ? `
            color: ${getContrastYIQ(props.color)};
            background-color: ${props.color};
            border-color: ${props.color};
            :hover{
                color: ${getContrastYIQ(props.color)};
                background-color: ${props.color};
                border-color: ${props.color};
            }
            `
      : `
            color: ${props.color};
            border-color: ${props.color};
            :hover{
                color: ${getContrastYIQ(props.color)};
                background-color: ${props.color};
                border-color: ${props.color};
            }
            `)}
`;
