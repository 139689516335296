import React, { useState } from "react";
import { Tooltip } from "reactstrap";

interface TooltipProps {
  message: string;
  target: string;
}

export function InfoToolTip({ message, target }: TooltipProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <Tooltip isOpen={tooltipOpen} target={target} toggle={toggle}>
      {message}
    </Tooltip>
  );
}

