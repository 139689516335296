import React from 'react';
import classnames from "classnames";
import { EstagioEsteira as Estagio } from '../../../../../entities/Marketing';

import {
    NavLink,
    NavItem,
    Button
} from "reactstrap";


interface INav {
    estagio: Estagio,
    active?: boolean,
    position: number,
    totalPositon: number,
    onClick(e: React.MouseEvent): void
}

const NavEstagio: React.FC<INav> = ({ estagio, active, onClick, position }) => {
    return (
        <NavItem
            className={`${active ? 'active' : ''} mb-2 d-flex flex-row align-items-center`}
        >
            <Button
                color={active ? 'primary' : 'link'}
                title={estagio.situacao === 'A' ? 'Ativo' : 'Inativo'}
                size='sm'
            >
                {`${position + 1}`}
            </Button>
            <NavLink
                className={classnames("mb-sm-3 mb-md-0", {
                    active: active
                })}
                onClick={onClick}
                href="#pablo"
                role="tab"
            >
                {
                    estagio.nome
                }
            </NavLink>

        </NavItem>
    )
}

export default NavEstagio;