import styled from "styled-components";

export const TipoEnvioContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

export const SmsContrudoContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
`

export const LabelToken = styled.label`
   font-size: 10px;
   overflow: auto;
   max-width: 100%;
   margin-top: 8px;
   word-wrap: break-word;      /* IE 5.5-7 */
    white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
    white-space: pre-wrap;   
`