import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Badge } from "reactstrap";
import Table from '../../../components/Table'
import { MenuComportamento } from '../../../components/Menus'
import { Meta } from '../../../entities/Marketing';
import Avatar from '../../../components/Avatar'


// import { Container } from './styles';
interface Props {
  metas: Array<Meta>,
  pageProperties: any,
  onTableChange(type: string, sortProperties: { page: number, sizePerPage: number, sortField: string, sortOrder: string }): Promise<void>,
  notify(type: string, msg: string): void,
  loading: boolean
}

const TableMetas: React.FC<Props> = ({ metas, pageProperties, onTableChange, notify, loading, ...props }) => {
  const history = useHistory()

  const listMeses = [
    { id: 1, nome: 'Janeiro' },
    { id: 2, nome: 'Fevereiro' },
    { id: 3, nome: 'Março' },
    { id: 4, nome: 'Abril' },
    { id: 5, nome: 'Maio' },
    { id: 6, nome: 'Junho' },
    { id: 7, nome: 'Julho' },
    { id: 8, nome: 'Agosto' },
    { id: 9, nome: 'Setembro' },
    { id: 10, nome: 'Outubro' },
    { id: 11, nome: 'Novembro' },
    { id: 12, nome: 'Dezembro' }];

  const [columns] = useState([
    {
      dataField: 'acoes',
      formatter: (cell: any, row: any) => acoesFormatter(cell, row)
    },
    {
      dataField: "mes",
      text: 'Período',
      formatter: (cell: any, row: any) => periodoFormatter(row)
    },
    {
      dataField: "valor",
      text: 'Meta',
      formatter: (cell: any, row: any) => valorFormatter(row)
    },
    {
      dataField: "unidade",
      text: 'Unidade',
      formatter: (cell: any, row: any) => unidadeFormatter(row)
    },
    {
      dataField: 'tipo_produto.nome',
      text: 'Tipo de produto'
    },
    {
      dataField: 'created_at',
      text: 'Registrada em',
      sort: true,
      formatter: (cell: any, row: any) => dataFormatter(cell, row)
    },
    {
      dataField: 'criador',
      text: 'Registrada por',
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: any, row: any) => criadorFormatter(row)
    }
  ])


  function criadorFormatter(row: any) {
    var criador = <>
      {
        row.criador &&
        <Avatar
          title={row.criador.name}
          user={row.criador}
          className='avatar-xs'
          style={{
            cursor: 'default'
          }}
        />
      }
    </>
    return criador

  }

  function unidadeFormatter(row: any) {
    var unidade = ''
    if (row) {
      if (row.unidade && row.unidade == 'M') {
        unidade = 'Monetária'
      } else {
        unidade = 'Quantidade'
      }
    }
    return unidade
  }


  function valorFormatter(row: any) {
    var valor = 0;
    if (row) {
      valor = row.valor
      if (row.unidade && row.unidade == 'M') {
        valor = parseFloat(valor.toString());
        return 'R$ ' + valor
      } else {
        valor = parseInt(valor.toString());
      }
    }
    return valor
  }

  function periodoFormatter(row: any) {
    var periodo = '';
    if (row) {
      //@ts-ignore
      periodo = getNomeMes(row.mes);
      periodo += '/' + row.ano
    }
    return periodo
  }

  function getNomeMes(mes: any) {
    if (mes) {
      return listMeses[mes - 1].nome
    }
  }

  function goToEdit(id: number) {
    history.push(`/admin/metas/${id}/edit`)
  }

  const dataFormatter = (cell: any, row: any) => {
    var data = null;
    if (cell) {
      const dt = new Date(cell)
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      {/* @ts-ignore */ }
      data = dt.toLocaleDateString('pt-br', options);
    }

    return (
      <>
        {data ? data : ''}
      </>
    )
  }

  const acoesFormatter = (cell: any, row: { id: any; }) => {
    return (
      <MenuComportamento
        actions={[{
          label: 'Alterar',
          icon: 'far fa-edit',
          onClick: () => goToEdit(row.id)
        }]}
      />
    )
  }

  return (
    <>
      <Table
        columns={columns}
        data={metas}
        pageProperties={pageProperties}
        onTableChange={onTableChange}
        notify={undefined}
        rowEvents={undefined} />
    </>
  );
}

export default TableMetas;