import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import {
  Spinner,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import "./styles.css";

export default ({ itens, pageProperties, notify, ...props }) => {
  const headerStyle = { fontSize: 9 };
  const { ExportCSVButton } = CSVExport;

  let columns = [
    {
      headerStyle: {
        fontSize: 9,
        position: "sticky",
        left: 0,
        zIndex: 10,
      },
      dataField: "colaborador_nome",
      text: "Nome do<br/>Colaborador",
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === "asc") return a.localeCompare(b);
        else return b.localeCompare(a);
      },
      headerFormatter: contacatacaoHeader,
      style: () => ({
        position: "sticky",
        left: 0,
        backgroundColor: "#fff",
      }),
    },
    {
      dataField: "oportunidades_total",
      text: "Oportunidades<br/>Total",
      sort: true,
      headerFormatter: contacatacaoHeader,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "oportunidades_recebidas",
      text: "oportunidades<br/>recebidas",
      sort: true,
      headerFormatter: contacatacaoHeader,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      headerStyle,
      dataField: "oportunidades_cadastradas",
      sort: true,
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>cadastradas",
      headerAlign: "center",
      align: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_em_negociacao",
      sort: true,
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>em negociação",
      headerAlign: "center",
      align: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_perdidas",
      sort: true,
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>perdidas",
      align: "center",
      headerAlign: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_convertidas",
      sort: true,
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>convertidas",
      align: "center",
      headerAlign: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_atrasadas",
      sort: true,
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>atrasadas",
      align: "center",
      headerAlign: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_atrasadas_periodo",
      sort: true,
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>atrasadas periodo",
      align: "center",
      headerAlign: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_agendadas_hoje",
      sort: true,
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>agendadas hoje",
      align: "center",
      headerAlign: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_agendadas_amanha",
      sort: true,
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>agendadas amanhã",
      align: "center",
      headerAlign: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_para_periodo",
      sort: true,
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>para o período",
      align: "center",
      headerAlign: "center",
    },
  ];

  columns = columns.map((c) => ({
    ...c,
    csvText: c.dataField,
    footer: (columnData, index) => {
      if (c.dataField == "colaborador_nome") return "Total";
      return columnData.reduce(
        (acc, item) => parseFloat(acc) + parseFloat(item),
        0
      );
    },
    footerStyle: (_, index) => {
      if (!!index) {
        return {
          textAlign: "center",
        };
      } else {
        return {
          position: "sticky",
          left: 0,
        };
      }
    },
  }));

  function contacatacaoHeader(column) {
    return (
      <>
        <div id={`column-${column.text}`}>
          <div dangerouslySetInnerHTML={{ __html: column.text }} />
          <span className="order-4 position-absolute right-0"></span>
        </div>
      </>
    );
  }

  return (
    <>
      {itens && pageProperties ? (
        // <Table
        //   columns={columns}
        //   data={itens}
        //   pageProperties={pageProperties}
        //   onTableChange={props.onTableChange}
        // />
        <ToolkitProvider
          data={itens}
          columns={columns}
          keyField={"id"}
          exportCSV={{
            fileName: "custom.xls",
            separator: ";",
            noAutoBOM: false,
            blobType: "text/xls;charset=ansi",
          }}
        >
          {(props) => (
            <div className="py-4">
              <UncontrolledDropdown
                group
                size="sm"
                direction="down"
                style={{ position: "absolute", top: -15, right: 30 }}
              >
                <DropdownToggle color="link">
                  <i className="fas fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="#">
                    <ExportCSVButton
                      {...props.csvProps}
                      style={{
                        backgroundColor: "transparent",
                        padding: 0,
                        border: 0,
                        width: "100%",
                        color: "#212529",
                        textAlign: "start",
                      }}
                    >
                      <i className="fas fa-file-export"></i>
                      <span className="ml-1">Exportar</span>
                    </ExportCSVButton>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
                sort={{
                  sortFunc: (a, b, order) => {
                    if (order === "asc") return a - b;
                    else return b - a;
                  },
                }}
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Spinner color="primary" />
        </div>
      )}
    </>
  );
};
