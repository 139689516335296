import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalFooter,
  PopoverBody,
  Row,
  Spinner,
  UncontrolledPopover,
} from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import api from '../../../../../../../../services/api';
import ReactQuill from 'react-quill';

import Select2 from 'react-select2-wrapper';
import NotificationAlert from 'react-notification-alert';
import CurrencyInput from 'react-currency-input';
import ITipoProduto from '../../../../../../../../entities/Marketing/TipoProduto';
import IProduto from '../../../../../../../../entities/Marketing/Produto';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Dropzone } from '../../../../../../../../components/Dropzone';
import { PlanosPagamento } from '../../PlanosPagamento';

interface EditarProdutoModalProps {
  isVisible: boolean;
  toggleModal: () => void;
  empresaId: number;
  produtoId: number;
}

enum Situacao {
  'A',
  'I',
}

interface FileProps {
  name: string;
  preview: string;
  path: string;
  id: number;
  url: string;
  capa: boolean;
  pivot: {
    capa: boolean;
  };
}

interface ProdutosMidias extends IProduto {
  midias: FileProps[];
  descricao: string;
}

const reactQuillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

const reactQuillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

export default function EditarProdutoModal({
  isVisible,
  toggleModal,
  produtoId,
  empresaId,
}: EditarProdutoModalProps) {
  const [nome, setNome] = useState('');
  const [tipoProduto, setTipoProduto] = useState<number | null>(null);
  const [situacao, setSituacao] = useState<Situacao>(0);
  const [preco, setPreco] = useState<number | null>(null);
  const [descricao, setDescricao] = useState('');
  const [saving, setSaving] = useState(false);
  const [tiposProdutos, setTiposProdutos] = useState<ITipoProduto[]>([]);

  const [alert, setAlert] = useState(null);
  const [files, setFiles] = useState<FileProps[]>([]);
  const [parcelas, setParcelas] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<number | null>(null);
  const [preview, setPreview] = useState({
    show: false,
    url: '',
    index: 0,
  });

  const notificationAlert = useRef<NotificationAlert>(null);

  function onClose() {
    setFiles([]);
    setNome('');
    setDescricao('');
    setSituacao(0);
    setTipoProduto(null);
    setPreco(null);
  }

  useEffect(() => {
    loadTiposProdutos();

    if (produtoId) {
      loadProduto(produtoId);
    }
  }, [produtoId]);

  async function loadProduto(id: number) {
    try {
      const { data: produto } = await api.get<ProdutosMidias>(
        `common/empresas/${empresaId}/produtos/${id}`
      );
      setLoadedProduct(produto);

      // Planos com as parcelas que já existem para esse produto, as quais
      // serão usadas para exibir na listagem do componente no modal (readonly)
      carregarParcelas();
    } catch (error) {
      console.log(error);
    }
  }

  async function carregarParcelas() {
    try {
      const { data: parcelas } = await api.get(
        `marketing/produtos/${produtoId}/parcelas`
      );

      setParcelas(parcelas);
    } catch (error) {
      console.error(error);
    }
  }

  function setLoadedProduct(produto: ProdutosMidias) {
    setNome(produto.nome);
    setPreco(produto.preco);
    setTipoProduto(produto.tipo_produto_id);
    setSituacao(produto.situacao);
    setFiles(produto.midias);
    setDescricao(produto.descricao);
    if (produto.midias.length > 0) {
      const cover = produto.midias.find((midia) => midia.pivot.capa);

      if (cover) {
        setSelectedFile(cover.id);
      }
    }
  }

  async function insertFile(files) {
    try {
      if (files && files.length > 0) {
        for (let file of files) {
          let formData = new FormData();
          // @ts-ignore
          formData.append('arquivo', file);

          await api.post(
            `common/empresas/${empresaId}/produtos/${produtoId}/imagens`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }
      }

      loadProduto(produtoId);
    } catch (error) {
      console.error(error);
    }
  }

  async function loadTiposProdutos() {
    try {
      const { data } = await api.get(
        `/common/empresas/${empresaId}/tipos-produtos`,
        {
          params: {
            situacao: 'A',
          },
        }
      );
      setTiposProdutos(data);
    } catch (error) {
      console.log(error);
    }
  }

  const notify = (type, msg) => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 3,
    };
    if (notificationAlert.current)
      notificationAlert.current.notificationAlert(options);
  };

  async function handleSubmit() {
    try {
      await api.put(
        `/common/empresas/${empresaId}/produtos/${produtoId}`,
        bind()
      );
      notify('success', 'Produto salvo com sucesso!');
      toggleModal();
    } catch (error) {
      notify('danger', 'Ocorreu um erro ao tentar cadastrar o produto.');
      console.error(error);
    } finally {
      setSaving(false);
    }
  }

  function bind() {
    return {
      nome,
      situacao,
      tipo_produto_id: tipoProduto,
      preco,
      descricao,
    };
  }

  const nextPreview = (currentIndex: number) => {
    return currentIndex + 1 > files.length - 1 ? 0 : currentIndex + 1;
  };

  const prevPreview = (currentIndex: number) => {
    return currentIndex - 1 < 0 ? files.length - 1 : currentIndex - 1;
  };

  const lightboxPreview = () => {
    if (files.length === 0) return;
    const file = files[preview.index];
    const nextFile = files[nextPreview(preview.index)];
    const prevFile = files[prevPreview(preview.index)];

    const urlNext = produtoId && nextFile.id ? nextFile.url : nextFile.preview;
    const urlPrev = produtoId && prevFile.id ? prevFile.url : prevFile.preview;
    return (
      <>
        {preview.show && (
          <Lightbox
            mainSrc={produtoId && file.id ? file.url : file.preview}
            nextSrc={urlNext}
            prevSrc={urlPrev}
            reactModalStyle={{ overlay: { zIndex: 1051 } }}
            onCloseRequest={() => setPreview({ ...preview, show: false })}
            onMovePrevRequest={() =>
              setPreview({
                ...preview,
                url: urlPrev,
                index: prevPreview(preview.index),
              })
            }
            discourageDownloads={false}
            imageTitle={file.name || file.path}
            onMoveNextRequest={() =>
              setPreview({
                ...preview,
                url: urlNext,
                index: nextPreview(preview.index),
              })
            }
          />
        )}
      </>
    );
  };

  function dropzoneCallback(acceptedFiles) {
    let f = files;
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    f = f.concat(newFiles);
    insertFile(newFiles);

    setFiles(f);
  }

  async function handleRemove(file: FileProps) {
    try {
      if (produtoId && file.id) {
        await api.delete(
          `/common/empresas/${empresaId}/produtos/${produtoId}/imagens/${file.id}`
        );
      }
      await loadProduto(produtoId);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleChangeProductCover(midia_id: number) {
    try {
      await api.put(
        `common/empresas/${empresaId}/produtos/${produtoId}/cover`,
        {
          midia_id: midia_id,
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  const handleRadioChange = (fileId: number) => {
    setSelectedFile(fileId);
    handleChangeProductCover(fileId);
  };

  const thumbs = files?.map((file, key) => (
    <ListGroupItem
      className=" px-0"
      style={{
        paddingTop: 0,
        border: 0,
      }}
    >
      <Row className=" align-items-center" key={key}>
        <Col
          onClick={() =>
            setPreview({
              show: true,
              index: key,
              url: produtoId && file.id ? file.url : file.preview,
            })
          }
          style={{
            cursor: 'pointer',
          }}
          className=" col-auto"
        >
          <div className=" avatar">
            <img
              alt={file.name}
              className=" avatar-img rounded"
              src={produtoId && file.id ? file.url : file.preview}
            />
          </div>
        </Col>
        <Col
          className="col ml-2"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: '0',
          }}
        >
          <div className="">
            <h4
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              className=" mb-1"
              data-dz-name
            >
              {produtoId ? file.name : file.path}
            </h4>
          </div>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <Label check className="mr-4">
            Imagem de capa
          </Label>
          <Input
            type="radio"
            name="coverImage"
            value={file.id}
            checked={selectedFile == file.id}
            onChange={(event) => handleRadioChange(+event.currentTarget.value)}
          />
        </Col>
        <Col className=" col-auto">
          <Button size="sm" color="danger" onClick={(e) => handleRemove(file)}>
            <i className="fas fa-trash" />
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  ));
  return (
    <Modal
      onClosed={onClose}
      className="modal-dialog-centered modal-lg"
      isOpen={isVisible}
      toggle={toggleModal}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Editar Produto
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col sm="12" md="6" lg="6">
            <FormGroup>
              <label className="form-control-label">Nome*</label>
              <Input
                className="form-control"
                placeholder="Nome do produto..."
                type="text"
                value={nome}
                onChange={({ target }) => setNome(target.value)}
              />
              {/* <small className="text-danger">{erros.nome || ''}</small> */}
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="6">
            <FormGroup>
              <label className="form-control-label">Tipo produto*</label>
              <Select2
                className="form-control"
                value={tipoProduto}
                options={{
                  placeholder: 'Selecione um tipo produto...',
                }}
                onSelect={(e: any) => setTipoProduto(e.target.value)}
                data={tiposProdutos.map((item) => ({
                  id: item.id,
                  text: item.nome,
                }))}
              />
              {/* <small class="text-danger">
                      {erros.tipo_produto_id || ''}
                    </small> */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Valor*</label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>R$</InputGroupText>
                </InputGroupAddon>
                <CurrencyInput
                  className="form-control"
                  value={preco}
                  placeholder="Valor do produto..."
                  onChangeEvent={(e, maskedvalue, floatvalue) =>
                    setPreco(floatvalue)
                  }
                />
              </InputGroup>
              {/* <small class="text-danger">{erros.preco || ''}</small> */}
            </FormGroup>
          </Col>
          <Col sm="12" md="6" lg="6">
            <FormGroup>
              <label className="form-control-label">Situação*</label>
              <Select2
                className="form-control"
                value={situacao}
                defaultValue="A"
                options={{
                  placeholder: 'Selecione a situação...',
                }}
                onSelect={(e) => setSituacao(e.target.value)}
                data={[
                  { id: 'A', text: 'Ativo' },
                  { id: 'I', text: 'Inativo' },
                ]}
              />
              {/* <small class="text-danger">{erros.situacao || ''}</small> */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <FormGroup>
              <label className="form-control-label">
                Descrição
                <Button
                  color="secondary"
                  id="desc-tooltip"
                  outline
                  size="sm"
                  type="button"
                >
                  ?
                </Button>
                <UncontrolledPopover placement="top" target="desc-tooltip">
                  <PopoverBody>
                    Utilize este campo para descrever o produto.
                  </PopoverBody>
                </UncontrolledPopover>
              </label>
              <ReactQuill
                value={descricao || ''}
                theme="snow"
                modules={reactQuillModules}
                formats={reactQuillFormats}
                onChange={(content) => setDescricao(content)}
              />
              {/* <small className="text-danger">{erros.descricao || ''}</small> */}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <FormGroup>
              <label className="form-control-label">Planos de Pagamento</label>
              <PlanosPagamento
                notify={notify}
                produtoId={produtoId}
                parcelas={parcelas}
                onReload={carregarParcelas}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <label className="form-control-label">Mídias</label>
              {lightboxPreview()}
              <Dropzone callback={dropzoneCallback} accept="image/*" multiple>
                <label style={{ cursor: 'pointer' }}>
                  Solte seus arquivos aqui...
                </label>
              </Dropzone>
              <ListGroup
                className=" dz-preview dz-preview-multiple list-group-lg list"
                style={{
                  paddingTop: 12,
                }}
                flush
              >
                {thumbs}
              </ListGroup>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <ModalFooter>
        <Row>
          <Col>
            <div className="float-right ">
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
              >
                Voltar
              </Button>
              <Button disabled={saving} color="primary" onClick={handleSubmit}>
                <Spinner
                  hidden={!saving}
                  className="mr-2"
                  color="light"
                  size="sm"
                />
                Salvar
              </Button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
