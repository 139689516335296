import React, { useState, useContext } from 'react';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import EmpresaContext from "../../../../../contexts/Empresa";

import {
    Acao
} from "../../../../../entities/Mentoring";
import { User } from '../../../../../entities/Security';
import BootstrapTable from "react-bootstrap-table-next";
import { MenuComportamento } from '../../../../../components/Menus'

// import { Container } from './styles';
import {
    Badge,
    Button,
    ListGroupItem,
    ListGroup,
    Progress,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import Avatares from '../../../../../components/timeLines/Pdca/Estrategias/Item/Avatares';

type Props = {
    acoes: Array<Acao>,
    loading?: boolean,
}

const Table: React.FC<Props> = ({ acoes, loading }) => {

    const { empresaSelecionada, changeEmpresaSelecionada, empresas } = useContext(EmpresaContext)
    const history = useHistory()

    function userQuensFormatter(row: any, column: any) {
        console.log(column);

        return <Avatares
            users={column.users || new Array<User>()}
        />
    }

    const dataCriacaoFormatter = (column: any) => {
        return (
            <>
                {moment(column.quando).format('D MMM YYYY HH:mm')}
            </>
        )
    }


    function situacaoFormatter(column: any) {
        let situacao;
        switch (column.situacao) {
            case 'nao_iniciado':
                situacao = 'Não Iniciado'
                break;

            case 'concluido':
                situacao = 'Concluído'
                break;

            case 'iniciado':
                situacao = 'Iniciado'
                break;

            case 'nao_executado':
                situacao = 'Não Executado'
                break;

            default:
                break;

        }
        return <div>{situacao}</div>
    }

    function handleEdit(column: any) {
        const empresa = empresas.find(item => item.id === column.objetivo.empresa.id)
        changeEmpresaSelecionada(empresa)
        history.push(`pdcas/${column?.objetivo_id}/edit`)
    }

    function acoesFormatter(row: any, column: any) {
        return (
            <MenuComportamento
                actions={[
                    {
                        label: 'Ver/Alterar',
                        icon: 'far fa-edit',
                        onClick: () => handleEdit(column)
                    }
                ]}
            />
        )
    }


    const [columns, setColumns] = useState([
        {
            dataField: 'objetivo_id',
            formatter: (row: any, column: any) => acoesFormatter(row, column)
        },
        {
            dataField: 'objetivo.empresa.nome',
            text: 'Empresa',
        },
        {
            dataField: "o_que",
            text: 'Ação',
        },
        {
            dataField: "quando",
            text: 'Quando',
            formatter: (row: any, column: any) => dataCriacaoFormatter(column),
            sort: true
        },
        {
            dataField: 'userQuens',
            text: 'Quem',
            formatter: (row: any, column: any) => userQuensFormatter(row, column),
        },
        {
            dataField: 'objetivo.estrategia.nome',
            text: 'Estratégia',
        },
        {
            dataField: 'situacao',
            text: 'Situação',
            formatter: (row: any, column: any) => situacaoFormatter(column),
        },
    ])


    return (
        <BootstrapTable
            remote
            // loading={loading}
            keyField="id"
            //noDataIndication={() => <NoDataIndication />}
            bootstrap4={true}
            bordered={false}
            data={acoes}
            //@ts-ignore
            onSort={(e) => console.log('iuou', e)}
            //@ts-ignore
            columns={columns}
            noDataIndication={() => <></>}
        // onTableChange={onTableChange}
        // overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(150, 150, 150, 0.5)' }) } })}
        // {...paginationTableProps}
        />
    )
}

export default Table;