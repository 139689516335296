import React, { useState, useEffect } from 'react';

import api from "../../../../services/api";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Form from './Form'
import {
  Button,
  Row,
  Col,
  Modal,
} from "reactstrap";

export default ({ notify, empresa, ...props }) => {

  const [tipoProdutos, setTipoProdutos] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [tipoProdutoSelecionado, setTipoProdutoSelecionado] = useState(null)
  const [columns, setColumns] = useState([
    {
      dataField: 'acoes',
      formatter: (cell, row) => acoesFormatter(cell, row)
    },
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true
    },
    {
      dataField: 'situacao',
      text: 'Situação',
      sort: true,
      formatter: (cell, row) => row.situacao == 'A' ? 'Ativo' : 'Inativo',
      align: 'center',
      headerAlign: 'center'
    },
  ])

  useEffect(() => {
    if (tipoProdutos.length === 0) loadTipoProdutos()
  }, [])

  useEffect(() => {
    if (!showModal) {
      setTipoProdutoSelecionado(null)
    }
  }, [showModal])

  function acoesFormatter(cell, row) {
    return (<>
      <div class="btn-group" role="group" aria-label="Basic example">
        <Button
          className="btn-sm"
          color="secondary"
          onClick={() => edit(row.id)}
          outline>
          <i class="far fa-edit"></i>
        </Button>
      </div>
    </>)
  }

  function edit(tipoProdutoId) {
    setTipoProdutoSelecionado(tipoProdutoId)
    setShowModal(true)
  }

  const dateFormater = (date) => {
    return new Date(date).toLocaleDateString('pt-br', { year: 'numeric', month: 'short', day: 'numeric' })
  }

  function handleSaveTipoProduto() {
    notify('success', 'Tipo produto salvo')
    loadTipoProdutos()
    setShowModal(false)
  }

  async function loadTipoProdutos() {
    try {
      const response = await api.get(`/common/empresas/${empresa.id}/tipos-produtos`)
      if (response.data) {
        setTipoProdutos(response.data)
      }
    } catch (error) {
      console.log(error)
      notify('danger', 'Não foi possível carregar tipos de produtos')
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          color="primary"
          type="button"
          className="btn-icon btn-3"
          onClick={() => setShowModal(!showModal)}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-fat-add"></i>
          </span>
          <span className="btn-inner--text">Novo</span>
        </Button>
        <Modal
          className="modal-dialog-centered"
          isOpen={showModal}
          toggle={() => setShowModal(!showModal)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {tipoProdutoSelecionado ? 'Alterar Tipo Produto' : 'Novo Tipo Produto'}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModal(!showModal)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              notify={notify}
              tipoProdutoId={tipoProdutoSelecionado}
              onSuccess={handleSaveTipoProduto}
              close={() => setShowModal(false)}
              empresaId={empresa.id}
            />
          </div>
        </Modal>
      </div>
      <Row>
        <Col>
          <ToolkitProvider
            data={tipoProdutos}
            keyField="nome"
            columns={columns}
            search
          >
            {props => (
              <div className="py-4">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                />
              </div>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </>
  );
}
