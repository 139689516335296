import React from 'react';

import SimpleHeader from '../../../components/Headers/SimpleHeader'
import Form from './Form'

const New: React.FC = () => {

  return (
    <>
      <SimpleHeader name="Nova Nota" parentName="Notas" />
      <Form />
    </>
  )
}

export default New;