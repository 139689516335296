import React from 'react';
import { User } from '../../../../../../entities/Security';

import Avatar from "../../../../../Avatar";

type Props = {
    users: Array<User>,
    center?: boolean
}

const Avatares: React.FC<Props> = ({
    users,
    center = true
}) => {

    return (
        <div
            className={`avatar-group d-flex ${center ? 'justify-content-center' : ''}`}
            style={{zIndex:0}}
        >
            {
                users.map((user, key) => (
                    <>
                        <Avatar
                            className="avatar-sm "
                            href="#"
                            id={`tooltip${key}${user.id}`}
                            user={user}
                            title={user.name}
                            style={{zIndex:0}}
                        />
                    </>
                ))
            }
        </div >
    )
}

export default Avatares;