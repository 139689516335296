import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Card, CardBody, Modal, Row } from "reactstrap";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
import { Container, PersonaButton, PersonaCard } from "./styles";

interface PersonasListProps {
  onSelectPersona: (personaId: number) => void;
  personaId: number;
}

export default function PersonasList({
  onSelectPersona,
  personaId,
}: PersonasListProps) {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [alert, setAlert] = useState(null);
  const [personas, setPersonas] = useState([]);
  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: false,
  });
  const [lastSearch, setLastSearch] = useState({});
  const [selectedPersona, setSelectedPersona] = useState<any | undefined>();
  const [personaName, setPersonaName] = useState<string | null>(null);
  const notificationAlert = useRef();

  // Radio group related state
  const [rSelected, setRSelected] = useState<number | null>(null);

  useEffect(() => {
    if (personaId) loadPersonas();
  }, [personaId]);

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert && notificationAlert.current)
      // @ts-ignore
      notificationAlert.current.notificationAlert(options);
  }

  async function updatePageProperties(response: any) {
    const { total, perPage, page, lastPage } = await response.data;
    setPageProperties({
      total,
      perPage,
      page,
      lastPage,
      loading: false,
    });
  }

  async function loadPersonas(
    page = 1,
    limit = 10,
    sortField = "nome",
    sortOrder = "asc",
    filters = lastSearch
  ) {
    try {
      setPageProperties({
        ...pageProperties,
        loading: true,
      });
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/personas/`,
        {
          params: {
            page,
            limit,
            sortField,
            sortOrder,
            ...filters,
          },
        }
      );

      setPersonas(response.data.data);
      if (personaId) {
        const per = response.data.data.find(
          (persona: any) => persona.id === personaId
        );
        setSelectedPersona(per);
        setPersonaName(per.nome);
      }

      await updatePageProperties(response);
    } catch (err) {
      // @ts-ignore
      console.log(err.response);
      notify("danger", "Houve um problema ao carregar personas.");
    } 
    finally {
      setPageProperties({
        ...pageProperties,
        loading: false,
      });
    }
  }

  function toggleModal() {
    setIsModalVisible(!isModalVisible);
  }

  function handleAddPersonaToTheList(persona: any) {
    // @ts-ignore
    setSelectedPersona(persona);
  }

  function onSubmitPersona() {
    if (!selectedPersona) return;
    onSelectPersona(selectedPersona.id);
    setPersonaName(selectedPersona.nome);
  }

  function capitalizeText(text: string) {
    return text.replace(/([!?.]\s+)([a-z])/g, function (data) {
      return data.toUpperCase();
    });
  }

  function sentenceCase(input: string, lowercaseBefore: boolean) {
    input = input === undefined || input === null ? "" : input;
    if (lowercaseBefore) {
      input = input.toLowerCase();
    }
    return input
      .toString()
      .replace(/(^|\. *)([a-z])/g, function (match, separator, char) {
        return separator + char.toUpperCase();
      });
  }
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isModalVisible}
        toggle={toggleModal}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Personas Ativas
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <ButtonGroup className="d-flex flex-column">
            {personas.map((persona: any, index: number) => (
              <Button
                key={index}
                className="mb-2 border-0 rounded"
                onClick={() => {
                  setRSelected(index);
                  handleAddPersonaToTheList(persona);
                }}
                active={rSelected === index}
              >
                <Card className="m-0">
                  <CardBody>
                    <Row>
                      <PersonaCard className="col ml--2">
                        <h4 className="mb-0 pb-2 text-capitalize text-start">
                          {persona?.nome.toLowerCase()}
                        </h4>
                        <div
                          className="mb-3 p-2 rounded"
                          style={{
                            backgroundColor: "#f5f6f6",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <p className="text-muted mb-0 text-start">
                            {persona?.caracteristicas.charAt(0).toUpperCase() +
                              persona?.caracteristicas.slice(1).toLowerCase()}
                          </p>
                        </div>
                      </PersonaCard>
                    </Row>
                  </CardBody>
                </Card>
              </Button>
            ))}
          </ButtonGroup>
        </div>

        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            Voltar
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              onSubmitPersona();
              toggleModal();
            }}
          >
            Salvar
          </Button>
        </div>
      </Modal>

      <Container>
        <PersonaButton
          type="button"
          color="primary"
          title="Selecionar Persona"
          onClick={() => {
            toggleModal();
            loadPersonas();
          }}
        >
          <span className="persona-name">
            {personaName ? personaName : "Selecionar Persona"}
          </span>
          <span className="btn-inner--icon">
            <i className="fas fa-users"></i>
          </span>
        </PersonaButton>
      </Container>
    </>
  );
}
