import React, { useEffect, useState, useContext } from "react";
import api from "../../../../services/api";
import EmpresaContext from "../../../../contexts/Empresa";
import Avatar from "../../../../components/Avatar";

import { Container, LoadingContainer } from "./styles";

import {
  Container as BootstrapContainer,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
} from "reactstrap";

type Informativo = {
  background_card: string;
  nome: string;
  nome_color: string;

  icon_type: string;
  icon: string;
  background_icon: string;

  descricao: string;
  descricao_color: string;

  value: string;
  value_color: string;
  user: any;
  tempo?: string;
  background: string;
};

interface IIformativos {
  notify(type: string, msg: string): void;
  mes: string;
  ano: string;
  tipoProduto: string;
  unidade: string;
  periodo: any;
  colaborador: any;
}

const Informativos: React.FC<IIformativos> = ({
  notify,
  mes,
  ano,
  tipoProduto,
  unidade,
  periodo,
  colaborador,
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [informativos, setInformativos] = useState(new Array<Informativo>());
  const [loading, setLoaging] = useState(false);

  useEffect(() => {
    loadInformativos();
  }, [mes, ano, tipoProduto, unidade, colaborador, periodo]);

  async function loadInformativos(): Promise<void> {
    setLoaging(true);
    try {
      const response = await api.get(
        `dashboards/empresas/${empresaSelecionada?.id}/informativos-metas`,
        {
          params: {
            ano,
            mes,
            tipoProduto,
            unidade,
            colaborador,
            periodo: periodo
              ? {
                  data_inicio: periodo.data_inicio,
                  data_fim: periodo.data_fim,
                }
              : null,
          },
        }
      );
      const data = await response.data;
      setInformativos(data as Array<Informativo>);
    } catch (error) {
      console.log(error);
      notify("danger", "Não é possível carregar informativos");
    }
    setLoaging(false);
  }

  const CardStats: React.FC<{ informativo: Informativo }> = ({
    informativo,
  }) => (
    <Card className={informativo.background_card}>
      <CardBody>
        <Row>
          <div className="col-9">
            <CardTitle
              className="text-uppercase mb-0 "
              style={{
                color: informativo.nome_color
                  ? informativo.nome_color
                  : "#8898aa",
              }}
            >
              {informativo.nome}
            </CardTitle>
            <span
              className="h2 font-weight-bold mb-0 "
              style={{
                color: informativo.value_color
                  ? informativo.value_color
                  : "#32325E",
                fontSize: "16px",
              }}
            >
              {informativo.value}
            </span>
          </div>
          <Col
            className="col-3"
            style={
              informativo.icon_type == "text"
                ? { paddingRight: 5, paddingLeft: 5, height: 48 }
                : { height: 48 }
            }
          >
            {informativo.icon_type == "avatar" ? (
              <Avatar
                title={informativo?.user?.name}
                user={informativo?.user}
                className="avatar"
              />
            ) : informativo.icon_type == "icon" ? (
              <div
                className={`icon icon-shape ${informativo.background_icon} text-white rounded-circle shadow`}
              >
                <i className={informativo.icon} />
              </div>
            ) : (
              <div>
                <span
                  className="h2 font-weight-bold mb-0 "
                  style={{
                    color: informativo.background_icon
                      ? informativo.background_icon
                      : "#32325E",
                    fontSize: "16px",
                  }}
                >
                  {informativo.icon}
                </span>
              </div>
            )}
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          <span
            className="text-nowrap text-bold"
            style={{
              color: informativo.descricao_color
                ? informativo.descricao_color
                : "#525f7f",
            }}
            dangerouslySetInnerHTML={{ __html: informativo.descricao }}
          />
        </p>
      </CardBody>
    </Card>
  );

  return (
    <div
      style={{
        marginBottom: "-40px",
      }}
    >
      <Container
        className="d-flex align-items-center"
        style={{
          minHeight: "200px",
        }}
      >
        <BootstrapContainer fluid className="mt-2">
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
              {loading ? (
                <LoadingContainer>
                  <Spinner color="secondary" />
                </LoadingContainer>
              ) : (
                <Row>
                  {informativos.map((item, key) => (
                    <Col key={key}>
                      <CardStats informativo={item} />
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
            {/* <Col>
                <InformativosAdicionais notify={notify} />
            </Col> */}
          </Row>
        </BootstrapContainer>
      </Container>
    </div>
  );
};

export default Informativos;
