import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import { usePersistedState } from '../../../../../hooks'

import {
    // global options for the charts
    BarParcial,
} from "../../../../../variables/charts";
import { Bar } from "react-chartjs-2";
import { SpinnerContainer } from '../styles'
import Select2 from "react-select2-wrapper";
import {
    Spinner,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    InputGroup,
    FormGroup,
    PopoverBody,
    UncontrolledPopover,
} from "reactstrap";

function MovimentacaoFunil({ notify, mes, ano, equipe, colaborador, campanha, lastSearch, publicacaoMovimentacao }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [loading, setLoading] = useState(false)
    const [emptyValue, setEmptyValue] = useState(null)

    const [dados, setDados] = useState({})

    const [byEstagioMF, setByEstagioMF] = useState(true)
    const [funilMF, setFunilMF] = usePersistedState('funilMovimentacaoPublicacao', null)
    const [funisListMF, setFunisListMF] = useState([])

    const [primeiroSetByEstagio, setPrimeiroSetByEstagio] = useState(true)
    const [primeiroSetFunil, setPrimeiroSetFunil] = useState(true)

    useEffect(() => {
        (async () => {
            if (funisListMF.length === 0) await loadFunis()
        })()
    }, [])

    useEffect(() => {
        if (!byEstagioMF || byEstagioMF == 'false') {
            if (!primeiroSetByEstagio && !primeiroSetFunil) {
                setFunilMF(null)
            }
        }

        if (!primeiroSetByEstagio) {
            loadGrafico()
        } else {
            setPrimeiroSetByEstagio(false)
        }

    }, [byEstagioMF])

    useEffect(() => {
        createChartData()
    }, [itens])

    useEffect(() => {
        if (!primeiroSetFunil) {
            if (byEstagioMF) {
                loadGrafico()
            }
        } else {
            setPrimeiroSetFunil(false)
        }

    }, [funilMF])

    useEffect(() => {
        if (publicacaoMovimentacao) {
            loadGrafico()
        }
    }, [publicacaoMovimentacao])

    async function loadFunis() {
        const response = await api.get(`common/empresas/${empresaSelecionada?.id}/funis`)
        if (response.data) {
            response.data.unshift({ id: -1, nome: '- Todos -' })
            setFunisListMF(response.data)
        }
    }

    async function loadGrafico() {
        setLoading(true)
        if (lastSearch && publicacaoMovimentacao) {
            try {
                const response = await api.get(`common/empresas/${empresaSelecionada?.id}/movimentacao-funil-publicacao`, {
                    params: {
                        ...lastSearch,
                        publicacao_id: publicacaoMovimentacao.id,
                        byEstagio: byEstagioMF,
                        funil_id: funilMF
                    }
                })
                setItens(await response.data)
            } catch (error) {
                console.error(error)
                notify('danger', 'Não foi possível carregar gráfico de Movimentação Funil Publicações')
            }
        } else {
            notify('danger', 'Não foi possível carregar gráfico de Movimentação Funil Publicações (ERROR)')
        }
        setLoading(false)
    }

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function findColor(textColor) {
        if (textColor === 'primary') {
            return '#5E72E4'
        } else if (textColor === 'info') {
            return '#11CDEF'
        } else if (textColor === 'danger') {
            return '#DE2E2E'
        } else if (textColor === 'default') {
            return '#172b4d'
        } else if (textColor === 'secondary') {
            return '#f4f5f7'
        } else if (textColor === 'success') {
            return '#2dce89'
        } else if (textColor === 'warning') {
            return '#fb6340'
        } else {
            return textColor
        }
    }

    function createChartData() {
        let labels = []
        let datasets = []
        const publicacoes = itens.map(publicacoes => publicacoes);

        if (publicacoes && publicacoes.length > 0) {

            if (!byEstagioMF || byEstagioMF == 'false') {
                labels = ['Oportunidades Perdidas', 'Oportunidades em Negociação', 'Oportunidades Convertidas']
                let data = [publicacoes[0].oportunidades_perdidas, publicacoes[0].oportunidades_em_negociacao, publicacoes[0].oportunidades_convertidas]
                let colors = ['#f5365c', '#5e72e4', '#2dce89']

                datasets = [{
                    data,
                    backgroundColor: colors,
                    borderColor: colors
                }]

            } else {
                const funilBase = publicacoes[0].funil
                if (funilBase) {
                    labels = funilBase.map(estagio => estagio.estagio.nome)
                    let colors = funilBase.map(estagio => estagio.cor)
                    let data = funilBase.map(estagio => estagio.valor)
                    datasets = [{
                        data,
                        backgroundColor: colors,
                        borderColor: colors
                    }]
                }
            }
        }

        setDados({
            labels,
            datasets
        })
    }

    const options = {
        legend: {
            display: false
        },
        tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
                label: function (tooltipItem) {
                    return tooltipItem.yLabel;
                }
            }
        },
        responsive: true,
        scales: {
            xAxes: [{
                barThickness: 20
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    precision: 0
                }
            }]
        }

    }


    return (
        <Card >
            <CardBody>
                <Row>
                    <Col lg="3" sm="3" md="3">
                        <FormGroup>
                            <label className="form-control-label" style={{ marginBottom: 0 }}>
                                Tipo
                                <Button
                                    color="secondary"
                                    id="tooltip8762793492233"
                                    outline
                                    size="sm"
                                    type="button">
                                    ?
                                </Button>
                                <UncontrolledPopover placement="top" target="tooltip8762793492233">
                                    <PopoverBody>
                                        Gráfico por Situação ou Estágios
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </label>
                            <div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline1MF" name="customRadioInline1MF" class="custom-control-input" checked={!byEstagioMF} onChange={({ target }) => setByEstagioMF(!target.checked)}></input>
                                    <label class="custom-control-label" for="customRadioInline1MF">Situação</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline2MF" name="customRadioInline2MF" class="custom-control-input" checked={byEstagioMF} onChange={({ target }) => setByEstagioMF(target.checked)}></input>
                                    <label class="custom-control-label" for="customRadioInline2MF">Estágios</label>
                                </div>
                            </div>
                        </FormGroup>

                    </Col>
                    {byEstagioMF &&
                        <Col sm="6" md="6" lg="6">
                            <label className="form-control-label">
                                Funil
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    className="input-group-alternative"
                                    defaultValue="-1"
                                    options={{
                                        placeholder: "Selecione um funil..."
                                    }}
                                    onSelect={({ target }) => setFunilMF(target.value)}
                                    value={funilMF}
                                    data={funisListMF.map((item) => ({ id: item.id, text: item.nome }))}
                                />
                            </InputGroup>
                        </Col>
                    }
                </Row>

                <div className="">
                    {
                        loading ?
                            <SpinnerContainer>
                                <Spinner
                                    color='primary'
                                    size='lg'
                                    style={{
                                        alignSelf: 'center'
                                    }}
                                />
                            </SpinnerContainer>
                            :
                            <div className='chart'>
                                <Bar
                                    data={{ ...dados }}
                                    // options={BarParcial.options}
                                    options={options}
                                    className="chart-canvas"
                                />
                            </div>
                    }
                </div>


            </CardBody>
        </Card>
    )
}

export default MovimentacaoFunil;