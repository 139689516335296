import React, { useState } from "react";

import Chat from "../../../../components/layouts/Chat/Modal";
import InputMask from "react-input-mask";
import { Button, ListGroup, ListGroupItem, Modal, Badge } from "reactstrap";
import Input from "./Input";

export default ({ show, onHidde, telefones }) => {
  const [contato, setContato] = useState();
  const [showChat, setShowChat] = useState();
  const [clienteId, setClienteId] = useState();

  function handleCloseChat() {
    setShowChat(false);
    setContato(null);
    setClienteId(null);
  }

  function handleShowChat({ ddd, numero, tipo_telefone, cliente_id }) {
    // if (tipo_telefone?.toLowerCase() === 'celular') {
    //     setClienteId(clienteId)
    //     setContato(`55${ddd}${numero}`)
    //     setClienteId(cliente_id)
    //     setShowChat(true)
    // }
  }

  function handleOpenWhatsapp({ ddd, numero, tipo_telefone }) {
    // if (tipo_telefone.toLowerCase() != "celular") return;
    const telefone = `${ddd}${numero}`;
    const win = window.open(
      `https://api.whatsapp.com/send?l=pt_BR&phone=55${telefone}`,
      "_blank"
    );
    win.focus();
  }

  function isCelular({ tipo_telefone }) {
    return tipo_telefone.toLowerCase() == "celular";
  }

  return (
    <>
      {showChat && (
        <Chat
          show={showChat}
          contato={contato}
          cliente_id={clienteId}
          canal={"whatsapp"}
          close={handleCloseChat}
        />
      )}
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={show}
        toggle={onHidde}
      >
        <div className="modal-header ">
          <h5 className="modal-title" id="exampleModalLabel">
            Telefones
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onHidde}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <small className="text-muted font-italic mb-3 d-inline-block ">
            Alterações realizadas aqui não serão salvas.
          </small>
          <ListGroup>
            {telefones.map((item, key) => (
              <span
                key={key}
              >
              
                <Input
                  numero={`${item.ddd}${item.numero}`}
                  tipo={item.tipo_telefone}
                />
              </span>
            ))}
          </ListGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onHidde}
          >
            Voltar
          </Button>
        </div>
      </Modal>
    </>
  );
};
