import React from "react";
import {
  Button,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import QRCode from "./components/QRCode";

// import { Container } from './styles';
interface Props {
  isOpen: boolean;
  anuncioId: number;
  clienteIndicadorId: number;
  data: any;
  hideModal(): void;
  telefones: any;
  notify: any;
}
const GerarLinkQRCode: React.FC<Props> = ({
  isOpen,
  anuncioId,
  clienteIndicadorId,
  data,
  hideModal,
  telefones,
  notify,
}) => {
  return (
    <>
      <Modal
        centered
        size="lg"
        toggle={function noRefCheck() {}}
        isOpen={isOpen}
        className="p-0"
      >
        <ModalBody className="p-0">
          <QRCode
            oportunidade={data}
            hideModal={hideModal}
            telefones={telefones}
            notify={notify}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default GerarLinkQRCode;
