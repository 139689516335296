import React, { useState, useContext, useEffect, useRef } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from '../../../../services/api'
import { AxiosResponse } from 'axios';
import {
    useParams,
    useHistory,
} from "react-router-dom";
import { AnotacaoNota, Nota, ParticipanteNota, TipoNota, Acao } from '../../../../entities/Mentoring';
import { User } from '../../../../entities/Security';
import Anotacoes from './Anotacoes';
import ParticipantesContainer from './Participantes';

//@ts-ignore
import Select2 from "react-select2-wrapper";
import ReactDatetime from "react-datetime";
import ReactQuill from "react-quill";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";

import { CenterContainer } from './styles';
import Acoes from "./Acoes";

import {
    Spinner,
    Container,
    Card,
    CardBody,
    Col,
    Row,
    FormGroup,
    Input,
    CardHeader,
    Button
} from "reactstrap";


export type FormError = {
    data_anotacao: string,
    tema: string,
    tipo_nota: string,
    anotacoes: string,
    participantes: string,
}

const Form: React.FC = () => {

    const { id: nota_id } = useParams() as { id?: number }
    const history = useHistory()
    const { empresaSelecionada } = useContext(EmpresaContext)

    const [nota, setNota] = useState({} as Nota)
    const [tema, setTema] = useState<string>('')
    const [dataAnotacao, setDataAnotacao] = useState<any>()
    const [tipoNota, setTipoNota] = useState<number | null | undefined>()
    const [anotacoes, setAnotacoes] = useState<Array<AnotacaoNota> | undefined>(undefined)

    const [users, setUsers] = useState(new Array<User>())
    const [participantes, setParticipantes] = useState<Array<ParticipanteNota> | undefined>(undefined)
    const [user, setUser] = useState<User | undefined>()

    const [acoes, setAcoes] = useState(new Array<Acao>())

    const [showConfirm, setShowConfirm] = useState(false)

    const [tiposNota, setTiposNota] = useState(new Array<TipoNota>())

    const [alert, setAlert] = useState<any>(undefined)
    const [erros, setErros] = useState<FormError>({} as FormError)
    const [saving, setSaving] = useState(false)
    const [salvarEmanter, setSalvarEmanter] = useState(false)
    const [readOnly, setReadOnly] = useState(false);

    const notificationAlert = useRef<NotificationAlert>(null)

    const [loadingAcoes, setLoadingAcoes] = useState(false)

    async function loadTiposNotas() {
        try {
            const response = await api.get(`mentoring/tipos-notas`)
            if (response.data && response.data.data)
                setTiposNota(await response.data.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos carregar os tipos de Notas')
        }
    }

    useEffect(() => {
        loadTiposNotas()
    }, [])

    useEffect(() => {
        console.log(nota_id)
        if (nota_id) loadNota()
    }, [nota_id])

    useEffect(() => {
        setTema(nota.tema)
        setDataAnotacao(nota.data_anotacao ? new Date(nota.data_anotacao) : null)
        setTipoNota(nota.tipo_nota_id ? nota.tipo_nota_id : null)

        const listParticipantes = participantesToUsers(nota.participantes);
        setUsers(listParticipantes);
        
        if (nota && nota.id) {
            loadAcoes();
        }
    }, [nota])

    async function loadNota() {
        try {
            const response = await api.get(`mentoring/empresas/${empresaSelecionada?.id}/notas/${nota_id}`)
            const data = await response.data
            setNota(data)
        } catch (error) {
            notify('error', 'Não foi possível carregar nota')
            console.error(error)
        }
    }

    async function handleSave(keep: boolean) {
       
        try {
            setErros({} as FormError)
            setSaving(true)

            if (nota.id) {
                await update('Nota Alterada', keep)
            } else {
                await insert('Nota Salva', keep)
            }

        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível salvar Nota')
            //@ts-ignore
            throwError(error.response)
        }
        setSaving(false)
    }

    function afterSave(msg: string, keep: boolean) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
                title={msg}
                onConfirm={() => keep ? setAlert(null) : history.goBack()}
                confirmBtnBsStyle="success"
                // showConfirm={false}
                btnSize=""
            />
        );
        // setTimeout(history.goBack, 2000);
    };

    async function insert(msg: string, keep: boolean) {
        try {
            const newNota = bind()
            const response = await api.post(`mentoring/empresas/${empresaSelecionada?.id}/notas`,
                {
                    ...newNota
                })
            const data = await response.data
            setNota(data)
            afterSave(msg, keep);
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível salvar Nota')
            //@ts-ignore
            throwError(error.response)
        } finally {
            setSaving(false)
        }
    }

    async function update(msg: string, keep: boolean) {
        try {
            const newNota = bind()
            const response = await api.put(`mentoring/empresas/${empresaSelecionada?.id}/notas/${nota.id}`,
                {
                    ...newNota,
                })
            //notify('success', 'Plano Estratégico alterado')      
            const data = await response.data
            setNota(data)
            
            // afterSave(msg);

            if (salvarEmanter == true) {
                setTimeout(setAlert, 2000);
            } else {
                setTimeout(history.goBack, 2000);
            }
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível alterar Nota')
            //@ts-ignore
            throwError(error.response)
        } finally {
            setSaving(false)
        }
    }

    function throwError(response: AxiosResponse) {
        if (response && response.status == 400) {
            if (response.data.length) {
                const responseError = {} as FormError
                for (let e of response.data) {
                    //@ts-ignore
                    responseError[e.field] = e.message
                }
                setErros(responseError)
            }
        }
    }

    function bind(): Nota {
        const nota = {} as Nota;

        nota.tema = tema;
        nota.data_anotacao = dataAnotacao as Date;
        nota.tipo_nota_id = tipoNota as number;
        nota.empresa_id = empresaSelecionada?.id as number;
        nota.anotacoes = anotacoes

        nota.participantes = usersToParticipantes(users);

        return nota;
    }

    function usersToParticipantes(users: Array<User>): Array<ParticipanteNota> {
        let participantes = [] as Array<ParticipanteNota>;
        if (users) {
            for (const user of users) {
                let participante = {} as ParticipanteNota;
                participante.user_id = user.id ? user.id : undefined
                participante.nome = user.name
                participantes.push(participante)
            }
        }
        return participantes
    }

    function participantesToUsers(participantes: Array<ParticipanteNota> | undefined): Array<User> {

        let users = [] as Array<User>;
        if (participantes) {
            for (const participante of participantes) {
                let user = {} as User;
                user.id = participante.user_id ? participante.user_id : null
                user.name = participante.nome ? participante.nome : '';
                user.email = participante.user?.email ? participante.user?.email : '';
                users.push(user)
            }
        }
        return users
    }

    function notify(type: string, msg: string) {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3
        };
        notificationAlert?.current?.notificationAlert(options);
    };

    async function addUser(user: User) {
        if (users.find((item) => { return item.id == user.id })) {
            notify('warning', 'Participante já adicionado anteriormente!')
            return
        }

        try {
            if (nota && nota.id) {
                await insertParticipante(user)
            } else {
                const newList = [...users];
                newList.push(user)
                setUsers(newList)
            }

        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos inserir o Participante')
        }
    }

    function confirmRemove(user: User) {
        setUser(user)
        setShowConfirm(true)
    }

    async function removeParticipante() {
        if (!users.find((item) => { return item.id == user?.id })) {
            notify('warning', 'Participante já removido anteriormente!')
            return
        }

        if (nota && nota.id) {
            try {
                await removeUserFromServer(user?.id as number)
                let newList = [...users];
                newList = newList.filter((item) => { return item.id != user?.id });
                setUsers(newList)
                setUser(undefined)
                setShowConfirm(false)

            } catch (error) {
                //@ts-ignore
                console.error(error.response)
                //@ts-ignore
                if (error.response.data.message) {
                    //@ts-ignore
                    notify('warning', error.response.data.message)
                    setShowConfirm(false)
                } else {
                    notify('danger', 'Não conseguimos remover o participante')
                }
            }

        } else {
            try {
                let newList = [...users];
                newList = newList.filter((item) => { return item.id != user?.id });
                setUsers(newList)
                setUser(undefined)
                setShowConfirm(false)
                notify('success', 'Participante Removido.')
            } catch (error) {
                console.error(error)
                notify('danger', 'Não conseguimos remover o participante')
            }

        }

    }

    async function removeUserFromServer(userId: number) {
        try {
            await api.delete(`mentoring/empresas/${empresaSelecionada?.id}/notas/${nota.id}/participantes/${userId}`)
            notify('success', 'Participante removido da Nota.')

        } catch (error) {
            throw error
        }
    }


    async function insertParticipante(user: User) {
        try {
            await api.post(`mentoring/empresas/${empresaSelecionada?.id}/notas/${nota.id}/participantes`, {
                user_id: user.id,
                nome: user.name
            })

            const { data } = await api.get(`mentoring/empresas/${empresaSelecionada?.id}/notas/${nota.id}/participantes`)
            const listUsers = data.map((participanteSelect: ParticipanteNota) => {
                return participanteSelect.user
            })

            setUsers(listUsers)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos adicionar o participante nesta nota.')
        }
    }

    async function loadAcoes() {
        setLoadingAcoes(true)
        try {
            const response = await api.get(`/mentoring/empresas/${empresaSelecionada?.id}/notas/${nota.id}/acoes`)
            setAcoes(await response.data)
        } catch (error) {
            console.log(error)
            notify('danger', 'Não conseguimos buscar as ações linkadas a esta Nota')
        } finally {
            setLoadingAcoes(false)
        }
    }

    return (
        <>

            <ReactBSAlert
                custom
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                title=''
                onConfirm={() => removeParticipante()}
                onCancel={() => setShowConfirm(false)}
                showCancel
                confirmBtnBsStyle="primary"
                confirmBtnText="Remover"
                cancelBtnText="cancelar"
                btnSize=""
                show={showConfirm}
            >
                {`Deseja remover esse participante da nota?`}
            </ReactBSAlert>



            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            {alert}
            <Container
                className='mt--6'
                fluid
            >
                <Card>
                    <CardHeader
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 2,
                        }}>
                        <h1>{nota.id ? 'Alterar Nota' : 'Nova Nota'}</h1>
                    </CardHeader>
                    <CardBody>

                        <Row>
                            <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                    >
                                        Data Anotação*
                                    </label>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: "Informe a data real da anotação...",
                                        }}
                                        value={dataAnotacao}
                                        dateFormat="DD/MM/YYYY"
                                        timeFormat='HH:mm'
                                        onChange={e => setDataAnotacao(e)}
                                    />
                                    {/* @ts-ignore */}
                                    <small class="text-danger">
                                        {erros.data_anotacao || ''}
                                    </small>
                                </FormGroup>
                            </Col>

                            <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                    >
                                        Tipo*
                                    </label>

                                    <Select2
                                        className="form-control"
                                        //@ts-ignore
                                        onSelect={(e) => setTipoNota(e.target.value)}
                                        value={tipoNota}
                                        data={tiposNota.map(item => ({ id: item.id, text: item.nome, title: item.descricao }))}
                                    />
                                    {/* @ts-ignore */}
                                    <small class="text-danger">
                                        {erros.tipo_nota || ''}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={12} lg={12}
                            >
                                <FormGroup>
                                    <label className="form-control-label" >
                                        Tema*
                                    </label>
                                    <Input
                                        placeholder='Nome...'
                                        className="form-control"
                                        value={tema}
                                        onChange={e => setTema(e.target.value)}
                                    />
                                    <small className="text-danger">
                                        {erros.tema || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                lg='12'
                                style={{
                                    paddingTop: 20
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <h3>Anotações</h3>
                                </div>
                                <Anotacoes
                                    readOnly={readOnly}
                                    notify={notify}
                                    notaId={nota_id}
                                    onAnotacoesChanged={setAnotacoes}
                                />
                                <small className="text-danger">
                                    {erros.anotacoes || ""}
                                </small>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                lg='12'
                                style={{
                                    paddingTop: 20
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <h3>Participantes</h3>
                                </div>
                                <ParticipantesContainer
                                    nota={nota}
                                    users={users}
                                    addUser={addUser}
                                    removeUser={confirmRemove}
                                />
                                <small className="text-danger">
                                    {erros.participantes || ""}
                                </small>
                            </Col>
                        </Row>

                        {(nota && nota.id && nota.id > 0) ?
                            loadingAcoes ?
                                <CenterContainer>
                                    <Spinner
                                        size='lg'
                                        color='primary'
                                    />
                                </CenterContainer>
                                :
                                <Acoes
                                    notify={notify}
                                    notaId={nota_id}
                                    itens={acoes}
                                    reload={loadAcoes}
                                />

                            :
                            <></>
                        }

                        <Row>
                            <Col>
                                <div className="float-right ">
                                    <Button
                                        className="ml-auto"
                                        color="link"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={() => history.goBack()}
                                    >
                                        voltar
                                    </Button>

                                    <Button
                                        disabled={saving}
                                        color="primary"
                                        onClick={() => {
                                            handleSave(true)
                                        }
                                        }
                                    >
                                        {
                                            <Spinner
                                                hidden={!saving}
                                                className="mr-2"
                                                color="light"
                                                size="sm"
                                            />
                                        }
                                        Salvar
                                    </Button>
                                    <Button
                                        disabled={saving}
                                        color="primary"
                                        onClick={() => {
                                            handleSave(false)
                                        }
                                        }                                    >
                                        {
                                            <Spinner
                                                hidden={!saving}
                                                className="mr-2"
                                                color="light"
                                                size="sm"
                                            />
                                        }
                                        Salvar e Fechar
                                    </Button>

                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container >
        </>
    )

}

export default Form;