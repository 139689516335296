import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import AuthContext from "../../../../contexts/Auth";
import api from "../../../../services/api";
import { usePersistedState } from '../../../../hooks/index'
import moment from 'moment'

import { bindErrors } from "../../../../utils";

import { SubmitContainer } from './styles';

import ReactBSAlert from "react-bootstrap-sweetalert";
import Pagination from '../../../../components/Pagination'
import Select2 from "react-select2-wrapper";
import {
  Button,
  ListGroupItem,
  Row,
  Col,
  ListGroup,
  Modal,
  Spinner,
  Badge,
  Progress,
  FormGroup,
  InputGroup,
  UncontrolledPopover,
  PopoverBody
} from "reactstrap";

function Clonar({
  show,
  lastSearch,
  onClose,
  onSuccess,
  notify,
}) {


  const { empresaSelecionada } = useContext(EmpresaContext)

  const [equipe, setEquipe] = useState(null)
  const [membros, setMembros] = useState([])
  const [encaminharPara, setEncaminharPara] = useState(null)

  const [pageProperties, setPageProperties] = useState({})

  const [notClonar, setNotClonar] = useState([])
  const [reiniciar_fase_atual, setReiniciar_fase_atual] = useState(true)

  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [page, setPage] = useState(0)
  const [erros, setErros] = useState({})
  const [alert, setAlert] = useState(null)

  const [empresas, setEmpresas] = usePersistedState('empresas', [])
  const [empresasList, setEmpresasList] = useState([])
  const { hasPermission } = useContext(AuthContext)

  useEffect(() => {
    setNotClonar([])
    setPage(0)
    if (show) loadPublicacoes()
  }, [show])

  useEffect(() => {
    if (empresasList.length === 0) loadEmpresas()
  }, [])

  useEffect(() => {
    setEmpresas(null)
  }, [empresasList])


  async function loadPublicacoes() {
    setLoading(true)
    try {
      const response = await api.get(`common/empresas/${empresaSelecionada?.id}/publicacoes`, {
        params: {
          ...lastSearch,
          limit: 5000
        }
      })
      setPageProperties(await response.data)
    } catch (err) {
      console.error(err)
      notify('danger', 'Houve um problema ao carregar as Publicações.');
    }
    setLoading(false)
  }

  function handleClonar() {
    setAlert(<ReactBSAlert
      warning
      style={{ display: "block" }}
      title={'Clonar ' + renderLabelQuantidadePublicacoes() + '?'}
      onConfirm={clonar}
      onCancel={() => setAlert(null)}
      showCancel
      reverseButtons={true}
      confirmBtnBsStyle="primary"
      confirmBtnText="Sim"
      cancelBtnBsStyle="link"
      cancelBtnText="Não"
      btnSize=""
    >

    </ReactBSAlert>)
  }

  async function clonarTeste() {
    console.log('Botão Clonar')
    console.log('Empresas')
    console.log(empresas)

    console.log('Publicações')
    const publicacoes = pageProperties
      ?.data
      ?.filter(publicacao => !notClonar.includes(publicacao.id))
      ?.map(publicacao => publicacao.id)
    console.log(publicacoes)

    setSaving(true)
    setAlert(null)
    setSaving(false)

  }

  async function clonar() {
    setSaving(true)
    setAlert(null)
    try {
      const publicacoes = pageProperties
        ?.data
        ?.filter(publicacao => !notClonar.includes(publicacao.id))
        ?.map(publicacao => publicacao.id)
      await api.post(`common/empresas/${empresaSelecionada.id}/publicacoes/clonar-publicacoes`,
        {
          destinatario_id: empresas,
          publicacoes,
          reiniciar_fase_atual: reiniciar_fase_atual === true
        })
      showSuccess("Publicações clonadas")
    } catch (error) {
      console.error(error)
      if (error.response) {
        const errorMessage = bindErrors(error.response.data)
        if (errorMessage) {
          errorMessage instanceof String
            ? showError(errorMessage)
            : setErros(errorMessage)
        } else {
          showError('Não foi possível clonar publicações')
        }
      }
    }
    setSaving(false)
  }

  function showSuccess(msg) {
    onSuccess()
    setAlert(<ReactBSAlert
      success
      title={msg}
      onConfirm={() => setAlert(null)}
      onCancel={() => setAlert(null)}
      showConfirm={false}
      timeout={2000}
    />)

    setTimeout(() => onClose(), 2000)
  }

  function showError(msg) {
    setAlert(<ReactBSAlert
      warning
      style={{ display: "block" }}
      title="Atenção"
      onConfirm={() => setAlert(null)}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="warning"
      confirmBtnText="Ok"
      btnSize=""
    >
      {msg}
    </ReactBSAlert>)
  }

  function handleClose() {
    if (!saving) onClose()
  }

  function handleSelectItem(publicacaoId) {
    let list = [...notClonar]
    if (notClonar.includes(publicacaoId)) {
      const index = list.indexOf(publicacaoId)
      list.splice(index, 1)
    } else {
      list.push(publicacaoId)
    }
    setNotClonar(list)
  }

  async function loadEmpresas() {
    try {
      const response = await api.get('security/sessions/empresas')
      if (response.data) {
        setEmpresasList(response.data)
      }
    } catch (error) {
      console.log(error)
      throwError('Não foi possível carregar empresas')
    }
  }

  function throwError(text) {
    if (notify) notify('danger', text)
  }

  function handleMultipleSelect(target, state) {
    const array = Array.from(target.selectedOptions)
    state(array.map((item) => item.value))
  }

  function renderLabelQuantidadePublicacoes() {
    let valor = pageProperties?.data?.length - notClonar.length
    let texto = 'Nenhuma publicação selecionada';
    if (valor && valor > 1) {
      texto = valor + ' publicações selecionadas';
    } else {
      if (valor && valor > 0) {
        texto = valor + ' publicação selecionada';
      }
    }

    return texto
  }

  return (<>
    <Modal
      className="modal-dialog-centered"
      isOpen={show}
      toggle={handleClose}
      size='lg'
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Clonar Publicações
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {alert}
        {
          loading ?
            <Progress
              animated
              value="100"
              color="info"
            />
            :
            <>
              <Row className='mb-4'>
                <Col xs="12" lg="12" sm="12" md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example-number-input"
                    >
                      Empresas Destino*
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Select2
                        multiple
                        onSelect={(e) => handleMultipleSelect(e.target, setEmpresas)}
                        onUnselect={(e) => handleMultipleSelect(e.target, setEmpresas)}
                        options={{
                          placeholder: "Selecione uma empresa..."
                        }}
                        value={empresas}
                        data={empresasList.map((item) => ({ id: item.id, text: item.nome }))}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row className='mb-4'>
                <Col xs="12" lg="12" sm="12" md="12">
                  <FormGroup>
                    <label className="form-control-label">
                      Reiniciar Fase Atual*
                      <Button
                        color="secondary"
                        id="tooltipReinicarCheck"
                        outline
                        size="sm"
                        type="button">
                        ?
                      </Button>
                      <UncontrolledPopover placement="top" target="tooltipReinicarCheck">
                        <PopoverBody>
                          Informe se as publicações que serão geradas devem manter a fase atual das publicações clonadas ou se devem reiniciar para a fase de Planejamento.
                        </PopoverBody>
                      </UncontrolledPopover>
                    </label>
                    <div>
                      <label className="custom-toggle">
                        <input
                          id='reinicarCheck'
                          type="checkbox"
                          value={reiniciar_fase_atual}
                          checked={reiniciar_fase_atual}
                          onChange={({ target }) => setReiniciar_fase_atual(target.checked)}
                        />
                        <span class="custom-toggle-slider rounded-circle" data-label-off="Não" data-label-on="Sim"></span>
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <SubmitContainer>
                <Button
                  onClick={handleClonar}
                  color='primary'
                  disabled={saving || (pageProperties?.data?.length - notClonar.length) <= 0 || !empresas || !empresas.length > 0}
                >
                  {
                    saving &&
                    <Spinner
                      size='sm'
                      className='mr-2'
                    />
                  }
                  Clonar
                </Button>
              </SubmitContainer>
              <small class="text-danger">
                {erros.publicacoes || ""}
              </small>
              <br />
              <small>{renderLabelQuantidadePublicacoes()}</small>
              <ListGroup data-toggle="checklist" flush>
                {
                  pageProperties?.data?.slice(page * 6, (page + 1) * 6)?.map((publicacao, key) => (
                    <ListGroupItem key={key} className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-primary">
                        <div className="checklist-primary">
                          <h5 className="checklist-title mb-0">{publicacao.titulo}</h5>
                          <small>{`Fase Atual: ${publicacao?.faseAtual?.nome || ''}`}</small>
                          <br></br>
                          <small>{`Data Postagem: ${moment(publicacao?.data_postagem).format('D MMM YYYY HH:mm') || ''}`}</small>

                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-primary">
                            <input
                              className="custom-control-input"
                              checked={!notClonar.includes(publicacao.id)}
                              type="checkbox"
                            />
                            <label
                              onClick={() => handleSelectItem(publicacao.id)}
                              className="custom-control-label"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))
                }
              </ListGroup>
              <small>{`${pageProperties?.data?.length} publicações carregadas`}</small>
              {
                pageProperties.data &&
                <Pagination
                  pageProperties={{
                    ...pageProperties,
                    perPage: 6,
                    page: page + 1,
                    lastPage: Math.ceil(pageProperties.total / 6)
                  }}
                  load={(pageProperties) => {
                    setPage(pageProperties?.page - 1)
                  }}
                />
              }
            </>
        }
      </div>
    </Modal>
  </>)
}

export default Clonar;