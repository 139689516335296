import React, { useState, useContext, useEffect, useRef } from 'react';
import EmpresaContext from "../../../../../contexts/Empresa";
import api from '../../../../../services/api'
import { AxiosResponse } from 'axios';
import {
    EstagioEsteira
} from '../../../../../entities/Marketing';

import NavItem from './NavItem'
import {
    Nav,
    Spinner,
    Button
} from "reactstrap";
import {
    Container,
    NavContainer,
    ContentContainer,
} from './styles';
import EstagioForm from "./Form";

interface IEstagios {
    esteiraId?: number,
    notify(type: string, msg: string): void
}

const Estagios: React.FC<IEstagios> = ({ esteiraId, notify }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [estagios, setEstagios] = useState(new Array<EstagioEsteira>())
    const [estagioSelecionado, setEstagioSelecionado] = useState<number | undefined>(undefined)
    const [newEstagio, setNewEstagio] = useState(false)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (esteiraId) loadEstagios()
    }, [esteiraId])

    async function loadEstagios() {
        setLoading(true)
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/esteiras/${esteiraId}/estagios`)
            const data = await response.data
            setEstagios(data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar estágios')
        } finally {
            setLoading(false)
        }
    }

    async function onSaveSuccess() {
        await loadEstagios()
        notify('success', 'Estágio salvo')
        setNewEstagio(false)
    }

    async function onDeletedSuccess() {
        setEstagioSelecionado(undefined)
        setNewEstagio(false)
        await loadEstagios()
        notify('success', 'Estágio removido')
    }

    function handleNewEstagio() {
        setNewEstagio(true)
        setEstagioSelecionado(undefined)
    }

    return (
        <>
            <h2
                style={{ textAlign: 'center' }}
            >
                Estágios
            </h2>
            <Container>

                <NavContainer>
                    <Nav
                        className="nav-fill flex-column flex-sm-column"
                        id="tabs-text"
                        pills
                        role="tablist"
                    >
                        {
                            loading ?
                                <Spinner
                                    className='align-self-center'
                                    color='primary'
                                />
                                :
                                estagios.map((item, key) =>
                                    <NavItem
                                        key={key}
                                        position={key}
                                        totalPositon={estagios.length}
                                        estagio={item}
                                        active={item.id === estagioSelecionado}
                                        onClick={(e: React.MouseEvent) => {
                                            e.preventDefault();
                                            setNewEstagio(false)
                                            setEstagioSelecionado(item.id)
                                        }}
                                    />
                                )
                        }
                        <div
                            className='d-flex justify-content-center'
                        >
                            <Button
                                color='secondary'
                                size='sm'
                                onClick={handleNewEstagio}
                            >
                                + Novo estágio
                        </Button>
                        </div>
                    </Nav>

                </NavContainer>
                <ContentContainer>
                    {
                        newEstagio ?
                            <EstagioForm
                                notify={notify}
                                esteiraId={esteiraId as number}
                                estagio={{} as EstagioEsteira}
                                onSaveSuccess={onSaveSuccess}
                                onDeleteSuccess={onDeletedSuccess}
                            />
                            :
                            estagioSelecionado &&
                            <EstagioForm
                                notify={notify}
                                esteiraId={esteiraId as number}
                                estagio={estagios.find(item => item.id === estagioSelecionado) as EstagioEsteira}
                                onSaveSuccess={onSaveSuccess}
                                onDeleteSuccess={onDeletedSuccess}
                            />
                    }
                </ContentContainer>
            </Container>
        </>
    )
}

export default Estagios;