import React, { useEffect, useState } from "react";
import { CardImg } from "reactstrap";
import api from "../services/api";

const capaF = require("../assets/img/brand/full_logo_ligth.svg");

export default () => {
  const [capa, setCapa] = useState(capaF);
  const [link, setLink] = useState(`https://app.playnee.com`);

  useEffect(() => {
    getCapa();
    getLink();
  }, []);

  async function getCapa() {
    const { data } = await api.get(
      `common/parametros/capa_tela_inicio_playnee`
    );
    if (data.valor != `null`) setCapa(data.valor);
  }
  //
  async function getLink() {
    const { data } = await api.get(
      `common/parametros/link_tela_inicio_playnee`
    );
    if (data.valor) setLink(data.valor);
  }

  return (
    <>
      <div className="bg-info header-chat" style={{ height: '144px'}}>
      </div>
      <div
        style={{
          minHeight: 500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: '-9rem'
        }}
        className="p-4"
      >
        <CardImg
          onClick={() => window.open(link, `_blank`)}
          alt="Card image cap"
          src={capa}
          style={{
            aspectRatio: 9 / 16,
            maxWidth: `100%`,
            borderRadius: 8,
          }}
        />
      </div>
    </>
  );
};
