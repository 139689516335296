import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import ShowModal from './Show'
import TagsInput from "react-tagsinput";

import { LabelWithBedge } from './styles'
import {
    Button,
    Row,
    Col,
    Badge,
} from "reactstrap";


export default ({
    showAddButton,
    onChange,
    publicacaoId,
    marcacoes = [],
}) => {

    const [marcacoesSelecionadas, setMarcacaoesSelecionadas] = useState([])
    const [showModal, setShowModal] = useState(false)

    const showModalMarcacoes = useRef()
    const notificationAlert = useRef()

    useEffect(() => {
        runCallback(marcacoesSelecionadas)
    }, [marcacoesSelecionadas])

    const handleShowModal = () => {
        showModalMarcacoes.current.showModal()
        setShowModal(!showModal)
    }

    function fixMarcacoes(marcacoes) {
        const arrayFixed = []
        if (marcacoes.length > 0) {
            marcacoes.forEach(element => {
                if (element.includes('#')) {
                    let fixed = element.split('#')
                    fixed = fixed.filter(item => item.trim().length > 0)
                    arrayFixed.push(...fixed)
                } else {
                    arrayFixed.push(element)
                }
            });
        }
        return arrayFixed
    }


    const notify = (type, msg) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        notificationAlert.current.notificationAlert(options);
    };

    function addMarcacoes(marcacoes) {
        let newMarcacoes = marcacoesSelecionadas.concat(marcacoes)
        let arr = newMarcacoes.map(x => typeof x === 'string' ? x.toLowerCase() : x);
        newMarcacoes = Array.from(new Set(arr));
        setMarcacaoesSelecionadas(newMarcacoes)
    }

    function runCallback(marcacoes) {
        const fakeEvent = {
            target: {
                name: 'marcacoes',
                value: marcacoes
            }
        }
        onChange(fakeEvent)
    }

    const onTagsChange = tagsinput => {
        if (!tagsinput) return
        const tags = fixMarcacoes(tagsinput)
        let unique = [...new Set(tags)];
        unique = unique.map((item) => item.trim())
        setMarcacaoesSelecionadas(unique)
    };

    function totalMarcacoes() {
        return marcacoes ? marcacoes.length : 0
    }


    return (
        <div style={
            {
                minHeight: 100,
                border: '1px solid #DEE2E6',
                padding: 4,
                borderRadius: 4,
            }}>
            <ShowModal ref={showModalMarcacoes} onMarcacaoSelected={addMarcacoes} />
            <NotificationAlert ref={notificationAlert} />
            {
                showAddButton &&
                <LabelWithBedge>
                    <Button
                        color="secondary"
                        size="sm"
                        type="button"
                        className='float-left'
                        onClick={handleShowModal}
                        outline>
                        + Marcações
                        </Button>
                    <Badge
                        size='sm'
                        color="secondary"
                        title={`${totalMarcacoes()} marcações adicionadas`}
                        className="badge-md badge-circle badge-floating border-white">
                        {totalMarcacoes()}
                    </Badge>
                </LabelWithBedge>
            }
            <Row>
                <Col>
                    <TagsInput
                        onlyUnique={true}
                        className="bootstrap-tagsinput"
                        onChange={onTagsChange}
                        tagProps={{ className: "tag badge mr-1 secondary" }}
                        value={marcacoes || []}
                        inputProps={{
                            className: "secondary",
                            placeholder: "Marcações...",
                            textTransform: "uppercase",
                            style: {

                            }
                        }}
                    />

                </Col>
            </Row>
        </div>
    )
}
