import React, { useEffect, useState } from 'react';

import classnames from "classnames";
import Avatar from '../../../../components/Avatar'
import {
    Button,
    Row,
    Col,
    Modal,
} from "reactstrap";


export default ({ show, onHidden, desigerSelecionado, designers = [], onConfirm, confirmButtonText = 'Confirmar' }) => {

    const [selecionado, setSelecionado] = useState(null)

    useEffect(() => {
        const result = designers.find(item => item.id == desigerSelecionado)
        setSelecionado(result)
    }, [desigerSelecionado, show])

    function handleConfirm() {
        onConfirm(selecionado)
    }

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={show}
                toggle={onHidden}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Designers Disponíveis
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidden}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div
                        style={{
                            textAlign: 'center'
                        }}
                        className='text-muted mb-4'>
                        Selecione um designer que será responsável por está criação.
                    </div>

                    {
                        designers.length == 0 &&
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: 'small'
                            }}
                            className='text-muted mb-4'>
                            Nenhum designer encontrado.
                    </div>

                    }

                    <Row>
                        {
                            designers.map((item, key) =>
                                <Col key={key}>
                                    <Button
                                        style={{
                                            borderRadius: 100,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        size={'sm'}
                                        aria-pressed={item == selecionado}
                                        autoComplete="off"
                                        color="secondary"
                                        className={classnames("focus", { active: item == selecionado }, 'mb-3')}
                                        onClick={() => setSelecionado(item)}
                                    >
                                        <Avatar key={key} user={item} className="mr-2 avatar-sm" />
                                        {item.name}
                                    </Button>
                                </Col>
                            )
                        }
                    </Row>

                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidden}
                    >
                        Cancelar
                        </Button>
                    <Button
                        disabled={!selecionado}
                        color="primary"
                        onClick={handleConfirm}
                        type="button">
                        {confirmButtonText}
                    </Button>
                </div>
            </Modal>
        </>
    );
}
