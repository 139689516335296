import styled from 'styled-components';
import { Jumbotron } from "reactstrap";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  

  @media (max-width: 768px) { 
        flex-direction: column;
    }
`;

export const NavContainer = styled(Jumbotron)`
    display: flex;
    flex-direction: column;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-right: 0;
    padding-left: 8px;
    position: sticky;
    top: 0;
    flex: .2;

    @media (max-width: 768px) { 
        flex: 1;
    }
`

export const ContentContainer = styled.div`
    display: flex;
    flex: .8;

    @media (max-width: 768px) { 
        flex: 1;
    }
`