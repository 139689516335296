import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import moment from 'moment'

import {
    chartExample3,
} from "../../../../../variables/charts";
import { Line } from "react-chartjs-2";
import { SpinnerContainer } from '../styles'
import Select2 from "react-select2-wrapper";
import {
    Spinner,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    InputGroup,
} from "reactstrap";

function ParcialOpotunidadesMes({ notify, mes, ano, equipe, colaborador, campanha }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [loading, setLoading] = useState(false)
    const [emptyValue, setEmptyValue] = useState(null)

    useEffect(() => {
        load()
    }, [mes, ano, equipe, colaborador, campanha])


    async function load() {
        setLoading(true)
        try {
            const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/parcial-oportunidades-mes`, {
                params: {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    campanha_id: campanha
                }
            })
            setItens(await response.data)
        } catch (error) {
            console.error(error)
            notify('Não foi possível carregar grafico de campanhas')
        }
        setLoading(false)
    }

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function createChartData() {

        const datasets = []

        const labels = itens.map(item => moment(item.periodo).format('MMM/YYYY'))
        const dataTotal = itens.map(item => parseFloat(item.oportunidades))
        const dataConvertidas = itens.map(item => parseFloat(item.oportunidades_convertidas))

        return {
            labels,
            datasets: [
                {
                    label: 'Total',
                    backgroundColor: '#5e72e4',
                    borderColor: '#5e72e4',
                    data: dataTotal,
                    fill: false
                },
                {
                    fill: false,
                    label: 'Convertidas',
                    borderColor: '#2dce89',
                    backgroundColor: '#2dce89',
                    data: dataConvertidas
                }
            ]
        }
    }


    return (
        <Card >
            <CardHeader>
                <h5 class="h3 mb-0">Oportunidades</h5>
                <h6 class="surtitle">Oportunidades criadas e convertidas nos últimos 13 meses</h6>
            </CardHeader>
            <CardBody>
                {/* div abaixo adicionada somente para alinhar com o gráfico do funil que fica na lateral, e recebeu um input, o que acabou desalinhando o dashboard */}
                <div
                    style={{ visibility: 'hidden' }}
                >
                    <InputGroup className="input-group-alternative">
                        <Select2/>
                    </InputGroup>
                </div>
                <div className="">
                    {
                        loading ?
                            <SpinnerContainer>
                                <Spinner
                                    color='primary'
                                    size='lg'
                                    style={{
                                        alignSelf: 'center'
                                    }}
                                />
                            </SpinnerContainer>
                            :
                            <div className='chart'>
                                <Line
                                    data={createChartData()}
                                    options={chartExample3.options}
                                    className="chart-canvas"
                                />
                            </div>
                    }
                </div>


            </CardBody>
        </Card>
    )
}

export default ParcialOpotunidadesMes;