import React, { useState, useEffect } from 'react';
import api from "../../../../../../services/api";

import Select2 from "react-select2-wrapper";
import {
    Button,
    Row,
    Col,
    Input,
    FormGroup,
    Modal
} from "reactstrap"

const Form = ({ notify,
    formatosSelecionados = [],
    empresa,
    show = false,
    calibragemId,
    onSuccess,
    onClose }) => {

    const [calibragem, setCalibragem] = useState({})
    const [formatoId, setFormatoId] = useState(null)
    const [dias, setDias] = useState(null)

    const [erros, setErros] = useState({})
    const [formatos, setFormatos] = useState([])

    useEffect(() => {
        if (formatos.length === 0) loadFormatos()
    }, [])

    useEffect(() => {
        setDias(calibragem.dias)
        setFormatoId(calibragem.formato_id)
    }, [calibragem])

    useEffect(() => {
        setCalibragem({})
    }, [show])

    useEffect(() => {
        if (calibragemId) loadCalibragem()
    }, [calibragemId])

    async function loadCalibragem() {
        try {
            const response = await api.get(`/common/empresas/${empresa.id}/calibragens/${calibragemId}`)
            setCalibragem(response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar calibragem')
        }
    }

    async function loadFormatos() {
        try {
            const response = await api.get('/marketing/formatos')
            setFormatos(response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar formatos')
        }
    }

    function handleSave() {
        updateErros(null)
        if (calibragem.id) {
            update()
        } else {
            insert()
        }
    }

    async function update() {
        try {
            await api.put(`/common/empresas/${empresa.id}/calibragens/${calibragemId}`, {
                dias,
                formato_id: formatoId
            })
            notify('success', 'Calibragem alterada')
            onSuccess()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível salvar calibragem')
            if (error.response) updateErros(error.response.data)
        }
    }

    async function insert() {
        try {
            await api.post(`/common/empresas/${empresa.id}/calibragens`, {
                dias,
                formato_id: formatoId
            })
            notify('success', 'Nova calibragem adicionada')
            onSuccess()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível salvar calibragem')
            if (error.response) updateErros(error.response.data)
        }
    }

    function updateErros(error) {
        try {
            if (error) {
                const errors = {}
                for (let e of error) {
                    errors[e.field] = e.message
                }
                setErros(errors)
            } else {
                setErros({})
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={show}
                toggle={() => onClose()}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {calibragem.id ? 'Alterar Calibragem' : 'Nova Calibragem'}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => onClose()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Formato*
                        </label>
                                <Select2
                                    disabled={calibragem.id}
                                    onSelect={(e) => setFormatoId(e.target.value)}
                                    options={{
                                        placeholder: "Selecione o formato da publicação..."
                                    }}
                                    value={formatoId}
                                    data={formatos
                                        .filter(item => calibragem.id || !formatosSelecionados.includes(item.id))
                                        .map(item => ({ id: item.id, text: item.nome }))}
                                />
                                <small class="text-danger">
                                    {erros.formato_id || ''}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Dias*
                        </label>
                                <Input
                                    className="form-control"
                                    placeholder="Tempo máximo para entregar peça da publicação..."
                                    type="number"
                                    value={dias}
                                    onChange={(e) => setDias(e.target.value)}
                                />
                                <small class="text-danger">
                                    {erros.dias || ''}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => onClose()}
                    >
                        Fechar
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        onClick={handleSave}
                    >
                        Salvar
                    </Button>
                </div>
            </Modal>

        </>
    );
}

export default Form