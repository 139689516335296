import React from "react";
import { CustomBtn, TextContainer } from "./styles";

interface BtnProps {
  waId: string;
  text?: string;
}

export function ContinuarConversaBtn({ waId, text }: BtnProps) {
  let params = new URLSearchParams();

  if (text) {
    params.set("text", text);
  }

  function handleOpenWhatsapp(num: string) {
    const win = window.open(
      `https://wa.me/${num}?${params.toString()}`,
      "_blank"
    );
    if (win) win.focus();
  }
  return (
    <CustomBtn onClick={() => handleOpenWhatsapp(waId)}>
      <div>
        <span className="custom-icon">
          <i className="fab fa-whatsapp"></i>
        </span>
      </div>
      <TextContainer>
        <small className="text-muted d-inline-block text-start">
          WhatsApp Web
        </small>
        <span className="d-inline-block">Iniciar conversa</span>
      </TextContainer>
    </CustomBtn>
  );
}
