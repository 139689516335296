import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button, Form as RBForm, FormGroup, Input, Label } from 'reactstrap';

import { useWhatsappContext } from '../../../../../contexts/Whatsapp';
import { Empresa } from '../../../../../entities/Common';
import { Oportunidade } from '../../../../../entities/Marketing';
import api from '../../../../../services/api';
import AlterarEstagioBtn from '../AlterarEstagioBtn';

interface FormProps {
  empresaSelecionada: Empresa | undefined;
  notify: (type: string, message: string) => void;
}

function extractDateAndTime(inputString: string | Date = new Date()): {
  date: string;
  time: string;
} {
  const dateObj = new Date(inputString);

  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const year = dateObj.getFullYear();

  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes}`;

  return { date: formattedDate, time: formattedTime };
}

const formatPhoneNumber = (phoneNumber: string) => {
  const numero = phoneNumber.slice(2);
  if (numero.length <= 2) {
    return numero;
  } else if (numero.length <= 7) {
    return `${numero.slice(0, 2)} ${numero.slice(2)}`;
  } else {
    return `${numero.slice(0, 2)} ${numero.slice(2, 6)}-${numero.slice(6, 11)}`;
  }
};
export default function Form({ empresaSelecionada, notify }: FormProps) {
  const {
    oportunidadeSelecionada,
    selectedContact,
    atualizarContatos,
    colaboradorSelecionadoId,
    selecionarOportunidade,
  } = useWhatsappContext();

  const [nome, setNome] = useState<string>(() => selectedContact?.name ?? '');
  const [comentario, setComentario] = useState<string>('');
  const [campanhas, setCampanhas] = useState<{ id: number; nome: string }[]>(
    []
  );
  const [campanha, setCampanha] = useState<{
    id: number;
    label: string;
    value: number;
  } | null>();
  const [anuncios, setAnuncios] = useState<
    { id: number; nome: string; funil_padrao_id: string | number }[]
  >([]);
  const [anuncio, setAnuncio] = useState<{
    id: number;
    label: string;
    value: number;
    funil_padrao_id: string | number;
  } | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [telefone, setTelefone] = useState<string>(() => {
    return selectedContact?.wa_id ?? '';
  });
  const [dataCadastroInicial, setDataCadastroInicial] = useState<any>(
    () => extractDateAndTime().date
  );
  const [horario, setHorario] = useState<any>(() => extractDateAndTime().time);

  const history = useHistory();

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      color: 'black',
      zIndex: 10,
      position: 'relative',
    }),
    control: (provided: any) => ({
      ...provided,
      minHeight: '46px',
      border: '1px solid #dee2e6',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#adb5bd',
      fontSize: '14px',
    }),
  };

  function formatarTelefone(formatTelefone: string) {
    if (!telefone) {
      return;
    }
    formatTelefone = formatTelefone.replace(/[^0-9]/g, '');

    let ddd = formatTelefone.substring(2, 4);
    let numero = formatTelefone.substring(4, formatTelefone.length);

    return { ddd, numero: `9${numero}` };
  }

  async function handleSave() {
    try {
      setIsSaving(true);

      if (oportunidadeSelecionada) {
        await handleUpdateOportunidade();
        await salvarComentario(oportunidadeSelecionada.id);
        notify('success', 'Oportunidade atualizada com sucesso!');
      } else {
        if (!nome || !telefone || !campanha?.id || !anuncio?.id) {
          notify('danger', 'Preencha todos os campos');
          return;
        }

        const formData = {
          nome,
          sexo: 'O',
          tipo_pessoa: 'F',
          telefones: [formatarTelefone(telefone!)],
          anuncio_id: anuncio?.id,
          user_id: colaboradorSelecionadoId,
          descricao: 'Essa oportunidade foi importada do Playnee Conversas.',
          data_agendamento: new Date(dataCadastroInicial + ' ' + horario),
          criador_id: colaboradorSelecionadoId,
        };

        if (comentario !== '' && comentario.length < 15) {
          window.alert('Descrição do comentário muito curta.');
          throw new Error('Descrição muito curta');
        }

        let url = `/marketing/oportunidades/oportunidade-simplificada`;

        const { data: oportunidadeCriada } = await api.post<Oportunidade>(
          url,
          formData
        );
        const { data } = await api.get<Oportunidade>(
          `/common/empresas/${empresaSelecionada!.id}/oportunidades/${
            oportunidadeCriada.id
          }`
        );

        if (data) {
          await api.post(`/meta/conversa/${selectedContact!.conversas[0].id}`, {
            oportunidade_id: data.id,
          });

          await api.put(`/meta/accounts/${selectedContact!.account_id}/contacts/${selectedContact!.wa_id}`, {
            name: nome,
          });

          await salvarComentario(data.id);
        }
        atualizarContatos();
        selecionarOportunidade(data);
        notify('success', 'Oportunidade criada com sucesso!');
      }
    } catch (error) {
      console.error(error);
      notify('danger', 'Não foi possível salvar oportunidade');
    } finally {
      setIsSaving(false);
    }
  }

  useEffect(() => {
    if (oportunidadeSelecionada) {
      const { date, time } = extractDateAndTime(
        oportunidadeSelecionada?.data_agendamento
      );
      setDataCadastroInicial(date);
      setHorario(time);
      //@ts-ignore
      setNome(oportunidadeSelecionada?.cliente?.nome);
      setTelefone(selectedContact!.wa_id);
      setCampanha({
        id: oportunidadeSelecionada.campanha_id,
        label: oportunidadeSelecionada.anuncio!.campanha!.nome,
        value: oportunidadeSelecionada.campanha_id,
      });
      setAnuncio({
        id: oportunidadeSelecionada.anuncio_id,
        label: oportunidadeSelecionada.anuncio!.nome,
        value: oportunidadeSelecionada.anuncio_id,
        funil_padrao_id: oportunidadeSelecionada.anuncio!.funil_padrao_id,
      });
    }
  }, [oportunidadeSelecionada]);

  useEffect(() => {
    setCampanha(null);
    setAnuncio(null);
    loadCampanhas();
  }, [empresaSelecionada]);

  useEffect(() => {
    if (!oportunidadeSelecionada) {
      const campanha_id = campanha?.id;
      setAnuncio(null);
      if (campanha_id) loadAnuncios(campanha_id);
    }
  }, [campanha, empresaSelecionada]);

  useEffect(() => {
    if (selectedContact) {
      setNome(selectedContact.name);
      setTelefone(selectedContact.wa_id);
      setDataCadastroInicial(() => extractDateAndTime().date);
      setHorario(() => extractDateAndTime().time);
      setCampanha(null);
      setAnuncio(null);
      setComentario('');
    }
  }, [selectedContact]);

  async function handleUpdateOportunidade() {
    if (!oportunidadeSelecionada) return;
    const formData = {
      user_id: oportunidadeSelecionada.user_id,
      cliente_id: oportunidadeSelecionada.cliente_id,
      campanha_id: oportunidadeSelecionada.campanha_id,
      anuncio_id: oportunidadeSelecionada.anuncio_id,
      estagio_id: oportunidadeSelecionada.estagio_id,
      data_agendamento: new Date(dataCadastroInicial + ' ' + horario),
      expectativa_id: oportunidadeSelecionada.expectativa_id,
    };

    if (comentario !== '' && comentario.length < 15) {
      notify('danger', 'Descrição do comentário muito curta!');
      throw new Error('Descrição muito curta');
    }

    const url = `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeSelecionada.id}`;

    await api.put(url, formData);
  }

  async function loadCampanhas() {
    const { data } = await api.get(
      `/marketing/campanha/${empresaSelecionada?.id}`,
      {
        params: {
          situacao: 'ativo',
        },
      }
    );
    setCampanhas(data);
    if (!oportunidadeSelecionada) return;
    const camp = data.find(
      (el: any) => el.id == oportunidadeSelecionada.campanha_id
    );
    if (camp) {
      setCampanha({
        id: camp.id,
        label: camp.nome,
        value: camp.id,
      });
      loadAnuncios(oportunidadeSelecionada.campanha_id);
    }
  }

  async function loadAnuncios(campanha_id: number) {
    if (campanha_id) {
      const { data } = await api.get(
        `common/empresas/${empresaSelecionada?.id}/campanhas/${campanha_id}/anuncios`,
        {
          params: {
            situacao: 'ativo',
          },
        }
      );
      setAnuncios(data);
      if (!oportunidadeSelecionada?.id) return;
      const anun = data.find(
        (el: any) => el.id == oportunidadeSelecionada.anuncio_id
      );
      if (anun) {
        setAnuncio({
          id: anun.id,
          label: anun.nome,
          value: anun.id,
          funil_padrao_id: anun.funil_padrao_id,
        });
      }
    }
  }

  async function salvarComentario(oportunidadeId: number) {
    if (comentario.length) {
      await api.post(
        `common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/comentarios`,
        {
          descricao: comentario,
        }
      );
    }
  }

  return (
    <RBForm className="rounded p-3 w-full shadow text-white">
      {oportunidadeSelecionada && (
        <div className='mb-2'>
          <AlterarEstagioBtn
            empresa={empresaSelecionada}
            oportunidade={oportunidadeSelecionada}
          />
        </div>
      )}
      <FormGroup className="">
        <Label className="form-control-label" for="nomeInp">
          Nome
        </Label>
        <Input
          disabled={Boolean(oportunidadeSelecionada)}
          type="text"
          name="nome"
          id="nomeInp"
          value={nome}
          onChange={(e) => setNome(e.currentTarget.value)}
          placeholder="Nome do cliente"
        />
      </FormGroup>
      <FormGroup>
        <Label className="form-control-label" for="telInp">
          Telefone
        </Label>
        <Input
          disabled
          type="text"
          name="telefone"
          id="telInp"
          value={formatPhoneNumber(telefone)}
          placeholder="Telefone do cliente"
        />
      </FormGroup>
      <FormGroup>
        <Label className="form-control-label">Data de agendamento</Label>
        <Input
          type="date"
          value={dataCadastroInicial}
          onChange={(e) => {
            return setDataCadastroInicial(e.currentTarget.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label className="form-control-label">Hora de agendamento</Label>
        <Input
          type="time"
          value={horario}
          onChange={(e) => setHorario(e.currentTarget.value)}
        />
      </FormGroup>
      <FormGroup style={{ zIndex: 10, position: 'relative' }}>
        <Label className="form-control-label" for="campanha">
          Campanha
        </Label>
        <Select
          isDisabled={!!oportunidadeSelecionada?.id}
          id="campanha"
          className="basic-single text-dark"
          styles={customStyles}
          classNamePrefix="custom-select"
          placeholder="Selecione uma Campanha"
          isSearchable
          menuPlacement="top"
          options={campanhas.map((item) => ({
            value: item.id,
            label: item.nome,
            id: item.id,
          }))}
          value={campanha}
          onChange={(e) => setCampanha(e)}
        />
      </FormGroup>
      <FormGroup style={{ zIndex: 11, position: 'relative' }}>
        <Label className="form-control-label" for="anuncio">
          Anúncio
        </Label>
        <Select
          id="anuncio"
          isDisabled={!!oportunidadeSelecionada?.id}
          menuPlacement="top"
          className="basic-single text-dark"
          styles={customStyles}
          classNamePrefix="custom-select"
          placeholder="Selecione um Anúncio"
          isSearchable
          options={anuncios.map((item) => ({
            value: item.id,
            label: item.nome,
            id: item.id,
            funil_padrao_id: item.funil_padrao_id,
          }))}
          value={anuncio}
          onChange={(e) => setAnuncio(e)}
        />
      </FormGroup>
      <FormGroup>
        <Label className="form-control-label" for="comentarios">
          Comentários
        </Label>
        <Input
          id="comentarios"
          name="comentarios"
          type="textarea"
          minLength={15}
          aria-describedby="textareaHelpBlock"
          onChange={(e) => setComentario(e.currentTarget.value)}
        />
        <small
          id="textareaHelpBlock"
          className="form-text text-muted"
          style={{ fontSize: '.72rem' }}
        >
          Comentário, se houver, deve conter ao menos 15 caracteres.
        </small>
      </FormGroup>
      <div className="d-flex align-items-center justify-content-between">
        {oportunidadeSelecionada && (
          <span
            className="btn btn-link pl-0"
            style={{
              width: 'max-content',
              textDecoration: 'underline',
            }}
            onClick={() => {
              history.push(
                `/admin/oportunidades/${oportunidadeSelecionada?.id}/edit`
              );
            }}
          >
            Ir para oportunidade
          </span>
        )}
        <Button disabled={isSaving} color="primary" onClick={handleSave}>
          Salvar
        </Button>
      </div>
    </RBForm>
  );
}
