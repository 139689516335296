import React, { useEffect, useState } from 'react';
import moment from "moment";
import { useHistory } from "react-router-dom";

import ModalObjetivos from "./ModalObjetivos";
import {
    Estrategia,
    Acao,
    Objetivo
} from '../../../../../entities/Mentoring';
import {
    getSituacaoFontConfig, fixSituacaoAcao
} from '../../../../../entities/Mentoring/Acao';
import { User } from '../../../../../entities/Security';


import ObjetivoForm from "../../../../../pages/mentoring/Pdca/Objetivo";
import {
    ListGroupItem
} from './styles';
import {
    Button,
    Row,
    Col,
    ButtonGroup
} from "reactstrap";
import Usuarios from "./Avatares";
import { type } from 'os';
import { MenuComportamento } from '../../../../Menus';

type Props = {
    estrategia: Estrategia
}

const Item: React.FC<Props> = ({ estrategia }) => {

    const history = useHistory()
    const [objetivo, setObjetivo] = useState<Objetivo>({} as Objetivo)
    const [acao, setAcao] = useState<Acao>({} as Acao)
    const [showModalObjetivos, setShowModalObjetivos] = useState(false)

    const [situacaoObjetivo, setSituacaoObjetivo] = useState('');


    useEffect(() => {
        const acoes = objetivo?.acoes?.length ? objetivo.acoes : [{}] as Array<Acao>
        setAcao(acoes[0])
        fixSituacaoByObjetivoAndAcao();
    }, [objetivo])

    useEffect(() => {
        let objetivo = {} as Objetivo;
        if (estrategia.objetivos && estrategia.objetivos.length > 0) {
            objetivo = estrategia.objetivos[0]
            setObjetivo(objetivo)
        }
        setObjetivo(objetivo)
    }, [estrategia])

    // function fixSituacaoAcao(situacao?: string): string {
    //     if (!situacao) return ''
    //     return situacao
    //         .toString()
    //         .replace('_', ' ')
    // }

    function fixSituacaoByObjetivoAndAcao() {
        setSituacaoObjetivo('');
        let situacao = '';
        let qtdNaoExecutado = 0;
        if (objetivo && objetivo.data_conclusao) {
            situacao = 'concluido'
        } else {
            let acoes = objetivo.acoes;
            if (acoes) {
                for (var acao of acoes) {
                    if (acao.situacao && (acao.situacao == 'iniciado' || acao.situacao == 'concluido')) {
                        situacao = 'iniciado'
                    } else {
                        if (acao.situacao && acao.situacao == 'nao_executado') {
                            qtdNaoExecutado++;
                            //situacao = 'nao_executado'
                        }
                    }

                }
            }

            if (!situacao || situacao === '') {
                if (qtdNaoExecutado > 0 && qtdNaoExecutado == acoes?.length) {
                    situacao = 'nao_executado'
                } else {
                    situacao = 'nao_iniciado'
                }
            }

        }

        console.log(situacao);
        setSituacaoObjetivo(situacao);
    }

    function handleEdit() {
        history.push(`pdcas/${objetivo?.id}/edit`)
    }

    function handleNew() {
        history.push(`pdcas/new?gruposEstrategicoId=${estrategia.grupo_estrategico_id}&estrategiaId=${estrategia.id}`)
    }

    function viewHistory(objetiveId: number) {
        setShowModalObjetivos(true)
    }


    const acoesFormatter = (acao: Acao) => {


        function createActions() {
            const actions = [
                {
                    label: 'Modificar Vigente',
                    icon: 'far fa-edit',
                    onClick: () => handleEdit()
                },
                {
                    label: ' Novo',
                    icon: 'far fa-plus-square',
                    onClick: () => handleNew()
                },
                {
                    label: 'Ver Histórico',
                    icon: 'far fa-eye',
                    onClick: () => viewHistory(acao.id),
                },
            ]
            if (!objetivo.id) {
                actions.shift()
            }
            return actions
        }

        return (
            <ButtonGroup size='sm' >
                <MenuComportamento
                    actions={createActions()}
                />
            </ButtonGroup >
        )
    }




    return (
        <>
            <ModalObjetivos
                isOpen={showModalObjetivos}
                objetivos={estrategia.objetivos}
                hide={() => setShowModalObjetivos(false)}
            />
            <ListGroupItem
                className="px-0"
            >
                <Row className="align-items-center">
                    <Col className="col ml--2">
                        <h4 className="mb-0">
                            <a
                                title={estrategia.descricao}
                                href="#"
                                onClick={e => e.preventDefault()}>
                                {estrategia.nome}
                            </a>
                        </h4>
                        <span style={getSituacaoFontConfig(situacaoObjetivo)}>● </span>
                        <small
                            style={{ textTransform: 'capitalize' }}
                            title={acao.o_que}
                        >
                            {fixSituacaoAcao(situacaoObjetivo)}
                        </small>
                    </Col>
                    {estrategia.objetivos && estrategia.objetivos.length > 0 && <Col>
                        <h5
                            title='Data da criação do objetivo'
                        >
                            Vigência  {
                                moment(objetivo.created_at).format('L')
                            }
                        </h5>
                    </Col>}
                    <Col>
                        <Usuarios
                            users={objetivo.quem || new Array<User>()}
                        />
                    </Col>
                    <Col className="col-auto">
                        {acoesFormatter(acao)}
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    )
}

export default Item;