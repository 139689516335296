import { formatDate } from "@fullcalendar/core";
import moment from "moment";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import ReactDatetime from "react-datetime";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

const EmailsOrSMSList: React.FC<{ data: any; type: "email" | "sms" }> = ({
  data,
  type,
}) => {
  const [modal, setModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<any>(undefined);

  const toggle = () => setModal(!modal);

  const formatDate = (date: string) => {
    const dt = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return dt.toLocaleDateString("pt-BR", options);
  };

  if (!data?.length) {
    return (
      <Row>
        <Col className="p-4 text-center">
          <p style={{ fontWeight: 500 }}>
            Este cliente não recebeu {type === "email" ? "emails" : "SMS"}.
          </p>
        </Col>
      </Row>
    );
  }

  const readOnly = true;

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        size="lg"
        toggle={toggle}
      >
        {/* <div className="rna-wrapper">
          <NotificationAlert ref={notificationAlert} />
        </div> */}

        <ModalHeader>Detalhes da Mensagem:</ModalHeader>
        <ModalBody className="py-0">
          <Row>
            <Col sm="6" md="6" lg="6">
              <FormGroup>
                <label className="form-control-label">Assunto</label>
                <Input
                  className="form-control"
                  value={
                    type === "email" ? selectedMessage?.email_assunto : "SMS"
                  }
                  disabled
                />
              </FormGroup>
            </Col>

            <Col lg="6" sm="6" md="6">
              <FormGroup>
                <label className="form-control-label">
                  Data de agendamento
                </label>
                <InputGroup className="input-group">
                  {/* @ts-ignore    */}
                  <ReactDatetime
                    locale={"pt-br"}
                    value={
                      selectedMessage?.data_agendamento
                        ? moment(selectedMessage?.data_agendamento)
                        : null
                    }
                    inputProps={{
                      placeholder: "Data de agendamento...",
                      name: "data_agendamento",
                      id: "data_agendamento",
                      autocomplete: "off",
                      disabled: readOnly,
                    }}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12" sm="12" md="12">
              <FormGroup>
                <label className="form-control-label">Conteúdo</label>
                <ReactQuill
                  value={
                    type === "email"
                      ? selectedMessage?.email_conteudo
                      : selectedMessage?.sms_conteudo
                  }
                  theme="snow"
                  modules={{
                    toolbar: false,
                  }}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <ListGroup className="mt-4">
        {data?.map((item: any) => (
          <ListGroupItem
            key={item.id}
            className="list-group-item-action flex-column align-items-start py-3 px-4"
            style={{
              cursor: "pointer",
            }}
            href="#"
            onClick={() => {
              setSelectedMessage(item);
              toggle();
            }}
          >
            <Row>
              <Col
                lg={1}
                sm={2}
                md={1}
                className="d-flex align-items-center justify-content-center"
              >
                {type === "email" ? (
                  <span style={{ fontSize: "24px", color: "Dodgerblue" }}>
                    <i className="fas fa-envelope"></i>
                  </span>
                ) : (
                  <span style={{ fontSize: "24px", color: "Dodgerblue" }}>
                    <i className="fas fa-paper-plane"></i>
                  </span>
                )}
              </Col>
              <Col className="d-flex">
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <div>
                    <div className="d-flex w-100 align-items-center">
                      <h4 className="mb-0">{item.nome}</h4>
                    </div>
                  </div>
                  <div className="mr-4">
                    <small>{formatDate(item?.data_agendamento)}</small>
                  </div>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </>
  );
};

export default EmailsOrSMSList;
