import React, { useState, useEffect, useContext, useRef } from 'react';
import EmpresaContext from '../../../contexts/Empresa'
import classnames from "classnames";
import api from "../../../services/api";

import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Nav,
    Row,
    Col,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import Form from './Form'
import Anuncios from './Anuncios'

export default ({ campanhaId, anuncio, history, ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [campanha, setCampanha] = useState({})
    const [tabActive, setTabActive] = useState('campanha')
    const [alert, setAlert] = useState(null)
    const notificationAlert = useRef()

    const [backToPublicacao, setBackToPublicacao] = useState(false)

    useEffect(() => {
        if (campanhaId && campanhaId != campanha.id) loadCampanha(campanhaId)
    }, [campanhaId])

    
    useEffect(() => {
        if (anuncio?.id) {
            // console.log('USE EFFECT ANUNCIO - DEVE IR PARA AS PUBLICACOES DO ANUNCIO')
            setTabActive('anuncio')
        }
    }, [anuncio])
    
    async function loadCampanha(campanhaId) {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada.id}/campanhas/${campanhaId}`)
            setCampanha(response.data)
        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível carregar campanha')
        }
    }

    const onCampanhaChange = (campanha) => {
        setCampanha(campanha ? campanha : {})
    }

    const notify = (type, msg) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3
        };
        if (notificationAlert.current) notificationAlert.current.notificationAlert(options);
    };

    const successAlert = msg => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", maxWidth: "500px" }}
                title={msg}
                confirmBtnBsStyle="success"
                showConfirm={false}
                btnSize=""
            />
        )
        setTimeout(() => setAlert(null), 2000);
    };

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            {alert}
            <Container className="mt--6" fluid>
                <Card>
                    <CardHeader>
                        <h1>
                            {
                                campanha.id ? 'Gerenciar Campanha ' : 'Nova Campanha'
                            }
                        </h1>
                        <small class="text-muted">{campanha.nome}</small>
                    </CardHeader>
                    <CardBody
                        outline
                        body
                    >

                        <Row>
                            <Col xs={12} lg={12} md={12} sm={12}>
                                <div
                                    className="nav-wrapper"
                                >
                                    <Nav
                                        className="nav-fill flex-column flex-md-row"
                                        pills
                                        role="tablist"
                                    >
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                role="tab"
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: tabActive == 'campanha'
                                                })}
                                                onClick={() => setTabActive('campanha')}
                                            >
                                                <i class="fas fa-ad mr-2"></i>
                                                Campanha
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: tabActive == 'anuncio'
                                                })}
                                                href="#"
                                                onClick={() => setTabActive('anuncio')}
                                                role="tab"
                                                disabled={!campanha.id}
                                            >
                                                <i class="fas fa-ad mr-2"></i>
                                                Anúncios
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                <hr />
                                <div>
                                    <TabContent activeTab={tabActive}>
                                        <TabPane tabId="campanha">
                                            <Form
                                                history={history}
                                                onCampanhaChange={onCampanhaChange}
                                                notify={notify}
                                                campanha={campanha}
                                                successAlert={successAlert}
                                            />
                                        </TabPane>
                                        <TabPane tabId="anuncio">
                                            <Anuncios
                                                history={history}
                                                onCampanhaChange={onCampanhaChange}
                                                notify={notify}
                                                campanha={campanha}
                                                anuncioToGoPublicacoes={anuncio}
                                                successAlert={successAlert}
                                            />
                                        </TabPane>

                                    </TabContent>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
}
