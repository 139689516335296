import React, { useState, useEffect } from 'react';
import api from "../../../services/api";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, } from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import Avatar from '../../../components/Avatar'
import { MenuComportamento } from '../../../components/Menus'

import styled from 'styled-components';

// reactstrap components
import {
    Badge,
    Button,
    ButtonGroup,
} from "reactstrap";

// import { Container } from './styles';

export default ({ artigos, pageProperties, notify, search, ...props }) => {

    const [artigo, setArtigo] = useState(null)

    const [columns, setColumns] = useState([
        {
            dataField: 'acoes',
            formatter: (cell, row) => AcoesFormatter({ cell, row, context: this })
        },
        {
            dataField: "titulo",
            text: 'Título',
            formatter: (row, column) => tituloFormatter(row, column),
            sort: true
        },
        {
            dataField: 'created_at',
            text: 'Data Criação',
            formatter: (row, column) => dataFormatter(row, column),
            align: 'center',
            headerAlign: 'center',
            sort: true
        },
        {
            dataField: 'data_publicacao',
            text: 'Data Publicação',
            formatter: (row, column) => dataFormatter(row, column),
            align: 'center',
            headerAlign: 'center',
            sort: true
        },
        {
            dataField: 'situacao',
            text: 'Situação',
            formatter: (row, column) => situacaoFormatter(row, column),
            align: 'center',
            headerAlign: 'center'
        }
    ])

    const CustomDiv = styled.div`
    p{font-size: 14px
    `

    const tituloFormatter = (cell, row) => {
        return (
            < CustomDiv dangerouslySetInnerHTML={{ __html: cell }}></CustomDiv>
        )
    }

    const dataFormatter = (cell, row) => {
        return (
            <>
                {moment(cell).format('D MMM YYYY HH:mm')}
            </>
        )
    }

    const AcoesFormatter = ({ cell, row, context }) => {


        function createActions() {

            const actions = [
                {
                    label: 'Alterar',
                    icon: 'far fa-edit',
                    onClick: () => goToEdit(row.id)
                }
            ]

            return actions

        }

        return (
            <ButtonGroup size='sm' >
                <MenuComportamento
                    actions={createActions()}
                />
            </ButtonGroup >
        )
    }

    const situacaoFormatter = (cell, row) => {
        let situacao = ''
        let color = 'secondary'
        switch (row.situacao) {
            case 'A':
                situacao = 'Ativo'
                color = 'success'
                break;
            case 'I':
                situacao = 'Inativo'
                color = 'danger'
                break;
        }
        return (

            <>
                <Badge color={color} className="badge-lg" pill>
                    {situacao}
                </Badge>
            </>
        )
    }



    function goToEdit(id) {
        // console.log('ABRINDO EDITAR PUBLICACAO');
        // console.log({props});        
        // console.log({anuncio});
        props.history.push(`/admin/artigos/${new Number(id)}/edit`)
    }

    const NoDataIndication = () => (
        <div className="spinner">
            <div className="rect1" />
            <div className="rect2" />
            <div className="rect3" />
            <div className="rect4" />
            <div className="rect5" />
        </div>
    );

    const RemotePagination = ({ loading, data, page, sizePerPage, onTableChange, totalSize }) => (
        <div>
            <PaginationProvider
                pagination={
                    paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                        alwaysShowAllBtns: true,
                        showTotal: true,
                        withFirstAndLast: true,
                        paginationTotalRenderer: (from, to, size) => {
                            const margin = {
                                paddingLeft: '4px'
                            }
                            return (
                                <span className="react-bootstrap-table-pagination-total" style={margin}>
                                    Exibindo {from} a {to} do total de {totalSize}.
                                </span>
                            )
                        },
                        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                    Exibir{" "}
                                    {
                                        <select
                                            name="datatable-basic_length"
                                            value={currSizePerPage}
                                            aria-controls="datatable-basic"
                                            className="form-control form-control-sm"
                                            onChange={e => onSizePerPageChange(e.target.value)}
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    }{" "}
                                    itens.
                                </label>
                            </div>
                        )
                    })
                }
            >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <div className="py-4 table-responsive">
                            <BootstrapTable
                                remote
                                loading={loading}
                                keyField="id"
                                noDataIndication={() => <NoDataIndication />}
                                bootstrap4={true}
                                bordered={false}
                                data={data}
                                onSort={(e) => console.log('iuou', e)}
                                columns={columns}
                                noDataIndication="Nenhum artigo encontrado"
                                onTableChange={onTableChange}
                                overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(150, 150, 150, 0.5)' }) } })}
                                {...paginationTableProps}
                            />
                        </div>
                    )
                }
            </PaginationProvider>
        </div>
    );


    return (
        <>
            {
                artigos && pageProperties &&
                <RemotePagination
                    data={artigos}
                    page={pageProperties.page}
                    sizePerPage={pageProperties.perPage}
                    totalSize={pageProperties.total}
                    onTableChange={props.onTableChange}
                    loading={pageProperties.loading}
                />
            }
        </>
    );
}
