import React, { useEffect, useState,useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";

import Select2 from "react-select2-wrapper";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
    Button,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    FormGroup,
} from "reactstrap";

export default ({ notify, anuncio, onSuccess, onBack }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [produtos, setProdutos] = useState([]) //Produtos do combo
    const [produto, setProduto] = useState(null) //produto selecionado no combo
    const [produtosAdicionados, setProdutosAdicionados] = useState([]) //produtos já adicionados ao anuncio

    const [columns, setColumns] = useState([
        {
            dataField: 'acoes',
            formatter: (cell, row) => acoesFormatter(cell, row)
        },
        {
            dataField: 'nome',
            text: 'Nome',
            sort: true
        },
        {
            dataField: 'preco',
            text: 'Valor',
            sort: true,
            formatter: (cell, row) => valorFormatter(cell, row)
        },
    ])

    useEffect(() => {
        if (produtos.length === 0) loadProdutos()
    }, [])

    useEffect(() => {
        if (anuncio && anuncio.id) loadProdutosAdicionados()
    }, [anuncio])

    async function loadProdutos() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada.id}/produtos`,
                { params: { limit: 1000, situacao: 'A' } })
            setProdutos(response.data.data.filter(item => !produtosAdicionados.find(p => p.id == item.id)))
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar produtos do select')
        }
    }

    function fixProdutoCombo() {
        return produtos.filter(item => !produtosAdicionados.find(p => p.id == item.id))
    }

    async function handleSave() {
        if (!produto) return
        try {
            await api.post(`common/empresas/${empresaSelecionada.id}/campanhas/${anuncio.campanha_id}/anuncios/${anuncio.id}/produtos/`,
                {
                    produto_id: produto
                });
            notify("success", "Produto salvo");
            setProduto(null)
            loadProdutosAdicionados()
        } catch (error) {
            notify('danger', 'Não foi possível salvar produto')
        }
    }

    async function handleRemove(id) {
        if (window.confirm("Deseja remover o produto?")) {
            try {
                await api.delete(`common/empresas/${empresaSelecionada.id}/campanhas/${anuncio.campanha_id}/anuncios/${anuncio.id}/produtos/${id}`);
                notify("success", "Produto removido");
                loadProdutosAdicionados()
            } catch (error) {
                console.log(error)
                if (error.response && error.response.status == 409) {
                    notify("danger", error.response.data.message);
                }
                else {
                    notify("danger", "Não foi possível remover produto");
                }
            }
        }
    }

    async function loadProdutosAdicionados() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada.id}/campanhas/${anuncio.campanha_id}/anuncios/${anuncio.id}/produtos`)
            setProdutosAdicionados(response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar produtos do select')
        }
    }

    function valorFormatter(cell, row) {
        return (
            <>
                {Number(row.preco).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </>
        )
    }

    function acoesFormatter(cell, row) {
        return (<>
            <div class="btn-group" role="group" aria-label="Basic example">
                <Button
                    className="btn-sm"
                    color="danger"
                    onClick={() => handleRemove(row.id)}
                >
                    <i class="fas fa-trash"></i>
                </Button>
            </div>
        </>)
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Row>
                    <Col lg={12} md={12}>
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="example-number-input"
                            >
                                Produto
                        </label>
                            <InputGroup className="mb-3">
                                <div
                                    style={{
                                        width: '400px'
                                    }}
                                >
                                    <Select2
                                        className="form-control"
                                        onSelect={(e) => setProduto(e.target.value)}
                                        data={fixProdutoCombo().map(it => ({ text: it.nome, id: it.id }))}
                                        value={produto}
                                    />
                                </div>
                                <InputGroupAddon addonType="append">
                                    <Button
                                        color="primary"
                                        type="button"
                                        outline
                                        onClick={handleSave}
                                        className="btn-icon btn-3"
                                    >
                                        <span className="btn-inner--icon">
                                            <i className="ni ni-fat-add"></i>
                                        </span>
                                        <span className="btn-inner--text">Adicionar</span>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            {
                <ToolkitProvider
                    data={produtosAdicionados}
                    keyField="nome"
                    columns={columns}
                >
                    {props => (
                        <div className="py-4 table-responsive">
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            }
        </>
    );
}
