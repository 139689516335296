import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
} from "reactstrap";
import "./styles.css";
import ReactBSAlert from "react-bootstrap-sweetalert";
import api from "../../../../../../services/api";
import { EmpresaModeloSite, ModeloSite, UsuarioModeloSite } from "../../../../../security/Users/me/components/Paginas";

interface SelecionarModeloModalProps {
  onClose: () => void;
  isOpen: boolean;
  pages: ModeloSite[];
  reload: () => Promise<void>;
  currentSiteId: string | number | undefined;
  siteUsuario: UsuarioModeloSite | EmpresaModeloSite| undefined;
}

export function SelecionarModeloModal({
  isOpen,
  onClose,
  pages,
  reload,
  currentSiteId = undefined,
  siteUsuario,
}: SelecionarModeloModalProps) {
  const [selectedModeloSiteId, setSelectedModeloSiteId] = useState<
    string | number | undefined
  >(currentSiteId);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [alert, setAlert] = useState<React.ReactNode | null>(null);
  const [preview, setPreview] = useState("");

  useEffect(() => {
    setSelectedModeloSiteId(currentSiteId);
  }, [currentSiteId]);

  async function handleSave() {
    try {
      if (!selectedModeloSiteId) return;
      await api.patch(`/marketing/modelos-sites/empresa/${siteUsuario?.id}`, {
        modelo_site_id: selectedModeloSiteId,
      });

      reload();
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block" }}
          title="Sucesso!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Modelo selecionado com êxito!
        </ReactBSAlert>
      );
      handleCloseModal();
    } catch (error) {
      console.error(error);
    }
  }

  function handleCloseModal() {
    setSelectedModeloSiteId(currentSiteId);
    onClose();
  }

  return (
    <>
      {alert}
      <Modal
        isOpen={isOpen}
        toggle={handleCloseModal}
        style={{ maxWidth: "90%" }}
      >
        <ModalHeader toggle={handleCloseModal}>Selecione o modelo</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "grid",
              gap: "1rem",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            {pages.map((page, index) => {
              const isCurrentSite =
                selectedModeloSiteId != undefined &&
                selectedModeloSiteId == page.id;
              return (
                <Card key={page.id}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPreview(page.link);
                      setIsFullScreen(true)}}
                    className="magnifier"
                  >
                    <CardImg
                      top
                      width="100%"
                      style={{ maxWidth: "100%" }}
                      src={
                        page.link + "preview.png" ||
                        "https://internal.playnee.com/vendedor/capa.png"
                      }
                      alt="Card image cap"
                    />
                  </div>

                  <CardBody>
                    <CardTitle tag="h5">Modelo {index + 1}</CardTitle>
                    <Button
                      size="sm"
                      color="primary"
                      disabled={isCurrentSite}
                      onClick={() => setSelectedModeloSiteId(page.id)}
                    >
                      {isCurrentSite ? "Modelo Atual" : "Selecionar Modelo"}
                    </Button>
                  </CardBody>
                </Card>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Voltar
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            disabled={
              !selectedModeloSiteId || selectedModeloSiteId == currentSiteId
            }
          >
            Salvar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isFullScreen}
        style={{ maxWidth: "min-content" }}
        toggle={() => setIsFullScreen(false)}
      >
        <img
          src={
            preview + "preview.png" ||
            "https://internal.playnee.com/vendedor/capa.png"
          }
          alt="Card image cap"
          style={{ maxWidth: "1440px", display: "block", borderRadius: "8px" }}
        />
      </Modal>
    </>
  );
}
