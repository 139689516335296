import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardText, Col, Row } from 'reactstrap';

// import NotificationAlert from "react-notification-alert";
import SimpleHeader from '../../../../../components/Headers/SimpleHeader';
import { HeaderContainer } from '../../styles';
import MarcaCard from './components/marca-card';
import MarcaModal from './components/MarcaModal';
import api from '../../../../../services/api';

export interface EmpresaMarca {
  id?: number;
  nome: string;
  logomarca: string;
  logomarca_url: string;
  logo: boolean;
  catalogo: boolean;
}

export default function MinhasMarcas() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [marcasEmpresa, setMarcasEmpresa] = useState<EmpresaMarca[]>([]);
  const [marcaSelecionada, setMarcaSelecionada] = useState<EmpresaMarca | null>(
    null
  );

  const closeModal = () => {
    setIsModalOpen(false);
    setMarcaSelecionada(null);
  };

  const openModal = (empresaMarca: EmpresaMarca | null = null) => {
    setIsModalOpen(true);
    setMarcaSelecionada(empresaMarca);
  };

  useEffect(() => {
    buscarMarcasEmpresa();
  }, []);

  async function buscarMarcasEmpresa() {
    try {
      const { data: marcas } = await api.get(`/common/empresas/minhas-marcas`);
      if (marcas) {
        setMarcasEmpresa(marcas);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {alert}
      <MarcaModal
        onClose={closeModal}
        isOpen={isModalOpen}
        onSuccess={buscarMarcasEmpresa}
        minhasMarcas={marcasEmpresa}
        empresaMarca={marcaSelecionada}
      />
      <div className="rna-wrapper">
        {/* <NotificationAlert ref={notificationAlert} /> */}
      </div>
      <SimpleHeader name="Minhas Marcas" parentName="Minha empresa" />

      <div className="container-fluid mt--6">
        <Card>
          <CardHeader>
            <HeaderContainer>
              <h1>Minhas Marcas</h1>
            </HeaderContainer>
          </CardHeader>
          <CardBody outline body>
            <Row>
              <Col xs={12} sm={6} lg={3}>
                <Card
                  style={{
                    background: '#eee',
                    height: '315px',
                    border: '2px dashed',
                    cursor: 'pointer',
                  }}
                  onClick={() => openModal()}
                >
                  <CardBody
                    className="d-flex align-items-center justify-content-center flex-column"
                    role="button"
                    style={{ cursor: 'pointer' }}
                  >
                    <CardText className="font-weight-bold">
                      <span>
                        <i className="fas fa-plus" />
                      </span>
                    </CardText>
                    <CardText className="font-weight-bold">Nova Marca</CardText>
                  </CardBody>
                </Card>
              </Col>
              {marcasEmpresa.map((empresa) => (
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                >
                  <MarcaCard {...empresa} onEdit={() => openModal(empresa)} onReload={buscarMarcasEmpresa}/>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
