import React, { useState } from "react";
import "./style.css";
import { Button } from "reactstrap";

interface OffcanvasProps {
  isOpen: boolean;
  onClose: () => void;
  toggle: () => void;
}

const Offcanvas: React.FC<OffcanvasProps> = ({
  isOpen,
  onClose,
  children,
  toggle,
}) => {
  return (
    <div className={`offcanvas-model ${isOpen ? "open" : ""}`}>
      <Button
        size="sm"
        color="secondary"
        className="mb-4"
        onClick={toggle}
        style={{
          position: "absolute",
          left: isOpen ? "-90px" : "-96px",
          top: "8px",
          zIndex: 10,
        }}
      >
        {isOpen ? (
          <>
            <i className="fas fa-chevron-right"></i> Esconder
          </>
        ) : (
          <>
            <i className="fas fa-chevron-left"></i> Exibir
          </>
        )}
      </Button>
      <div className="offcanvas-model-content">{children}</div>
    </div>
  );
};

export default Offcanvas;
