import React, { useState, useRef, useContext } from 'react';
import EmpresaContext from "../../../contexts/Empresa";
import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";

import Filters from './Filters'
import Table from './Table'
import {
    Button,
    UncontrolledTooltip,
    Badge,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

export default ({ ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [produtos, setProdutos] = useState([])
    const [pageProperties, setPageProperties] = useState({
        total: "0",
        perPage: 10,
        page: 1,
        lastPage: 1,
        loading: false,
        sort: new Map([])
    })
    const [lastSearch, setLastSearch] = useState({})
    const notificationAlert = useRef();


    function notify(type, msg) {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (notificationAlert) notificationAlert.current.notificationAlert(options);
    };

    async function updatePageProperties(response) {
        const { total, perPage, page, lastPage } = await response.data
        await setPageProperties({
            ...pageProperties,
            total,
            perPage,
            page,
            lastPage,
            loading: false,
        })
    }

    async function loadProdutos(page = 1,
        limit = 10,
        sortField = "nome",
        sortOrder = "desc",
        filters = lastSearch) {
        try {
            setPageProperties({
                ...pageProperties,
                loading: true
            })
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/produtos`, {
                params: {
                    page,
                    limit,
                    sortField,
                    sortOrder,
                    ...filters
                }
            })
            setProdutos(response.data)
            await updatePageProperties(response)
        } catch (err) {
            console.log(err.response)
            notify('danger', 'Houve um problema ao carregar os produtos.');
        }
    }

    const handleTableChange = async (type, { page, sizePerPage, sortField, sortOrder, ...props }) => {
        try {
            if (type == 'sort') {
                const value = pageProperties.sort.get(sortField)
                const newOrder = (value || 'asc') == 'desc' ? 'asc' : 'desc'
                const map = pageProperties.sort
                map.set(sortField, newOrder)
                sortOrder = newOrder
            }
            await loadProdutos(page == 0 ? 1 : page, sizePerPage, sortField, sortOrder)
        } catch (error) {
            console.error(error)
            notify('danger', 'Houve um problema ao carregar as publicações.');
        }
    }

    function handleNewProduto() {
        props.history.push('produtos/new')
    }


    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <SimpleHeader name="Lita de produtos" parentName="Produtos" {...props} />
            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 100,
                                }}>
                                <Filters
                                    load={(filters) => {
                                        setLastSearch(filters)
                                        loadProdutos(
                                            1,
                                            10,
                                            "nome",
                                            "desc",
                                            filters)
                                    }}
                                />
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Button
                                            disabled={!empresaSelecionada?.id}
                                            color="primary"
                                            type="button"
                                            onClick={handleNewProduto}
                                            size="sm">
                                            <span className="btn-inner--icon">
                                                <i className="ni ni-fat-add" />
                                            </span>
                                                    Novo produto
                                            </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table
                                            produtos={produtos ? produtos.data : []}
                                            notify={notify}
                                            onTableChange={handleTableChange}
                                            history={props.history}
                                            pageProperties={pageProperties}
                                            loading={loading}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
