/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

class Calendar extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <footer className="footer pt-0">
            <Row>
              <Col>
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://playnee.com"
                    target="_blank"
                  >
                    Playnee
                  </a>
                </div>
              </Col>
              <Col>
                <div className="copyright text-center text-lg-right text-muted">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="secondary"
                      style={{
                        borderRadius: "40px",
                        padding: "0px",
                      }}
                    >
                      <i
                        className="far fa-question-circle"
                        style={{
                          color: "#0847d6",
                          fontSize: "26px",
                          fontWeight: "600",
                        }}
                      ></i>
                    </DropdownToggle>
                    <DropdownMenu positionFixed={"relative"}>
                      <DropdownItem
                        href="https://api.whatsapp.com/send?phone=557998963826"
                        target="_blank"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "8px",
                          }}
                        >
                          <i
                            class="fab fa-whatsapp"
                            style={{
                              fontSize: "1.4em",
                              color: "#008328",
                              fontWeight: "bold",
                            }}
                          ></i>
                          <a
                            href="https://api.whatsapp.com/send?phone=5579998782516"
                            target="_blank"
                            style={{
                              fontSize: "1.1em",
                              fontWeight: "700",
                              color: "#008328",
                            }}
                          >
                            Whatsapp
                          </a>
                        </div>
                      </DropdownItem>
                      <DropdownItem
                        href="https://ajuda.playnee.com"
                        target="_blank"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "8px",
                          }}
                        >
                          <i
                            class="fa fa-question-circle"
                            style={{
                              fontSize: "1.4em",
                              color: "#0847d6",
                              fontWeight: "bold",
                            }}
                          ></i>
                          <a
                            href="https://ajuda.playnee.com"
                            target="_blank"
                            style={{
                              fontSize: "1.1em",
                              fontWeight: "700",
                              color: "#0847d6",
                            }}
                          >
                            Central de Ajuda
                          </a>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div
                  className="copyright text-center text-lg-left text-muted"
                  style={{ fontSize: "x-small" }}
                >
                  <a
                    className="font-weight-bold ml-1"
                    href="https://playnee.com/termos-de-uso.pdf"
                    target="_blank"
                  >
                    Termo de Uso
                  </a>
                </div>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default Calendar;
