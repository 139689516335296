import React, { useEffect, useState } from "react";

import classnames from "classnames";
import Avatar from "../../../../components/Avatar";
import { Button, Row, Col, FormGroup, Input, Modal } from "reactstrap";

export default ({
  show,
  onHidden,
  publicacao,
  anuncio,
  onConfirm,
  confirmButtonText = "Confirmar",
  errorMsg,
}) => {
  const [newId, setNewId] = useState(null);

  function handleConfirm() {
    console.log("ALTERANDO ID");
    console.log(newId);
    publicacao.rede_social_id = newId;
    onConfirm(publicacao);
  }

  useEffect(() => {
    if (publicacao) {
      setNewId(publicacao.rede_social_id);
    }
  }, [publicacao]);

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={show} toggle={onHidden}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Editar Id do Canal
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onHidden}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Título: {publicacao?.titulo}
          </h5>
        </div>

        <div
          className="modal-header"
          style={{
            marginTop: "-25px",
          }}
        >
          <h5 className="modal-title" id="exampleModalLabel">
            Anúncio: {anuncio?.nome}
          </h5>
        </div>
        <div
          className="modal-header"
          style={{
            marginTop: "-25px",
          }}
        >
          <h5 className="modal-title" id="exampleModalLabel">
            Canal: {anuncio?.canal?.nome}
          </h5>
        </div>
        <div
          className="modal-header"
          style={{
            marginTop: "-25px",
          }}
        >
          <h5 className="modal-title" id="exampleModalLabel">
            Id Atual: {publicacao?.rede_social_id}
          </h5>
        </div>
        <div className="modal-body">
          <div
            style={{
              textAlign: "center",
            }}
            className="text-muted mb-4"
          >
            Informe o id gerado após o cadastro do anúncio no canal escolhido.
          </div>

          <Col lg={12}>
            <FormGroup>
              <label className="form-control-label">
                {`Id do anúncio no canal`}
              </label>
              <Input
                className="form-control"
                placeholder="Id no anúncio no canal selecionado..."
                value={newId}
                onChange={(e) => setNewId(e.target.value)}
              />
              <small class="text-danger">{errorMsg || ""}</small>
            </FormGroup>
          </Col>
        </div>
        <div className="modal-footer">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onHidden}
          >
            Cancelar
          </Button>
          <Button
            disabled={!publicacao}
            color="primary"
            onClick={handleConfirm}
            type="button"
          >
            {confirmButtonText}
          </Button>
        </div>
      </Modal>
    </>
  );
};
