import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
`;

export const PersonaCard = styled.div`
  text-align: start;

  p {
    display: block;
  }
  p::first-letter{
    text-transform: 'capitalize';
  }
`

export const PersonaButton = styled.button`
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    word-wrap: break-word;
    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    font-weight: 400;
    display: block;
    height: calc(2.75rem + 2px);
    padding: 0.625rem 0.75rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    font-size: 0.875rem;
    transition: all 0.15s ease-in-out;
    text-align: start;

    .persona-name {
      display: none;
      margin-right: 1em;
      transition: all .33s ease-in-out;
    }

    &:hover {
      .persona-name {
        display: inline;
      }

    }
`