import React from "react";
import { hasPermission, getContrastYIQ } from "../../../utils";
import moment from "moment";

import { UncontrolledTooltip, Button } from "reactstrap";

export const createColumns = ({
  lastSearch,
  setFuncionarioMovimentacao,
  setShowModalMovimentacao,
}) => {
  const headerStyle = { fontSize: 9 };

  let copy = getColumns(
    headerStyle,
    lastSearch,
    setFuncionarioMovimentacao,
    setShowModalMovimentacao
  );

  return copy;
};

const getColumns = (
  headerStyle,
  lastSearch,
  setFuncionarioMovimentacao,
  setShowModalMovimentacao
) => {
  const columns = [
    {
      dataField: "acoes",
      formatter: (cell, row) =>
        acoesFormatter(
          cell,
          row,
          lastSearch,
          setFuncionarioMovimentacao,
          setShowModalMovimentacao
        ),
      headerStyle: (colum, colIndex) => {
        return {
          paddingLeft: 10,
          paddingRight: 10,
          textAlign: "center",
          position: "sticky",
          left: 0,
          zIndex: 1,
        };
      },
      style: (colum, colIndex) => {
        return {
          paddingLeft: 10,
          paddingRight: 10,
          textAlign: "center",
          position: "sticky",
          left: 0,
          backgroundColor: "#fff",
        };
      },
    },
    {
      dataField: "colaborador_nome",
      text: "Colaborador",
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === "asc") return a.localeCompare(b);
        else return b.localeCompare(a);
      },
      text: "Colaborador<br/>&nbsp;<br/>&nbsp;",
      headerFormatter: contacatacaoHeader,
      headerStyle: {
        fontSize: 9,
        position: "sticky",
        left: 48,
        zIndex: 1,
      },
      style: () => ({
        position: "sticky",
        left: 48,
        backgroundColor: "#fff",
      }),
    },
    {
      dataField: "oportunidades_recebidas",
      text: "oportunidades<br/>recebidas<br/>&nbsp;",
      headerFormatter: contacatacaoHeader,
      headerAlign: "center",
      align: "center",
      headerStyle,
      sort: true,
    },
    {
      headerStyle,
      dataField: "oportunidades_cadastradas_comigo",
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>cadastradas<br/>(comigo)",
      headerAlign: "center",
      sort: true,
      align: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_cadastradas_encaminhadas",
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>cadastradas<br/>(encaminhadas)",
      headerAlign: "center",
      sort: true,
      align: "center",
    },
    {
      headerStyle,
      dataField: "comentarios",
      headerFormatter: contacatacaoHeader,
      text: "comentários<br/>&nbsp;<br/>&nbsp;",
      headerAlign: "center",
      sort: true,
      align: "center",
    },
    {
      headerStyle,
      dataField: "reagendamentos",
      headerFormatter: contacatacaoHeader,
      text: "reagendamentos<br/>&nbsp;<br/>&nbsp;",
      headerAlign: "center",
      sort: true,
      align: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_encerradas",
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>encerradas<br/>&nbsp;",
      align: "center",
      sort: true,
      headerAlign: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_perdidas",
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>perdidas<br/>&nbsp;",
      align: "center",
      sort: true,
      headerAlign: "center",
    },
    {
      headerStyle,
      dataField: "oportunidades_convertidas",
      headerFormatter: contacatacaoHeader,
      text: "oportunidades<br/>convertidas<br/>&nbsp;",
      align: "center",
      sort: true,
      headerAlign: "center",
    },
  ];

  const newColumns = columns.map((item) => ({
    ...item,
    csvText: item.dataField,
    footer: (columnData, data, index) => {
      if (!index) return "--";
      if (data.dataField == "colaborador_nome") return "Total";
      return columnData.reduce(
        (acc, item) => parseFloat(acc) + parseFloat(item ?? 0),
        0
      );
    },
    footerStyle: (_, index) => {
      if (!index) {
        return {
          textAlign: "center",
          position: "sticky",
          left: 0,
          zIndex: 1,
        };
      }
      if (index == 1) {
        return {
          position: "sticky",
          textAlign: "start",
          zIndex: 1,
          left: 48,
        };
      }
      return {
        textAlign: "center",
      };
    },
  }));

  return newColumns;
};

function acoesFormatter(
  cell,
  row,
  lastSearch,
  setFuncionarioMovimentacao,
  setShowModalMovimentacao
) {
  return (
    <>
      <div
        id={`column-func-${row.colaborador_id}`}
        class="btn-group"
        role="group"
        aria-label="Basic example"
      >
        <Button
          className="btn-sm"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          color="secondary"
          onClick={() =>
            goToGraficoMovimentacao(
              row,
              lastSearch,
              setFuncionarioMovimentacao,
              setShowModalMovimentacao
            )
          }
          outline
        >
          <i class="fa fa-filter"></i>
        </Button>
        <UncontrolledTooltip
          target={`column-func-${row.colaborador_id}`}
          placement="top"
        >
          Movimentação no Funil
        </UncontrolledTooltip>
      </div>
    </>
  );
}

function goToGraficoMovimentacao(
  row,
  lastSearch,
  setFuncionarioMovimentacao,
  setShowModalMovimentacao
) {
  // console.log('ABRIR MODAL GRAFICO');
  // console.log({ row });
  // console.log({ lastSearch });
  setFuncionarioMovimentacao(row);
  setShowModalMovimentacao(true);
}

function contacatacaoHeader(column) {
  return (
    <>
      <div id={`column-${column.text}`} className="position-relative">
        <div dangerouslySetInnerHTML={{ __html: column.text }} />
        <span className="order-4 position-absolute" style={{ top: "22px", right: "-24px" }}></span>
      </div>
    </>
  );
}
