import React, { useState, useEffect, useContext } from 'react';
import api from "../../../../services/api";
import EmpresaContext from '../../../../contexts/Empresa'
import { Oportunidade } from "../../../../entities/Marketing";

import { SpinnerContainer } from './styles';
import {
    UncontrolledAlert,
    Button,
    Modal,
    Spinner,
    Badge
} from "reactstrap";
import AuthContext from '../../../../contexts/Auth';

interface IExport {
    search: object,
    show: boolean,
    onClose(): void,
    isConversao?: boolean,
}

const Export: React.FC<IExport> = ({ search, show, onClose, isConversao }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const { hasRole } = useContext(AuthContext)
    const [alert, setAlert] = useState<string | undefined>()
    const [sumario, setSumario] = useState(new Array<any>())
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setSumario(new Array<any>())
        if (show) {
            loadSumario()
        }
    }, [show])

    useEffect(() => {
        if (sumario) {
            console.log({ sumario })
        }
    }, [sumario])


    async function loadSumario() {

        setLoading(true)
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/sumariooportunidades`, {
                params: {
                    ...search
                }
            })
            setSumario(await response.data)
        } catch (error) {
            console.error(error)
            showAlert('Não conseguimos carregar o sumário')
        }
        setLoading(false)
    }

    function showAlert(texto: string) {
        setAlert(texto)
        setTimeout(() => setAlert(undefined), 5000)
    }

    return (
        <>
            <Modal
                size='lg'
                className="modal-dialog-centered"
                isOpen={show}
                toggle={onClose}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Sumário de Oportunidades
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onClose}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" style={{overflow: 'auto'}}>
                    {
                        alert &&
                        <UncontrolledAlert className="alert-danger" fade={true}>
                            <span className="alert-inner--icon">
                                !
                            </span>{" "}
                            <span className="alert-inner--text">
                                {alert}
                            </span>
                        </UncontrolledAlert>
                    }
                    {
                        loading ?
                            <>
                                <div className="modal-body">

                                    <div
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        className='mb-4'>
                                        <br></br>
                                        <h3>
                                            Carregando sumário baseado nos filtros escolhidos.
                                        </h3>
                                    </div>

                                    <div
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        className='mb-4'>
                                        <br></br>
                                        <h4>Aguarde...</h4>

                                        <SpinnerContainer>
                                            <Spinner
                                                size='lg'
                                                color='primary'
                                                style={{
                                                    marginTop: '-150px'
                                                }}
                                            />
                                        </SpinnerContainer>
                                    </div>
                                </div>
                            </>

                            :

                            <>
                                <div className="modal-body">

                                    <div
                                        style={{
                                            textAlign: 'initial'
                                        }}
                                        className='mb-4'>

                                        <table style={{ width: "100%" }}>
                                            <tr>
                                                <th></th>
                                                <th>Quantidade</th>
                                                <th>Valor</th>
                                            </tr>
                                            <tr>
                                                <td style={{ color: 'white', fontWeight: 'bold' }}>{'-'}</td>
                                                <td style={{ color: 'white', fontWeight: 'bold' }}>{'-'}</td>
                                                <td style={{ color: 'white', fontWeight: 'bold' }}>{'-'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Em Negociação</td>
                                                {/* @ts-ignore */}
                                                <td><Badge className="badge-lg" style={{ color: 'white', background: 'blue', fontSize: 15, fontWeight: 'bold' }} >{sumario && sumario.oportunidadesAbertas && sumario.oportunidadesAbertas.quantidade ? sumario.oportunidadesAbertas.quantidade : '0'}</Badge></td>
                                                {/* @ts-ignore */}
                                                <td><Badge className="badge-lg" style={{ color: 'white', background: 'blue', fontSize: 15, fontWeight: 'bold' }} >{sumario && sumario.oportunidadesAbertas && sumario.oportunidadesAbertas.valor ? 'R$ ' + sumario.oportunidadesAbertas.valor : 'R$ 0.00'}</Badge></td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Convertidas</td>
                                                {/* @ts-ignore */}
                                                <td><Badge className="badge-lg" style={{ color: 'white', background: '#2cd949', fontSize: 15, fontWeight: 'bold' }} >{sumario && sumario.oportunidadesConvertidas && sumario.oportunidadesConvertidas.quantidade ? sumario.oportunidadesConvertidas.quantidade : '0'}</Badge></td>
                                                {/* @ts-ignore */}
                                                <td><Badge className="badge-lg" style={{ color: 'white', background: '#2cd949', fontSize: 15, fontWeight: 'bold' }} >{sumario && sumario.oportunidadesConvertidas && sumario.oportunidadesConvertidas.valor ? 'R$ ' + sumario.oportunidadesConvertidas.valor : 'R$ 0.00'}</Badge></td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Perdidas</td>
                                                {/* @ts-ignore */}
                                                <td><Badge className="badge-lg" style={{ color: 'white', background: '#f52751', fontSize: 15, fontWeight: 'bold' }} >{sumario && sumario.oportunidadesPerdidas && sumario.oportunidadesPerdidas.quantidade ? sumario.oportunidadesPerdidas.quantidade : '0'}</Badge></td>
                                                {/* @ts-ignore */}
                                                <td><Badge className="badge-lg" style={{ color: 'white', background: '#f52751', fontSize: 15, fontWeight: 'bold' }} >{sumario && sumario.oportunidadesPerdidas && sumario.oportunidadesPerdidas.valor ? 'R$ ' + sumario.oportunidadesPerdidas.valor : 'R$ 0.00'}</Badge></td>
                                            </tr>
                                            <tr>
                                                <td style={{ color: 'white', fontWeight: 'bold' }}>{'-'}</td>
                                                <td style={{ color: 'white', fontWeight: 'bold' }}>{'-'}</td>
                                                <td style={{ color: 'white', fontWeight: 'bold' }}>{'-'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Total</td>
                                                {/* @ts-ignore */}
                                                <td><Badge className="badge-lg" style={{ color: 'white', background: '#8d8d8d', fontSize: 15 }} >{sumario && sumario.oportunidadesTotal && sumario.oportunidadesTotal.quantidade ? sumario.oportunidadesTotal.quantidade : '0'}</Badge></td>
                                                {/* @ts-ignore */}
                                                <td><Badge className="badge-lg" style={{ color: 'white', background: '#8d8d8d', fontSize: 15 }} >{sumario && sumario.oportunidadesTotal && sumario.oportunidadesTotal.valor ? 'R$ ' + sumario.oportunidadesTotal.valor : 'R$ 0.00'}</Badge></td>
                                            </tr>

                                        </table>

                                    </div>

                                </div>
                            </>

                    }
                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onClose}
                    >
                        fechar
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default Export;