import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Row,
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import api from '../../../../../../../services/api';
import { ModeloSite, UsuarioModeloSite } from '../../CentralVendedor';
import './styles.css';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { EmpresaModeloSite } from '../../../../../../common/Empresas/MinhaEmpresa/Sites';
import classnames from 'classnames';

interface SelecionarModeloModalProps {
  onClose: () => void;
  isOpen: boolean;
  pages: ModeloSite[];
  reload: () => Promise<void>;
  currentSiteId: string | number | undefined;
  siteUsuario: UsuarioModeloSite | EmpresaModeloSite | undefined;
}

export function SelecionarModeloModal({
  isOpen,
  onClose,
  pages,
  reload,
  currentSiteId = undefined,
  siteUsuario,
}: SelecionarModeloModalProps) {
  const [selectedModeloSiteId, setSelectedModeloSiteId] = useState<
    string | number | undefined
  >(currentSiteId);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [alert, setAlert] = useState<React.ReactNode | null>(null);
  const [preview, setPreview] = useState('');
  const [activeTab, setActiveTab] = useState('1');
  const [modelosCatalogo, setModelosCatalogo] = useState<any>([]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    setSelectedModeloSiteId(currentSiteId);
  }, [currentSiteId]);

  useEffect(() => {
    switch (activeTab) {
      case '2': {
        if (modelosCatalogo.length === 0) {
          loadModelosCatalogo();
        }
      }
    }
  }, [activeTab]);

  async function loadModelosCatalogo() {
    try {
      const { data } = await api.get(`/marketing/ms?layout=${2}`);

      setModelosCatalogo(data);
    } catch (error) {
      console.error('Houve um erro ao carregar os dados do site.', error);
    }
  }

  async function handleSave() {
    try {
      if (!selectedModeloSiteId) return;
      await api.post(`/marketing/modelos-sites/usuario/${siteUsuario?.id}`, {
        modelo_site_id: selectedModeloSiteId,
      });

      reload();
      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block' }}
          title="Sucesso!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Modelo selecionado com êxito!
        </ReactBSAlert>
      );
      handleCloseModal();
    } catch (error) {
      console.error(error);
    }
  }

  function handleCloseModal() {
    setSelectedModeloSiteId(currentSiteId);
    onClose();
  }

  return (
    <>
      {alert}
      <Modal
        isOpen={isOpen}
        toggle={handleCloseModal}
        style={{ maxWidth: '90%' }}
      >
        <ModalHeader toggle={handleCloseModal}>Selecione o modelo</ModalHeader>
        <ModalBody style={{  height: '70vh', overflowY: 'scroll', paddingTop: 0,  }}>
          <Nav tabs className='sticky-top bg-white'>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1');
                }}
              >
                Standard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                Catálogo
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div
                style={{
                  display: 'grid',
                  gap: '1rem',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  paddingBlock: '1rem',
                }}
              >
                {pages.map((page, index) => {
                  const isCurrentSite =
                    selectedModeloSiteId != undefined &&
                    selectedModeloSiteId == page.id;
                  return (
                    <Card key={page.id}>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setPreview(page.link);
                          setIsFullScreen(true);
                        }}
                        className="magnifier"
                      >
                        <CardImg
                          top
                          width="100%"
                          style={{ maxWidth: '100%' }}
                          src={
                            page.link + 'preview.png' ||
                            'https://internal.playnee.com/vendedor/capa.png'
                          }
                          alt="Card image cap"
                        />
                      </div>

                      <CardBody>
                        <CardTitle tag="h5">Modelo {index + 1}</CardTitle>
                        <Button
                          size="sm"
                          color="primary"
                          disabled={isCurrentSite}
                          onClick={() => setSelectedModeloSiteId(page.id)}
                        >
                          {isCurrentSite ? 'Modelo Atual' : 'Selecionar Modelo'}
                        </Button>
                      </CardBody>
                    </Card>
                  );
                })}
              </div>
            </TabPane>
            <TabPane tabId="2">
              {modelosCatalogo.length === 0 && <span>Nenhum modelo encontrado.</span>}
              <div
                style={{
                  display: 'grid',
                  gap: '1rem',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  paddingBlock: '1rem',
                }}
              >
                {modelosCatalogo.map((page, index) => {
                  const isCurrentSite =
                    selectedModeloSiteId != undefined &&
                    selectedModeloSiteId == page.id;
                  return (
                    <Card key={page.id}>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setPreview(page.link);
                          setIsFullScreen(true);
                        }}
                        className="magnifier"
                      >
                        <CardImg
                          top
                          width="100%"
                          style={{ maxWidth: '100%' }}
                          src={
                            page.link + 'preview.png' ||
                            'https://internal.playnee.com/vendedor/capa.png'
                          }
                          alt="Card image cap"
                        />
                      </div>

                      <CardBody>
                        <CardTitle tag="h5">Modelo {index + 1}</CardTitle>
                        <Button
                          size="sm"
                          color="primary"
                          disabled={isCurrentSite}
                          onClick={() => setSelectedModeloSiteId(page.id)}
                        >
                          {isCurrentSite ? 'Modelo Atual' : 'Selecionar Modelo'}
                        </Button>
                      </CardBody>
                    </Card>
                  );
                })}
              </div>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Voltar
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            disabled={
              !selectedModeloSiteId || selectedModeloSiteId == currentSiteId
            }
          >
            Salvar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isFullScreen}
        style={{ maxWidth: 'min-content' }}
        toggle={() => setIsFullScreen(false)}
      >
        <img
          src={
            preview + 'preview.png' ||
            'https://internal.playnee.com/vendedor/capa.png'
          }
          alt="Card image cap"
          style={{ maxWidth: '1440px', display: 'block', borderRadius: '8px' }}
        />
      </Modal>
    </>
  );
}
