import React, { useState, useContext, useEffect, useRef } from 'react';
import EmpresaContext from "../../../../../../contexts/Empresa";
import { AxiosResponse } from 'axios';
import api from '../../../../../../services/api'
import { EstagioEsteira, MalaDireta } from "../../../../../../entities/Marketing";
import moment from "moment";

import MalaDiretaForm from '../../../../malasDiretas/Form/ContentToSend'
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
//@ts-ignore
import Select2 from "react-select2-wrapper";
import ReactDatetime from "react-datetime";
import {
    Spinner,
    Card,
    CardBody,
    Col,
    Row,
    FormGroup,
    Input,
    CardHeader,
    CardFooter,
    Button,
    PopoverBody,
    UncontrolledPopover,
    InputGroupAddon,
    InputGroupText,
    InputGroup
} from "reactstrap";
import {
    Container,
    FormContainer
} from './styles';

interface IForm {
    estagio: EstagioEsteira,
    esteiraId: number,
    onDeleteSuccess(): Promise<void>,
    onSaveSuccess(): Promise<void>,
    notify(type: string, msg: string): void,
}

interface FormError {
    nome?: string,
    fluxo?: string,
    data_execucao?: string,
    periodo_execucao?: string,
    situacao?: string,
    avanco_automatico?: string,
    executar_em_estagios_anteriores?: string,
    'mala_direta.email_assunto'?: string,
    'mala_direta.email_remetente_id'?: string,
    'mala_direta.email_conteudo'?: string,
    'mala_direta.enviar_sms'?: string,
    'mala_direta.enviar_email'?: string,
    'mala_direta.sms_conteudo'?: string,
}

interface MalaDiretaError {
    email_assunto?: string,
    email_remetente_id?: string,
    email_conteudo?: string,
    enviar_sms?: string,
    enviar_email?: string,
    sms_conteudo?: string,
}

const Form: React.FC<IForm> = ({
    estagio,
    esteiraId,
    onDeleteSuccess,
    onSaveSuccess,
    notify
}) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [tabActive, setTabActive] = useState('');

    const [nome, setNome] = useState('')
    const [situacao, setSituacao] = useState<'A' | 'I'>('A')
    const [executarEmEstagiosAnteriores, setExecutarEmEstagiosAnteriores] = useState<boolean>(false)
    const [avancoAutomatico, setAvancoAutomatico] = useState<boolean>(true)
    const [fluxo, setFluxo] = useState<undefined | 'data' | 'periodo'>()
    const [dataExecucao, setDataExecucao] = useState<moment.Moment | undefined>()
    const [periodoExecucao, setPeriodoExecucao] = useState<string | undefined>()
    //
    const [sender, setSender] = useState<number | undefined>(undefined);
    const [subject, setSubject] = useState<string>('');
    const [bodyText, setBodyText] = useState<string>('');
    const [npsTextEmail, setNpsTextEmail] = useState<string>('');
    const [npsTextSms, setNpsTextSms] = useState<string>('');
    const [enviarSms, setEnviarSms] = useState(false)
    const [enviarEmail, setEnviarEmail] = useState(false)
    const [smsConteudo, setSmsConteudo] = useState('')
    const [smsAcentuado, setSmsAcentuado] = useState(false)

    const [erros, setErros] = useState({} as FormError)
    const [maladiretaErros, setMaladiretaErros] = useState({} as MalaDiretaError)
    const [alert, setAlert] = useState<any>()
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        const malaErros = {
            email_assunto: erros["mala_direta.email_assunto"],
            email_remetente_id: erros["mala_direta.email_remetente_id"],
            email_conteudo: erros["mala_direta.email_conteudo"],
            enviar_sms: erros["mala_direta.enviar_sms"],
            enviar_email: erros["mala_direta.enviar_email"],
            sms_conteudo: erros["mala_direta.sms_conteudo"],
        }
        setMaladiretaErros(malaErros)
    }, [erros])

    useEffect(() => {
        setNome(estagio.nome || '')
        setSituacao(estagio.situacao)
        setExecutarEmEstagiosAnteriores(estagio.executar_em_estagios_anteriores || false)
        setAvancoAutomatico(estagio.avanco_automatico || true)
        setFluxo(estagio.fluxo)
        setDataExecucao(moment(estagio.data_execucao))
        setPeriodoExecucao(estagio.periodo_execucao ? estagio.periodo_execucao + "" : undefined)
        setSender(estagio?.malaDireta?.email_remetente_id)
        setSubject(estagio?.malaDireta?.email_assunto || '')
        setBodyText(estagio?.malaDireta?.email_conteudo || '')
        setEnviarSms(estagio?.malaDireta?.enviar_sms || false)
        setEnviarEmail(estagio?.malaDireta?.enviar_email || false)
        setSmsConteudo(estagio?.malaDireta?.sms_conteudo || '')
    }, [estagio])


    async function handleSave() {
        setErros({})
        if (estagio && estagio.id) {
            await update()
        } else {
            await insert()
        }
    }

    async function update() {
        try {
            setLoading(true)
            const newEstagio = bind()
            await api.put(`common/empresas/${empresaSelecionada?.id}/esteiras/${estagio?.id}/estagios/${estagio?.id}`, newEstagio)
            setAlert(
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
                    title={'Estágio salva'}
                    confirmBtnBsStyle="success"
                    showConfirm={false}
                    btnSize=""
                />
            );
            await onSaveSuccess()
            setTimeout(() => setAlert(undefined), 2000);
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível salvar estágio')
            //@ts-ignore
            throwError(error.response)
        } finally {
            setLoading(false)
        }
    }

    async function insert() {
        try {
            setLoading(true)
            const newEstagio = bind()
            await api.post(`common/empresas/${empresaSelecionada?.id}/esteiras/${esteiraId}/estagios/`, newEstagio)
            await onSaveSuccess()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível salvar estágio')
            //@ts-ignore
            throwError(error.response)
        } finally {
            setLoading(false)
        }
    }

    function bind(): EstagioEsteira {
        const mala_direta = {
            ...estagio.malaDireta,
            email_assunto: subject,
            email_remetente_id: sender,
            email_conteudo: bodyText,
            sms_conteudo: smsConteudo,
            //@ts-ignore
            enviar_sms: enviarSms ? true : undefined,
            //@ts-ignore
            enviar_email: enviarEmail ? true : undefined
        } as MalaDireta
        return {
            ...estagio,
            mala_direta,
            nome,
            situacao,
            executar_em_estagios_anteriores: executarEmEstagiosAnteriores,
            avanco_automatico: avancoAutomatico ,
            fluxo: fluxo as 'data' | 'periodo',
            data_execucao: dataExecucao?.toDate(),
            periodo_execucao: periodoExecucao ? new Number(periodoExecucao).valueOf() : undefined
        }
    }

    function throwError(response: AxiosResponse) {
        if (response && response.status == 400) {
            if (response.data.length) {
                const responseError = {} as FormError
                for (let e of response.data) {
                    //@ts-ignore
                    responseError[e.field] = e.message
                }
                setErros(responseError)
            }
        }
    }

    async function deleteEstagio() {
        setLoading(true)
        try {
            await api.delete(`/common/empresas/${empresaSelecionada?.id}/esteiras/${esteiraId}/estagios/${estagio.id}`)
            await onDeleteSuccess()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível remover estágio')
        } finally {
            setLoading(false)
        }
    }

    async function handleDelete() {
        if (!estagio || !estagio.id) return
        if (window.confirm(`Deseja remover este anúncio?`)) {
            await deleteEstagio()
        }
    }

    return (
        <>
            {alert}
            <Container>
                <Card
                    className='d-flex'
                    style={{ flex: 1 }}
                >
                    {
                        !estagio?.id &&
                        <CardHeader>
                            Novo Estágio
                        </CardHeader>
                    }
                    <CardBody>
                        <Row>
                            <Col
                                sm='12'
                                md='12'
                                lg='6'
                            >
                                <FormGroup>
                                    <label className="form-control-label" >
                                        Nome*
                                </label>
                                    <Input
                                        placeholder='Nome...'
                                        className="form-control"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                    />
                                    <small className="text-danger">
                                        {erros.nome || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                            <Col
                                sm='12'
                                md='12'
                                lg='6'
                            >
                                <FormGroup>
                                    <label className="form-control-label" >
                                        Situação*
                                </label>
                                    <Select2
                                        className="form-control"
                                        //@ts-ignore
                                        onSelect={(e) => setSituacao(e.target.value)}
                                        value={situacao}
                                        data={[{ id: 'A', text: 'Ativo' }, { id: 'I', text: 'Inativo' }]}
                                    />
                                    <small className="text-danger">
                                        {erros.situacao || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                sm='12'
                                md='12'
                                lg='6'
                            >
                                <FormGroup>
                                    <label className="form-control-label" >
                                        Fluxo*
                                    <Button
                                            className='p-0 ml-2 mb-1'
                                            color="link"
                                            id="tooltip87627934998230490932"
                                            type="button">
                                            ?
                                    </Button>
                                        <UncontrolledPopover placement="top" target="tooltip87627934998230490932">
                                            <PopoverBody>
                                                Informe se a execução deste estágio será em uma data especifica ou após uma quantidade de dias em que a oportunidade foi executada pelo estágio anterior.
                                        </PopoverBody>
                                        </UncontrolledPopover>
                                    </label>
                                    <Select2
                                        className="form-control"
                                        //@ts-ignore
                                        onSelect={(e) => setFluxo(e.target.value)}
                                        value={fluxo}
                                        data={[{ id: 'data', text: 'Data' }, { id: 'periodo', text: 'Período' }]}
                                    />
                                    <small className="text-danger">
                                        {erros.fluxo || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                            <Col
                                sm='12'
                                md='12'
                                lg='6'
                            >
                                <FormGroup>
                                    <label className="form-control-label" >
                                        {fluxo === 'data' ? 'Data*' : 'Período*'}
                                        <Button
                                            className='p-0 ml-2 mb-1'
                                            color="link"
                                            id="toll893274982"
                                            type="button">
                                            ?
                                    </Button>
                                        <UncontrolledPopover placement="top" target="toll893274982">
                                            <PopoverBody>
                                                {
                                                    fluxo === 'data'
                                                        ?
                                                        'Selecione a data em que o estagio será executado'
                                                        : 'Caso informe 0, a execução será imediata'
                                                }
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </label>
                                    {
                                        fluxo === 'data' ?
                                            <ReactDatetime
                                                value={dataExecucao}
                                                inputProps={{
                                                    placeholder: "Data para execução...",
                                                    style: { position: 'absolute' }
                                                }}
                                                isValidDate={current => {
                                                    return current.isAfter(new Date(new Date().setDate(new Date().getDate() - 1)));
                                                }}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat='HH:mm'
                                                onChange={e => setDataExecucao(e as moment.Moment)}
                                            />
                                            :
                                            <InputGroup
                                            >
                                                <Input
                                                    placeholder="Período para execução..."
                                                    type="number"
                                                    value={periodoExecucao}
                                                    onChange={(e) => setPeriodoExecucao(e.target.value)}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>dias</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                    }
                                    <small className="text-danger">
                                        {(fluxo === 'data' ? erros.data_execucao : erros.periodo_execucao) || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                sm='12'
                                md='12'
                                lg='6'
                            >
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            checked={new Boolean(executarEmEstagiosAnteriores).valueOf()}
                                            onChange={() => setExecutarEmEstagiosAnteriores(!executarEmEstagiosAnteriores)}
                                            className="custom-control-input"
                                            id="check1"
                                            type="checkbox"
                                        />
                                        <label className="custom-control-label" htmlFor="check1">
                                            Executar em estágios anteriores
                                    </label>
                                        <Button
                                            className='p-0 ml-2 mb-1'
                                            color="link"
                                            id="tooltip8762793499823049823222"
                                            type="button">
                                            ?
                                    </Button>
                                        <UncontrolledPopover placement="top" target="tooltip8762793499823049823222">
                                            <PopoverBody>
                                                Selecione caso queira que novas oportunidades não executadas por estágios anteriores, sejam incluidas na execução deste estágio.
                                        </PopoverBody>
                                        </UncontrolledPopover>
                                    </div>
                                    <small className="text-danger">
                                        {erros.executar_em_estagios_anteriores || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="check2"
                                            type="checkbox"
                                            checked={avancoAutomatico}
                                            onChange={() => setAvancoAutomatico(!avancoAutomatico)}
                                        />
                                        <label className="custom-control-label" htmlFor="check2">
                                            Avançar automaticamente
                                    </label>
                                        <Button
                                            className='p-0 ml-2 mb-1'
                                            color="link"
                                            id="tooltip8762793499823049823"
                                            type="button">
                                            ?
                                    </Button>
                                        <UncontrolledPopover placement="top" target="tooltip8762793499823049823">
                                            <PopoverBody>
                                                Selecione caso queira automatizar o avanço das oportunidades deste estágio para o próximo estágio.
                                        </PopoverBody>
                                        </UncontrolledPopover>
                                    </div>
                                    <small className="text-danger">
                                        {erros.avanco_automatico || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* @ts-ignore */}
                        <MalaDiretaForm
                            sender={sender}
                            setSender={setSender}
                            subject={subject}
                            setSubject={setSubject}
                            bodyText={bodyText}
                            setBodyText={setBodyText}
                            enviarSms={enviarSms}
                            setEnviarSms={setEnviarSms}
                            enviarEmail={enviarEmail}
                            setEnviarEmail={setEnviarEmail}
                            smsConteudo={smsConteudo}
                            setSmsConteudo={setSmsConteudo}
                            smsAcentuado={smsAcentuado}
                            setSmsAcentuado={setSmsAcentuado}
                            tabActive={tabActive}
                            setTabActive={setTabActive}
                            isNps={false}
                            isEdit={false}
                            npsTextEmail={npsTextEmail}
                            setNpsTextEmail={setNpsTextEmail}
                            npsTextSms={npsTextSms}
                            setNpsTextSms={setNpsTextSms}
                            erros={maladiretaErros}
                            notify={notify}
                            destinatarioEmail={undefined}
                            destinatarioSms={undefined}
                        />
                    </CardBody>
                    <CardFooter
                        className='d-flex justify-content-between'
                    >
                        <div>
                            {
                                estagio?.id &&
                                <Button
                                    disabled={loading}
                                    color='danger'
                                    onClick={handleDelete}
                                >
                                    Remover
                                </Button>
                            }
                        </div>
                        <div>
                            <Button
                                color='primary'
                                disabled={loading}
                                onClick={handleSave}
                            >
                                {
                                    loading &&
                                    <Spinner
                                        color='secondary'
                                        size='sm'
                                        className='mr-2'
                                    />
                                }
                                Salvar
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Container>
        </>
    )
}

export default Form;