import React from "react";

import { Canais } from "./Canais";
import { Paginas } from "./Paginas";

export interface ModeloSite {
  id: number;
  link: string;
  tipo_modelo: "afiliacao" | "indicacao";
  params_map: string;
}

interface ParametroSite {
  id: number;
  chave: string;
  valor: string;
  descricao: string;
  usuario_modelo_site_id: string;
}

export interface UsuarioModeloSite {
  id: string;
  user_empresa_id: number;
  modelo_site_id: number;
  parametroSite: ParametroSite[];
  site: ModeloSite;
}

export function CentralVendedor() {
  return (
    <>
      <Paginas />
      <Canais />
    </>
  );
}
