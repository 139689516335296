import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../../../../services/api";
// @ts-ignore
import InputMask from "react-input-mask";
import ReactQuill from "react-quill";
// @ts-ignore
import Select2 from "react-select2-wrapper";
// @ts-ignore
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
import {
  Spinner,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  UncontrolledPopover,
  PopoverBody,
  UncontrolledTooltip,
  Badge,
  CardHeader,
  Card,
  CardBody,
} from "reactstrap";
import { useHistory } from "react-router";
import EmpresaContext from "../../../../../contexts/Empresa";
import Logo from "../../Logo";

const ConfiguracoesConta: React.FC = () => {
  const { empresaSelecionada } = useContext<any>(EmpresaContext);
  const [empresa, setEmpresa] = useState(empresaSelecionada);

  // const history = useHistory();
  const [nome, setNome] = useState<any>(null);
  const [cnpj, setCnpj] = useState(null);
  const [site, setSite] = useState<any>();
  const [perfil_negocio_id, setPerfil_negocio_id] = useState(null);
  const [plano_id, setPlano_id] = useState(null);
  const [periodo_cortesia, setPeriodo_cortesia] = useState(0);
  const [observacoes, setObservacoes] = useState("");
  const [cabecalho_publicacao, setCabecalho_publicacao] = useState("");
  const [rodape_publicacao, setRodape_publicacao] = useState("");

  const [perfisNegocio, setPerfisNegocio] = useState([]);
  const [planos, setPlanos] = useState([]);

  const [saving, setSaving] = useState(false);
  const [erros, setErros] = useState<any>({});
  const notificationAlert = useRef<any>();

  function throwError(text: string) {
    if (notify) notify("danger", text);
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  useEffect(() => {
    if (perfisNegocio.length === 0) loadPerfisNegocio();
    if (planos.length === 0) loadPlanos();
  }, []);

  useEffect(() => {
    setNome(empresaSelecionada.nome);
    setCnpj(empresaSelecionada.cnpj);
    setSite(empresaSelecionada.site);
    setPerfil_negocio_id(empresaSelecionada.perfil_negocio_id);
    setPlano_id(empresaSelecionada.plano_id);
    setPeriodo_cortesia(empresaSelecionada.periodo_cortesia);
    setObservacoes(empresaSelecionada.observacoes);
  }, []);

  async function loadPerfisNegocio() {
    try {
      const response = await api.get(`/common/perfis_negocio`);
      setPerfisNegocio(response.data);
    } catch (error) {
      console.log(error);
      throwError("Não foi possível carregar Perfis Negócio");
    }
  }

  async function loadPlanos() {
    try {
      const response = await api.get(`/common/planos`);
      setPlanos(response.data);
    } catch (error) {
      console.log(error);
      // throwError("Não foi possível carregar Planos");
    }
  }

  async function handleSave() {
    setSaving(true);
    if (empresaSelecionada && empresaSelecionada.id) {
      await update();
    } else {
      await insert();
    }
    setSaving(false);
  }

  async function insert() {
    try {
      const response = await api.post("/common/empresas", {
        nome,
        cnpj,
        site,
        perfil_negocio_id,
        plano_id,
        periodo_cortesia,
        observacoes,
      });
      updateErros(null);
      notify("success", "Empresa adicionada com sucesso");
      updateEmpresa(response.data);
    } catch (error: any) {
      console.log(error);
      throwError("Não foi possível salvar empresa");
      updateErros(error.response.data);
    }
  }

  function updateEmpresa(empresa: any) {
    setNome(empresa.nome);
    setCnpj(empresa.cnpj);
    setSite(empresa.site);
    setPerfil_negocio_id(empresa.perfil_negocio_id);
    setPlano_id(empresa.plano_id);
    setPeriodo_cortesia(empresa.periodo_cortesia);
    setObservacoes(empresa.observacoes);
  }

  async function update() {
    try {
      const response = await api.put(
        `/common/empresas/${empresaSelecionada.id}`,
        {
          nome,
          cnpj,
          site,
          perfil_negocio_id,
          plano_id,
          periodo_cortesia,
          observacoes,
        }
      );
      updateErros(null);
      notify("success", "Empresa alterada com sucesso");
      updateEmpresa(response.data);
    } catch (error: any) {
      console.log({ error });
      throwError("Não foi possível salvar empresa");
      if (error.response && error.response.data) {
        updateErros(error.response.data);
      }
    }
  }

  function updateErros(error: any) {
    try {
      if (error) {
        const errors: any = {};
        for (let e of error) {
          errors[e.field] = e.message;
        }
        setErros(errors);
      } else {
        setErros({});
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onEmpresaChange(empresa: any) {
    setEmpresa(empresa ? empresa : {});
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Dados da empresa" parentName="Minha empresa" />
      {/* @ts-ignore */}
      <div className="container-fluid mt--6 ">
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                {empresa?.id && (
                  <Logo
                    history={undefined}
                    onEmpresaChange={onEmpresaChange}
                    notify={notify}
                    empresa={empresa}
                  />
                )}
                <h1 className="mb-0">Dados da empresa</h1>
                <small
                  className="text-muted"
                  style={{ position: "absolute", bottom: "4px" }}
                >
                  Para informações sobre os melhores formatos de logotipo e seu
                  uso, leia{" "}
                  <a
                    className="text-primary"
                    style={{ textDecoration: "underline" }}
                    href="https://ajuda.playnee.com/artigos/como-adicionar-logomarca-na-empresa"
                    target="_blank"
                  >
                    este artigo
                  </a>
                  .
                </small>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={4} md={4}>
                    <FormGroup>
                      <label className="form-control-label">Nome*</label>
                      <Input
                        className="form-control"
                        placeholder="Nome da empresa..."
                        type="text"
                        value={nome}
                        onChange={(e: any) => setNome(e.target.value)}
                      />
                      <small className="text-danger">
                        {erros.nome ? erros.nome : ""}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <FormGroup>
                      <label className="form-control-label">CNPJ</label>
                      <InputMask
                        placeholder="Informe o CNPJ..."
                        className="form-control"
                        maskPlaceholder={null}
                        mask="99.999.999/9999-99"
                        value={cnpj}
                        onChange={(e: any) => setCnpj(e.target.value)}
                      />
                      <small className="text-danger">
                        {erros.cnpj ? erros.cnpj : ""}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Site
                      </label>
                      <Input
                        className="form-control"
                        placeholder="https://exemplo.com.br"
                        type="text"
                        value={site}
                        //@ts-ignore
                        onChange={({ target: any }) => setSite(target.value)}
                      />
                      <small className="text-danger">{erros.site || ""}</small>
                    </FormGroup>
                  </Col>
                </Row>
                {/* Campo período cortesia só fica visível quando a empresa esta sendo editada pelo menu Empresas (Adiministrador) */}
                {empresaSelecionada && (
                  <Row>
                    <Col lg={4} md={4}>
                      <FormGroup>
                        <label className="form-control-label">
                          Perfil Negocio*
                        </label>
                        <Select2
                          className="form-control"
                          value={perfil_negocio_id}
                          options={{
                            placeholder: "Selecione o perfil do negócio...",
                          }}
                          //@ts-ignore
                          onSelect={(e: any) =>
                            //@ts-ignore
                            setPerfil_negocio_id(parseInt(e.target.value))
                          }
                          data={perfisNegocio.map((item: any) => ({
                            id: item.id,
                            text: item.nome,
                          }))}
                        />
                        <small className="text-danger">
                          {erros.perfil_negocio_id || ""}
                        </small>
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={4}>
                      <FormGroup>
                        <label className="form-control-label">Plano*</label>
                        <Select2
                          className="form-control"
                          value={plano_id}
                          options={{
                            placeholder: "Selecione o plano...",
                          }}
                          onSelect={(e: any) =>
                            //@ts-ignore
                            setPlano_id(parseInt(e.target.value))
                          }
                          data={planos.map((item: any) => ({
                            id: item.id,
                            text: item.nome,
                          }))}
                          disabled={true}
                        />
                        <small className="text-danger">
                          {erros.plano_id || ""}
                        </small>
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Período de Cortesia
                        </label>
                        <Button
                          className="p-0 ml-2 mb-1"
                          color="link"
                          id="tooltipPeriodoCortesia"
                          type="button"
                        >
                          ?
                        </Button>
                        <UncontrolledPopover
                          placement="top"
                          target="tooltipPeriodoCortesia"
                        >
                          <PopoverBody>
                            Infome o valor, em dias, referente ao período de
                            cortesia desta empresa. Para período indeterminado,
                            informe o valor -1.
                          </PopoverBody>
                        </UncontrolledPopover>
                        <Input
                          className="form-control"
                          placeholder="valor em dias..."
                          type="number"
                          value={periodo_cortesia}
                          min={-1}
                          max={731}
                          onChange={({ target: any }) =>
                            //@ts-ignore
                            setPeriodo_cortesia(target.value)
                          }
                        />
                        <small className="text-danger">
                          {erros.periodo_cortesia || ""}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Observações
                      </label>
                      <Input
                        className="form-control"
                        type="textarea"
                        style={{ height: 250 }}
                        value={observacoes}
                        onChange={({ target }) => setObservacoes(target.value)}
                      />
                      <small className="text-danger">
                        {erros.corpo_texto || ""}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="float-right ">
                      {/* <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => history.goBack()}
            >
              Voltar
            </Button> */}
                      <Button
                        color="primary"
                        onClick={handleSave}
                        disabled={saving}
                      >
                        {saving && <Spinner size="sm" />}
                        Salvar
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ConfiguracoesConta;
