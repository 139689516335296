import React from 'react';
import Form from "./Form";

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader';

export default ({ location, ...props }) => {
    return (
        <>
            <SimpleHeader
                name="Nova Mala Direta"
                parentName="Oportunidades"
                {...props} />
            <Form
                location={location}
                {...props}
            />
        </>
    );
}