import React, { useState, useEffect } from 'react';

import api from "../../../../../../services/api";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Form from './Form'
import { MenuComportamento } from "../../../../../../components/Menus";
import Estagios from './Estagios'
import {
  Button,
  Badge,
  Row,
  Col,
  Modal,
} from "reactstrap";

export default ({ notify, empresa, ...props }) => {

  const [funis, setFunis] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [showModalEstagio, setShowModalEstagio] = useState(false)
  const [showModalInativar, setShowModalInativar] = useState(false)
  const [showModalTornarPadrao, setShowModalTornarPadrao] = useState(false)

  const [funilSelecionado, setFunilSelecionado] = useState(null)
  const [columns, setColumns] = useState([
    {
      dataField: 'acoes',
      formatter: (cell, row) => acoesFormatter(cell, row)
    },
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true,
      formatter: (cell, row) => nomeDescricaoFormatter(cell, row)
    },
    {
      dataField: 'fl_ispadraoempresa',
      text: '',
      sort: false,
      formatter: (cell, row) => isPadraoFormatter(cell, row),
      align: 'center',
      headerAlign: 'center'
    },
    {
      dataField: 'situacao',
      text: 'Situação',
      sort: true,
      //formatter: (cell, row) => row.situacao == 'A' ? 'Ativo' : 'Inativo',
      formatter: (cell, row) => situacaoFormatter(cell, row),
      align: 'center',
      headerAlign: 'center'
    }
  ])

  useEffect(() => {
    if (funis.length === 0) loadFunis()
  }, [])

  //ao fechar modal editar, remover funilSelecionado
  useEffect(() => {
    if (!showModal) {
      setFunilSelecionado(null)
    }
  }, [showModal])

  //ao fechar modal estágios, remover funilSelecionado
  useEffect(() => {
    if (!showModalEstagio) {
      setFunilSelecionado(null)
    }
  }, [showModalEstagio])

  //ao fechar modal inativar, remover funilSelecionado
  useEffect(() => {
    if (!showModalInativar) {
      setFunilSelecionado(null)
    }
  }, [showModalInativar])

  //ao fechar modal tornar padrão, remover funilSelecionado
  useEffect(() => {
    if (!showModalTornarPadrao) {
      setFunilSelecionado(null)
    }
  }, [showModalTornarPadrao])


  function acoesFormatter(cell, row) {
    var acao;

    if (row) {
      if (row.situacao == 'I' || row.fl_ispadraoempresa == true) {
        acao = [
          {
            label: 'Editar Funil',
            icon: 'far fa-edit',
            onClick: () => edit(row)
          },
          {
            label: 'Estágios',
            icon: 'far fa-flag',
            onClick: () => editEstagio(row)
          }]
      } else {
        acao = [
          {
            label: 'Editar Funil',
            icon: 'far fa-edit',
            onClick: () => edit(row)
          },
          {
            label: 'Estágios',
            icon: 'far fa-flag',
            onClick: () => editEstagio(row)
          },
          ,
          {
            label: 'Tornar Padrão',
            icon: 'far fa-check-circle',
            onClick: () => handlerConfirmarTornarPadrao(row)
          },
          {
            label: 'Inativar',
            icon: 'fas fa-trash',
            onClick: () => handlerConfirmarInativar(row)
          }]
      }
    }

    return (<>
      <MenuComportamento
        direction='right'
        actions={acao}
      />
    </>)

  }

  function nomeDescricaoFormatter(cell, row) {
    let nome = cell
    let descricao = row.descricao

    return (
      <div title={descricao}>
        {nome}
      </div>
    )
  }

  function situacaoFormatter(cell, row) {
    let situacao = cell
    let color = 'secondary'
    switch (cell) {
      case 'A':
        situacao = 'Ativo'
        color = 'success'
        break;
      case 'I':
        situacao = 'Inativo'
        color = 'danger'
        break;
    }
    return (
      <>
        <Badge color={color} className="badge-lg" pill>
          {situacao}
        </Badge>
      </>
    )
  }

  function isPadraoFormatter(cell, row) {
    let isPadrao = cell
    let color = 'primary'

    var badge = <></>;

    if (isPadrao == true) {
      badge = <>
        <Badge color={color} className="badge-lg" pill>
          Funil Padrão
        </Badge>
      </>
    }

    return (
      badge
    )
  }

  function edit(funil) {
    setFunilSelecionado(funil)
    setShowModal(true)
  }

  function editEstagio(funil) {
    setFunilSelecionado(funil)
    setShowModalEstagio(true)
  }

  function handlerConfirmarInativar(funil) {
    setFunilSelecionado(funil)
    setShowModalInativar(true)
  }

  function handlerConfirmarTornarPadrao(funil) {
    setFunilSelecionado(funil)
    setShowModalTornarPadrao(true)
  }

  function handleSaveFunil() {
    notify('success', 'Funil salvo')
    loadFunis()
    setShowModal(false)
  }

  async function loadFunis() {
    try {
      const response = await api.get(`/common/empresas/${empresa.id}/funis`)
      if (response.data) {
        setFunis(response.data)
      }
    } catch (error) {
      console.log(error)
      notify('danger', 'Não foi possível carregar os Funis')
    }
  }

  async function handlerInativar(funil_id) {
    try {
      const response = await api.put(`/common/empresas/${empresa.id}/funis/${funil_id}/inativar`)
      if (response.data) {
        notify('success', response.data.message)
        loadFunis()
      }
    } catch (error) {
      console.log(error)
      notify('danger', 'Não foi possível Inativar o Funil')
    } finally {
      setShowModalInativar(false)
      setFunilSelecionado(null)
    }
  }

  async function handlerTornarPadrao(funil_id) {
    try {
      const response = await api.put(`/common/empresas/${empresa.id}/funis/${funil_id}/tornarpadraoempresa`)
      if (response.data) {
        notify('success', response.data.message)
        loadFunis()
      }
    } catch (error) {
      console.log(error)
      notify('danger', 'Não foi possível configurar o Funil como Padrão')
    } finally {
      setShowModalTornarPadrao(false)
      setFunilSelecionado(null)
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          color="primary"
          type="button"
          className="btn-icon btn-3"
          onClick={() => setShowModal(!showModal)}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-fat-add"></i>
          </span>
          <span className="btn-inner--text">Novo</span>
        </Button>

        <Modal
          className="modal-dialog-centered"
          isOpen={showModal}
          toggle={() => setShowModal(!showModal)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {funilSelecionado ? 'Alterar Funil' : 'Novo Funil'}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModal(!showModal)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              notify={notify}
              funilId={funilSelecionado?.id}
              onSuccess={handleSaveFunil}
              close={() => setShowModal(false)}
              empresaId={empresa.id}
            />
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={showModalEstagio}
          toggle={() => setShowModalEstagio(!showModalEstagio)}
        >
          <div className="modal-header">
            <div>
              <h5 className="modal-title" id="exampleModalLabel">
                {funilSelecionado ? 'Editar Estágios do Funil' : 'ERROR'}
              </h5>
              <h4>
                {funilSelecionado ? funilSelecionado.nome : 'FUNIL NOMEERROR'}
              </h4>
            </div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModalEstagio(!showModalEstagio)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Estagios
              {...props}
              notify={notify}
              empresa={empresa}
              funilId={funilSelecionado?.id}
            />
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered modal-danger"
          size="md"
          isOpen={showModalInativar}
          toggle={() => setShowModalInativar(!showModalInativar)}

        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Inativar Funil
            </h6>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="fas fa-exclamation-circle ni-3x" />
              <h4 className="heading mt-4"></h4>
              <p>
                Você realmente deseja inativar este Funil?
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModalInativar(false)}
            >
              Fechar
            </Button>
            <Button
              onClick={() => handlerInativar(funilSelecionado?.id)}
              className="btn-white"
              color="default"
              type="button">
              Inativar
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered modal-primary"
          size="md"
          isOpen={showModalTornarPadrao}
          toggle={() => setShowModalTornarPadrao(!showModalTornarPadrao)}

        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Tornar Funil Padrão
            </h6>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="fas fa-exclamation-circle ni-3x" />
              <h4 className="heading mt-4"></h4>
              <p>
                Você realmente deseja tornar este funil o Funil Padrão da Empresa? Esta ação não impede que os demais funis ativos sejam usados.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModalTornarPadrao(false)}
            >
              Fechar
            </Button>
            <Button
              onClick={() => handlerTornarPadrao(funilSelecionado?.id)}
              className="btn-white"
              color="default"
              type="button">
              Tornar Padrão
            </Button>
          </div>
        </Modal>

      </div>
      <Row>
        <Col>
          <ToolkitProvider
            data={funis}
            keyField="nome"
            columns={columns}
            search
          >
            {props => (
              <div className="py-4">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                />
              </div>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </>
  );
}
