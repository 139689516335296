import styled from "styled-components";

export const CustomBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #eee;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  height: 48px;
  margin-left: auto;
  padding: 0.625rem 1.25rem 0.625rem 0.5rem;
  letter-spacing: 0.025em;
  font-size: 0.875rem;

  & .custom-icon {
    font-size: 24px;
    color: white;
    background: green;
    border-radius: 50px;
    width: 36px;
    height: 36px;
    display: inline-block;
    font-weight: 500;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
`;
