import api from '../../../services/api';

export default function useReports() {
  async function fetchCampanhas(empresaId: string | number) {
    try {
      const { data: campanhas } = await api.get(
        `marketing/campanha/${empresaId}`
      );
      return campanhas;
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchEmpresasGrupo(empresaId: string | number) {
    try {
      const { data: empresas } = await api.get(
        `common/empresas/${empresaId}/empresas-grupo`
      );

      return empresas;
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchAnuncios(campanhaId: number, empresaId: number) {
    try {
      const { data: anuncios } = await api.get(
        `marketing/anuncios/${campanhaId}/${empresaId}`
      );

      if (anuncios.length > 0) anuncios.unshift({ id: -1, nome: '- Todos -' });

      return anuncios;
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchEquipes(empresaId: number) {
    try {
      const { data: equipes } = await api.get(
        `/common/empresas/${empresaId}/equipes`
      );
      return equipes;
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchFunis(empresaId: number) {
    const { data: funis } = await api.get(`common/empresas/${empresaId}/funis`);
    if (funis) {
      funis.unshift({ id: -1, nome: '- Todos -' });
    }

    return funis;
  }

  async function fetchTiposProdutos(empresaId: number) {
    try {
      const { data: tiposProdutos } = await api.get(
        `/common/empresas/${empresaId}/tipos-produtos?situacao=A`
      );

      if (tiposProdutos) {
        tiposProdutos.unshift({ id: -1, nome: '- Todos -' });
      }

      return tiposProdutos;
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchProdutos(
    empresaId: number,
    tipoProduto: string | number
  ) {
    try {
      const { data: produtos } = await api.get(
        `/common/empresas/${empresaId}/produtos/`,
        {
          params: {
            situacao: 'A',
            limit: 1000,
            tipoProduto,
          },
        }
      );
      return produtos;
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchCanais() {
    try {
      const { data: canais } = await api.get(`/marketing/canais`);
      return canais;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    fetchCampanhas,
    fetchAnuncios,
    fetchEquipes,
    fetchEmpresasGrupo,
    fetchFunis,
    fetchTiposProdutos,
    fetchProdutos,
    fetchCanais,
  };
}
