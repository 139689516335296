import React, { useState, useEffect, useContext } from "react";
import EmpresaContext from "../../../../../../contexts/Empresa";
import api from "../../../../../../services/api";
import { usePersistedState } from "../../../../../../hooks";

import Select2 from "react-select2-wrapper";
import Filters from "../../../../../../components/Headers/Filters";
import { Input, Row, Col, FormGroup, InputGroup } from "reactstrap";

export default ({ title, notify, load, ...props }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [nome, setNome] = usePersistedState("nome", null);
  const [situacao, setSituacao] = usePersistedState("situacoes", "A");
  const [tiposProdutos, setTipoProdutos] = usePersistedState(
    "tiposProdutos",
    []
  );

  const [tiposProdutosList, setTipoProdutosList] = useState([]);
  //Flag para definir tempo de execução
  const [runLoad, setRunLoad] = useState(props.search ? props.search : true);
  const [monitorClearFilters, setMonitorClearFilters] = useState(undefined);

  useEffect(() => {
    if (monitorClearFilters) search();
  }, [monitorClearFilters]);

  useEffect(() => {
    if (runLoad) {
      search();
      setRunLoad(false);
    }
  }, [runLoad]);

  useEffect(() => {
    if (tiposProdutosList.length === 0) loadTiposProdutos();
  }, []);

  async function loadTiposProdutos() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/tipos-produtos`
      );
      setTipoProdutosList(response.data);
    } catch (error) {
      console.log(error);
      throwError("Não foi possível carregar tipos de produtos");
    }
  }

  function throwError(text) {
    if (notify) notify("danger", text);
  }

  async function search() {
    await load({
      nome,
      situacao,
      tiposProdutos,
    });
  }

  function handleMultipleSelect(target, state) {
    const array = Array.from(target.selectedOptions);
    state(array.map((item) => item.value));
  }

  async function clearFilters() {
    setNome("");
    setSituacao("A");
    setTipoProdutos([]);
  }

  return (
    <>
      <Filters
        onSearch={search}
        title={<h1>Lista de Produtos</h1>}
        clearFilters={clearFilters}
        onFiltersClead={setMonitorClearFilters}
      >
        <Row className="py-4">
          <Col xs="12" lg="6" sm="12" md="12">
            <FormGroup>
              <label className="form-control-label">Nome</label>
              <Input
                className="form-control-alternative"
                placeholder="Nome do produto"
                type="text"
                value={nome}
                onChange={({ target }) => setNome(target.value)}
              />
            </FormGroup>
          </Col>
          <Col xs="12" lg="6" sm="6" md="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example-number-input"
              >
                Tipos de produtos
              </label>
              <InputGroup className="input-group-alternative">
                <Select2
                  multiple
                  onSelect={(e) =>
                    handleMultipleSelect(e.target, setTipoProdutos)
                  }
                  onUnselect={(e) =>
                    handleMultipleSelect(e.target, setTipoProdutos)
                  }
                  value={tiposProdutos}
                  data={tiposProdutosList.map((item) => ({
                    id: item.id,
                    text: item.nome,
                  }))}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs="12" lg="6" sm="6" md="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example-number-input"
              >
                Situação
              </label>
              <InputGroup className="input-group-alternative">
                <Select2
                  className="form-control"
                  value={situacao}
                  onSelect={({ target }) => setSituacao(target.value)}
                  data={[
                    { id: "A", text: "Ativo" },
                    { id: "I", text: "Inativo" },
                  ]}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Filters>
    </>
  );
};
