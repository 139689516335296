import React, { useContext, useEffect, useState } from 'react';
import ReactDatetimeClass from 'react-datetime';
//@ts-ignore
import Select2 from "react-select2-wrapper";
import EmpresaContext from "../../../../../contexts/Empresa";

import Filters from '../../../../../components/Headers/Filters';

// import { Container } from './styles';
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Col,
    Row,
    CardHeader,
    Button,
} from "reactstrap";
import empresas from '../../../../security/Users/empresas';
import { usePersistedState } from '../../../../../hooks';
import api from '../../../../../services/api';
import { User } from '../../../../../entities/Security';
import moment from 'moment';
import { Estrategia, GrupoEstrategico } from '../../../../../entities/Mentoring';
import { Empresa } from '../../../../../entities/Common';

type Props = {
    title: string
    setParamsAcoes({ }): void
}


const AcaoFilters: React.FC<Props> = ({ title, setParamsAcoes, ...props }) => {


    const { empresaSelecionada, empresas } = useContext(EmpresaContext)

    const [empresaBoxSelect, setEmpresaBoxSelect] = useState([])
    const [empresaComboBox, setEmpresaComboBox] = useState(new Array<Empresa>())

    const [situacaoSelected, setSituacaoSelected] = useState([])
    
    const [usersSelected, setUsersSelected] = useState([])
    const [usersComboBox, setUsersComboBox] = useState(new Array<User>())

    const [estrategia, setEstrategia] = useState<number | undefined>()
    const [estrategias, setEstrategias] = useState(new Array<Estrategia>())
    const [grupoEstrategico, setGrupoEstrategico] = useState<number | undefined | null>()
    const [gruposEstrategicos, setGruposEstrategicos] = useState(new Array<GrupoEstrategico>())

    const [dataAgendamentoInicial, setDataAgendamentoInicial] = usePersistedState('dataAgendamentoInicial', getDataAgendamentoInicial())
    const [dataAgendamentoFinal, setDataAgendamentoFinal] = usePersistedState('dataAgendamentoFinal', getDataAgendamentoFinal())

    useEffect(() => {
        loadGruposEstrategicos()
    }, [])

    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        if (empresaBoxSelect && empresaBoxSelect.length > 0) {
            loadUserByEmpresaBox()
        } else {
            loadUser()
        }

    }, [empresaBoxSelect])

    useEffect(() => {
        loadEstrategias()
    }, [grupoEstrategico])



    async function loadUser() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/colaboradores`)
            setUsersComboBox(await response.data)
        } catch (error) {
            console.error(error)
        }
    }

    async function loadUserByEmpresaBox() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/colaboradoresbyempresas`,
                {
                    params: {
                        empresa_id: empresaBoxSelect,
                        empresas: empresaBoxSelect
                    }
                }
            )
            setUsersComboBox(await response.data)
        } catch (error) {
            console.error(error)
        }
    }

    async function loadEstrategias() {
        if (!grupoEstrategico) {
            setEstrategia(undefined)
            setEstrategias(new Array<Estrategia>())
            return
        }
        try {
            const response = await api.get(`mentoring/grupos-estrategicos/${grupoEstrategico}/estrategias`)
            setEstrategias(await response.data)
        } catch (error) {
            console.error(error)
        }
    }

    async function loadGruposEstrategicos() {
        try {
            const response = await api.get(`/mentoring/grupos-estrategicos`)
            const data = await response.data
            setGruposEstrategicos(data)
        } catch (error) {
            console.error(error)
        }
    }


    function handleMultipleSelect(target: any, state: any) {
        const array = Array.from(target.selectedOptions)
        state(array.map((item: any) => item.value))
    }


    function handleEmpresaSelect(target: any) {
        console.log(target.selectedOptions?.[0].value)
        setEmpresaBoxSelect(target.selectedOptions?.[0].value)
    }

    function getDataAgendamentoInicial() {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth());
    }

    function getDataAgendamentoFinal() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    function handleClearFilter() {
        setDataAgendamentoInicial(getDataAgendamentoInicial)
        setDataAgendamentoFinal(getDataAgendamentoFinal)
        setEmpresaBoxSelect([]);
        setUsersSelected([]);
        loadUser();
        setSituacaoSelected([]);
        setGrupoEstrategico(0)
        setEstrategia(0)
    }

    function handleSearch() {

        setParamsAcoes(
            {
                empresa_id: empresaBoxSelect ? empresaBoxSelect : [],
                colaboradores: usersSelected ? usersSelected : [],
                situacoes: situacaoSelected ? situacaoSelected : [],
                grupoestrategico_id: grupoEstrategico,
                estrategia_id: estrategia,
                data_inicio: dataAgendamentoInicial,
                data_fim: dataAgendamentoFinal
            }
        )

    }


    return (
        <>
            <Filters
                onSearch={handleSearch}
                title={<h1>{title}</h1>}
                onFiltersClead={() => { }}
                clearFilters={() => { handleClearFilter() }}
                isLoading={false}
                isOpen={false}
            >
                <Row>
                    <Col xs={4}>
                        <FormGroup>
                            <Label for="exampleSelect">Empresa</Label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    //onSelect={(e: any) => handleEmpresaSelect(e.target)}
                                    multiple
                                    onSelect={(e: any) => handleMultipleSelect(e.target, setEmpresaBoxSelect)}
                                    onUnselect={(e: any) => handleMultipleSelect(e.target, setEmpresaBoxSelect)}

                                    options={{
                                        placeholder: "Selecione as Empresas"
                                    }}
                                    value={empresaBoxSelect}
                                    data=
                                    {
                                        // //lista de empresas, mais uma empresa para dar a opção -TODAS-
                                        // //ordenadas em ordem alfabética (rever maneira de ordenar para encontrar algo mais legível)
                                        // [...empresas, { id: -1, nome: '-TODAS-' } as Empresa].sort(function (a, b) { return (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0) }).map((item) => (
                                        //     {
                                        //         id: item.id,
                                        //         text: item.nome
                                        //     }
                                        // ))

                                        empresas.sort(function (a, b) { return (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0) }).map((item) => (
                                            {
                                                id: item.id,
                                                text: item.nome
                                            }
                                        ))
                                    }
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={4}>
                        <FormGroup>
                            <Label for="exampleSelect">Colaboradores</Label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    multiple
                                    onSelect={(e: any) => handleMultipleSelect(e.target, setUsersSelected)}
                                    onUnselect={(e: any) => handleMultipleSelect(e.target, setUsersSelected)}
                                    options={{
                                        placeholder: "Selecione colaboradores"
                                    }}
                                    value={usersSelected}
                                    data={
                                        //usersComboBox.map((item) => ({ id: item.id, text: item.name }))
                                        usersComboBox.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0) }).map((item) => (
                                            {
                                                id: item.id,
                                                text: item.name
                                            }
                                        ))
                                    }
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={4}>
                        <FormGroup>
                            <Label for="exampleSelect">Situação</Label>
                            <Select2
                                className="form-control"
                                //@ts-ignore
                                //onSelect={(e) => setSituacao(e.target.value)}
                                multiple
                                onSelect={(e: any) => handleMultipleSelect(e.target, setSituacaoSelected)}
                                onUnselect={(e: any) => handleMultipleSelect(e.target, setSituacaoSelected)}
                                value={situacaoSelected}
                                options={{
                                    placeholder: "Selecione Situações"
                                }}
                                data={[
                                    { id: 'nao_iniciado', text: 'Não Iniciado' },
                                    { id: 'iniciado', text: 'Iniciado' },
                                    { id: 'concluido', text: 'Concluído' },
                                    { id: 'nao_executado', text: 'Não Executado', }
                                ]}
                            />
                        </FormGroup>
                    </Col>
                </Row >
                <Row>
                    <Col xs="6" lg="6" sm="6" md="6">
                        <fieldset className="border p-2">
                            <legend className="w-auto" style={{ margin: 0 }}>
                                <label
                                    className="form-control-label"
                                >
                                    Estratégia
                                        </label>
                            </legend>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="exampleSelect"> Grupo Estratégico</Label>
                                        <Select2
                                            className="form-control"
                                            //@ts-ignore
                                            onSelect={(e) => setGrupoEstrategico(e.target.value)}
                                            value={grupoEstrategico}
                                            data={
                                                //gruposEstrategicos.map(item => ({ id: item.id, text: item.nome }))
                                                gruposEstrategicos.sort(function (a, b) { return (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0) }).map((item) => (
                                                    {
                                                        id: item.id,
                                                        text: item.nome
                                                    }
                                                ))
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="exampleSelect">Estratégia</Label>
                                        <Select2
                                            className="form-control"
                                            //@ts-ignore
                                            onSelect={(e) => setEstrategia(e.target.value)}
                                            value={estrategia}
                                            data={
                                                //estrategias.map(item => ({ id: item.id, text: item.nome }))
                                                estrategias.sort(function (a, b) { return (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0) }).map((item) => (
                                                    {
                                                        id: item.id,
                                                        text: item.nome
                                                    }
                                                ))
                                                
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                    <Col xs="auto" lg="6" sm="6" md="6">
                        <fieldset className="border p-2">
                            <legend className="w-auto" style={{ margin: 0 }}>
                                <label
                                    className="form-control-label"
                                >
                                    Quando
                                        </label>
                            </legend>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example-number-input"
                                        >
                                            Início
                                        </label>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: "Início",
                                                    style: {
                                                        width: '100px'
                                                    }
                                                }}
                                                //COMENTADO PORQUE É TESTE DE DATA
                                                value={moment(dataAgendamentoInicial)}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat={false}
                                                renderDay={(props, currentDate, selectedDate) => {
                                                    let classes = props.className;
                                                    if (
                                                        dataAgendamentoInicial &&
                                                        dataAgendamentoFinal &&
                                                        dataAgendamentoInicial._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " start-date";
                                                    } else if (
                                                        dataAgendamentoInicial &&
                                                        dataAgendamentoFinal &&
                                                        new Date(dataAgendamentoInicial._d + "") <
                                                        new Date(currentDate._d + "") &&
                                                        new Date(dataAgendamentoFinal._d + "") >
                                                        new Date(currentDate._d + "")
                                                    ) {
                                                        classes += " middle-date";
                                                    } else if (
                                                        dataAgendamentoFinal &&
                                                        dataAgendamentoFinal._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " end-date";
                                                    }
                                                    return (
                                                        <td {...props} className={classes}>
                                                            {currentDate.date()}
                                                        </td>
                                                    );
                                                }}
                                                onChange={e => setDataAgendamentoInicial(e)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example-number-input"
                                        >
                                            Fim
                                                </label>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: "Fim",
                                                    style: {
                                                        width: '100px'
                                                    }
                                                }}
                                                value={moment(dataAgendamentoFinal)}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat={false}
                                                renderDay={(props, currentDate, selectedDate) => {
                                                    let classes = props.className;
                                                    if (
                                                        dataAgendamentoInicial &&
                                                        dataAgendamentoFinal &&
                                                        dataAgendamentoInicial._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " start-date";
                                                    } else if (
                                                        dataAgendamentoInicial &&
                                                        dataAgendamentoFinal &&
                                                        new Date(dataAgendamentoInicial._d + "") <
                                                        new Date(currentDate._d + "") &&
                                                        new Date(dataAgendamentoFinal._d + "") >
                                                        new Date(currentDate._d + "")
                                                    ) {
                                                        classes += " middle-date";
                                                    } else if (
                                                        dataAgendamentoFinal &&
                                                        dataAgendamentoFinal._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " end-date";
                                                    }
                                                    return (
                                                        <td {...props} className={classes}>
                                                            {currentDate.date()}
                                                        </td>
                                                    );
                                                }}
                                                onChange={e => setDataAgendamentoFinal(e)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>

            </Filters>

        </>
    );
}

export default AcaoFilters;