import styled from "styled-components";
import { Input } from "reactstrap";

interface Props {
  $isSent?: boolean;
  $isWarning?: boolean;
}

interface ChatBoxProps {
  isRecordingAudio: boolean;
}

export const ChatBox = styled(Input)<ChatBoxProps>`
  resize: none;
  position: absolute;
  left: 10%;
  width: ${({ isRecordingAudio }) => (isRecordingAudio ? "70%" : "80%")};
  bottom: 2px;
  whiteSpace: pre-wrap;
  wordWrap: break-word;
  borderRadius: 8px;
`;

export const ChatHeader = styled.div`
  height: 59px;
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid rgb(236, 239, 243);
`;

export const MessageDateContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  .date-label {
    padding: 8px;
    background: rgb(243, 243, 243);
    border-radius: 8px;
    font-size: 0.75rem;
  }
`;

export const ChatWindow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: flex-end;
  overflow-x: hidden;
  background: rgb(236, 239, 243);
  padding-top: 4px;
`;

export const MessageBubble = styled.div<Props>`
  max-width: 80%;
  flex-direction: column;
  margin: 8px;
  padding: 6px 7px 8px 9px;
  background: ${({ $isSent, $isWarning }) => {
    if ($isSent && !$isWarning) return "rgb(122 219 246 / 42%)"
    if ($isSent && $isWarning) return "#f6e97a6b"
    return "#fff"
  }};
  align-self: ${({ $isSent }) => ($isSent ? "flex-end" : "flex-start")};
  border-radius: 7.5px;
  color: black;
  box-shadow: 0 1px 0.5px #0b141a21;

  .box {
    &--message {
      word-break: break-word;
    }
    &--timestamp {
      display: flex;
      flex: 1%;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;

      & span, small {
        font-size: .75rem;
      }
    }
  }

  .message {
    &--text {
      font-weight: 400;
      font-size: 14.2px;
      margin-bottom: 0;
    }

    &--timestamp {
      text-align: right;
      font-size: 0.7rem;
    }
  }
`;
