import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../contexts/Empresa'
import api from "../../../services/api";

import Form from "./Form"

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

export default ({ match, location, ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [id, setId] = useState(null)

    useEffect(() => {
        const { state } = location
    }, [])

    return (
        <>
            <SimpleHeader
                name="Novo Artigo"
                parentName="Artigos"
                {...props} />
            <Form
                {...props}                
            />
        </>
    )


}
