import React, { useEffect, useState, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
import moment from 'moment'
import 'moment/locale/pt-br'

import Pagination from '../../../../components/Pagination'
import {
    Button,
    Modal,
    ListGroupItem,
    ListGroup,
} from "reactstrap";


export default ({ show, historicos, oportunidade_id, notify, onHidde }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [pageProperties, setPageProperties] = useState({ page: 1, lastPage: 1 })

    useEffect(() => {
        if (show) loadHistorico(pageProperties)
    }, [show])

    async function loadHistorico({ page = 1, limit = 5 }) {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada.id}/oportunidades/${oportunidade_id}/historicos`, {
                params: {
                    page,
                    limit
                }
            })
            const array = await response.data.data
            setItens(array)
            setPageProperties(response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar histórico')
        }
    }

    const createHistoricoPreview = (item, key) => (
        <>
            <ListGroupItem
                key={key}
                className="list-group-item-action flex-column align-items-start py-4 px-4"
                href="#"
            >
                <div className="d-flex w-100 justify-content-between">
                    <div>
                        <div className="d-flex w-100 align-items-center">
                            <i class="far fa-clock mr-2"></i>
                            <h5 className="mb-1">{moment(item.created_at).format('LLL')}</h5>
                        </div>
                    </div>
                </div>
                <p className="text-sm mb-0">
                    <div dangerouslySetInnerHTML={{ __html: item.message }} />
                </p>
            </ListGroupItem>
        </>
    )



    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={show}
                toggle={onHidde}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Histórico
            </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidde}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <ListGroup flush className=' py-4 px-4'>
                        {itens.map((item, key) => (
                            <>
                                {createHistoricoPreview(item, key)}
                            </>
                        ))}
                    </ListGroup>
                    <Pagination
                        pageProperties={pageProperties}
                        load={(pageProperties) => {
                            loadHistorico(pageProperties)
                        }}
                    />
                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidde}
                    >
                        Fechar
            </Button>
                </div>
            </Modal>
        </>
    );
}
