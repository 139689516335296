import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from '../../../../../services/api';

import Table from '../../../../../components/Table'

export default ({ referencia_id, notify, tipo_contato, isEdit, malaDireta }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [recipients, setRecipients] = useState([]);
    const [lastSearch, setLastSearch] = useState({})
    const [pageProperties, setPageProperties] = useState({
        total: "0",
        perPage: 10,
        page: 1,
        lastPage: 1,
        loading: false,
        sort: new Map([])
    })

    useEffect(() => {
        if (malaDireta && malaDireta.id > 0) {
            console.log('IS EDIT EM DESTINATARIOS USEEFFECT MALA DIRETA')
            console.log('CARREGANDO DESTINOS POR MALA DIRETA')
            console.log(malaDireta)
            loadRecipientsByMalaDireta()
        }
    }, [malaDireta])


    useEffect(() => {
        if (referencia_id && referencia_id.length > 0) loadRecipients()
    }, [referencia_id])

    async function loadRecipients(page = 1,
        limit = 10,
        sortField = "contato",
        sortOrder = "asc",
        filters = lastSearch) {
        try {
            setPageProperties({
                ...pageProperties,
                loading: true
            })
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/destinatarios-malas-diretas-temporarios/${referencia_id}`, {
                params: {
                    tipo_contato,
                    page,
                    limit,
                    sortField,
                    sortOrder,
                    ...filters
                }
            })
            setRecipients(response.data.data)
            await updatePageProperties(response)
        } catch (err) {
            console.error(err)
            notify('danger', 'Houve um problema ao carregar os destinatários temporários.');
        }
    }

    async function loadRecipientsByMalaDireta(page = 1,
        limit = 10,
        sortField = "contato",
        sortOrder = "asc",
        filters = lastSearch) {

        console.log('LOAD BY MALA DIRETA')
        try {
            setPageProperties({
                ...pageProperties,
                loading: true
            })
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/destinatarios-malas-diretas/${malaDireta.id}`, {
                params: {
                    tipo_contato,
                    page,
                    limit,
                    sortField,
                    sortOrder,
                    ...filters
                }
            })
            setRecipients(response.data.data)
            await updatePageProperties(response)
        } catch (err) {
            console.error(err)
            notify('danger', 'Houve um problema ao carregar os destinatários temporários.');
        }
    }

    async function updatePageProperties(response) {
        const { total, perPage, page, lastPage } = await response.data
        setPageProperties({
            ...pageProperties,
            total,
            perPage,
            page,
            lastPage,
            loading: false,
        })
    }

    const handleTableChange = async (type, { page, sizePerPage, sortField, sortOrder }) => {
        try {
            if (type == 'sort') {
                const value = pageProperties.sort.get(sortField)
                const newOrder = (value || 'asc') == 'desc' ? 'asc' : 'desc'
                const map = pageProperties.sort
                map.set(sortField, newOrder)
                sortOrder = newOrder
            }
            if (malaDireta && malaDireta.id > 0) {
                await loadRecipientsByMalaDireta(page == 0 ? 1 : page, sizePerPage, sortField, sortOrder)

            } else {
                await loadRecipients(page == 0 ? 1 : page, sizePerPage, sortField, sortOrder)
            }

        } catch (error) {
            notify('danger', 'Houve um problema ao carregar os destinatários.');
        }
    }

    const [columns] = useState([
        {
            dataField: 'contato',
            text: tipo_contato == 'email' ? 'Email' : 'Telefone',
        },
        {
            dataField: 'cliente.nome',
            text: 'Nome',
        },
    ])

    return (
        <>
            <Table
                columns={columns}
                data={recipients}
                pageProperties={pageProperties}
                onTableChange={handleTableChange}
            />
        </>
    );
}

