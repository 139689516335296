import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../contexts/Empresa";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import api from "../../../services/api";
import { usePersistedState } from '../../../hooks/index'
import moment from 'moment'

import Filters from "../../../components/Headers/Filters";
import {
    Button,
    CardBody,
    Row,
    Col,
    Input,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

export default ({ title, load, children, filters = {}, clearFiltersPrioridades }, notify, ...props) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [dataPublicacaoInicial, setDataPublicacaoInicial] = usePersistedState('dataPublicacaoInicial', getDataPublicacaoInicial())
    const [dataPublicacaoFinal, setDataPublicacaoFinal] = usePersistedState('dataPublicacaoFinal', getDataPublicacaoFinal())
    const [dataCadastroInicial, setDataCadastroInicial] = usePersistedState('dataCadastroInicial', null)
    const [dataCadastroFinal, setDataCadastroFinal] = usePersistedState('dataCadastroFinal', null)
    const [titulo, setTitulo] = usePersistedState('titulo', null)
    const [situacoes, setSituacoes] = usePersistedState('situacoes', ['A'])
    const [site_id, setSite_id] = usePersistedState('site_id', null) 
    const [categoria_id, setCategoria_id] = usePersistedState('categoria_id', null)

    const [situacoesList, setSituacoesList] = useState([])
    const [sitesList, setSitesList] = useState([])
    const [categoriasList, setCategoriasList] = useState([])

    const [runLoad, setRunLoad] = useState(props.search ? props.search : true)
    const [monitorClearFilters, setMonitorClearFilters] = useState(undefined)

    useEffect(() => {
        if (monitorClearFilters) search()
    }, [monitorClearFilters])

    useEffect(() => {
        if (situacoesList.length === 0) loadSituacoes()
        if (sitesList.length === 0) loadSites()
    }, [])

    useEffect(() => {
        if (site_id && site_id > 0) {
            loadCategorias(site_id)
        } else {
            setCategoriasList([])
        }
    }, [site_id])

    useEffect(() => {
        if (runLoad) {
            search()
            setRunLoad(false)
        }
    }, [runLoad])

    async function loadSituacoes() {
        try {

            const data = [
                {
                    id: "A",
                    value: "Ativar",
                    label: "Ativo",
                    color: "success"
                },
                {
                    id: "I",
                    value: "Inativar",
                    label: "Inativo",
                    color: "danger"
                }
            ]

            setSituacoesList(data)
        } catch (err) {
            console.log(err)
        }
    }

    async function loadSites() {
        setSitesList([])
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/sites-empresas`);
            setSitesList(response.data)
        } catch (err) {
            notify("danger", "Não foi possível carregar os sites.");
        }
    }

    async function loadCategorias(site_id) {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/sites-empresas/${site_id}/categorias`);
            setCategoriasList(response.data)
        } catch (err) {
            notify("danger", "Não foi possível carregar as categorias.");
        }
    }

    function getDataPublicacaoInicial() {
        if ('dataAgendamentoInicial' in filters) {
            return filters.dataAgendamentoInicial
        }
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth());
    }

    function getDataPublicacaoFinal() {
        if (filters.dataAgendamentoFinal) return filters.dataAgendamentoFinal
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    async function handleClearFilter() {
        if (clearFiltersPrioridades) {
            await clearFiltersPrioridades()
        }
        clearFilters();
    }

    function clearFilters() {
        setDataPublicacaoInicial(getDataPublicacaoInicial)
        setDataPublicacaoFinal(getDataPublicacaoFinal)
        setDataCadastroInicial(null)
        setDataCadastroFinal(null)
        setTitulo('')
        setSituacoes(['A'])
        setSite_id(null)
        setCategoria_id(null)
    }

    function handleMultipleSelect(target, state) {
        const array = Array.from(target.selectedOptions)
        state(array.map((item) => item.value))
    }

    async function handlePesquisar() {
        await search(true)
    }

    function search(clearPagitation) {
        load({
            dataPublicacaoInicial,
            dataPublicacaoFinal,
            dataCadastroInicial,
            dataCadastroFinal,
            titulo,
            situacoes,
            site_id,
            categoria_id
        }, clearPagitation)
    }

    return (
        <>
            <Filters
                onSearch={handlePesquisar}
                title={title}
                clearFilters={handleClearFilter}
                onFiltersClead={setMonitorClearFilters}
            >
                <CardBody>
                    <Row>
                        <Col xs="auto" lg="6" sm="6" md="6">
                            <fieldset class="border p-2">
                                <legend class="w-auto" style={{ margin: 0 }}>
                                    <label
                                        className="form-control-label"
                                    >
                                        Publicação
                                    </label>
                                </legend>
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example-number-input"
                                            >
                                                Início
                                            </label>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-calendar-grid-58" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder: "Agendamento inicial",
                                                        style: {
                                                            width: '100px'
                                                        }
                                                    }}
                                                    value={moment(dataPublicacaoInicial)}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat={false}
                                                    renderDay={(props, currentDate, selectedDate) => {
                                                        let classes = props.className;
                                                        if (
                                                            dataPublicacaoInicial &&
                                                            dataPublicacaoFinal &&
                                                            dataPublicacaoInicial._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " start-date";
                                                        } else if (
                                                            dataPublicacaoInicial &&
                                                            dataPublicacaoFinal &&
                                                            new Date(dataPublicacaoInicial._d + "") <
                                                            new Date(currentDate._d + "") &&
                                                            new Date(dataPublicacaoFinal._d + "") >
                                                            new Date(currentDate._d + "")
                                                        ) {
                                                            classes += " middle-date";
                                                        } else if (
                                                            dataPublicacaoFinal &&
                                                            dataPublicacaoFinal._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " end-date";
                                                        }
                                                        return (
                                                            <td {...props} className={classes}>
                                                                {currentDate.date()}
                                                            </td>
                                                        );
                                                    }}
                                                    onChange={e => setDataPublicacaoInicial(e)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example-number-input"
                                            >
                                                Fim
                                            </label>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-calendar-grid-58" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder: "Agendamento final",
                                                        style: {
                                                            width: '100px'
                                                        }
                                                    }}
                                                    value={moment(dataPublicacaoFinal)}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat={false}
                                                    renderDay={(props, currentDate, selectedDate) => {
                                                        let classes = props.className;
                                                        if (
                                                            dataPublicacaoInicial &&
                                                            dataPublicacaoFinal &&
                                                            dataPublicacaoInicial._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " start-date";
                                                        } else if (
                                                            dataPublicacaoInicial &&
                                                            dataPublicacaoFinal &&
                                                            new Date(dataPublicacaoInicial._d + "") <
                                                            new Date(currentDate._d + "") &&
                                                            new Date(dataPublicacaoFinal._d + "") >
                                                            new Date(currentDate._d + "")
                                                        ) {
                                                            classes += " middle-date";
                                                        } else if (
                                                            dataPublicacaoFinal &&
                                                            dataPublicacaoFinal._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " end-date";
                                                        }
                                                        return (
                                                            <td {...props} className={classes}>
                                                                {currentDate.date()}
                                                            </td>
                                                        );
                                                    }}
                                                    onChange={e => setDataPublicacaoFinal(e)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                        <Col xs="auto" lg="6" sm="6" md="6" >
                            <fieldset class="border p-2">
                                <legend class="w-auto" style={{ margin: 0 }}>
                                    <label
                                        className="form-control-label"
                                    >
                                        Cadastro
                                    </label>
                                </legend>
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example-number-input"
                                            >
                                                Início
                                            </label>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-calendar-grid-58" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder: "Data inicial",
                                                        style: {
                                                            width: '100px'
                                                        }
                                                    }}
                                                    value={moment(dataCadastroInicial)}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat={false}
                                                    renderDay={(props, currentDate, selectedDate) => {
                                                        let classes = props.className;
                                                        if (
                                                            dataCadastroInicial &&
                                                            dataCadastroFinal &&
                                                            dataCadastroInicial._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " start-date";
                                                        } else if (
                                                            dataCadastroInicial &&
                                                            dataCadastroFinal &&
                                                            new Date(dataCadastroInicial._d + "") <
                                                            new Date(currentDate._d + "") &&
                                                            new Date(dataCadastroFinal._d + "") >
                                                            new Date(currentDate._d + "")
                                                        ) {
                                                            classes += " middle-date";
                                                        } else if (
                                                            dataCadastroFinal &&
                                                            dataCadastroFinal._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " end-date";
                                                        }
                                                        return (
                                                            <td {...props} className={classes}>
                                                                {currentDate.date()}
                                                            </td>
                                                        );
                                                    }}
                                                    onChange={e => setDataCadastroInicial(e)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="example-number-input"
                                            >
                                                Fim
                                            </label>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-calendar-grid-58" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <ReactDatetime
                                                    inputProps={{
                                                        placeholder: "Data final",
                                                        style: {
                                                            width: '100px'
                                                        }
                                                    }}
                                                    value={moment(dataCadastroFinal)}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat={false}
                                                    renderDay={(props, currentDate, selectedDate) => {
                                                        let classes = props.className;
                                                        if (
                                                            dataCadastroInicial &&
                                                            dataCadastroFinal &&
                                                            dataCadastroInicial._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " start-date";
                                                        } else if (
                                                            dataCadastroInicial &&
                                                            dataCadastroFinal &&
                                                            new Date(dataCadastroInicial._d + "") <
                                                            new Date(currentDate._d + "") &&
                                                            new Date(dataCadastroFinal._d + "") >
                                                            new Date(currentDate._d + "")
                                                        ) {
                                                            classes += " middle-date";
                                                        } else if (
                                                            dataCadastroFinal &&
                                                            dataCadastroFinal._d + "" === currentDate._d + ""
                                                        ) {
                                                            classes += " end-date";
                                                        }
                                                        return (
                                                            <td {...props} className={classes}>
                                                                {currentDate.date()}
                                                            </td>
                                                        );
                                                    }}
                                                    onChange={e => setDataCadastroFinal(e)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col xs="4" lg="4" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Título
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    placeholder="Título da publicação..."
                                    type="text"
                                    value={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="2" lg="2" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input"
                                >
                                    Situações
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        multiple
                                        defaultValue="A"
                                        onSelect={(e) => handleMultipleSelect(e.target, setSituacoes)}
                                        onUnselect={(e) => handleMultipleSelect(e.target, setSituacoes)}
                                        options={{
                                            placeholder: "Selecione uma situação...."
                                        }}
                                        value={situacoes}
                                        data={
                                            situacoesList.map((item) => ({ id: item.id, text: item.label }))
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="3" lg="3" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input"
                                >
                                    Site
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        className="form-control"
                                        value={site_id}
                                        options={{
                                            placeholder: "Selecione um site..."
                                        }}
                                        onSelect={e => setSite_id(e.target.value)}
                                        data={sitesList.map(item => ({ id: item.id, text: item.nome }))}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="3" lg="3" sm="6" md="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-number-input"
                                >
                                    Categoria
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <Select2
                                        className="form-control"
                                        value={categoria_id}
                                        options={{
                                            placeholder: "Selecione uma categoria..."
                                        }}
                                        onSelect={e => setCategoria_id(e.target.value)}
                                        data={categoriasList.map(item => ({ id: item.id, text: item.nome }))}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {children}
                    </Row>
                </CardBody>
            </Filters>

        </>
    );
}
