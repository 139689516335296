import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
} from "reactstrap";
import { Cliente } from "../../../../../entities/Common";
import { Oportunidade } from "../../../../../entities/Marketing";

export interface OportunidadeProps extends Oportunidade {
  cliente: Cliente;
}

interface OportunidadeCardProps {
  oportunidade: OportunidadeProps;
}

export default function OportunidadeCard({
  oportunidade,
}: OportunidadeCardProps) {
  const { created_at, anuncio, data_agendamento, estagio, cliente } =
    oportunidade;

  return (
    <Card className="w-100 mb-0">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <span className="text-muted">
            {new Date(created_at).toLocaleString()}
          </span>
          <span
            className="text-white"
            style={{
              background: estagio.cor,
              padding: "2px 4px",
              fontSize: ".65rem",
              fontWeight: "bold",
              borderRadius: "2px",
            }}
          >
            {estagio.nome}
          </span>
        </div>
        <span className="font-weight-bold my-1 d-inline-block">{cliente.nome}</span>
        <CardText>
          <div
            className="d-flex flex-column font-weight-bold"
            style={{
              fontSize: ".75rem",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Campanha:{" "}
              <span className="font-weight-normal">
                {anuncio?.campanha?.nome}
              </span>
            </span>
            <span
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Anúncio:{" "}
              <span className="font-weight-normal">{anuncio?.nome}</span>
            </span>
            <span
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Agendamento:{" "}
              <span className="font-weight-normal">
                {new Date(data_agendamento).toLocaleString()}
              </span>
            </span>
          </div>
        </CardText>
      </CardBody>
    </Card>
  );
}
