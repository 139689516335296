import styled from 'styled-components';
import { Button } from 'reactstrap'

export const Container = styled.div`
  
`;

export const CircleContainer = styled.div`
    display: flex;
    justify-content: space-between;

    .active{
        border: 3px solid #32325d;
    }
`

export const Circle = styled.input`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 2px solid #ced4da;
    cursor: pointer;
    ::-webkit-color-swatch {
        border: none;
        border-radius: 50%
    }
    :focus {
        border: 2px solid #ccc;
    }

    :hover{
        opacity: 0.6;
    }

    ${props =>
        props.value &&
        `
          background: ${props.value};
        `}
`