import React, { useEffect, useState, useRef, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import { Persona } from '../../../../entities/Marketing'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import { AxiosResponse } from 'axios';
import { bindErrors } from "../../../../utils";
import api from "../../../../services/api";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Image } from './styles'
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Nav,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    Spinner,
    CardFooter,
} from "reactstrap";
import { Dropzone } from '../../../../components/Dropzone';

// import { Container } from './styles';
interface IForm {
    personaId?: number
}

interface IError {
    nome: string,
    comportamento: string,
    caracteristicas: string,
    avatar: string
}

const Form: React.FC<IForm> = ({ personaId }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const { getRootProps, getInputProps, open, acceptedFiles, rejectedFiles } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: 'image/*',
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const newFile = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            if (newFile.length === 0) return
            console.log(newFile[0])
            setAvatar(newFile[0])
        }
    });
    const history = useHistory()

    const [persona, setPersona] = useState({} as Persona)
    const [nome, setNome] = useState('')
    const [caracteristicas, setCaracteristicas] = useState('')
    const [comportamento, setComportamento] = useState('')
    const [alert, setAlert] = useState<any>(null)
    const [avatar, setAvatar] = useState<any>(undefined)

    const [saving, setSaving] = useState(false)
    const [erros, setErros] = useState<IError>({} as IError)

    const notificationAlert = useRef(null)

    useEffect(() => {
        if (personaId) loadPersona()
    }, [personaId])

    useEffect(() => {
        if (persona.id) {
            fillPersona(persona)
        }
    }, [persona])

    async function loadPersona() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/personas/${personaId}`)
            setPersona(await response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar persona')
        }
    }

    function dzCallback(acceptedFiles: any) {
        const newFile = acceptedFiles.map((file: any) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))
        if (newFile.length === 0) return
        console.log(newFile[0])
        setAvatar(newFile[0])
      }

    async function fillPersona(persona: Persona) {
        setNome(persona.nome)
        if (persona.avatar) setAvatar(persona.avatar_url)
        setCaracteristicas(persona.caracteristicas)
        setComportamento(persona.comportamento)
    }

    async function handleDelete() {
        if (window.confirm("Deseja remover está persona?")) {
            await deletePersona()
        }
    }
    async function deletePersona() {
        try {
            await api.delete(`/common/empresas/${empresaSelecionada?.id}/personas/${personaId}`)
            successAlert('Persona removida')
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível remover persona')
        }
    }


    const notify = (type: string, msg: string) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text" style={{ zIndex: 100 }}>
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3
        };
        //@ts-ignore
        if (notificationAlert.current) notificationAlert?.current?.notificationAlert(options);
    };

    async function handleSave() {
        setSaving(true)
        if (persona.id) {
            await update()
        } else {
            await insert()
        }
        setSaving(false)
    }

    async function insert() {
        try {
            const response = await api.post(`common/empresas/${empresaSelecionada?.id}/personas`, bind())
            saveAvatar(response.data?.id);
            successAlert('Persona Salva')
        } catch (error: any) {
            setSaving(false)
            console.log(error)
            notify('danger', 'Não foi possível salvar persona')
            if (error.response) updateErros(error.response)
        }
    }

    async function update() {
        try {
            const response = await api.put(`common/empresas/${empresaSelecionada?.id}/personas/${personaId}`, bind());
            saveAvatar(response.data?.id);
            successAlert('Persona Salva')
        } catch (error: any) {
            setSaving(false)
            console.log(error)
            notify('danger', 'Não foi possível salvar persona')
            if (error.response) updateErros(error.response)
        }
    }

    function bind() {
        const formData = { nome, comportamento, caracteristicas}
        
        return formData
    }

    async function saveAvatar(id: string | number) {
        if (saving || !avatar) return;
    
        setSaving(true);
        try {
          let formData = new FormData();
          formData.append("arquivo", avatar);
          await api.post(
            `/common/empresas/${empresaSelecionada?.id}/personas/${id}/store-avatar`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data;",
              },
            }
          );

          // setAvatar(null);
        } catch (error) {
          notify("danger", "Não foi possível alterar a imagem da persona.");
        } finally {
          setSaving(false);
        }
      }

    const redirectBack = () => {
        history.goBack();
    };

    const successAlert = (msg: string) => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
                title={msg}
                onConfirm={redirectBack}
                confirmBtnBsStyle="success"
                showConfirm={false}
                btnSize=""
            />
        )
        setTimeout(redirectBack, 2000);
    };

    async function updateErros(response: AxiosResponse) {
        const responseErro = await response.data
        const bind = bindErrors(responseErro)
        if ((typeof bind) == 'string') {
            notify('danger', bind)
        } else if ((typeof bind) == 'object') {
            //@ts-ignore
            setErros(bind as Error)
        }
    }

    return (
        <>
            <div className="rna-wrapper" >
                <NotificationAlert ref={notificationAlert} />
            </div>
            {alert}
            <Container className="mt--6" fluid>
                <Card>
                    <CardHeader>
                        <div className='ml-4'>
                            <h1>
                                {
                                    persona.id ? 'Alterar Persona ' : 'Nova Persona'
                                }
                            </h1>
                        </div>
                    </CardHeader>
                    <CardBody
                        outline
                        body
                    >
                        <Row>
                            <Col
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                            >
                                {/* <div {...getRootProps({ className: 'dropzone' })}> */}
                                    <Dropzone accept='image/*' callback={dzCallback}>
                                    <div
                                        title='Alterar foto'
                                        // onClick={open}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {
                                            avatar ?
                                                <a
                                                    style={{
                                                        height: 200,
                                                        width: 200
                                                    }}
                                                    href="#" onClick={e => e.preventDefault()} >
                                                    <Image
                                                        alt="avatar"
                                                        height={200}
                                                        width={200}
                                                        //@ts-ignore
                                                        src={(typeof avatar) == 'object' ? avatar.preview : avatar}
                                                    />
                                                </a>
                                                :
                                                <a
                                                    style={{
                                                        height: 200,
                                                        width: 200
                                                    }}
                                                    className="avatar avatar-xl rounded-circle "
                                                    href="#"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className="far fa-user"></i>
                                                </a>

                                        }
                                        <small className="text-danger">
                                            {erros.avatar || ""}
                                        </small>
                                    </div>
                                    </Dropzone>

                                    {/* <input {...getInputProps()} /> */}
                                {/* </div> */}
                            </Col>
                            <Col>
                                <Row>
                                    <Col lg="12" sm="12" md="12">
                                        <FormGroup>
                                            <label className="form-control-label" >
                                                Nome*
                                        </label>
                                            <Input
                                                className="form-control-alternative"
                                                placeholder="Nome..."
                                                type="text"
                                                name="nome"
                                                value={nome}
                                                onChange={e => setNome(e.target.value)}
                                            />
                                            <small className="text-danger">
                                                {erros.nome || ''}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <label className="form-control-label" >
                                                Descrição*
                                        </label>
                                            <Input
                                                style={{
                                                    minHeight: 200
                                                }}
                                                placeholder='Descrição...'
                                                className="form-control-alternative"
                                                value={caracteristicas}
                                                onChange={e => setCaracteristicas(e.target.value)}
                                                type='textarea'
                                            />
                                            <small className="text-danger">
                                                {erros.caracteristicas || ""}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label className="form-control-label" >
                                        É comum*
                                        </label>
                                    <Input
                                        style={{
                                            minHeight: 120
                                        }}
                                        placeholder='É comum...'
                                        className="form-control-alternative"
                                        value={comportamento}
                                        onChange={e => setComportamento(e.target.value)}
                                        type='textarea'
                                    />
                                    <small className="text-danger">
                                        {erros.comportamento || ""}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter
                        style={{
                            position: "sticky",
                            bottom: 0,
                            border: "2 solid",
                            boxShadow: "0px 0px 2px 2px #f5f5f5",
                            zIndex: 9
                        }}
                    >

                        <Row>
                            <Col>
                                <div className="float-left ">
                                    {
                                        persona.id &&
                                        <Button
                                            color='danger'
                                            onClick={handleDelete}
                                        >
                                            Remover
                                    </Button>
                                    }
                                </div>
                            </Col>
                            <Col>
                                <div className="float-right ">
                                    {
                                        <>
                                            <Button
                                                className="ml-auto"
                                                color="link"
                                                data-dismiss="modal"
                                                type="button"
                                                disabled={saving}
                                                onClick={() => history.goBack()}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                disabled={saving}
                                                onClick={handleSave}>
                                                <Spinner
                                                    hidden={!saving}
                                                    className="mr-2"
                                                    color="light"
                                                    size="sm"
                                                />
                                                Salvar
                                            </Button>
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Container>
        </>
    )
}

export default Form;


