import React, { useContext } from 'react';
import api from "../../../services/api";
import { Acao } from "../../../entities/Mentoring";
import EmpresaContext from "../../../contexts/Empresa";
import List from "./List";



// import { Container } from './styles';
import {
    Row,
    Col,
} from "reactstrap";


type Props = {
    itens: Array<Acao>,
    objetivoId?: number,
    notify(type: string, msg: string): void,
}

const Acoes: React.FC<Props> = ({ itens, notify, objetivoId }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)



    return (

        <List
            loading={false}
            itens={itens}
        />

    );
}

export default Acoes;