import React from "react";
import api from "../../../../../../../services/api";
import { WALoginBtn } from "./styles";

export default function WhatsAppLoginButton({
  onSuccess,
}: {
  onSuccess: () => Promise<void>;
}) {
  function launchWhatsAppSignup() {
    //@ts-ignore
    FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          api
            .post("/meta/access-token", { code })
            .then((res) => onSuccess())
            .catch((err) => console.error(err));
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: process.env.REACT_APP_FB_LOGIN_CONFIG_ID,
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      }
    );
  }
  return (
    <WALoginBtn className="shadow" onClick={launchWhatsAppSignup}>
      Integrar com o WhatsApp
    </WALoginBtn>
  );
}
