//@ts-ignore
import classnames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
import EmpresaContext from "../../../../../contexts/Empresa";
import Colaboradores from "./Colaboradores";
import Equipes from "./Equipes";
import api from "../../../../../services/api";
import { Equipe } from "../../../../../entities/Common";

const ConfiguracoesVendas: React.FC<any> = ({
  hiddeTabs,
  history,
  ...props
}) => {
  const [empresa, setEmpresa] = useState({});
  const [tabActive, setTabActive] = useState("users");
  const [alert, setAlert] = useState(null);
  const [equipes, setEquipes] = useState<Equipe[]>([]);

  const notificationAlert = useRef<any>();
  const { empresaSelecionada } = useContext<any>(EmpresaContext);

  var externaTabActive = props.location.state?.externaTabActive
    ? props.location.state.externaTabActive
    : null;

  useEffect(() => {
    if (externaTabActive && externaTabActive.length > 0) {
      setTabActive(externaTabActive);
    }
  }, [externaTabActive]);

  useEffect(() => {
    if (equipes.length === 0) loadEquipes();
  }, [empresa]);

  async function loadEquipes() {
    try {
      const { data } = await api.get(
        `/common/empresas/${empresaSelecionada.id}/equipes`
      );
      setEquipes(data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar equipes");
    }
  }

  const notify = (type: any, msg: string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text" style={{ zIndex: 100 }}>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    if (notificationAlert.current)
      notificationAlert.current.notificationAlert(options);
  };

  function onEmpresaChange(empresa: any) {
    setEmpresa(empresa ? empresa : {});
  }

  const CustomNavItem = ({ children, disabled, name }: any) => {
    return (
      <>
        <NavItem>
          <NavLink
            href="#"
            role="tab"
            className={classnames("mb-sm-3 mb-md-0 mt-2", {
              active: tabActive === name,
            })}
            onClick={() => setTabActive(name)}
            disabled={disabled}
          >
            {children}
          </NavLink>
        </NavItem>
      </>
    );
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Equipes" parentName="Minha empresa" />
      <div className="container-fluid mt--6">
        <Card>
          <CardHeader>
            <h1>Equipes</h1>
          </CardHeader>
          <CardBody outline body>
            <Row>
              <Col xs={12} lg={12} md={12} sm={12}>
                <div className="nav-wrapper">
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    pills
                    role="tablist"
                  >
                    <CustomNavItem
                      name="users"
                      disabled={!empresaSelecionada.id}
                    >
                      <>
                        <i className="fas fa-user mr-2" />
                        Colaboradores
                      </>
                    </CustomNavItem>
                    <CustomNavItem
                      name="equipes"
                      disabled={!empresaSelecionada.id}
                    >
                      <>
                        <i className="fas fa-user-friends mr-2" />
                        Equipes
                      </>
                    </CustomNavItem>
                  </Nav>
                </div>
                <hr className="mt-0" />
                <div>
                  <TabContent activeTab={tabActive}>
                    <TabPane tabId="users">
                      {empresaSelecionada.id && (
                        <Colaboradores
                          {...props}
                          history={history}
                          equipes={equipes}
                          loadEquipes={loadEquipes}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>

                    <TabPane tabId="equipes">
                      {empresaSelecionada.id && (
                        <Equipes
                          {...props}
                          history={history}
                          onEmpresaChange={onEmpresaChange}
                          equipes={equipes}
                          loadEquipes={loadEquipes}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ConfiguracoesVendas;
