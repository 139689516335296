import React, { CSSProperties, useEffect, useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import NotificationAlert from "react-notification-alert";

interface DropzoneProps {
  maxSize?: number;
  accept: string | undefined;
  callback: <T extends File>(acceptedFiles: T[]) => void;
  //   callback: (
  //     newFile: {
  //       preview: string;
  //     }[]
  //   ) => void;
  children: React.ReactNode;
  multiple?: boolean;
}

const baseStyle: CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  cursor: "pointer",
  borderColor: "#DEE2E6",
  borderStyle: "dashed",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export function Dropzone({
  maxSize = 5 * 1024 * 1024,
  accept,
  callback,
  children,
  multiple = false,
}: DropzoneProps) {
  const notificationAlert = useRef<any>(null);

  const {
    rejectedFiles,
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isDragActive,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxSize,
    accept,
    onDrop: (acceptedFiles) => {
      console.log("Recebidos: ", {acceptedFiles})
      callback(acceptedFiles);
    },
  });

  useEffect(() => {
    if (rejectedFiles[0]?.size > maxSize) {
      notify("danger", "Tamanho do arquivo excede o máximo permitido. (5MB)");
    }
  }, [rejectedFiles]);

  const notify = (type: string, msg: string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const style: CSSProperties = useMemo(() => {
    if (multiple) {
      return {
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      };
    } else {
      return {};
    }
  }, [isDragActive, isDragReject]);

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <div {...getRootProps({ className: "dropzone", style })}>
        <div onClick={open}>{children}</div>
        <input {...getInputProps()} />
      </div>
    </>
  );
}
