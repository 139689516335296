import React, { useState, useEffect } from 'react';

import Select2 from "react-select2-wrapper";
import Filters from '../../../components/Headers/Filters'

import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
} from "reactstrap";

export default ({ title, notify, load, ...props }) => {

    const [nome, setNome] = useState(null)

    //Flag para definir tempo de execução
    const [runLoad, setRunLoad] = useState(props.search ? props.search : true)

    useEffect(() => {
        if (runLoad) {
            search()
            setRunLoad(false)
        }
    }, [runLoad])


    function throwError(text) {
        if (notify) notify('danger', text)
    }


    function search() {
        load({
            nome,
        })
    }

    function hanldeClearFilter() {
        setNome('')
    }

    function handleMultipleSelect(target, state) {
        const array = Array.from(target.selectedOptions)
        state(array.map((item) => item.value))
    }

    return (
        <>
            <Filters
                onSearch={search}
                title={<h1>Personas</h1>}
                onFiltersClead={hanldeClearFilter}
            >
                <Row className="py-4">
                    <Col
                        lg={6}
                        md={12}
                    >
                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                Nome
                                    </label>
                            <Input
                                className="form-control-alternative"
                                placeholder="Nome..."
                                type="text"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Filters>
        </>
    );
}
