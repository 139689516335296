import styled from 'styled-components'

export const Container = styled.div`
`

export const InfoToken = styled.div`
    position: absolute;
    right: 10px;
    cursor: pointer;
`
export const LabelToken = styled.label`
   font-size: 10px;
   overflow: auto;
   max-width: 100%;
   margin-top: 8px;
   word-wrap: break-word;      /* IE 5.5-7 */
    white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
    white-space: pre-wrap;   
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`
