import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../contexts/Empresa";
import Pagination from '../../../components/Pagination'
import moment from 'moment'
import 'moment/locale/pt-br'

import api from "../../../services/api";

import {
    ListGroupItem,
    ListGroup,
} from "reactstrap";

export default ({ publicacaoId, notify }, ...props) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [pageProperties, setPageProperties] = useState({ page: 1, lastPage: 1 })


    useEffect(() => {
        loadHistorico(pageProperties, publicacaoId)
    }, [publicacaoId])

    const formatDate = (date) => {
        return moment(date).format('LLL')
    }


    async function loadHistorico({ page = 1, limit = 5 }, publicacaoId) {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}/historicos`, {
                params: {
                    page,
                    limit
                }
            })
            setItens(response.data.data)
            setPageProperties(response.data)
        } catch (error) {
            notify('danger', 'Não foi possivel carregar comentarios')
        }
    }

    const createHistoricoPreview = (item, key) => (
        <>
            <ListGroupItem
                key={key}
                className="list-group-item-action flex-column align-items-start py-4 px-4"
                href="#"
            >
                <div className="d-flex w-100 justify-content-between">
                    <div>
                        <div className="d-flex w-100 align-items-center">
                            <i class="far fa-user mr-2"></i>
                            <h5 className="mb-1">{item.user.name}</h5>
                        </div>
                    </div>
                    <small>{formatDate(item.created_at)}</small>
                </div>
                <p className="text-sm mb-0">
                    {item.descricao}
                </p>
            </ListGroupItem>
        </>
    )


    return (
        <>
            <ListGroup flush className=' py-4 px-4'>
                {itens.map((item, key) => (
                    <>
                        {createHistoricoPreview(item, key)}
                    </>
                ))}
            </ListGroup>
            <Pagination
                pageProperties={pageProperties}
                load={(pageProperties) => {
                    loadHistorico(pageProperties, publicacaoId)
                }}
            />
        </>
    );
}
