import React from 'react';
import PrintOportunidade from "./Form/print"

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

class Edit extends React.Component {

    dateOptions() {
        return { year: 'numeric', month: 'long', day: 'numeric' };
    }

    state = {
        publicacao: {
            "created_at": new Date().toLocaleDateString('pt-br', this.dateOptions)
        }
    }

    getId() {
        const {
            match: { params }
        } = this.props;
        const { id } = params;
        return id;
    }


    render() {
        return (
            <>
                <SimpleHeader name="Imprimir Oportunidade" parentName="Oportunidades" {...this.props} />
                <PrintOportunidade {...this.props} oportunidadeId={this.getId()} isNew={false} />
            </>
        )
    }
}

export default Edit;