import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import { IContact } from '../../types';
import { Container } from './styles';
import { useWhatsappContext } from '../../../../../contexts/Whatsapp';
import api from '../../../../../services/api';
import IMessage from '../../../../../entities/Meta/message';
import CountdownTimer from '../CountdownTimer';

interface ICtt extends IContact {
  hasNewMsg?: boolean;
  estagio?: {
    cor: string;
    nome: string;
  };
}

function timeAgo(timestamp: Date, locale = 'en') {
  let value;
  const diff = (new Date().getTime() - timestamp.getTime()) / 1000;
  const minutes = Math.floor(diff / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  if (years > 0) {
    value = rtf.format(0 - years, 'year');
  } else if (months > 0) {
    value = rtf.format(0 - months, 'month');
  } else if (days > 0) {
    value = rtf.format(0 - days, 'day');
  } else if (hours > 0) {
    value = rtf.format(0 - hours, 'hour');
  } else if (minutes > 0) {
    value = rtf.format(0 - minutes, 'minute');
  } else {
    value = rtf.format(0 - +diff.toFixed(0), 'second');
  }
  return value;
}

const ChatCard: React.FC<{ data: ICtt }> = ({ data }) => {
  const {
    contacts,
    selectContact,
    selectedContact,
    selectedAccount,
    selecionarOportunidade,
    serverTime,
    colaboradorSelecionadoId,
  } = useWhatsappContext();

  const [lastMsg, setLastMsg] = useState<string>(' ');

  const [date, setDate] = useState<string>(
    timeAgo(new Date(data.dt_last_message), 'pt-br')
  );

  const [IDsDasOportunidades, setIDsDasOportunidades] = useState<number[]>([]);
  const [qtdMensagensNaoLidas, setQtdMensagensNaoLidas] = useState(0);

  useEffect(() => {
    if (!data) return;

    const { conversas } = data;

    if (!conversas) return;

    const oportunidadeIDs = conversas
      .filter((conversa) => conversa.oportunidade_id != null)
      .map((conversa) => conversa.oportunidade_id);

    const uniqueIDs = Array.from(new Set(oportunidadeIDs));
    setIDsDasOportunidades(uniqueIDs);
    setQtdMensagensNaoLidas(data.conversas[0].nao_lidas);
  }, [data]);

  // @ts-ignore
  const qtdOportunidades = IDsDasOportunidades.length;
  const hasOportunidades = qtdOportunidades > 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(timeAgo(new Date(data.dt_last_message), 'pt-br'));
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [data.dt_last_message]);

  function onSelectContact(contato: ICtt) {
    if (contato.wa_id !== selectedContact?.wa_id) {
      selectContact(contato);
    }
  }

  useEffect(() => {
    handleLastMessage();
    setDate(timeAgo(new Date(data.dt_last_message), 'pt-br'));
  }, [data]);

  const handleLastMessage = async () => {
    const { data: mensagem } = await api.get<IMessage>(
      `meta/accounts/${data?.account_id}/contacts/${data?.wa_id}/last-message/${colaboradorSelecionadoId}`
    );

    if (!mensagem.text) {
      setLastMsg(mensagem.type);
      return;
    }
    setLastMsg(mensagem.text);
  };

  const conversaValida = data.conversas?.find(
    (conversa) => conversa.is_sent == false
  );

  const mensagemTemplate = data.conversas[0].is_sent;

  const backgroundStyles =
    data?.wa_id?.concat(data.account_id) ===
    selectedContact?.wa_id?.concat(selectedContact.account_id);

  const hasNewMessages = qtdMensagensNaoLidas > 0;
  return (
    <Container
      onClick={() => onSelectContact(data)}
      isSelected={backgroundStyles}
    >
      <div className="contact--info">
        <div>
          <span className="contact-name">{data.name}</span>
          {(!mensagemTemplate && selectedAccount?.api_meta) && <CountdownTimer
            serverTime={new Date(
              new Date(serverTime).setHours(new Date().getHours() - 3)
            ).toISOString()}
            durationHours={24}
            startTime={
              (conversaValida?.timestamps &&
                new Date(conversaValida?.timestamps).toISOString()) ||
              new Date(data.conversas[0].timestamps).toISOString()
            }
          />}
        </div>

        <small>{date}</small>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <p
          className="contact-message mb-0"
          style={{ fontWeight: hasNewMessages ? 'bold' : 'normal' }}
        >
          {lastMsg} {lastMsg == 'image' && <i className="fas fa-images"></i>}
          {lastMsg == 'video' && <i className="fas fa-video"></i>}
          {lastMsg == 'audio' && <i className="fas fa-headphones"></i>}
        </p>
        {hasNewMessages && (
          <Badge pill color="primary">
            <span className="text-white" style={{ fontSize: '10px' }}>
              {qtdMensagensNaoLidas}
            </span>
          </Badge>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <small className="text-muted d-flex w-100 align-items-center">
          {data?.estagio && (
            <span
              className="text-white"
              style={{
                background: data.estagio.cor,
                padding: '2px 4px',
                fontSize: '.65rem',
                fontWeight: 'bold',
                borderRadius: '2px',
                marginRight: '4px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {data.estagio.nome}
            </span>
          )}
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {hasOportunidades &&
              `Você possui ${qtdOportunidades} oportunidade(s) para este contato.`}
          </span>
          {!hasOportunidades &&
            `Você não possui oportunidades para este contato.`}
        </small>
      </div>
    </Container>
  );
};

export default ChatCard;
