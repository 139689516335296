import React, { useState, useEffect, useContext, useRef } from 'react';
import EmpresaContext from '../../../../../../contexts/Empresa'
//@ts-ignore
import NotificationAlert from "react-notification-alert";
// import { Container } from './styles';
import Status from './StatusObjetivos'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ListGroupItem,
    Row,
    Col,
    ButtonGroup
} from "reactstrap";

import { User } from "../../../../../../entities/Security";


import {
    Objetivo, QuestaoAlvo
} from '../../../../../../entities/Mentoring';

import Usuarios from "../Avatares";
import moment from 'moment';
import { MenuComportamento } from '../../../../../Menus';
import { useHistory } from "react-router-dom";
import api from "../../../../../../services/api";

type Props = {
    questaoAlvo?: QuestaoAlvo | undefined | null
    objetivos: Array<Objetivo> | undefined
    isOpen: boolean
    hide(): void
}

const ModalObjetivos: React.FC<Props> = ({
    questaoAlvo,
    objetivos,
    isOpen,
    hide
}) => {

    const history = useHistory();
    const { empresaSelecionada } = useContext(EmpresaContext)
    const [status, setStatus] = useState('V')
    const [iSLoadObjetivos, setIsLoadObjetivos] = useState(false)
    const [objetivoList, setObjetivoList] = useState([])
    const notificationAlert = useRef<NotificationAlert>(null)

    function notify(type: string, msg: string) {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3
        };
        notificationAlert?.current?.notificationAlert(options);
    };

    function handleEdit(id: number) {
        history.push(`/admin/pdcas/${id}/edit`)
    }

    function handleVincular(id: number) {
        vincularObjetivo(id);
    }

    function handleDesvincular(id: number) {
        desvincularObjetivo(id);
    }

    useEffect(() => {
        if (iSLoadObjetivos) {
            loadObjetivos()
        }

        if (questaoAlvo) {
            setIsLoadObjetivos(true)
        }
    }, [status])

    useEffect(() => {
        if (questaoAlvo) {
            setIsLoadObjetivos(true)
            setStatus('V')
        }
    }, [questaoAlvo])


    useEffect(() => {
        if (objetivos) {
            //@ts-ignore
            setObjetivoList(objetivos);
        }

    }, [objetivos])

    async function loadObjetivos() {
        try {
            const response = await api.get(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos/${questaoAlvo?.plano_estrategico_id}/questoes-alvo/${questaoAlvo?.id}/objetivos`,
                {
                    params: {
                        status: status || 'V'
                    }
                })

            if (response.data) {
                if (questaoAlvo) {
                    setObjetivoList(response.data)
                }
            }

        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível carregar os objetivos desta questão alvo')
        }
    }


    async function vincularObjetivo(id: number) {
        try {
            const response = await api.put(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos/${questaoAlvo?.plano_estrategico_id}/questoes-alvo/${questaoAlvo?.id}/objetivos/${id}/vincular-objetivo`)
            notify('success', 'Objetivo Vinculado com sucesso')
            loadObjetivos();
        } catch (error) {
            console.log(error)
            //@ts-ignore
            if (error.response && error.response.status == 403) {
                //@ts-ignore
                notify("danger", error.response.data.message);
            }
            else {
                notify('danger', 'Não foi possível vincular o objetivo a esta questão alvo')
            }
        }
    }


    async function desvincularObjetivo(id: number) {
        try {
            const response = await api.put(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos/${questaoAlvo?.plano_estrategico_id}/questoes-alvo/${questaoAlvo?.id}/objetivos/${id}/desvincular-objetivo`)
            notify('success', 'Objetivo desvinculado com sucesso')
            loadObjetivos();
        } catch (error) {
            console.log(error)
            //@ts-ignore
            if (error.response && error.response.status == 403) {
                //@ts-ignore
                notify("danger", error.response.data.message);
            }
            else {
                notify('danger', 'Não foi possível desvincular o objetivo a esta questão alvo')
            }
        }
    }



    const acoesFormatter = (id: number) => {

        function createActions() {
            const actions = [
                {
                    label: 'Modificar Vigente',
                    icon: 'far fa-edit',
                    onClick: () => handleEdit(id)
                }
            ]

            if (questaoAlvo && status) {
                if (status == 'N') {
                    actions.push({
                        label: 'Vincular',
                        icon: 'fas fa-link',
                        onClick: () => handleVincular(id)
                    })
                }

                if (status == 'V') {
                    actions.push({
                        label: 'Desvincular',
                        icon: 'fas fa-unlink',
                        onClick: () => handleDesvincular(id)
                    })
                }
            }



            return actions
        }

        return (
            <ButtonGroup size='sm' >
                <MenuComportamento
                    actions={createActions()}
                />
            </ButtonGroup >
        )
    }

    return (

        <>
            <div className="rna-wrapper" style={{zIndex: 9999}}>
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Modal
                className="modal-dialog-centered"
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Objetivos
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => hide()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <div
                        style={{
                            display: (questaoAlvo ? 'flex' : 'none'),
                            flex: 1,
                            justifyContent: 'flex-end',
                            marginTop: -50,
                            marginBottom: 10
                        }}
                    >
                        <Status
                            value={status}
                            onChange={setStatus}
                        />
                    </div>

                    {objetivoList?.map(obj => (
                        <ListGroupItem
                            className="px-0"
                        >
                            <Row className="align-items-center">

                                <Col >
                                    <div className="pl-2">
                                        <h5
                                            title='Descrição do Objetivo'
                                        >
                                            {/* para renderizar o html guardado como descrição */}
                                            {/* @ts-ignore */}
                                            <div dangerouslySetInnerHTML={{ __html: obj.descricao }}>
                                            </div>

                                        </h5>

                                        <h5
                                            title='Data da criação do Objetivo'
                                        >
                                            {/* @ts-ignore */}
                                            Vigência: {moment(obj.created_at).format('LLL')}
                                        </h5>

                                        {/* @ts-ignore */}
                                        {obj?.data_conclusao &&
                                            <h5
                                                title='Data da Conclusão do Objetivo'
                                            >
                                                {/* @ts-ignore */}
                                                Conclusão: {moment(obj.data_conclusao).format('LLL')}
                                            </h5>
                                        }

                                    </div>
                                </Col>
                                <Col className="col-auto">
                                    {/* @ts-ignore */}
                                    {acoesFormatter(obj.id)}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    ))}
                    {(!objetivoList || objetivoList.length <= 0) &&
                        (questaoAlvo ? 'Sem Objetivos Vinculados' : 'Sem Objetivos Cadastrados')}
                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => hide()}
                    >
                        Fechar
                    </Button>
                </div>
            </Modal>
        </>
    );
}

export default ModalObjetivos;