import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import classnames from "classnames";
import Select from "react-select";

import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  Row,
} from "reactstrap";
import { IContact } from "../../types";
import ChatCard from "./ChatCard";
import { useWhatsappContext } from "../../../../../contexts/Whatsapp";
import AuthContext from "../../../../../contexts/Auth";
import EmpresaContext from "../../../../../contexts/Empresa";
import { FilterChip } from "./styles";
import Table from "../Table";
import TemplateModal from "../Modals/Templates";
import NewChatIcon from "../Icons/NewChat";

enum Filtros {
  TODAS = "todas",
  OPORTUNIDADES = "oportunidades",
  QUALIFICACOES = "qualificacoes",
}

enum Classificacao {
  TODAS = "todas",
  NAO_LIDAS = "nao-lidas",
  NOVAS = "novas",
}

const PanelChat: React.FC = () => {
  const {
    contacts,
    colaboradores,
    sendMessage,
    selecionarColaborador,
    selectedAccount,
    usuarioSelecionado,
  } = useWhatsappContext();
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { user } = useContext(AuthContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [listedContacts, setListedContacts] = useState<IContact[]>(contacts);
  const [opportunityFilter, setOpportunityFilter] = useState<Filtros>(
    Filtros.TODAS
  );
  const [readStatusFilter, setReadStatusFilter] = useState<Classificacao>(
    Classificacao.TODAS
  );

  const { hasRole } = useContext(AuthContext);
  const [hasPermission, setHasPermission] = useState(() =>
    hasRole("gestor_comercial")
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [modal, setModal] = useState(false);
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen((isOpen) => !isOpen);

  const topicChat = `whatsapp:empresa_id=${empresaSelecionada?.id}&account_id=${
    selectedAccount?.id
  }&user_id=${user!.id}`;

  const customStyles = {
    container: (provided) => ({ ...provided, flex: 1 }),
    option: (provided) => ({ ...provided, zIndex: 100, fontSize: "13px" }),
    menu: (provided) => ({ ...provided, zIndex: 100 }),
    control: (provided) => ({
      ...provided,
      minHeight: "36px",
      fontSize: "13px",
    }),
  };

  const filteredContacts = listedContacts.filter(
    (contato) =>
      contato.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contato.wa_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function setContactFilters(filter: Filtros = Filtros.TODAS) {
    let updatedFilteredContacts: IContact[] = [];
    setOpportunityFilter(filter);

    switch (filter) {
      case Filtros.TODAS: {
        updatedFilteredContacts = contacts;
        break;
      }

      case Filtros.OPORTUNIDADES: {
        updatedFilteredContacts = contacts.filter((contact) =>
          contact.conversas.some(
            (conversa) => conversa.oportunidade_id !== null
          )
        );
        break;
      }

      case Filtros.QUALIFICACOES: {
        updatedFilteredContacts = contacts.filter((contact) =>
          contact.conversas.every(
            (conversa) => conversa.oportunidade_id === null
          )
        );
        break;
      }

      default: {
        console.warn(`Filtro desconhecido: ${filter}`);
        updatedFilteredContacts = contacts;
        break;
      }
    }

    setListedContacts(updatedFilteredContacts);
  }

  useEffect(() => {
    setContactFilters(opportunityFilter);
  }, [contacts]);

  // FIXME: criar um novo state para rastrear uma lista de não lidos
  const naoLidas = filteredContacts.filter(
    (data) => data.conversas[0].nao_lidas > 0
  ).length;

  // Função para aplicar filtros
  const applyFilters = useCallback(
    (contacts: IContact[]) => {
      return contacts.filter((contact) => {
        let matches = true;

        // Aplicar filtro de oportunidade
        if (opportunityFilter === Filtros.OPORTUNIDADES) {
          matches = matches && contact.conversas[0].oportunidade_id !== null;
        } else if (opportunityFilter === Filtros.QUALIFICACOES) {
          matches = matches && contact.conversas[0].oportunidade_id === null;
        }

        // Aplicar filtro de status de leitura
        if (readStatusFilter === Classificacao.NAO_LIDAS) {
          matches = matches && contact.conversas[0].nao_lidas > 0;
        } else if (readStatusFilter === Classificacao.NOVAS) {
          matches =
            matches && contact.conversas[0].data_primeiro_atendimento == null;
        }

        return matches;
      });
    },
    [opportunityFilter, readStatusFilter]
  );

  // Lista de contatos filtrada
  const newFilteredContacts = useMemo(
    () => applyFilters(filteredContacts),
    [filteredContacts, applyFilters]
  );

  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle size="sm" outline color="secondary">
              <NewChatIcon />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={openModal}>Conversar com cliente</DropdownItem>
              <DropdownItem onClick={toggleModal}>Conversar com novo número</DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Button size="sm" outline color="secondary" onClick={toggleCollapse}>
            <span className="btn-inner--icon">
              <i className="fas fa-filter"></i>
            </span>
          </Button>
        </div>
      </div>
      <Collapse isOpen={isCollapseOpen}>
        <ButtonGroup
          className="btn-group-toggle justify-content-center my-2 w-100"
          size="sm"
          data-toggle="buttons"
        >
          <Button
            className={classnames({
              active: opportunityFilter == Filtros.TODAS,
            })}
            color="secondary"
            onClick={() => setOpportunityFilter(Filtros.TODAS)}
          >
            Todas
          </Button>
          <Button
            className={classnames({
              active: opportunityFilter == Filtros.OPORTUNIDADES,
            })}
            color="secondary"
            onClick={() => setOpportunityFilter(Filtros.OPORTUNIDADES)}
          >
            Oportunidades
          </Button>
          <Button
            className={classnames({
              active: opportunityFilter == Filtros.QUALIFICACOES,
            })}
            color="secondary"
            onClick={() => setOpportunityFilter(Filtros.QUALIFICACOES)}
          >
            Qualificações
          </Button>
        </ButtonGroup>
        {hasPermission && (
          <div className="mb-3">
            <Label className="form-control-label" style={{ fontSize: "13px" }}>
              Conversas do Colaborador
            </Label>
            <Select
              placeholder="Selecione o colaborador"
              styles={customStyles}
              defaultValue={{
                label: usuarioSelecionado?.name,
                value: usuarioSelecionado?.id,
              }}
              onChange={(item) => {
                selecionarColaborador(item!.value!);
                sendMessage(
                  topicChat,
                  JSON.stringify({
                    type: "contatos",
                    body: {
                      empresa_id: empresaSelecionada?.id,
                      account_id: selectedAccount?.id,
                      user_id: item?.value,
                    },
                  })
                );
              }}
              options={colaboradores?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </div>
        )}
      </Collapse>
      <InputGroup className="my-3">
        <InputGroupAddon addonType="prepend" style={{ height: "36px" }}>
          <InputGroupText>
            <span>
              <i className="fas fa-search"></i>
            </span>
          </InputGroupText>
        </InputGroupAddon>
        <Input
          style={{ height: "36px" }}
          value={searchTerm}
          placeholder="Pesquise pelo nome ou número..."
          onChange={(event) => setSearchTerm(event.target.value)}
        />
      </InputGroup>
      <ButtonGroup
        className="btn-group-toggle justify-content-center mb-3 w-100"
        size="sm"
        data-toggle="buttons"
        style={{ gap: ".5rem" }}
      >
        <FilterChip
          className={classnames({
            current: readStatusFilter == Classificacao.TODAS,
          })}
          color="secondary"
          onClick={() => setReadStatusFilter(Classificacao.TODAS)}
        >
          Todas
        </FilterChip>
        <FilterChip
          className={classnames({
            current: readStatusFilter == Classificacao.NAO_LIDAS,
          })}
          color="secondary"
          onClick={() => setReadStatusFilter(Classificacao.NAO_LIDAS)}
        >
          Não lidas{" "}
          {naoLidas > 0 && (
            <Badge
              color="danger"
              className="position-absolute"
              style={{ top: "-10px", right: "2px" }}
            >
              {naoLidas}
            </Badge>
          )}
        </FilterChip>
        <FilterChip
          className={classnames({
            current: readStatusFilter == Classificacao.NOVAS,
          })}
          color="secondary"
          onClick={() => setReadStatusFilter(Classificacao.NOVAS)}
        >
          Novas
        </FilterChip>
      </ButtonGroup>
      {newFilteredContacts.map((data) => (
        <ChatCard
          key={data.wa_id.concat(data.account_id)}
          data={data}
        />
      ))}

      <Modal isOpen={modal} toggle={closeModal} size="md">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Nova conversa
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col lg={12} md={12}>
              <Table />
            </Col>
          </Row>
        </div>
      </Modal>

      <TemplateModal isNewContact isOpen={isModalOpen} toggle={toggleModal} />
    </>
  );
};

export default PanelChat;
