import { Button } from "reactstrap";
import styled from "styled-components";

export const TipoEventoBtn = styled(Button)`
  flex: 1 !important;
  font-size: inherit;
  background: #f6f9fc;
  height: 54px;
  color: #5e72e4;
  min-width: min-content;
  font-weight: 'normal'
`;

export const DivCard = styled.div`
  white-space: initial;
  background-color: white;
  padding: 8px;
  margin: auto auto 10px;
  border-radius: 8px;
  width: 370px;
  max-width: 370px;
  min-height: 200px;
  cursor: pointer;
  transition: 100ms;
  :hover {
    background-color: #e7e7e7;
  }
`;
