import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Badge } from "reactstrap";
import Table from '../../../components/Table'
import { MenuComportamento } from '../../../components/Menus'
import { Nota } from '../../../entities/Mentoring';


// import { Container } from './styles';
interface Props {
  notas: Array<Nota>,
  pageProperties: any,
  onTableChange(type: string, sortProperties: { page: number, sizePerPage: number, sortField: string, sortOrder: string }): Promise<void>,
  notify(type: string, msg: string): void,
  loading: boolean
}

const TableNotas: React.FC<Props> = ({ notas, pageProperties, onTableChange, notify, loading, ...props }) => {
  const history = useHistory()

  const [columns] = useState([
    {
      dataField: 'acoes',
      formatter: (cell: any, row: any) => acoesFormatter(cell, row)
    },
    {
      dataField: "tema",
      text: 'Tema',
    },
    {
      dataField: "tipoNota.nome",
      text: 'Tipo',
    },
    {
      dataField: 'data_anotacao',
      text: 'Data Anotação',
      sort: true,
      formatter: (cell: any, row: any) => dataFormatter(cell, row)
    },
    {
      dataField: 'created_at',
      text: 'Criada em',
      sort: true,
      formatter: (cell: any, row: any) => dataFormatter(cell, row)
    }
  ])


  
  function goToEdit(id: number) {
    history.push(`/admin/notas/${id}/edit`)
  }

  const dataFormatter = (cell: any, row: any) => {
    const dt = new Date(cell)
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    {/* @ts-ignore */ }
    const data = dt.toLocaleDateString('pt-br', options);

    return (
      <>
        {data}
      </>
    )
  }

  const acoesFormatter = (cell: any, row: { id: any; }) => {
    return (
      <MenuComportamento
        actions={[{
          label: 'Alterar',
          icon: 'far fa-edit',
          onClick: () => goToEdit(row.id)
        }]}
      />
    )
  }



  return (
    <>
      <Table
        columns={columns}
        data={notas}
        pageProperties={pageProperties}
        onTableChange={onTableChange}
        notify={undefined}
        rowEvents={undefined} />
    </>
  );
}

export default TableNotas;