import React, { useState, useEffect,useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import ReactQuill from "react-quill";

import {
    Button,
    Row,
    Col,
    FormGroup,
} from "reactstrap";
import { verificarTamanhoConteudo } from '../../../../../utils';

export default ({ notify, close, onSuccess, notaId, anotacao = {}, ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [descricao, setDescricao] = useState('')
    const [erros, setErros] = useState({})


    useEffect(() => {
        if (anotacao.descricao) setDescricao(anotacao.descricao)
    }, [anotacao])


    async function insert() {
        try {
            await api.post(`/mentoring/empresas/${empresaSelecionada?.id}/notas/${notaId}/anotacoes`, {
                descricao,
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível adicionar a anotação')
            if (error.response) updateErros(error.response.data)
        }
    }

    async function update() {
        try {
            await api.put(`/mentoring/empresas/${empresaSelecionada?.id}/notas/${notaId}/anotacoes/${anotacao.id}`, {
                descricao,
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível atualizar a anotação')
            if (error.response) updateErros(error.response.data)
        }
    }

    function updateErros(error) {
        if (error && error instanceof Array) {
            const errors = {}
            for (let e of error) {
                errors[e.field] = e.message
            }
            setErros(errors)
        } else {
            setErros({})
        }
    }

    function handleSave() {
        if (
            verificarTamanhoConteudo(descricao) 
          ) {
            notify(
              "danger",
              "Por favor, reduza o tamanho das imagens ou o comprimento do conteúdo nos editores de texto."
            );
            return;
        }

        if (notaId && anotacao.id) {
            console.log('UPDATE ANOTACAO')
            update()
        } else if (notaId) {
            console.log('INSERT ANOTACAO')
            insert()
        } else {
            if (valid()) {
                onSuccess({ descricao })
            }
        }
    }

    function valid() {
        const error = {}
        if (!descricao && descricao.length <= 10) {
            error['descricao'] = 'Anotação inválida'
        }
        setErros(error)
        return !error.descricao
    }


    return (
        <>
            <Row>
                <Col  >
                    <FormGroup>
                        <ReactQuill
                            value={descricao || ""}
                            theme="snow"
                            modules={{
                                toolbar: [
                                    ["bold", "italic"],
                                    ["link", "blockquote", "code"],
                                    [
                                        {
                                            list: "ordered"
                                        },
                                        {
                                            list: "bullet"
                                        }
                                    ]
                                ]
                            }}
                            onChange={v => setDescricao(v)}
                        />
                        <small class="text-danger">
                            {erros.descricao || ''}
                        </small>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="float-right ">
                        <Button
                            className="ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => close()}
                        >
                            Fechar
                          </Button>
                        <Button
                            color="primary"
                            onClick={handleSave}
                        >
                            Salvar
                          </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
}
