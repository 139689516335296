import React, { useState, useEffect } from 'react';
import api from "../../../../services/api";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Form from './Form'
import {
    Button,
    Row,
    Col,
} from "reactstrap"


export default ({ notify, empresa, ...props }) => {

    const [calibragens, setCalibragens] = useState([])
    const [calibragem, setCalibragem] = useState({})
    const [showModal, setShowModal] = useState(false)

    const [columns, setColumns] = useState([
        {
            dataField: 'acoes',
            formatter: (cell, row) => acoesFormatter(cell, row)
        },
        {
            dataField: 'formato.nome',
            text: 'Formato',
            sort: true,
        },
        {
            dataField: 'dias',
            text: 'Dias',
            sort: true,
        },
    ])

    useEffect(() => {
        if (calibragens.length === 0) loadCalibragens()
    }, [empresa])

    async function loadCalibragens() {
        try {
            const response = await api.get(`/common/empresas/${empresa.id}/calibragens`)
            setCalibragens(response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar calibragens')
        }
    }

    function acoesFormatter(cell, row) {
        return (<>
            <div class="btn-group" role="group" aria-label="Basic example">
                <Button
                    className="btn-sm"
                    color="secondary"
                    onClick={() => {
                        setCalibragem(row)
                        setShowModal(true)
                    }}
                    outline>
                    <i class="far fa-edit"></i>
                </Button>
                <Button
                    className="btn-sm"
                    title='Remover'
                    color="danger"
                    outline
                    onClick={() => handleDelete(row)}
                >
                    <i class="fas fa-trash"></i>
                </Button>
            </div>
        </>)
    }

    async function handleDelete(calibragem) {
        if (window.confirm(`Deseja remover a calibragem do formato ${calibragem.formato.nome}?`)) {
            try {
                await api.delete(`/common/empresas/${empresa.id}/calibragens/${calibragem.id}`);
                notify("success", "Caligragem removida");
                loadCalibragens()
            } catch (error) {
                console.error(error)
                notify("danger", "Não foi possível remover calibragem");
            }
        }
    }


    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center'
                }}
            >
                <Button
                    color="primary"
                    type="button"
                    className="btn-icon btn-3"
                    onClick={() => {
                        setCalibragem({})
                        setShowModal(!showModal)
                    }}
                >
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Novo</span>
                </Button>
            </div>
            <Form
                show={showModal}
                onClose={() => setShowModal(false)}
                notify={notify}
                empresa={empresa}
                calibragemId={calibragem.id}
                formatosSelecionados={calibragens.map(item => item.formato_id)}
                onSuccess={() => {
                    loadCalibragens()
                    setCalibragem({})
                    setShowModal(false)
                }}
            />
            <Row>
                <Col>
                    <ToolkitProvider
                        data={calibragens}
                        columns={columns}
                        keyField={'id'}
                    >
                        {props => (
                            <div className="py-4">
                                <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                </Col>
            </Row>
        </>
    );
}
