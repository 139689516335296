import React from 'react';
import FormProdutos from "./Form"

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

class New extends React.Component {

    dateOptions(){
        return { year: 'numeric', month: 'long', day: 'numeric' };
    }

    state = {
        oportunidade: {
            "created_at": new Date().toLocaleDateString('pt-br', this.dateOptions)
        }
    }


    render(){
        return(
            <>
                <SimpleHeader name="Novo produto" parentName="Produtos" {...this.props} />
                <FormProdutos {...this.props} isNew={true}/>
            </>
        )
    }

}

export default New;
