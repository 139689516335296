import React, { useState, useEffect } from 'react';
import api from "../../../../services/api";

import AddEstagio from './AddEstagio';
import List from './List';
import { Button } from "reactstrap";


export default ({ notify, empresa, ...props }) => {

    const [estagios, setEstagios] = useState([]);
    const [showAddEstagio, setShowAddEstagio] = useState(false);


    useEffect(() => {
        if (empresa) loadEstagios();
    }, [empresa])

    async function loadEstagios() {
        try {
            const response = await api.get(`/common/empresas/${empresa.id}/estagios`);
            const data = await response.data
            setEstagios(data);
        } catch (error) {
            console.error(error);
            notify('danger', 'Não foi possível carregar estagios');
        }
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center'
                }}
            >
                <AddEstagio
                    empresa={empresa}
                    estagios={estagios}
                    onSuccess={loadEstagios}
                    show={showAddEstagio}
                    notify={notify}
                    close={() => setShowAddEstagio(false)}
                />
                <Button
                    color="primary"
                    type="button"
                    className="btn-icon btn-3"
                    onClick={() => setShowAddEstagio(!showAddEstagio)}
                >
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Adicionar</span>
                </Button>
            </div>
            <List
                estagios={estagios}
                empresa={empresa}
                reload={loadEstagios}
                notify={notify}
            />
        </>
    );
}
