import React, { useEffect, useState } from "react";
import "./styles.css";

export function AudioPlayer({ audioSource }) {
  const [src, setSrc] = useState("");

  useEffect(() => {
    if (!audioSource) return;
    
    const removeBase64Prefix = (base64String: string) => {
      const prefixPattern = /^data:audio\/[a-zA-Z0-9.-]+;base64,/;
      return base64String.replace(prefixPattern, "");
    };

    const base64ToBlob = (base64: string) => {
      const byteChars = atob(base64);
      const byteNumbers = new Array(byteChars.length);

      for (let i = 0; i < byteChars.length; i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "audio/mp3" });
    };

    const cleanBase64Audio = removeBase64Prefix(audioSource);

    const audioBlob = base64ToBlob(cleanBase64Audio);

    const audioUrl = URL.createObjectURL(audioBlob);
    setSrc(audioUrl);

    return () => {
      URL.revokeObjectURL(audioUrl);
    };
  }, [audioSource]);

  return (
    <>
      {src && (
        <div className="">
          <audio controls>
            <source id="audioSource" src={src} type="audio/mp3" />
            Seu navegador não suporta o elemento de áudio.
          </audio>
        </div>
      )}
    </>
  );
}
