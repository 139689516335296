import styled from 'styled-components';
import {
    ListGroupItem as NativeListGroupItem
} from "reactstrap";

export const Container = styled.div`
  
`;


export const ListGroupItem = styled(NativeListGroupItem)`
    border-top: 0;
`