import React from 'react';

export default function WhatsAppVerifiedIcon() {
  return (
    <svg
      width="22px"
      height="22px"
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
    >
      <path d="M70.28,1019.02c4.78-17.66,9.21-34.19,13.73-50.7,15.59-56.94,31.32-113.85,46.58-170.88,1.16-4.35,.39-10.25-1.69-14.29-73.16-141.43-76.57-284.22-1.4-424.85,66.24-123.91,170.95-202.13,309.85-225.45,185.53-31.16,338.46,31.03,445.48,185.46,182.39,263.2,32.98,623.97-283.32,685.73-99.66,19.46-195.32,4.39-285.83-41.86-6.16-3.15-11.51-3.58-18.08-1.84-71.99,19.07-144.05,37.89-216.09,56.76-2.54,.66-5.14,1.07-9.23,1.92Zm106.75-105.13c43.45-11.39,84.8-21.92,125.93-33.24,9.55-2.63,16.84-1.33,25.33,3.67,81.41,47.95,168.94,63.86,261.52,45.15,261.42-52.83,383.06-355.62,228.11-572.99-88.91-124.73-214.24-175.43-365.27-150.38-173.46,28.78-298.91,177.64-303.57,353.65-2.02,76.01,18.51,146.05,59.79,209.91,3.72,5.75,4.85,10.77,2.86,17.64-9.17,31.69-17.7,63.57-26.41,95.4-2.68,9.8-5.22,19.64-8.27,31.18Z" />
      <path d="M638.04,795.24c-33.32-.53-60.92-12.19-89.03-22.78-95.02-35.8-162.26-103.2-215.77-186.82-15.38-24.04-30.45-48.17-38.93-75.65-16.95-54.91-8.72-104.22,33.81-145.22,17.14-16.53,37.82-19.24,59.92-12.28,10.37,3.27,17.32,10.53,21.13,20.96,10.62,28.99,21.96,57.73,31.86,86.95,2.05,6.04,1.06,14.81-1.98,20.47-4.76,8.9-12.41,16.26-18.91,24.21-2.68,3.28-5.7,6.29-8.33,9.61-10.68,13.45-10.91,18.43-2.42,33.52,32.12,57.07,77.58,100.16,135.94,129.72,4.04,2.05,8.19,3.95,12.45,5.47,10,3.56,18.81,2.49,26.16-6.29,10.89-12.99,22.44-25.42,33.3-38.43,9.44-11.32,14.33-13.34,28.21-6.43,26.27,13.09,52.16,26.94,78.1,40.69,23.73,12.58,24.44,14.34,19.5,40.61-8.07,42.88-43.62,74.17-91.37,80.35-5.4,.7-10.84,1.06-13.64,1.33Z" />
      <circle fill="#fff" cx="855.94" cy="325.04" r="151.82" />
      <path
        fill="#1eaa61"
        d="M830.2,495.84c-5.93-.9-11.9-1.66-17.8-2.72-46.66-8.44-84.22-31.8-112.76-69.46-18.51-24.42-30.38-51.94-33.85-82.39-7.81-68.69,15-125.32,69.73-168.04,34.05-26.58,73.48-38.28,116.55-36.03,57.2,3,102.98,28.07,137.15,73.95,17.94,24.08,28.98,51.24,32.53,81,6.59,55.32-7.97,104.5-45.9,145.76-31.14,33.88-69.98,53.08-116.07,57.16-.88,.08-1.72,.51-2.58,.77h-27Zm-7.34-153.77c-1.51-1.4-2.44-2.21-3.31-3.08-16.23-16.21-32.42-32.47-48.69-48.64-7.83-7.77-15.71-7.72-23.58-.02-6.1,5.96-12.17,11.95-18.22,17.96-8.08,8.02-8.12,16.7-.1,24.74,14.13,14.15,28.27,28.28,42.42,42.41,13.27,13.25,26.49,26.56,39.85,39.72,7.05,6.94,15.04,6.96,22.25,.32,2.4-2.2,4.67-4.54,6.97-6.84,39.26-39.23,78.54-78.43,117.74-117.72,8.58-8.6,8.35-16.84-.29-25.38-5.73-5.67-11.47-11.33-17.21-16.99-8.44-8.33-16.18-8.38-24.57-.03-21.76,21.64-43.53,43.29-65.23,65-9.41,9.41-18.66,18.98-28.04,28.54Z"
      />
    </svg>
  );
}
