import React, { useState, useRef, useContext } from 'react';
import Axios from 'axios';
import EmpresaContext from '../../../../contexts/Empresa';
import api from '../../../../services/api';

import NotificationAlert from 'react-notification-alert';
import Table from './Table';
import Filters from './Filters';

// reactstrap components
import { Card, CardBody, CardHeader, Container, Row, Col } from 'reactstrap';
// core components
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
let cancelApi = undefined;

export default function PerformanceGrupo({ ...props }) {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [itens, setItens] = useState([]);
  const [pageProperties, setPageProperties] = useState({
    total: '0',
    perPage: 9999,
    page: 1,
    lastPage: 1,
    loading: false,
    sort: new Map([]),
  });
  const [lastSearch, setLastSearch] = useState({});
  const notificationAlert = useRef();

  function notify(type, msg) {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function updatePageProperties(response) {
    const { total, perPage, page, lastPage } = await response.data;
    setPageProperties({
      ...pageProperties,
      total,
      perPage,
      page,
      lastPage,
      loading: false,
    });
  }

  async function loadItens(
    page = 1,
    limit = 9999,
    sortField = 'colaborador_nome',
    sortOrder = 'ASC',
    filters = lastSearch,
    empresaGrupoId
  ) {
    try {
      setLoading(true);
      setPageProperties({
        ...pageProperties,
        loading: true,
      });

      const cancelSource = Axios.CancelToken.source();
      cancelApi = cancelSource;

      const response = await api.get(
        `common/empresas/${empresaGrupoId}/performances`,
        {
          cancelToken: cancelSource.token,
          params: {
            page,
            limit: 9999,
            sortField,
            sortOrder,
            ...filters,
          },
        }
      );

      setItens(response.data);
      await updatePageProperties(response);
    } catch (err) {
      console.error(err);
      notify('danger', 'Houve um problema ao carregar lista.');
    } finally {
      setLoading(false);
    }
  }

  const handleTableChange = async (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    try {
      if (type == 'sort') {
        sortField = sortField.replace('__meta__.', '');
        const value = pageProperties.sort.get(sortField);
        const newOrder = (value || 'asc') == 'desc' ? 'asc' : 'desc';
        const map = pageProperties.sort;
        map.set(sortField, newOrder);
        sortOrder = newOrder;
      }
      if (cancelApi) {
        try {
          cancelApi.cancel('Request canceled');
        } catch (error) {}
      }
      await loadItens(page == 0 ? 1 : page, sizePerPage, sortField, sortOrder);
    } catch (error) {
      notify('danger', 'Houve um problema ao carregar lista.');
    }
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader
        name="Performance dos Colaboradores Grupo"
        parentName="Relatórios"
        {...props}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 100,
                }}
              >
                <Filters
                  {...props}
                  notify={notify}
                  title={<h1>Performance dos Colaboradores Grupo</h1>}
                  data={itens}
                  load={(filters, empresaGrupoId) => {
                    setLastSearch(filters);
                    loadItens(1, 10, 'colaborador_nome', 'asc', filters, empresaGrupoId);
                  }}
                />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Table
                      itens={itens ? itens.data : []}
                      notify={notify}
                      onTableChange={handleTableChange}
                      history={props.history}
                      pageProperties={pageProperties}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
