import React, { useEffect, useState, useRef } from "react";
import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";

import Filters from "./Filters";
import Table from "./Table";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";
// core components
import SimpleHeader from "../../../components/Headers/SimpleHeader";

export default ({ ...props }) => {
  const [loading] = useState(false);
  const [alert] = useState(null);
  const [malasDiretas, setMalasDiretas] = useState([]);

  const [detalhes, setDetalhes] = useState(null);
  const [showDetalhes, setShowDetalhes] = useState(false);

  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: false,
    sort: new Map([]),
  });
  const [lastSearch, setLastSearch] = useState({});
  const notificationAlert = useRef();

  function notify(type, msg) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  useEffect(() => {
    if (detalhes) {
      setShowDetalhes(true);
    }
  }, [detalhes]);

  useEffect(() => {
    if (!showDetalhes) {
      setDetalhes(null);
    }
  }, [showDetalhes]);

  async function updatePageProperties(response) {
    const { total, perPage, page, lastPage } = await response.data;
    await setPageProperties({
      ...pageProperties,
      total,
      perPage,
      page,
      lastPage,
      loading: false,
    });
  }

  async function loadMalasDiretas(
    page = 1,
    limit = 10,
    sortField = "data_agendamento",
    sortOrder = "desc",
    filters = lastSearch
  ) {
    try {
      setPageProperties({
        ...pageProperties,
        loading: true,
      });
      const json = localStorage.getItem("@playnee-empresa");
      const empresaSelecionada = JSON.parse(json);
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/malas-diretas/`,
        {
          params: {
            page,
            limit,
            sortField,
            sortOrder,
            ...filters,
          },
        }
      );
      setMalasDiretas(response.data);
      await updatePageProperties(response);
    } catch (err) {
      console.log(err.response);
      notify("danger", "Houve um problema ao carregar as Malas Diretas.");
    }
  }

  async function loadDetalhes() {
    console.log("LOAD DETALHES INDEX");
    try {
      setDetalhes(null);

      const json = localStorage.getItem("@playnee-empresa");
      const empresaSelecionada = JSON.parse(json);
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/detalhes-credencial-sms/`
      );
      setDetalhes(await response.data);
    } catch (err) {
      console.log(err.response);
      notify("danger", "Houve um problema ao carregar Detalhes.");
    }
  }

  const handleTableChange = async (
    type,
    { page, sizePerPage, sortField, sortOrder, ...props }
  ) => {
    try {
      if (type == "sort") {
        const value = pageProperties.sort.get(sortField);
        const newOrder = (value || "asc") == "desc" ? "asc" : "desc";
        const map = pageProperties.sort;
        map.set(sortField, newOrder);
        sortOrder = newOrder;
      }
      await loadMalasDiretas(
        page == 0 ? 1 : page,
        sizePerPage,
        sortField,
        sortOrder
      );
    } catch (error) {
      console.error(error);
      notify("danger", "Houve um problema ao carregar as Malas Diretas.");
    }
  };

  return (
    <>
      {alert}

      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={showDetalhes}
        toggle={() => setShowDetalhes(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Detalhes
          </h5>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowDetalhes(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <h4 style={{ textAlign: "initial" }}>
            Empresa Credencial: <b>{detalhes?.empresa_telecomunicacao_nome}</b>
          </h4>
          <h4 style={{ textAlign: "initial" }}>
            Status:{" "}
            <b>{detalhes?.credencial_situacao == "A" ? "Ativo" : "Inativo"}</b>
          </h4>
          <h4 style={{ textAlign: "initial" }}>
            Data Início Contrato: <b>{detalhes?.data_contrato}</b>
          </h4>
          <h4 style={{ textAlign: "initial" }}>
            Ciclo: <b>{detalhes?.ciclo}</b>
          </h4>
          <h4 style={{ textAlign: "initial" }}>
            Início Ciclo Atual: <b>{detalhes?.data_inicio_ciclo_atual}</b>
          </h4>
          <h4 style={{ textAlign: "initial" }}>
            Final Ciclo Atual: <b>{detalhes?.data_fim_ciclo_atual}</b>
          </h4>
          <h4 style={{ textAlign: "initial" }}>
            Limite de envios por ciclo: <b>{detalhes?.limite_por_ciclo}</b>
          </h4>
          <h4 style={{ textAlign: "initial" }}>
            Enviados/Agendados Ciclo Atual:{" "}
            <b>{detalhes?.enviados_ou_agendados_ciclo_atual}</b>
          </h4>
          <h4 style={{ textAlign: "initial" }}>
            Saldo Ciclo Atual: <b>{detalhes?.saldo_atual}</b>
          </h4>
          <h4 style={{ textAlign: "initial" }}>
            Bloquear ao exceder:{" "}
            <b>{detalhes?.bloquear_ao_excerder ? "Sim" : "Não"}</b>
          </h4>
          <br></br>
          <h4 style={{ textAlign: "initial" }}>
            <b>{detalhes?.mensagem}</b>
          </h4>
        </div>
      </Modal>

      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader
        name="Listagem de Malas Diretas"
        parentName="Malas Diretas"
        {...props}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 100,
                }}
              >
                <Filters
                  title={<h1>Malas Diretas</h1>}
                  load={(filters) => {
                    setLastSearch(filters);
                    loadMalasDiretas(
                      1,
                      10,
                      "data_agendamento",
                      "desc",
                      filters
                    );
                  }}
                  loadInfo={loadDetalhes}
                  notify={notify}
                />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Table
                      malasDiretas={malasDiretas ? malasDiretas.data : []}
                      notify={notify}
                      onTableChange={handleTableChange}
                      history={props.history}
                      pageProperties={pageProperties}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
