import React, { useEffect, useState } from 'react';

import api from "../../../../../../services/api";
import Select2 from "react-select2-wrapper";
import moment from 'moment';
import ReactDatetime from "react-datetime";

import {
    Button,
    FormGroup,
    Row,
    Col,
    Input,
    Modal,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";
import { ROW_SELECT_DISABLED } from 'react-bootstrap-table-next';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ show, onHidden, empresa, onInserted, credencial, setCredencial, notify }) => {

    const [alias, setAlias] = useState(null)
    const [token, setToken] = useState(null)
    const [canal, setCanal] = useState(null)
    const [dt_inicio_contrato, setDt_inicio_contrato] = useState(null)
    const [readOnly, setReadOnly] = useState(false)
    const [qtd_periodo_ciclo, setQtd_periodo_ciclo] = useState(1)
    const [tipo_periodo_ciclo, setTipo_periodo_ciclo] = useState('mes')
    const [qtd_limite_ciclo, setQtd_limite_ciclo] = useState(1000)
    const [flag_bloquear_envio, setFlag_bloquear_envio] = useState(true);
    const [empresa_telecomunicacao_id, setEmpresa_telecomunicacao_id] = useState(null)
    const [situacao, setSituacao] = useState(null)
    const [erros, setErros] = useState({})
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        setErros({})
        // setAlias(null)
        // setToken(null)
        // setCanal(null)
        if (credencial && credencial.id) {
            // console.log('Show Update Credencial')
            setAlias(credencial.alias)
            setToken(credencial.token)
            setCanal(credencial.canal)
            setDt_inicio_contrato(moment(credencial.dt_inicio_contrato))
            setQtd_periodo_ciclo(credencial.qtd_periodo_ciclo)
            setTipo_periodo_ciclo(credencial.tipo_periodo_ciclo)
            setQtd_limite_ciclo(credencial.qtd_limite_ciclo)
            setFlag_bloquear_envio(credencial.flag_bloquear_envio)
            setEmpresa_telecomunicacao_id(credencial.empresa_telecomunicacao_id)
            setSituacao(credencial.situacao)
        } else {
            // console.log('Show Cadastro Credencial')
            setAlias(null)
            setToken(null)
            setCanal(null)
            setDt_inicio_contrato(null)
            setQtd_periodo_ciclo(1)
            setTipo_periodo_ciclo('mes')
            setQtd_limite_ciclo(1000)
            setFlag_bloquear_envio(true)
            setEmpresa_telecomunicacao_id(null)
            setSituacao('A')
        }
    }, [show])

    async function insert() {
        setSaving(true)
        try {
            setErros({})
            const response = await api.post(`common/empresas/${empresa.id}/credenciais-mensagens`, {
                alias,
                token,
                canal,
                dt_inicio_contrato,
                qtd_periodo_ciclo,
                tipo_periodo_ciclo,
                qtd_limite_ciclo,
                flag_bloquear_envio,
                empresa_telecomunicacao_id,
                situacao
            })
            onInserted(response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível salvar credenciais')
            if (error.response) updateErros(error.response.data)
        } finally {
            setSaving(false)
        }
    }

    async function update() {
        setSaving(true)
        try {
            setErros({})
            const response = await api.put(`common/empresas/${empresa.id}/credenciais-mensagens/${credencial.id}`, {
                alias,
                token,
                canal,
                dt_inicio_contrato,
                qtd_periodo_ciclo,
                tipo_periodo_ciclo,
                qtd_limite_ciclo,
                flag_bloquear_envio,
                empresa_telecomunicacao_id,
                situacao
            })
            console.log(response)
            onInserted(response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível atualizar credencial')
            if (error.response) updateErros(error.response.data)
        } finally {
            setSaving(false)
        }
    }

    function updateErros(error) {
        try {
            if (error) {
                const errors = {}
                for (let e of error) {
                    errors[e.field] = e.message
                }
                setErros(errors)
            } else {
                setErros({})
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function handleConfirm() {
        if (credencial && credencial.id) {
            await update()
        } else {
            await insert()
        }

    }

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={show}
                toggle={onHidden}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Nova Credencial Mensagem
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidden}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Parceira de Telecomunicação*
                                </label>
                                <Select2
                                    className="form-control"
                                    options={{
                                        placeholder: "Empresa de Telecomunicação..."
                                    }}
                                    value={empresa_telecomunicacao_id}
                                    onChange={({ target }) => setEmpresa_telecomunicacao_id(target.value)}
                                    data={[
                                        { id: 1, text: "Zenvia" },
                                        { id: 2, text: "BestVoice" },
                                    ]}
                                />
                                <small style={{ color: 'red' }}>
                                    {erros.empresa_telecomunicacao_id || ''}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Palavra chave*
                                </label>
                                <Input
                                    value={alias}
                                    onChange={({ target }) => setAlias(target.value)}
                                    placeholder='Palavra chave...'
                                />
                                <small style={{ color: 'red' }}>
                                    {erros.alias || ''}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Token*
                                </label>
                                <Input
                                    value={token}
                                    onChange={({ target }) => setToken(target.value)}
                                    placeholder='Token...'
                                />
                                <small style={{ color: 'red' }}>
                                    {erros.token || ''}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Canal*
                                </label>
                                <Select2
                                    className="form-control"
                                    options={{
                                        placeholder: "Canal..."
                                    }}
                                    value={canal}
                                    onChange={({ target }) => setCanal(target.value)}
                                    data={[
                                        { id: "sms", text: "SMS" },
                                        { id: "whatsapp", text: "Whatsapp" },
                                    ]}
                                />
                                <small style={{ color: 'red' }}>
                                    {erros.canal || ''}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <legend class="w-auto" style={{ margin: 0 }}>
                                    <label
                                        className="form-control-label"
                                    >
                                        Início Contrato*
                                    </label>
                                </legend>
                                <InputGroup className="input-group" >
                                    <ReactDatetime
                                        closeOnSelect={true}
                                        locale={'pt-br'}
                                        value={
                                            dt_inicio_contrato
                                                ? moment(dt_inicio_contrato)
                                                : null
                                        }
                                        inputProps={{
                                            placeholder: "Início Contrato...",
                                            name: "dt_inicio_contrato",
                                            id: "dt_inicio_contrato",
                                            autocomplete: "off",
                                            disabled: readOnly
                                        }}
                                        dateFormat="DD/MM/YYYY"
                                        timeFormat='HH:mm'
                                        onChange={e => setDt_inicio_contrato(e)}
                                    />
                                    {!readOnly &&
                                        <InputGroupAddon addonType="append"  >
                                            <InputGroupText
                                                style={{
                                                    padding: '10px'
                                                }}>
                                                <i class="far fa-calendar-alt" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    }
                                </InputGroup>
                                <small class="text-danger">
                                    {erros.dt_inicio_contrato || ""}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Periodo Ciclo*
                                </label>
                                <Input
                                    className="form-control"
                                    placeholder="Quantidade de Periodos que representa um Ciclo..."
                                    type="number"
                                    value={qtd_periodo_ciclo}
                                    onChange={(e) => setQtd_periodo_ciclo(e.target.value)}
                                />
                                <small class="text-danger">
                                    {erros.qtd_periodo_ciclo || ''}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Medida Periodo*
                                </label>
                                <Select2
                                    defaultValue="A"
                                    onSelect={(e) => setTipo_periodo_ciclo(e.target.value)}
                                    options={{
                                        placeholder: "Selecione o tipo de período..."
                                    }}
                                    value={tipo_periodo_ciclo}
                                    data={
                                        [
                                            {
                                                id: 'dia',
                                                text: 'Dia'
                                            }, {
                                                id: 'mes',
                                                text: 'Mês'
                                            }, {
                                                id: 'semestre',
                                                text: 'Semestre'
                                            }, {
                                                id: 'ano',
                                                text: 'Ano'
                                            }
                                        ]
                                    }
                                />
                                <small class="text-danger">
                                    {erros.tipo_periodo_ciclo ? erros.tipo_periodo_ciclo : ''}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Limite de SMS's Ciclo*
                                </label>
                                <Input
                                    className="form-control"
                                    placeholder="Limite de SMS's por Ciclo..."
                                    type="number"
                                    value={qtd_limite_ciclo}
                                    onChange={(e) => setQtd_limite_ciclo(e.target.value)}
                                />
                                <small class="text-danger">
                                    {erros.qtd_limite_ciclo || ''}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Bloquear ao Exceder?*
                                </label>
                                <div>
                                    <label className="custom-toggle">
                                        <input
                                            id='flagBloquearEnvio'
                                            type="checkbox"
                                            defaultChecked={flag_bloquear_envio}
                                            value={flag_bloquear_envio}
                                            onChange={() => setFlag_bloquear_envio(!flag_bloquear_envio)}
                                        />
                                        <span class="custom-toggle-slider rounded-circle" data-label-off="Não" data-label-on="Sim"></span>
                                    </label>
                                </div>

                                <small class="text-danger">
                                    {erros.flag_bloquear_envio ? erros.flag_bloquear_envio : ''}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="12" lg="12">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Situação*
                                </label>
                                <Select2
                                    defaultValue="A"
                                    onSelect={(e) => setSituacao(e.target.value)}
                                    options={{
                                        placeholder: "Selecione uma situação..."
                                    }}
                                    value={situacao}
                                    data={
                                        [
                                            {
                                                id: 'A',
                                                text: 'Ativo'
                                            }, {
                                                id: 'I',
                                                text: 'Inativo'
                                            }
                                        ]
                                    }
                                />
                                <small class="text-danger">
                                    {erros.situacao ? erros.situacao : ''}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>


                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidden}
                    >
                        Voltar
                    </Button>
                    <Button
                        disabled={saving}
                        color="primary"
                        onClick={handleConfirm}
                        type="button">
                        {
                            saving &&
                            <Spinner
                                className='mr-2'
                                color='secondary'
                                size='sm'
                            />
                        }
                        Salvar
                    </Button>
                </div>
            </Modal>
        </>
    );
}
