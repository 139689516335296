import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom'
import Form from "./Form"

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

export default ({ ...props }) => {

    const match = useRouteMatch()
    const [id, setId] = useState<number | undefined>(undefined)

    useEffect(() => {
        const { params } = match
        //@ts-ignore
        if (params && params?.id != id) {
            //@ts-ignore
            setId(params?.id)
        }
    }, [])

    return (
        <>
            <SimpleHeader
                name="Alterar Persona"
                parentName="Personas"
                {...props} />
            <Form
                {...props}
                personaId={id}
            />
        </>
    )


}
