import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import EmpresaContext from "../../../contexts/Empresa";
import api from "../../../services/api";
import Filters from "./Filters";
import Table from "./Table";
//@ts-ignore
import NotificationAlert from "react-notification-alert";


const Vendas: React.FC<{ oportunidade?: any; callback: any }> = ({
  oportunidade,
  callback,
}) => {
  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: false,
  });
  const [lastSearch, setLastSearch] = useState({});
  const [propostas, setPropostas] = useState();

  const { empresaSelecionada } = useContext(EmpresaContext);
  const notificationAlert = useRef();

  function notify(type: any, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert && notificationAlert.current)
      // @ts-ignore
      notificationAlert.current.notificationAlert(options);
  }

  async function updatePageProperties(response: any) {
    const { total, perPage, page, lastPage } = await response.data;
    await setPageProperties({
      total,
      perPage,
      page,
      lastPage,
      loading: false,
    });
  }

  async function carregarPropostas(
    page = 1,
    limit = 10,
    sortField = "created_at",
    sortOrder = "desc",
    filters = lastSearch
  ) {
    try {
      setPageProperties({
        ...pageProperties,
        loading: true,
      });
      const response = await api.get(`marketing/vendas-externas/`, {
        params: {
          page,
          limit,
          sortField,
          sortOrder,
          ...filters,
        },
      });
      console.log(response.data.data);
      setPropostas(response.data.data);
      await updatePageProperties(response);
    } catch (err) {
      // @ts-ignore
      console.log(err.response);
      notify("danger", "Houve um problema ao carregar as propostas.");
    }
  }

  const handleTableChange = async (
    type: string,
    {
      page,
      sizePerPage,
      sortField,
      sortOrder,
    }: {
      page: number;
      sizePerPage: number;
      sortField: string;
      sortOrder: "asc" | "desc";
    }
  ) => {
    try {
      await carregarPropostas(
        page == 0 ? 1 : page,
        sizePerPage,
        sortField,
        sortOrder
      );
    } catch (error) {
      notify("danger", "Houve um problema ao carregar as clientes.");
    }
  };

  useEffect(() => {
    carregarPropostas();
  }, []);

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Row>
        <Col>
          <Card style={{ boxShadow: "none", marginBottom: 0 }}>
            <CardHeader
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <Filters
                title={<h1>Vendas</h1>}
                notify={notify}
                data={propostas}
                load={(filters: any) => {
                  setLastSearch(filters);
                  carregarPropostas(1, 10, "nome", "desc", filters);
                }}
              />
            </CardHeader>
            <CardBody style={{ padding: 0 }}>
              <Row>
                <Col>
                  <Table
                    data={propostas || []}
                    pageProperties={pageProperties}
                    onTableChange={handleTableChange}
                    oportunidade={oportunidade}
                    callback={callback}
                    notify={notify}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Vendas;
