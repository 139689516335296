import React, { useState } from "react";
import { Button, Col, FormGroup, Input } from "reactstrap";
import api from "../../../../../../../services/api";
import { ParcelaProps } from ".";

interface PlanoItemProps {
  plano: ParcelaProps;
//   reload: () => Promise<void>;
  notify: (type: string, message: string) => void;
//   removeLocally: (contatoToUpdate: PlanoPagamentoProps) => void;
//   updateLocally: (contatoToUpdate: PlanoPagamentoProps) => void;
}

export function PlanoItem({
  plano,
//   reload,
  notify,
//   removeLocally,
//   updateLocally,
}: PlanoItemProps) {
  const [nome, setNome] = useState(plano?.nome);
  const [descricao, setDescricao] = useState(plano?.descricao);
  const [quantidade, setQuantidade] = useState(plano?.quantidade);
  const [valor, setValor] = useState(plano?.valor);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function valid() {
    if (
      nome.length < 2 ||
      quantidade > 0 ||
      valor > 0 
    ) {
      notify("danger", "Verifique todos os campos do plano.");
      return false;
    }
    return true;
  }
//   async function handleUpdate() {
//     const isValid = valid();
//     if (!isValid) return;
//     try {
//       setIsLoading(true);
//       if (!contato.id) {
//         notify("success", "Contato atualizado com sucesso!");
//         updateLocally(contato);
//         setIsEditing(false);
//         return;
//       }
//       await api.put(`/common/clientes/contatos/${contato.id}`, {
//         cargo,
//         nome,
//         ddd: +ddd,
//         numero: +numero,
//       });
//       reload();
//       setIsEditing(false);
//       notify("success", "Contato atualizado com sucesso!");
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setIsLoading(false);
//     }
//   }

//   async function handleRemove() {
//     try {
//       setIsLoading(true);
//       if (!contato.id) {
//         notify("success", "Contato removido com sucesso!");
//         removeLocally(contato);
//         return;
//       }
//       await api.delete(`/common/clientes/contatos/${contato.id}`);
//       reload();
//       notify("success", "Contato removido com sucesso!");
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setIsLoading(false);
//     }
//   }

  return (
    <div className="w-100 d-flex">
      <Col lg="3" className="p-1">
        <FormGroup>
          <Input
            disabled={!isEditing}
            placeholder="Nome"
            className="form-control"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </FormGroup>
      </Col>
      <Col lg="2" className="p-1">
        <FormGroup>
          <Input
            disabled={!isEditing}
            type="number"
            placeholder="Quantidade"
            className="form-control"
            value={quantidade}
            onChange={(e) => setQuantidade(+e.target.value)}
          />
        </FormGroup>
      </Col>
      <Col lg="2" className="p-1">
        <FormGroup>
        <Input
            disabled={!isEditing}
            type="number"
            placeholder="Valor"
            className="form-control"
            value={valor}
            onChange={(e) => setValor(+e.target.value)}
          />
        </FormGroup>
      </Col>
      <Col className="p-1">
        <FormGroup>
        <Input
            disabled={!isEditing}
            placeholder="Descricao"
            className="form-control"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
        </FormGroup>
      </Col>
      {/* <Col className="p-1">
        <FormGroup className="d-flex align-items-center">
          {isEditing && (
            <Button
              type="button"
              outline
              color="primary"
              size="sm"
              onClick={handleUpdate}
              disabled={isLoading}
            >
              Salvar
            </Button>
          )}
          <Button
            className="btn-sm"
            color="primary"
            onClick={() => setIsEditing(!isEditing)}
          >
            <i
              className={`fas ${isEditing ? "fa-times-circle" : "fa-edit"}`}
            ></i>
          </Button>
          <Button
            className="btn-sm"
            color="danger"
            onClick={handleRemove}
            disabled={isLoading}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </FormGroup>
      </Col> */}
    </div>
  );
}
