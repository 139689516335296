import React, { useEffect } from 'react';
import { Nota } from '../../../../../entities/Mentoring';
import { User } from '../../../../../entities/Security';

import FieldParticipantes from "./FieldParticipantes";
import List from "../../../../../components/lists/Users";
import {
    Row,
    Col
} from "reactstrap";

type Props = {
    nota: Nota,
    users: Array<User>
    addUser(user: User): void,
    removeUser(user: User): void
}

const Participantes: React.FC<Props> = ({ nota, users, addUser, removeUser }) => {

    return (
        <Row>
            <Col>
                <FieldParticipantes
                    nota={nota}
                    onSuccessInsert={addUser}
                    onSuccessDelete={removeUser}
                    usersAdded={users}
                />
                <List
                    itens={users}
                    handleDelete={removeUser}
                />
            </Col>
        </Row>
    )
}

export default Participantes;