import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  Spinner,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";

import { TipoAgendamento } from ".";
import api from "../../../../../services/api";

interface TipoTarefaModalProps {
  isOpen: boolean;
  tipoTarefaSelecionada?: TipoAgendamento | null;
  onClose: () => void;
  onReload: () => void;
}

export default function TipoTarefaModal({
  isOpen,
  onClose,
  onReload,
  tipoTarefaSelecionada,
}: TipoTarefaModalProps) {
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [situacao, setSituacao] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [cor, setCor] = useState("#12ccf0");
  const [isPublic, setIsPublic] = useState(false);
  const [alert, setAlert] = useState<any>(null);
  const notificationAlert = useRef<any>();

  function limparCampos() {
    setNome("");
    setDescricao("");
    setSituacao(true);
    setCor("#12ccf0");
    setIsPublic(false);
  }
  useEffect(() => {
    if (tipoTarefaSelecionada) {
      const { nome, descricao, cor, situacao, is_public } =
        tipoTarefaSelecionada;

      setNome(nome);
      setDescricao(descricao);
      setSituacao(situacao);
      setCor(cor);
      setIsPublic(is_public);
    }

    return () => {
      limparCampos();
    };
  }, [tipoTarefaSelecionada]);

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function handleSave() {
    if (!nome || !descricao) return;
    try {
      setIsLoading(true);
      const dados = {
        nome: nome,
        descricao: descricao,
        situacao: situacao,
        cor: cor,
        is_public: isPublic,
      };
      if (tipoTarefaSelecionada) {
        await api.patch(
          `marketing/tipos-agendamentos/${tipoTarefaSelecionada.id}`,
          dados
        );
      } else {
        await api.post(`marketing/tipos-agendamentos`, dados);
      }

      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block" }}
          title="Sucesso!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Tipo de tarefa {tipoTarefaSelecionada ? "atualizada" : "adicionada"}{" "}
          com sucesso!
        </ReactBSAlert>
      );
      onReload();
      limparCampos();
    } catch (error) {
      console.error(error);
      notify(
        "danger",
        `Ocorreu um erro ao tentar ${
          tipoTarefaSelecionada ? "atualizar" : "salvar"
        } o tipo de tarefa.`
      );
    } finally {
      setIsLoading(false);
      onClose();
    }
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>

      <Modal isOpen={isOpen} toggle={onClose} className="modal-dialog-centered">
        <ModalHeader>
          {tipoTarefaSelecionada ? "Editar" : "Novo"} Tipo de Tarefa
        </ModalHeader>

        <div className="modal-body">
          <form className="new-event--form">
            <FormGroup>
              <label className="form-control-label">Nome</label>
              <Input
                className="form-control new-event--title"
                placeholder="Nome do novo tipo de tarefa"
                type="text"
                onChange={(e) => setNome(e.target.value)}
                value={nome}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Descrição</label>
              <Input
                className="form-control edit-event--description textarea-autosize"
                placeholder="Descreva aqui o tipo de tarefa"
                type="textarea"
                value={descricao}
                style={{ height: "25vh" }}
                onChange={(e) => setDescricao(e.target.value)}
              />
              <i className="form-group--bar" />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Situação</label>
              <Input
                className="form-control new-event--title"
                placeholder="Situação"
                type="select"
                value={situacao ? "ativo" : "inativo"}
                onChange={(e) =>
                  setSituacao(e.target.value == "ativo" ? true : false)
                }
              >
                <option value="ativo">Ativo</option>
                <option value="inativo">Inativo</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Cor</label>
              <Input
                className="form-control new-event--title"
                placeholder="Cor"
                type="color"
                value={cor}
                onChange={(e) => setCor(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Visibilidade</label>
              <Input
                className="form-control new-event--title"
                placeholder="Visibilidade"
                type="select"
                value={isPublic ? "publico" : "privado"}
                onChange={(e) =>
                  setIsPublic(e.target.value == "publico" ? true : false)
                }
              >
                <option value="privado">Privado</option>
                <option value="publico">Público</option>
              </Input>
            </FormGroup>

            <input className="edit-event--id" type="hidden" />
          </form>
        </div>

        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={onClose}
          >
            Cancelar
          </Button>

          <Button
            className="new-event--add d-flex align-items-center"
            color="primary"
            type="button"
            disabled={isLoading}
            onClick={handleSave}
          >
            <Spinner
              hidden={!isLoading}
              className="mr-2"
              color="light"
              size="sm"
            />
            Salvar
          </Button>
        </div>
      </Modal>
    </>
  );
}
