import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import { createColumns } from "./Columns";
import ParcialColaboradores from "./chart/ParcialColaboradores";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";

import {
  Spinner,
  Modal,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import "./styles.css";

export default ({ itens, pageProperties, notify, lastSearch, ...props }) => {
  const [funcionarioMovimentacao, setFuncionarioMovimentacao] = useState(null);
  const [showModalMovimentacao, setShowModalMovimentacao] = useState(false);

  const { ExportCSVButton } = CSVExport;

  function handleCloseModalMovimentacao() {
    setShowModalMovimentacao(false);
    setFuncionarioMovimentacao(null);
  }

  return (
    <>
      {/*Modal Movimentacao*/}
      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={showModalMovimentacao}
        toggle={() => handleCloseModalMovimentacao()}
      >
        <div className="modal-header" style={{ paddingBottom: 0 }}>
          <h5 className="modal-title" id="exampleModalLabel">
            Movimentação do Funil
            <br />
            <small className="text-muted" title={`Id e Nome do Funcionário`}>
              {funcionarioMovimentacao
                ? `#${funcionarioMovimentacao.colaborador_id} | ${funcionarioMovimentacao.colaborador_nome}`
                : ""}
            </small>
          </h5>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModalMovimentacao}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingTop: 5 }}>
          <Row className="mt-4">
            <Col lg="12" md="12" sm="12" xs="12">
              <ParcialColaboradores
                notify={notify}
                mes={1}
                ano={2022}
                equipe={null}
                colaborador={null}
                unidade={null}
                periodo={null}
                tipoProduto={null}
                lastSearch={lastSearch}
                funcionarioMovimentacao={funcionarioMovimentacao}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      {itens && pageProperties ? (
        // <Table
        //     columns={createColumns({
        //         lastSearch: lastSearch,
        //         setFuncionarioMovimentacao: setFuncionarioMovimentacao,
        //         setShowModalMovimentacao: setShowModalMovimentacao
        //     })}
        //     data={itens}
        //     pageProperties={pageProperties}
        //     onTableChange={props.onTableChange}
        // />
        <ToolkitProvider
          data={itens}
          columns={createColumns({
            lastSearch: lastSearch,
            setFuncionarioMovimentacao: setFuncionarioMovimentacao,
            setShowModalMovimentacao: setShowModalMovimentacao,
          })}
          keyField={"id"}
          exportCSV={{
            fileName: "custom.xls",
            separator: ";",
            noAutoBOM: false,
            blobType: "text/xls;charset=ansi",
          }}
        >
          {(props) => (
            <div className="py-4">
              <UncontrolledDropdown
                group
                size="sm"
                direction="down"
                style={{ position: "absolute", top: -15, right: 30 }}
              >
                <DropdownToggle color="link">
                  <i className="fas fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="#">
                    <ExportCSVButton
                      {...props.csvProps}
                      style={{
                        backgroundColor: "transparent",
                        padding: 0,
                        border: 0,
                        width: "100%",
                        color: "#212529",
                        textAlign: "start",
                      }}
                    >
                      <i className="fas fa-file-export"></i>
                      <span className="ml-1">Exportar</span>
                    </ExportCSVButton>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
                sort={{
                  sortFunc: (a, b, order) => {
                    if (order === "asc") return a - b;
                    else return b - a;
                  },
                }}
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Spinner color="primary" />
        </div>
      )}
    </>
  );
};
