import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../../../contexts/Empresa";
import api from "../../../../../services/api";
// import { chartPie } from "../../../../../variables/charts";
import { Pie } from "react-chartjs-2";
import { SpinnerContainer } from '../styles'
import {
    Spinner,
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";

var colors = [
    "#12263F",
    "#5e72e4",
    "#adb5bd",
    "#11cdef",
    "#8898aa",
    "#525f7f",
    "#32325d",
    "#212529",
    "#172b4d",
    "#f4f5f7",
    "#2dce89",
    "#f5365c",
    "#fb6340",
]

function Campanhas({ notify, mes, ano, equipe, colaborador, setCampanha, campanha }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [loading, setLoading] = useState(false)
    const [showLegend, setShowLegend] = useState(true)

    useEffect(() => {
        load()
    }, [mes, ano, equipe, colaborador])

    useEffect(() => {
        //validação utilizada pra habilitar ou desabilitar legendas
        //de acordo com a quantidade de campanhas, para não bugar o gráfico quando houver muitas legendas
        if (itens && itens.length > 10) {
            setShowLegend(false)
        } else {
            setShowLegend(true)
        }

    }, [itens])

    async function load() {
        setLoading(true)
        try {
            const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/campanhas`, {
                params: {
                    mes,
                    ano,
                    equipe,
                    colaborador
                }
            })
            setItens(await response.data)
        } catch (error) {
            console.error(error)
            notify('Não foi possível carregar grafico de campanhas')
        }
        setLoading(false)
    }

    function handleSelecionarCampanha(e) {
        if (!e || e.length === 0) {
            setCampanha(null)
            return
        }

        const chartIndex = e[0]._index
        const campanhaSelecionada = itens[chartIndex]
        campanhaSelecionada.id === campanha ? setCampanha(undefined) : setCampanha(campanhaSelecionada.id)
    }

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function createChartData() {

        const labels = []
        const data = []
        const backgroundColor = []
        const borderColor = []

        itens.map((item, index) => {
            labels.push(item.nome)
            data.push(parseFloat(item.total_oportunidades))
            let background = index < 11 ? colors[index] : getRandomColor()
            backgroundColor.push(background)
            borderColor.push(campanha === item.id ? '#adb5bd' : '#fff')
        })

        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor,
                    borderColor,
                    label: 'Campanhas',
                },
            ],
        }
    }

    const chartPieOptions = {
        responsive: true,
        legend: {
            //position: "top"
            position: "bottom",
            display: showLegend
        },
        tooltips: {
            enabled: true,
            mode: 'point'
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    }


    return (
        <Card>
            <CardHeader>
                <h5 class="h3 mb-0">Campanhas</h5>
                <h6 class="surtitle">Oportunidades por campanha</h6>
            </CardHeader>
            <CardBody>
                <div className="">
                    {
                        loading ?
                            <SpinnerContainer>
                                <Spinner
                                    color='primary'
                                    size='lg'
                                    style={{
                                        alignSelf: 'center'
                                    }}
                                />
                            </SpinnerContainer>
                            :
                            <div className='chart'>
                                <Pie
                                    onElementsClick={handleSelecionarCampanha}
                                    data={createChartData()}
                                    //options={chartPie.options}
                                    options={chartPieOptions}
                                    className="chart-canvas"
                                />
                            </div>
                    }
                </div>


            </CardBody>
        </Card >
    )
}
function areEqual(prevProps, nextProps) {
    if (
        prevProps.ano !== nextProps.ano ||
        prevProps.mes !== nextProps.mes ||
        prevProps.campanha !== nextProps.campanha ||
        prevProps.equipe !== nextProps.equipe ||
        prevProps.colaborador !== nextProps.colaborador
    ) {
        return false
    }
    return true
}

export default React.memo(Campanhas, areEqual)