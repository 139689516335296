import React from 'react';
import { Button, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import Form from './components/Form';

// import { Container } from './styles';
interface Props {
    isOpen: boolean
    anuncioId: number;
    clienteIndicadorId: number,
    data: any,
    hideModal(): void
}
const OportunidadeSimplificada: React.FC<Props> = ({ isOpen, anuncioId, clienteIndicadorId, data, hideModal }) => {

    return <>
        <Modal
            centered
            size="lg"
            toggle={function noRefCheck() { }}
            isOpen={isOpen}
            className='p-0'
        >
            <ModalBody
            className='p-0'>
                <Form anuncioId={anuncioId} clienteIndicadorId={clienteIndicadorId} data={data} hideModal={hideModal} />
            </ModalBody>
        </Modal>
    </>
}

export default OportunidadeSimplificada;