import React, { } from 'react';
import {
    ListGroup,
} from "reactstrap";
import {
    Estrategia,
} from '../../../../entities/Mentoring';
import EstrategiaListItem from "./Item";

type Props = {
    estrategias?: Array<Estrategia>
}

const Item: React.FC<Props> = ({ estrategias }) => {

    return (
        <ListGroup className="list" flush>
            {
                estrategias?.map((item, key) => (
                    <EstrategiaListItem
                        key={key}
                        estrategia={item}
                    />
                ))
            }
        </ListGroup>
    )
}

export default Item;