import React, { useState, useEffect } from 'react';
import api from "../../../../services/api";

import Select2 from "react-select2-wrapper";
import {
    Button,
    Row,
    Col,
    Input,
    FormGroup,
} from "reactstrap";

export default ({ notify, funilId, empresaId, close, onSuccess, ...props }) => {

    const [nome, setNome] = useState(null)
    const [situacao, setSituacao] = useState('A')
    const [descricao, setDescricao] = useState(null)

    const [funil, setFunil] = useState({})
    const [erros, setErros] = useState({})


    useEffect(() => {
        if (funilId && funilId != funil.id) {
            loadFunil(funilId)
        }
    }, [funilId])

    useEffect(() => {
        setNome(funil.nome)
        setSituacao(funil.situacao ? funil.situacao : 'A')
        setDescricao(funil.descricao)
    }, [funil])

    async function loadFunil(funilId) {
        try {
            const response = await api.get(`/common/empresas/${empresaId}/funis/${funilId}`)
            if (response.data) {
                setFunil(response.data)
            }
            updateErros(null)
        } catch (error) {
            console.log(error)
        }
    }


    function handleSave() {
        if (funilId) {
            update()
        } else {
            insert()
        }
    }

    async function insert() {
        try {
            console.log(situacao)
            await api.post(`/common/empresas/${empresaId}/funis`, {
                nome,
                situacao,
                descricao
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível adicionar o funil')
            if (error.response) updateErros(error.response.data)
        }
    }

    async function update() {
        try {
            await api.put(`/common/empresas/${empresaId}/funis/${funilId}`, {
                nome,
                situacao,
                descricao
            })
            updateErros(null)
            if (onSuccess) onSuccess()
        } catch (error) {
            console.error(error)
            if (notify) notify('danger', 'Não foi possível salvar o funil')
            if (error.response) updateErros(error.response.data)
        }
    }

    function updateErros(error) {
        if (error && error instanceof Array) {
            const errors = {}
            for (let e of error) {
                errors[e.field] = e.message
            }
            setErros(errors)
        } else {
            setErros({})
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Nome*
                        </label>
                        <Input
                            className="form-control"
                            placeholder="Nome..."
                            type="text"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />
                        <small class="text-danger">
                            {erros.nome ? erros.nome : ''}
                        </small>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                            title={(funilId && funil.fl_ispadraoempresa) ? 'Funil configurado como padrão não pode ser inativado!' : ''}
                        >
                            Situação*
                        </label>
                        <Select2
                            defaultValue="A"
                            onSelect={(e) => setSituacao(e.target.value)}
                            options={{
                                disabled: (funilId && funil.fl_ispadraoempresa),
                                placeholder: "Selecione uma situação..."
                            }}
                            value={situacao}
                            data={
                                [
                                    {
                                        id: 'A',
                                        text: 'Ativo'
                                    }, {
                                        id: 'I',
                                        text: 'Inativo'
                                    }
                                ]
                            }
                        />
                        <small class="text-danger">
                            {erros.situacao ? erros.situacao : ''}
                        </small>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Descrição
                        </label>
                        <Input
                            className="form-control"
                            placeholder="Descrição..."
                            type="textarea"
                            rows={3}
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                        />
                        <small class="text-danger">
                            {erros.descricao ? erros.descricao : ''}
                        </small>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="float-right ">
                        <Button
                            className="ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => close()}
                        >
                            Fechar
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleSave}
                        >
                            Salvar
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
}
