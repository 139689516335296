import React, { useState, useRef, useEffect, useContext } from 'react';
import classnames from 'classnames';
import { useHistory } from "react-router-dom";

import { TipoEnvioContainer } from "./styles";
import SmsForm from './SmsForm';
import EmailForm from './EmailForm';
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";

function Form({
    sender,
    setSender,
    subject,
    setSubject,
    midias,
    setMidias,
    bodyText,
    setBodyText,
    npsTextEmail,
    setNpsTextEmail,
    enviarSms,
    setEnviarSms,
    enviarEmail,
    setEnviarEmail,
    smsConteudo,
    setSmsConteudo,
    smsAcentuado,
    setSmsAcentuado,
    npsTextSms,
    setNpsTextSms,
    tabActive,
    setTabActive,
    destinatarioEmail,
    destinatarioSms,
    isNps,
    isEdit,
    erros,
    notify,
    ...props
}) {

    const history = useHistory()

    useEffect(() => {
        if (tabActive === '') {
            if (enviarEmail) {
                setTabActive('email')
            } else if (enviarSms) {
                setTabActive('sms')
            }
        } else {
            if (tabActive === 'email' && !enviarEmail) {
                setTabActive('')
            }
            else if (tabActive === 'sms' && !enviarSms) {
                setTabActive('')
            }
        }
    }, [enviarEmail, enviarSms])

    return (
        <>
            <Row>
                <Col className='text-center'>
                    <h3
                        className=" mb-4"
                    >
                        O que deseja enviar?*
                    </h3>
                    <TipoEnvioContainer>
                        <div className="custom-control custom-checkbox mr-4">
                            <input
                                className="custom-control-input"
                                id="check-email"
                                type="checkbox"
                                checked={enviarEmail}
                                onChange={({ target }) => setEnviarEmail(target.checked)}
                                disabled={isEdit}
                            />
                            <label className="custom-control-label" htmlFor="check-email">
                                Email
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox mr-4">
                            <input
                                className="custom-control-input"
                                id="check-sms"
                                type="checkbox"
                                checked={enviarSms}
                                onChange={({ target }) => setEnviarSms(target.checked)}
                                disabled={isEdit}
                            />
                            <label className="custom-control-label" htmlFor="check-sms">
                                SMS
                            </label>
                        </div>
                    </TipoEnvioContainer>
                    <small class="text-danger">
                        {erros.enviar_sms || ''}
                        {erros.enviar_email || ''}
                    </small>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12} md={12} sm={12}>
                    <div
                        className="nav-wrapper"
                    >
                        <Nav
                            className="nav-fill flex-column flex-md-row"
                            pills
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    disabled={!enviarEmail}
                                    href="#"
                                    role="tab"
                                    className={classnames("mb-sm-3 mb-md-0 mt-2", {
                                        active: tabActive == 'email'
                                    })}
                                    onClick={() => setTabActive('email')}
                                >
                                    <i className="fas fa-envelope mr-2" />
                                    Email
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    disabled={!enviarSms}
                                    role="tab"
                                    className={classnames("mb-sm-3 mb-md-0 mt-2", {
                                        active: tabActive == 'sms'
                                    })}
                                    onClick={() => setTabActive('sms')}
                                >
                                    <i class="fas fa-paper-plane mr-2"></i>
                                    SMS
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div>
                        <TabContent activeTab={tabActive}>
                            <TabPane tabId="email">
                                <EmailForm
                                    erros={erros}
                                    history={history}
                                    midias={midias}
                                    notify={notify}
                                    sender={sender}
                                    subject={subject}
                                    bodyText={bodyText}
                                    setSender={setSender}
                                    setSubject={setSubject}
                                    setBodyText={setBodyText}
                                    setMidias={setMidias}
                                    isNps={isNps}
                                    npsText={npsTextEmail}
                                    setNpsText={setNpsTextEmail}
                                />
                                {
                                    destinatarioEmail &&
                                    <>
                                        <Row className='mb--3'>
                                            <Col>
                                                <h2 className='d-flex'>
                                                    <span>Destinatários</span>
                                                </h2>
                                            </Col>
                                        </Row>
                                        <hr className='mb--4' />
                                        {
                                            destinatarioEmail
                                        }
                                    </>
                                }

                            </TabPane>
                            <TabPane tabId="sms">
                                <SmsForm
                                    {...props}
                                    erros={erros}
                                    history={history}
                                    notify={notify}
                                    conteudo={smsConteudo}
                                    setConteudo={setSmsConteudo}
                                    smsAcentuado={smsAcentuado}
                                    setSmsAcentuado={setSmsAcentuado}
                                    isNps={isNps}
                                    npsText={npsTextSms}
                                    setNpsText={setNpsTextSms}
                                />
                                {
                                    destinatarioSms &&
                                    <>
                                        <Row className='mb--3'>
                                            <Col>
                                                <h2 className='d-flex'>
                                                    <span>Destinatários</span>
                                                </h2>
                                            </Col>
                                        </Row>
                                        <hr className='mb--4' />
                                        {
                                            destinatarioSms
                                        }
                                    </>
                                }

                            </TabPane>
                        </TabContent>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Form;