import React, { useState, useEffect } from 'react';
import Form from "./Form"

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

export default ({ match, location,...props }) => {

    const [id, setId] = useState(null)
    const [onBackAnuncio, setOnBackAnuncio] = useState(false)
    const [anuncio, setAnuncio] = useState(null) 

    useEffect(() => {
        const { params } = match
        if (params && params.id != id) {
            setId(params.id)
        }

        const { state } = location
        if (state?.onBackAnuncio) {
            setOnBackAnuncio(state?.onBackAnuncio)
        }

        if (state?.anuncio) {
            setAnuncio(state?.anuncio)
        }
    }, [])

    return (
        <>
            <SimpleHeader
                name="Alterar Publicação"
                parentName="Publicações"
                {...props} />
            <Form
                {...props}
                publicacaoId={id}
                onBackAnuncio={onBackAnuncio}
                anuncioToBack={anuncio}
            />
        </>
    )


}
