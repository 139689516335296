import React, { useState, useEffect, useContext } from 'react'; import Form from "./Form";

import SimpleHeader from '../../../../components/Headers/SimpleHeader'

import {useLocation} from "react-router-dom";
import { QuestaoAlvo } from '../../../../entities/Mentoring';

// import { Container } from './styles';

const New: React.FC = () => {

    const [questaoAlvo, setQuestaoAlvo] = useState(null)
    const location = useLocation()

    useEffect(() => {
        const state: any = location.state;

        if (state?.toQuestaoAlvo) {
            setQuestaoAlvo(state?.toQuestaoAlvo)
        }
    }, [])

    return (
        <>
            <SimpleHeader
                name="Novo PDCA"
                parentName="PDCAs"

            />
            <Form
                //@ts-ignore
                questaoAlvoId={questaoAlvo?.id}
            />
        </>
    );
}

export default New;