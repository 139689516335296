import React, { useContext, useEffect, useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  Button,
  Modal,
  Badge,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import AccountsListGroup from './AccountsListGroup';
import ContaSocialCard from './components/ContaSocialCard';
import FacebookAds from '../FacebookAds';
import api from '../../../../../../services/api';
import './components/styles.css';
import EmpresaContext from '../../../../../../contexts/Empresa';

interface ClientInfoResponse {
  success: boolean;
  sessionInfo: {
    pushname: string;
    wid: {
      server: string;
      user: string;
      _serialized: string;
    };
    me: {
      server: string;
      user: string;
      _serialized: string;
    };
    platform: string;
  };
}

function formatPhoneNumber(phoneNumber: string) {
  // Adiciona o "+" no começo, os dois primeiros dígitos são o código do país
  const countryCode = `+${phoneNumber.slice(0, 2)}`;

  // Os próximos dois dígitos são o código de área
  const areaCode = phoneNumber.slice(2, 4);

  // Verifica se o número tem o '9' após o DDD
  const hasNine = phoneNumber[4] === '9';

  // Se tiver o '9', considera essa parte ao dividir o número
  const firstPart = hasNine ? phoneNumber.slice(5, 9) : phoneNumber.slice(4, 8);
  const secondPart = hasNine
    ? phoneNumber.slice(9, 13)
    : phoneNumber.slice(8, 12);

  // Formata o número final
  return `${countryCode} ${areaCode} ${
    hasNine ? '9 ' : ''
  }${firstPart}-${secondPart}`;
}

export default function ContasSociais() {
  const [activeTab, setActiveTab] = useState('hidden');
  const [fbStatus, setFbStatus] = useState<any>(false);
  const [accounts, setAccounts] = useState<any>([]);
  const [connectedPages, setConnectedPages] = useState([]);
  const [modal, setModal] = useState(false);
  const [QRImage, setQRImage] = useState('');
  const [isSessionActive, setIsSessionActive] = useState(false);

  const { empresaSelecionada } = useContext(EmpresaContext);

  async function carregarEquipesEmAccounts() {
    const { data } = await api.get('/meta/accounts');
    setAccounts(data);
  }
  const toggle = () => {
    setActiveTab('hidden');
  };

  useEffect(() => {
    carregarEquipesEmAccounts();
    checkFacebookStatus();
    checkWhatsAppSessionStatus();
  }, []);

  async function checkFacebookStatus() {
    const response = await api.get('common/canais/facebook');
    const { is_valid, pages } = response.data;
    setFbStatus(is_valid);
    setConnectedPages(pages);
  }

  async function fetchQRCode() {
    const { data } = await api.get(`/meta/qr/image?session=empresa&id=${empresaSelecionada?.id}`, { responseType: 'blob' });

    const url = URL.createObjectURL(data);
    setQRImage(url);
  }

  async function checkWhatsAppSessionStatus() {
    const { data } = await api.get(`/meta/status?session=empresa&id=${empresaSelecionada?.id}`);

    if (data) {
      setIsSessionActive(data.state == 'CONNECTED' ? true : false);
    }
  }

  async function getClientInfo() {
    try {
      const { data } = await api.get<ClientInfoResponse>(`/meta/class-info?session=empresa&id=${empresaSelecionada?.id}`);

      if (data) {
        const {
          sessionInfo: { pushname, wid },
        } = data;

        // Se os dados da sessão existirem, registra a conta no sistema
        await api.post(`/meta/accounts/web`, {
          account: {
            verified_name: pushname,
            code_verification_status: 'VERIFIED',
            display_phone_number: wid.user,
            quality_rating: 'GREEN',
            id: wid.user,
          },
        });

        // Exibe o alerta de sucesso e fecha o modal
        window.alert('Conta sincronizada com sucesso!');
        setModal(false); // Fechar o modal
        checkWhatsAppSessionStatus();
        setQRImage("");
        // Retorna verdadeiro para parar o polling
        return true;
      }
    } catch (error) {
      console.error('Erro ao obter informações do cliente:', error);
    }

    // Retorna falso para continuar o polling
    return false;
  }

  async function terminateWhatsAppSession() {
    await api.get(`/meta/terminate?session=empresa&id=${empresaSelecionada?.id}`);

    checkWhatsAppSessionStatus();
  }

  function toggleWhatsAppModal() {
    setModal((currentState) => !currentState);
  }

  function handleModalClose() {
    setModal(false);
    setQRImage('');
  }

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout | undefined;

    if (QRImage) {
      // Quando a imagem do QR Code for exibida, inicia o polling
      pollingInterval = setInterval(async () => {
        const isClientSynced = await getClientInfo();

        if (isClientSynced) {
          // Se a conta for sincronizada, para o polling
          clearInterval(pollingInterval);
        }
      }, 3000);
    }

    // Limpa o polling se o componente for desmontado
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [QRImage]);

  return (
    <>
      <Modal isOpen={modal} toggle={handleModalClose}>
        <ModalHeader>WhatsApp</ModalHeader>
        <ModalBody>{QRImage && <img src={QRImage} />}</ModalBody>
        {isSessionActive && <Badge color="success">Conectado</Badge>}
        {!isSessionActive && <Badge color="danger">Desconectado</Badge>}
        <ModalFooter>
          {!isSessionActive && (
            <Button color="primary" onClick={fetchQRCode}>
              Iniciar Sessão
            </Button>
          )}
          {isSessionActive && (
            <Button color="danger" onClick={terminateWhatsAppSession}>
              Desconectar
            </Button>
          )}
          <Button color="secondary" onClick={handleModalClose}>
            Voltar
          </Button>
        </ModalFooter>
      </Modal>

      {activeTab === 'hidden' && (
        <Nav role="tablist" style={{ gap: '1rem' }}>
          <ContaSocialCard
            isConnected={accounts.length > 0}
            icon="fab fa-whatsapp"
            name="whataspp-ba"
            setTabActive={() => setActiveTab('whatsapp-ba')}
            title="WhatsApp Business"
          />
          <ContaSocialCard
            isConnected={fbStatus}
            icon="fab fa-facebook"
            name="facebookads"
            setTabActive={() => setActiveTab('facebookads')}
            title="Facebook Ads"
          />

          <ContaSocialCard
            isConnected={isSessionActive}
            icon="fab fa-whatsapp"
            name="whatsapp"
            setTabActive={toggleWhatsAppModal}
            title="WhatsApp"
          />
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId="whatsapp-ba">
          <AccountsListGroup
            hideTab={toggle}
            accounts={accounts}
            onSuccess={carregarEquipesEmAccounts}
          />
        </TabPane>

        <TabPane tabId="facebookads">
          <Button onClick={toggle}>Voltar</Button>
          <FacebookAds fbStatus={fbStatus} connectedPages={connectedPages} />
        </TabPane>

        <TabPane tabId="whatsapp">
          <Button onClick={toggle}>Voltar</Button>
          <FacebookAds fbStatus={fbStatus} connectedPages={connectedPages} />
        </TabPane>
      </TabContent>
    </>
  );
}
