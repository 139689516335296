import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { 
  Spinner,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
 } from "reactstrap";

import "./styles.css";

export default ({ itens, pageProperties, notify, ...props }) => {
  const headerStyle = { fontSize: 9 };
  const { ExportCSVButton } = CSVExport;

  function removeHtmlTags(input) {
    console.log({ input });
    var cleanedText = input.replace(/<.*?>/g, "");
    cleanedText = cleanedText.replace(/&#[0-9]+;/g, "");
    return cleanedText;
  }

  let columns = [
    {
      headerStyle: {
        fontSize: 9,
        position: "sticky",
        left: 0,
        zIndex: 10,
      },
      dataField: "nome",
      text: "Estágio<br/>  &nbsp;",
      sort: true,
      headerFormatter: headerFormatter,
      sortFunc: (a, b, order) => {
        if (order === "asc") return a.localeCompare(b);
        else return b.localeCompare(a);
      },
      style: () => ({
        position: "sticky",
        left: 0,
        backgroundColor: "#fff",
      }),
    },
    {
      dataField: "valor_atual_abertas",
      text: "Estão no Estágio<br/>Abertas",
      sort: true,
      headerFormatter: headerFormatter,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "valor_atual_encerradas",
      text: "Estão no Estágio<br/>Encerradas",
      sort: true,
      headerFormatter: headerFormatter,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "passaram",
      text: "Passaram pelo estágio<br/>  &nbsp;",
      sort: true,
      headerFormatter: headerFormatter,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "saltaram",
      text: "Saltaram o estágio<br/>  &nbsp;",
      sort: true,
      headerFormatter: headerFormatter,
      headerAlign: "center",
      align: "center",
      headerStyle,
    },
    {
      dataField: "total",
      text: "Total<br/>  &nbsp;",
      sort: true,
      headerFormatter: headerFormatter,
      headerAlign: "center",
      align: "center",
      headerStyle,
      formatter: (cell, row) => totalFormatter(row),
    },
    {
      dataField: "funil",
      text: "Funil<br/>  &nbsp;",
      sort: true,
      headerFormatter: headerFormatter,
      headerAlign: "center",
      align: "center",
      headerStyle,
      formatter: (cell, row) => funilFormatter(row),
    },
  ];

  columns = columns.map((c) => ({
    ...c,
    csvText: c.dataField,
    footer: (columnData, data, index) => {
      if (!index) return "Total";
      if (data.dataField == "total" || data.dataField == "funil") return "--";
      return columnData.reduce(
        (acc, item) => parseFloat(acc) + parseFloat(item ?? 0),
        0
      );
    },

    footerStyle: (_, index) => {
      if (!!index) {
        return {
          textAlign: "center",
        };
      } else {
        return {
          position: "sticky",
          left: 0,
        };
      }
    },
  }));

  function headerFormatter(column) {
    return (
      <>
        <div id={`column-${column.text}`}>
          <div dangerouslySetInnerHTML={{ __html: column.text }} />
          <span className="order-4 position-absolute right-0" style={{ top: "33px" }}></span>
        </div>
      </>
    );
  }

  function totalFormatter(row) {
    var total =
      parseInt(row.valor_atual_abertas) +
      parseInt(row.valor_atual_encerradas) +
      parseInt(row.passaram) +
      parseInt(row.saltaram);
    return total;
  }

  function funilFormatter(row) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 20,
        }}
        title={row.funil}
      >
        {row.funil && (
          <div
            style={{
              width: (row.funil / row.totalOportunidades) * 100 * 2,
              height: 5,
              backgroundColor: row.cor,
              borderRadius: 3,
            }}
            title={row.funil}
          ></div>
        )}
      </div>
    );
  }

  return (
    <>
      {itens && pageProperties ? (
        <ToolkitProvider
          data={itens}
          columns={columns}
          keyField={"id"}
          exportCSV={{
            fileName: "custom.xls",
            separator: ";",
            noAutoBOM: false,
            blobType: "text/xls;charset=ansi",
          }}
        >
          {(props) => (
            <div className="py-4">
                <UncontrolledDropdown
                group
                size="sm"
                direction="down"
                style={{ position: "absolute", top: -15, right: 30 }}
              >
                <DropdownToggle color="link">
                  <i className="fas fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="#">
                    <ExportCSVButton
                      {...props.csvProps}
                      style={{
                        backgroundColor: "transparent",
                        padding: 0,
                        border: 0,
                        width: "100%",
                        color: "#212529",
                        textAlign: "start",
                      }}
                    >
                      <i className="fas fa-file-export"></i>
                      <span className="ml-1">Exportar</span>
                    </ExportCSVButton>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
                sort={{
                  sortFunc: (a, b, order) => {
                    if (order === "asc") return a - b;
                    else return b - a;
                  },
                }}
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Spinner color="primary" />
        </div>
      )}
    </>
  );
};
