import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../contexts/Empresa'
import api from "../../../../services/api";
import moment from 'moment'

import ExpandedFilters from './ExpandedFilters'
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import { SpinnerContainer } from './styles'
import {
    Button,
    Container as BootstrapContainer,
    Row,
    Col,
    Alert,
    Spinner,
} from "reactstrap";

function Periodos({
    notify,
    isGestor,
    onDateChanged,
    mes,
    ano,
    equipe,
    colaborador,
    unidade,
    tipoProduto,
    periodo,
    setPeriodo,
}) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    //const [periodos, setPeriodos] = useState([{ id: 1, inicio: '01', fim: '07' }, { id: 2, inicio: '08', fim: '14' }, { id: 3, inicio: '15', fim: '21' }, { id: 4, inicio: '22', fim: '31' }])
    const [periodos, setPeriodos] = useState([])
    const [divPeriodosButtons, setDivPeriodosButtons] = useState([])
    const [canReloadPeriodosButton, setCanReloadPeriodosButton] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadPeriodos()
    }, [mes, ano, colaborador, tipoProduto, unidade, empresaSelecionada])

    async function loadPeriodos() {
        setPeriodo(null)
        setPeriodos([])
        setLoading(true)
        if (colaborador && colaborador > 0) {
            try {
                const response = await api.get(`/marketing/empresas/${empresaSelecionada.id}/periodos_for_dashboard`, {
                    params: {
                        mes,
                        ano,
                        colaborador,
                        tipoProduto,
                        unidade
                    }
                })
                setPeriodos(await response.data)

                if (response && response.data && response.data.length > 0) {
                    setPeriodo(response.data[0])
                }
            } catch (error) {
                console.error(error)
                notify('danger', 'Não foi possível carregar Períodos para Dashboard de Meta')
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        reloadPeriodosButton()
        // multiStep()
    }, [periodos])

    useEffect(() => {
        if (canReloadPeriodosButton) {
            reloadPeriodosButton();
        }
    }, [periodo])


    function reloadPeriodosButton() {
        setCanReloadPeriodosButton(false)

        if (!(periodo && periodo.data_inicio)) {
            if (periodos && periodos.length > 0) {
                setPeriodo(periodos[0])
                for (const per of periodos) {
                    if (!(moment(per.data_inicio).isAfter(new Date()))) {
                        setPeriodo(per)
                    }
                }
            }
        }

        let buttons = [];
        if (periodos && periodos.length > 0) {
            for (const per of periodos) {

                buttons.push(
                    <Button
                        className="btn btn-neutral"
                        style={{
                            background: (periodo?.id == per.id) ? '#0847d6' : (moment(per.data_inicio).isAfter(new Date())) ? '#f5f5f5' : '#ffffff',
                            borderColor: (periodo?.id == per.id) ? '#0847d6' : (moment(per.data_inicio).isAfter(new Date())) ? '#f5f5f5' : '#ffffff',
                            color: (periodo?.id == per.id) ? '#ffffff' : '#0847d6',
                            opacity: 1
                        }}
                        //outline
                        active={(periodo?.id == per.id)}
                        disabled={moment(per.data_inicio).isAfter(new Date())}
                        onClick={() => setPeriodo(per)}>
                        {`${moment(per.data_inicio).format('DD')} a ${moment(per.data_fim).format('DD')}`}
                    </Button>
                )

            }
        }
        setDivPeriodosButtons(buttons)
        setCanReloadPeriodosButton(true)
    }


    return (
        <>

            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    position: 'sticky',
                    top: 70,
                    zIndex: 100,
                }}
            >
                <Row className="mb-4">
                    <Col lg="12" sm="12" md="12" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '6px' }}>
                        {loading ?

                            <SpinnerContainer>
                                <Spinner
                                    color='primary'
                                    size='lg'
                                    style={{
                                        alignSelf: 'center'
                                    }}
                                />
                            </SpinnerContainer>

                            :

                            divPeriodosButtons
                        }
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Periodos;