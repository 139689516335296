import styled from 'styled-components'

export const LabelWithBedge = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;

    .badge{
        top: -14%;
    }
`