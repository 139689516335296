import React, { useState, useContext, useEffect, useRef } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from '../../../../services/api'
import { AxiosResponse } from 'axios';
import {
  useParams,
  useHistory,
} from "react-router-dom";
import {
  Anuncio,
  Esteira
} from '../../../../entities/Marketing';

import { } from './styles';
import Estagios from './Estagios'
import Anuncios from "./Anuncios";
//@ts-ignore
import Select2 from "react-select2-wrapper";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Spinner,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Input,
  CardHeader,
  Button
} from "reactstrap";

export type FormError = {
  nome?: string,
  situacao?: string
  anuncios?: string
}

const Form: React.FC = ({ }) => {

  const { id: esteira_id } = useParams() as { id?: number }
  const history = useHistory()
  const { empresaSelecionada } = useContext(EmpresaContext)

  const [esteira, setEsteira] = useState({} as Esteira)
  const [nome, setNome] = useState('')
  const [situacao, setSituacao] = useState<'A' | 'I'>('A')
  const [anuncios, setAnuncios] = useState(new Array<Anuncio>())

  const [alert, setAlert] = useState<any>(undefined)
  const [erros, setErros] = useState<FormError>({} as FormError)
  const [saving, setSaving] = useState(false)

  const notificationAlert = useRef<NotificationAlert>(null)

  useEffect(() => {
    console.log(esteira_id)
    if (esteira_id) loadEsteira()
  }, [esteira_id])

  useEffect(() => {
    setNome(esteira.nome)
    setSituacao(esteira.situacao)
  }, [esteira])

  async function loadEsteira() {
    try {
      const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/esteiras/${esteira_id}`)
      const data = await response.data
      setEsteira(data)
    } catch (error) {
      notify('error', 'Não foi possível carregar esteira')
      console.error(error)
    }
  }

  async function handleSave() {
    setErros({})
    if (esteira.id) {
      await update()
    } else {
      await insert()
    }
  }

  async function insert() {
    try {
      setSaving(true)
      const newEsteira = bind()
      const response = await api.post(`common/empresas/${empresaSelecionada?.id}/esteiras`,
        {
          ...newEsteira,
          anuncios: anuncios.map(anuncio => anuncio.id)
        })
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
          title={'Esteira salva'}
          confirmBtnBsStyle="success"
          showConfirm={false}
          btnSize=""
        />
      );
      setTimeout(() => setAlert(undefined), 2000);

      const data = await response.data
      setEsteira(data)
    } catch (error) {
      console.error(error)
      notify('danger', 'Não foi possível salvar esteira')
      throwError(error.response)
    } finally {
      setSaving(false)
    }
  }

  async function update() {
    try {
      setSaving(true)
      const newEsteira = bind()
      const response = await api.put(`common/empresas/${empresaSelecionada?.id}/esteiras/${esteira.id}`,
        {
          ...newEsteira,
        })
      notify('success', 'Esteira salva')

      const data = await response.data
      setEsteira(data)
    } catch (error) {
      console.error(error)
      notify('danger', 'Não foi possível salvar esteira')
      throwError(error.response)
    } finally {
      setSaving(false)
    }
  }

  function throwError(response: AxiosResponse) {
    if (response && response.status == 400) {
      if (response.data.length) {
        const responseError = {} as FormError
        for (let e of response.data) {
          //@ts-ignore
          responseError[e.field] = e.message
        }
        setErros(responseError)
      }
    }
  }

  function bind(): Esteira {
    return {
      ...esteira,
      nome,
      situacao
    }
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {msg}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3
    };
    notificationAlert?.current?.notificationAlert(options);
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      {alert}
      <Container
        className='mt--6'
        fluid
      >
        <Card>
          <CardHeader
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
            }}>
            <h1>{esteira.id ? 'Alterar Esteira' : 'Nova Esteira'}</h1>
          </CardHeader>
          <CardBody>
            <Row>
              <Col
                sm='12'
                md='12'
                lg='7'
              >
                <Row>
                  <Col
                    sm='12'
                    md='12'
                    lg='6'
                  >
                    <FormGroup>
                      <label className="form-control-label" >
                        Nome*
                  </label>
                      <Input
                        placeholder='Nome...'
                        className="form-control"
                        value={nome}
                        onChange={e => setNome(e.target.value)}
                      />
                      <small className="text-danger">
                        {erros.nome || ""}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col
                    sm='12'
                    md='12'
                    lg='6'
                  >
                    <FormGroup>
                      <label className="form-control-label" >
                        Situação*
                      </label>
                      <Select2
                        className="form-control"
                        //@ts-ignore
                        onSelect={(e) => setSituacao(e.target.value)}
                        value={situacao}
                        data={[{ id: 'A', text: 'Ativo' }, { id: 'I', text: 'Inativo' }]}
                      />
                      <small className="text-danger">
                        {erros.situacao || ""}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col
                sm='12'
                md='12'
                lg='5'
              >
                <Anuncios
                  esteiraId={esteira.id}
                  notify={notify}
                  updateAnuncios={setAnuncios}
                />
              </Col>
            </Row>
            {
              esteira.id &&
              <Estagios
                esteiraId={esteira.id}
                notify={notify}
              />
            }
            <Row>
              <Col>
                <div className="float-right ">
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    voltar
                  </Button>
                  <Button
                    disabled={saving}
                    color="primary"
                    onClick={handleSave}
                  >
                    {
                      <Spinner
                        hidden={!saving}
                        className="mr-2"
                        color="light"
                        size="sm"
                      />
                    }
                            Salvar
                    </Button>

                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Form;