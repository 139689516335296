import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import Axios from 'axios'
import PerformancePublicacao from '../../../../marketing/PerformancePublicacao/Table';

import {
    // global options for the charts
    BarParcial,
} from "../../../../../variables/charts";
import { Bar } from "react-chartjs-2";
import { SpinnerContainer } from '../styles'
import {
    Spinner,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";
let cancelApi = undefined

function ParcialAnuncios({ notify, mes, ano, equipe, colaborador, campanha, history }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [loading, setLoading] = useState(false)
    const [emptyValue, setEmptyValue] = useState(null)

    const [anuncio, setAnuncio] = useState(null)
    const [showModalPublicacoes, setShowModalPublicacoes] = useState(false)
    const [itensPublicacoes, setItensPublicacoes] = useState([])
    const [loadingPublicacoes, setLoadingPublicacoes] = useState(false)
    const [pageProperties, setPageProperties] = useState({
        total: "0",
        perPage: 10,
        page: 1,
        lastPage: 1,
        loading: false,
        sort: new Map([])
    })
    const [lastSearch, setLastSearch] = useState({})

    function handleCloseModalMovimentacao() {
        setItensPublicacoes([])
        setShowModalPublicacoes(false)
        setAnuncio(null)
    }

    useEffect(() => {
        load()
        setLastSearch(
            {
                mes,
                ano,
                equipe,
                colaborador,
                campanha_id: campanha,
                anuncio_id: anuncio ? anuncio.id : 0
            }
        )
    }, [mes, ano, equipe, colaborador, campanha])


    useEffect(() => {
        if (anuncio && anuncio.id > 0) {
            console.log({ anuncio })
            setShowModalPublicacoes(!showModalPublicacoes)
            loadItensPublicacoes()
        }
    }, [anuncio])

    async function load() {
        setLoading(true)
        try {
            const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/anuncios`, {
                params: {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    campanha_id: campanha
                }
            })
            setItens(await response.data)
        } catch (error) {
            console.error(error)
            notify('Não foi possível carregar grafico de campanhas')
        }
        setLoading(false)
    }

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function createChartData() {

        const datasets = []

        const labels = itens.map(anuncio => `${anuncio.nome} (${anuncio.total})`)

        datasets.push({
            label: 'Oportunidades Perdidas',
            backgroundColor: '#f5365c',
            borderColor: '#f5365c',
            data: itens.map(anuncio => parseFloat(anuncio.oportunidades_perdidas))
        })

        datasets.push({
            label: 'Oportunidades em Negociação',
            backgroundColor: '#5e72e4',
            borderColor: '#5e72e4',
            data: itens.map(anuncio => parseFloat(anuncio.oportunidades_em_negociacao))
        })

        datasets.push({
            label: 'Oportunidades Convertidas',
            backgroundColor: '#2dce89',
            borderColor: '#2dce89',
            data: itens.map(anuncio => parseFloat(anuncio.oportunidades_convertidas))
        })

        datasets.push({
            label: 'Impacto (%)',
            backgroundColor: '#2dce89',
            borderColor: '#2dce89',
            data: itens.map(anuncio => anuncio.oportunidades_convertidas > 0 && anuncio.oportunidades_convertidas_total > 0 ? parseFloat((anuncio.oportunidades_convertidas / anuncio.oportunidades_convertidas_total * 100)).toFixed(2) : 0)
        })

        return {
            labels,
            datasets
        }
    }

    function handleSelecionarAnuncio(e) {

        if (!e || e.length === 0) {
            setAnuncio(null)
            setLastSearch(
                {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    campanha_id: campanha,
                    anuncio_id: 0
                }
            )
            return
        }

        const chartIndex = e[0]._index
        const anuncioSelecionado = itens[chartIndex]
        if (anuncio && anuncioSelecionado.id === anuncio.id) {
            setAnuncio(null)
            setLastSearch(
                {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    campanha_id: campanha,
                    anuncio_id: 0
                }
            )
        } else {
            setAnuncio(anuncioSelecionado)
            setLastSearch(
                {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    campanha_id: campanha,
                    anuncio_id: anuncioSelecionado.id
                }
            )
        }
    }

    const handleTableChange = async (type, { page, sizePerPage, sortField, sortOrder }) => {
        try {
            if (type == 'sort') {
                sortField = sortField.replace('__meta__.', '')
                const value = pageProperties.sort.get(sortField)
                const newOrder = (value || 'asc') == 'desc' ? 'asc' : 'desc'
                const map = pageProperties.sort
                map.set(sortField, newOrder)
                sortOrder = newOrder
            }
            if (cancelApi) {
                try {
                    await cancelApi.cancel('Request canceled')
                } catch (error) {

                }
            }
            await loadItensPublicacoes(page == 0 ? 1 : page, sizePerPage, sortField, sortOrder)
        } catch (error) {
            console.log({ error })
            notify('danger', 'Houve um problema ao carregar lista. (change table)');
        }
    }

    async function loadItensPublicacoes(page = 1,
        limit = 10,
        sortField = "oportunidades_total",
        sortOrder = "DESC",
        filters = lastSearch) {
        try {
            setLoadingPublicacoes(true)
            setPageProperties({
                ...pageProperties,
                loading: true
            })
            const cancelSource = await Axios.CancelToken.source()
            cancelApi = cancelSource
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/performances-publicacao`, {
                cancelToken: cancelSource.token,
                params: {
                    page,
                    limit,
                    sortField,
                    sortOrder,
                    ...filters
                }
            })
            setItensPublicacoes(await response.data)
            await updatePageProperties(response)
        } catch (err) {
            console.error(err)
            notify('danger', 'Houve um problema ao carregar lista. (load)');
        } finally {
            setLoading(false)
        }
    }

    async function updatePageProperties(response) {
        const { total, perPage, page, lastPage } = await response.data
        setPageProperties({
            ...pageProperties,
            total,
            perPage,
            page,
            lastPage,
            loading: false,
        })
    }

    return (
        <>
            {/*Modal Publicações do Anúncio*/}
            <Modal
                size="lg"
                className="modal-dialog-centered"
                isOpen={showModalPublicacoes}
                toggle={() => handleCloseModalMovimentacao()}
            >
                <div className="modal-header" style={{ paddingBottom: 0 }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                        Publicações do Anúncio
                        <br />
                        <small className="text-muted"
                            title={`Id e Título do Anúncio`}>
                            {anuncio ? `#${anuncio.id} | ${anuncio.nome}` : ""}
                        </small>
                    </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={handleCloseModalMovimentacao}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" style={{ paddingTop: 5 }}>
                    <Row className='mt-4'>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <PerformancePublicacao
                                itens={itensPublicacoes ? itensPublicacoes.data : []}
                                notify={notify}
                                onTableChange={handleTableChange}
                                history={history}
                                pageProperties={pageProperties}
                                loading={loadingPublicacoes}
                                lastSearch={lastSearch}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>

            <Card >
                <CardHeader>
                    <h5 class="h3 mb-0">Anúncios</h5>
                    <h6 class="surtitle">Parcial dos anúncios</h6>
                </CardHeader>
                <CardBody>
                    <div className="">
                        {
                            loading ?
                                <SpinnerContainer>
                                    <Spinner
                                        color='primary'
                                        size='lg'
                                        style={{
                                            alignSelf: 'center'
                                        }}
                                    />
                                </SpinnerContainer>
                                :
                                <div className='chart'>
                                    <Bar
                                        onElementsClick={handleSelecionarAnuncio}
                                        data={createChartData()}
                                        options={BarParcial.options}
                                        className="chart-canvas"
                                    />
                                </div>
                        }
                    </div>


                </CardBody>
            </Card>
        </>
    )
}

export default ParcialAnuncios;