import styled from 'styled-components'
import { Button } from "reactstrap";

import { getContrastYIQ } from "../../../../../utils";


export const EstagioButton = styled.button`
${props =>
        props.color &&
        (props.active ?
            `
            color: ${getContrastYIQ(props.color)};
            background-color: ${props.color};
            border-color: ${props.color};
            :hover{
                color: ${getContrastYIQ(props.color)};
                background-color: ${props.color};
                border-color: ${props.color};
            }
            `
            :
            `
            color: ${props.color};
            border-color: ${props.color};
            :hover{
                color: ${getContrastYIQ(props.color)};
                background-color: ${props.color};
                border-color: ${props.color};
            }
            `
        )}
        
`
