import React, { useContext, useState, useEffect } from "react";
import TabelaPerformanceColaboradorCanaisPeriodo from "./Graficos/PerformanceColaboradorCanaisPeriodo";
import TabelaPerformanceGeralColaborador from "./Graficos/PerformanceGeralColaborador";
import CardInformativo from "./Informativos";
import FluxoVendasPeriodo from "./Graficos/FluxoVendasPeriodo";
import AuthContext from "../../../contexts/Auth";
import { usePersistedState } from "../../../hooks";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import Filters from "./Filters";
import moment from "moment";
import { Col, Row } from "reactstrap";
import KpisContextProvider from "../../../contexts/Kpis";

// import { Container } from './styles';

const Kpis: React.FC = () => {
  const { hasPermission } = useContext(AuthContext);

  const [mes, setMes] = usePersistedState("mes", moment().format("MM"));
  const [ano, setAno] = usePersistedState("ano", moment().format("YYYY"));
  const [campanha, setCampanha] = useState(null);
  const [canais, setCanais] = useState();

  useEffect(() => {
    setCampanha(null);
  }, [mes, ano]);

  return (
    <>
      <KpisContextProvider>
        {alert}
        <SimpleHeader name="KPIs" parentName="Dashboard" />
        <Filters
          mes={mes}
          ano={ano}
          setMes={setMes}
          setAno={setAno}
          campanha={campanha}
          canais={canais}
          setCanais={setCanais}
        />
        <div className="pb-3 mt-4">
          <CardInformativo
            mes={mes}
            ano={ano}
            canais={canais}
          ></CardInformativo>
        </div>
        <div>
          <TabelaPerformanceGeralColaborador
            mes={mes}
            ano={ano}
            campanha={campanha}
            canais={canais}
          ></TabelaPerformanceGeralColaborador>
        </div>
        <div>
          <TabelaPerformanceColaboradorCanaisPeriodo
            mes={mes}
            ano={ano}
            canais={canais}
          ></TabelaPerformanceColaboradorCanaisPeriodo>
        </div>
        <div>
          <FluxoVendasPeriodo
            mes={mes}
            ano={ano}
            canais={canais}
          ></FluxoVendasPeriodo>
        </div>
      </KpisContextProvider>
    </>
  );
};

export default Kpis;
