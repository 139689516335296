import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Table from "../../../../../components/Table";
import api from "../../../../../services/api";
import EmpresaContext from "../../../../../contexts/Empresa";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { AxiosResponse } from "axios";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import { SelectableButton } from "./styles";
import { string } from "prop-types";
import Avatar from "../../../../../components/Avatar";
import IEstagio from "../../../../../entities/Marketing/Estagio";
import { KpisContext } from "../../../../../contexts/Kpis";

// import { Container } from './styles';

interface IGraficos {
  mes: number;
  ano: number;
  canais: any;
}

interface ISumario {
  oportunidadesagendadasnoperiodo: number | null;
  mediadiaoportunidadesagendadasnoperiodo: number | null;
  oportunidadesagendadasparaoperiodo: number | null;
  mediadiaoportunidadesagendadasparaoperiodo: number | null;
}

const FluxoVendasPeriodo: React.FC<IGraficos> = ({ mes, ano, canais }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [fluxoDeVendas, setFluxoDeVendas] = useState([]);
  const [estagioSelecionado, setEstagioSelecionado] = useState(null);
  const [columnsNoPeriodo, setColumnsNoPeriodo] = useState([]);
  const [columnsParaOperiodo, setColumnsParaOPeriodo] = useState([]);
  const [lastSearch, setLastSearch] = useState({});
  const [time, setTime] = useState<any>(0);
  const [statePeriodo, setStatePeriodo] = useState("noPeriodo");
  const [sumarioPeriodo, setSumarioPeriodo] = useState<ISumario>();
  const [estagios, setEstagios] = useState<Array<IEstagio>>([]);
  const [nomeEstagioSelecionado, setNomeEstagioSelecionado] = useState(null);
  const [currentLead, setCurrentLead] = useState("agendadasNoPeriodo");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { requestAvaliable, handlerequestAvaliable } = useContext(KpisContext);
  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: isLoading,
  });

  useEffect(() => {
    loadEstagios();
  }, []);

  useEffect(() => {
    setColumnsNoPeriodo([
      {
        dataField: "name",
        keyField: "id",
        text: "Colaborador",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => colaboradorFormatter(row),
        headerFormatter: (column: any, colIndex: any) => {
          let divFormatter = (
            <div
              style={{
                width: "145px",
                display: "flex",
                alignItems: "center",
                whiteSpace: "break-spaces",
                margin: "auto",
                height: "6vh",
                justifyContent: "center",
              }}
            >
              Colaborador
            </div>
          );
          return divFormatter;
        },
      },
      {
        dataField: "oportunidadesagendadasnoperiodo",
        keyField: "id",

        text: "Total",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesagendadasnoperiodo),
        headerFormatter: (column: any, colIndex: any) => {
          let divFormatter = (
            <div
              style={{
                width: "145px",
                display: "flex",
                alignItems: "center",
                whiteSpace: "break-spaces",
                margin: "auto",
                height: "6vh",
              }}
            >
              ({nomeEstagioSelecionado}) agendadas no período
            </div>
          );
          return divFormatter;
        },
      },
      {
        dataField: "mediadia",
        keyField: "id",

        text: "Média",
        align: "center",
        headerAlign: "center",
        formatter: (column: any, row: any) =>
          divFormatterNumber(row.mediadia, column),
        headerFormatter: (column: any, colIndex: any) => {
          let divFormatter = (
            <div
              style={{
                width: "145px",
                display: "flex",
                alignItems: "center",
                whiteSpace: "break-spaces",
                margin: "auto",
                height: "6vh",
              }}
            >
              Média/dia ({nomeEstagioSelecionado}) agendadas no período
            </div>
          );
          return divFormatter;
        },
      },
      {
        dataField: "oportunidadesdia",
        keyField: "id",

        text: "Dia",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          // dataFim = new Date(dataFim.setDate(dataFim.getDate() - 1));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) => divFormatter(row.oportunidadesdia),
      },
      {
        dataField: "oportunidadesdiamenos1",
        keyField: "id",

        text: "oportunidadesdiamenos1",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 1));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos1),
      },
      {
        dataField: "oportunidadesdiamenos2",
        keyField: "id",

        text: "oportunidadesdiamenos2",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 2));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos2),
      },
      {
        dataField: "oportunidadesdiamenos3",
        keyField: "id",

        text: "oportunidadesdiamenos3",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 3));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos3),
      },
      {
        dataField: "oportunidadesdiamenos4",
        keyField: "id",

        text: "oportunidadesdiamenos4",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 4));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos4),
      },
      {
        dataField: "oportunidadesdiamenos5",
        keyField: "id",

        text: "oportunidadesdiamenos5",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 5));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos5),
      },
      {
        dataField: "oportunidadesdiamenos6",
        keyField: "id",

        text: "oportunidadesdiamenos6",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 6));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos6),
      },
      {
        dataField: "oportunidadesdiamenos7",
        keyField: "id",

        text: "oportunidadesdiamenos7",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 7));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos7),
      },
      {
        dataField: "oportunidadesdiamenos8",
        keyField: "id",

        text: "oportunidadesdiamenos8",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 8));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos8),
      },
      {
        dataField: "oportunidadesdiamenos9",
        keyField: "id",

        text: "oportunidadesdiamenos9",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 9));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos9),
      },
    ] as any);
    setColumnsParaOPeriodo([
      {
        dataField: "name",
        keyField: "id",

        text: "Colaborador",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) => colaboradorFormatter(row),
        headerFormatter: (column: any, colIndex: any) => {
          let divFormatter = (
            <div
              style={{
                width: "145px",
                display: "flex",
                alignItems: "center",
                whiteSpace: "break-spaces",
                margin: "auto",
                height: "6vh",
                justifyContent: "center",
              }}
            >
              Colaborador
            </div>
          );
          return divFormatter;
        },
      },
      {
        dataField: "oportunidadesagendadasnoperiodo",
        keyField: "id",

        text: "Total",
        align: "center",
        headerAlign: "center",
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesagendadasnoperiodo),
        headerFormatter: (column: any, colIndex: any) => {
          let divFormatter = (
            <div
              style={{
                width: "145px",
                display: "flex",
                alignItems: "center",
                whiteSpace: "break-spaces",
                margin: "auto",
                height: "6vh",
              }}
            >
              {nomeEstagioSelecionado} agendadas para o período
            </div>
          );
          return divFormatter;
        },
      },
      {
        dataField: "mediadia",
        keyField: "id",

        text: "Média",
        align: "center",
        headerAlign: "center",
        formatter: (column: any, row: any) =>
          divFormatterNumber(row.mediadia, column),
        headerFormatter: (column: any, colIndex: any) => {
          let divFormatter = (
            <div
              style={{
                width: "145px",
                display: "flex",
                alignItems: "center",
                whiteSpace: "break-spaces",
                margin: "auto",
                height: "6vh",
              }}
            >
              Média/dia {nomeEstagioSelecionado} agendadas para o período
            </div>
          );
          return divFormatter;
        },
      },
      {
        dataField: "oportunidadesdia",
        keyField: "id",
        text: "Dia",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }
          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;
          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) => divFormatter(row.oportunidadesdia),
      },
      {
        dataField: "oportunidadesdiamenos1",
        keyField: "id",
        text: "oportunidadesdiamenos1",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 1));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos1),
      },
      {
        dataField: "oportunidadesdiamenos2",
        keyField: "id",

        text: "oportunidadesdiamenos2",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 2));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos2),
      },
      {
        dataField: "oportunidadesdiamenos3",
        keyField: "id",

        text: "oportunidadesdiamenos3",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 3));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos3),
      },
      {
        dataField: "oportunidadesdiamenos4",
        keyField: "id",

        text: "oportunidadesdiamenos4",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 4));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos4),
      },
      {
        dataField: "oportunidadesdiamenos5",
        keyField: "id",

        text: "oportunidadesdiamenos5",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 5));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos5),
      },
      {
        dataField: "oportunidadesdiamenos6",
        keyField: "id",

        text: "oportunidadesdiamenos6",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 6));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos6),
      },
      {
        dataField: "oportunidadesdiamenos7",
        keyField: "id",

        text: "oportunidadesdiamenos7",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 7));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos7),
      },
      {
        dataField: "oportunidadesdiamenos8",
        keyField: "id",

        text: "oportunidadesdiamenos8",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 8));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos8),
      },
      {
        dataField: "oportunidadesdiamenos9",
        keyField: "id",

        text: "oportunidadesdiamenos9",
        align: "center",
        headerAlign: "center",
        headerFormatter: (column: any, colIndex: any) => {
          let dataFim = new Date(ano, mes, 0);
          if (dataFim > new Date()) {
            dataFim = new Date();
          }

          dataFim = new Date(dataFim.setDate(dataFim.getDate() - 9));

          let diaFim = new Date(dataFim).getDate();
          let mesFim = new Date(dataFim).getMonth() + 1;

          return (
            <>
              {formatWeekDay(new Date(dataFim)).toUpperCase()}
              <br />
              {formatNumDataWithZero(diaFim)}/{formatNumDataWithZero(mesFim)}
            </>
          );
        },
        formatter: (cell: any, row: any) =>
          divFormatter(row.oportunidadesdiamenos9),
      },
    ] as any);
    if (estagioSelecionado && canais) {
      tempo();
    }
  }, [estagioSelecionado, mes, ano, currentLead, canais]);

  function divFormatterNumber(row: any, column: any) {
    if (typeof column == "number") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "48px",
          }}
        >
          {column.toFixed(2)}
        </div>
      );
    }
    let divFormatter = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "48px",
        }}
      >
        {column}
      </div>
    );
    return divFormatter;
  }

  function divFormatter(row: any) {
    let divFormatter = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "48px",
        }}
      >
        {row}
      </div>
    );
    return divFormatter;
  }

  function colaboradorFormatter(row: any) {
    let colaboradorAvatar = (
      <div style={{ height: 48 }}>
        {row && (
          <Avatar
            title={row.name}
            user={{ avatar: row.avatar, name: row.name }}
            className="avatar"
            style={{
              cursor: "default",
            }}
          />
        )}
      </div>
    );
    return colaboradorAvatar;
  }

  async function loadSumarios() {
    handlerequestAvaliable(false);
    try {
      const dataInicio = moment([ano, mes - 1, 1]).format("YYYY-MM-DD");
      let dataFim = moment([ano, mes - 1, 1])
        .endOf("month")
        .format("YYYY-MM-DD");
      if (new Date(dataFim) > new Date()) {
        // @ts-ignore
        dataFim = new Date();
      }

      let urlRotaPeriodo = "";
      if (currentLead == "agendadasNoPeriodo") {
        urlRotaPeriodo = "kpis-sumario-fluxo-vendas-executado-no-periodo";
      } else {
        urlRotaPeriodo = "kpis-sumario-fluxo-vendas-no-periodo";
      }
      const response = await api.get(
        `/dashboards/empresas/${empresaSelecionada?.id}/${urlRotaPeriodo}`,
        {
          params: {
            estagio_id: estagioSelecionado,
            data_inicio: dataInicio,
            data_fim: dataFim,
            canal_id: canais,
          },
        }
      );
      setSumarioPeriodo(response.data[0]);
      console.log(sumarioPeriodo);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar estagios");
    } finally {
      handlerequestAvaliable(true);
    }
  }

  async function loadEstagios() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/estagios`
      );
      setEstagios(response.data);
      if (response.data.length && !estagioSelecionado)
        setEstagioSelecionado(response.data[0].id);
      if (!nomeEstagioSelecionado) {
        setNomeEstagioSelecionado(response.data[0].nome);
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar estagios");
    }
  }

  const formatNumDataWithZero = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };

  const formatWeekDay = (data: Date) => {
    const diaDaSemana = [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ];
    return diaDaSemana[data.getDay()];
  };
  async function handleTableChange(
    type: string,
    sortProperties: {
      page: number;
      sizePerPage: number;
      sortField: string;
      sortOrder: string;
    }
  ): Promise<void> {
    try {
      await loadData(
        sortProperties.page == 0 ? 1 : sortProperties.page,
        sortProperties.sizePerPage,
        sortProperties.sortField,
        sortProperties.sortOrder
      );
    } catch (error) {
      notify("danger", "Houve um problema ao carregar estágios.");
    }
  }

  const handleCheck = (value: string) => setCurrentLead(value);

  async function tempo() {
    clearTimeout(time);
    setTime(
      setTimeout(async (e) => {
        loadData();
        loadSumarios();
      }, 3000)
    );
  }

  async function loadData(
    page = 1,
    limit = 10,
    sortField = "tema",
    sortOrder = "asc",
    filters = lastSearch
  ) {
    handlerequestAvaliable(false);

    setIsLoading(true);
    try {
      if (isLoading) {
        console.log("essa porra ainda tá rodando");
        return;
      }

      const dataInicio = moment([ano, mes - 1, 1]).format("YYYY-MM-DD");
      let dataFim = moment([ano, mes - 1, 1])
        .endOf("month")
        .format("YYYY-MM-DD");
      if (new Date(dataFim) > new Date()) {
        // @ts-ignore
        dataFim = new Date();
      }

      let urlRota = "";
      console.log({ currentLead, aaa: "agendadasNoPeriodo" });

      if (currentLead == "agendadasNoPeriodo") {
        urlRota = "kpis-fluxo-vendas-executado-no-periodo";
      } else {
        urlRota = "kpis-fluxo-vendas-para-o-periodo";
      }

      const response = await api.get(
        `/dashboards/empresas/${empresaSelecionada?.id}/${urlRota}/`,
        {
          params: {
            estagio_id: estagioSelecionado,
            data_inicio: dataInicio,
            data_fim: dataFim,
            canal_id: canais,
          },
        }
      );
      setFluxoDeVendas(response.data);
      await updatePageProperties(response.data);
    } catch (err) {
      console.error(err);
      notify("danger", "Houve um problema ao carregar fluxo de vendas");
    } finally {
      setIsLoading(false);
      handlerequestAvaliable(true);
    }
  }

  async function updatePageProperties(response: AxiosResponse) {
    const { total, perPage, page, lastPage } = await response.data;
    setPageProperties({
      total,
      perPage,
      page,
      lastPage,
      loading: isLoading,
    });
  }

  function selecaoEstagio(id: any, nome: any) {
    setEstagioSelecionado(id);
    setNomeEstagioSelecionado(nome);
  }

  function notify(type: any, msg: any) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    // if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }
  function checkCurrentSelectionState() {
    if (currentLead == "agendadasNoPeriodo") {
      return columnsNoPeriodo;
    } else {
      return columnsParaOperiodo;
    }
  }
  return (
    <>
      {alert}
      <div className="rna-wrapper">
        {/* <NotificationAlert ref={notificationAlert} /> */}
      </div>
      <Card body>
        <div></div>
        <div>
          <span>
            <Card style={{ minHeight: "365px" }}>
              <div>
                <CardHeader>
                  <h5 className="h3 mb-0">Fluxo de vendas</h5>
                  <small>
                    Ações realizadas ao longo do período visando o avanço das
                    oportunidades ao longo do funil de vendas.
                  </small>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <h6 className="surtitle">Kpis</h6> */}
                  </div>
                </CardHeader>
              </div>
              <CardBody className="pt-0">
                <div
                  className="card-header pt-3 pb-2"
                  style={{
                    display: "flex",
                    overflow: "auto",
                    justifyContent: "center",
                  }}
                >
                  {estagios.map(({ nome, id }) => {
                    return (
                      <SelectableButton
                        className={"btn btn-outline-secondary mb-2"}
                        active={id == estagioSelecionado}
                        color={"#0847d6"}
                        onClick={() => selecaoEstagio(id, nome)}
                      >
                        {nome}
                      </SelectableButton>
                    );
                  })}
                </div>
                <div className="pt-2">
                  <FormGroup
                    tag="fieldset"
                    style={{
                      display: "flex",
                      marginBottom: "0px",
                    }}
                  >
                    <FormGroup
                      check
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      <Input
                        onChange={(e: any) => handleCheck(e.target.value)}
                        checked={currentLead == "agendadasNoPeriodo"}
                        name="radio2"
                        value={"agendadasNoPeriodo"}
                        type="radio"
                      />{" "}
                      <Label
                        check
                        style={{
                          fontSize: "0.9em",
                          fontWeight: 600,
                        }}
                      >
                        ({nomeEstagioSelecionado}) agendadas no período
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        onChange={(e: any) => handleCheck(e.target.value)}
                        checked={currentLead == "agendadasParaOPeriodo"}
                        name="radio2"
                        value={"agendadasParaOPeriodo"}
                        type="radio"
                      />{" "}
                      <Label
                        check
                        style={{
                          fontSize: "0.9em",
                          fontWeight: 600,
                        }}
                      >
                        ({nomeEstagioSelecionado}) agendadas para o período
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </div>

                <div className="row pt-3 justify-content-center">
                  {currentLead == "agendadasNoPeriodo" ? (
                    <div className="col-5">
                      <Card
                        style={{
                          backgroundColor: "#eaedf3",
                        }}
                      >
                        <CardTitle
                          className="p-3 mb-0"
                          style={{
                            backgroundColor: "#12ccf0",
                            borderRadius: "0.375rem 0.375rem 0px 0px",
                          }}
                        >
                          <h4
                            className="text-uppercase mb-0 font-weight-normal"
                            style={{
                              color: "#000000",
                            }}
                          >
                            ({nomeEstagioSelecionado}) agendadas no período
                          </h4>
                        </CardTitle>
                        <div className="p-3 pt-0">
                          <div>
                            <h1>
                              {sumarioPeriodo?.oportunidadesagendadasnoperiodo}
                            </h1>
                            <h2>
                              Média/Dia = {""}
                              {
                                sumarioPeriodo?.mediadiaoportunidadesagendadasnoperiodo
                              }
                            </h2>
                          </div>
                          <div
                            className="icon icon-shape bg-white text-white rounded-circle shadow"
                            style={{
                              position: "absolute",
                              right: "48px",
                              top: "50%",
                            }}
                          >
                            <i
                              className="fa fa-filter"
                              style={{
                                fontSize: "1.5rem",
                                color: "#12ccf0",
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                  ) : (
                    <div className="col-5">
                      <Card
                        style={{
                          backgroundColor: "#eaedf3",
                        }}
                      >
                        <CardTitle
                          className="p-3 mb-0"
                          style={{
                            backgroundColor: "#12ccf0",
                            borderRadius: "0.375rem 0.375rem 0px 0px",
                          }}
                        >
                          <h4
                            className="text-uppercase mb-0 font-weight-normal"
                            style={{
                              color: "#000000",
                            }}
                          >
                            {nomeEstagioSelecionado} agendadas para o período
                          </h4>
                        </CardTitle>
                        <div className="p-3 pt-0">
                          <div>
                            <h2>
                              {
                                sumarioPeriodo?.oportunidadesagendadasparaoperiodo
                              }
                            </h2>
                            <h2>
                              Média/Dia = {""}
                              {
                                sumarioPeriodo?.mediadiaoportunidadesagendadasparaoperiodo
                              }
                            </h2>
                          </div>
                          <div
                            className="icon icon-shape bg-white text-white rounded-circle shadow"
                            style={{
                              position: "absolute",
                              right: "48px",
                              top: "50%",
                            }}
                          >
                            <i
                              className="fa fa-filter"
                              style={{
                                fontSize: "1.5rem",
                                color: "#12ccf0",
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
                <div className="">
                  {isLoading ? (
                    <Container
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "300px",
                      }}
                    >
                      <Spinner color="primary" size=""></Spinner>
                    </Container>
                  ) : (
                    <Table
                      columns={
                        columnsNoPeriodo.length || columnsParaOperiodo.length
                          ? checkCurrentSelectionState()
                          : [
                              {
                                dataField: "name",
                                keyField: "id",

                                text: "Nome",
                                align: "center",
                                headerAlign: "center",
                              },
                            ]
                      }
                      data={fluxoDeVendas}
                      noDataIndication="Nenhum item encontrado"
                      pageProperties={pageProperties}
                      onTableChange={handleTableChange}
                      notify={undefined}
                      rowEvents={undefined}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </span>
        </div>
      </Card>
    </>
  );
};
export default FluxoVendasPeriodo;
