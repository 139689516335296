import styled from 'styled-components'


export const SpinnerContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center
`

export const RadioContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center
`