import React, { useContext } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";
import { MenuComportamento } from '../../../components/Menus'

// reactstrap components
import {
    Button,
    Badge,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    paginationTotalRenderer: (from, to, size) => {
        const margin = {
            paddingLeft: '4px'
        }
        return (
            <span className="react-bootstrap-table-pagination-total" style={margin}>
                Exibindo {from} de {to} do total de {size}.
            </span>
        )
    },
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Exibir{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                itens.
      </label>
        </div>
    )
});

const { SearchBar } = Search;

class MalasDiretas extends React.Component {


    state = {
        edit: null,
        alert: null,
        malasDiretas: this.createDataTable([]),
        localStorageEmpresaAnterior: 0

    }

    notify = (type, msg) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    onNewClicked = async (e) => {
        e.preventDefault();
        this.props.history.push('malas-diretas/new')
    }

    dataCriacaoFormatter(cell, row) {
        const dt = new Date(row.data_postagem)
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return (
            <>
                {dt.toLocaleDateString('pt-br', options)}
            </>
        )
    }
    async loadMalasDiretas() {
        const json = localStorage.getItem('@playnee-empresa')
        const empresaSelecionada = JSON.parse(json)
        const res = await api.get(`/common/empresas/${empresaSelecionada?.id}/malas-diretas/`);
        this.setData(res.data)
    }

    setData(data) {
        this.setState({
            malasDiretas: this.createDataTable(data)
        })
    }

    //Vai para a tela de edição
    goToEdit(id) {
        this.props.history.push(`/admin/malas-diretas/${new Number(id)}/edit`)
    }

    valorFormatter(cell, row) {
        console.log(row.preco)
        return (
            <>
                {Number(row.preco).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </>
        )
    }

    situacaoFormatter(cell, row) {
        let situacao = row.situacao
        let color = 'secondary'
        switch (row.situacao) {
            case 'concluido':
                situacao = 'Concluído'
                color = 'success'
                break;
            case 'em-andamento':
                situacao = 'Em Andamento'
                color = 'secondary'
                break;
            case 'cancelado':
                situacao = 'Cancelado'
                color = 'warning'
                break;
            case '':
                color = 'danger'
                break;
        }
        return (

            <>
                <Badge color={color} className="badge-lg" pill>
                    {situacao}
                </Badge>
            </>
        )
    }

    envioSmsFormatter(cell, row) {
        let enviarSms = row.enviar_sms        
        let gerados = row.qtd_sms_gerados
        let enviados = row.qtd_sms_enviados
        let resultado = ''
        let tipoEnvio = ''
        
        let color = 'secondary'

        if (enviarSms) {
            tipoEnvio = '.'
            color = 'success'
            resultado = 'Gerados: '+gerados + ' | Enviados: '+enviados
        }else{
            tipoEnvio = '.'
            color = 'danger'
        }

        return (

            <>
                <Badge color={color} className="badge-lg" pill>
                    {tipoEnvio}
                </Badge> {resultado}
            </>
        )
    }

    
    envioEmailFormatter(cell, row) {
        let enviarEmail = row.enviar_email        
        let gerados = row.qtd_emails_gerados
        let enviados = row.qtd_emails_enviados
        let resultado = ''
        let tipoEnvio = ''
        
        let color = 'secondary'

        if (enviarEmail) {
            tipoEnvio = '.'
            color = 'success'
            resultado = 'Gerados: '+gerados + ' | Enviados: '+enviados
        }else{
            tipoEnvio = '.'
            color = 'danger'
        }

        return (

            <>
                <Badge color={color} className="badge-lg" pill>
                    {tipoEnvio}
                </Badge> {resultado}
            </>
        )
    }



    componentDidMount() {
        this.loadMalasDiretas();
    }

    dataFormatter(cell, row) {
        const dt = new Date(cell)
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return (
            <>
                {dt.toLocaleDateString('pt-br', options)}
            </>
        )
    }

    acoesFormatter = (cell, row,) => {

        return (
            <MenuComportamento
                actions={[
                    {
                        label: 'Alterar',
                        icon: 'far fa-edit',
                        onClick: () => this.goToEdit(row.id)
                    }
                ]}
            />
        )
    }

    createDataTable(data) {
        if (data[0])
            return (
                <ToolkitProvider
                    data={data}
                    keyField='id'
                    columns={[
                        // {
                        //     dataField: 'acoes',
                        //     formatter: (cell, row) => this.acoesFormatter(cell, row,)
                        // },
                        {
                            dataField: "nome",
                            text: 'Nome',
                            sort: true,
                        },
                        {
                            dataField: 'created_at',
                            text: 'Data Criação',
                            sort: true,
                            formatter: this.dataFormatter
                        },
                        {
                            dataField: 'data_agendamento',
                            text: 'Data Agendamento',
                            sort: true,
                            formatter: this.dataFormatter
                        },
                        {
                            dataField: "origem",
                            text: 'Origem',
                            sort: true,
                        },
                        {
                            dataField: 'envio_sms',
                            text: 'Envio Sms',
                            formatter: this.envioSmsFormatter,
                            sort: true
                        },
                        {
                            dataField: 'envio_email',
                            text: 'Envio Email',
                            formatter: this.envioEmailFormatter,
                            sort: true
                        },
                        {
                            dataField: 'situacao',
                            text: 'Situação',
                            formatter: this.situacaoFormatter,
                            sort: true
                        }
                    ]} search
                >
                    {props => (
                        <div className="py-4 table-responsive">
                            <div
                                id="datatable-basic_filter"
                                className="dataTables_filter float-right"
                            >
                                <label>
                                    <SearchBar
                                        className="form-control-sm"
                                        placeholder="Pesquisar..."
                                        {...props.searchProps}
                                    />
                                </label>
                            </div>
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                pagination={pagination}
                                bordered={false}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            )
    }




    render() {

        return (
            <>
                {this.state.alert}
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <SimpleHeader name="Listagem de Malas Diretas" parentName="MalasDiretas" {...this.props} />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader
                                    style={{
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    }}>
                                    <h1>Malas Diretas</h1>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs='12'>
                                            <span >
                                                {/* <Button
                                                    id="tooltip0983764373524743"
                                                    color="primary"
                                                    type="button"
                                                    onClick={e => this.onNewClicked(e)}
                                                    size="sm">
                                                    <span className="btn-inner--icon">
                                                        <i className="ni ni-fat-add" />
                                                    </span>
                                                    Nova Mala Direta
                                                </Button> */}
                                            </span>

                                        </Col>

                                        <Col>
                                            {this.state.malasDiretas}
                                            {console.log(this.state.malasDiretas)}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }


}

export default MalasDiretas;