import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

import CustomCalendar from "../../../../components/FullCalendar";

export interface IData {
  newEvent: {
    produto_oportunidade_id: string | number;
    nome: string;
    data_agendamento: Date;
    produto: string;
    oportunidade_id: string | number;
  };
  titulo?: string;
}

export const ModalCalendario: React.FC<IData> = ({
  newEvent,
  titulo = "Agendar",
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Button color="link" size="sm" onClick={toggle}>
        <span className="btn-inner--icon mr-2">
          <i className="ni ni-calendar-grid-58" />
        </span>
        {titulo}
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="xl"
        style={{
          width: "100%",
          maxWidth: "70%",
          maxHeight: "80%",
        }}
      >
        <ModalBody className="p-0">
          <CustomCalendar newEvent={newEvent} oportunidadeId={newEvent.oportunidade_id} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
