import React, { useContext, useRef, useState } from "react";
import { Alert, Button, ListGroup, ListGroupItem, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import AddEquipeModal from "./components/AddEquipeModal";
import EmpresaContext from "../../../../../../contexts/Empresa";
import WhatsAppLoginButton from "./components/WhatsAppLoginButton";
import { TutorialContainer } from "./components/styles";

interface AccountsList {
  hideTab: () => void;
  accounts: any;
  onSuccess: () => Promise<void>;
}

export default function AccountsListGroup({
  hideTab,
  accounts,
  onSuccess,
}: AccountsList) {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const notificationAlert = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountSelecionada, setAccountSelecionada] = useState<string>("");

  function openModal(accountId: string) {
    setIsModalOpen(true);
    setAccountSelecionada(accountId);
  }

  function closeModal() {
    setIsModalOpen(false);
    setAccountSelecionada("");
  }

  function toggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert?.current)
      //@ts-ignore
      notificationAlert.current.notificationAlert(options);
  }

  if (accounts.length === 0) {
    return (
      <TutorialContainer>
        <legend>Atenção aos passos necessários</legend>
        <p>1. Integre usa conta do Facebook ao Playnee clicando no botão:</p>
        <div className="btn-container">
          <WhatsAppLoginButton onSuccess={onSuccess} />
        </div>
        <small className="d-inline-block mb-4">
          Siga as instruções do Facebook para integração.
        </small>
        <p>
          2. Uma vez realizada a integração, o Playnee irá listar o(s) número(s)
          de Whatsapp integrado(s) ao Playnee. Observe que ao lado de cada
          número listado você encontrará o botão para adicionar as equipes
          responsáveis pelo atendimento ao número.
        </p>
        <div className="mb-2">
          <span>Botão para adicionar as equipes:</span>
          <Button size="sm" className="ml-1" color="primary">
            Adicionar Equipes
          </Button>
        </div>
        <p>
          Essa informação é importante pois é a partir dela que saberemos para
          quem entregar os clientes que entram em contado com sua empresa por
          meio do número WhatsApp.
        </p>
        <Alert color="primary text-center">
          Observação: é necessário criar equipes de captação/atendimento antes
          de executar esta ação.
        </Alert>
      </TutorialContainer>
    );
  }
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>

      <AddEquipeModal
        close={closeModal}
        show={isModalOpen}
        empresaId={empresaSelecionada?.id}
        accountId={accountSelecionada}
      />

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <Button onClick={hideTab}>Voltar</Button>
        <div>
          <h2>WhatApp</h2>
        </div>

        <div>
          <WhatsAppLoginButton onSuccess={onSuccess} />
        </div>
      </div>
      {accounts.length > 0 && (
        <ListGroup className="list mt-4" flush>
          <h3>Números Conectados</h3>
          {accounts.map((account, key) => (
            <ListGroupItem
              className="list-group-item-action px-0"
              style={{
                flexDirection: "row",
                display: "flex",
                flex: 1,
                alignItems: "center",
              }}
              key={key}
            >
              <div className="col-auto">
                <Button
                  className="btn-sm"
                  color="danger"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </div>
              <Row
                style={{ flex: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="align-items-center"
              >
                <div className="col">
                  <h4 className="mb-0">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {account.phone_number}
                    </a>{" "}
                    - <span>{account.verified_name}</span>
                  </h4>
                  {/* <small>{`${account.__meta__.equipes_count} colaboradores`}</small> */}
                </div>
              </Row>
              <Button
                className="btn-sm"
                color="primary"
                onClick={() => openModal(account.id)}
              >
                Adicionar Equipes
              </Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </>
  );
}
