import React, { useState, useEffect } from "react";
import api from "../../../../../../services/api";

import Form from "./Form";
import AddMembro from "./AddMembro";
import List from "./List";
import {
  Button,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
} from "reactstrap";
import classnames from "classnames";

export default ({ notify, empresa, equipes, loadEquipes, ...props }) => {
  // const [equipes, setEquipes] = useState([]);
  const [equipe, setEquipe] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalEquipeCaptadora, setShowModalEquipeCaptadora] = useState(false);
  const [showAddMembro, setShowAddMembro] = useState(false);
  const [captadora, setCaptadora] = useState("");

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = (captadora) => {
    setCaptadora(captadora);
    setShowModal(true);
  };

  // useEffect(() => {
  //   if (equipes.length === 0) loadEquipes();
  // }, [empresa]);

  // async function loadEquipes() {
  //   try {
  //     const response = await api.get(`/common/empresas/${empresa.id}/equipes`);
  //     setEquipes(await response.data);
  //   } catch (error) {
  //     console.error(error);
  //     notify("danger", "Não foi possível carregar equipes");
  //   }
  // }

  function handleEditEquipe(equipe) {
    setEquipe(equipe);
    setCaptadora(equipe.captadora);
    setShowModal(true);
  }

  function handleShowAddMembro(equipe) {
    setEquipe(equipe);
    setShowAddMembro(true);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        <AddMembro
          equipe={equipe}
          empresa={empresa}
          onSuccess={loadEquipes}
          show={showAddMembro}
          notify={notify}
          close={() => {
            setShowAddMembro(false);
            setEquipe({});
          }}
        />
        <Form
          captadora="I"
          equipe={equipe}
          empresa={empresa}
          onSuccess={loadEquipes}
          show={showModal}
          notify={notify}
          close={() => {
            setShowModal(false);
            setEquipe({});
          }}
        />

        <Form
          captadora="A"
          equipe={equipe}
          empresa={empresa}
          onSuccess={loadEquipes}
          show={showModalEquipeCaptadora}
          notify={notify}
          close={() => {
            setShowModalEquipeCaptadora(false);
            setEquipe({});
          }}
        />

        <Form
          captadora={captadora}
          equipe={equipe}
          empresa={empresa}
          onSuccess={loadEquipes}
          show={showModal}
          notify={notify}
          close={() => {
            setShowModal(false);
            setEquipe({});
          }}
        />
      </div>

      <div>
        <Nav tabs>
          <NavItem style={{ cursor: "pointer", fontSize: "0.9rem" }}>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Equipes de Captação/Atendimento
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer", fontSize: "0.9rem" }}>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Equipes de Trabalho
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} style={{ paddingTop: "1.25rem" }}>
          <TabPane tabId="1">
            <Button
              color="primary"
              type="button"
              className="btn-icon btn-3"
              size="sm"
              onClick={() => {
                setEquipe({});
                toggleModal("A")
                // setShowModalEquipeCaptadora(!showModal);
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add"></i>
              </span>
              <span className="btn-inner--text">Novo</span>
            </Button>
            <List
              showMembros={handleShowAddMembro}
              itens={equipes.filter(equipe => equipe.captadora == 'A')}
              onSelected={handleEditEquipe}
              empresa={empresa}
              reload={loadEquipes}
              notify={notify}
            />
          </TabPane>
          <TabPane tabId="2">
            <Button
              color="primary"
              type="button"
              className="btn-icon btn-3"
              size="sm"
              onClick={() => {
                setEquipe({});
                toggleModal("I");
                // setShowModal(!showModal);
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add"></i>
              </span>
              <span className="btn-inner--text">Novo</span>
            </Button>
            <List
              showMembros={handleShowAddMembro}
              itens={equipes.filter(equipe => equipe.captadora == 'I')}
              onSelected={handleEditEquipe}
              empresa={empresa}
              reload={loadEquipes}
              notify={notify}
            />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};
