import React, { useContext, useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";
import TemplateModal from "../Modals/Templates";
import api from "../../../../../services/api";
import EmpresaContext from "../../../../../contexts/Empresa";
import { useWhatsappContext } from "../../../../../contexts/Whatsapp";

export default function Table() {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { selectedAccount } = useWhatsappContext();

  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [destinatario, setDestinatario] = useState<any>(null);
  const [clientes, setClientes] = useState<any>([]);

  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen((isOpen) => !isOpen);

  const [columns] = useState([
    {
      dataField: "nome",
      text: "Nome",
      sort: true,
    },
    {
      dataField: "numero",
      text: "Número",
      sort: true,
      formatter: (cell, row) => formatPhoneNumber(`${row.ddd}${row.numero}`),
    },
    {
      dataField: "acoes",
      formatter: (cell, row) => acoesFormatter(cell, row),
    },
  ]);

  async function buscarClientes(searchParam = "") {
    try {
      let query = new URLSearchParams();
      if (searchParam) {
        query.append("nome", searchParam);
        query.append("cpfCnpj", searchParam);
        query.append("email", searchParam);
        query.append("telefone[ddd]", searchParam.slice(0, 2));
        query.append("telefone[numero]", searchParam.slice(2));
      }

      const { data } = await api.get(
        `common/empresas/${empresaSelecionada?.id}/clientes?${query.toString()}`
      );

      setClientes(data.data);
      setIsSearching(false);
    } catch (error) {
      console.error({ error });
    }
  }

  useEffect(() => {
    buscarClientes();
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    if (!isSearching) setIsSearching(true);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      buscarClientes(newSearchTerm);
    }, 1000);

    setDebounceTimeout(timeout);
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length <= 2) {
      return phoneNumber;
    } else if (phoneNumber.length <= 10) {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        6
      )}-${phoneNumber.slice(6)}`;
    } else {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        7
      )}-${phoneNumber.slice(7)}`;
    }
  };

  function toggleTemplateModal(row) {
    setDestinatario({
      wa_id: `${row.ddd}${row.numero}`,
      account_id: selectedAccount?.id,
      cliente_id: row.id,
      name: row.nome,
    });
    toggleModal();
  }

  function acoesFormatter(cell, row) {
    return (
      <>
        <div className="btn-group" role="group">
          <Button
            className="btn-sm"
            color="primary"
            onClick={() => toggleTemplateModal(row)}
            outline
          >
            <i className="fas fa-clone"></i>
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <TemplateModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        clienteId={destinatario?.cliente_id}
        waId={destinatario?.wa_id}
        name={destinatario?.name}
      />
      <Label className="form-control-label">Meus clientes</Label>
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend" style={{ height: "36px" }}>
          <InputGroupText>
            <span>
              <i className="fas fa-search"></i>
            </span>
          </InputGroupText>
        </InputGroupAddon>
        <Input
          style={{ height: "36px" }}
          value={searchTerm}
          placeholder="Pesquise pelo nome ou número..."
          onChange={(event) => handleSearchChange(event)}
        />
        {isSearching && (
          <Spinner
            size="sm"
            color="primary"
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              zIndex: 10,
            }}
          />
        )}
      </InputGroup>
      <ToolkitProvider
        data={clientes}
        keyField="name"
        // @ts-ignore
        columns={columns}
        search
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
}
