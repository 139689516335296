import React, { useState, useEffect } from "react";
import api from "../../../../services/api";

import { Image } from "./styles";
import { Spinner } from "reactstrap";
import { Dropzone } from "../../../../components/Dropzone";

export default ({ empresa, notify, history, onEmpresaChange, ...props }) => {
  const [logo, setLogo] = useState(null);
  const [savingAvatar, setSavingAvatar] = useState(false);

  function dzCallback(acceptedFiles) {
    const newFile = acceptedFiles.map((file) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });
    
    if (newFile.length === 0) return;

    setLogo(newFile[0]);
    onEmpresaChange({
      ...empresa,
      logo: null,
      logo_url: newFile[0].preview,
    });
  }

  useEffect(() => {
    if (logo != null) saveLogo();
  }, [logo]);

  async function saveLogo() {
    setSavingAvatar(true);
    try {
      let formData = new FormData();
      formData.append("arquivo", logo);
      const response = await api.post(
        `/common/empresas/${empresa.id}/logos`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onEmpresaChange(response.data);
    } catch (error) {
      notify("danger", "Não foi possível alterar imagem");
    }
    setSavingAvatar(false);
  }

  return (
    <>
      <Dropzone callback={dzCallback} accept="image/*">
        <div
          title="Alterar foto"
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: "8px",
            marginBottom: "8px",
          }}
        >
          {empresa.logo && empresa.logo.length > 0 ? (
            <a href="#" onClick={(e) => e.preventDefault()}>
              <Image
                alt="logo"
                style={{ maxHeight: 50 }}
                src={`${empresa?.logo_url}?v=${Date.now()}`}
              />
            </a>
          ) : (
            <a
              className="avatar avatar-xl rounded-circle "
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              {empresa.nome.substring(0, 1)}
            </a>
          )}
          <Spinner hidden={!savingAvatar} color="light" size="md" />
        </div>
      </Dropzone>
    </>
  );
};
