import React, { useState, useEffect, useContext, useRef } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import Avatar from '../../../../../components/Avatar'
import { AxiosResponse } from 'axios';

import { SpinnerContainer } from '../styles'
// reactstrap components
import {
    Spinner,
    Button,
    Card,
    CardBody,
    CardHeader,
    Badge,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Progress,
    //Table,
    UncontrolledTooltip
} from "reactstrap";

import Table from './Table';
import SelectRankingMode from './SelectRankingMode';

function RankingColaboradores({ notify, mes, ano, equipe, colaborador, tipoProduto, unidade, periodo, setCardHeight }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [item, setItem] = useState({})
    const [loading, setLoading] = useState(false)

    const [dados, setDados] = useState([])
    const tableRef = useRef(null)

    const [rankingMode, setRankingMode] = useState('mensal')

    useEffect(() => {
        setCardHeight(tableRef.current.firstChild.clientHeight)
    }, [itens])

    const [pageProperties, setPageProperties] = useState({
        total: "0",
        perPage: 5,
        page: 1,
        lastPage: 1,
        loading: false,
        showSizePerPage: false
    })

    useEffect(() => {
        loadRanking()
    }, [mes, ano, equipe, colaborador, tipoProduto, unidade, periodo, rankingMode, empresaSelecionada])

    async function updatePageProperties(response) {
        const { total, perPage, page, lastPage } = await response.data
        setPageProperties({
            total,
            perPage,
            page,
            lastPage,
            loading: false,
            showSizePerPage: false
        })
    }

    async function loadRanking(page = 1,
        limit = 5,
        sortField = "vendas_colaborador",
        sortOrder = "desc") {
        try {
            setPageProperties({
                ...pageProperties,
                loading: true
            })
            const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/ranking-parcial-meta-colaboradores`, {
                params: {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    unidade,
                    tipoProduto,
                    getUser: 'T',

                    periodo: periodo && rankingMode == 'periodo' ? {
                        data_inicio: periodo.data_inicio,
                        data_fim: periodo.data_fim
                    } : null,

                    page,
                    limit,
                    sortField,
                    sortOrder
                }
            })
            setItens(response.data.data)
            //setItens(await response.data)
            await updatePageProperties(response)
        } catch (err) {
            //@ts-ignore
            console.log(err.response)
            notify('danger', 'Houve um problema ao carregar o Ranking Load.');
        }
    }

    async function handleTableChange(type, sortProperties) {
        try {
            await loadRanking(
                sortProperties.page == 0 ? 1 : sortProperties.page,
                sortProperties.sizePerPage,
                sortProperties.sortField,
                sortProperties.sortOrder)
        } catch (error) {
            notify('danger', 'Houve um problema ao carregar o Ranking Handle.');
        }
    }

    return (
        <span ref={tableRef}>
            <Card style={{ minHeight: '365px' }}>
                <div>
                    <CardHeader>
                        <h5 class="h3 mb-0">Ranking</h5>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h6 class="surtitle">Ranking de Vendas por Colaboradores</h6>
                            {
                                SelectRankingMode(rankingMode, setRankingMode, periodo)
                            }
                        </div>

                    </CardHeader>

                </div>
                <CardBody>

                    <div className="">
                        {
                            loading ?
                                <SpinnerContainer>
                                    <Spinner
                                        color='primary'
                                        size='lg'
                                        style={{
                                            alignSelf: 'center'
                                        }}
                                    />
                                </SpinnerContainer>
                                :
                                <Table
                                    colaboradores={itens || []}
                                    unidade
                                    notify={notify}
                                    onTableChange={handleTableChange}
                                    pageProperties={pageProperties}
                                    loading={loading}
                                />

                        }
                    </div>

                </CardBody>
            </Card>
        </span>

    );

}

export default RankingColaboradores;