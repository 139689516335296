import React, { useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { MenuComportamento } from "../../../components/Menus";
import Table from "../../../components/Table";
import api from "../../../services/api";

// @ts-ignore
export default ({
  data,
  pageProperties,
  onTableChange,
  notify,
  oportunidade,
  callback,
  ...props
}: any) => {
  const [clienteSelecionado, setClienteSelecionado] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const [columns] = useState([
    {
      dataField: "acoes",
      formatter: (cell: any, row: any) => acoesFormatter(cell, row),
    },
    {
      dataField: "proposta",
      text: "Nr Venda",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "pessoa",
      text: "Cliente",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "modelo",
      text: "Produto",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "emissao_proposta",
      text: "Venda",
      formatter: (cell: any, row: any) => dateFormater(row.emissao_proposta),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_baixa",
      text: "Faturamento",
      formatter: (cell: any, row: any) => dateFormater(row.data_baixa),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_cancelamento",
      text: "Cancelamento",
      formatter: (cell: any, row: any) => dateFormater(row.data_cancelamento),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "proposta_loja",
      text: "Valor Proposto",
      formatter: (cell: any, row: any) => valorFormatter(row.proposta_loja),
      align: "center",
      headerAlign: "center",
    },
  ]);

  async function vincularProposta() {
    try {
      await api.post(
        `/vincular-proposta`,
        {
          proposta_id: clienteSelecionado.proposta,
          oportunidade_id: oportunidade.id,
        }
      );

      callback();
      setShowModal(false);
    } catch (error) {
      console.log(error);
      if (notify) notify("danger", "Não foi possível vincular a proposta.");
    }
  }

  function goToEdit(id: number) {
    props.history.push(`/admin/clientes/${new Number(id)}/edit`);
  }

  function valorFormatter(num: any) {
    return (
      <>
        {Number(num).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}
      </>
    );
  }

  function dateFormater(date: Date) {
    if (!date) {
      return "";
    }
    const dt = new Date(date);
    var options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return dt.toLocaleDateString("pt-br", options);
  }

  function toggleModal(cliente: any) {
    setClienteSelecionado(cliente);
    setShowModal(true);
  }

  const acoesFormatter = (row: any, context: any) => {
    return (
      <MenuComportamento
        actions={[
          {
            label: "Detalhar",
            icon: "far fa-edit",
            onClick: () => {
              toggleModal(context);
            },
          },
        ]}
      />
    );
  };

  function formatPhone(phoneNumber: any) {
    return phoneNumber
      .replace(/\D/g, "")
      .replace(/^(\d{2})\B/, "($1) ")
      .replace(/(\d{1})?(\d{4})(\d{4})/, "$1$2-$3");
  }

  return (
    <>
      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
      >
        <ModalHeader>
          <Row>
            <Col>
              <strong>Número da Venda: </strong>
              <span>#{clienteSelecionado?.proposta}</span>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <Row className="mb-2 pb-2 border-bottom">
            <Col>
              <strong>Cliente</strong>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <strong>Nome/Razão Social: </strong>
              <span>{clienteSelecionado?.pessoa}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <strong>CPF/CNPJ: </strong>
              <span>{clienteSelecionado?.cpf_cnpj}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <strong>Vendedor: </strong>
              <span>{clienteSelecionado?.vendedor || "Não Encontrado"}</span>
            </Col>
          </Row>
          <Row className="mb-2 mt-4 pb-2 border-bottom">
            <Col>
              <strong>Venda</strong>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <strong>Produto: </strong>
              <span>{clienteSelecionado?.modelo}</span>
            </Col>
            <Col>
              <strong>Valor Proposto: </strong>
              <span>{valorFormatter(clienteSelecionado?.proposta_loja)}</span>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <strong>Venda: </strong>
              <span>{dateFormater(clienteSelecionado?.emissao_proposta)}</span>
            </Col>
            <Col>
              <strong>Faturamento: </strong>
              <span>{dateFormater(clienteSelecionado?.data_baixa)}</span>
            </Col>
          </Row>
          <Row className="mb-2 pb-2">
            <Col>
              <strong>Cancelamento: </strong>
              <span>{dateFormater(clienteSelecionado?.data_cancelamento)}</span>
            </Col>
          </Row>

          <Row className="mb-2 mt-4 pb-2 border-bottom">
            <Col>
              <strong>Contatos</strong>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <strong>Email: </strong>
              <span>{clienteSelecionado?.email}</span>
            </Col>
            <Col>
              <strong>Residencial: </strong>
              <span>{clienteSelecionado?.residencial}</span>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <strong>Comercial: </strong>
              <span>{clienteSelecionado?.comercial}</span>
            </Col>
            <Col>
              <strong>Celular: </strong>
              <span>{clienteSelecionado?.celular}</span>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowModal(!showModal)}>
            Voltar
          </Button>
          <Button
            color="primary"
            onClick={vincularProposta}
          >
            Vincular Venda
          </Button>
        </ModalFooter>
      </Modal>

      {/* @ts-ignore */}
      <Table
        columns={columns}
        data={data}
        pageProperties={pageProperties}
        onTableChange={onTableChange}
      />
    </>
  );
};
