import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "../../../../contexts/Auth";
import api from "../../../../services/api";
import { usePersistedState } from '../../../../hooks/index'

import Select2 from "react-select2-wrapper";
import Filters from '../Filters'
import {
    Col,
    FormGroup,
    InputGroup,
} from "reactstrap";
import moment from 'moment';

export default ({ title, notify, load, publicacoes, filtersPrioridades = {}, ...props }) => {

    const { hasPermission } = useContext(AuthContext)
    const [empresasList, setEmpresasList] = useState([])
    const [empresas, setEmpresas] = usePersistedState('empresas', [])
    const [designers, setDesigners] = usePersistedState('designers', [])
    //const [designersSelecionados, setDesignerSelecionados] = useState([])
    const [designersSelecionados, setDesignerSelecionados] = usePersistedState('designersSelecionados', getDesignersSelecionados())
    //Flag para definir tempo de execução
    const [runLoad] = useState(props.search ? props.search : true)


    useEffect(() => {
        if (empresasList.length === 0) loadEmpresas()
    }, [])

    useEffect(() => {
        if (hasPermission('ver-todas-publicacoes')) loadDesigners()
    }, [empresas])

    async function loadEmpresas() {
        try {
            const response = await api.get('security/sessions/empresas')
            if (response.data) {
                setEmpresasList(response.data)
            }
        } catch (error) {
            console.log(error)
            throwError('Não foi possível carregar empresas')
        }
    }

    function throwError(text) {
        if (notify) notify('danger', text)
    }

    async function handlePesquisar() {
        await search(true)
    }

    async function search(filters, clearPagination) {

        const filtersPrioridades = {
            ...filters,
            empresas,
            designers: designersSelecionados
        }

        await load({
            ...filtersPrioridades
        }, clearPagination)
    }

    function getDesignersSelecionados() {
        if ('designers' in filtersPrioridades) {
            let data = filtersPrioridades.designers
            return data
        } else {
            return []
        }
    }

    async function loadDesigners() {
        try {
            const response = await api.get(`/common/designers`,
                {
                    params: {
                        empresas
                    }
                })
            const data = await response.data
            setDesigners(data)
            setDesignerSelecionados(designersSelecionados)
        } catch (err) {
            notify('danger', 'Não foi possível carregar designers da empresa.')
        }
    }

    function handleMultipleSelect(target, state) {
        const array = Array.from(target.selectedOptions)
        state(array.map((item) => item.value))
    }

    async function clearFiltersPrioridades() {
        setDesignerSelecionados([]);
        setEmpresas([]);
    }

    return (
        <>
            <Filters
                title={<h1>Publicações vs Prioridades</h1>}
                data={publicacoes}
                filters={{
                    dataAgendamentoInicial: moment().subtract(15, 'day'),
                    dataAgendamentoFinal: moment().add(15, 'days'),
                    fases: [2, 3],
                }}
                load={(filters, clearPagination) => {
                    search(filters, clearPagination)
                }}
                search={runLoad}
                clearFiltersPrioridades={clearFiltersPrioridades}
            >
                <Col xs="12" lg="6" sm="12" md="12">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example-number-input"
                        >
                            Empresas
                            </label>
                        <InputGroup className="input-group-alternative">
                            <Select2
                                multiple
                                onSelect={(e) => handleMultipleSelect(e.target, setEmpresas)}
                                onUnselect={(e) => handleMultipleSelect(e.target, setEmpresas)}
                                options={{
                                    placeholder: "Selecione uma empresa..."
                                }}
                                value={empresas}
                                data={empresasList.map((item) => ({ id: item.id, text: item.nome }))}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                {
                    hasPermission('ver-todas-publicacoes') &&

                    <Col xs="12" lg="6" sm="12" md="12">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="example-number-input"
                            >
                                Designers
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    multiple
                                    onSelect={(e) => handleMultipleSelect(e.target, setDesignerSelecionados)}
                                    onUnselect={(e) => handleMultipleSelect(e.target, setDesignerSelecionados)}
                                    options={{
                                        placeholder: "Selecione os designers..."
                                    }}
                                    value={designersSelecionados}
                                    data={designers.map((item) => ({ id: item.id, text: item.name }))}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                }

            </Filters>
        </>
    );
}
