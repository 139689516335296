import React, { useState, useEffect, useRef } from 'react';

import Select2 from 'react-select2-wrapper';
import NotificationAlert from 'react-notification-alert';
import {
  Button,
  FormGroup,
  Modal,
  Row,
  Col,
  InputGroupAddon,
  InputGroup,
} from 'reactstrap';
import api from '../../../../services/api';

export default function AdicionarGrupoModal({
  empresas,
  onClose,
  onSuccess,
  show,
  notify,
}: any) {
  const [empresaId, setEmpresaId] = useState<any>();

  async function handleAddEmpresaGrupo() {
    if (!empresaId) return;
    try {
      await api.patch(
        `common/empresas/empresas-grupo/update`, {
          data: {
            empresa_filial_id: empresaId
          }
        }
      );

      onSuccess() // reload da tabela
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={show}
      toggle={onClose}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Adicionar novo empresa ao grupo
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col
            lg={12}
            md={12}
            style={{
              flexDirection: 'column',
              flex: 1,
              display: 'flex',
            }}
          >
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example-number-input"
              >
                Empresas
              </label>
              <InputGroup className="mb-3">
                <Select2
                  maximumInputLength={10}
                  className="form-control"
                  data={empresas?.map((item) => ({
                    id: item.id,
                    text: item.nome,
                  }))}
                  onSelect={(e) => setEmpresaId(e.target.value)}
                  options={{
                    placeholder: 'Empresas...',
                  }}
                  value={empresaId}
                />
              </InputGroup>
            </FormGroup>
            <div style={{ alignSelf: 'center' }}>
              <Button
                color="primary"
                type="button"
                outline
                disabled
                onClick={handleAddEmpresaGrupo}
                className="btn-icon btn-3"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add"></i>
                </span>
                <span className="btn-inner--text">Adicionar</span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Row>
          <Col>
            <div className="float-right ">
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={onClose}
              >
                Fechar
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
