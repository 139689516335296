import React, { useState, useRef, useContext, useEffect } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import { AxiosResponse } from 'axios';
import { useHistory } from "react-router-dom";
import api from "../../../../services/api";
//@ts-ignore
import NotificationAlert from "react-notification-alert";

import { authStorageKey, empresaStorageKey } from "../../../../utils";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from '../../../../components/Headers/SimpleHeader'

const PainelConfiguracoes: React.FC = ({ }) => {

  const history = useHistory()
  const { empresaSelecionada } = useContext(EmpresaContext)
  const [dadosPainel, setDadosPainel] = useState(null)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(null)

  const notificationAlert = useRef<NotificationAlert>(null);

  useEffect(() => {
    if (!dadosPainel) loadDados()
  }, [])

  async function loadDados() {
    try {
      const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/dadospainel`)
      const data = await response.data
      setDadosPainel(data.dados)
    } catch (error) {
      console.log(error)
      notify('danger', 'Não foi possível carregar os dados do painel de configuração')
    }

  }

  function goToEscolherPlano() {
    //notify('success', 'Em breve está função estará implementada!')
    var url = ``
    url = `https://playnee.com/planos-precos.html?empresa_id=${empresaSelecionada?.id}`
    //teste local
    //url = `http://127.0.0.1:5500/planos-precos.html?empresa_id=${this.getIdEmpresaLogada()}`
    window.open(url)
  }

  function goToEditTipoProdutos() {
    history.push(`/admin/meus-produtos`, { externaTabActive: 'tipo_produto' })
  }

  function goToEditProdutos() {
    history.push(`/admin/meus-produtos`, { externaTabActive: 'produtos' })
  }

  function goToEditCampanhas() {
    history.push(`/admin/campanhas`)
  }

  function goToEditEquipe() {
    history.push(`/admin/equipes`, { externaTabActive: 'equipes' })
  }

  function validarCheckDados(item: String) {
    switch (item) {
      case 'equipes':
        //@ts-ignore
        if (dadosPainel && dadosPainel.equipes && parseInt(dadosPainel.equipes) > 0) {
          return true
        }
        break;
      case 'tiposProduto':
        //@ts-ignore
        if (dadosPainel && dadosPainel.tipos_produtos && parseInt(dadosPainel.tipos_produtos) > 0) {
          return true
        }
        break;

      case 'produtos':
        //@ts-ignore
        if (dadosPainel && dadosPainel.produtos && parseInt(dadosPainel.produtos) > 0) {
          return true
        }
        break;

      case 'anuncios':
        //@ts-ignore
        if (dadosPainel && dadosPainel.anuncios && parseInt(dadosPainel.anuncios) > 0) {
          return true
        }
        break;
    }

    return false
  }


  function goToEditColaboradores() {
    history.push(`/admin/equipes`, { externaTabActive: 'users' })
  }

  function goToEditDestinos() {
    history.push(`/admin/integracoes`, { externaTabActive: 'facebookAds' })
  }

  function goToEditFunis() {
    history.push(`/admin/configuracoes-vendas`, { externaTabActive: 'funis' })
  }

  function goToLink1() {
    window.open('https://playnee.com/ebook-ep1/', '_blank');
  }

  function goToLink2() {
    window.open('https://playnee.com/ebook-ep2/', '_blank');
  }

  function goToPlayneeAcademy() {
    //var href = `${propetiesAcademy.path}${propetiesAcademy.alias === 'treinamento_playnee' ? '?tokenPlaynee=' + this.getToken() + '&idPerfilNegocio=' + this.getPerfilNegocioEmpresaLogada() : ''}`
    let link = `https://academy.playnee.com.br/app/login.html?tokenPlaynee=${getToken()}&idPerfilNegocio=${getPerfilNegocioEmpresaLogada()}`
    window.open(link, '_blank');
  }

  function getToken() {
    let auth = localStorage.getItem(authStorageKey)
    if (auth) {
      const authObj = JSON.parse(auth)
      return authObj.token
    }
  }

  function getPerfilNegocioEmpresaLogada() {
    let empresa = localStorage.getItem(empresaStorageKey)
    if (empresa) {
      const empresaObj = JSON.parse(empresa)
      return empresaObj.perfil_negocio_id
    }
  }

  function validarAssinatura() {
    if (empresaSelecionada) {
      if (!empresaSelecionada.assinatura_id) {
        if (empresaSelecionada.assinatura_status != 'externa') {
          return false
        } else {
          return true
        }
      } else {
        if (empresaSelecionada.assinatura_status != 'ativa' && empresaSelecionada.assinatura_status != 'authorized') {
          return false
        } else {
          return true
        }
      }
    } else {
      return false
    }
  }

  function validarPeriodoTesteGratis() {
    if (empresaSelecionada && empresaSelecionada.id) {
      if (empresaSelecionada.dias_cadastro || empresaSelecionada.dias_cadastro == 0) {
        if (empresaSelecionada.periodo_cortesia || empresaSelecionada.periodo_cortesia == 0) {
          if (empresaSelecionada.periodo_cortesia < 0 || (empresaSelecionada.dias_cadastro < empresaSelecionada.periodo_cortesia)) {
            return true
          }
        }
      }
    }
    return false
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {msg}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    notificationAlert?.current?.notificationAlert(options);
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="" parentName="Painel de Configurações" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                }}>
                <h1>Painel de Configurações</h1>
              </CardHeader>
              <CardBody>
                {!validarAssinatura()
                  &&
                  <Row style={{ paddingBottom: 30 }}>
                    <Col xs="12" lg="12" sm="12" md="12">
                      <div style={{ height: 60, borderStyle: 'solid', borderWidth: 1, borderColor: validarPeriodoTesteGratis() ? '#0847d6' : 'red', borderTop: `5px ${validarPeriodoTesteGratis() ? '#0847d6' : 'red'} solid`, display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fas fa-info-circle" style={{ marginLeft: 10, color: validarPeriodoTesteGratis() ? '#0847d6' : 'red', fontSize: 25 }}></i>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h3 style={{ marginLeft: 10, marginBottom: 0, color: validarPeriodoTesteGratis() ? '#0847d6' : 'red' }}>
                              {
                                validarPeriodoTesteGratis() ?
                                  (
                                    // @ts-ignore
                                    empresaSelecionada?.periodo_cortesia < 0 ?
                                      `Seu teste grátis terminará em breve!`
                                      :
                                      // @ts-ignore
                                      `Seu teste grátis termina em ${empresaSelecionada?.periodo_cortesia - empresaSelecionada?.dias_cadastro} dias!`
                                  )
                                  :
                                  `Seu teste grátis acabou!`
                              }
                            </h3>
                            <h5 style={{ marginLeft: 10, marginBottom: 0 }}>
                              {
                                validarPeriodoTesteGratis() ?
                                  `Seu período de teste grátis terminará em breve. Escolha algum dos nossos planos e não pare o crescimento da sua empresa.`
                                  :
                                  `Seu período de teste grátis terminou. Escolha algum dos nossos planos para continuar o crescimento da sua empresa.`
                              }

                            </h5>
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            style={{ height: 40, marginRight: 10, minWidth: 120 }}
                            color={validarPeriodoTesteGratis() ? "primary" : "danger"}
                            type="button"
                            onClick={goToEscolherPlano}
                            size="sm">
                            Escolher Plano
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                }

                <Row>
                  <Col xs="12" lg="12" sm="12" md="12">
                    <div>
                      <div>
                        <h3>Bem vindo ao Playnee. É aqui onde começa o seu crescimento.</h3>
                        <h4 style={{ marginLeft: 20, marginBottom: 0 }}>Comece por essas tarefas para configurar a sua empresa:</h4>
                      </div>

                      <div style={{ display: 'flex', marginLeft: 20 }}>

                        <div onClick={goToEditEquipe} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', borderStyle: 'solid', borderWidth: 3, borderColor: '#dfdfdf', width: 125, height: 100, marginTop: 10, marginRight: 10, cursor: 'pointer' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 10, marginRight: 10 }} >
                            {/* @ts-ignore */}
                            <i className="fas fa-user-friends" style={{ color: validarCheckDados('equipes') ? '#dfdfdf' : '#0847d6' }}></i>
                            {/* @ts-ignore */}
                            <i className="far fa-check-circle" style={{ color: validarCheckDados('equipes') ? '#008000' : '#dfdfdf' }}></i>
                          </div>
                          <h4 style={{ marginLeft: 10, marginBottom: 0, lineHeight: '16px' }}>Configurar Equipe</h4>
                        </div>

                        <div onClick={goToEditTipoProdutos} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', borderStyle: 'solid', borderWidth: 3, borderColor: '#dfdfdf', width: 125, height: 100, marginTop: 10, marginRight: 10, cursor: 'pointer' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 10, marginRight: 10 }} >
                            {/* @ts-ignore */}
                            <i className="fas fa-box" style={{ color: validarCheckDados('tiposProduto') ? '#dfdfdf' : '#0847d6' }}></i>
                            {/* @ts-ignore */}
                            <i className="far fa-check-circle" style={{ color: validarCheckDados('tiposProduto') ? '#008000' : '#dfdfdf' }}></i>
                          </div>
                          <h4 style={{ marginLeft: 10, marginBottom: 0, lineHeight: '16px' }}>Tipos de Produtos</h4>
                        </div>

                        <div onClick={goToEditProdutos} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', borderStyle: 'solid', borderWidth: 3, borderColor: '#dfdfdf', width: 125, height: 100, marginTop: 10, marginRight: 10, cursor: 'pointer' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 10, marginRight: 10 }} >
                            {/* @ts-ignore */}
                            <i className="fas fa-box" style={{ color: validarCheckDados('produtos') ? '#dfdfdf' : '#0847d6' }}></i>
                            {/* @ts-ignore */}
                            <i className="far fa-check-circle" style={{ color: validarCheckDados('produtos') ? '#008000' : '#dfdfdf' }}></i>
                          </div>
                          <h4 style={{ marginLeft: 10, marginBottom: 0, lineHeight: '16px' }}>Adicionar Produtos</h4>
                        </div>

                        <div onClick={goToEditCampanhas} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', borderStyle: 'solid', borderWidth: 3, borderColor: '#dfdfdf', width: 125, height: 100, marginTop: 10, marginRight: 10, cursor: 'pointer' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 10, marginRight: 10 }} >
                            {/* @ts-ignore */}
                            <i className="fas fa-bullhorn" style={{ color: validarCheckDados('anuncios') ? '#dfdfdf' : '#0847d6' }}></i>
                            {/* @ts-ignore */}
                            <i className="far fa-check-circle" style={{ color: validarCheckDados('anuncios') ? '#008000' : '#dfdfdf' }}></i>
                          </div>
                          <h4 style={{ marginLeft: 10, marginBottom: 0, lineHeight: '16px' }}>Configurar campanhas & anúncios</h4>
                        </div>

                      </div>

                    </div>
                  </Col>
                </Row>

                <Row style={{ paddingTop: 30 }}>
                  <Col xs="12" lg="12" sm="12" md="12">
                    <div>
                      <div>
                        <h3>Outras configurações para sua empresa</h3>
                      </div>

                      <div style={{ marginLeft: 20, marginBottom: 10, minHeight: 80, borderStyle: 'solid', borderWidth: 3, borderColor: '#dfdfdf', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fas fa-share-alt" style={{ marginLeft: 10, color: '#0847d6', fontSize: 25 }}></i>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h3 style={{ marginLeft: 10, marginBottom: 0 }}>Integrar conta do Facebook</h3>
                            <h5 style={{ marginLeft: 10, marginBottom: 0, maxWidth: 720 }}>
                              Crie campanha de <b>Geração de Cadastro</b> no <b>Facebook Business</b> e integre direto ao Playnee.
                              As oportunidades captadas por suas campanhas lá serão automaticamente sincronizadas.
                            </h5>
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            style={{ height: 40, marginRight: 10, minWidth: 120 }}
                            color="primary"
                            type="button"
                            onClick={goToEditDestinos}
                            size="sm">
                            Integrar
                          </Button>
                        </div>
                      </div>

                      <div style={{ marginLeft: 20, marginBottom: 10, minHeight: 80, borderStyle: 'solid', borderWidth: 3, borderColor: '#dfdfdf', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fas fa-filter" style={{ marginLeft: 10, color: '#0847d6', fontSize: 25 }}></i>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h3 style={{ marginLeft: 10, marginBottom: 0 }}>Configurar Funil de Vendas</h3>
                            <h5 style={{ marginLeft: 10, marginBottom: 0, maxWidth: 720 }}>
                              É possível configurar um ou mais <b>Funis de Venda</b> para o seu negócio de forma simples.
                            </h5>
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            style={{ height: 40, marginRight: 10, minWidth: 120 }}
                            color="primary"
                            type="button"
                            onClick={goToEditFunis}
                            size="sm">
                            Configurar
                          </Button>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>

                <Row style={{ paddingTop: 30 }}>
                  <Col xs="12" lg="12" sm="12" md="12">
                    <div>
                      <div>
                        <h3>Alguns conteúdos do Playnee</h3>
                      </div>

                      <div style={{ marginLeft: 20, marginBottom: 10, minHeight: 80, borderStyle: 'solid', borderWidth: 3, borderColor: '#dfdfdf', display: 'flex', justifyContent: 'space-between' }}>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fas fa-sort-amount-up" style={{ marginLeft: 10, color: '#0847d6', fontSize: 25 }}></i>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h3 style={{ marginLeft: 10, marginBottom: 0 }}>Como vender nas redes sociais</h3>
                            <h5 style={{ marginLeft: 10, marginBottom: 0, maxWidth: 720 }}>
                              O passo a passo, do básico ao avançado, de como você abordar o mundo digital de forma estratégica para que consiga alavancar suas vendas!
                            </h5>
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            style={{ height: 40, marginRight: 10, minWidth: 120 }}
                            color="primary"
                            type="button"
                            onClick={goToLink1}
                            size="sm">
                            Acessar
                          </Button>
                        </div>
                      </div>

                      <div style={{ marginLeft: 20, marginBottom: 10, minHeight: 80, borderStyle: 'solid', borderWidth: 3, borderColor: '#dfdfdf', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fas fa-search-dollar" style={{ marginLeft: 10, color: '#0847d6', fontSize: 25 }}></i>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h3 style={{ marginLeft: 10, marginBottom: 0 }}>Resultado acima de R$ 5.000,00</h3>
                            <h5 style={{ marginLeft: 10, marginBottom: 0, maxWidth: 720 }}>
                              Técnicas de venda para profissionais que estão na busca pelo crescimento.
                              E-book 100% online e gratuito.
                            </h5>
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            style={{ height: 40, marginRight: 10, minWidth: 120 }}
                            color="primary"
                            type="button"
                            onClick={goToLink2}
                            size="sm">
                            Acessar
                          </Button>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>

                <Row style={{ paddingTop: 30 }}>
                  <Col xs="12" lg="12" sm="12" md="12">
                    <div>
                      <div>
                        <h3>Curso do Playnee Academy recomendados</h3>
                      </div>

                      <div style={{ marginLeft: 20, marginBottom: 10, minHeight: 80, borderStyle: 'solid', borderWidth: 3, borderColor: '#dfdfdf', display: 'flex', justifyContent: 'space-between' }}>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fas fa-graduation-cap" style={{ marginLeft: 10, color: '#0847d6', fontSize: 25 }}></i>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h3 style={{ marginLeft: 10, marginBottom: 0 }}>Gestão Comercial e em Marketing</h3>
                            <h5 style={{ marginLeft: 10, marginBottom: 0, maxWidth: 720 }}>
                              Descubra as áreas de marketing comercial e se prepare para sua jornada de transformação.
                            </h5>
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            style={{ height: 40, marginRight: 10, minWidth: 120 }}
                            color="primary"
                            type="button"
                            onClick={goToPlayneeAcademy}
                            size="sm">
                            Acessar
                          </Button>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PainelConfiguracoes;