import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../contexts/Empresa'
import api from "../../../services/api";

import Form from "./Form"

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

export default ({ match, location, ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [id, setId] = useState(null)
    const [clone, setClone] = useState(null)
    const [anuncio, setAnuncio] = useState(null)
    const [onBackAnuncio, setOnBackAnuncio] = useState(false)

    useEffect(() => {
        const { state } = location
        if (state?.toClone) {
            loadClone(state?.toClone)
        }

        if (state?.onBackAnuncio) {
            setOnBackAnuncio(state?.onBackAnuncio)
        }

        if (state?.toAnuncio) {
            setAnuncio(state?.toAnuncio)
        }
    }, []) 


    async function loadClone(publicacaoId) {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}`);
            setClone(response.data)
        } catch (err) {
            console.error(err);
            this.notify("danger", "Não foi possível carregar publicacao.");
        }
    }

    return (
        <>
            <SimpleHeader
                name="Nova Publicação"
                parentName="Publicações"
                {...props} />
            <Form
                {...props}
                publicacaoId={id}
                anuncio={anuncio}                
                clone={clone}
                onBackAnuncio={onBackAnuncio}
                anuncioToBack={anuncio}
            />
        </>
    )


}
