import React, { useState, useEffect } from 'react';
import Table from '../../../../components/Table';
import Preview from '../../Publicacoes/Preview';
import moment from 'moment';
import MovimentacaoFunil from './chart/MovimentacaoFunil';

import {
  Spinner,
  Button,
  UncontrolledTooltip,
  Modal,
  Row,
  Col,
} from 'reactstrap';

import './styles.css';

export default ({ itens, pageProperties, notify, lastSearch, ...props }) => {
  const headerStyle = { fontSize: 9 };

  const [publicacaoPreviewId, setPublicacaoPreviewId] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const [publicacaoMovimentacao, setPublicacaoMovimentacao] = useState(null);
  const [showModalMovimentacao, setShowModalMovimentacao] = useState(false);

  function handleCloseModalMovimentacao() {
    setShowModalMovimentacao(false);
    setPublicacaoMovimentacao(null);
  }

  const [columns] = useState([
    {
      dataField: 'publicacao_preview',
      formatter: (cell, row) =>
        publicacaoPreviewFormatter(
          cell,
          row,
          setPublicacaoPreviewId,
          setShowPreview
        ),
      headerStyle: (colum, colIndex) => {
        return { paddingLeft: 10, paddingRight: 10, textAlign: 'center' };
      },
      style: (colum, colIndex) => {
        return { paddingLeft: 10, paddingRight: 10, textAlign: 'center' };
      },
    },
    {
      dataField: 'movimentacao_funil',
      formatter: (cell, row) =>
        movimentacaoFunilFormatter(
          cell,
          row,
          lastSearch,
          setPublicacaoMovimentacao,
          setShowModalMovimentacao
        ),
      headerStyle: (colum, colIndex) => {
        return {
          paddingLeft: 10,
          paddingRight: 10,
          width: 50,
          textAlign: 'center',
        };
      },
      style: (colum, colIndex) => {
        return {
          paddingLeft: 10,
          paddingRight: 10,
          width: 50,
          textAlign: 'center',
        };
      },
    },
    {
      dataField: 'titulo',
      text: 'Título',
      // headerFormatter: headerFormatter,
      // headerStyle,
      sort: true,
    },
    {
      dataField: 'oportunidades_total',
      text: 'Oportunidades',
      // headerFormatter: headerFormatter,
      headerAlign: 'center',
      align: 'center',
      // headerStyle,
      sort: true,
    },
    {
      dataField: 'anuncio',
      text: 'Anúncio',
      // headerFormatter: headerFormatter,
      headerAlign: 'center',
      align: 'center',
      // headerStyle,
      sort: true,
    },
    {
      dataField: 'campanha',
      text: 'Campanha',
      // headerFormatter: headerFormatter,
      headerAlign: 'center',
      align: 'center',
      // headerStyle,
      sort: true,
    },
    {
      dataField: 'data_postagem',
      text: 'Data Postagem',
      // headerFormatter: headerFormatter,
      formatter: (row, column) => dataFormatter(row, column),
      align: 'center',
      headerAlign: 'center',
      sort: true,
    },
  ]);

  function publicacaoPreviewFormatter(
    cell,
    row,
    setPublicacaoPreviewId,
    setShowPreview
  ) {
    return (
      <>
        <div
          id={`column-public-${row.id}`}
          class="btn-group"
          role="group"
          aria-label="Basic example"
        >
          <Button
            className="btn-sm"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            color="secondary"
            onClick={() =>
              goToPreviewPublicacao(row, setPublicacaoPreviewId, setShowPreview)
            }
            outline
          >
            <i class="far fa-eye"></i>
          </Button>
          <UncontrolledTooltip
            target={`column-public-${row.id}`}
            placement="top"
          >
            Ver Publicação
          </UncontrolledTooltip>
        </div>
      </>
    );
  }

  function goToPreviewPublicacao(row, setPublicacaoPreviewId, setShowPreview) {
    console.log('ABRIR PREVIEW');
    console.log({ row });
    setPublicacaoPreviewId(row.id);
    setShowPreview(true);
  }

  function movimentacaoFunilFormatter(
    cell,
    row,
    lastSearch,
    setFuncionarioMovimentacao,
    setShowModalMovimentacao
  ) {
    return (
      <>
        <div
          id={`column-func-${row.colaborador_id}`}
          class="btn-group"
          role="group"
          aria-label="Basic example"
        >
          <Button
            className="btn-sm"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            color="secondary"
            onClick={() =>
              goToGraficoMovimentacao(
                row,
                lastSearch,
                setFuncionarioMovimentacao,
                setShowModalMovimentacao
              )
            }
            outline
          >
            <i class="fa fa-filter"></i>
          </Button>
          <UncontrolledTooltip
            target={`column-func-${row.colaborador_id}`}
            placement="top"
          >
            Movimentação no Funil
          </UncontrolledTooltip>
        </div>
      </>
    );
  }

  function goToGraficoMovimentacao(
    row,
    lastSearch,
    setFuncionarioMovimentacao,
    setShowModalMovimentacao
  ) {
    // console.log('ABRIR MODAL GRAFICO');
    // console.log({ row });
    // console.log({ lastSearch });
    setFuncionarioMovimentacao(row);
    setShowModalMovimentacao(true);
  }

  function headerFormatter(column) {
    return (
      <>
        <div id={`column-${column.text}`}>
          <div dangerouslySetInnerHTML={{ __html: column.text }} />
        </div>
      </>
    );
  }

  const dataFormatter = (cell, row) => {
    return <>{moment(row.data_postagem).format('D MMM YYYY HH:mm')}</>;
  };

  return (
    <>
      <Preview
        isOpen={showPreview}
        showHistorico={true}
        readOnly={true}
        close={() => {
          setShowPreview(!showPreview);
          setPublicacaoPreviewId(null);
        }}
        publicacaoId={publicacaoPreviewId}
      />

      {/*Modal Movimentacao*/}
      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={showModalMovimentacao}
        toggle={() => handleCloseModalMovimentacao()}
      >
        <div className="modal-header" style={{ paddingBottom: 0 }}>
          <h5 className="modal-title" id="exampleModalLabel">
            Movimentação do Funil
            <br />
            <small className="text-muted" title={`Id e Título da Publicação`}>
              {publicacaoMovimentacao
                ? `#${publicacaoMovimentacao.id} | ${publicacaoMovimentacao.titulo}`
                : ''}
            </small>
          </h5>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModalMovimentacao}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingTop: 5 }}>
          <Row className="mt-4">
            <Col lg="12" md="12" sm="12" xs="12">
              <MovimentacaoFunil
                notify={notify}
                mes={1}
                ano={2022}
                equipe={null}
                colaborador={null}
                unidade={null}
                periodo={null}
                tipoProduto={null}
                lastSearch={lastSearch}
                publicacaoMovimentacao={publicacaoMovimentacao}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      {itens && pageProperties ? (
        <Table
          columns={columns}
          data={itens}
          pageProperties={pageProperties}
          onTableChange={props.onTableChange}
          sort={{
            sortFunc: (a, b, order) => {
              if (order === 'asc') return a - b;
              else return b - a;
            },
          }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          {/* <Spinner
                            color='primary'
                        /> */}
          Sem dados para exibir.
        </div>
      )}
    </>
  );
};
