import React, { useState, useEffect, useContext, useRef } from "react";
import EmpresaContext from "../../../../../contexts/Empresa";
import AuthContext from "../../../../../contexts/Auth";
import api from "../../../../../services/api";
//@ts-ignore
import Select2 from "react-select2-wrapper";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import { ApiAccess } from "../../../../../entities/Security";

import {
  Button,
  Row,
  Col,
  Modal,
  ListGroupItem,
  ListGroup,
  FormGroup,
  ButtonGroup,
  Label,
  Input,
} from "reactstrap";
import Anuncio from "../../../../../entities/Marketing/Anuncio";
import { User } from "../../../../../entities/Security";
import { Cliente } from "../../../../../entities/Common";
import { Empresa } from "../../../../../entities/Common";

interface Props {
  anuncio: Anuncio;
  show: boolean;
  onClose: any;
}

const ProgramaDeAfiliacao: React.FC<Props> = ({
  anuncio,
  show = false,
  onClose = () => {},
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { auth } = useContext(AuthContext);
  const [afiliado, setAfiliado] = useState<Cliente>();
  const [clienteId, setClienteId] = useState<number>();
  const [sitesEmpresa, setSitesEmpresa] = useState([]);
  const [currentClienteFilter, setCurrentClienteFilter] = useState("byNome");
  const notificationRef = useRef<NotificationAlert>(null);
  const [empresa, setEmpresa] = useState<Empresa>();
  const [apiAccess, setApiAccess] = useState<ApiAccess>({} as ApiAccess);

  useEffect(() => {
    anuncio && loadAfiliado();
    loadApiAccess();
    loadEmpresa();
    console.log(anuncio);
  }, [show, anuncio]);

  async function loadApiAccess() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/api-access`
      );
      setApiAccess(response?.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar empresa");
    }
  }

  async function loadEmpresa() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}`
      );
      setEmpresa(response?.data);
      console.log("Empresaaa:", response?.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar empresa");
    }
  }

  async function loadAfiliado() {
    try {
      const response = await api.get(
        `marketing/anuncios/${anuncio?.id}/cliente-afiliado`
      );
      setAfiliado(response.data[0]?.afiliado);
      await loadSitesEmpresa();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível buscar afiliado id");
    }
  }

  const notify = (type: string, msg: string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text" style={{ zIndex: 2000 }}>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      autoDismiss: 3,
    };
    if (notificationRef.current)
      notificationRef.current.notificationAlert(options);
  };

  async function loadSitesEmpresa() {
    try {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/sites-empresas`
      );
      setSitesEmpresa(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível buscar sites da empresa");
    }
  }

  async function handleDelete(afiliado: { nome: string; id: number }) {
    if (window.confirm(`Deseja remover ${afiliado.nome} do anúncio?`)) {
      try {
        await api.put(`marketing/anuncios/${anuncio?.id}/cliente-afiliado`, {
          afiliado_id: null,
        });
        notify("success", "Cliente removido");
        await loadAfiliado();
      } catch (error) {
        console.error(error);
        notify("danger", "Não foi possível remover cliente");
      }
    }
  }
  async function handleAdded(id: number) {
    try {
      await api.put(`marketing/anuncios/${anuncio?.id}/cliente-afiliado`, {
        afiliado_id: id,
      });
      notify("success", "Cliente adicionado");
      await loadAfiliado();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível adicionar cliente");
    }
  }

  function funcLink(
    path: any,
    anuncio_id: any,
    token: any,
    avatar: any,
    logo_empresa: any
  ) {
    return `${path}?anuncio_id=${anuncio_id}&token=${token}&avatar=${avatar}&logo=${logo_empresa}`;
  }

  function clienteOptions() {
    const option = {
      placeholder: "Pesquisar um cliente...",
    };
    // @ts-ignore
    option["ajax"] = {
      url: `${process.env.REACT_APP_API_URL}/common/empresas/${empresaSelecionada?.id}/clientes`,
      dataType: "json",
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
      processResults: function (data: any) {
        return {
          results: data.data.map((item: any) => ({
            id: item.id,
            text: `${item.nome} | ${item.email} ${
              item.cpf_cnpj ? ` | ${item.cpf_cnpj}` : ""
            }`,
          })),
        };
      },
      data: function (params: any) {
        const filters = {
          email: params.term,
          cpfCnpj: params.term,
        };
        let myDate = {};
        switch (currentClienteFilter) {
          case "byNome":
            myDate = { nome: params.term };
            break;
          case "byEmail":
            myDate = { email: params.term };
            break;
          case "byCpfCnpj":
            myDate = { cpfCnpj: params.term };
            break;
        }
        var query = {
          ...myDate,
        };
        return query;
      },
    };
    return option;
  }
  const handleCheck = (value: string) => setCurrentClienteFilter(value);
  return (
    <>
      <NotificationAlert ref={notificationRef} />

      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={show}
        toggle={onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            Programa de Afiliação
            <br />
            <small className="text-muted">
              Anúncio:{" "}
              {
                // @ts-ignore
                anuncio?.nome
              }
            </small>
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-3">
          {afiliado?.id ? (
            <>
              <ListGroup className="list" flush>
                <h4>Cliente selecionado</h4>
                <ListGroupItem
                  className="list-group-item-action px-0"
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "0px 10px",
                    }}
                  >
                    {afiliado.avatar_url && afiliado.avatar ? (
                      <a
                        href="#"
                        style={{
                          marginBottom: 50,
                          cursor: "default",
                          pointerEvents: "none",
                        }}
                      >
                        <img
                          alt="avatar"
                          className="rounded-circle"
                          src={afiliado.avatar_url}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                    ) : (
                      <a
                        className="avatar rounded-circle mt--4"
                        href="#"
                        style={{
                          position: "relative",
                          top: "6px",
                          cursor: "default",
                          pointerEvents: "none",
                        }}
                      >
                        <i className="fas fa-user"></i>
                      </a>
                    )}
                  </div>
                  <Row style={{ flex: 1 }} className="align-items-center">
                    <div className="col d-flex">
                      <h4 className="mb-0">
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          {afiliado?.nome}
                        </a>
                      </h4>
                      <br />
                    </div>
                  </Row>
                  <div className="col-auto">
                    <Button
                      className="btn-sm"
                      color="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        // @ts-ignore
                        handleDelete(afiliado);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </div>
                </ListGroupItem>
              </ListGroup>

              <ListGroup className="list mt-5" flush>
                <h4>Sites disponíveis</h4>
                {sitesEmpresa.map(
                  (site: { nome: string; path: string }, key) => (
                    <ListGroupItem
                      className="list-group-item-action px-0"
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                      }}
                      key={key}
                    >
                      <div className="col-auto">
                        <i className="fas fa-link"></i>
                      </div>
                      <Row style={{ flex: 1 }} className="align-items-center">
                        <div className="col">
                          {/* <small>{site?.nome}</small> */}
                          <h4 className="mb-0">
                            <a
                              href="#"
                              onClick={(e) => e.preventDefault()}
                              title={`${site?.path}`}
                            >
                              {site?.nome}
                            </a>
                          </h4>
                        </div>
                      </Row>
                      <div className="col-auto">
                        <CopyToClipboard
                          // text={`${site?.path}?anuncio_id=${anuncio?.id}&token=${apiAccess.token}&avatar=${afiliado?.avatar_url}`}
                          text={funcLink(
                            site.path,
                            anuncio.id,
                            apiAccess.token,
                            afiliado.avatar ? afiliado.avatar_url : "",
                            empresa?.logo_url ? empresa.logo_url : ""
                          )}
                          onCopy={() => notify("success", "Copiado")}
                        >
                          <Button
                            className="btn-sm"
                            color="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              //   handleDelete(usuarioAfiliadoId);
                            }}
                          >
                            Copiar link
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </ListGroupItem>
                  )
                )}
              </ListGroup>
            </>
          ) : (
            <>
              <div>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Adicionar cliente
                  </label>
                  <FormGroup
                    tag="fieldset"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "10px",
                    }}
                  >
                    <FormGroup check>
                      <Input
                        onChange={(e: any) => handleCheck(e.target.value)}
                        checked={currentClienteFilter === "byNome"}
                        name="radio1"
                        value={"byNome"}
                        type="radio"
                      />{" "}
                      <Label check>Nome</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        onChange={(e: any) => handleCheck(e.target.value)}
                        checked={currentClienteFilter === "byEmail"}
                        name="radio1"
                        value={"byEmail"}
                        type="radio"
                      />{" "}
                      <Label check>Email</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        onChange={(e: any) => handleCheck(e.target.value)}
                        checked={currentClienteFilter === "byCpfCnpj"}
                        name="radio1"
                        value={"byCpfCnpj"}
                        type="radio"
                      />{" "}
                      <Label check>CPF/CNPJ</Label>
                    </FormGroup>
                  </FormGroup>
                  <Select2
                    maximumInputLength={10}
                    className="form-control"
                    onSelect={(e: any) => setClienteId(e.target.value)}
                    options={clienteOptions()}
                    value={clienteId}
                  />
                </FormGroup>
                <div>
                  <Button
                    color="primary"
                    type="button"
                    outline
                    onClick={() => clienteId && handleAdded(clienteId)}
                    className="btn-icon btn-3"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Adicionar</span>
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ProgramaDeAfiliacao;
