import React, { useEffect, useState } from 'react';

import api from "../../../../../../services/api";

import {
    Button,
    FormGroup,
    Row,
    Col,
    Input,
    Modal,
    Spinner,
} from "reactstrap";


// eslint-disable-next-line import/no-anonymous-default-export
export default ({ show, onHidden, empresa, onInserted, notify }) => {

    const [email, setEmail] = useState(null)
    const [erros, setErros] = useState({})
    const [saving, setSaving] = useState(false)


    async function insert() {
        setSaving(true)
        try {
            setErros({})
            const response = await api.post(`common/empresas/${empresa.id}/emails`, {
                email
            })
            onInserted(response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível salvar emails')
            if (error.response) updateErros(error.response.data)
        } finally {
            setSaving(false)
        }
    }

    function updateErros(error) {
        try {
            if (error) {
                const errors = {}
                for (let e of error) {
                    errors[e.field] = e.message
                }
                setErros(errors)
            } else {
                setErros({})
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function handleConfirm() {
        await insert()
    }

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={show}
                toggle={onHidden}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Novo Email
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidden}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                >
                                    Email*
                        </label>
                                <Input
                                    onChange={({ target }) => setEmail(target.value)}
                                    placeholder='Endereço de email...'
                                />
                                <small style={{ color: 'red' }}>
                                    {erros.email}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onHidden}
                    >
                        Voltar
                        </Button>
                    <Button
                        disabled={!email || saving}
                        color="primary"
                        onClick={handleConfirm}
                        type="button">
                        {
                            saving &&
                            <Spinner
                                className='mr-2'
                                color='secondary'
                                size='sm'
                            />
                        }
                        Salvar
                    </Button>
                </div>
            </Modal>
        </>
    );
}
