import React, { useContext, useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useReactToPrint } from 'react-to-print';
import { Button, Col, Container, Row } from 'reactstrap';

import AuthContext from '../../../contexts/Auth';
import IAnuncio from '../../../entities/Marketing/Anuncio';
import api from '../../../services/api';
import CartaoColaborador from './components/CartaoColaborador';
import ListaIndicacoes from './ListaIndicacoes';

interface Props {}
const GerarLinkQRCode: React.FC<Props> = ({}) => {
  const { user } = useContext(AuthContext);
  const cartaoColaboradorRef = useRef(null);
  const [link, setLink] = useState("");
  const [textCopiado, setTextCopiado] = useState("");
  const [anuncioColaborador, setAnuncioColaborador] = useState<IAnuncio>();
  const [showListarIndicacoes, setShowListarIndicacoes] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => cartaoColaboradorRef.current,
  });

  useEffect(() => {
    loadAnuncioColaborador();
  }, []);

  async function loadAnuncioColaborador() {
    /*
      O serviço chamado por essa função retorna somente o primeiro resultado
      da busca, o que nesse caso é o anúncio de afiliação e não indicação

      por isso foi acrescentado o parâmetro de pesquisa "tipo"
    */

    const response = await api.get(
      `marketing/anuncios/usuario-afiliado-id/${user?.id}?tipo=indicacao`
    );
    setAnuncioColaborador(response?.data.id);
  }

  return (
    <>
      <Container>
        {user && (
          <span ref={cartaoColaboradorRef}>
            <CartaoColaborador colaborador={user} setLink={setLink} />
          </span>
        )}
        {anuncioColaborador ? (
          <Row className="mt-5 justify-content-center">
            <Col sm="12" md="6" lg="8" className="text-center">
              <div>
                <CopyToClipboard
                  text={link}
                  onCopy={() => setTextCopiado("Copiado")}
                >
                  <Button
                    className="ml-auto"
                    color="primary"
                    type="button"
                    onClick={() => setTextCopiado("Copiado")}
                  >
                    <i
                      className="fas fa-link mr-2"
                      style={{ fontSize: 20 }}
                    ></i>
                    Copiar link
                  </Button>
                </CopyToClipboard>
                <Button
                  className="ml-auto"
                  color="primary"
                  type="button"
                  onClick={handlePrint}
                >
                  <i className="fas fa-print mr-2" style={{ fontSize: 20 }}></i>
                  Imprimir
                </Button>
                <Button
                  className="ml-auto"
                  color="primary"
                  type="button"
                  onClick={() => setShowListarIndicacoes(true)}
                >
                  Lista de indicações
                </Button>
                {showListarIndicacoes && (
                  <ListaIndicacoes
                    isOpen={showListarIndicacoes}
                    colaborador={anuncioColaborador}
                    hideModal={() => setShowListarIndicacoes(false)}
                  />
                )}
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};

export default GerarLinkQRCode;
