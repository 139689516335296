import React from 'react';

import Oportunidades from "./index";

const OportunidadesReadyOnly: React.FC = ({
    
}) => {
    return (
        <Oportunidades
            readOnly={true}
        />
    )
}

export default OportunidadesReadyOnly;