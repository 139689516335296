import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: flex-end;
  overflow-x: hidden;
  background: rgb(236, 239, 243);
  padding-top: 4px;
`;