import React, { useState } from 'react';

import classnames from "classnames";
import {
    Button,
    ButtonGroup
} from "reactstrap";

function SelectDisplayMode(value, onChange) {

    const [visoes] = useState([
        { id: 'lista', text: 'Lista' },
        { id: 'kanban', text: 'Kanban' }
    ])

    return (
        <>
            <ButtonGroup key={'btn-display-mode-oportunidade'} className="btn-group-toggle" size='sm' data-toggle="buttons">
                {
                    visoes.map((item, key) => (
                        <Button
                            key={'btn-display-mode-oportunidade-' + item.id}
                            className={classnames({ active: value == item.id })}
                            color="secondary"
                            onClick={() => onChange(item.id)}>
                            <input
                                autoComplete="off"
                                name="options"
                                type="radio"
                                value={value == item.id}
                            />
                            {item.text}
                        </Button>
                    ))
                }
            </ButtonGroup>
        </>
    )
}

export default SelectDisplayMode;