import React, { useState, useEffect } from 'react';
import api from "../../../../../services/api";

import Form from './Form'
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Button,
    ListGroupItem,
    ListGroup,
    Modal
} from "reactstrap";

export default ({
    notify,
    empresa,
    showNewCredencial,
    setShowNewCredencial,
    hiddeNewCredencial,
    ...props
}) => {

    const [credenciais, setCredenciais] = useState([])
    const [credencial, setCredencial] = useState({})
    const [alert, setAlert] = useState(null)
    const [showHelp, setShowHelp] = useState(false)

    useEffect(() => {
        if (empresa && empresa.id) loadCredenciais()
    }, [])

    async function loadCredenciais() {
        try {
            const response = await api.get(`common/empresas/${empresa.id}/credenciais-mensagens`)
            setCredenciais(await response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar credenciais')
        }
    }


    async function removeCredencial({ id }) {
        try {
            await api.delete(`common/empresas/${empresa.id}/credenciais-mensagens/${id}`)
            await loadCredenciais()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível remover credencial')
        }
    }


    function ListItem({ credencial, key }) {

        const [loading, setLoading] = useState(false)

        return (
            <ListGroupItem key={key} className="checklist-entry flex-column align-items-start py-4 px-4">
                <div className={`checklist-item checklist-item-success`}>
                    <div className="checklist-primary">
                        <h5 className="checklist-title mb-0">
                            {credencial.alias}
                        </h5>
                        <small>
                            {credencial.canal}
                        </small>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div className="custom-control custom-checkbox custom-checkbox-primary"
                        >

                            {
                                loading ?
                                    <i
                                        class="fas fa-circle-notch fa-spin fa-lg primary mr-2"
                                    ></i>
                                    :
                                    <>
                                        <div class="btn-group" role="group" >
                                            <Button
                                                className="btn-sm"
                                                color="secondary"
                                                onClick={() => {
                                                    setCredencial(credencial)
                                                    setShowNewCredencial(true)
                                                }}
                                                outline>
                                                <i class="far fa-edit"></i>
                                            </Button>
                                            <Button
                                                className="btn-sm"
                                                color="danger"
                                                onClick={(e) => {
                                                    if (window.confirm("Deseja remover credencial?")) {
                                                        setLoading(true)
                                                        removeCredencial(credencial)
                                                    }
                                                }}
                                            >
                                                <i class="fas fa-trash"></i>
                                            </Button>
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </div>
            </ListGroupItem>
        )
    }

    const ModalHelp = ({ show, onHidden }) => (
        <Modal
            className="modal-dialog-centered"
            isOpen={show}
            toggle={onHidden}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Ajuda
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={onHidden}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body jusify-content-center">
                Para que sua empresa possa enviar Sms e Mensagens Whatsapp, precisamos que você informe suas credenciais da plataforma de envio.
                Acesse sua plataforma de envio, busque pelos dados referente a integrações e identifique o login (alias, palavra-chave, key) e senha (token, password) do canal de comunicação (sms, whatsapp) que deseja integrar.
            </div>
            <div className="modal-footer">
                <Button
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={onHidden}
                >
                    Fechar
                </Button>
            </div>
        </Modal>
    )

    const ButtonHelp = () => (<>
        <Button
            color="link"
            id="tooltip-99882211"
            outline
            size="sm"
            onClick={() => setShowHelp(true)}
            type="button">
            ?
        </Button>
    </>)


    return (
        <>
            {alert}
            <ModalHelp
                show={showHelp}
                onHidden={() => setShowHelp(false)}
            />
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'end'
                }}
                className='mb-4'
            >

                <h2>
                    Sms / Whatsapp
                </h2>
                <ButtonHelp />
            </div>
            <Form
                show={showNewCredencial}
                onHidden={hiddeNewCredencial}
                empresa={empresa}
                credencial={credencial}
                setCredencial={setCredencial}
                onInserted={(data) => {
                    notify('success', 'Credencial adicionada com sucesso.')
                    loadCredenciais()
                    setCredencial({})
                    hiddeNewCredencial()
                }}
                notify={notify}
            />
            <ListGroup className="list " flush>
                {
                    credenciais.map((item, key) => <ListItem credencial={item} key={key} />)
                }
            </ListGroup>
        </>
    );
}
