import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import Avatar from '../../../../components/Avatar';
import AuthContext from '../../../../contexts/Auth';
import { User } from '../../../../entities/Security';
import { CentralVendedor } from './components/CentralVendedor';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';

const MeuPerfil: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const history = useHistory();

  const toggle = (tab: string) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <>
      <SimpleHeader parentName="Meu Perfil" />
      <Container className="mt--6" fluid>
        <Row className="sticky-top" style={{ zIndex: 4 }}>
          <Col>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <Avatar
                      user={user as User}
                      className="bg-secondary"
                      style={{ color: 'blue' }}
                    />
                    <div className="ml-4">
                      <h1>{user?.name}</h1>
                      <span>{user?.roles[0].name}</span>
                    </div>
                  </div>
                  <Nav>
                    <ButtonGroup>
                      <Button
                        className={`${
                          currentActiveTab === '1' ? 'active primary' : null
                        } form-control-label`}
                        onClick={() => toggle('1')}
                      >
                        Central
                      </Button>

                      <Button onClick={() => history.push('/admin/me/edit')}>
                        Configurações de conta
                      </Button>
                    </ButtonGroup>
                  </Nav>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <TabContent activeTab={currentActiveTab}>
                  <TabPane tabId="1">
                    <CentralVendedor />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MeuPerfil;
