import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom'

import SimpleHeader from '../../../../components/Headers/SimpleHeader'
import Form from "./Form";

// import { Container } from './styles';

const Edit: React.FC = () => {

    const match = useRouteMatch()
    const [id, setId] = useState<number | undefined>(undefined)

    useEffect(() => {
        const { params } = match
        //@ts-ignore
        if (params && params?.id != id) {
            //@ts-ignore
            setId(params?.id)
        }
    }, [])

    return (
        <>
            <SimpleHeader
                name="Alterar PDCA"
                parentName="PDCAs"
            />
            <Form
                objetivoId={id}
            />
        </>
    );
}

export default Edit;