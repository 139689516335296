import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../../contexts/Auth";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
// reactstrap components
import ReactHtmlParser from "react-html-parser";
import Pagination from "../../../../components/Pagination";
import Form from "./Form";
import { Button, Row, Col, ListGroupItem, ListGroup, Modal } from "reactstrap";

export default ({
  oportunidadeId,
  oportunidadeIdToCrossSelling,
  tipoCrossSelling,
  notify,
  readOnly,
  onComentariosChanged,
  handleShowEmailAvulso,
  ...props
}) => {
  const { user } = useContext(AuthContext);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [comentarios, setComentarios] = useState([]);
  const [comentarioEmedicao, setComentarioEmEdicao] = useState({});

  const [pageProperties, setPageProperties] = useState({});
  const [showModal, setShowModal] = useState(false);

  const comentariosCrossSelling = [
    {
      id: 1,
      tipo: "produtos_declinados",
      descricao: `Oportunidade gerada a partir de processo de sugestão de cross-selling para produtos declinados em uma venda. <a href="${process.env.REACT_APP_PLAYNEE_URL}/admin/oportunidades/${oportunidadeIdToCrossSelling}/edit" target="_blank">Oportunidade de Origem: ${oportunidadeIdToCrossSelling}</a>`,
    },
    {
      id: 2,
      tipo: "oportunidade_encerrada",
      descricao: `Oportunidade gerada a partir de processo de sugestão de cross-selling para oportunidades encerradas. <a href="${process.env.REACT_APP_PLAYNEE_URL}/admin/oportunidades/${oportunidadeIdToCrossSelling}/edit" target="_blank">Oportunidade de Origem: ${oportunidadeIdToCrossSelling}</a>`,
    },
  ];

  useEffect(() => {
    if (oportunidadeId) loadComentarios(pageProperties, oportunidadeId);
  }, [oportunidadeId]);

  useEffect(() => {
    if (oportunidadeIdToCrossSelling && oportunidadeIdToCrossSelling > 0) {
      var comentario = {
        //descricao: `Oportunidade gerada a partir de processo de sugestão de cross-selling para produtos declinados em uma venda. Oportunidade de Origem: ${oportunidadeIdToCrossSelling}`
        descricao: `<a href="${process.env.REACT_APP_PLAYNEE_URL}/admin/oportunidades/${oportunidadeIdToCrossSelling}/edit" target="_blank">Oportunidade de Origem: ${oportunidadeIdToCrossSelling}</a>`,
      };

      if (tipoCrossSelling) {
        for (const coment of comentariosCrossSelling) {
          if (coment.tipo == tipoCrossSelling) {
            comentario = {
              //descricao: `Oportunidade gerada a partir de processo de sugestão de cross-selling para produtos declinados em uma venda. Oportunidade de Origem: ${oportunidadeIdToCrossSelling}`
              descricao: coment.descricao,
            };
          }
        }
      }

      handleSave(comentario);
    }
  }, [oportunidadeIdToCrossSelling]);

  useEffect(() => {
    if (onComentariosChanged) {
      onComentariosChanged(comentarios);
    }
  }, [comentarios]);

  async function loadComentarios({ page = 1, limit = 3 }, oportunidadeId) {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/comentarios`,
        {
          params: {
            page,
            limit,
          },
        }
      );
      setComentarios(response.data.data);
      setPageProperties(response.data);
    } catch (error) {
      notify("danger", "Não foi possivel carregar comentarios");
    }
  }

  const formatDate = (date) => {
    const dt = new Date(date);
    var options = { year: "numeric", month: "short", day: "numeric" };
    return dt.toLocaleDateString("pt-br", options);
  };

  function removeLocal(comentario) {
    const array = comentarios.filter(
      (item) => item.created_at != comentario.created_at
    );
    setComentarios(array);
  }

  async function remove(item) {
    if (window.confirm("Deseja remover o comentário?")) {
      try {
        if (!oportunidadeId) {
          //Se não estiver salvo, remove localmente
          removeLocal(item);
          return;
        }
        await api.delete(
          `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/comentarios/${item.id}`
        );
        notify("success", "Comentário removido");
        loadComentarios(pageProperties, oportunidadeId);
      } catch (error) {
        console.error(error);
        notify("danger", "Não foi possível remover comentário");
      }
    }
  }

  function handleSave(comentario) {
    notify("success", "Comentário salvo");
    //Caso exita oportunidade, recarrega a lista de comentários
    if (oportunidadeId) {
      loadComentarios(pageProperties, oportunidadeId);
      setShowModal(false);
      return;
    }

    if (!oportunidadeId && comentario) {
      const copy = comentarios;
      if (comentarioEmedicao.descricao) {
        //Se tiver um comentario em edição, atualiza o mesmo
        copy.forEach((item) => {
          if (item.created_at == comentarioEmedicao.created_at) {
            item.descricao = comentario.descricao;
          }
        });
      } else {
        //Se nao cria um novo
        const obj = {
          ...comentario,
          created_at: new Date(),
          user: {
            ...user,
          },
        };
        copy.push(obj);
      }
      setComentarios(copy);
    }
    setShowModal(false);
    setComentarioEmEdicao({});
  }

  const createComentarioPreview = (item, key) => (
    <>
      <ListGroupItem
        key={key}
        className="list-group-item-action flex-column align-items-start py-4 px-4"
        style={{
          cursor: "pointer",
        }}
        href="#"
      >
        <Row>
          <Col
            lg={1}
            sm={2}
            md={1}
            className="d-flex align-items-center justify-content-center"
          >
            <Button
              className="btn-sm"
              color="primary"
              onClick={(e) => {
                return;
              }}
            >
              <i
                class={
                  item.tipo == "email"
                    ? "fas fa-envelope"
                    : "fas fa-file-signature"
                }
              ></i>
            </Button>
          </Col>
          <Col
            onClick={(e) => {
              if (readOnly) return;
              if (item.tipo == "comentario") {
                if (item.user.id == user?.id) {
                  setComentarioEmEdicao(item);
                  setShowModal(true);
                } else {
                  notify("danger", "Você não pode alterar este comentário");
                }
              } else {
                if (item.tipo == "email") {
                  handleShowEmailAvulso(item);
                }
              }
            }}
          >
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div>
                <div className="d-flex w-100 align-items-center">
                  <i class="far fa-user mr-2"></i>
                  <h5 className="mb-1">{item.user.name}</h5>
                </div>
                <p className="text-sm mb-0">
                  {ReactHtmlParser(item.descricao)}
                </p>
              </div>
              <div>
              <small>{formatDate(item.created_at)}</small>
              </div>
            </div>
          </Col>
          <Col
            lg={1}
            sm={2}
            md={1}
            className="d-flex align-items-center justify-content-center"
          >
            <Button
              disabled={readOnly || item.tipo == "email"}
              className="btn-sm"
              color="danger"
              onClick={() => remove(item)}
            >
              <i class="fas fa-trash"></i>
            </Button>
          </Col>
        </Row>
      </ListGroupItem>
    </>
  );

  return (
    <>
      <div className="mt-4 ml-4">
        <Button
          disabled={readOnly}
          color="primary"
          type="button"
          className="btn-icon btn-3"
          size="sm"
          onClick={() => {
            if (readOnly) return;
            setShowModal(!showModal);
            setComentarioEmEdicao({});
          }}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-fat-add"></i>
          </span>
          <span className="btn-inner--text">Novo</span>
        </Button>
        <Modal
          className="modal-dialog-centered"
          isOpen={showModal}
          toggle={() => setShowModal(!showModal)}
          backdrop="static"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Adicionar Comentário
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModal(!showModal)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              notify={notify}
              oportunidadeId={oportunidadeId}
              onSuccess={handleSave}
              comentario={comentarioEmedicao}
              close={() => setShowModal(false)}
            />
          </div>
        </Modal>
      </div>
      {
        <ListGroup flush className=" py-4 px-4">
          {comentarios.map((item, key) => (
            <>{createComentarioPreview(item, key)}</>
          ))}
        </ListGroup>
      }
      {oportunidadeId && comentarios && comentarios.length > 0 && (
        <Pagination
          pageProperties={pageProperties}
          load={(pageProperties) => {
            loadComentarios(pageProperties, oportunidadeId);
          }}
        />
      )}
    </>
  );
};
