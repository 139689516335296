import React, { useState, useEffect, useContext } from 'react';
import { Oportunidade } from "../../../../entities/Marketing";
import { MotivoEncerramento, PoliticaPrivacidade } from "../../../../entities/Common";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
//@ts-ignore
import Select2 from "react-select2-wrapper";
import {
    Button,
    Modal,
    FormGroup,
    Row,
    Col
} from "reactstrap";

interface IAssinatura {
    oportunidade?: Oportunidade,
    onAssinado(): void,
    notify(type: string, text: string): void
}

const Assinatura: React.FC<IAssinatura> = ({
    oportunidade,
    onAssinado,
    notify
}) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [showModal, setShowModal] = useState(false)
    const [assinado, setAssinado] = useState(false);
    const [politicaPrivacidadeVigente, setPoliticaPrivacidadeVigente] = useState<PoliticaPrivacidade | undefined>()
    const [politicaPrivacidadeOportunidade, setPoliticaPrivacidadeOportunidade] = useState<PoliticaPrivacidade | undefined>()

    useEffect(() => {
        if (!politicaPrivacidadeVigente) {
            loadPoliticaPrivacidadeVigente()
        }
    }, [])

    useEffect(() => {
        setAssinado(oportunidade?.data_assinatura_politica ? true : false)

        if (oportunidade && (oportunidade.politica_privacidade_id ? oportunidade.politica_privacidade_id > 0 : false) && !politicaPrivacidadeOportunidade) {
            loadPoliticaPrivacidadeOportunidade()
        }

    }, [oportunidade])


    function isNew() {
        return !(oportunidade && oportunidade.id)
    }

    async function loadPoliticaPrivacidadeVigente() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/politica-privacidade-vigente`)
            setPoliticaPrivacidadeVigente(await response.data)
        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível carregar Politica de Privacidade Vigente')
        }
    }

    async function loadPoliticaPrivacidadeOportunidade() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/politicas-privacidade/${oportunidade?.politica_privacidade_id}`)
            setPoliticaPrivacidadeOportunidade(await response.data)
        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível carregar Politica de Privacidade da Oportunidade')
        }
    }

    async function handlerAssinarPoliticaToOportunidade(e: React.MouseEvent) {
        e.preventDefault()
        try {
            await api.post(`/common/empresas/${empresaSelecionada?.id}/politicas-privacidade/${politicaPrivacidadeVigente?.id}/assinar-politica-privacidade/${oportunidade?.id}`,
                { tipo_assinatura_politica: 'F' }
            )

            if (notify) notify('success', 'Politica de Privacidade Assinada')
            setShowModal(false)
            onAssinado();
        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível Assinar Politica de Privacidade')
        }
    }

    function handlerVerPolitica(e: React.MouseEvent) {
        try {
            window.open(politicaPrivacidadeVigente?.link_url, '_blank');
        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível Imprimir Politica de Privacidade')
        }
    }

    function isAssinado() {
        return oportunidade?.data_assinatura_politica
    }

    //@ts-ignore
    const formatDate = (date) => {
        const dt = new Date(date)
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        //@ts-ignore
        return dt.toLocaleDateString('pt-br', options)
    }

    return (
        <>
            {
                !isNew() &&
                <Button
                    // title={isMarcado() ? oportunidade?.motivoEncerramento?.nome : "Adicione Assinatura da Política de Privacidade"}
                    title={isAssinado() ? "Verificar Assinatura da Política de Privacidade" : "Adicione Assinatura da Política de Privacidade"}
                    color="link"
                    size='sm'
                    onClick={() => setShowModal(true)}>
                    <i className={isAssinado() ? 'fas fa-file-contract' : 'fas fa-file-signature'}></i>
                </Button>
            }
            <Modal
                className={isAssinado() ? "modal-dialog-centered modal-success" : "modal-dialog-centered modal-danger"}
                size="md"
                isOpen={showModal}
                toggle={() => setShowModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Assinatura de Política de Privacidade
                    </h6>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        {/* <i className="fas fa-question-circle ni-3x" /> */}
                        <i className={isAssinado() ? 'fas fa-file-contract ni-3x' : "fas fa-file-signature ni-3x"}></i>
                        <h4 className="heading mt-4"></h4>
                        {isAssinado() ?
                            <>
                                <p style={{ fontSize: '1.2rem' }}>
                                    Política de Privacidade Assinada
                                </p>
                                <p style={{ fontSize: '1.1rem' }}>
                                    Política de Privacidade: {politicaPrivacidadeOportunidade?.nome} <br></br>  Versão:  {politicaPrivacidadeOportunidade?.versao}
                                </p>
                                <p style={{ fontSize: '1.1rem' }}>
                                    Assinada {oportunidade?.tipo_assinatura_politica == 'F' ? 'fisicamente' : 'digitalmente'} em {formatDate(oportunidade?.data_assinatura_politica)}
                                </p>
                            </>
                            :
                            <>
                                <p>
                                    ATENÇÃO
                                </p>
                                <p>
                                    O cliente ainda não assinou a Política de Privacidade. Imprima a politica e recolha a assinatura do seu cliente manualmente e registre se o seu cliente assinou.
                                </p>
                            </>
                        }
                        {!isAssinado() &&
                            < div className="custom-control custom-checkbox mr-4">
                                <input
                                    className="custom-control-input"
                                    id="check-sms"
                                    type="checkbox"
                                    checked={assinado}
                                    onChange={({ target }) => setAssinado(target.checked)}
                                />
                                <label className="custom-control-label" htmlFor="check-sms">
                                    Cliente Assinou
                                </label>
                            </div>
                        }

                    </div>
                </div>
                <div className="modal-footer">
                    <div>
                        <Button
                            className="text-white ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowModal(false)}
                        >
                            Fechar
                        </Button>
                        <Button
                            onClick={handlerVerPolitica}
                            className="btn-white"
                            color="default"
                            type="button">
                            Ver Política
                        </Button>
                        {!isAssinado() &&
                            <Button
                                onClick={handlerAssinarPoliticaToOportunidade}
                                className="btn-white"
                                color="default"
                                type="button"
                                disabled={!assinado}>
                                Salvar
                            </Button>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Assinatura;