import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import moment from "moment";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
import { KpisContext } from "../../../../contexts/Kpis";

interface IGraficos {
  mes: number;
  ano: number;
  canais: any;
}
interface IInformativos {
  atrasosperiodo: number | null;
  estimativadesperdicioleads: string | null;
  tempomedioatendimentoperiodo: string | null;
  percentualatrasosperiodo: string | null;
}

const CardInformativo: React.FC<IGraficos> = ({ mes, ano, canais }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { requestAvaliable, handlerequestAvaliable } = useContext(KpisContext);
  const [time, setTime] = useState<any>(0);

  const [sumarioPerformancePeriodo, setSumarioPerformancePeriodo] =
    useState<IInformativos>();
  const [percentualPronto, setPercentualPronto] = useState();
  useEffect(() => {
    // loadData();
  }, []);
  useEffect(() => {
    if (canais) tempo();
  }, [mes, ano, canais]);

  async function tempo() {
    clearTimeout(time);
    setTime(
      setTimeout(async (e) => {
        loadData();
      }, 3000)
    );
  }

  async function loadData() {
    setIsLoading(true);
    handlerequestAvaliable(false);
    try {
      if (isLoading) {
        console.log("essa porra ainda tá rodando");
        return;
      }

      const dataInicio = moment([ano, mes - 1, 1]).format("YYYY-MM-DD");
      let dataFim = moment([ano, mes - 1, 1])
        .endOf("month")
        .format("YYYY-MM-DD");
      if (new Date(dataFim) > new Date()) {
        // @ts-ignore
        dataFim = new Date();
      }

      const response = await api.get(
        `/dashboards/empresas/${empresaSelecionada?.id}/kpis-sumario-performance-periodo/`,
        {
          params: {
            data_inicio: dataInicio,
            data_fim: dataFim,
            canal_id: canais,
          },
        }
      );
      setSumarioPerformancePeriodo(response.data[0]);
      setPercentualPronto(
        (response.data[0]?.percentualatrasosperiodo).toFixed(2)
      );

      console.log({ percentualPronto, canais });
    } catch (err) {
      console.error(err);
      notify("danger", "Houve um problema ao carregar fluxo de vendas");
    } finally {
      setIsLoading(false);
      handlerequestAvaliable(true);
    }
  }

  function notify(type: any, msg: any) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
  }

  return (
    <>
      <Container body>
        <div
          className="row"
          style={{
            justifyContent: "space-around",
          }}
        >
          <Card
            className="col-4 mb-0"
            style={{
              backgroundColor: "#5e72e4",
              padding: "1rem",
              maxWidth: "350px",
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "space-between",
                padding: "0px 1rem 0px 1rem",
              }}
            >
              <CardTitle
                className="mb-0"
                style={{
                  height: "16vh",
                }}
              >
                <h4 className="text-uppercase mb-0 text-white font-weight-normal">
                  Atrasos do período
                </h4>
                <h5 className="text-white font-weight-normal">
                  Oportunidades do período em atraso
                </h5>
              </CardTitle>
              <div
                className="icon icon-shape bg-white text-white rounded-circle shadow"
                style={{
                  marginRight: "-5px",
                }}
              >
                <i
                  className="far fa-frown"
                  style={{
                    fontSize: "1.5rem",
                    color: "#5e72e4",
                  }}
                />
              </div>
            </div>
            <div>
              <h1
                style={{
                  color: "#fff",
                  marginBottom: "0px",
                  position: "absolute",
                  bottom: "12px",
                }}
              >
                {sumarioPerformancePeriodo?.atrasosperiodo}{" "}
                <span
                  style={{
                    fontSize: "0.8em",
                  }}
                >
                  ({percentualPronto}%)
                </span>
              </h1>
            </div>
          </Card>
          <Card
            className="col-4 mb-0"
            style={{
              backgroundColor: "#2dce89",
              padding: "1rem",
              maxWidth: "350px",
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                padding: "0px 1rem 0px 1rem",
              }}
            >
              <CardTitle
                className="mb-0"
                style={{
                  height: "16vh",
                }}
              >
                <h4 className="text-uppercase mb-0 text-white font-weight-normal">
                  Tempo médio de atendimento
                </h4>
                <h5 className="text-white font-weight-normal">
                  Tempo médio entre o rebecimento de uma oportunidade e seu
                  atendimento
                </h5>
              </CardTitle>
              <div
                className="icon icon-shape bg-white text-white rounded-circle shadow"
                style={{
                  marginRight: "-5px",
                }}
              >
                <i
                  className="fas fa-hourglass-half"
                  style={{
                    fontSize: "1.5rem",
                    color: "#2dce89",
                    width: "24px",
                  }}
                />
              </div>
            </div>
            <div>
              <h1
                style={{
                  color: "#fff",
                  marginBottom: "0px",
                  position: "absolute",
                  bottom: "12px",
                }}
              >
                {sumarioPerformancePeriodo?.tempomedioatendimentoperiodo}
              </h1>
            </div>
          </Card>
          <Card
            className="col-4 mb-0"
            style={{
              backgroundColor: "#fa6340",
              padding: "1rem",
              maxWidth: "350px",
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                padding: "0px 1rem 0px 1rem",
              }}
            >
              <CardTitle
                className="mb-0"
                style={{
                  height: "16vh",
                }}
              >
                <h4 className="text-uppercase mb-0 text-white font-weight-normal">
                  Desperdício de investimento
                </h4>
                <h5 className="text-white font-weight-normal">
                  Estimativa de Desperdicio de investimento em Leads
                </h5>
              </CardTitle>
              <div
                className="icon icon-shape bg-white text-white rounded-circle shadow"
                style={{
                  marginRight: "-5px",
                }}
              >
                <i
                  className="fas fa-hand-holding-usd"
                  style={{
                    fontSize: "1.5rem",
                    color: "#fa6340",
                    width: "24px",
                  }}
                />
              </div>
            </div>
            <div>
              <h1
                style={{
                  color: "#fff",
                  marginBottom: "0px",
                  position: "absolute",
                  bottom: "12px",
                }}
              >
                {sumarioPerformancePeriodo?.estimativadesperdicioleads}
              </h1>
            </div>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default CardInformativo;
