import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../../../../../services/api";
import EmpresaContext from "../../../../../../contexts/Empresa";
// @ts-ignore
import NotificationAlert from "react-notification-alert";
import Form from "./Form";
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Button,
  ListGroupItem,
  ListGroup,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  PopoverBody,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import { ButtonContainer, InfoToken, LabelToken } from "../styles";
import CopyToClipboard from "react-copy-to-clipboard";

const Sms: React.FC = () => {
  const [credenciais, setCredenciais] = useState([]);
  const [credencial, setCredencial] = useState({});
  const [alertNotify, setAlertNotify] = useState(null);
  const [showSmsForm, setShowSmsForm] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const notificationAlert = useRef<any>();

  useEffect(() => {
    loadCredenciais();
  }, []);

  async function loadCredenciais() {
    try {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/credenciais-mensagens`
      );
      setCredenciais(await response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar credenciais");
    }
  }

  function notify(type: any, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function removeCredencial({ id }: any) {
    try {
      await api.delete(
        `common/empresas/${empresaSelecionada?.id}/credenciais-mensagens/${id}`
      );
      await loadCredenciais();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível remover credencial");
    }
  }

  function handleNewSms(e: any) {
    e.preventDefault();
    setShowSmsForm(true);
  }

  function ListItem({ credencial, key }: any) {
    const [loading, setLoading] = useState(false);

    return (
      <ListGroupItem
        key={key}
        className="checklist-entry flex-column align-items-start py-4 px-4"
      >
        <div className={`checklist-item checklist-item-success`}>
          <div className="checklist-primary">
            <h5 className="checklist-title mb-0">{credencial.alias}</h5>
            <small>{credencial.canal}</small>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="custom-control custom-checkbox custom-checkbox-primary">
              {loading ? (
                <i className="fas fa-circle-notch fa-spin fa-lg primary mr-2"></i>
              ) : (
                <>
                  <div className="btn-group" role="group">
                    <Button
                      className="btn-sm"
                      color="secondary"
                      onClick={() => {
                        setCredencial(credencial);
                        setShowSmsForm(true);
                      }}
                      outline
                    >
                      <i className="far fa-edit"></i>
                    </Button>
                    <Button
                      className="btn-sm"
                      color="danger"
                      onClick={(e) => {
                        if (window.confirm("Deseja remover credencial?")) {
                          setLoading(true);
                          removeCredencial(credencial);
                        }
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ListGroupItem>
    );
  }

  const ModalHelp = ({ show, onHidden }: any) => (
    <Modal className="modal-dialog-centered" isOpen={show} toggle={onHidden}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Ajuda
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onHidden}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body jusify-content-center">
        Para que sua empresa possa enviar Sms e Mensagens Whatsapp, precisamos
        que você informe suas credenciais da plataforma de envio. Acesse sua
        plataforma de envio, busque pelos dados referente a integrações e
        identifique o login (alias, palavra-chave, key) e senha (token,
        password) do canal de comunicação (sms, whatsapp) que deseja integrar.
      </div>
      <div className="modal-footer">
        <Button
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onHidden}
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );

  const ButtonHelp = () => (
    <>
      <Button
        color="link"
        id="tooltip-99882211"
        outline
        size="sm"
        onClick={() => setShowHelp(true)}
        type="button"
      >
        ?
      </Button>
    </>
  );

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <ModalHelp show={showHelp} onHidden={() => setShowHelp(false)} />
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "end",
        }}
        className="mb-4"
      >
        <h2>Sms</h2>
        <ButtonHelp />
      </div>
      <div className="pb-5">
        <ButtonContainer>
          <Button caret color="primary" onClick={handleNewSms}>
            <span className="btn-inner--icon">
              <i className="ni ni-fat-add"></i>
            </span>
            <span className="btn-inner--text">Novo</span>
          </Button>
        </ButtonContainer>
      </div>
      <Form
        show={showSmsForm}
        onHidden={() => setShowSmsForm(false)}
        empresa={empresaSelecionada}
        credencial={credencial}
        setCredencial={setCredencial}
        onInserted={(data: any) => {
          setShowSmsForm(false);
          notify("success", "Credencial adicionada com sucesso.");
          loadCredenciais();
          setCredencial({});
        }}
        notify={notify}
      />
      <ListGroup className="list" flush>
        {credenciais.map((item, key) => (
          <ListItem credencial={item} key={key} />
        ))}
      </ListGroup>
    </>
  );
};
export default Sms;
