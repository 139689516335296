import React, { useState, useEffect } from 'react';
import api from "../../../../../services/api";

import Form from './Form'
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Button,
    ListGroupItem,
    ListGroup,
    Modal
} from "reactstrap";

export default ({
    notify,
    empresa,
    showNewEmail,
    hiddeNewEmail,
    ...props
}) => {

    const [emails, setEmails] = useState([])
    const [alert, setAlert] = useState(null)
    const [showHelp, setShowHelp] = useState(false)

    useEffect(() => {
        if (empresa && empresa.id) loadEmails()
    }, [])

    async function loadEmails() {
        try {
            const response = await api.get(`common/empresas/${empresa.id}/emails`)
            setEmails(response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar emails')
        }
    }


    async function removeEmail({ email }) {
        try {
            await api.delete(`common/empresas/${empresa.id}/emails/${email}`)
            loadEmails()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível remover email')
        }
    }

    function isVerificado({ situacao }) {
        return situacao == 'A'
    }


    function ListItem({ email, key }) {

        const [loading, setLoading] = useState(false)

        return (
            <ListGroupItem key={key} className="checklist-entry flex-column align-items-start py-4 px-4">
                <div className={`checklist-item checklist-item-${isVerificado(email) ? 'success' : 'danger'}`}>
                    <div className="checklist-primary">
                        <h5 className="checklist-title mb-0">
                            {email.email}
                        </h5>
                        <small
                            title={isVerificado(email) ? 'Email verificado' : 'Este email ainda não foi verificado. Por favor, acesse sua caixa de entrada.'}
                        >
                            {isVerificado(email) ? 'Verificado' : 'Não verificado'}
                        </small>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div className="custom-control custom-checkbox custom-checkbox-primary"
                        >

                            {
                                loading ?
                                    <i
                                        class="fas fa-circle-notch fa-spin fa-lg primary mr-2"
                                    ></i>
                                    :
                                    <>
                                        <div class="btn-group" role="group" >
                                            <Button
                                                className="btn-sm"
                                                color="danger"
                                                onClick={(e) => {
                                                    if (window.confirm("Deseja remover o Email?")) {
                                                        setLoading(true)
                                                        removeEmail(email)
                                                    }
                                                }}
                                            >
                                                <i class="fas fa-trash"></i>
                                            </Button>
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </div>
            </ListGroupItem>
        )
    }

    const ModalHelp = ({ show, onHidden }) => (
        <Modal
            className="modal-dialog-centered"
            isOpen={show}
            toggle={onHidden}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Ajuda
          </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={onHidden}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body jusify-content-center">
                Para que você possa utilizar o endereço de email da sua empresa como remente dos emails enviados por <strong>mala direta</strong>,
                precisaremos verificar se esse email é realmente seu.<br />
                O processo é simples, adicione o email da sua empresa e em alguns instantes você irá receber um email dos nossos servidores Amazon solicitando a verificação.
                Basta clicar no link no corpo do email e o email estará verificado.<br /><strong>O endereço de email informado será usado apenas como remetente dos envios da mala direta</strong>.
            </div>
            <div className="modal-footer">
                <Button
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={onHidden}
                >
                    Fechar
          </Button>
            </div>
        </Modal>
    )

    const ButtonHelp = () => (<>
        <Button
            color="link"
            id="tooltip-99882211"
            outline
            size="sm"
            onClick={() => setShowHelp(true)}
            type="button">
            ?
                  </Button>
    </>)


    return (
        <>
            {alert}
            <ModalHelp
                show={showHelp}
                onHidden={() => setShowHelp(false)}
            />
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'end'
                }}
                className='mb-4'
            >

                <h2>
                    Emails
                   </h2>
                <ButtonHelp />
            </div>
            <Form
                show={showNewEmail}
                onHidden={hiddeNewEmail}
                empresa={empresa}
                onInserted={(data) => {
                    notify('success', 'Email adicionado com sucesso.')
                    loadEmails()
                    hiddeNewEmail()
                    setAlert(<ReactBSAlert
                        success
                        onConfirm={() => setAlert(null)}
                        onCancel={() => setAlert(null)}
                        confirmBtnBsStyle="success"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                        {`Abra a caixa de entrada do email ${data.email} para fazer a verificação do mesmo.`}
                    </ReactBSAlert>)
                }}
                notify={notify}
            />
            <ListGroup className="list " flush>
                {
                    emails.map((item, key) => <ListItem email={item} key={key} />)
                }
            </ListGroup>
        </>
    );
}
