import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

//@ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import {
  Col,
  Row,
  Button
} from "reactstrap";

interface Props {
  periodos: Array<any>,
  columnsPeriodos: any,
  unidade: any,
  valor: any,
  somaValorPeriodos: any,
  beforeSaveCellPeriodo: Function,
  handleDistruibuirPorPeriodos: Function
}

const TablePeriodos: React.FC<Props> = ({periodos, columnsPeriodos, unidade, valor, somaValorPeriodos, beforeSaveCellPeriodo, handleDistruibuirPorPeriodos}) => {

  const [data, setData] = useState([]) //Lista para receber produtos adicionados
  const [reload, setReload] = useState(false)

  useEffect(() => {
    console.log('####### USE EFFECT EM TABLEPERIODOS da LISTA PERIODO ########')
    console.log(reload)
    setData([])
    if (periodos && periodos.length > 0) {
      setReload(true);
    } else {
      //@ts-ignore
      setData(periodos)
      setReload(false);
    }

  }, [periodos])

  useEffect(() => {
    console.log('####### USE EFFECT RELOAD TABELA PERIODO ########')
    console.log(reload)
    if (reload) {
      //@ts-ignore
      setData(periodos)
      setReload(false);
    }

  }, [reload])

  return (
    <>
      <Row>
        <Col className='col-12'>


          <ToolkitProvider
            data={data ? data : []}
            keyField="data_inicio"
            //@ts-ignore
            columns={columnsPeriodos}
          >
            {props => (
              <div className="table-responsive py-4">
                <BootstrapTable
                  {...props.baseProps}
                  noDataIndication={()=> <span>Nenhum período adicionado</span>}
                  bootstrap4={true}
                  bordered={false}
                  cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, beforeSaveCell: beforeSaveCellPeriodo })}
                />
              </div>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
      <div>
        {(unidade && unidade == 'M') ?
          `Soma parcial: R$ ${parseFloat(somaValorPeriodos).toFixed(2)}  /  Saldo: R$ ${valor ? parseFloat((valor - somaValorPeriodos).toFixed(2)).toFixed(2) : parseFloat((0 - somaValorPeriodos).toFixed(2)).toFixed(2)}`          
          :
          //@ts-ignore
          `Soma parcial: ${parseInt(somaValorPeriodos)}  /  Saldo: ${valor ? parseInt((valor - somaValorPeriodos)) : parseInt((0 - somaValorPeriodos))}`
        }

        {/* <Button
          color="primary"
          type="button"
          outline
          //@ts-ignore
          onClick={handleRedistruibuirPorProdutos}
          className="btn-icon btn-3 btn-sm"
          title='Redistribuir Meta por Produtos Automaticamente'
          style={{ marginLeft: 15 }}
          disabled={!(produtos && produtos.length > 0)}
        >
          <span className="btn-inner--icon">
            <i className="fas fa-divide"></i>
          </span>
          <span className="btn-inner--text">Redistribuição</span>
        </Button> */}

      </div>
    </>
  );

}

export default TablePeriodos;