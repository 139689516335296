import React, { useState } from 'react';

import './header.css'
import {
    CardHeader,
    Row,
    Col,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

export default ({
    mala_direta_id,
    erros,
    style,
    situacao,
    setSituacao
}, ...props) => {

    const situacoes = ['ativo', 'inativo'];

    return (
        <CardHeader
            style={{
                position: "sticky",
                top: 0,
                zIndex: 100,
                border: "1 solid",
                boxShadow: "0px 2px #f5f5f5",
                ...style,
            }}
        >
            <Row
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 4
                }}
            >
                <div>
                    <h1 className='d-flex'>
                        <span>{mala_direta_id ? 'Alterar Mala Direta' : 'Nova Mala Direta'}</span>                         
                    </h1>
                </div>
                <div>
                    <UncontrolledDropdown
                        group
                        direction='left'
                        size='sm'
                        //disabled
                        className="ml-2"
                    >
                        <DropdownToggle
                            caret
                            disabled={!mala_direta_id}
                            color={situacao == 'ativo' ? 'success' : 'danger'}
                        >
                            {situacao}
                        </DropdownToggle>
                        <DropdownMenu >
                            {
                                situacoes.map((item, key) => {
                                    return (
                                        <DropdownItem
                                            //href="#"
                                            key={key}
                                            onClick={e =>
                                                setSituacao(item)
                                            }
                                        >
                                            {item}
                                        </DropdownItem>
                                    )
                                })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <small class="text-danger">
                        {erros.situacao}
                    </small>
                </div>
            </Row>
        </CardHeader >
    );
}
