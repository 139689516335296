import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../contexts/Empresa'
import moment from 'moment'
import { usePersistedState } from '../../../../hooks'
import api from "../../../../services/api";
import { hasPermission } from '../../../../utils';

import TagsInput from "react-tagsinput";
import Filters from '../../../../components/Headers/Filters'
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import InputMask from "react-input-mask";
import SelectDisplayMode from '../SelectDisplayMode';
import {
    CardBody,
    Row,
    Col,
    // Input,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Button,
    Collapse,
} from "reactstrap";


export default ({ title, load, history, visaoMode, setVisaoMode, notify, ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [limpouDataEncerramento, setLimpouDataEncerramento] = usePersistedState('limpouDataEncerramento', false)
    const [dataEncerramentoInicial, setDataEncerramentoInicial] = usePersistedState('dataEncerramentoInicial', (() => {
        if (!limpouDataEncerramento) {
            const now = new Date();
            return new Date(now.getFullYear(), now.getMonth());
        }
        return null
    })())
    const [dataEncerramentoFinal, setDataEncerramentoFinal] = usePersistedState('dataEncerramentoFinal', (() => {
        if (!limpouDataEncerramento) return new Date()
        return null
    })())
    const [dataCadastroInicial, setDataCadastroInicial] = usePersistedState('dataCadastroInicial', null)
    const [dataCadastroFinal, setDataCadastroFinal] = usePersistedState('dataCadastroFinal', null)

    const [canais, setCanais] = usePersistedState('canais', [])
    const [campanha, setCampanha] = usePersistedState('campanha', -1)
    const [anunciosSelecionados, setAnunciosSelecionados] = usePersistedState('anunciosSelecionados', [])
    const [funis, setFunis] = usePersistedState('funis', [])

    //estagio 3 - Venda
    const [estagios, setEstagios] = usePersistedState('estagios', [3])

    const [expectativa, setExpectativa] = usePersistedState('expectativa', null)
    const [cliente, setCliente] = usePersistedState('cliente', '')

    //OnlyEmAbertas = false (Encerradas)    
    const [onlyEmAbertas, setOnlyEmAbertas] = usePersistedState('onlyEmAbertas', 'false')

    //motivoEncerramento = null (se em estágio venda, motivo encerramento pode ser desconsiderado)
    const [motivoEncerramento, setMotivoEncerramento] = usePersistedState('motivoEncerramento', null)

    const [colaboradores, setColaboradores] = usePersistedState('colaboradores', [])
    const [produtos, setProdutos] = usePersistedState('produtos', [])
    const [tipoProduto, setTipoProduto] = usePersistedState('tipoProduto', null)
    const [contactacao, setContactacao] = usePersistedState('contactacao', '-1')
    const [equipes, setEquipes] = usePersistedState('equipes', [])
    const [tags, setTags] = usePersistedState('tags', [])
    const [pesquisarTelefoneCliente, setPesquisarTelefoneCliente] = usePersistedState('pesquisarTelefoneCliente', false)

    //Listas para carregar os filtros
    const [canaisList, setCanaisList] = useState([])
    const [campanhas, setCampanhas] = useState([])
    const [anuncios, setAnuncios] = useState([])
    const [funisList, setFunisList] = useState([])
    // const [estagiosList, setEstagiosList] = useState([])
    // const [expectativas, setExpectativas] = useState([])
    // const [motivosEncerramentos, setMotivosEncerramentos] = useState([])
    const [users, setUsers] = useState([])
    const [produtosList, setProdutosList] = useState([])
    const [tiposProdutos, setTipoProdutos] = useState([])
    const [equipesList, setEquipesList] = useState([])

    //Flag para definir tempo de execução
    const [runLoad, setRunLoad] = useState(props.search ? props.search : true)
    const [monitorClearFilters, setMonitorClearFilters] = useState(undefined)

    //flag pra chamar o fillEquipes
    const [runFillEquipes, setRunFillEquipes] = useState(true)
    //flag pra chamar o fillMotivoEncerramento
    const [runFillMotivoEncerramento, setRunFillMotivoEncerramento] = useState(true)
    //flag pra chamar o fillProdutos
    const [runFillProdutos, setRunFillProdutos] = useState(true)

    const [maisFiltros, setMaisFiltros] = usePersistedState('maisFiltrosOportunidades', false)

    useEffect(() => {
        if (monitorClearFilters) {
            console.log('MONITOR CLEAR FILTERS');
            search(true, true)
        }
    }, [monitorClearFilters])

    useEffect(() => {
        (async () => {
            if (canaisList.length === 0) await loadCanais()
            if (campanhas.length === 0) await loadCampanhas()
            if (funisList.length === 0) await loadFunis()
            //if (estagiosList.length === 0) await loadEstagios()
            //if (expectativas.length == 0) await loadExpectativas()
            if (tiposProdutos.length === 0) await loadTiposProdutos()
            if (equipesList.length === 0) await loadEquipes()
            //Carregar combos caso o valor esteja na sessão
            if (campanha) await loadAnuncios(campanha)
            //Search comentado pra achar duplicidade de consulta inicial
            // await search(false, false)
        })()
    }, [])


    useEffect(() => {
        if (runFillEquipes) {
            fillEquipes()
        }

        if (hasPermission('ver-todas-oportunidades')) {
            loadColaboradores()
        }

        setRunFillEquipes(false)

    }, [equipes])

    useEffect(() => {
        loadProdutos(tipoProduto)
    }, [tipoProduto])


    // useEffect(() => {
    //     loadEstagios()
    // }, [funis])

    useEffect(() => {
        loadAnuncios(campanha)
    }, [canais])

    useEffect(() => {
        if (runLoad) {
            //Search comentado pra achar duplicidade de consulta inicial
            search(false, false)
            setRunLoad(false)
        }
    }, [runLoad])

    useEffect(() => {
        setMotivoEncerramento(null)
        //loadEncerramentos()
    }, [onlyEmAbertas])

    useEffect(() => {
        if (anunciosSelecionados && anunciosSelecionados.length > 0) {
            setAnunciosSelecionados(anunciosSelecionados)
        } else {
            setAnunciosSelecionados([])
        }
    }, [anuncios])


    async function handlePesquisar() {
        await search(true, false)
    }

    async function search(clearPagination, clearSort) {

        await load({
            dataCadastroInicial,
            dataCadastroFinal,
            dataEncerramentoInicial,
            dataEncerramentoFinal,
            canais,
            campanha,
            anuncios: anunciosSelecionados,
            funis,
            estagios,
            expectativa,
            cliente,
            pesquisarTelefoneCliente,
            onlyEmAbertas,
            motivoEncerramento,
            colaboradores,
            tipoProduto,
            produtos,
            tags,
            equipes: (equipes && equipes.length > 0) ? equipes : [-1],
            onlyContactadas: contactacao
        }, clearPagination, clearSort)
    }

    function handleLimparDataEncerramento() {
        setDataEncerramentoFinal(null)
        setDataEncerramentoInicial(null)
        setLimpouDataEncerramento(true)
    }

    function handleLimparDataCadastro() {
        setDataCadastroInicial(null)
        setDataCadastroFinal(null)
    }

    async function handleClearFilter() {
        setLimpouDataEncerramento(false)
        setDataEncerramentoInicial((() => {
            const now = new Date();
            return new Date(now.getFullYear(), now.getMonth());
        })())
        setDataEncerramentoFinal(new Date())
        setDataCadastroInicial(null)
        setDataCadastroFinal(null)
        setCanais([])
        setCampanha(-1)
        setAnunciosSelecionados([])
        setAnuncios([])
        setFunis([])
        setEstagios([3])
        setExpectativa(null)
        setCliente('')
        setPesquisarTelefoneCliente(false)
        setOnlyEmAbertas('false')
        setMotivoEncerramento(null)

        setProdutos([])
        setTipoProduto(null)

        setUsers([])
        setTags([])
        setContactacao('-1')

        setColaboradores([])
        setRunFillEquipes(true)
        setEquipes(null)

    }

    /* Adiciona todos os itens em <code>equipeList</code> para equipes, se não houver equipe selecionada na sessão*/
    function fillEquipes() {
        setRunFillEquipes(false)
        if (equipes && equipes.length > 0) {
            setEquipes(equipes)
        } else {
            setEquipes(equipesList.map(item => item.id))
        }
    }

    async function loadEquipes() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada.id}/equipes`)
            const data = await response.data
            if (data.length > 0) {
                setEquipesList(data)
                if (runFillEquipes) {
                    fillEquipes()
                }
                setRunFillEquipes(false)
            }
        } catch (error) {
            console.error(error)
        }
    }

    async function loadTiposProdutos() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada.id}/tipos-produtos?situacao=A`)

            if (response.data) {
                response.data.unshift({ id: -1, nome: '- Todos -' })
                setTipoProdutos(response.data)
            }

        } catch (error) {
            console.log(error)
        }
    }

    async function loadProdutos(tipoProduto) {
        if (tipoProduto && tipoProduto == -1) {
            tipoProduto = null
        }
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada.id}/produtos/`, {
                params: {
                    situacao: 'A',
                    limit: 1000,
                    tipoProduto
                }
            })
            const data = await response.data.data
            setProdutosList(data)
            if (produtos && produtos.length > 0 && runFillProdutos) {
                setProdutos(produtos)
            } else {
                setProdutos([])
            }
            setRunFillProdutos(false)
        } catch (error) {
            console.log(error)
        }
    }

    // async function loadEncerramentos() {
    //     try {
    //         const aplicacoes = []
    //         switch (onlyEmAbertas) {
    //             case 'true':
    //                 aplicacoes.push('abertura')
    //                 aplicacoes.push('null')
    //                 break;
    //             case 'false':
    //                 aplicacoes.push('encerramento')
    //                 aplicacoes.push('null')
    //                 break;
    //             default:
    //                 break;
    //         }
    //         const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/motivos-encerramentos`, {
    //             params: { aplicacoes }
    //         })
    //         if (response.data) {
    //             response.data.unshift({ id: -1, nome: '- Todos -' })
    //             setMotivosEncerramentos(response.data)
    //             if (motivoEncerramento && runFillMotivoEncerramento) {
    //                 setMotivoEncerramento(motivoEncerramento)
    //             }
    //             setRunFillMotivoEncerramento(false)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    async function loadColaboradores() {
        try {
            setColaboradores([])
            const response = await api.get(`common/empresas/${empresaSelecionada.id}/colaboradores`, {
                params: {
                    roles: ['comercial', 'relacionamento'],
                    equipes: (equipes && equipes.length > 0) ? equipes : [-1],
                }
            })
            setUsers(response.data)
            if (colaboradores && colaboradores.length > 0) {
                setColaboradores(colaboradores)
            } else {
                setColaboradores([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function loadCanais() {
        try {
            const response = await api.get(`/marketing/canais`);
            setCanaisList(response.data)
        } catch (error) {
            console.error(error);
            notify('danger', 'Não foi possível carregar canais')
        }
    }


    async function loadCampanhas() {
        const response = await api.get(`marketing/campanha/${empresaSelecionada.id}`)

        if (response.data) {
            response.data.unshift({ id: -1, nome: '- Todas -' })
            setCampanhas(response.data)
        }

    }

    async function loadAnuncios(campanha_id) {
        setCampanha(campanha_id)

        if (campanha_id) {
            var response = null;

            response = await api.get(`marketing/anuncios/${campanha_id}/${empresaSelecionada.id}`,
                {
                    params: {
                        situacao: 'ativo',
                        canais
                    }
                })

            setAnuncios(await response.data)
            if (anunciosSelecionados && anunciosSelecionados.length > 0) {
                setAnunciosSelecionados(anunciosSelecionados)
            } else {
                setAnunciosSelecionados([])
            }
        }
    }

    // async function loadEstagios() {
    //     setEstagiosList([])
    //     var response = null;

    //     if (funis && funis.length > 0) {
    //         response = await api.get(`common/empresas/${empresaSelecionada?.id}/estagios/getbyfunis`, {
    //             params: {
    //                 funis
    //             }
    //         })
    //     } else {
    //         response = await api.get(`common/empresas/${empresaSelecionada?.id}/estagios`)
    //     }

    //     if (response && response.data) {
    //         setEstagiosList(response.data)
    //     }
    // }

    async function loadFunis() {
        const response = await api.get(`common/empresas/${empresaSelecionada?.id}/funis`)
        if (response.data) {
            //response.data.unshift({ id: -1, nome: '- Todos -' })
            setFunisList(response.data)
        }
    }

    // async function loadExpectativas() {
    //     const response = await api.get(`marketing/expectativas`)
    //     if (response.data) {
    //         response.data.unshift({ id: -1, nome: '- Todos -' })
    //         setExpectativas(response.data)
    //     }
    // }

    const handleTags = tagsinput => {
        if (!tagsinput) return
        const newTags = tagsinput
            .map(tag => tag.trim().toLowerCase())
        let unique = [...new Set(newTags)];
        setTags(unique)
    };

    function handleMultipleSelect(target, state) {
        const array = Array.from(target.selectedOptions)
        state(array.map((item) => item.value))
    }


    function ColaboradorSelect({ }) {
        if (hasPermission('ver-todas-oportunidades')) {
            return (
                <>
                    <Col xs="12" lg="4" sm="12" md="6">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="example-number-input"
                            >
                                Equipes
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    multiple
                                    onSelect={({ target }) => handleMultipleSelect(target, setEquipes)}
                                    onUnselect={({ target }) => handleMultipleSelect(target, setEquipes)}
                                    options={{
                                        placeholder: "Selecione..."
                                    }}
                                    value={equipes}
                                    data={equipesList.map((item) => ({ id: item.id, text: item.nome }))}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs="12" lg="4" sm="12" md="6">
                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                Colaboradores
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    multiple
                                    onSelect={({ target }) => handleMultipleSelect(target, setColaboradores)}
                                    onUnselect={({ target }) => handleMultipleSelect(target, setColaboradores)}
                                    options={{
                                        placeholder: "Selecione os colaboradore..."
                                    }}
                                    value={colaboradores}
                                    data={
                                        users.map(user => ({ id: user.id, text: user.name }))
                                    }
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </>
            )
        }
        return null
    }


    return (
        <>
            <Filters
                title={title}
                // displayMode={() => SelectDisplayMode(visaoMode, setVisaoMode)}
                onSearch={handlePesquisar}
                clearFilters={handleClearFilter}
                onFiltersClead={setMonitorClearFilters}
            >
                <CardBody >
                    <Row style={{ padding: 4 }}>
                        <Col xs="auto" lg="6" sm="6" md="12">
                            <div
                                style={{
                                    border: '1px solid',
                                    borderRadius: '4px',
                                    borderColor: '#eee',
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    padding: 13
                                }}
                            >
                                <FormGroup style={{ flex: 1, paddingRight: 4 }}>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Conversão inicial
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "Data inicial",
                                                style: {
                                                    width: '100px'
                                                }
                                            }}
                                            value={moment(dataEncerramentoInicial)}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            renderDay={(props, currentDate, selectedDate) => {
                                                let classes = props.className;
                                                if (
                                                    dataEncerramentoInicial &&
                                                    dataEncerramentoFinal &&
                                                    dataEncerramentoInicial._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " start-date";
                                                } else if (
                                                    dataEncerramentoInicial &&
                                                    dataEncerramentoFinal &&
                                                    new Date(dataEncerramentoInicial._d + "") <
                                                    new Date(currentDate._d + "") &&
                                                    new Date(dataEncerramentoFinal._d + "") >
                                                    new Date(currentDate._d + "")
                                                ) {
                                                    classes += " middle-date";
                                                } else if (
                                                    dataEncerramentoFinal &&
                                                    dataEncerramentoFinal._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " end-date";
                                                }
                                                return (
                                                    <td {...props} className={classes}>
                                                        {currentDate.date()}
                                                    </td>
                                                );
                                            }}
                                            onChange={e => setDataEncerramentoInicial(e)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup style={{ flex: 1, paddingLeft: 4 }}>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Conversão final
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "Data final",
                                                style: {
                                                    width: '100px'
                                                }
                                            }}
                                            style={{ width: '100px' }}
                                            value={moment(dataEncerramentoFinal)}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            renderDay={(props, currentDate, selectedDate) => {
                                                let classes = props.className;
                                                if (
                                                    dataEncerramentoInicial &&
                                                    dataEncerramentoFinal &&
                                                    dataEncerramentoInicial._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " start-date";
                                                } else if (
                                                    dataEncerramentoInicial &&
                                                    dataEncerramentoFinal &&
                                                    new Date(dataEncerramentoInicial._d + "") <
                                                    new Date(currentDate._d + "") &&
                                                    new Date(dataEncerramentoFinal._d + "") >
                                                    new Date(currentDate._d + "")
                                                ) {
                                                    classes += " middle-date";
                                                } else if (
                                                    dataEncerramentoFinal &&
                                                    dataEncerramentoFinal._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " end-date";
                                                }
                                                return (
                                                    <td {...props} className={classes}>
                                                        {currentDate.date()}
                                                    </td>
                                                );
                                            }}
                                            onChange={e => setDataEncerramentoFinal(e)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <Button
                                    className='ml-2'
                                    color='secondary'
                                    outline
                                    title='Limpar datas de conversão'
                                    size='sm'
                                    onClick={handleLimparDataEncerramento}
                                >
                                    <span className="btn-inner--icon">
                                        <i className="fa fa-trash" />
                                    </span>
                                </Button>
                            </div>
                        </Col>
                        <Col xs="auto" lg="6" sm="6" md="12"
                        >
                            <div
                                style={{
                                    border: '1px solid',
                                    borderRadius: '4px',
                                    borderColor: '#eee',
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    padding: 13
                                }}
                            >
                                <FormGroup style={{ flex: 1, paddingRight: 4 }}>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Cadastro inicial
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "Data inicial",
                                                style: {
                                                    width: '100px'
                                                }
                                            }}
                                            value={moment(dataCadastroInicial)}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            renderDay={(props, currentDate, selectedDate) => {
                                                let classes = props.className;
                                                if (
                                                    dataCadastroInicial &&
                                                    dataCadastroFinal &&
                                                    dataCadastroInicial._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " start-date";
                                                } else if (
                                                    dataCadastroInicial &&
                                                    dataCadastroFinal &&
                                                    new Date(dataCadastroInicial._d + "") <
                                                    new Date(currentDate._d + "") &&
                                                    new Date(dataCadastroFinal._d + "") >
                                                    new Date(currentDate._d + "")
                                                ) {
                                                    classes += " middle-date";
                                                } else if (
                                                    dataCadastroFinal &&
                                                    dataCadastroFinal._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " end-date";
                                                }
                                                return (
                                                    <td {...props} className={classes}>
                                                        {currentDate.date()}
                                                    </td>
                                                );
                                            }}
                                            onChange={e => setDataCadastroInicial(e)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup style={{ flex: 1, paddingLeft: 4 }}>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Cadastro final
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "Data final",
                                                style: {
                                                    width: '100px'
                                                }
                                            }}
                                            style={{ width: '100px' }}
                                            value={moment(dataCadastroFinal)}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            renderDay={(props, currentDate, selectedDate) => {
                                                let classes = props.className;
                                                if (
                                                    dataCadastroInicial &&
                                                    dataCadastroFinal &&
                                                    dataCadastroInicial._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " start-date";
                                                } else if (
                                                    dataCadastroInicial &&
                                                    dataCadastroFinal &&
                                                    new Date(dataCadastroInicial._d + "") <
                                                    new Date(currentDate._d + "") &&
                                                    new Date(dataCadastroFinal._d + "") >
                                                    new Date(currentDate._d + "")
                                                ) {
                                                    classes += " middle-date";
                                                } else if (
                                                    dataCadastroFinal &&
                                                    dataCadastroFinal._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " end-date";
                                                }
                                                return (
                                                    <td {...props} className={classes}>
                                                        {currentDate.date()}
                                                    </td>
                                                );
                                            }}
                                            onChange={e => setDataCadastroFinal(e)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <Button
                                    className='ml-2'
                                    color='secondary'
                                    outline
                                    title='Limpar datas de cadastro'
                                    size='sm'
                                    onClick={handleLimparDataCadastro}
                                >
                                    <span className="btn-inner--icon">
                                        <i className="fa fa-trash" />
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 15 }}>
                        <Col xs="12" lg="12" sm="12" md="12">
                            <FormGroup>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: 1,
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <label
                                        className="form-control-label"
                                    >
                                        Cliente
                                    </label>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="check-telefone"
                                            type="checkbox"
                                            checked={pesquisarTelefoneCliente}
                                            onChange={() => setPesquisarTelefoneCliente(!pesquisarTelefoneCliente)}
                                        />
                                        <label
                                            className="custom-control-label" htmlFor="check-telefone">
                                            Pesquisar por telefone
                                        </label>
                                    </div>
                                </div>
                                <InputMask
                                    placeholder={pesquisarTelefoneCliente ? 'Telfone do cliente...' : 'Nome ou CPF/CNPJ do cliente...'}
                                    className="form-control"
                                    maskPlaceholder={null}
                                    mask={pesquisarTelefoneCliente ? "99 99999-9999" : undefined}
                                    value={cliente}
                                    onChange={e => setCliente(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: -20 }}>
                        <Col xs="12" lg="12" sm="12" md="12">
                            <Button
                                style={{ display: 'flex', padding: 0, color: '#4385b1' }}
                                className="ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={(e) => { e.preventDefault(); setMaisFiltros(!maisFiltros) }}
                            >
                                {maisFiltros ? 'menos filtros (-)' : 'mais filtros (+)'}
                            </Button>
                        </Col>
                    </Row>

                    <Collapse isOpen={maisFiltros}>
                        <Row className="py-4">
                            <Col xs="12" lg="4" sm="12" md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Canais
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Select2
                                            multiple
                                            onSelect={({ target }) => handleMultipleSelect(target, setCanais)}
                                            onUnselect={({ target }) => handleMultipleSelect(target, setCanais)}
                                            options={{
                                                placeholder: "Selecione..."
                                            }}
                                            value={canais}
                                            data={canaisList.map((item) => ({ id: item.id, text: item.nome }))}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs="12" lg="4" sm="12" md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Campanha
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Select2
                                            defaultValue="-1"
                                            onSelect={(e) => loadAnuncios(e.target.value)}
                                            options={{
                                                placeholder: "Selecione uma campanha..."
                                            }}
                                            value={campanha}
                                            data={
                                                campanhas.map((item) => ({ id: item.id, text: item.nome }))
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs="12" lg="4" sm="12" md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Anúncios
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Select2
                                            multiple
                                            onSelect={({ target }) => handleMultipleSelect(target, setAnunciosSelecionados)}
                                            onUnselect={({ target }) => handleMultipleSelect(target, setAnunciosSelecionados)}
                                            options={{
                                                placeholder: "Selecione os anúncios..."
                                            }}
                                            value={anunciosSelecionados}
                                            data={
                                                anuncios.map((item) => ({ id: item.id, text: item.nome }))
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" lg="4" sm="12" md="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Funil
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Select2
                                            multiple
                                            onSelect={({ target }) => handleMultipleSelect(target, setFunis)}
                                            onUnselect={({ target }) => handleMultipleSelect(target, setFunis)}
                                            options={{
                                                placeholder: "Selecione..."
                                            }}
                                            value={funis}
                                            data={funisList.map((item) => ({ id: item.id, text: item.nome }))}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>

                            {/* <Col xs="12" lg="2" sm="12" md="3">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Expectativa
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Select2
                                            className="input-group-alternative"
                                            defaultValue="-1"
                                            options={{
                                                placeholder: "Selecione..."
                                            }}
                                            onSelect={(e) => setExpectativa(e.target.value)}
                                            value={expectativa}
                                            data={
                                                expectativas.map((item) => ({ id: item.id, text: item.nome }))
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col> */}

                            <Col xs="12" lg="4" sm="12" md="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                    >
                                        Tipo de produto
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Select2
                                            className="input-group-alternative"
                                            defaultValue="-1"
                                            options={{
                                                placeholder: "Selecione..."
                                            }}
                                            onSelect={({ target }) => setTipoProduto(target.value)}
                                            value={tipoProduto}
                                            data={tiposProdutos.map((item) => ({ id: item.id, text: item.nome }))}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs="12" lg="4" sm="12" md="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Produtos
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Select2
                                            multiple
                                            onSelect={({ target }) => handleMultipleSelect(target, setProdutos)}
                                            onUnselect={({ target }) => handleMultipleSelect(target, setProdutos)}
                                            options={{
                                                placeholder: "Selecione..."
                                            }}
                                            value={produtos}
                                            data={produtosList.map((item) => ({ id: item.id, text: item.nome }))}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" lg="4" sm="12" md="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-number-input"
                                    >
                                        Contactação
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Select2
                                            className="input-group-alternative"
                                            defaultValue='-1'
                                            options={{
                                                placeholder: "Selecione..."
                                            }}
                                            onSelect={({ target }) => setContactacao(target.value)}
                                            value={contactacao}
                                            data={[
                                                { id: 'true', text: 'Contactados' },
                                                { id: 'false', text: 'Não contactados' },
                                                { id: '-1', text: 'Contactados e não contactados' }
                                            ]}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <ColaboradorSelect />
                        </Row>

                        <Row>
                            <Col xs="12" sm="12" md="12" lg="12">
                                <FormGroup>
                                    <label className="form-control-label" >
                                        Tags
                                    </label>
                                    <div style={
                                        {
                                            minHeight: 'calc(2.75rem + 2px)',
                                            border: '1px solid #DEE2E6',
                                            padding: 4,
                                            borderRadius: 4,
                                        }}>
                                        <TagsInput
                                            className="bootstrap-tagsinput"
                                            onChange={handleTags}
                                            tagProps={{ className: "tag badge badge-primary mr-1" }}
                                            value={tags}
                                            inputProps={{
                                                className: "",
                                                placeholder: "Adicionar tags..."
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Collapse>

                </CardBody>
            </Filters>
        </>
    )
}
