import React, { useState, useEffect, useContext } from 'react';
import api from "../../../../../services/api";
import EmpresaContex from "../../../../../contexts/Empresa";
import {
    Anuncio,
    Campanha
} from '../../../../../entities/Marketing';

import {
    Modal,
    Col,
    Row,
    FormGroup,
    Button,
} from "reactstrap";
//@ts-ignore
import Select2 from "react-select2-wrapper";

interface INewAnuncio {
    show: boolean,
    hidde(): void,
    esteiraId?: number,
    onSuccess(anuncio: Anuncio): Promise<void>,
    notify(type: string, msg: string): void,
    anunciosAdicionados: Array<Anuncio>
}

const NewAnuncio: React.FC<INewAnuncio> = ({
    show,
    hidde,
    onSuccess,
    esteiraId,
    notify,
    anunciosAdicionados
}) => {

    const { empresaSelecionada } = useContext(EmpresaContex)
    const [anuncios, setAnuncios] = useState(new Array<Anuncio>())
    const [campanhas, setCampanhas] = useState(new Array<Campanha>())
    const [campanha, setCampanha] = useState<number | undefined>(undefined)
    const [anuncio, setAnuncio] = useState<number | undefined>(undefined)

    const [saving, setSaving] = useState(false)

    useEffect(() => {
        loadCampanha()
    }, [])

    useEffect(() => {
        if (campanha) loadAnuncio()
    }, [campanha])


    function clear() {
        setCampanha(undefined)
        setAnuncio(undefined)
    }

    async function handleSave(e: React.MouseEvent) {
        e.preventDefault()
        if (!anuncio) return
        if (esteiraId) {
            await save()
        } else {
            const anuncioObject = anuncios.find(item => item.id == anuncio) as Anuncio
            anuncioObject.campanha = campanhas.find(item => item.id == campanha)
            onSuccess(anuncioObject)
        }
        clear()

    }

    async function save() {
        try {
            setSaving(true)
            await api.post(`/common/empresas/${empresaSelecionada?.id}/esteiras/${esteiraId}/anuncios`,
                {
                    anuncio_id: anuncio
                })
            await onSuccess({} as Anuncio)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível adicionar anúncio')
        } finally {
            setSaving(false)
        }
    }

    async function loadCampanha() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/campanhas`)
            const data = await response.data
            setCampanhas(data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar campanhas')
        }
    }

    async function loadAnuncio() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/campanhas/${campanha}/anuncios`,
            {
                params: {
                    situacao: 'ativo'
                }
            })
            const data = await response.data
            setAnuncios(data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar anúncios')
        }
    }

    return (
        <Modal
            size="md"
            className="modal-dialog-centered"
            isOpen={show}
            toggle={hidde}
        >
            <div className="modal-header">
                <h5 className="modal-title" >
                    Adicionar Anúncio
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={hidde}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col
                        sm={12}
                        md={12}
                        lg={12}
                    >
                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                Campanha*
                        </label>
                            <Select2
                                //@ts-ignore
                                onSelect={(e) => setCampanha(e.target.value)}
                                options={{
                                    placeholder: "Selecione uma campanha..."
                                }}
                                value={campanha}
                                data={campanhas.map(item => ({ id: item.id, text: item.nome }))}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                Anúncio*
                        </label>
                            <Select2
                                //@ts-ignore
                                onSelect={(e) => setAnuncio(e.target.value)}
                                options={{
                                    placeholder: "Selecione o anúncio..."
                                }}
                                value={anuncio}
                                data={anuncios
                                    .filter(item => !anunciosAdicionados.find(anuncio => anuncio.id === item.id))
                                    .map(item => ({ id: item.id, text: item.nome }))}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    disabled={saving}
                    color="link"
                    type="button"
                    onClick={hidde}
                >
                    voltar
            </Button>
                <Button
                    disabled={saving}
                    color="primary"
                    onClick={handleSave}
                    type="button">
                    Adicionar Anúncio
                </Button>
            </div>
        </Modal>
    )
}

export default NewAnuncio;