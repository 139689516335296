import React, { useState, useEffect, useContext } from "react";
import EmpresaContext from "../../../../contexts/Empresa";

import api from "../../../../services/api";

import { MenuComportamento } from "../../../../components/Menus";
import EquipesModal from "./Equipes";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Form from "./Form";
import PublicacaoModal from "./Publicacao/ModalList";
import ProdutosConfigs from "./Produtos";
import Publicacoes from "./Publicacoes";
import Situacoes from "./Situacaoes";
import ProgramaDeIndicacaoModal from "./ProgramaDeIndicacao";
import ProgramaDeAfiliacaoModal from "./ProgramaDeAfiliacao";
import {
  Button,
  Row,
  UncontrolledPopover,
  PopoverBody,
  Col,
  Modal,
} from "reactstrap";

export default ({
  notify,
  campanha,
  history,
  anuncioToGoPublicacoes,
  ...props
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [anuncios, setAnuncios] = useState([]);
  const [situacao, setSituacao] = useState("ativo");

  const [showAnuncios, setShowAnuncios] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [showProdutos, setShowProdutos] = useState(false);
  const [showPublicacao, setShowPublicacao] = useState(false);
  const [anuncioSelecionado, setAnuncioSelecionado] = useState(null);
  const [showEquipes, setShowEquipes] = useState(null);
  const [showProgramaDeIndicacao, setShowProgramaDeIndicacao] = useState(null);
  const [showProgramaDeAfiliacao, setShowProgramaDeAfiliacao] = useState(null);

  const [columns] = useState([
    {
      dataField: "acoes",
      formatter: (cell, row) => acoesFormatter(cell, row),
    },
    {
      dataField: "nome",
      text: "Nome",
      sort: true,
    },
    {
      dataField: "canal.nome",
      text: "Canal",
      sort: true,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "orientacao.nome",
      text: "Orientação",
      sort: true,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "center",
      headerAlign: "center",
      formatter: (row, column) => valorFormatter(row, column),
    },
    {
      dataField: "dt_inicio",
      text: "Data Início",
      sort: true,
      formatter: (cell, row) => dateFormatter(row.dt_inicio),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "dt_fim",
      text: "Data Fim",
      sort: true,
      formatter: (cell, row) => dateFormatter(row.dt_fim),
      align: "center",
      headerAlign: "center",
    },
  ]);

  useEffect(() => {
    if (campanha && campanha.id) loadAnuncios();
  }, [campanha, situacao]);

  useEffect(() => {
    if (!showModal) setAnuncioSelecionado(null);
  }, [showModal]);

  useEffect(() => {
    if (anuncioToGoPublicacoes) {
      // console.log('ANUNCIO PARA MOSTRAR PUBLICACOES')
      // console.log({anuncioToGoPublicacoes})
      editPublicacao(anuncioToGoPublicacoes);
    }
  }, [anuncioToGoPublicacoes]);

  const valorFormatter = (cell, row) => {
    return (
      <>
        {Number(row.valor).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}
      </>
    );
  };

  const dateFormatter = (date) => {
    const dt = new Date(date);
    var options = { year: "numeric", month: "long", day: "numeric" };
    return <>{dt.toLocaleDateString("pt-br", options)}</>;
  };

  function goTo({ opcao, anuncio }) {
    switch (opcao) {
      case "publicacao":
        editPublicacao(anuncio);
        break;
      case "produtos":
        //setAnuncioSelecionado(anuncio);
        setShowProdutos(true);
        break;
      case "equipes":
        setShowEquipes(anuncio);
        break;
      case "programadeindicacao":
        setShowProgramaDeIndicacao(anuncio);
        break;
      case "programadeindicacao":
        setShowProgramaDeAfiliacao(anuncio);
        break;
      default:
        break;
    }
  }

  function acoesFormatter(cell, row) {
    return (
      <MenuComportamento
        actions={[
          {
            label: "Alterar",
            icon: "far fa-edit",
            onClick: () => edit(row),
          },
          {
            label: "Publicações",
            icon: "ni ni-album-2",
            onClick: () => editPublicacao(row),
          },
          {
            label: "Produtos",
            icon: "fas fa-box",
            onClick: () => {
              setAnuncioSelecionado(row);
              setShowProdutos(true);
            },
          },
          {
            label: "Equipes",
            icon: "fas fa-user-friends",
            onClick: () => setShowEquipes(row),
          },
          {
            label: "Programa de Indicação",
            icon: "fas fa-link",
            onClick: () => setShowProgramaDeIndicacao(row),
          },
          {
            label: "Programa de Afiliação",
            icon: "fas fa-sitemap",
            onClick: () => setShowProgramaDeAfiliacao(row),
          },
        ]}
      />
    );
  }

  function handleOnBack() {
    history.push(`/admin/campanhas`);
  }

  function editPublicacao(anuncio) {
    //setShowPublicacao(true)
    setShowAnuncios(false);
    setShowPublicacao(true);
    setAnuncioSelecionado(anuncio);
  }

  function handlerGoBackEditPublicacao() {
    //setShowPublicacao(true)
    setShowAnuncios(true);
    setShowPublicacao(false);
    setAnuncioSelecionado(null);
  }

  function edit(anuncio) {
    setAnuncioSelecionado(anuncio);
    setShowModal(true);
  }

  function handleSaveAnuncio() {
    notify("success", "Anúncio salvo");
    loadAnuncios();
    setShowModal(false);
  }

  async function loadAnuncios() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada.id}/campanhas/${campanha.id}/anuncios`,
        {
          params: {
            situacao: situacao || "",
          },
        }
      );
      if (response.data) {
        setAnuncios(response.data);
      }
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar os anúncios");
    }
  }

  function ConfigModal({}) {
    const onClose = () => {
      setShowProdutos(false);
      setAnuncioSelecionado(false);
    };
    return (
      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={showProdutos}
        toggle={onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Produtos
            <br />
            <small className="text-muted">
              {anuncioSelecionado ? anuncioSelecionado.nome : null}
            </small>
          </h5>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <h4 style={{ textAlign: "center" }}>
            Gerenciar Produtos
            <Button
              color="secondary"
              id="tooltip-998822"
              outline
              size="sm"
              type="button"
            >
              ?
            </Button>
            <UncontrolledPopover placement="top" target="tooltip-998822">
              <PopoverBody>
                Caso o anúncio esteja captando oportunidades, os produtos
                adicionados aqui serão adicionados nas novas oportunidades
                geradas por este anúncio
              </PopoverBody>
            </UncontrolledPopover>
          </h4>
          <ProdutosConfigs
            notify={notify}
            anuncio={anuncioSelecionado}
            onSuccess={handleSaveAnuncio}
            onBack={onClose}
          />
        </div>
      </Modal>
    );
  }

  return (
    <>
      {/* ------------------ CAMPOS DE ANÚNCIOS ------------------------- */}
      <div
        style={{
          display: showAnuncios ? "flex" : "none",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <EquipesModal
          anuncio={showEquipes}
          show={showEquipes}
          onClose={() => setShowEquipes(null)}
          notify={notify}
        />
        {showProgramaDeIndicacao && (
          <ProgramaDeIndicacaoModal
            anuncio={showProgramaDeIndicacao}
            show={showProgramaDeIndicacao}
            onClose={() => setShowProgramaDeIndicacao(null)}
            // notify={notify}
          />
        )}
        {showProgramaDeAfiliacao && (
          <ProgramaDeAfiliacaoModal
            anuncio={showProgramaDeAfiliacao}
            show={showProgramaDeAfiliacao}
            onClose={() => setShowProgramaDeAfiliacao(null)}
            // notify={notify}
          />
        )}
        <Button
          color="primary"
          type="button"
          className="btn-icon btn-3"
          onClick={() => setShowModal(!showModal)}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-fat-add"></i>
          </span>
          <span className="btn-inner--text">Novo Anúncio</span>
        </Button>

        <ConfigModal />
        <Modal
          size="lg"
          className="modal-dialog-centered"
          isOpen={showModal}
          backdrop="static"
          toggle={() => setShowModal(!showModal)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {anuncioSelecionado ? "Alterar Anúncio" : "Novo Anúncio"}
              <br />
              <small className="text-muted" title={`Código Anúncio`}>
                {anuncioSelecionado ? "#" + anuncioSelecionado.id : ""}
              </small>
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModal(!showModal)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              notify={notify}
              goTo={(opcao) => goTo({ opcao, anuncio: anuncioSelecionado })}
              anuncioId={anuncioSelecionado?.id}
              onSuccess={handleSaveAnuncio}
              close={() => setShowModal(false)}
              campanhaId={campanha.id}
            />
          </div>
        </Modal>
      </div>
      <div
        style={{
          display: showAnuncios ? "flex" : "none",
          flex: 1,
          justifyContent: "flex-end",
          marginBottom: -10,
        }}
      >
        <Situacoes value={situacao} onChange={setSituacao} />
      </div>
      <Row
        style={{
          display: showAnuncios ? "flex" : "none",
        }}
      >
        <Col>
          <ToolkitProvider
            data={anuncios}
            keyField="nome"
            columns={columns}
            search
          >
            {(props) => (
              <div className="table-responsive py-4" style={{ minHeight: 380 }}>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                />
              </div>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
      <Row
        //botaoVoltar da tela de Anuncios
        style={{
          display: showAnuncios ? "flex" : "none",
        }}
      >
        <Col>
          <div className="float-right ">
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              //onClick={() => history.goBack()}
              onClick={() => handleOnBack()}
            >
              Voltar
            </Button>
          </div>
        </Col>
      </Row>

      {/* ------------------ CAMPOS DE PUBLICAÇÔES ------------------------- */}

      <Publicacoes
        show={!showAnuncios}
        anuncio={anuncioSelecionado}
        history={history}
      />

      <Row
        //botaoVoltar da tela de Publicacoes
        style={{
          display: !showAnuncios ? "flex" : "none",
        }}
      >
        <Col>
          <div className="float-right ">
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => handlerGoBackEditPublicacao()}
            >
              Voltar
            </Button>
          </div>
        </Col>
      </Row>
      {/* <iframe  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT-QoQ-93-v1X28-lHtuT6GQQQh7NdPS4U7QW_NeWsq5njP5NwElpDT85KI4TfIIvjlUMbaEmungdLf/pubhtml?gid=1249934430&amp;single=true&amp;widget=true&amp;headers=false"></iframe> */}
    </>
  );
};
