import React, { useState, useEffect } from 'react';
import api from "../../../../services/api";
import { GrupoEstrategico } from "../../../../entities/Mentoring";

//@ts-ignore
import Select2 from "react-select2-wrapper";
import Filters from '../../../../components/Headers/Filters'
import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
} from "reactstrap";
import Situacoes from '../Situacoes';

type Props = {
    title: string,
    notify(type: string, text: string): void,
    load(): Promise<void>,
    nomePdca?: string
    setNomePdca(value: string): void
    situacao: string,
    setSituacao(value: string): void,
    setIsClearFilterAction(value: boolean): void
}

const PdcaFilters: React.FC<Props> = ({ title, notify, load, nomePdca, setNomePdca, situacao, setSituacao, setIsClearFilterAction, ...props }) => {

    function throwError(text: string) {
        if (notify) notify('danger', text)
    }

    function search() {
        load()
    }

    function clearFilters() {
        setNomePdca('');
        //validação usada para evitar que o useEffect de situação seja acionado
        //quando o valor for reiniciado pelo botão limpar
        if(situacao && situacao == 'com_acoes'){
            setIsClearFilterAction(false);    
        }else{
            setIsClearFilterAction(true);
            setSituacao('com_acoes');
        }
    }

    return (
        <>
            <Filters
                onSearch={search}
                title={<h1>{title}</h1>}
                onFiltersClead={() => { }}
                clearFilters={() => { clearFilters() }}
                isLoading={false}
                isOpen={false}
            >
                <Row>
                    <Col
                        lg='6'
                        sm='12'
                        md='6'
                    >
                        <FormGroup id='formFiltersPDCA'>
                            <label>
                                Nome
                            </label>
                            {/* @ts-ignore */}
                            <Input
                                value={nomePdca}
                                onChange={e => setNomePdca(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Filters>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-end',
                    marginBottom: -10
                }}
            >
                <Situacoes
                    value={situacao}
                    onChange={setSituacao}
                />
            </div>

        </>
    );
}


export default PdcaFilters