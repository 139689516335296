import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import api from "../../../../services/api";
import Portal from "../../../Portal";
import { useHistory } from "react-router-dom";

interface TipoAgendamentoEmpresa {
  tipoAgendamento: TipoAgendamento;
  tipo_agendamento_id: number;
}
interface TipoAgendamento {
  id: number;
  nome: string;
  descricao: string;
  situacao: boolean;
  cor: string;
  is_public: boolean;
  padrao: "A" | "I";
  icon: string;
}

interface EventModalProps {
  visible: boolean;
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  onSave: () => {};
  eventId?: string | number | undefined;
  startDate?: Date | string;
  agendamento?: any;
  tipoAgendamento?: TipoAgendamento;
  nomeCliente?: string;
  oportunidadeId?: string | number;
  produtoOportunidadeId?: string | number;
}

const EventModal: React.FC<EventModalProps> = ({
  visible = false,
  toggleModal,
  onSave,
  eventId,
  onClose,
  startDate,
  agendamento,
  tipoAgendamento,
  nomeCliente,
  oportunidadeId = null,
  produtoOportunidadeId = null,
}) => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [oportunidadeIdExistente, setOportunidadeIdExistente] = useState<string | number | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [concluido, setConcluido] = useState<boolean>(false);
  const [alert, setAlert] = useState<any>(null);
  const [tiposAgendamentos, setTiposAgendamentos] = useState<TipoAgendamento[]>(
    []
  );
  const [tipoAgendamentoSelecionado, setTipoAgendamentoSelecionado] =
    useState<any>(() =>
      tipoAgendamento
        ? { label: tipoAgendamento.nome, value: tipoAgendamento.id }
        : {}
    );
  const [eventTitle, setEventTitle] = useState(() =>
    nomeCliente ? `${nomeCliente} - ${tipoAgendamento?.nome}` : ""
  );
  const [eventDescription, setEventDescription] = useState(() =>
    tipoAgendamento ? tipoAgendamento.descricao : ""
  );
  const [dataCadastroInicial, setDataCadastroInicial] = useState<any>(
    new Date() + ""
  );
  const [dataCadastroFinal, setDataCadastroFinal] = useState<any>(
    new Date(new Date().getTime() + 15 * 60000) + ""
  );
  const [diaInteiro, setDiaInteiro] = useState(false);

  // add new event type states

  // TODO: dinâmico de se existe um tipo enviado
  const [newEventModal, setNewEventModal] = useState(false);

  const [newEventName, setNewEventName] = useState("");
  const [newEventTypeDescription, setNewEventTypeDescription] = useState("");
  const [newEventSituacao, setNewEventSituacao] = useState(true);
  const [newEventColor, setNewEventColor] = useState("#12ccf0");
  const [newEventPrivacy, setNewEventPrivacy] = useState(false);
  const history = useHistory();

  const closeNewEventModal = () => {
    setNewEventModal(false);
    setNewEventName("");
    setNewEventTypeDescription("");
    setNewEventSituacao(true);
    setNewEventColor("#12ccf0");
    setNewEventPrivacy(false);
  };

  //#endregion

  useEffect(() => {
    if (!visible) return;
    loadTiposAgendamentos();
    if (eventId) loadEvent();
    const dateReceived = startDate ? new Date(startDate) : new Date();
    dateReceived.setHours(12, 0, 0, 0);
    setDataCadastroInicial(moment(dateReceived));
    setDataCadastroFinal(moment(dateReceived).add(15, "minutes"));

    if (agendamento) {
      const { data_agendamento, nome } = agendamento;
      setEventTitle(nome);
      if (
        new Date(data_agendamento).toLocaleDateString("pt-br") ==
        // @ts-ignore
        new Date(startDate).toLocaleDateString("pt-br")
      ) {
        setDataCadastroInicial(moment(data_agendamento));
        setDataCadastroFinal(moment(data_agendamento).add(15, "minutes"));
      }
    }

    return () => {
      setEventTitle("");
      setEventDescription("");
      setTipoAgendamentoSelecionado(null);
      setConcluido(false);
      setSubmitted(false);
      setOportunidadeIdExistente(null);
    };
  }, [visible]);

  useEffect(() => {
    if (!diaInteiro) return;
    changeDateToWholeDay(dataCadastroInicial);
  }, [diaInteiro]);

  useEffect(() => {
    if (dataCadastroFinal < dataCadastroInicial) {
      setDataCadastroFinal(moment(dataCadastroInicial).add(15, "minutes"));
    }
  }, [dataCadastroInicial]);

  async function loadTiposAgendamentos() {
    try {
      setIsLoading(true);
      const { data } = await api.get<TipoAgendamentoEmpresa[]>(
        `marketing/tipos-agendamentos-empresas`
      );
      const tiposAgendamentosEmpresa = data.map((tae) => tae.tipoAgendamento);
      setTiposAgendamentos(tiposAgendamentosEmpresa);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function loadEvent() {
    try {
      setIsLoading(true);
      const { data } = await api.get(`marketing/agendamentos/${eventId}`);
      setTipoAgendamentoSelecionado({
        value: data.tipoAgendamento.id,
        label: data.tipoAgendamento.nome,
      });
      setEventTitle(data.titulo);
      setEventDescription(data.descricao);
      setDataCadastroInicial(new Date(data.data_inicio));
      setDataCadastroFinal(new Date(data.data_fim));
      setOportunidadeIdExistente(data?.oportunidade_id);
      setConcluido(data.data_conclusao);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function addNewEvent() {
    try {
      setIsLoading(true);
      await api.post(`marketing/agendamentos`, {
        tipo_agendamento_id: tipoAgendamentoSelecionado.value,
        titulo: eventTitle,
        descricao: eventDescription,
        data_fim: dataCadastroFinal,
        data_inicio: dataCadastroInicial,
        produto_oportunidade_id: produtoOportunidadeId,
        oportunidade_id: oportunidadeId,
      });

      setAlert(
        <Portal id="sweet-alert-portal">
          <ReactBSAlert
            success
            style={{ display: "block" }}
            title="Sucesso!"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            Tarefa adicionada com sucesso!
          </ReactBSAlert>
        </Portal>
      );
      onClose();
      toggleModal(false);
      onSave();
    } catch (error) {
      console.error(error);
      setSubmitted(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function addNewEventType() {
    try {
      setIsLoading(true);
      await api.post(`marketing/tipos-agendamentos`, {
        nome: newEventName,
        descricao: newEventTypeDescription,
        situacao: newEventSituacao,
        cor: newEventColor,
        is_public: newEventPrivacy,
      });
      setAlert(
        <Portal id="sweet-alert-portal">
          <ReactBSAlert
            success
            style={{ display: "block" }}
            title="Sucesso!"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            Novo tipo de tarefa adicionado com sucesso!
          </ReactBSAlert>
        </Portal>
      );
      closeNewEventModal();
      loadTiposAgendamentos();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    return;
  }

  async function updateEvent() {
    try {
      setIsLoading(true);
      await api.put(`marketing/agendamentos/${eventId}`, {
        tipo_agendamento_id: tipoAgendamentoSelecionado.value,
        titulo: eventTitle,
        descricao: eventDescription,
        data_fim: dataCadastroFinal,
        data_inicio: dataCadastroInicial,
      });

      onClose();
      onSave();

      setAlert(
        <Portal id="sweet-alert-portal">
          <ReactBSAlert
            success
            style={{ display: "block" }}
            title="Sucesso!"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            Tarefa atualizado com sucesso!
          </ReactBSAlert>
        </Portal>
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleConfirmarConcluido() {
    setAlert(
      <Portal id="sweet-alert-portal">
        <ReactBSAlert
          warning
          title="Marcar como concluído?"
          onConfirm={marcarComoConcluido}
          onCancel={() => setAlert(null)}
          showCancel
          disabled={isLoading}
          confirmBtnBsStyle="primary"
          confirmBtnText="Sim"
          cancelBtnBsStyle="link"
          cancelBtnText="Não"
          btnSize=""
        />
      </Portal>
    );
  }

  function handleConfirmarPendente() {
    setAlert(
      <Portal id="sweet-alert-portal">
        <ReactBSAlert
          warning
          title="Marcar como pendente?"
          onConfirm={marcarComoPendente}
          onCancel={() => setAlert(null)}
          showCancel
          disabled={isLoading}
          confirmBtnBsStyle="primary"
          confirmBtnText="Sim"
          cancelBtnBsStyle="link"
          cancelBtnText="Não"
          btnSize=""
        />
      </Portal>
    );
  }

  async function marcarComoConcluido() {
    try {
      setIsLoading(true);

      await api.put(`marketing/agendamentos/${eventId}/concluir`, {
        data_conclusao: new Date(),
      });

      loadEvent();
      onSave();

      setAlert(
        <Portal id="sweet-alert-portal">
          <ReactBSAlert
            success
            style={{ display: "block" }}
            title="Sucesso!"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            Tarefa marcada como concluída com sucesso!
          </ReactBSAlert>
        </Portal>
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function marcarComoPendente() {
    try {
      setIsLoading(true);

      await api.put(`marketing/agendamentos/${eventId}/concluir`, {
        data_conclusao: null,
      });

      loadEvent();
      onSave();

      setAlert(
        <Portal id="sweet-alert-portal">
          <ReactBSAlert
            success
            style={{ display: "block" }}
            title="Sucesso!"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            Sua tarefa foi marcada como pendente.
          </ReactBSAlert>
        </Portal>
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function changeDateToWholeDay(dataInicial: Date) {
    const startOfDay = new Date(dataInicial);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(dataInicial);
    endOfDay.setHours(23, 59, 59, 999);
    setDataCadastroInicial(startOfDay);
    setDataCadastroFinal(endOfDay);
  }

  const inputBorderStyles =
    submitted && !tipoAgendamentoSelecionado ? { borderColor: "red" } : {};

  const customStyles = {
    container: (provided) => ({ ...provided, flex: 1 }),
    control: (provided) => ({
      ...provided,
      minHeight: "46px",
      ...inputBorderStyles,
    }),
  };

  return (
    <>
      {alert}
      <Modal
        isOpen={visible}
        toggle={onClose}
        className="modal-dialog-centered "
      >
        <div className="modal-header d-flex space-between">
          <h5 className="modal-title">
            <span>{eventId ? "Editar Tarefa" : "Nova Tarefa"}</span>
          </h5>
          {oportunidadeIdExistente && (
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                history.push(`/admin/oportunidades/${oportunidadeIdExistente}/edit`);
              }}
            >
              Ir para oportunidade
            </Button>
          )}
        </div>
        <div className="modal-body">
          <form className="new-event--form">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example-number-input"
              >
                Tipo de tarefa
              </label>
              <InputGroup className="input-group">
                <Select
                  placeholder="Selecione o tipo de tarefa..."
                  styles={customStyles}
                  value={tipoAgendamentoSelecionado}
                  onChange={(item) => setTipoAgendamentoSelecionado(item)}
                  options={tiposAgendamentos.map((item) => ({
                    value: item.id,
                    label: item.nome,
                  }))}
                />
                <Input
                  className="d-none"
                  aria-hidden
                  invalid={submitted && !tipoAgendamentoSelecionado}
                />
                <FormFeedback className="col-12 p-0">
                  Selecione um tipo de tarefa.
                </FormFeedback>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Título</label>
              <Input
                className="form-control new-event--title"
                placeholder="Título da tarefa"
                type="text"
                defaultValue={eventTitle}
                onChange={(e) => setEventTitle(e.target.value)}
                invalid={submitted && eventTitle.length === 0}
              />
              <FormFeedback>Digite um título para a tarefa.</FormFeedback>
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Descrição</label>
              <Input
                className="form-control edit-event--description textarea-autosize"
                placeholder="Descrição da tarefa"
                type="textarea"
                defaultValue={eventDescription}
                style={{ height: "25vh" }}
                onChange={(e) => setEventDescription(e.target.value)}
              />
              <i className="form-group--bar" />
            </FormGroup>
            <input className="edit-event--id" type="hidden" />

            <Row>
              <Col xs={6} md={6} lg={6} xl={6}>
                <FormGroup style={{ flex: 1, paddingRight: 4 }}>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Data inicial
                  </label>
                  <InputGroup className="input-group flex-nowrap">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Data inicial",
                        disabled: diaInteiro,
                      }}
                      value={moment(dataCadastroInicial)}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          dataCadastroInicial &&
                          dataCadastroFinal &&
                          dataCadastroInicial._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          dataCadastroInicial &&
                          dataCadastroFinal &&
                          new Date(dataCadastroInicial._d + "") <
                            new Date(currentDate._d + "") &&
                          new Date(dataCadastroFinal._d + "") >
                            new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          dataCadastroFinal &&
                          dataCadastroFinal._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) => setDataCadastroInicial(e)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={6} md={6} lg={6} xl={6}>
                <FormGroup style={{ flex: 1, paddingRight: 4 }}>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Data final
                  </label>
                  <InputGroup className="input-group flex-nowrap">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Data final",
                        disabled: diaInteiro,
                      }}
                      value={moment(dataCadastroFinal)}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          dataCadastroInicial &&
                          dataCadastroFinal &&
                          dataCadastroInicial._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          dataCadastroInicial &&
                          dataCadastroFinal &&
                          new Date(dataCadastroInicial._d + "") <
                            new Date(currentDate._d + "") &&
                          new Date(dataCadastroFinal._d + "") >
                            new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          dataCadastroFinal &&
                          dataCadastroFinal._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) => setDataCadastroFinal(e)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup className="d-flex flex-column">
              <label className="form-control-label">
                A tarefa ocorrerá ao longo de todo o dia?
              </label>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  onChange={() => setDiaInteiro(!diaInteiro)}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </FormGroup>
          </form>
        </div>
        <div className={`modal-footer ${concluido && "p-0"}`}>
          {eventId && concluido ? (
            <div
              className="bg-gradient-danger rounded-bottom d-flex flex-column justify-content-center align-items-center"
              style={{ width: "100%", padding: "24px" }}
            >
              <p className="text-center font-weight-bold text-white">
                Tarefa Encerrada
              </p>
              <Button
                type="button"
                color="link"
                style={{ textDecoration: "underline", color: "white" }}
                onClick={handleConfirmarPendente}
              >
                Marcar como pendente
              </Button>
            </div>
          ) : (
            <>
              {eventId && (
                <Button
                  type="button"
                  color="danger"
                  style={{ marginRight: "auto" }}
                  onClick={handleConfirmarConcluido}
                  disabled={concluido}
                >
                  {concluido ? "Tarefa concluído" : "Marcar como concluída"}
                </Button>
              )}
              <Button
                className="ml-auto"
                color="link"
                type="button"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                className="new-event--add d-flex align-items-center"
                color="primary"
                type="button"
                onClick={eventId ? updateEvent : addNewEvent}
                disabled={isLoading}
              >
                <Spinner
                  hidden={!isLoading}
                  className="mr-2"
                  color="light"
                  size="sm"
                />
                {eventId ? "Salvar" : "Criar"}
              </Button>
            </>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={newEventModal}
        toggle={closeNewEventModal}
        className="modal-dialog-centered"
      >
        <ModalHeader>Novo Tipo de Tarefa</ModalHeader>
        <div className="modal-body">
          <form className="new-event--form">
            <FormGroup>
              <label className="form-control-label">Nome</label>
              <Input
                className="form-control new-event--title"
                placeholder="Nome do novo tipo de tarefa"
                type="text"
                onChange={(e) => setNewEventName(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Descrição</label>
              <Input
                className="form-control edit-event--description textarea-autosize"
                placeholder="Descreva aqui o tipo de tarefa"
                type="textarea"
                defaultValue={newEventTypeDescription}
                style={{ height: "25vh" }}
                onChange={(e) => setNewEventTypeDescription(e.target.value)}
              />
              <i className="form-group--bar" />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Situação</label>
              <Input
                className="form-control new-event--title"
                placeholder="Situação"
                type="select"
                onChange={(e) =>
                  setNewEventSituacao(e.target.value == "ativo" ? true : false)
                }
              >
                <option value="ativo">Ativo</option>
                <option value="inativo">Inativo</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Cor</label>
              <Input
                className="form-control new-event--title"
                placeholder="Cor"
                type="color"
                defaultValue="#12ccf0"
                onChange={(e) => setNewEventColor(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">Visibilidade</label>
              <Input
                className="form-control new-event--title"
                placeholder="Visibilidade"
                type="select"
                onChange={(e) =>
                  setNewEventPrivacy(e.target.value == "publico" ? true : false)
                }
              >
                <option value="privado">Privado</option>
                <option value="publico">Público</option>
              </Input>
            </FormGroup>

            <input className="edit-event--id" type="hidden" />
          </form>
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={closeNewEventModal}
          >
            Cancelar
          </Button>
          <Button
            className="new-event--add d-flex align-items-center"
            color="primary"
            type="button"
            disabled={isLoading}
            onClick={addNewEventType}
          >
            <Spinner
              hidden={!isLoading}
              className="mr-2"
              color="light"
              size="sm"
            />
            Salvar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EventModal;
