import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  UncontrolledPopover,
  PopoverBody,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import api from '../../../../../../../services/api';
import { Empresa } from '../../../../../../../entities/Common';
import { EmpresaMarca } from '../..';

interface MarcaModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  empresaMarca: EmpresaMarca | null;
  minhasMarcas: EmpresaMarca[];
}

export default function MarcaModal({
  isOpen,
  onClose,
  onSuccess,
  empresaMarca,
  minhasMarcas,
}: MarcaModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [catalogoIntegrado, setCatalogoIntegrado] = useState(true);
  const [marcaPagina, setMarcaPagina] = useState(true);
  const [empresasMarca, setEmpresasMarca] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [empresaMarcaSelecionada, setEmpresaMarcaSelecionada] = useState<any>(
    []
  );

  useEffect(() => {
    buscarEmpresasMarca();
  }, []);

  useEffect(() => {
    if (empresaMarca) {
      setEmpresaMarcaSelecionada({
        value: empresaMarca.id,
        label: empresaMarca.nome,
      });
      setCatalogoIntegrado(empresaMarca.catalogo);
      setMarcaPagina(empresaMarca.logo);
    }
  }, [empresaMarca]);

  async function buscarEmpresasMarca() {
    try {
      const { data: empresas } = await api.get<Empresa[]>(
        `/common/empresas-marca`
      );

      if (empresas) {
        const result = empresas.map((emp) => ({
          value: emp.id,
          label: emp.nome,
        }));
        setEmpresasMarca(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const selectOptions = useMemo(() => {
    return empresasMarca.filter(
      (emp) => !minhasMarcas.some((empm) => emp.value == empm.id)
    );
  }, [minhasMarcas]);

  async function handleSubmit(shouldClose: boolean = false) {
    try {
      setIsLoading(true);
      if (empresaMarca) {
        await api.put(`/common/empresas/minhas-marcas/${empresaMarca.id}`, {
          catalogo: catalogoIntegrado,
          logo: marcaPagina,
        });
      } else {
        await api.post(`/common/empresas/minhas-marcas`, {
          empresa_marca_id: empresaMarcaSelecionada.value,
          catalogo: catalogoIntegrado,
          logo: marcaPagina,
        });
      }
      onSuccess();

      if (shouldClose) {
        onClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function clearFields() {
    setEmpresaMarcaSelecionada(null);
    setCatalogoIntegrado(true);
    setMarcaPagina(true);
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} onClosed={clearFields}>
      <ModalHeader toggle={onClose}>
        {empresaMarca ? 'Editar' : 'Nova'} Marca
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Marca</Label>
          <Select
            isDisabled={Boolean(empresaMarca)}
            placeholder="Selecione a marca"
            value={empresaMarcaSelecionada}
            onChange={(item) => setEmpresaMarcaSelecionada(item)}
            options={selectOptions}
          />
        </FormGroup>

        <FormGroup check className="d-flex align-items-center">
          <Label check>
            <Input
              type="checkbox"
              defaultChecked={catalogoIntegrado}
              onChange={(event) => setCatalogoIntegrado(event.target.checked)}
            />{' '}
            Catálogo integrado online
          </Label>
          <Button
            color="secondary"
            id="catalogo-tooltip"
            outline
            size="sm"
            type="button"
            className="ml-2"
          >
            ?
          </Button>
          <UncontrolledPopover placement="top" target="catalogo-tooltip">
            <PopoverBody>
              Ao selecionar essa opção, você exibirá os produtos desta marca nas
              suas páginas de vendas, ampliando o catálogo disponível para seus
              clientes. Seus produtos também serão exibidos.
            </PopoverBody>
          </UncontrolledPopover>
        </FormGroup>
        <FormGroup check className="d-flex align-items-center">
          <Label check>
            <Input
              type="checkbox"
              defaultChecked={marcaPagina}
              onChange={(event) => setMarcaPagina(event.target.checked)}
            />{' '}
            Apresentar marca em minhas páginas
          </Label>
          <Button
            color="secondary"
            id="marca-tooltip"
            outline
            size="sm"
            type="button"
            className="ml-2"
          >
            ?
          </Button>
          <UncontrolledPopover placement="top" target="marca-tooltip">
            <PopoverBody>
              Ao selecionar essa opção, algumas das suas páginas de vendas
              exibirão a logo desta marca, reforçando a parceria entre as
              empresas.
            </PopoverBody>
          </UncontrolledPopover>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Voltar
        </Button>
        <Button
          disabled={isLoading}
          color="primary"
          onClick={() => handleSubmit(false)}
        >
          {isLoading && <Spinner size="sm" />} Salvar
        </Button>
        <Button
          disabled={isLoading}
          color="primary"
          onClick={() => handleSubmit(true)}
        >
          {isLoading && <Spinner size="sm" />} Salvar e Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
