import React, { useState, useEffect, useContext } from 'react';
//@ts-ignore
import Select2 from 'react-select2-wrapper';
import { usePersistedState } from '../../../hooks'
import Filters from '../../../components/Headers/Filters'
import { Meta } from '../../../entities/Marketing';
import api from "../../../services/api";
import EmpresaContext from "../../../contexts/Empresa";

import {
    Row,
    Col,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";

import ReactDatetimeClass from 'react-datetime';
import moment from 'moment';

interface Props {
    title: React.ReactElement,
    data: Array<Meta> | undefined
    notify(type: string, msg: string): void,
    load(filters: object): void
    search?: boolean
}

const FilterMetas: React.FC<Props> = ({ title, notify, load, ...props }) => {
    const { empresaSelecionada } = useContext(EmpresaContext)
    const [mes, setMes] = usePersistedState('mesMeta', getMesAtual())
    const [ano, setAno] = usePersistedState('anoMeta', getAnoAtual())
    const [unidade, setUnidade] = usePersistedState('unidadeMeta', null)
    const [tipoProduto, setTipoProduto] = usePersistedState('tipoProdutoMeta', null)

    const [tiposProdutosList, setTipoProdutosList] = useState([])
    const [meses, setMeses] = useState([])

    //Flag para definir tempo de execução
    //pra que essa validação
    const [runLoad, setRunLoad] = useState(true)

    useEffect(() => {
        if (runLoad) {
            search()
            setRunLoad(false)
        }
    }, [runLoad])

    useEffect(() => {
        (async () => {
            if (meses.length == 0) await loadMeses()
            if (tiposProdutosList.length === 0) loadTiposProdutos()
            //await search()
        })()
    }, [])


    function throwError(text: string) {
        if (notify) notify('danger', text)
    }

    async function loadTiposProdutos() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/tipos-produtos`)
            setTipoProdutosList(response.data)
        } catch (error) {
            console.log(error)
            throwError('Não foi possível carregar tipos de produtos')
        }
    }

    async function loadMeses() {
        const listMeses = [
            { id: -1, nome: '-Todos-' },
            { id: 1, nome: 'Janeiro' },
            { id: 2, nome: 'Fevereiro' },
            { id: 3, nome: 'Março' },
            { id: 4, nome: 'Abril' },
            { id: 5, nome: 'Maio' },
            { id: 6, nome: 'Junho' },
            { id: 7, nome: 'Julho' },
            { id: 8, nome: 'Agosto' },
            { id: 9, nome: 'Setembro' },
            { id: 10, nome: 'Outubro' },
            { id: 11, nome: 'Novembro' },
            { id: 12, nome: 'Dezembro' }];
        //@ts-ignore
        setMeses(listMeses);
    }


    function getMesAtual() {
        const now = new Date();
        return (now.getMonth() + 1);
    }

    function getAnoAtual() {
        const now = new Date();
        return now.getFullYear();
    }

    function search() {
        load({
            mes: mes,
            ano: ano,
            unidade: unidade,
            tipo_produto_id: tipoProduto
        })
    }

    function handleClearFilter() {
        setMes(getMesAtual())
        setAno(getAnoAtual())
        setUnidade('T')
        setTipoProduto([])
    }

    function handleMultipleSelect(target: any, state: any) {
        const array = Array.from(target.selectedOptions)
        state(array.map((item: any) => item.value))
    }


    return (
        <>
            <Filters
                onSearch={search}
                title={<h1>Metas</h1>}
                onFiltersClead={handleClearFilter}
                isLoading={undefined}
                clearFilters={undefined}
                isOpen={undefined}
            >

                <Row style={{ paddingBottom: '1rem' }}>
                    <Col
                        xl={2}
                        lg={2}
                        md={4}
                        sm={4}
                    >
                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                Mês
                            </label>

                            <InputGroup className="input-group-alternative">
                                <Select2
                                    className="input-group-alternative"
                                    options={{
                                        placeholder: "Selecione..."
                                    }}
                                    //@ts-ignore
                                    onSelect={(e) => setMes(e.target.value)}
                                    value={mes}
                                    data={
                                        //@ts-ignore
                                        meses.map((item) => ({ id: item.id, text: item.nome }))
                                    }
                                />
                            </InputGroup>
                        </FormGroup>

                    </Col>

                    <Col
                        xl={2}
                        lg={2}
                        md={3}
                        sm={3}
                    >
                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                Ano
                            </label>

                            <InputGroup className="input-group-alternative">
                                <Input
                                    id="example-number-input"
                                    type="number"
                                    min={getAnoAtual() - 20}
                                    max={getAnoAtual() + 20}
                                    value={ano}
                                    onChange={e => setAno(e.target.value)}
                                />
                            </InputGroup>
                        </FormGroup>

                    </Col>


                    <Col
                        xl={2}
                        lg={4}
                        md={5}
                        sm={5}
                    >
                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                Unidade
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    className="input-group-alternative"
                                    defaultValue="-1"
                                    options={{
                                        placeholder: "Selecione..."
                                    }}
                                    //@ts-ignore
                                    onSelect={(e) => setUnidade(e.target.value)}
                                    value={unidade}
                                    data={[{
                                        id: 'T', text: '-Todas-'
                                    }, {
                                        id: 'M', text: 'Monetária'
                                    },
                                    {
                                        id: 'Q', text: 'Quantidade'
                                    }
                                    ]}
                                />
                            </InputGroup>


                        </FormGroup>

                    </Col>

                    <Col
                        xl={4}
                        lg={8}
                        md={12}
                        sm={12}
                    >
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="example-number-input"
                            >
                                Tipos de produtos
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    multiple
                                    //@ts-ignore
                                    onSelect={(e) => handleMultipleSelect(e.target, setTipoProduto)}
                                    //@ts-ignore
                                    onUnselect={(e) => handleMultipleSelect(e.target, setTipoProduto)}
                                    value={tipoProduto}
                                    //@ts-ignore
                                    data={tiposProdutosList.map((item) => ({ id: item.id, text: item.nome }))}
                                />
                            </InputGroup>
                        </FormGroup>

                    </Col>

                </Row>

            </Filters>
        </>
    );
}

export default FilterMetas;