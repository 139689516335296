import React, { useCallback, useEffect, useRef } from "react";
import { Container } from "./styles";
import IMessage from "../../../entities/Meta/message";
import Message from "../Message";
import { useWhatsappContext } from "../../../contexts/Whatsapp";

interface ChatWindowProps {
  messages: IMessage[];
}

export default function ChatWindow({ messages }: ChatWindowProps) {
  const chatBoxRef = useRef<HTMLDivElement | null>(null);
  const { selectedContact, selectedAccount } = useWhatsappContext();

  const scrollToBottom = useCallback((behavior: "auto" | "smooth" | undefined = undefined) => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollIntoView({
        block: "end",
        behavior: behavior,
      });
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [selectedContact, scrollToBottom]);

  useEffect(() => {
    if (messages) {
      scrollToBottom("smooth");
    }
  }, [messages, scrollToBottom]);

  return (
    <Container className="col" ref={chatBoxRef}>
      {messages?.map((message, index, messages) => {
        /*
         * Este trecho de código é utilizado
         * para apresentar um elemento extra
         * contendo uma data para agrupar as mensagens
         * */

        const currentDate = new Date(messages[index].timestamps);
        const currentDateStr = currentDate.toLocaleDateString();

        // Obter a data da mensagem anterior, se existir
        const previousDate =
          index > 0 ? new Date(messages[index - 1].timestamps) : null;
        const previousDateStr = previousDate
          ? previousDate.toLocaleDateString()
          : "";

        // Verificar se a data atual é diferente da data da mensagem anterior
        const isAnotherDateGroup = currentDateStr !== previousDateStr;

        return (
          <Message
            key={message.wamid}
            currentDate={currentDateStr}
            isAnotherDateGroup={isAnotherDateGroup}
            message={message}
            apiMeta={Boolean(selectedAccount?.api_meta)}
          />
        );
      })}
    </Container>
  );
}
