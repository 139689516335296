/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";

import api from "../../../../../../services/api";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { axios } from "axios";

import {
  UncontrolledPopover,
  PopoverBody,
  Button,
  Modal,
  ListGroupItem,
  ListGroup,
  Badge,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { element } from "prop-types";

export default ({ notify, empresa, ...props }) => {
  const [destinos, setDestinos] = useState([]);
  const [destinosEmpresa, setDestinosEmpresa] = useState([]);
  const [destinoConfig, setDestinoConfig] = useState(null);
  const [fbStatus, setFbStatus] = useState(false);
  const [connectedPages, setConnectedPages] = useState([]);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (destinos.length === 0) loadDestinos();

    console.log({
      TOKEN_FB: new URLSearchParams(window.location.search).get("code"),
    });
  }, []);

  useEffect(() => {
    if (empresa && empresa.id) loadDestinosEmpresas();
  }, [empresa]);


  async function loadDestinosEmpresas() {
    try {
      const response = await api.get(`common/empresas/${empresa.id}/destinos`);
      setDestinosEmpresa(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar destinos");
    }
  }

  async function loadDestinos() {
    try {
      const response = await api.get(`marketing/destinos`, {
        params: {
          situacao: "A",
        },
      });
      setDestinos(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar destinos");
    }
  }

  async function addDestino(destino) {
    try {
      await api.post(`common/empresas/${empresa.id}/destinos`, {
        destino_id: destino.id,
      });
      loadDestinosEmpresas();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível marcar destino");
    }
  }

  async function removeDestino(destino) {
    try {
      await api.delete(`common/empresas/${empresa.id}/destinos/${destino.id}`);
      loadDestinosEmpresas();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível desmarcar destino");
    }
  }

  function ListItem({ destino, key }) {
    const [loading, setLoading] = useState(false);

    return (
      <ListGroupItem
        key={key}
        className="checklist-entry flex-column align-items-start py-4 px-4"
      >
        <div className="checklist-item checklist-item-primary">
          <div className="checklist-primary">
            <h5 className="checklist-title mb-0">
              <i class={destino.icone}></i> &nbsp;
              {destino.nome}
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* {destino.canal.nome == "Facebook ADS" && (
              <Button
                className="btn-icon btn-2"
                size="sm"
                color="secondary"
                outline
                type="button"
                onClick={() => setDestinoConfig(destino.id)}
                disabled={
                  !destinosEmpresa
                    .map((item) => item.pivot.destino_id)
                    .includes(destino.id)
                }
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-settings-gear-65" />
                </span>
              </Button>
            )} */}
            <div className="custom-control custom-checkbox custom-checkbox-primary">
              {loading ? (
                <i class="fas fa-circle-notch fa-spin fa-lg primary mr-2"></i>
              ) : (
                <>
                  <input
                    className="custom-control-input"
                    id={`checklist-${destino.id}`}
                    type="checkbox"
                    checked={destinosEmpresa
                      .map((item) => item.pivot.destino_id)
                      .includes(destino.id)}
                    onClick={(e) => {
                      setLoading(true);
                      if (e.target.checked) {
                        addDestino(destino);
                      } else {
                        removeDestino(destino);
                      }
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`checklist-${destino.id}`}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </ListGroupItem>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
       
      </div>

      <ListGroup data-toggle="checklist" flush>
        {destinos.map((item, key) => (
          <ListItem destino={item} key={key} />
        ))}
      </ListGroup>
    </>
  );
};
