import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import {
  Button,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Spinner,
} from 'reactstrap';

import EmpresaContext from '../../../../../../../contexts/Empresa';
import { Produto } from '../../../../../../../entities/Marketing';
import api from '../../../../../../../services/api';
import { SubmitContainer } from './styles';

interface ImportarProdutosProps {
  show: boolean;
  lastSearch: any;
  onClose(): void;
  onSuccess: any;
  notify: any;
}

interface ProdutosProps {
  id: number;
  tipo_produto_id: number;
}

interface EmpresaMarca {
  id?: number;
  nome: string;
  logomarca: string;
  logo: boolean;
  catalogo: boolean;
}

export default function ImportarProdutosModal({
  show,
  lastSearch,
  onClose,
  onSuccess,
  notify,
}: ImportarProdutosProps) {
  const [produtosToImport, setProdutosToImport] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<any>(null);
  const [checkAll, setCheckAll] = useState(false);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [empresasMarca, setEmpresasMarca] = useState<EmpresaMarca[]>([]);
  const [empresaMarcaSelecionada, setEmpresaMarcaSelecionada] =
    useState<any>(null);

  const { empresaSelecionada } = useContext(EmpresaContext);

  function closeModal() {
    onClose();
    setCheckAll(false);
    setEmpresaMarcaSelecionada(null);
    setProdutos([]);
  }

  async function handleImport() {
    try {
      setIsLoading(true);
      if (produtosToImport.length > 0 && empresaMarcaSelecionada) {
        await api.post(
          `/common/empresas/${empresaSelecionada?.id}/produtos/import`,
          {
            produtos: produtosToImport,
            empresa_marca_id: empresaMarcaSelecionada.value,
          }
        );

        setAlert(
          <ReactBSAlert
            success
            style={{ display: 'block', marginTop: '-100px' }}
            title="Produtos importados!"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            Os produtos foram importados com sucesso!
          </ReactBSAlert>
        );
        setCheckAll(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function buscarMarcasEmpresa() {
    try {
      const { data: marcas } = await api.get<EmpresaMarca[]>(
        `/common/empresas/minhas-marcas`
      );
      if (marcas) {
        setEmpresasMarca(marcas);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const selectOptions = useMemo(() => {
    return empresasMarca.map((emp) => ({ value: emp.id, label: emp.nome }));
  }, [empresasMarca]);

  function handleSelectItem(id: number) {
    let list = [...produtosToImport];
    if (produtosToImport.includes(id)) {
      const index = list.indexOf(id);
      list.splice(index, 1);
    } else {
      list.push(id);
    }
    setProdutosToImport(list);
  }

  function clear() {
    setEmpresaMarcaSelecionada(null);
  }

  async function loadProdutosMarca(
    empresa_id: number,
    page = 1,
    limit = 9999,
    sortField = 'nome',
    sortOrder = 'desc'
  ) {
    try {
      if (empresa_id) {
        const response = await api.get<Produto[]>(
          `/common/empresas/${empresa_id}/produtos`,
          {
            params: {
              page,
              limit,
              sortField,
              sortOrder,
            },
          }
        );
        //@ts-ignore
        setProdutos(response.data.data);
      }
    } catch (error) {
      console.log(error);
      notify('danger', 'Houve um problema ao carregar os produtos.');
    }
  }

  useEffect(() => {
    if (empresaMarcaSelecionada)
      loadProdutosMarca(empresaMarcaSelecionada.value);
  }, [empresaMarcaSelecionada]);

  useEffect(() => {
    buscarMarcasEmpresa();
  }, []);

  useEffect(() => {
    if (checkAll) {
      setProdutosToImport(produtos.map((produto: ProdutosProps) => produto.id));
    } else {
      setProdutosToImport([]);
    }
  }, [checkAll]);

  return (
    <>
      {alert}
      {show && (
        <Modal
          className="modal-dialog-centered"
          isOpen={show}
          toggle={closeModal}
          size="lg"
          onClosed={clear}
          scrollable
        >
          <ModalHeader toggle={closeModal} >
            <h5 className="modal-title" id="exampleModalLabel">
              Importar de uma marca
            </h5>
           
          </ModalHeader>
          <ModalBody className='pt-0 mt-4'>
            {loading ? (
              <Progress animated value="100" color="info" />
            ) : (
              <>
              <div className='sticky-top bg-white'>
              <FormGroup>
                  <Label>Marca</Label>
                  <Select
                    placeholder="Selecione a marca"
                    value={empresaMarcaSelecionada}
                    onChange={(item) => setEmpresaMarcaSelecionada(item)}
                    options={selectOptions}
                  />
                </FormGroup>

                <SubmitContainer>
                  <Button
                    onClick={handleImport}
                    color="primary"
                    disabled={isLoading || produtosToImport.length <= 0}
                  >
                    {isLoading && <Spinner size="sm" className="mr-2" />}
                    Importar
                  </Button>
                </SubmitContainer>
              </div>
                <br />
                <ListGroup data-toggle="checklist" flush>
                  <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                    <FormGroup
                      check
                      className="form-check-inline"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <small>
                        {`${produtosToImport.length} produtos selecionados`}
                      </small>
                      <div
                        className="custom-control custom-checkbox custom-checkbox-primary"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <small>Todos</small>
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          checked={checkAll}
                          onClick={() => setCheckAll(!checkAll)}
                        />
                      </div>
                    </FormGroup>
                  </ListGroupItem>
                  {produtos?.map((produto: Produto, key: number) => (
                    <ListGroupItem
                      key={key}
                      className="checklist-entry flex-column align-items-start py-4 px-4"
                    >
                      <div className="checklist-item checklist-item-primary">
                        <div className="checklist-primary">
                          <h5 className="checklist-title mb-0">
                            {produto.nome}
                          </h5>
                          <small style={{ display: 'block' }}>{`R$: ${
                            produto?.preco || ''
                          }`}</small>
                          <small
                            style={{ display: 'block' }}
                          >{`Tipo de Produto: ${
                            produto?.tipoProduto?.nome || ''
                          }`}</small>
                        </div>
                        <div>
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              style={{ borderColor: '#525f7f' }}
                              checked={produtosToImport.includes(produto.id)}
                              onClick={() => handleSelectItem(produto.id)}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
}
