import React, { useState, useRef, useContext, useEffect } from 'react';
import EmpresaContext from "../../../contexts/Empresa";
import { AxiosResponse } from 'axios';
import api from "../../../services/api";
//@ts-ignore
import NotificationAlert from "react-notification-alert";


import { GrupoEstrategico } from '../../../entities/Mentoring';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
} from "reactstrap";
import TimeLine from "../../../components/timeLines/Pdca";
import Filters from "./Filters";
// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

const Pdca: React.FC = ({ ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [pageProperties, setPageProperties] = useState({
        total: "0",
        perPage: 10,
        page: 1,
        lastPage: 1,
        loading: false,
        data: new Array<GrupoEstrategico>(),
        sort: new Map([])
    })
    const [lastSearch, setLastSearch] = useState({})
    const notificationAlert = useRef<NotificationAlert>(null);

    const [isClearFilterAction, setIsClearFilterAction] = useState(false)
    const [nomePdca, setNomePdca] = useState('')
    const [situacao, setSituacao] = useState('com_acoes')


    useEffect(() => {
        console.log('useEffect Padrão')
        load()
    }, [])

    useEffect(() => {
        if (!isClearFilterAction) {
            load()
        }
        setIsClearFilterAction(false);
    }, [situacao])

    function notify(type: string, msg: string) {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (notificationAlert) notificationAlert?.current?.notificationAlert(options);
    };


    async function load() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/pdca`,
                {
                    params: {
                        nome: nomePdca,
                        situacao: situacao || ''
                    }
                })
            setPageProperties(await response.data)            
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos carregar os PDCAs')
        }
    }

    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <SimpleHeader name="Listagem de PDCAs" parentName="PDCAs" {...props} />
            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                }}>
                                <Filters
                                    title='Estratégias de Marketing'
                                    load={load}
                                    notify={notify}
                                    situacao={situacao}
                                    setSituacao={setSituacao}
                                    nomePdca={nomePdca}
                                    setNomePdca={setNomePdca}
                                    setIsClearFilterAction={setIsClearFilterAction}
                                />
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs='12'>

                                    </Col>
                                    <Col>
                                        <TimeLine
                                            pdca={pageProperties.data}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Pdca