import React, { useEffect, useState, useContext } from 'react';
import api from '../../../../services/api'
import EmpresaContext from '../../../../contexts/Empresa'

import {
    Container,
    Gradient,
    LoadingContainer,
} from './styles';

import ParcialColaboradores from '../charts/ParcialColaboradores'
import RankingColaboradores from '../charts/RankingColaboradores'
import VendasMetaUltimosMeses from '../charts/VendasMetaUltimosMeses'

import {
    Container as BootstrapContainer,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Spinner
} from "reactstrap";

interface IGraficos {
    notify(type: string, msg: string): void,
    mes: string,
    ano: string,
    equipe: any,
    campanha: any,
    colaborador: any,
    isGestor: boolean,
    setCampanha: any,
    unidade: string,
    periodo: any,
    tipoProduto: string,

}

const Graficos: React.FC<IGraficos> = ({ notify, mes, ano, equipe, unidade, colaborador, periodo, tipoProduto, isGestor }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [loading, setLoaging] = useState(false)
    const [cardHeight, setCardHeight] = useState()

    return (
        <>
            <Container
            // className='header d-flex align-items-center'
            >

                <BootstrapContainer
                    fluid
                    className='mt-2'
                >
                    {isGestor ?

                        <Row className='mt-4'>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                <VendasMetaUltimosMeses
                                    notify={notify}
                                    mes={mes}
                                    ano={ano}
                                    equipe={equipe}
                                    colaborador={colaborador}
                                    unidade={unidade}
                                    tipoProduto={tipoProduto}
                                    periodo={periodo}
                                    cardHeight={cardHeight}
                                />

                            </Col>
                            <Col lg='6' md='6' sm='12' xs='12'>
                                <RankingColaboradores
                                    notify={notify}
                                    mes={mes}
                                    ano={ano}
                                    equipe={equipe}
                                    colaborador={colaborador}
                                    unidade={unidade}
                                    periodo={periodo}
                                    tipoProduto={tipoProduto}
                                    setCardHeight={setCardHeight}
                                />
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col lg='12' md='12' sm='12' xs='12'>
                                <RankingColaboradores
                                    notify={notify}
                                    mes={mes}
                                    ano={ano}
                                    equipe={equipe}
                                    colaborador={colaborador}
                                    unidade={unidade}
                                    periodo={periodo}
                                    tipoProduto={tipoProduto}
                                    setCardHeight={setCardHeight}
                                />
                            </Col>
                        </Row>
                    }

                    {isGestor &&
                        <Row className='mt-4'>
                            <Col lg='12' md='12' sm='12' xs='12'>
                                <ParcialColaboradores
                                    notify={notify}
                                    mes={mes}
                                    ano={ano}
                                    equipe={equipe}
                                    colaborador={colaborador}
                                    unidade={unidade}
                                    periodo={periodo}
                                    tipoProduto={tipoProduto}                                    
                                />
                            </Col>
                        </Row>
                    }

                </BootstrapContainer>
            </Container>
        </>
    )
}

export default Graficos;