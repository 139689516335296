import React from 'react';
import Tab from "./Tab"

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'

export default ({ ...props }) => {

    return (
        <>
            <SimpleHeader
                name="Nova campanha"
                parentName="Campanhas"
                {...props} />
            <Tab
                {...props}
            />
        </>
    )

}