import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
import DropZone from "../anexos/Dropzone";
import Select2 from "react-select2-wrapper";
import ReactQuill from "react-quill";
import {
  Button,
  FormGroup,
  Row,
  Col,
  Input,
  PopoverBody,
  UncontrolledPopover,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";


export default ({
  notify,
  sender,
  subject,
  bodyText,
  midias,
  setMidias,
  setSender,
  setSubject,
  setBodyText,
  isNps,
  npsText,
  setNpsText,
  erros
}) => {

  const { empresaSelecionada } = useContext(EmpresaContext)
  const [modal, setModal] = useState(false);
  const [emailsEmpresa, setEmailsEmpresa] = useState([]);
  const [empresa, setEmpresa] = useState(undefined);
  const [linkNPS, setLinkNps] = useState('')

  const [uploadProgress, setUploadProgress] = useState({
    progress: -1,
    totalProgress: 0,
    showModal: false,
  });
  const [arquivos, setArquivos] = useState([]);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    loadEmailsEmpresa()
    loadEmpresa()

  }, [])


  useEffect(() => {
    var urlNps = empresa?.url_nps;
    if(urlNps){
      setLinkNps(`<a href="${urlNps}" target="_blank">CLIQUE AQUI para Participar da Pesquisa de Satisfação.</a>`);
      setNpsText(`<a href="${urlNps}" target="_blank">CLIQUE AQUI para Participar da Pesquisa de Satisfação.</a>`);
    }
  }, [empresa])

  async function loadEmailsEmpresa() {
    try {
      const response = await api.get(`/common/empresas/${empresaSelecionada.id}/emails?situacao=A`);
      setEmailsEmpresa(response.data)
    } catch (error) {
      notify('danger', 'Houve um problema ao carregar lista de emails da empresa.');
      throw error
    }
  }

  async function loadEmpresa() {
    try {
      const response = await api.get(`/common/empresas/${empresaSelecionada.id}`);
      setEmpresa(response.data);      
    } catch (error) {
      notify('danger', 'Houve um problema ao carregar os dados da empresa.');
    }
  }

  const toggle = () => {
    setModal(!modal);
  }
  return (
    <>
      <Row>
        <Col>
          <div
            className="float-right "
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              color="link"
              type="button"
              onClick={toggle}
            >
              Visualizar
            </Button>
          </div>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggle} size='lg'>
        <ModalHeader toggle={toggle}>Visualização do email</ModalHeader>
        <ModalBody>
          {
            !!empresa ?
              <table width="100%" style={{ borderCollapse: 'collapse' }} cellpadding="0">
                <tbody>
                  <tr>
                    <td valign="middle" style={{ width: 115 }} height="47">
                      <a href={empresa.site}>
                        <img alt={empresa.nome} height={{ height: "100%" }} src={empresa.logo_url} style={{ border: 0, borderStyle: 'none', color: '#006699', textDecoration: 'none' }} title={empresa.nome} width="107" class="CToWUd" />
                      </a>
                    </td>
                    <td valign="top" style={{ border: 0, borderBottom: 1, borderStyle: 'solid', borderColor: '#cccccc', padding: '5px 0', textAlign: 'right', whiteSpace: 'nowrap' }}>
                      <table align="right" style={{ borderCollapse: 'collapse' }} cellpadding="0">
                        <tbody>
                          <tr>
                            <td style={{ padding: '0px 10px' }}>
                              <a href="#" onClick={e => e.preventDefault()} title="Não desejo receber emails como este">Cancelar inscrição</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: 20 }} colspan="2">
                      <div dangerouslySetInnerHTML={{ __html: bodyText }} />
                      {isNps && 
                      <div dangerouslySetInnerHTML={{ __html: linkNPS }} />
                      }                      
                    </td>
                  </tr>
                </tbody>
              </table> :
              <p>Não foi possível carregar a visualização do email</p>
          }
        </ModalBody>
      </Modal>

      <Row>
        <Col sm="12" md="12" lg="6">
          <FormGroup>
            <label className="form-control-label" >
              Assunto*
            </label>
            <Input
              placeholder='Informe o assunto do email...'
              className="form-control"
              value={subject}
              onChange={e => setSubject(e.target.value)}
            />
            <small class="text-danger">
              {erros.email_assunto || ""}
            </small>
          </FormGroup>
        </Col>
        <Col lg="6" sm="12" md="12">
          <FormGroup>
            <label className="form-control-label">
              Remetente*
              <Button
                color="secondary"
                id="tooltip87627934922"
                outline
                size="sm"
                type="button">
                ?
              </Button>
              <UncontrolledPopover placement="top" target="tooltip87627934922">
                <PopoverBody>
                  Informe o email que vai ser usado como remetente...
                </PopoverBody>
              </UncontrolledPopover>
            </label>
            <Select2
              className="form-control"
              value={sender}
              options={{
                placeholder: "Remetente..."
              }}
              onSelect={e => setSender(e.target.value)}
              data={emailsEmpresa.map(email => ({ text: email.email, id: email.id }))}
            />
            <small class="text-danger">
              {erros.email_remetente_id || ''}
            </small>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12" sm="12" md="12">
          <FormGroup>
            <label className="form-control-label">
              Conteúdo*
              <Button
                color="secondary"
                id="tooltip876279349"
                outline
                size="sm"
                type="button">
                ?
              </Button>
              <UncontrolledPopover placement="top" target="tooltip876279349">
                <PopoverBody>
                  Informe o texto que será enviado no corpo do email...
                </PopoverBody>
              </UncontrolledPopover>

              {isNps &&

                <>
                  <Button
                    color="secondary"
                    id="url_nps_9923"
                    outline
                    size="sm"
                    type="button">
                    <i className="fas fa-info-circle"></i>
                  </Button>


                  <UncontrolledPopover placement="top" target="url_nps_9923">
                    <PopoverBody>
                      Por se tratar de uma Mala Direta do tipo NPS, adicionaremos ao final do texto um hiperlink,
                      que redirecionará o cliente para a página da Pesquisa de Satisfação da sua Empresa.
                      Clique em Visualizar para ver como ficará o e-mail.
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              }
            </label>

            <ReactQuill
              value={bodyText || ""}
              theme="snow"
              modules={{
                toolbar: [
                  ["bold", "italic"],
                  ["link", "blockquote", "code"],
                  [
                    {
                      list: "ordered"
                    },
                    {
                      list: "bullet"
                    }
                  ]
                ]
              }}
              onChange={v => setBodyText(v)}
            />
            <small class="text-danger">
              {erros.email_conteudo || ''}
            </small>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12" sm="12" md="12">
          <FormGroup>
              <DropZone
                  alert={setAlert}
                  clearAutoReloadList={() => setUploadProgress({
                      ...uploadProgress,
                      reloadList: false
                  })}
                  midias={midias}
                  // arquivos={arquivos}
                  uploadProgress={uploadProgress}
                  notify={notify}
                  setMidias={setMidias}
              />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}