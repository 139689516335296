import React, { useEffect, useState } from 'react';
import api from "../../../../../services/api";

import ColorCircle from "../ColorCircle";

import {
    Button,
    ListGroupItem,
    ListGroup,
    Row,
    Input,
    Spinner
} from "reactstrap";

function List({ estagios, empresa, reload = () => { }, notify }) {

    const [ordenando, setOrdenando] = useState(false)
    const [changeColor, setChangeColor] = useState(false)

    async function handleDelete(estagio) {
        if (window.confirm(`Deseja remover este estagio  ${estagio.nome}?`)) {
            try {
                await api.delete(`/common/empresas/${empresa.id}/estagios/${estagio.id}`);
                notify("success", "Estágio Removido");
                reload();
            } catch (error) {
                const response = error.response
                console.log({ response })
                if (response && response.status === 400 && response.data) {
                    notify("danger", response.data[0].message);
                } else {
                    notify("danger", "Não foi possível remover o estágio");
                }
            }
        }
    }

    async function hanldeOrderChange(e, estagio_id, ordem) {
        e.preventDefault()
        try {
            setOrdenando(true)
            await api.post(`/common/empresas/${empresa.id}/estagios/${estagio_id}/ordenar`,
                {
                    ordem
                });
            notify("success", "Ordem alterada");
            reload();
        } catch (error) {
            console.error(error);
            notify("danger", "Não foi possível alterar ordem");
        } finally {
            setOrdenando(false)
        }
    }

    async function handleSaveColor(e, estagio, cor) {
        e.preventDefault()
        try {
            setChangeColor(true)
            await api.post(`common/empresas/${empresa.id}/estagios/${estagio.id}/cores`,
                {
                    cor
                })
            onCorAlterada()
        } catch (error) {
            console.error(error)
            notify('danger', "Não conseguimos mudar a cor do estagio")
        } finally {
            setChangeColor(false)
        }
    }

    function onCorAlterada() {
        notify('success', 'Cor alterada')
        reload()
    }

    function createOrdemSelection(ordem) {
        const options = []
        for (let i = ordem; i > 0; i--) {
            options.push(<option key={i}>#{i}</option>)
        }
        return options.reverse()
    }

    return (
        <>
            <ListGroup className="list mt-4" flush>
                {
                    estagios.map((estagio, key) => (
                        <ListGroupItem
                            draggable
                            className="list-group-item-action px-0"
                            style={{ flexDirection: 'row', display: 'flex', flex: 1, alignItems: 'center' }}
                            key={key}>
                            <div className="col-auto">
                                <Button
                                    className="btn-sm"
                                    color="danger"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleDelete(estagio)
                                    }}
                                >
                                    <i class="fas fa-trash"></i>
                                </Button>
                            </div>
                            <div className="col-auto">
                                <ColorCircle
                                    estagio={estagio}
                                    handleSaveColor={handleSaveColor} />
                            </div>
                            <Row
                                style={{ flex: 1 }}
                                className="align-items-center">
                                <div className="col col-12">
                                    <h4 className="mb-0">
                                        <a href="#" onClick={e => e.preventDefault()}>
                                            {estagio.nome}
                                        </a>
                                    </h4>
                                </div>
                                <div className="col col-lg-1">
                                    {
                                        ordenando ?
                                            <Spinner size='sm' color='primary' />
                                            :
                                            <Input
                                                size='sm'
                                                type="select"
                                                className='form-control-flush'
                                                onChange={(e) => hanldeOrderChange(e, estagio.id, e.target.value.replace('#', ''))}
                                                value={`#${key + 1}`}
                                            >
                                                {
                                                    createOrdemSelection(key + 1)
                                                }
                                            </Input>
                                    }
                                </div>
                            </Row>
                        </ListGroupItem>
                    ))
                }
            </ListGroup>
        </>
    )
}

export default List;