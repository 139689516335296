import React, { useState, useEffect, useContext, useRef } from 'react';
import EmpresaContext from "../../../../../contexts/Empresa";
import api from '../../../../../services/api'
import { AxiosResponse } from 'axios';
import ReactQuill from "react-quill";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import Select2 from "react-select2-wrapper";

import {
    Button,
    Spinner,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";
import { QuestaoAlvo } from '../../../../../entities/Mentoring';
import { verificarTamanhoConteudo } from '../../../../../utils';


// import { Container } from './styles';

export type FormError = {
    nome?: string,
    descricao?: string,
    objetivo_inicial?: string,
    objetivo_final?: string,
    situacao_atual?: string,
    status?: string
}

interface Props {
    notify(type: string, msg: string): void,
    goTo: any
    history: any
    questaoId: any,
    onSuccess: any,
    close: any,
    planoEstrategicoId: any
}

const FormQuestoesAlvo: React.FC<Props> = ({ notify, goTo, history, questaoId, onSuccess, close, planoEstrategicoId }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [questaoAlvo, setQuestaoAlvo] = useState({} as QuestaoAlvo)

    const [nome, setNome] = useState('')
    const [descricao, setDescricao] = useState('')
    const [objetivo_inicial, setObjetivo_inicial] = useState('')
    const [objetivo_final, setObjetivo_final] = useState('')
    const [situacao_atual, setSituacao_atual] = useState('')
    const [status, setStatus] = useState<'N' | 'A'>('N')

    const [statusList, setStatusList] = useState([
        { id: 'A', text: 'Alcançada' },
        { id: 'N', text: 'Não Alcançada' }
    ])

    const [alert, setAlert] = useState<any>(undefined)
    const [erros, setErros] = useState<FormError>({} as FormError)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        console.log(questaoId)
        if (questaoId) loadQuestaoAlvo()
    }, [questaoId])

    useEffect(() => {
        setNome(questaoAlvo.nome)
        setDescricao(questaoAlvo.descricao)
        setObjetivo_inicial(questaoAlvo.objetivo_inicial)
        setObjetivo_final(questaoAlvo.objetivo_final)
        setSituacao_atual(questaoAlvo.situacao_atual)
        setStatus(questaoAlvo.status as any)
    }, [questaoAlvo])

    async function loadQuestaoAlvo() {
        try {
            const response = await api.get(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos/${planoEstrategicoId}/questoes-alvo/${questaoId}`)
            const data = await response.data
            setQuestaoAlvo(data)
        } catch (error) {
            notify('error', 'Não foi possível carregar Questão-Alvo')
            console.error(error)
        }
    }

    async function handleSave() {
        if (
            verificarTamanhoConteudo(descricao) 
            || verificarTamanhoConteudo(objetivo_inicial)
            || verificarTamanhoConteudo(objetivo_final)
            || verificarTamanhoConteudo(situacao_atual)
          ) {
            notify(
              "danger",
              "Por favor, reduza o tamanho das imagens ou o comprimento do conteúdo nos editores de texto."
            );
            return;
        }

        setErros({} as FormError)
        setSaving(true)
        if (questaoId) {
            await update()
        } else {
            await insert()
        }
        setSaving(false)
    }

    function afterSave(msg: string) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
                title={msg}
                onConfirm={() => (close(), onSuccess())}
                confirmBtnBsStyle="success"
                showConfirm={false}
                btnSize=""
            />
        );
        //setTimeout({close, onSuccess}, 2000);
        setTimeout(() => (close(), onSuccess()), 2000);
    };

    function afterError(msg: string) {
        setAlert(
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
                title={msg}
                onConfirm={() => setAlert(null)}
                confirmBtnBsStyle="danger"
                showConfirm={false}
                btnSize=""
            />
        );
        setTimeout(() => setAlert(null), 2000);
    };

    async function insert() {
        try {
            const newQuestao = bind()
            const response = await api.post(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos/${planoEstrategicoId}/questoes-alvo`,
                {
                    ...newQuestao
                })
            const data = await response.data
            setQuestaoAlvo(data)
            afterSave('Questão-Alvo Salva');
        } catch (error) {
            console.error(error)
            //notify('danger', 'Não foi possível salvar Questão-Alvo')
            afterError('Não foi possível salvar Questão-Alvo');
            //@ts-ignore            
            throwError(error.response)
        } finally {
            setSaving(false)
        }
    }


    async function update() {
        try {
            const newQuestao = bind()
            const response = await api.put(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos/${planoEstrategicoId}/questoes-alvo/${questaoId}`,
                {
                    ...newQuestao,
                })
            const data = await response.data
            setQuestaoAlvo(data)
            afterSave('Questão-Alvo Alterada');
        } catch (error) {
            console.error(error)
            //notify('danger', 'Não foi possível alterar Questão-Alvo')
            afterError('Não foi possível alterar Questão-Alvo');
            //@ts-ignore
            throwError(error.response)
        } finally {
            setSaving(false)
        }
    }

    function bind(): QuestaoAlvo {
        const questao = {} as QuestaoAlvo;

        questao.nome = nome;
        questao.descricao = descricao;
        questao.objetivo_inicial = objetivo_inicial;
        questao.objetivo_final = objetivo_final;
        questao.situacao_atual = situacao_atual;
        questao.plano_estrategico_id = planoEstrategicoId as number;
        questao.status = status as any;

        return questao;
    }

    function throwError(response: AxiosResponse) {
        if (response && response.status == 400) {
            if (response.data.length) {
                const responseError = {} as FormError
                for (let e of response.data) {
                    //@ts-ignore
                    responseError[e.field] = e.message
                }
                setErros(responseError)
            }
        }
    }

    return <>
        {alert}
        <Row>
            <Col sm={12} md={12} lg={12}
            >
                <FormGroup>
                    <label className="form-control-label" >
                        Nome*
                    </label>
                    <Input
                        placeholder='Nome...'
                        className="form-control"
                        value={nome}
                        onChange={e => setNome(e.target.value)}
                    />
                    <small className="text-danger">
                        {erros.nome || ""}
                    </small>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col>
                <FormGroup>
                    <label
                        className="form-control-label"
                    >
                        Descrição*
                    </label>
                    <ReactQuill
                        theme="snow"
                        modules={{
                            toolbar: [
                                ["bold", "italic"],
                                ["link", "blockquote"],
                                [
                                    {
                                        list: "ordered"
                                    },
                                    {
                                        list: "bullet"
                                    }
                                ]
                            ]
                        }}
                        value={descricao || ''}
                        onChange={e => setDescricao(e)}
                    />
                    {/* @ts-ignore */}
                    <small class="text-danger">
                        {erros.descricao || ''}
                    </small>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col>
                <FormGroup>
                    <label
                        className="form-control-label"
                    >
                        Objetivo Inicial*
                    </label>
                    <ReactQuill
                        theme="snow"
                        modules={{
                            toolbar: [
                                ["bold", "italic"],
                                ["link", "blockquote"],
                                [
                                    {
                                        list: "ordered"
                                    },
                                    {
                                        list: "bullet"
                                    }
                                ]
                            ]
                        }}
                        value={objetivo_inicial || ''}
                        onChange={e => setObjetivo_inicial(e)}
                    />
                    {/* @ts-ignore */}
                    <small class="text-danger">
                        {erros.objetivo_inicial || ''}
                    </small>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col>
                <FormGroup>
                    <label
                        className="form-control-label"
                    >
                        Objetivo Final*
                    </label>
                    <ReactQuill
                        theme="snow"
                        modules={{
                            toolbar: [
                                ["bold", "italic"],
                                ["link", "blockquote"],
                                [
                                    {
                                        list: "ordered"
                                    },
                                    {
                                        list: "bullet"
                                    }
                                ]
                            ]
                        }}
                        value={objetivo_final || ''}
                        onChange={e => setObjetivo_final(e)}
                    />
                    {/* @ts-ignore */}
                    <small class="text-danger">
                        {erros.objetivo_final || ''}
                    </small>
                </FormGroup>
            </Col>
        </Row>


        <Row>
            <Col>
                <FormGroup>
                    <label
                        className="form-control-label"
                    >
                        Situação Atual*
                    </label>
                    <ReactQuill
                        theme="snow"
                        modules={{
                            toolbar: [
                                ["bold", "italic"],
                                ["link", "blockquote"],
                                [
                                    {
                                        list: "ordered"
                                    },
                                    {
                                        list: "bullet"
                                    }
                                ]
                            ]
                        }}
                        value={situacao_atual || ''}
                        onChange={e => setSituacao_atual(e)}
                    />
                    {/* @ts-ignore */}
                    <small class="text-danger">
                        {erros.situacao_atual || ''}
                    </small>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGroup>
                    <label
                        className="form-control-label"
                    >
                        Status*
                    </label>
                    <Select2
                        defaultValue={status}
                        onSelect={(e: any) => setStatus(e.target.value)}
                        options={{
                            placeholder: "Selecione o Status..."
                        }}
                        value={status}
                        data={statusList}
                    />
                    {/* @ts-ignore */}
                    <small class="text-danger">
                        {erros.status || ''}
                    </small>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col>
                <div className="float-right ">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => close()}
                    >
                        voltar
                    </Button>
                    <Button
                        disabled={saving}
                        color="primary"
                        onClick={handleSave}
                    >
                        {
                            <Spinner
                                hidden={!saving}
                                className="mr-2"
                                color="light"
                                size="sm"
                            />
                        }
                        Salvar
                    </Button>

                </div>
            </Col>
        </Row>

    </>;
}

export default FormQuestoesAlvo;