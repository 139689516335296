import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import { UsuarioModeloSite } from "../../CentralVendedor";
import Offcanvas from "../components/Offcanvas";
import { useEditarModeloModal } from "./useEditarModeloModal";
import { EmpresaModeloSite } from "../../../../../../common/Empresas/MinhaEmpresa/Sites";

interface EditarModeloModalProps {
  onClose: () => void;
  isOpen: boolean;
  usuarioSite: UsuarioModeloSite | EmpresaModeloSite | undefined;
}

export function EditarModeloModal({
  isOpen,
  onClose,
  usuarioSite,
}: EditarModeloModalProps) {
  const {
    handleInputChange,
    isFormVisible,
    onSubmit,
    paramsMap,
    setIsFormVisible,
    onParamsPreview,
    iframeURLQueryParams,
    clearParamsPreview,
    handleClose,
    alert,
    isLoading,
  } = useEditarModeloModal({ onClose, usuarioSite });

  return (
    <>
      {alert}
      <Modal isOpen={isOpen} toggle={handleClose} style={{ maxWidth: "90%" }}>
        <ModalHeader toggle={handleClose}>Editar Modelo</ModalHeader>
        <ModalBody className="pt-0">
          <div style={{ position: "relative", overflow: "hidden" }}>
            <iframe
              style={{ width: "100%", height: "80vh" }}
              id="myIframe"
              // src={
              //   usuarioSite?.site.tipo_modelo == "vendedor"
              //     ? `http://127.0.0.1:5173/?UMS=${usuarioSite?.id}${iframeURLQueryParams}`
              //     : `http://127.0.0.1:5174/?UMS=${usuarioSite?.id}${iframeURLQueryParams}`
              // }
              src={`${usuarioSite?.site?.link}?UMS=${usuarioSite?.id}${iframeURLQueryParams}`}
            ></iframe>
            <Offcanvas
              toggle={() => setIsFormVisible(!isFormVisible)}
              isOpen={isFormVisible}
              onClose={() => setIsFormVisible(!isFormVisible)}
            >
              <Form>
                {paramsMap?.map((input) => (
                  <FormGroup key={input.id}>
                    <Label>{input.descricao}</Label>
                    <Input
                      type="text"
                      id={input.id.toString()}
                      name={input.chave}
                      onChange={handleInputChange}
                      defaultValue={input.valor || ""}
                      data-description={input.descricao}
                    />
                  </FormGroup>
                ))}
                <div className="d-flex justify-content-between">
                  <Button size="sm" type="button" onClick={onParamsPreview}>
                    Visualizar
                  </Button>
                  {/* <Button size="sm" type="button" onClick={clearParamsPreview}>Restaurar</Button> */}
                  <Button
                    size="sm"
                    color="primary"
                    onClick={onSubmit}
                    disabled={isLoading}
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            </Offcanvas>
          </div>

          {/* <div
          style={{
            position: "absolute",
            top: "120px",
            right: "170px",
            width: "40%",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        > */}

          {/* </div> */}
        </ModalBody>
      </Modal>
    </>
  );
}
