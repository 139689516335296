import React, { useEffect, useState } from "react";
import FormOportunidade from "./Form";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
export default ({ ...props }) => {
  const [optId, setOptId] = useState();

  useEffect(() => {
    setOptId(props.match?.params.id);
  }, []);

  return (
    <>
      <SimpleHeader
        name="Alterar Oportunidade"
        parentName="Oportunidades"
        {...props}
      />
      <FormOportunidade {...props} oportunidadeId={optId} isNew={false} />
    </>
  );
};
