import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Badge, Progress } from "reactstrap";
import Table from '../../../../../components/Table'
import { MenuComportamento } from '../../../../../components/Menus'
import { Meta } from '../../../../../entities/Marketing';
import Avatar from '../../../../../components/Avatar'

// import { Container } from './styles';
interface Props {
  colaboradores: Array<any>,
  unidade: any,
  pageProperties: any,
  onTableChange(type: string, sortProperties: { page: number, sizePerPage: number, sortField: string, sortOrder: string }): Promise<void>,
  notify(type: string, msg: string): void,
  loading: boolean
}

const TableRanking: React.FC<Props> = ({ colaboradores, unidade, pageProperties, onTableChange, notify, loading, ...props }) => {
  const history = useHistory()

  const [columns] = useState([

    {
      dataField: 'user',
      text: 'Colaborador',
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: any, row: any) => colaboradorFormatter(row)
    },
    {
      dataField: "vendas_colaborador",
      text: 'Vendas',
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: any, row: any) => valorFormatter(row)
    },
    {
      dataField: 'impacto',
      text: 'Impacto',
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: any, row: any) => impactoFormatter(row)
    },
  ])


  function colaboradorFormatter(row: any) {
    var colaborador = <div style={{ height: 48 }}>
      {
        row.user &&
        <Avatar
          title={row.user.name}
          user={row.user}
          className='avatar'
          style={{
            cursor: 'default',
          }}
        />
      }
    </div>
    return colaborador

  }


  function valorFormatter(row: any) {
    var valor = 0;
    if (row) {
      valor = row.vendas_colaborador
      if (unidade && unidade == 'M') {
        valor = parseFloat(valor.toString());
        return 'R$ ' + valor
      } else {
        valor = parseInt(valor.toString());
      }
    }
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', height: 48 }}>
      {valor}
    </div>
  }

  function impactoFormatter(row: any) {
    var impacto = <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', height: 48 }}>
      {
        row.impacto &&

        <div>
          <span>{row.impacto && row.impacto > 0 ? parseFloat(row.impacto).toFixed(2) : 0}%</span>
          <div>
            <Progress
              style={{ height: 5 }}
              max="100"
              value={row.impacto}
              barClassName={row && row.impacto_color ? row.impacto_color : 'bg_info'}
            />
          </div>
        </div>
      }
    </div>
    return impacto

  }




  return (
    <>
      <Table
        columns={columns}
        data={colaboradores}
        pageProperties={pageProperties}
        onTableChange={onTableChange}
        notify={undefined}
        rowEvents={undefined}
      />
    </>
  );
}

export default TableRanking;