import styled from 'styled-components'

export const SpinnerContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 200px;
`

export const CountContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
`


export const SubmitContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`