import React, { Fragment, useEffect, useState } from "react";
import { ContatoItem } from "./Contato";
import api from "../../../../services/api";
import { Button, Col, FormGroup, Input } from "reactstrap";
import InputMask from "react-input-mask";

export interface ContatoProps {
  id?: number;
  cargo: string;
  nome: string;
  numero: string;
  ddd: string;
}

export function ListaContatos({
  clienteId,
  notify,
  onContatosChange,
}: {
  clienteId: number;
  notify: (type: string, message: string) => void;
  onContatosChange: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  const [contatos, setContatos] = useState<ContatoProps[]>([]);
  const [cargo, setCargo] = useState("");
  const [nome, setNome] = useState("");
  const [ddd, setDdd] = useState("");
  const [numero, setNumero] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function valid() {
    if (
      nome.length < 2 ||
      cargo.length < 2 ||
      ddd.length < 2 ||
      numero.length < 8
    ) {
      notify("danger", "Verifique todos os campos do contato.");
      return false;
    }
    return true;
  }

  function limparCampos() {
    setCargo("");
    setNome("");
    setDdd("");
    setNumero("");
  }

  async function handleSubmit() {
    const isValid = valid();
    if (!isValid) return;
    try {
      setIsLoading(true);

      if (!clienteId) {
        notify("success", "Contato salvo com sucesso!");
        limparCampos();

        setContatos((prevState) => [
          ...prevState,
          { cargo, nome, ddd: ddd, numero: numero },
        ]);
        return;
      }

      await api.post("/common/clientes/contatos", {
        cargo,
        nome,
        ddd: +ddd,
        numero: +numero,
        cliente_id: clienteId,
      });
      carregarContatos();
      limparCampos();
      notify("success", "Contato salvo com sucesso!");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function removeContatoLocal(contatoToUpdate: ContatoProps) {
    const updatedContatos = contatos.filter(
      (contato) =>
        contato.ddd !== contatoToUpdate.ddd ||
        contato.numero !== contatoToUpdate.numero ||
        contato.cargo !== contatoToUpdate.cargo ||
        contato.nome !== contatoToUpdate.nome
    );
    setContatos(updatedContatos);
  }

  function updateContatoLocal(contatoToUpdate: ContatoProps) {
    const updatedContatos = contatos.filter(
      (contato) =>
        contato.ddd !== contatoToUpdate.ddd ||
        contato.numero !== contatoToUpdate.numero ||
        contato.cargo !== contatoToUpdate.cargo ||
        contato.nome !== contatoToUpdate.nome
    );
    setContatos([...updatedContatos, contatoToUpdate]);
  }

  async function carregarContatos() {
    const { data } = await api.get<ContatoProps[]>(
      `/common/clientes/${clienteId}/contatos`
    );
    setContatos(data);
  }

  useEffect(() => {
    onContatosChange(contatos);
  }, [contatos]);

  useEffect(() => {
    if (!clienteId) return;
    carregarContatos();
  }, [clienteId]);

  return (
    <Fragment>
      {contatos?.map((contato) => (
        <ContatoItem
          key={`${contato.nome}${contato.numero}${contato.cargo}`}
          contato={contato}
          reload={carregarContatos}
          notify={notify}
          removeLocally={removeContatoLocal}
          updateLocally={updateContatoLocal}
        />
      ))}
      <div className="w-100 d-flex">
        <Col lg="3" className="p-1">
          <FormGroup>
            <Input
              placeholder="Cargo"
              className="form-control"
              value={cargo}
              onChange={(e) => setCargo(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" className="p-1">
          <FormGroup>
            <Input
              placeholder="Nome"
              className="form-control"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="1" className="p-1">
          <FormGroup>
            <InputMask
              mask="99"
              maskPlaceholder={null}
              className="form-control"
              placeholder="DDD"
              value={ddd}
              onChange={(e) => setDdd(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col className="p-1">
          <FormGroup>
            <InputMask
              mask="999999999"
              maskPlaceholder={null}
              className="form-control"
              placeholder="Número"
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col className="p-1">
          <FormGroup className="d-flex align-items-center">
            <Button
              type="button"
              disabled={isLoading}
              outline
              color="primary"
              size="sm"
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </FormGroup>
        </Col>
      </div>
    </Fragment>
  );
}
