import React, { useState, useEffect, useContext } from "react";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";

import EquipeList from "./Equipes/ListGroup";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import CurrencyInput from "react-currency-input";
import EquipeForm from "./Equipes/New";
import { Button, Input, Row, Col, FormGroup, Spinner } from "reactstrap";

export default ({
  notify,
  anuncioId,
  campanhaId,
  close,
  onSuccess,
  goTo,
  ...props
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [equipes, setEquipes] = useState([]);
  const [anuncio, setAnuncio] = useState({});
  const [nome, setNome] = useState(null);
  const [situacao, setSituacao] = useState(null);
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [canal, setCanal] = useState(null);
  const [valor, setValor] = useState(null);
  const [orientacao, setOrientacao] = useState(null);
  const [segmentacao, setSegmentacao] = useState(null);
  const [redeSocialId, setRedeSocialId] = useState(null);
  const [funil, setFunil] = useState(null);

  const [situacoes, setSituacoes] = useState([
    { id: "ativo", text: "Ativo" },
    { id: "inativo", text: "Inativo" },
    { id: "em_analise", text: "Em Análise" },
    { id: "nao_aprovado", text: "Não Aprovado" },
  ]);
  const [orientacoes, setOrientacoes] = useState([]);
  const [canais, setCanais] = useState([]);
  const [funis, setFunis] = useState([]);

  const [saving, setSaving] = useState(false);
  const [erros, setErros] = useState({});

  useEffect(() => {
    if (canais.length === 0) loadCanais();
    if (orientacoes.length === 0) loadOrientacoes();
    if (funis.length === 0) loadFunis();
  }, []);

  useEffect(() => {
    setNome(anuncio.nome);
    setSituacao(anuncio.situacao);
    setCanal(anuncio.canal_id);
    setValor(anuncio.valor);
    setOrientacao(anuncio.orientacao_id);
    setDataInicial(anuncio.dt_inicio ? new Date(anuncio.dt_inicio) : null);
    setDataFinal(anuncio.dt_fim ? new Date(anuncio.dt_fim) : null);
    setSegmentacao(anuncio.segmentacao);
    setRedeSocialId(anuncio.rede_social_id);
    setFunil(anuncio.funil_padrao_id);
  }, [anuncio]);

  useEffect(() => {
    if (anuncioId) {
      loadAnuncio();
    }
  }, [anuncioId]);

  useEffect(() => {
    if (anuncio && anuncio.id) loadEquipes();
  }, [anuncio]);

  async function loadEquipes() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada.id}/campanhas/${anuncio?.campanha_id}/anuncios/${anuncio?.id}/equipes`
      );
      setEquipes(await response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível buscar equipes");
    }
  }

  async function loadAnuncio() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada.id}/campanhas/${campanhaId}/anuncios/${anuncioId}`
      );
      setAnuncio(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível buscar anúncios");
    }
  }

  async function loadOrientacoes() {
    try {
      const response = await api.get(`/marketing/anuncios/orientacoes/`);
      setOrientacoes(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar orientações");
    }
  }

  async function loadCanais() {
    try {
      const response = await api.get(`/marketing/canais`);
      setCanais(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar canais");
    }
  }

  async function loadFunis() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada.id}/funis`
      );
      setFunis(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar funis");
    }
  }

  function canalSelecionado() {
    const find = canais.find((it) => it.id == canal);
    return find ? find : {};
  }

  async function handleSave() {
    setSaving(true);
    if (anuncioId) {
      await update();
    } else {
      await insert();
    }
    setSaving(false);
  }

  async function insert() {
    try {
      await api.post(
        `/common/empresas/${empresaSelecionada.id}/campanhas/${campanhaId}/anuncios/`,
        {
          nome,
          dt_fim: dataFinal,
          dt_inicio: dataInicial,
          canal_id: canal,
          orientacao_id: 2,
          valor,
          situacao,
          segmentacao,
          rede_social_id: redeSocialId,
          equipes: equipes.map((item) => item.equipe.id),
          funil_padrao_id: funil,
        }
      );
      updateErros(null);
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      if (notify) notify("danger", "Não foi possível adicionar anúncio");
      if (error.response) updateErros(error.response.data);
    }
  }

  async function update() {
    try {
      await api.put(
        `/common/empresas/${empresaSelecionada.id}/campanhas/${campanhaId}/anuncios/${anuncioId}`,
        {
          nome,
          dt_fim: dataFinal,
          dt_inicio: dataInicial,
          canal_id: canal,
          orientacao_id: 2,
          valor,
          situacao,
          segmentacao,
          rede_social_id: redeSocialId,
          equipes,
          funil_padrao_id: funil,
        }
      );
      updateErros(null);
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      if (notify) notify("danger", "Não foi possível salvar anúncio");
      if (error.response) updateErros(error.response.data);
    }
  }

  async function handleDelete(equipeAnuncio) {
    if (window.confirm(`Deseja remover a equipe?`)) {
      try {
        if (anuncio && anuncio.id) {
          await api.delete(
            `/common/empresas/${empresaSelecionada.id}/campanhas/${anuncio.campanha_id}/anuncios/${anuncio.id}/equipes/${equipeAnuncio.id}`
          );
          notify("success", "Equipe removida");
          await loadEquipes();
        } else {
          setEquipes(
            equipes.filter((item) => item.equipe.id !== equipeAnuncio.equipe.id)
          );
          await loadEquipes();
        }
      } catch (error) {
        console.error(error);
        notify("danger", "Não foi possível remover Equipe");
      }
    }
  }

  async function onAddedEquipe(equipeAnuncio) {
    if (anuncio && anuncio.id) {
      await loadEquipes();
    } else {
      const itens = [...equipes];
      itens.push(equipeAnuncio);
      setEquipes(itens);
    }
  }

  function updateErros(error) {
    if (error && error instanceof Array) {
      const errors = {};
      for (let e of error) {
        errors[e.field] = e.message;
      }
      setErros(errors);
    } else {
      setErros({});
    }
  }

  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={8} xl={8}>
          <Row>
            <Col lg={6}>
              <FormGroup>
                <label className="form-control-label">Nome*</label>
                <Input
                  className="form-control"
                  placeholder="Nome do anúncio..."
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
                <small class="text-danger">
                  {erros.nome ? erros.nome : ""}
                </small>
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <label className="form-control-label">Situação*</label>
                <Select2
                  defaultValue="-1"
                  onSelect={(e) => setSituacao(e.target.value)}
                  options={{
                    placeholder: "Selecione a situação...",
                  }}
                  value={situacao}
                  data={situacoes}
                />
                <small class="text-danger">{erros.situacao || ""}</small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">
                  Data início do anúncio*
                </label>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Selecione quando o anúncio inicia...",
                  }}
                  value={dataInicial}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      dataInicial &&
                      dataFinal &&
                      dataInicial._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      dataInicial &&
                      dataFinal &&
                      new Date(dataInicial._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(dataFinal._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      dataFinal &&
                      dataFinal._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={(e) => setDataInicial(e)}
                />
                <small class="text-danger">{erros.dt_inicio || ""}</small>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label">
                  Data final do anúncio*
                </label>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Selecione quando o anúncio acaba...",
                  }}
                  value={dataFinal}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      dataInicial &&
                      dataFinal &&
                      dataInicial._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      dataInicial &&
                      dataFinal &&
                      new Date(dataInicial._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(dataFinal._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      dataFinal &&
                      dataFinal._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={(e) => setDataFinal(e)}
                />
                <small class="text-danger">{erros.dt_fim || ""}</small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Valor</label>
                <CurrencyInput
                  className="form-control"
                  value={valor}
                  placeholder="Valor do aúncio..."
                  onChangeEvent={(e, maskedvalue, floatvalue) =>
                    setValor(floatvalue)
                  }
                />
                <small class="text-danger">{erros.valor || ""}</small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Funil*</label>
                <Select2
                  defaultValue="-1"
                  onSelect={(e) => setFunil(e.target.value)}
                  options={{
                    placeholder: "Selecione o funil...",
                  }}
                  value={funil}
                  data={funis.map((item) => ({ id: item.id, text: item.nome }))}
                />
                <small class="text-danger">{erros.funil_padrao_id || ""}</small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Canal*</label>
                <Select2
                  defaultValue="-1"
                  onSelect={(e) => setCanal(e.target.value)}
                  options={{
                    placeholder: "Selecione o canal...",
                  }}
                  value={canal}
                  data={canais.map((item) => ({
                    id: item.id,
                    text: item.nome,
                  }))}
                />
                <small class="text-danger">{erros.canal_id || ""}</small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Segmentação</label>
                <Input
                  style={{ height: 100 }}
                  className="form-control"
                  placeholder="Segmentação..."
                  type="textarea"
                  value={segmentacao}
                  onChange={(e) => setSegmentacao(e.target.value)}
                />
                <small class="text-danger">{erros.segmentacao || ""}</small>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col>
          <EquipeForm
            equipesAdicionadas={equipes}
            anuncio={anuncio}
            onAddedEquipe={onAddedEquipe}
          />
          <small class="text-danger">{erros.equipes || ""}</small>
          <EquipeList equipes={equipes} handleDelete={handleDelete} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="float-right ">
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => close()}
            >
              Voltar
            </Button>
            <Button color="primary" onClick={handleSave} disabled={saving}>
              <Spinner
                hidden={!saving}
                className="mr-2"
                color="light"
                size="sm"
              />
              Salvar
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
