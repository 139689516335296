import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Badge } from "reactstrap";
import Table from '../../../components/Table'
import { MenuComportamento } from '../../../components/Menus'


export default ({ esteiras, pageProperties, onTableChange, notify, ...props }) => {

    const history = useHistory()

    const [columns] = useState([
        {
            dataField: 'acoes',
            formatter: (cell, row) => acoesFormatter(cell, row, this)
        },
        {
            dataField: "nome",
            text: 'Nome',
        },
        {
            situacao: "situacao",
            text: 'Situação',
            formatter: (row, column) => situacaoFormater(row, column),
            align: 'center',
            headerAlign: 'center',
        }
    ])


    const situacaoFormater = (cell, row) => (
        <>
            <Badge
                id={`denc-${row.id}`}
                color={row.situacao === 'I' ? 'secondary' : 'success'}
                className="badge-lg"
                pill
            >
                {row.situacao === 'I' ? 'Inativo' : 'Ativo'}
            </Badge>
        </>
    )


    function goToEdit(id) {
        history.push(`/admin/esteiras/${id}/edit`)
    }

    const acoesFormatter = (cell, row, context) => {
        return (
            <MenuComportamento
                actions={[{
                    label: 'Alterar',
                    icons: 'far fa-edit',
                    onClick: () => goToEdit(row.id)
                }]}
            />
        )
    }



    return (
        <>
            <Table
                columns={columns}
                data={esteiras}
                pageProperties={pageProperties}
                onTableChange={onTableChange}
            />
        </>
    );
}

