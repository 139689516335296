import React, { useState, useEffect, useContext, useRef } from 'react';
import EmpresaContext from '../../../../contexts/Empresa';
import moment from 'moment';
import { usePersistedState } from '../../../../hooks';
import api from '../../../../services/api';
import { hasPermission } from '../../../../utils';

import Filters from '../../../../components/Headers/Filters';
import ReactDatetime from 'react-datetime';
import Select2 from 'react-select2-wrapper';
import Select from 'react-select';
import {
  CardBody,
  Row,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import useReports from '../useReports';

const firstDayOfCadastro = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth());
};

export default ({ title, load, history, notify, ...props }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [periodoByCadastro, setPeriodoByCadastro] = usePersistedState(
    'periodoByCadastro',
    true
  );
  const [dataCadastroInicial, setDataCadastroInicial] = usePersistedState(
    'dataCadastroInicial',
    firstDayOfCadastro()
  );
  const [dataCadastroFinal, setDataCadastroFinal] = usePersistedState(
    'dataCadastroFinal',
    moment()
  );

  const [equipes, setEquipes] = usePersistedState('equipes', []);
  const [colaboradores, setColaboradores] = usePersistedState(
    'colaboradores',
    []
  );

  const [canais, setCanais] = usePersistedState('canais', []);
  const [campanha, setCampanha] = usePersistedState('campanha', -1);
  const [anunciosSelecionados, setAnunciosSelecionados] = usePersistedState(
    'anunciosSelecionados',
    []
  );

  const [funil, setFunil] = usePersistedState('funil', -1);
  const [tipoProduto, setTipoProduto] = usePersistedState('tipoProduto', null);
  const [produtos, setProdutos] = usePersistedState('produtos', []);

  const [equipesList, setEquipesList] = useState([]);
  const [colaboradoresList, setColaboradoresList] = useState([]);
  const [canaisList, setCanaisList] = useState([]);
  const [campanhasList, setCampanhasList] = useState([]);
  const [anunciosList, setAnunciosList] = useState([]);
  const [funisList, setFunisList] = useState([]);
  const [tiposProdutosList, setTipoProdutosList] = useState([]);
  const [produtosList, setProdutosList] = useState([]);

  const [monitorClearFilters, setMonitorClearFilters] = useState(undefined);
  const [empresasGrupo, setEmpresasGrupo] = useState([]);
  const [empresaGrupoId, setEmpresaGrupoId] = useState(null);
  const {
    fetchEmpresasGrupo,
    fetchEquipes,
    fetchCampanhas,
    fetchCanais,
    fetchFunis,
    fetchProdutos,
    fetchTiposProdutos,
  } = useReports();

  const equipesRef = useRef(null);

  useEffect(() => {
    loadEmpresasGrupo();
    loadCanais();
  }, []);

  useEffect(() => {
    if (monitorClearFilters) {
      search();
    }
  }, [monitorClearFilters]);

  useEffect(() => {
    if (!empresaGrupoId) return;

    loadFunis();
    loadTiposProdutos();
    loadEquipes();
    loadCampanhas();

    if (campanha) loadAnuncios(campanha);
  }, [empresaGrupoId]);

  useEffect(() => {
    if (!tipoProduto) return;

    loadProdutos(tipoProduto);
  }, [tipoProduto]);

  useEffect(() => {
    if (equipesList && equipesList.length > 0) {
      fillEquipes();
    }
  }, [equipesList]);

  useEffect(() => {
    if (equipes.length === 0) {
      fillEquipes();
    } else {
      if (hasPermission('ver-todas-oportunidades') && empresaGrupoId) {
        loadColaboradores();
      }
    }
  }, [equipes]);

  const changeEquipes = (e) => setEquipes(e);

  function fillEquipes() {
    if (equipesList && equipesList.length > 0) {
      setEquipes(equipesList.map((item) => item.id));
    }
  }

  async function loadEmpresasGrupo() {
    const empresas = await fetchEmpresasGrupo(empresaSelecionada.id);
    setEmpresasGrupo(empresas);
  }

  async function loadEquipes() {
    const equipes = await fetchEquipes(empresaGrupoId);
    setEquipesList(equipes);
  }

  async function loadColaboradores() {
    try {
      setColaboradores([]);
      const response = await api.get(
        `common/empresas/${empresaGrupoId}/colaboradores`,
        {
          params: {
            roles: ['comercial', 'relacionamento'],
            equipes: equipes && equipes.length > 0 ? equipes : [-1],
          },
        }
      );
      setColaboradoresList(response.data);
      if (colaboradores && colaboradores.length > 0) {
        setColaboradores(colaboradores);
      } else {
        setColaboradores([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadCanais() {
    try {
      const canais = await fetchCanais();
      setCanaisList(canais);
    } catch (error) {
      console.error(error);
      notify('danger', 'Não foi possível carregar canais.');
    }
  }

  async function loadCampanhas() {
    const campanhas = await fetchCampanhas(empresaGrupoId);

    if (campanhas) {
      campanhas.unshift({ id: -1, nome: '- Todas -' });
      setCampanhasList(campanhas);
    }
  }

  async function loadAnuncios(campanha_id) {
    setCampanha(campanha_id);

    if (campanha_id) {
      var response = null;

      response = await api.get(
        `marketing/anuncios/${campanha_id}/${empresaGrupoId}`,
        {
          params: {
            situacao: 'ativo',
            canais,
          },
        }
      );

      setAnunciosList(await response.data);
      if (anunciosSelecionados && anunciosSelecionados.length > 0) {
        setAnunciosSelecionados(anunciosSelecionados);
      } else {
        setAnunciosSelecionados([]);
      }
    }
  }

  async function loadFunis() {
    const funis = await fetchFunis(empresaGrupoId);

    if (funis) {
      funis.unshift({ id: -1, nome: '- Todos -' });
      setFunisList(funis);
    }
  }

  async function loadTiposProdutos() {
    const tiposProdutos = await fetchTiposProdutos(empresaGrupoId);

    if (tiposProdutos) {
      tiposProdutos.unshift({ id: -1, nome: '- Todos -' });
      setTipoProdutosList(tiposProdutos);
    }
  }

  async function loadProdutos(tipoProduto) {
    if (tipoProduto && tipoProduto == -1) {
      tipoProduto = null;
    }
    const result = await fetchProdutos(empresaGrupoId, tipoProduto);
    setProdutosList(result);
    if (produtos && produtos.length > 0) {
      setProdutos(produtos);
    } else {
      setProdutos([]);
    }
  }

  function handleMultipleSelect(target, state) {
    const array = Array.from(target.selectedOptions);
    state(array.map((item) => item.value));
  }

  async function search() {
    await load(
      {
        periodoByCadastro,
        dataCadastroInicial,
        dataCadastroFinal,
        equipes: equipes && equipes.length > 0 ? equipes : [-1],
        colaboradores,

        canais,
        campanha,
        anuncios: anunciosSelecionados,

        funil,
        tipoProduto,
        produtos,
      },
      empresaGrupoId
    );
  }

  async function handleClearFilter() {
    setPeriodoByCadastro(true);
    setDataCadastroInicial(firstDayOfCadastro());
    setDataCadastroFinal(new Date());
    setEquipes([]);
    setColaboradores([]);

    setCanais([]);
    setCampanha(-1);
    setAnunciosSelecionados([]);

    setFunil(-1);
    setProdutos([]);
    setTipoProduto(null);
    setEmpresaGrupoId(null);
  }

  function ColaboradorSelect({}) {
    if (hasPermission('ver-todas-oportunidades')) {
      return (
        <Row className="py-4">
          <Col xs="12" lg="12" sm="12" md="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example-number-input"
              >
                Equipes
              </label>
              <InputGroup className="input-group-alternative">
                <Select
                  closeMenuOnSelect={false}
                  placeholder="Selecione suas equipes..."
                  styles={{ container: () => ({ width: '100%' }) }}
                  isMulti
                  isSearchable
                  onChange={(values) => {
                    console.log({ values });
                    setEquipes(values.map((e) => e.value));
                  }}
                  options={equipesList.map((item) => ({
                    value: item.id,
                    label: item.nome,
                  }))}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs="12" lg="12" sm="12" md="12">
            <FormGroup>
              <label className="form-control-label">Colaboradores</label>
              <InputGroup className="input-group-alternative">
                <Select2
                  multiple
                  onSelect={({ target }) =>
                    handleMultipleSelect(target, setColaboradores)
                  }
                  onUnselect={({ target }) =>
                    handleMultipleSelect(target, setColaboradores)
                  }
                  options={{
                    placeholder: 'Selecione os colaboradore...',
                  }}
                  value={colaboradores}
                  data={colaboradoresList.map((user) => ({
                    id: user.id,
                    text: user.name,
                  }))}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      );
    }
    return null;
  }

  function AnuncioSelect({}) {
    return (
      <Row className="py-4">
        <Col xs="12" lg="4" sm="12" md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example-number-input"
            >
              Canais
            </label>
            <InputGroup className="input-group-alternative">
              <Select2
                multiple
                onSelect={({ target }) =>
                  handleMultipleSelect(target, setCanais)
                }
                onUnselect={({ target }) =>
                  handleMultipleSelect(target, setCanais)
                }
                options={{
                  placeholder: 'Selecione...',
                }}
                value={canais}
                data={canaisList.map((item) => ({
                  id: item.id,
                  text: item.nome,
                }))}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs="12" lg="4" sm="12" md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example-number-input"
            >
              Campanha
            </label>
            <InputGroup className="input-group-alternative">
              <Select2
                defaultValue="-1"
                onSelect={(e) => loadAnuncios(e.target.value)}
                options={{
                  placeholder: 'Selecione uma campanha...',
                }}
                value={campanha}
                data={campanhasList.map((item) => ({
                  id: item.id,
                  text: item.nome,
                }))}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs="12" lg="4" sm="12" md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example-number-input"
            >
              Anúncios
            </label>
            <InputGroup className="input-group-alternative">
              <Select2
                multiple
                onSelect={({ target }) =>
                  handleMultipleSelect(target, setAnunciosSelecionados)
                }
                onUnselect={({ target }) =>
                  handleMultipleSelect(target, setAnunciosSelecionados)
                }
                options={{
                  placeholder: 'Selecione os anúncios...',
                }}
                value={anunciosSelecionados}
                data={anunciosList.map((item) => ({
                  id: item.id,
                  text: item.nome,
                }))}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  function FunilAndProdutoSelect({}) {
    return (
      <Row className="py-4">
        <Col xs="12" lg="4" sm="12" md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example-number-input"
            >
              Funil
            </label>
            <InputGroup className="input-group-alternative">
              <Select2
                className="input-group-alternative"
                defaultValue="-1"
                options={{
                  placeholder: 'Selecione...',
                }}
                onSelect={({ target }) => setFunil(target.value)}
                value={funil}
                data={funisList.map((item) => ({
                  id: item.id,
                  text: item.nome,
                }))}
              />
            </InputGroup>
          </FormGroup>
        </Col>

        <Col xs="12" lg="4" sm="12" md="6">
          <FormGroup>
            <label className="form-control-label">Tipo de produto</label>
            <InputGroup className="input-group-alternative">
              <Select2
                className="input-group-alternative"
                defaultValue="-1"
                options={{
                  placeholder: 'Selecione...',
                }}
                onSelect={({ target }) => setTipoProduto(target.value)}
                value={tipoProduto}
                data={tiposProdutosList.map((item) => ({
                  id: item.id,
                  text: item.nome,
                }))}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs="12" lg="4" sm="12" md="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example-number-input"
            >
              Produtos
            </label>
            <InputGroup className="input-group-alternative">
              <Select2
                multiple
                onSelect={({ target }) =>
                  handleMultipleSelect(target, setProdutos)
                }
                onUnselect={({ target }) =>
                  handleMultipleSelect(target, setProdutos)
                }
                options={{
                  placeholder: 'Selecione...',
                }}
                value={produtos}
                data={produtosList.map((item) => ({
                  id: item.id,
                  text: item.nome,
                }))}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Filters
        title={title}
        onSearch={search}
        clearFilters={handleClearFilter}
        onFiltersClead={setMonitorClearFilters}
      >
        <CardBody>
          <Row className="mb-4">
            <Col xs="auto" lg="6" sm="12" md="12">
              <fieldset className="border p-2">
                <legend className="w-auto" style={{ margin: 0 }}>
                  <label className="form-control-label">
                    Período
                    <div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="customRadioInline1"
                          name="customRadioInline1"
                          class="custom-control-input"
                          checked={periodoByCadastro}
                          onChange={({ target }) =>
                            setPeriodoByCadastro(target.checked)
                          }
                        ></input>
                        <label
                          class="custom-control-label"
                          for="customRadioInline1"
                        >
                          Cadastro
                        </label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="customRadioInline2"
                          name="customRadioInline1"
                          class="custom-control-input"
                          checked={!periodoByCadastro}
                          onChange={({ target }) =>
                            setPeriodoByCadastro(!target.checked)
                          }
                        ></input>
                        <label
                          class="custom-control-label"
                          for="customRadioInline2"
                        >
                          Passagem
                        </label>
                      </div>
                    </div>
                  </label>
                </legend>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Início
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Data inicial',
                            style: {
                              width: '100px',
                            },
                          }}
                          value={moment(dataCadastroInicial)}
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              dataCadastroInicial._d + '' ===
                                currentDate._d + ''
                            ) {
                              classes += ' start-date';
                            } else if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              new Date(dataCadastroInicial._d + '') <
                                new Date(currentDate._d + '') &&
                              new Date(dataCadastroFinal._d + '') >
                                new Date(currentDate._d + '')
                            ) {
                              classes += ' middle-date';
                            } else if (
                              dataCadastroFinal &&
                              dataCadastroFinal._d + '' === currentDate._d + ''
                            ) {
                              classes += ' end-date';
                            }
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          onChange={(e) => setDataCadastroInicial(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Fim
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Data final',
                            style: {
                              width: '100px',
                            },
                          }}
                          style={{ width: '100px' }}
                          value={moment(dataCadastroFinal)}
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              dataCadastroInicial._d + '' ===
                                currentDate._d + ''
                            ) {
                              classes += ' start-date';
                            } else if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              new Date(dataCadastroInicial._d + '') <
                                new Date(currentDate._d + '') &&
                              new Date(dataCadastroFinal._d + '') >
                                new Date(currentDate._d + '')
                            ) {
                              classes += ' middle-date';
                            } else if (
                              dataCadastroFinal &&
                              dataCadastroFinal._d + '' === currentDate._d + ''
                            ) {
                              classes += ' end-date';
                            }
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          onChange={(e) => setDataCadastroFinal(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>

          {hasPermission('ver-todas-oportunidades') && (
            <Row className="py-4">
              <Col xs="12" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Empresa
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      defaultValue="-1"
                      onSelect={(e) => setEmpresaGrupoId(e.target.value)}
                      options={{
                        placeholder: 'Selecione uma empresa',
                      }}
                      value={empresaGrupoId}
                      data={empresasGrupo.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Equipes
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select
                      closeMenuOnSelect={false}
                      placeholder="Selecione suas equipes..."
                      styles={{ container: () => ({ width: '100%' }) }}
                      isMulti
                      isSearchable
                      onChange={(values) => {
                        console.log({ values });
                        setEquipes(values.map((e) => e.value));
                      }}
                      options={equipesList.map((item) => ({
                        value: item.id,
                        label: item.nome,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="12" lg="12" sm="12" md="12">
                <FormGroup>
                  <label className="form-control-label">Colaboradores</label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      multiple
                      onSelect={({ target }) =>
                        handleMultipleSelect(target, setColaboradores)
                      }
                      onUnselect={({ target }) =>
                        handleMultipleSelect(target, setColaboradores)
                      }
                      options={{
                        placeholder: 'Selecione os colaboradore...',
                      }}
                      value={colaboradores}
                      data={colaboradoresList.map((user) => ({
                        id: user.id,
                        text: user.name,
                      }))}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          )}

          <AnuncioSelect />

          <FunilAndProdutoSelect />
        </CardBody>
      </Filters>
    </>
  );
};
