import React, { useEffect, useState } from "react";
import { UsuarioModeloSite } from "../../CentralVendedor";
import api from "../../../../../../../services/api";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { EmpresaModeloSite } from "../../../../../../common/Empresas/MinhaEmpresa/Sites";

interface EditarModeloModalProps {
  onClose: () => void;
  usuarioSite: UsuarioModeloSite | EmpresaModeloSite | undefined;
}

interface FormDataProps {
  [key: string]: {
    value: string;
    description: string | undefined;
  };
}

interface ParamsMapProps {
  id: number;
  chave: string;
  valor: string;
  descricao: string;
  usuario_modelo_site_id: string;
}

export function useEditarModeloModal({
  onClose,
  usuarioSite,
}: EditarModeloModalProps) {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formData, setFormData] = useState<FormDataProps>({});
  const [paramsMap, setParamsMap] = useState<ParamsMapProps[]>([]);
  const [alert, setAlert] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [iframeURLQueryParams, setIframeURLQueryParams] = useState("");

  function handleClose() {
    clearParamsPreview();
    setFormData({});
    onClose();
  }

  async function carregarParametros() {
    try {
      if (!usuarioSite) return;
      const { data: params } = await api.get<ParamsMapProps[]>(
        `/marketing/modelos-sites/parametros/${usuarioSite.id}`
      );

      const siteParams: ParamsMapProps[] = JSON.parse(
        usuarioSite.site.params_map
      );

      const updatedParamsMap = siteParams.map((param) => {
        const foundParam = params.find((uParam) => uParam.chave == param.chave);

        return foundParam || param;
      });

      setParamsMap(updatedParamsMap);
    } catch (error) {
      console.error(error);
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, dataset } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: {
        value,
        description: dataset.description,
      },
    }));
  };

  function onParamsPreview() {
    let queryParams = "";
    Object.entries(formData).forEach(([name, { value }]) => {
      queryParams += `&${name}=${value}`;
    });

    setIframeURLQueryParams(`${queryParams}`);
  }

  function clearParamsPreview() {
    setIframeURLQueryParams("");
  }

  async function onSubmit() {
    const formDataArray = Object.entries(formData).map(
      ([name, { value, description }]) => ({
        chave: name,
        valor: value,
        descricao: description,
      })
    );

    try {
      setIsLoading(true);
      await api.post("/marketing/modelos-sites/parametros", {
        siteParams: formDataArray,
        usuario_modelo_site_id: usuarioSite?.id,
      });

      carregarParametros();
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block" }}
          title="Sucesso!"
          onConfirm={() => {
            handleClose();
            setAlert(null);
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Página editada com sucesso!
        </ReactBSAlert>
      );
    } catch (error) {
      console.error("Houve um erro ao tentar fazer a requisição.", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (usuarioSite?.id) carregarParametros();
  }, [usuarioSite]);

  return {
    onSubmit,
    handleInputChange,
    paramsMap,
    isFormVisible,
    setIsFormVisible,
    onParamsPreview,
    iframeURLQueryParams,
    clearParamsPreview,
    handleClose,
    alert,
    isLoading
  };
}
