import React, { useState, useContext } from 'react';
import moment from 'moment';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import { hasPermission } from '../../../utils';
 
import { useHistory } from "react-router-dom";
import EmpresaContext from "../../../contexts/Empresa";

import { User } from '../../../entities/Security';
import Avatares from '../../../components/timeLines/Pdca/Estrategias/Item/Avatares';

import { MenuComportamento } from '../../../components/Menus' 



export default ({ acoes, pageProperties, notify, history, ...props }) => {

    const { empresaSelecionada, changeEmpresaSelecionada, empresas } = useContext(EmpresaContext)

    function userQuensFormatter(row, column) {
        //console.log(column);
        return <Avatares
            users={column.users || []}
        />
    }

    const dataCriacaoFormatter = (column) => {
        return (
            <>
                {moment(column.quando).format('D MMM YYYY HH:mm')}
            </>
        )
    }


    function situacaoFormatter(column) {
        let situacao;
        switch (column.situacao) {
            case 'nao_iniciado':
                situacao = 'Não Iniciado'
                break;

            case 'concluido':
                situacao = 'Concluído'
                break;

            case 'iniciado':
                situacao = 'Iniciado'
                break;

            case 'nao_executado':
                situacao = 'Não Executado'
                break;

            default:
                break;

        }
        return <div>{situacao}</div>
    }


    function handleEdit(row, column) {
        console.log({column})
        console.log({row})
        const empresa = empresas.find(item => item.id === column.objetivo.empresa.id)
        changeEmpresaSelecionada(empresa)
        history.push(`/admin/pdcas/${column?.objetivo_id}/edit`)
    }

    // function goToEdit(id) {
    //     history.push(`/admin/esteiras/${id}/edit`)
    // }


    function acoesFormatter(row, column) {
        return (
            <MenuComportamento
                actions={[
                    {
                        label: 'Ver/Alterar',
                        icon: 'far fa-edit',
                        onClick: () => handleEdit(row, column)
                    }
                ]}
            />
        )
    }



    const [columns] = useState([
        {
            dataField: 'objetivo_id',
            formatter: (row, column) => acoesFormatter(row, column)
        },
        {
            dataField: 'objetivo.empresa.nome',
            text: 'Empresa',
        },
        {
            dataField: "o_que",
            text: 'Ação',
        },
        {
            dataField: "quando",
            text: 'Quando',
            formatter: (row, column) => dataCriacaoFormatter(column),
            sort: true
        },
        {
            dataField: 'userQuens',
            text: 'Quem',
            formatter: (row, column) => userQuensFormatter(row, column),
        },
        {
            dataField: 'objetivo.estrategia.nome',
            text: 'Estratégia',
        },
        {
            dataField: 'situacao',
            text: 'Situação',
            formatter: (row, column) => situacaoFormatter(column),
        },
    ])


    const NoDataIndication = () => (
        <div className="spinner">
            <div className="rect1" />
            <div className="rect2" />
            <div className="rect3" />
            <div className="rect4" />
            <div className="rect5" />
        </div>
    );

    const RemotePagination = ({ loading, data, page, sizePerPage, onTableChange, totalSize }) => (
        <div>
            <PaginationProvider
                pagination={
                    paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                        alwaysShowAllBtns: true,
                        showTotal: true,
                        withFirstAndLast: true,
                        paginationTotalRenderer: (from, to, size) => {
                            const margin = {
                                paddingLeft: '4px'
                            }
                            return (
                                <span className="react-bootstrap-table-pagination-total" style={margin}>
                                    Exibindo {from} a {to} do total de {totalSize}.
                                </span>
                            )
                        },
                        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                    Exibir{" "}
                                    {
                                        <select
                                            name="datatable-basic_length"
                                            value={currSizePerPage}
                                            aria-controls="datatable-basic"
                                            className="form-control form-control-sm"
                                            onChange={e => onSizePerPageChange(e.target.value)}
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    }{" "}
                                    itens.
                          </label>
                            </div>
                        )
                    })
                }
            >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                    remote
                                    loading={loading}
                                    keyField="id"
                                    noDataIndication={() => <NoDataIndication />}
                                    bootstrap4={true}
                                    bordered={false}
                                    data={data}
                                    columns={columns}
                                    noDataIndication="Nenhum item encontrado"
                                    onTableChange={onTableChange}
                                    overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(150, 150, 150, 0.5)' }) } })}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                }
            </PaginationProvider>
        </div>
    );


    return (
        <>
            {
                acoes && pageProperties &&
                <RemotePagination
                    data={acoes}
                    page={pageProperties.page}
                    sizePerPage={pageProperties.perPage}
                    totalSize={pageProperties.total}
                    onTableChange={props.onTableChange}
                    loading={pageProperties.loading}
                />
            }
        </>
    );
}
