import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import api from "../../../../../../../services/api";
import NotificationAlert from "react-notification-alert";
import { Equipe } from "../../../../../../../entities/Common";

interface Colaborador {
  id: number;
  name: string;
  email: string;
  userEmpresas: {
    equipe_id: number;
    nome: string;
  }[];
}
interface EquipeTrabahoProps {
  onClose: () => void;
  isOpen: boolean;
  equipes: Equipe[];
  loadEquipes: () => Promise<void>;
  colaborador: Colaborador;
}

export function EquipeTrabalho({
  isOpen,
  onClose,
  equipes,
  loadEquipes,
  colaborador,
}: EquipeTrabahoProps) {
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [equipeTrabalhoSelecionada, setEquipeTrabalhoSelecionada] = useState<{
    value: string | number;
    label: string;
  } | null>(null);
  const notificationAlert = useRef<any>(null);

  const inputBorderStyles =
    submitted && !equipeTrabalhoSelecionada ? { borderColor: "red" } : {};

  const customStyles = {
    container: (provided) => ({ ...provided, flex: 1 }),
    control: (provided) => ({
      ...provided,
      minHeight: "46px",
      ...inputBorderStyles,
    }),
  };

  useEffect(() => {
    if (!colaborador) return;
    const equipeAnterior = equipes.find(
      (equipe) => equipe.id === colaborador?.userEmpresas[0].equipe_id
    );
    if (equipeAnterior)
      setEquipeTrabalhoSelecionada({
        value: equipeAnterior.id,
        label: equipeAnterior.nome,
      });
  }, [colaborador]);

  const notify = (type: string, msg: string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  function handleUpdate() {
    setSubmitted(true);
    if (!equipeTrabalhoSelecionada) return;
    update();
    onClose();
  }

  async function update() {
    try {
      setIsLoading(true);

      await api.post(`/security/usuarios/empresas/update-equipe`, {
        equipe_id: equipeTrabalhoSelecionada?.value,
        user_id: colaborador.id,
      });

      setSubmitted(false);
      notify("success", "Colaborador adicionado à equipe com sucesso!");
      loadEquipes();
    } catch (error) {
      notify("danger", "Não foi possível adicionar o colaborador à equipe.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>Editar Equipes de Trabalho</ModalHeader>
        <ModalBody>
          <form className="new-event--form">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example-number-input"
              >
                Equipe de Trabalho
              </label>
              <InputGroup className="input-group">
                <Select
                  placeholder="Selecione a equipe de trabalho..."
                  styles={customStyles}
                  value={equipeTrabalhoSelecionada}
                  onChange={(item) => setEquipeTrabalhoSelecionada(item)}
                  options={equipes.map((item: any) => ({
                    value: item.id,
                    label: item.nome,
                  }))}
                />
                <Input
                  className="d-none"
                  aria-hidden
                  invalid={submitted && !equipeTrabalhoSelecionada}
                />
                <FormFeedback className="col-12 p-0">
                  Selecione uma equipe de trabalho.
                </FormFeedback>
              </InputGroup>
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleUpdate}
            disabled={isLoading || !equipeTrabalhoSelecionada}
          >
            Salvar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
