import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  SetStateAction,
} from "react";
import api from "../../../../../services/api";
import EmpresaContext from "../../../../../contexts/Empresa";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import ReactDOM from "react-dom";
import { QRCodeSVG } from "qrcode.react";
import { ApiAccess } from "../../../../../entities/Security";

//@ts-ignore
import Select2 from "react-select2-wrapper";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
//@ts-ignore
import InputMask from "react-input-mask";

import {
  Spinner,
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  CardFooter,
  CardHeader,
  CardBody,
  Card,
  UncontrolledTooltip,
} from "reactstrap";
import { user } from "../../../../../utils";
import AuthContext from "../../../../../contexts/Auth";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
  hideModal(): void;
  oportunidade: any;
  telefones: any;
  notify: any;
}
interface ErrorProps {
  cliente_id: number;
  nome: string;
  email: string;
  sexo: string;
  telefones: string;
  campanha_id: number;
  anuncio_id: number;
}

interface PaginaProgramaIndicacao {
  indicacao: { preview: string; link: string };
}

const QRCode: React.FC<Props> = ({ hideModal, oportunidade, notify }) => {
  const { user } = useContext(AuthContext);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [cliente, setCliente] = useState<any>();
  const [erros, setErros] = useState<ErrorProps>();
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState<any>();
  const history = useHistory();

  const [apiAccess, setApiAccess] = useState<ApiAccess>({} as ApiAccess);
  const [telefones, setTelefones] = useState<any>([]);
  const [campanhas, setCampanhas] = useState<any>([]);
  const [anuncios, setAnuncios] = useState<any>([]);
  const [campanha, setCampanha] = useState<any>(null);
  const [linksiteIndicacao, setLinkSiteIndicacao] = useState<any>(null);
  const [anuncioColaborador, setAnuncioColaborador] = useState<any>(null);
  const [anuncio, setAnuncio] = useState<any>(null);
  const [descricao, setDescricao] = useState<any>(
    "Cliente indicado por: " + oportunidade.cliente.nome
  );
  const [textCopiar, setTextCopiar] = useState("Copiar link");
  const [linkPaginaIndicacao, setLinkPaginaIndicacao] = useState("");

  useEffect(() => {
    loadCampanhas();
    loadTelefonesCliente();
    loadLinkSiteIndicacao();
    loadAnuncioColaborador();
    loadApiAccess();
    carregarSiteUsuario();
  }, []);

  useEffect(() => {
    if (campanha && campanha.id > 0) {
      loadAnuncios(campanha.id);
    } else {
      setAnuncios([]);
      setAnuncio(null);
    }
  }, [campanha]);

  async function loadApiAccess() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/api-access`
      );
      setApiAccess(response?.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar empresa");
    }
  }

  async function loadCampanhas() {
    const response = await api.get(
      `marketing/campanha/${empresaSelecionada?.id}`,
      {
        params: {
          situacao: "ativo",
        },
      }
    );
    setCampanhas(response.data);
  }

  async function loadAnuncios(campanha_id: any) {
    if (campanha_id) {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/campanhas/${campanha_id}/anuncios`,
        {
          params: {
            situacao: "ativo",
          },
        }
      );
      setAnuncios(response.data);
    }
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: "calc(2.75rem + 2px)",
    }),
  };

  function getLink() {
    // return (
    //   linksiteIndicacao +
    //   "?anuncio_id=" +
    //   anuncioColaborador +
    //   "&cliente_indicador_id=" +
    //   oportunidade.cliente.id +
    //   "&criador_id=" +
    //   oportunidade.user_id +
    //   "&token=" +
    //   apiAccess.token
    // );

    return (
      linkPaginaIndicacao + "&cliente_indicador_id=" + oportunidade.cliente.id
    );
  }

  function handleOpenWhatsapp(ddd: any, numero: any) {
    const telefone = `${ddd}${numero}`;
    const win = window.open(
      `https://api.whatsapp.com/send?l=pt_BR&phone=55${telefone}&text=` +
        getLink(),
      "_blank"
    );
    win?.focus();
  }

  async function loadTelefonesCliente() {
    try {
      // @ts-ignore
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/clientes/${oportunidade?.cliente_id}/telefonesbycliente`
      );
      const tels = await response.data;
      setTelefones(tels);
    } catch (error) {
      console.error(error);
    }
  }

  async function carregarSiteUsuario() {
    try {
      const {
        data: { indicacao },
      } = await api.get<PaginaProgramaIndicacao>(
        "/marketing/modelos-sites/usuario"
      );

      setLinkPaginaIndicacao(indicacao.link);
    } catch (error) {
      console.error("Houve um erro ao carregar os dados do site.", error);
    }
  }

  async function loadLinkSiteIndicacao() {
    const response = await api.get(
      `common/empresas/${empresaSelecionada?.id}/sites-empresas/indicacao`
    );
    console.log(response.data.path);

    setLinkSiteIndicacao(response.data.path);
  }
  async function loadAnuncioColaborador() {
    const response = await api.get(
      `marketing/anuncios/usuario-afiliado-id/${oportunidade.user_id}`
    );
    setAnuncioColaborador(response.data.length ? response.data[0].id : null);
  }

  return (
    <>
      <Container className="p-0">
        <Card className="m-0">
          <CardHeader>
            <h2>Gerador de Link</h2>
          </CardHeader>
          <CardBody>
            {!anuncioColaborador && (
              <h2>
                Não existe um anúncio do programa de indicação configurado para
                o colaborador(a) {oportunidade?.user.name}.
              </h2>
            )}

            {anuncioColaborador && (
              <>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <Row>
                      <Col sm="12" md="12" lg="12" className="text-center">
                        <QRCodeSVG value={getLink()} />
                      </Col>
                    </Row>
                    <Row className="mt-5 justify-content-center">
                      <Col sm="12" md="6" lg="4" className="text-center">
                        <div>
                          <CopyToClipboard
                            text={getLink()}
                            onCopy={() => setTextCopiar("Copiado")}
                          >
                            <Button
                              className="ml-auto"
                              color="primary"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => setTextCopiar("Copiado")}
                            >
                              <i
                                className="fas fa-link mr-2"
                                style={{ fontSize: 20 }}
                              ></i>
                              Copiar link
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </Col>
                      <Col sm="12" md="6" lg="4" className="text-center">
                        <div>
                          {console.log(telefones)}
                          {telefones.map((item: any, key: any) => (
                            <Button
                              className="ml-auto"
                              color="primary"
                              data-dismiss="modal"
                              type="button"
                              onClick={() =>
                                handleOpenWhatsapp(item.ddd, item.numero)
                              }
                            >
                              <i
                                className="fab fa-whatsapp mr-2"
                                style={{ fontSize: 20 }}
                              ></i>
                              Whatsapp
                            </Button>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
          <CardFooter>
            <div className="float-right">
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={hideModal}
              >
                Voltar
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};
export default QRCode;
