import React, { useState, useEffect, useContext, useRef } from "react";
import EmpresaContext from "../../../../../contexts/Empresa";
import AuthContext from "../../../../../contexts/Auth";
import api from "../../../../../services/api";
//@ts-ignore
import Select2 from "react-select2-wrapper";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import { ApiAccess } from "../../../../../entities/Security";

import {
  Button,
  Row,
  Col,
  Modal,
  ListGroupItem,
  ListGroup,
  FormGroup,
  Container,
  ModalBody,
} from "reactstrap";
import Anuncio from "../../../../../entities/Marketing/Anuncio";
import { User } from "../../../../../entities/Security";
import { useReactToPrint } from "react-to-print";
import CartaoColaborador from "../../../ProgramaDeIndicacao/components/CartaoColaborador";
import IAnuncio from "../../../../../entities/Marketing/Anuncio";
import ICampanha from "../../../../../entities/Marketing/Campanha";

interface Props {
  anuncio: Anuncio;
  show: boolean;
  onClose: any;
}

const ProgramaDeIndicacao: React.FC<Props> = ({
  anuncio,
  show = false,
  onClose = () => {},
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { auth } = useContext(AuthContext);
  const [usuarioAfiliadoId, setUsuarioAfiliadoId] = useState<User>();
  const [anuncioColaborador, setAnuncioColaborador] = useState<IAnuncio>();
  const [colaboradorId, setColaboradorId] = useState<number>();
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState<User>();
  const [campanhaAtual, setCampanhaAtual] = useState<ICampanha>();
  const [sitesEmpresa, setSitesEmpresa] = useState<any>();
  const [showCartaoColaborador, setShowCartaoColaborador] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const notificationRef = useRef<NotificationAlert>(null);
  const [apiAccess, setApiAccess] = useState<ApiAccess>({} as ApiAccess);
  const cartaoColaboradorRef = useRef(null);
  const [link, setLink] = useState("");
  const handlePrint = useReactToPrint({
    content: () => cartaoColaboradorRef.current,
  });

  useEffect(() => {
    anuncio && loadUsuarioAfiliadoId();
    loadApiAccess();
  }, [show, anuncio]);

  useEffect(() => {
    anuncioColaborador && loadCampanhaSelecionada();
  }, [anuncioColaborador]);

  async function loadApiAccess() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/api-access`
      );
      setApiAccess(response?.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar empresa");
    }
  }

  async function loadUsuarioAfiliadoId() {
    try {
      const response = await api.get(
        `marketing/anuncios/${anuncio?.id}/usuario-afiliado-id`
      );
      setUsuarioAfiliadoId(response.data[0]?.usuarioAfiliado?.user);
      await loadSitesEmpresa();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível buscar usuario id");
    }
  }
  async function loadCampanhaSelecionada() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/campanhas/${anuncioColaborador?.campanha_id}`
      );
      console.log("anuncio colaborador: ", anuncioColaborador);

      setCampanhaAtual(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível buscar usuario id");
    }
  }

  const notify = (type: string, msg: string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text" style={{ zIndex: 2000 }}>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      autoDismiss: 3,
    };
    if (notificationRef.current)
      notificationRef.current.notificationAlert(options);
  };

  async function loadSitesEmpresa() {
    try {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/sites-empresas`
      );
      response.data.map((e: any) => {
        if (e.is_indicacao == true) {
          setSitesEmpresa(e);
        }
      });
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível buscar sites da empresa");
    }
  }

  async function handleDelete(usuarioAfiliado: { name: string; id: number }) {
    if (window.confirm(`Deseja remover ${usuarioAfiliado.name} do anúncio?`)) {
      try {
        await api.put(`marketing/anuncios/${anuncio?.id}/usuario-afiliado-id`, {
          usuario_afiliado_id: null,
        });
        notify("success", "Colaborador removido");
        await loadUsuarioAfiliadoId();
      } catch (error) {
        console.error(error);
        notify("danger", "Não foi possível remover colaborador");
      }
    }
  }

  async function verifyUsuarioAfiliado(id: number) {
    try {
      const response = await api.get(
        `marketing/anuncios/usuario-afiliado-id/${id}`
      );
      if (response.data.length) {
        setAnuncioColaborador(response.data[0]);
        await loadColaboradores();

        setShowAlertModal(true);
      } else {
        handleAdded(id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadColaboradores() {
    try {
      const response = await api.get(`/security/usuarios/${colaboradorId}`);
      if (response.data) {
        setColaboradorSelecionado(response.data);
      }
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar os colaboradores");
    }
  }

  async function handleAdded(id: number) {
    try {
      await api.put(`marketing/anuncios/${anuncio?.id}/usuario-afiliado-id`, {
        usuario_afiliado_id: id,
      });
      notify("success", "Colaborador adicionado");
      await loadUsuarioAfiliadoId();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível adicionar colaborador");
    }
  }

  function clienteOptions() {
    const option = {
      placeholder: "Colaboradores...",
    };
    // @ts-ignore
    option["ajax"] = {
      url: `${process.env.REACT_APP_API_URL}/common/empresas/${empresaSelecionada?.id}/colaboradores`,
      dataType: "json",
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
      processResults: function (data: any) {
        return {
          results: data.map((item: { id: any; name: any; email: any }) => ({
            id: item.id,
            text: `${item.name}, ${item.email}`,
          })),
        };
      },
    };
    return option;
  }

  return (
    <>
      <NotificationAlert ref={notificationRef} />
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={showAlertModal}
        toggle={() => setShowAlertModal(!showAlertModal)}
      >
        <div className="modal-header">
          <h1
            className="modal-title"
            style={{
              color: "#ff0000",
              fontWeight: "bold",
              fontSize: "1.4em",
            }}
          >
            ATENÇÃO
            <br />
          </h1>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            {colaboradorId && (
              <>
                <h3>
                  O colaborador "{colaboradorSelecionado?.name}" já possui um
                  anúncio no Programa de indicação, confira:
                </h3>
                <h3
                  className="mb-3"
                  style={{
                    color: "#fff",
                    backgroundColor: "rgb(8, 71, 214)",
                    width: "fit-content",
                    padding: "2px 10px",
                    borderRadius: "10px",
                    filter: "drop-shadow(2px 2px 4px #c1c1c1)",
                    fontWeight: "bolder",
                  }}
                >
                  Campanha: {campanhaAtual?.nome}
                  <br />
                  Anúncio: {anuncioColaborador?.nome}
                </h3>
                <h3>
                  Se você tem certeza que deseja criar um novo anúncio para esse
                  colaborador no Programa de Indicação, vá até o anúncio do
                  programa de indicação atual e remova o colaborador do mesmo e
                  volte aqui para tentar novamente.
                </h3>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={showCartaoColaborador}
        toggle={() => setShowCartaoColaborador(!showCartaoColaborador)}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            Programa de Indicação - Cartão Colaborador
            <br />
            <small className="text-muted">
              Anúncio:{" "}
              {
                // @ts-ignore
                anuncio?.nome
              }
            </small>
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowCartaoColaborador(!showCartaoColaborador)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Container className="mb-3">
          {usuarioAfiliadoId && (
            <span ref={cartaoColaboradorRef}>
              <CartaoColaborador
                colaborador={usuarioAfiliadoId}
                setLink={setLink}
              />
            </span>
          )}
          <Row className="mt-5 justify-content-center">
            <Col sm="12" md="6" lg="4" className="text-center">
              <div>
                <Button
                  className="ml-auto"
                  color="primary"
                  type="button"
                  onClick={handlePrint}
                >
                  <i className="fas fa-print mr-2" style={{ fontSize: 20 }}></i>
                  Imprimir
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={show}
        toggle={onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            Programa de Indicação
            <br />
            <small className="text-muted">
              Anúncio:{" "}
              {
                // @ts-ignore
                anuncio?.nome
              }
            </small>
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-3">
          {usuarioAfiliadoId?.id ? (
            <>
              <ListGroup className="list" flush>
                <h4>Colaborador selecionado</h4>
                <ListGroupItem
                  className="list-group-item-action px-0"
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <div className="col-auto">
                    <Button
                      className="btn-sm"
                      color="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        // @ts-ignore
                        handleDelete(usuarioAfiliadoId);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </div>
                  <Row style={{ flex: 1 }} className="align-items-center">
                    <div className="col d-flex">
                      <h4 className="mb-0">
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          {usuarioAfiliadoId?.name}
                        </a>
                      </h4>
                      <br />
                    </div>
                  </Row>
                </ListGroupItem>
              </ListGroup>

              <ListGroup className="list mt-5" flush>
                {sitesEmpresa ? (
                  <>
                    <h4>Site de Programa de Indicação</h4>
                    <ListGroupItem
                      className="list-group-item-action px-0"
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                      }}
                      key={sitesEmpresa?.id}
                    >
                      <div className="col-auto">
                        <i className="fas fa-link"></i>
                      </div>
                      <Row style={{ flex: 1 }} className="align-items-center">
                        <div className="col">
                          {/* <small>{site?.nome}</small> */}
                          <h4 className="mb-0">
                            <a
                              href="#"
                              onClick={(e) => e.preventDefault()}
                              title={`${sitesEmpresa?.path}`}
                            >
                              {sitesEmpresa?.nome}
                            </a>
                          </h4>
                        </div>
                      </Row>
                      <div className="col-auto">
                        <CopyToClipboard
                          text={`${sitesEmpresa?.path}?anuncio_id=${anuncio?.id}&criador_id=${usuarioAfiliadoId?.id}&token=${apiAccess.token}`}
                          onCopy={() => notify("success", "Copiado")}
                        >
                          <Button
                            className="btn-sm"
                            color="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              //   handleDelete(usuarioAfiliadoId);
                            }}
                          >
                            Copiar link
                          </Button>
                        </CopyToClipboard>
                        <Button
                          className="btn-sm"
                          color="primary"
                          onClick={(e) => {
                            setShowCartaoColaborador(true);
                          }}
                        >
                          <i
                            className="fas fa-address-card"
                            style={{ fontSize: 20 }}
                          ></i>
                        </Button>
                      </div>
                    </ListGroupItem>
                  </>
                ) : (
                  <p>Ainda não tem site para Programa de Indicação.</p>
                )}
              </ListGroup>
            </>
          ) : (
            <>
              <div>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Adicionar colaborador
                  </label>
                  <Select2
                    maximumInputLength={10}
                    className="form-control"
                    onSelect={(e: any) => setColaboradorId(e.target.value)}
                    options={clienteOptions()}
                    value={colaboradorId}
                  />
                </FormGroup>
                <div>
                  <Button
                    color="primary"
                    type="button"
                    outline
                    onClick={() =>
                      colaboradorId && verifyUsuarioAfiliado(colaboradorId)
                    }
                    className="btn-icon btn-3"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Adicionar</span>
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ProgramaDeIndicacao;
