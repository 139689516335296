
import React, { useState, useRef, useEffect, useContext } from 'react';
import { usePersistedState } from '../../../hooks'
import AuthContext from "../../../contexts/Auth";
import EmpresaContext from '../../../contexts/Empresa'
import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";
import Table from './Table'
import Filters from './Filters'
import ClonarPublicacoes from './Clonar'

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";

import SimpleHeader from '../../../components/Headers/SimpleHeader'

export default ({ ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const { hasPermission, hasRole } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = usePersistedState('page', 1)
    const [currentPerPage, setCurrentPerPage] = usePersistedState('perPage', 10)
    const [publicacoes, setPublicacoes] = useState([])
    const [alert, setAlert] = useState(null)
    const [pageProperties, setPageProperties] = useState({
        total: "0",
        perPage: currentPage,
        page: currentPerPage,
        lastPage: 1,
        loading: false,
        sort: new Map([])
    })
    const [lastSearch, setLastSearch] = useState({})
    const notificationAlert = useRef();
    const [showClonagem, setShowClonagem] = useState(false)

    const notify = (type, msg) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (notificationAlert) notificationAlert.current.notificationAlert(options);
    };

    const onNewClicked = (e) => {
        e.preventDefault();
        if (props.anuncio) {
            props.history.push('/admin/publicacoes/new', { toAnuncio: props.anuncio })
        } else {
            props.history.push('/admin/publicacoes/new')
        }
    }

    async function updatePageProperties(response) {
        const { total, perPage, page, lastPage } = await response.data
        setPageProperties({
            ...pageProperties,
            total,
            perPage,
            page,
            lastPage,
            loading: false,
        })
    }

    async function loadPublicacoes(page = 1,
        limit = 10,
        sortField = "(data_postagem, created_at)",
        sortOrder = "desc",
        filters = lastSearch) {
        setCurrentPage(page)
        setCurrentPerPage(limit)
        var anuncio = props.anuncio
        try {
            setPageProperties({
                ...pageProperties,
                loading: true
            })
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/publicacoes`, {
                params: {
                    page,
                    limit,
                    sortField,
                    sortOrder,
                    ...filters,
                    anuncio
                }
            })
            setPublicacoes(response.data)
            await updatePageProperties(response)
        } catch (err) {
            notify('danger', 'Houve um problema ao carregar as publicações.');
        }
    }

    const handleTableChange = async (type, { page, sizePerPage, sortField, sortOrder, ...props }) => {
        try {
            if (type == 'sort') {
                const value = pageProperties.sort.get(sortField)
                const newOrder = (value || 'asc') == 'desc' ? 'asc' : 'desc'
                const map = pageProperties.sort
                map.set(sortField, newOrder)
                sortOrder = newOrder
            }
            await loadPublicacoes(page == 0 ? 1 : page, sizePerPage, sortField, sortOrder)
        } catch (error) {
            console.error(error)
            notify('danger', 'Houve um problema ao carregar as publicações.');
        }
    }

    function handleClonarPublicacoes() {
        setShowClonagem(true)
    }

    function onCloseClonagem() {
        setShowClonagem(false)
    }

    function onClonagemRealizada() {
        loadPublicacoes()
    }

    const AdditionalOptions = ({
        hasPermission,
        handleClonarPublicacoes }) => {

        const [menu, setMenu] = useState([])

        useEffect(() => {
            createDropMenus()
        }, [])

        function createDropMenus() {
            const menus = []
            var anuncio = props.anuncio;
            if (hasPermission('clonar-publicacoes') && !anuncio) {
                menus.push(<li key={'clonar-publicacoes'}>
                    <DropdownItem href="#pablo" onClick={handleClonarPublicacoes}>
                        <i className="fas fa-copy"></i>
                        {'Clonar Publicações'}
                    </DropdownItem>
                </li>)
            }
            setMenu(menus)
        }

        return (<div>
            {
                menu.length > 0 &&
                <UncontrolledDropdown group size='sm' direction='down'>
                    <DropdownToggle color="link">
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        {menu}
                    </DropdownMenu>
                </UncontrolledDropdown>
            }

        </div>
        )
    }



    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <ClonarPublicacoes
                show={showClonagem}
                onClose={onCloseClonagem}
                onSuccess={onClonagemRealizada}
                lastSearch={lastSearch}
                notify={notify}
            />           
            <SimpleHeader name="Listagem de publicações" parentName="Publicações" {...props} />
            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 100,
                                }}>
                                <Filters
                                    title={<h1>Publicações</h1>}
                                    data={publicacoes}
                                    anuncio={props.anuncio}
                                    load={(filters, clearPagination) => {
                                        setLastSearch(filters)
                                        loadPublicacoes(
                                            clearPagination ? 1 : currentPage,
                                            clearPagination ? 10 : currentPerPage,
                                            "(data_postagem, created_at)",
                                            "desc",
                                            filters)
                                    }}
                                />
                            </CardHeader>
                            <CardBody>
                                <Row>

                                    {/* <Col xs='12'>
                                        <span >
                                            <Button
                                                id="tooltip0983764373524743"
                                                color="primary"
                                                type="button"
                                                onClick={e => onNewClicked(e)}
                                                size="sm">
                                                <span className="btn-inner--icon">
                                                    <i className="ni ni-fat-add" />
                                                </span>
                                                Nova Publicação
                                                </Button>
                                        </span>
                                    </Col> */}

                                    <Col>
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                            justifyContent: 'space-between',
                                            padding: 4
                                        }}>
                                            {
                                                <Button
                                                    id="tooltip0983764373524743"
                                                    disabled={empresaSelecionada?.id == 0 ? true : false}
                                                    color="primary"
                                                    type="button"
                                                    onClick={e => onNewClicked(e)}
                                                    size="sm">
                                                    <span className="btn-inner--icon">
                                                        <i className="ni ni-fat-add" />
                                                    </span>
                                                    Nova Publicação
                                                </Button>

                                            }
                                            {(() => {
                                                if (empresaSelecionada?.id == 0) {
                                                    const a =
                                                        <>
                                                            <UncontrolledTooltip
                                                                delay={0}
                                                                placement="bottom"
                                                                target="tooltip0983764373524743">
                                                                Primeiro selecione uma empresa...
                                                            </UncontrolledTooltip>
                                                        </>
                                                    return a
                                                }
                                            })()}
                                            <AdditionalOptions
                                                hasPermission={hasPermission}
                                                publicacoes={publicacoes}
                                                handleClonarPublicacoes={handleClonarPublicacoes}
                                            />

                                        </div>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col>
                                        <Table
                                            publicacoes={publicacoes ? publicacoes.data : []}
                                            notify={notify}
                                            onTableChange={handleTableChange}
                                            history={props.history}
                                            anuncio={props.anuncio}
                                            pageProperties={pageProperties}
                                            loading={loading}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
