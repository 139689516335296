import React from 'react';
import api from "../../../services/api";

import {
    Button,
    Row,
    Col,
    Modal,
} from "reactstrap";


class Show extends React.Component {

    constructor(props) {
        super(props)
        if (props.handleMarcacoesUpdated) {
            this.setState({
                ...this.state,
                handleMarcacoesUpdated: props.handleMarcacoesUpdated
            })
        }
        this.aqui = props.handleMarcacoesUpdated
        this.loadMarcacoes = this.loadMarcacoes.bind(this)
    }

    state = {
        ...this.state,
        defaultModal: false,
        marcacoes: [],
        marcacoesAdicionadas: [],
    };

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    //Exibe o modal
    showModal() {
        this.toggleModal('defaultModal')
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            //Se for para exibir o modal
            this.setState({
                defaultModal: true
            })
        }

        if (nextProps.marcacoesAdicionadas) {
            //Se for para atualizar as marcacoes adicionadas
            const marcacoesAdicionadas = nextProps.marcacoesAdicionadas
            this.setState({
                ...this.state,
                marcacoesAdicionadas
            });
            this.setMarcacoesDisponiveis();
        }
    }

    componentDidMount() {
        this.loadMarcacoes()
    }

    async loadMarcacoes() {
        try {
            const json = localStorage.getItem('@playnee-empresa')
            const empresaSelecionada = JSON.parse(json)
            const response = await api.get('/marketing/marcacoes/' + empresaSelecionada?.id);
            const marcacoes = response.data
            //Carrega as marcacoes adicionadas em cima das marcacoes padrões
            const marcacoesAdicionadas = []
            marcacoes.map(item => {
                if (item.padrao == 'S') {
                    marcacoesAdicionadas.push(item.marcacao)
                }
            })

            await marcacoes.map(item => {
                this.setState({
                    ...this.state,
                    [`marcacao_${item.id}`]: {
                        item,
                        selected: item.padrao == 'S',
                    }
                })
            });
            this.setState({
                ...this.state,
                marcacoes,
                marcacoesAdicionadas
            })
            //this.setMarcacoesDisponiveis();
        } catch (err) {
            console.error(err)
        }
    }

    setMarcacoesDisponiveis() {
        const marcacoes = this.state.marcacoes;
        const marcacoesAdicionadas = this.state.marcacoesAdicionadas;
        let marcacoesDisponiveis = marcacoes.filter((elem, i, marcacoes) => {
            if (!marcacoesAdicionadas.includes(elem)) {
                return elem
            }
        })
        this.setState({
            ...this.state,
            marcacoesDisponiveis,
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const checked = target.checked;
        target.checked = checked

        let marcacoesAdicionadas = this.state.marcacoesAdicionadas

        //Add/Remove da lista de marcacoes adicionadas...
        if (checked) {
            marcacoesAdicionadas.push(value)
        } else {
            marcacoesAdicionadas = marcacoesAdicionadas.filter(item => (item != value))
        }

        console.log(marcacoesAdicionadas)

        //Atualiza o estado da marcacao
        this.setState({
            ...this.state,
            [target.id]: {
                selected: checked
            },
            marcacoesAdicionadas,
        })
    }

    handleAddMarcacaoes() {
        this.props.onMarcacaoSelected(this.state.marcacoesAdicionadas)
        //Hidden modal
        this.toggleModal("defaultModal")
    }

    marcacoesAdicionadas() {
        return this.state.marcacoesAdicionadas
    }

    render() {
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.toggleModal("defaultModal")}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Marcações
                </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("defaultModal")}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row >
                        <Col>
                            {
                                //Lista os campos das marcaçoes...
                                this.state.marcacoes.map((marcacao, key) => (
                                    <>
                                        <div key={key} className="custom-control custom-checkbox custom-control-inline">
                                            <input
                                                className="custom-control-input"
                                                id={`marcacao_${marcacao.id}`}
                                                type="checkbox"
                                                value={marcacao.marcacao}
                                                onChange={event => this.handleInputChange(event)}
                                                checked={this.state[`marcacao_${marcacao.id}`].selected}

                                            />
                                            <label className="custom-control-label" htmlFor={`marcacao_${marcacao.id}`}>
                                                {marcacao.marcacao}
                                            </label>
                                        </div>
                                    </>
                                ))
                            }
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        color="primary"
                        onClick={this.handleAddMarcacaoes.bind(this)}
                        type="button">
                        Adicionar marcações
                </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("defaultModal")}
                    >
                        Fechar
                </Button>
                </div>
            </Modal>
        )
    }
}

export default Show;