import React, { useState, useRef, useEffect, useContext } from "react";
//@ts-ignore
import classnames from "classnames";
import api from "../../../../../services/api";
import AuthContext from "../../../../../contexts/Auth";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";


import MotivosEncerramentos from "./MotivosEncerramentos";
import Estagios from "./Estagios";
import Funis from "./Funis";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import { HeaderContainer } from "./styles";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Nav,
  Row,
  Col,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import EmpresaContext from "../../../../../contexts/Empresa";

// empresaId,
//   history,
//   hiddeTabs = {},
//   externaTabActive,
//   minhaEmpresa,
//   ...props



const ConfiguracoesVendas: React.FC<any> = ({ hiddeTabs, history, ...props }) => {

  const [empresa, setEmpresa] = useState({});
  const [tabActive, setTabActive] = useState("motivosEncerramentos");
  const [alert, setAlert] = useState(null);
  const notificationAlert = useRef<any>();
  const { empresaSelecionada } = useContext<any>(EmpresaContext)
  const { hasPermission, hasRole } = useContext(AuthContext);

  var externaTabActive = props.location.state?.externaTabActive ? props.location.state.externaTabActive : null;

  useEffect(() => {
    if (externaTabActive && externaTabActive.length > 0) {
      setTabActive(externaTabActive);
    }
  }, [externaTabActive]);

  // async function loadEmpresa(empresaId:number) {
  //   try {
  //     const response = await api.get(`/common/empresas/${empresaId}`);
  //     setEmpresa(response?.data);
  //   } catch (error) {
  //     console.log(error);
  //     notify("danger", "Não foi possível carregar empresa");
  //   }
  // }

  const notify = (type:any, msg:string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text" style={{ zIndex: 100 }}>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    if (notificationAlert.current)
      notificationAlert.current.notificationAlert(options);
  };

  function onEmpresaChange(empresa:any) {
    setEmpresa(empresa ? empresa : {});
  }

  const CustomNavItem = ({ children, disabled, name }:any) => {
    return (
      <>
          <NavItem>
            <NavLink
              href="#"
              role="tab"
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabActive === name,
              })}
              onClick={() => setTabActive(name)}
              disabled={disabled}
            >
              {children}
            </NavLink>
          </NavItem>
        
      </>
    );
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Configurações de vendas" parentName="Minha empresa" />
      <div className="container-fluid mt--6">
        <Card>
          <CardHeader>
            <HeaderContainer>
                <h1>Configurações de vendas</h1>
            </HeaderContainer>
          </CardHeader>
          <CardBody outline body>
            <Row>
              <Col xs={12} lg={12} md={12} sm={12}>
                <div className="nav-wrapper">
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    pills
                    role="tablist"
                  >
                    <CustomNavItem
                      name="motivosEncerramentos"
                      disabled={!empresaSelecionada?.id}
                    >
                      <>
                        <i className="fas fa-bookmark mr-2"></i>
                        Motivos de checagem
                      </>
                    </CustomNavItem>
                    <CustomNavItem name="funis" disabled={!empresaSelecionada?.id}>
                      <>
                        <i className="fas fa-filter mr-2"></i>
                        Funis
                      </>
                    </CustomNavItem>
                    {/* <CustomNavItem name="estagios" disabled={!empresaSelecionada?.id}>
                      <>
                        <i className="fas fa-long-arrow-alt-up mr-2"></i>
                        Estagios
                      </>
                    </CustomNavItem> */}
                  </Nav>
                </div>
                <hr className="mt-0" />
                <div>
                  <TabContent activeTab={tabActive}>
                    <TabPane tabId="motivosEncerramentos">
                      {empresaSelecionada?.id && (
                        <MotivosEncerramentos
                          {...props}
                          history={history}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>
                    {/* <TabPane tabId="estagios">
                      {empresaSelecionada?.id && (
                        <Estagios
                          {...props}
                          history={history}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane> */}

                    <TabPane tabId="funis">
                      {empresaSelecionada?.id && (
                        <Funis
                          {...props}
                          history={history}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
export default ConfiguracoesVendas;
