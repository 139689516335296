import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import EmpresaContext from "../../../contexts/Empresa";
import { AxiosResponse } from 'axios';
import api from "../../../services/api";
//@ts-ignore
import NotificationAlert from "react-notification-alert";


import { GrupoEstrategico } from '../../../entities/Mentoring';
import { User } from '../../../entities/Security';
import Usuarios from "../../../components/timeLines/Pdca/Estrategias/Item/Avatares";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Collapse,
    ListGroup,
    ListGroupItem
} from "reactstrap";

import Filters from "./Filters";
// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader'
import moment from "moment";
import Pagination from '../../../components/Pagination'

type PropsAny = {
    eventos: Array<any>
}

const TimelineEventos: React.FC = ({ ...props }) => {

    const history = useHistory()

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [pageProperties, setPageProperties] = useState({
        total: "0",
        perPage: 10,
        page: 1,
        lastPage: 1,
        loading: false,
        data: new Array<any>(),
        sort: new Map([])
    })
    const [lastSearch, setLastSearch] = useState({})
    const notificationAlert = useRef<NotificationAlert>(null);

    const [isClearFilterAction, setIsClearFilterAction] = useState(false)
    const [nomeEvento, setNomeEvento] = useState('')
    const [situacao, setSituacao] = useState('todos')

    const [eventos, setEventos] = useState([])


    useEffect(() => {
        console.log('useEffect Padrão')
        loadEventos(pageProperties)
    }, [])

    useEffect(() => {
        if (!isClearFilterAction) {
            loadEventos({ page: 1, limit: 10 })
        }
        setIsClearFilterAction(false);
    }, [situacao])

    function notify(type: string, msg: string) {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (notificationAlert) notificationAlert?.current?.notificationAlert(options);
    };

    function handlerExibirEvento(e: React.MouseEvent, evento: any) {
        e.preventDefault();
        if (evento.tipo_evento) {
            if (evento.tipo_evento == 'acao') {
                console.log('HANDLER EXIBIR EVENTO TIPO AÇÃO')
                history.push(`/admin/pdcas/${evento.action_id}/edit`)
            } else {
                if (evento.tipo_evento == 'nota') {
                    console.log('HANDLER EXIBIR EVENTO TIPO AÇÃO')
                    history.push(`/admin/notas/${evento.action_id}/edit`)
                } else {
                    console.log('HANDLER EXIBIR EVENTO')
                    console.log('EVENTO TIPO NÃO IDENTIFICADO')
                }
            }
        } else {
            console.log('HANDLER EXIBIR EVENTO')
            console.log('EVENTO NULL')
        }
    }


    async function loadEventos({ page = 1, limit = 10 }) {
        try {
            const response = await api.get(`mentoring/empresas/${empresaSelecionada?.id}/timelineeventos`,
                {
                    params: {
                        nome: nomeEvento,
                        situacao: situacao || '',
                        page,
                        limit
                    }
                })
            setPageProperties(await response.data)
            setEventos(await response.data.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos carregar os Eventos')
        }
    }

    const EventoContainer: React.FC<PropsAny> = ({ eventos }) => {
        const [isOpen, setIsOpen] = useState(false)
        return (
            <div className="timeline-content" style={{ maxWidth: 'inherit' }}>
                <ListGroup className="list" flush>
                    {
                        // @ts-ignore
                        eventos.map((item, key) => (
                            <>
                                <ListGroupItem
                                    className="px-0"
                                >
                                    <Row className="align-items-center">
                                        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: 40 }}>
                                            <span className={`timeline-step ${item.tipo_evento ? item.tipo_evento == 'acao' ? 'badge-success' : 'badge-primary' : 'badge-primary'}`} style={{ zIndex: 0 }}>
                                                <i className={`${item.tipo_evento ? item.tipo_evento == 'acao' ? 'fas fa-check' : `${item.icone}` : 'fas fa-check'}`} />
                                            </span>
                                        </Col>

                                        <Col>
                                            <h4 className="mb-0">
                                                <a
                                                    title={item.descricao}
                                                    href="#"
                                                    onClick={e => e.preventDefault()}>
                                                    {item.nome}
                                                </a>
                                            </h4>
                                            <span style={{ fontSize: '130%', color: '#000000' }}>● </span>
                                            <small
                                                style={{ textTransform: 'capitalize' }}
                                                // title={acao.o_que}
                                                title={item.tipo_evento ? item.tipo_evento == 'acao' ? 'Estratégia' : 'Tipo da Nota' : ''}
                                            >
                                                {item.descricao}
                                            </small>
                                        </Col>
                                        <Col>
                                            <h5
                                                title='Data de Execução do Evento'
                                            >
                                                Quando: {
                                                    moment(item.data_quando).format('L')
                                                }
                                            </h5>
                                        </Col>
                                        <Col>
                                            <Usuarios
                                                // users={item.quem || new Array<User>()}
                                                users={item.users || new Array<User>()}
                                            />
                                        </Col>
                                        <Col className="col-auto">
                                            <Button
                                                color="link"
                                                type="button"
                                                onClick={(e) => handlerExibirEvento(e, item)}
                                            >
                                                exibir
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </>
                        ))
                    }
                </ListGroup>

                {
                    eventos && eventos.length > 0 &&
                    <div style={{ marginTop: 20 }}>
                        <Pagination
                            pageProperties={pageProperties}
                            // @ts-ignore
                            load={(pageProperties) => {
                                loadEventos(pageProperties)
                            }}
                        />
                    </div>
                }
            </div >
        )
    }

    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <SimpleHeader name="Listagem de Eventos" parentName="Eventos" {...props} />
            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                }}>
                                <Filters
                                    title='Timeline de Eventos'
                                    load={() => loadEventos(pageProperties)}
                                    notify={notify}
                                    situacao={situacao}
                                    setSituacao={setSituacao}
                                    nomeEvento={nomeEvento}
                                    setNomeEvento={setNomeEvento}
                                    setIsClearFilterAction={setIsClearFilterAction}
                                    setPageProperties={setPageProperties}
                                />
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs='12'>

                                    </Col>
                                    <Col>

                                        <Container
                                            className='timeline timeline-one-side'
                                            data-timeline-axis-style="dashed"
                                            data-timeline-content="axis"
                                            style={{ margin: 0 }}
                                        >

                                            <div className="timeline-block">
                                                <EventoContainer eventos={eventos} />
                                            </div>

                                        </Container>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default TimelineEventos