import React, { useState, useContext, useEffect, useRef } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from '../../../../services/api'
import { AxiosResponse } from 'axios';
import {
  useParams,
  useHistory,
} from "react-router-dom";
import { Meta } from '../../../../entities/Marketing';
import { } from './styles';
//@ts-ignore
import Select2 from "react-select2-wrapper";
import ReactDatetime from "react-datetime";
import ReactQuill from "react-quill";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
//@ts-ignore
import CurrencyInput from 'react-currency-input';
import BootstrapTable from "react-bootstrap-table-next";
//@ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import PeriodosConfigs from './Periodos';

import TableProduto from './TableProduto';
import TableColaborador from './TableColaborador';

import moment from 'moment';

import {
  Spinner,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardHeader,
  Collapse,
  Button,
  Modal,
  UncontrolledPopover,
  PopoverBody
} from "reactstrap";
import Table from '../../../../components/Table'

import { string } from 'prop-types';
import { prototype } from 'module';

export type FormError = {
  mes?: number,
  ano?: number,
  valor?: number,
  unidade?: string,
  tipo_produto_id?: number,
  produtos?: string,
  colaboradores?: string,
  periodos_colaboradores?: string,
}

const Form: React.FC = ({ }) => {

  const { id: meta_id } = useParams() as { id?: number }
  const history = useHistory()
  const { empresaSelecionada } = useContext(EmpresaContext)

  const [meta, setMeta] = useState({} as Meta)

  const [mes, setMes] = useState<number | null>(null)
  const [ano, setAno] = useState<number | null>(null)
  const [valor, setValor] = useState<number | null>(null)
  const [unidade, setUnidade] = useState<any>(null)
  const [tipoProduto, setTipoProduto] = useState<any>(null)
  const [tipoProdutoOld, setTipoProdutoOld] = useState<any>(null)

  const [meses, setMeses] = useState([])
  const [tiposProdutosList, setTipoProdutosList] = useState([])

  const [produtosList, setProdutosList] = useState([]) //Lista de possíveis produtos a serem adicionados
  const [produtoSelected, setProdutoSelected] = useState(null) //produto selecionado para ser adicionado
  const [produtoRemoved, setProdutoRemoved] = useState(null) //produto selecionado para ser removido
  const [produtos, setProdutos] = useState([]) //Lista de produtos já adicionados

  const [colaboradoresList, setColaboradoresList] = useState([]) //Lista de possíveis colaboradores a serem adicionados
  const [colaboradorSelected, setColaboradorSelected] = useState(null) //colaborador selecionado para ser adicionado
  const [colaboradorRemoved, setColaboradorRemoved] = useState(null) //colaborador selecionado para ser removido
  const [colaboradorEditPeriodo, setColaboradorEditPeriodo] = useState(null) //colaborador selecionado para ser editado as metas por periodo
  const [colaboradores, setColaboradores] = useState([]) //Lista de colaboradores já adicionados

  const [somaValorProdutos, setSomaValorProdutos] = useState(0);
  const [somaValorColaboradores, setSomaValorColaboradores] = useState(0);

  const [showPeriodosModal, setShowPeriodosModal] = useState(false)

  const [alert, setAlert] = useState<any>(undefined)
  const [erros, setErros] = useState<FormError>({} as FormError)
  const [saving, setSaving] = useState(false)

  const notificationAlert = useRef<NotificationAlert>(null)

  const [dataBase, setDataBase] = useState(new Date())
  const y = dataBase.getFullYear(), m = dataBase.getMonth();
  const [firstDay, setFirstDay] = useState(new Date(y, m, 1))
  const [lastDay, setLastDay] = useState(new Date(y, m + 1, 0))

  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: false
  })

  const [state, setState] = useState({
    openedCollapses: []
  })

  useEffect(() => {
    //objeto mes se refere ao numero do mes selecionado (ex. janeiro = 01, dezembro = 12)
    if ((mes && mes > 0) && (ano && ano > 0)) {
      setDataBase(new Date(ano, mes - 1, 1))
      setFirstDay(new Date(ano, mes - 1, 1))
      setLastDay(new Date(ano, mes, 0))

    } else {
      //notify('danger', 'Período Inválido. Verifique se Mês e Ano foram informado!')
      console.log('Período Inválido. Verifique se Mês e Ano foram informado!')
    }

  }, [mes, ano])


  useEffect(() => {
    console.log('DATA BASE ALTERADA')
    console.log({ dataBase })
  }, [dataBase])


  function acoesFormatterProduto(cell: any, row: any) {
    return (
      <>
        < div className="col-auto" >
          <Button
            className="btn-sm"
            color="danger"
            onClick={(e) => {
              e.preventDefault()
              setProdutoRemoved(row)
            }}
          >
            {/* @ts-ignore */}
            <i class="fas fa-trash"></i>
          </Button>
        </div >
      </>
    )
  }

  //@ts-ignore
  function beforeSaveCellProduto(oldValue, newValue, row, column, done) {
    setTimeout(() => {
      if (unidade) {
        if (unidade == 'M') {
          //UNIDADE MONETÁRIA
          var value = parseFloat(newValue);
          if (value == newValue) {
            if (window.confirm('Está de acordo com essa alteração?')) {
              recalcularParcialProduto(oldValue, newValue);
              done(true);
            } else {
              done(false);
            }
          } else {
            notify('danger', 'Valor não condiz com a Unidade MONETÁRIA!')
            done(false);
          }

        } else {
          //UNIDADE QUANTIDADE
          var value = parseInt(newValue);

          if (value == newValue) {
            if (window.confirm('Está de acordo com essa alteração?')) {
              recalcularParcialProduto(oldValue, newValue);
              done(true);
            } else {
              done(false);
            }
          } else {
            notify('danger', 'Valor não condiz com a Unidade QUANTIDADE! Informe um valor inteiro.')
            done(false);
          }
        }

      } else {
        //UNIDADE DESCONHECIDA
        done(false);
      }

    }, 0);
    return { async: true };
  }


  //@ts-ignore
  function beforeSaveCellColaborador(oldValue, newValue, row, column, done) {
    setTimeout(() => {
      if (unidade) {
        if (unidade == 'M') {
          //UNIDADE MONETÁRIA
          var value = parseFloat(newValue);

          if (value == newValue) {
            if (window.confirm('Está de acordo com essa alteração?')) {
              recalcularParcialColaborador(oldValue, newValue);
              done(true);
            } else {
              done(false);
            }
          } else {
            notify('danger', 'Valor não condiz com a Unidade MONETÁRIA!')
            done(false);
          }

        } else {
          //UNIDADE QUANTIDADE
          var value = parseInt(newValue);
          if (value == newValue) {
            if (window.confirm('Está de acordo com essa alteração?')) {
              recalcularParcialColaborador(oldValue, newValue);
              done(true);
            } else {
              done(false);
            }
          } else {
            notify('danger', 'Valor não condiz com a Unidade QUANTIDADE! Informe um valor inteiro.')
            done(false);
          }
        }

      } else {
        //UNIDADE DESCONHECIDA
        done(false);
      }

    }, 0);
    return { async: true };
  }

  function handleAddProduto() {
    console.log('###### ADD PRODUTO ####')
    console.log({ produtoSelected })
    if (produtoSelected) {
      //@ts-ignore
      var prod = produtosList.find(e => e.id == produtoSelected)
      console.log({ prod })
      if (prod) {
        //@ts-ignore
        prod.meta_id = meta ? meta.id : 0
        //@ts-ignore
        prod.produto_id = prod ? prod.id : 0
        //@ts-ignore
        prod.valor = 0
        //@ts-ignore
        prod.produto = {
          //@ts-ignore          
          id: prod ? prod.id : 0,
          //@ts-ignore          
          nome: prod ? prod.nome : '',
          //@ts-ignore          
          tipo_produto_id: prod ? prod.tipo_produto_id : 0,
        }

        if (produtos && produtos.length >= 0) {
          produtos.push(prod)
        } else {
          //@ts-ignore
          var prods = [];
          prods.push(prod)
          //@ts-ignore
          setProdutos(prods);
        }
        setProdutoSelected(null)
        //@ts-ignore
        loadProdutos(prod.tipo_produto_id);
      } else {
        console.log('PROD NÂO ENCONTRADO')
      }
    }

  }

  function handleAddAllProdutos() {
    if (produtosList && produtosList.length > 0) {

      //@ts-ignore
      let prods = [];
      for (const prod of produtosList) {

        console.log({ prod })
        if (prod) {
          //@ts-ignore
          prod.meta_id = meta ? meta.id : 0
          //@ts-ignore
          prod.produto_id = prod ? prod.id : 0
          //@ts-ignore
          prod.valor = 0
          //@ts-ignore
          prod.produto = {
            //@ts-ignore          
            id: prod ? prod.id : 0,
            //@ts-ignore          
            nome: prod ? prod.nome : '',
            //@ts-ignore          
            tipo_produto_id: prod ? prod.tipo_produto_id : 0,
          }

          if (produtos && produtos.length >= 0) {
            produtos.push(prod)
          } else {
            prods.push(prod)
          }

        } else {
          console.log('PROD NÂO ENCONTRADO')
        }
      }

      if (!(produtos && produtos.length && produtos.length >= 0)) {
        //@ts-ignore
        setProdutos(prods)
      }

      setProdutoSelected(null)
      //@ts-ignore
      loadProdutos(tipoProduto);

    } else {
      notify('warning', 'Lista de Produtos Vazia!')
    }

  }

  function handleAddColaborador() {
    console.log('###### ADD COLABORADOR####')
    console.log({ colaboradorSelected })

    if (colaboradorSelected) {
      //@ts-ignore
      var colab = colaboradoresList.find(e => e.id == colaboradorSelected)
      console.log({ colab })

      if (colab) {
        console.log('###### ADD COLAB####')

        //@ts-ignore
        colab.meta_id = meta ? meta.id : 0
        //@ts-ignore
        colab.user_id = colab ? colab.id : 0
        //@ts-ignore
        colab.colaborador = {
          //@ts-ignore          
          id: colab ? colab.id : 0,
          //@ts-ignore          
          name: colab ? colab.name : '',
        }
        //@ts-ignore
        colab.valor = 0
        //@ts-ignore
        colab.periodos = [{
          meta_id: meta ? meta.id : 0,
          //@ts-ignore
          user_id: colab ? colab.id : 0,
          //@ts-ignore
          name: colab ? colab.name : '',
          data_inicio: moment(firstDay),
          data_fim: moment(lastDay),
          valor: 0
        }]

        if (colaboradores && colaboradores.length >= 0) {
          console.log('PUSH')
          colaboradores.push(colab)
        } else {
          console.log('SET')
          //@ts-ignore
          setColaboradores([colab]);
        }
        //@ts-ignore
        loadColaboradores();
      } else {
        console.log('COLAB NÂO ENCONTRADO')
      }

    }

  }



  function handleAddAllColaboradores() {
    console.log('###### ADD ALL COLABORADORES ####')

    if (colaboradoresList && colaboradoresList.length > 0) {

      //@ts-ignore
      let colabs = [];

      for (const colab of colaboradoresList) {

        console.log({ colab })
        if (colab) {
          //@ts-ignore
          colab.meta_id = meta ? meta.id : 0
          //@ts-ignore
          colab.user_id = colab ? colab.id : 0
          //@ts-ignore
          colab.colaborador = {
            //@ts-ignore          
            id: colab ? colab.id : 0,
            //@ts-ignore          
            name: colab ? colab.name : '',
          }
          //@ts-ignore
          colab.valor = 0
          //@ts-ignore
          colab.periodos = [{
            meta_id: meta ? meta.id : 0,
            //@ts-ignore
            user_id: colab ? colab.id : 0,
            //@ts-ignore
            name: colab ? colab.name : '',
            data_inicio: moment(firstDay),
            data_fim: moment(lastDay),
            valor: 0
          }]

          if (colaboradores && colaboradores.length >= 0) {
            colaboradores.push(colab)
          } else {
            colabs.push(colab)
          }

        } else {
          console.log('COLAB NÂO ENCONTRADO')
        }

      }

      if (!(colaboradores && colaboradores.length && colaboradores.length >= 0)) {
        //@ts-ignore
        setColaboradores(colabs)
      }

      setColaboradorSelected(null)

      //@ts-ignore
      loadColaboradores();

    } else {
      notify('warning', 'Lista de Colaboradores Vazia!')
    }

  }



  useEffect(() => {
    //@ts-ignore
    if (produtoRemoved && produtoRemoved.id > 0) {
      handleDeleteProduto(produtoRemoved)
    }

  }, [produtoRemoved])

  function handleDeleteProduto(produto: any) {
    if (produto) {
      var oldValue = produto.valor;
      //@ts-ignore
      setProdutos(produtos.filter(e => produto.id != e.id))
      recalcularParcialProduto(oldValue, 0);
    }
  }


  useEffect(() => {
    //@ts-ignore
    if (colaboradorRemoved && colaboradorRemoved.id > 0) {
      handleDeleteColaborador(colaboradorRemoved)
    }

  }, [colaboradorRemoved])

  function handleDeleteColaborador(colaborador: any) {
    if (colaborador) {
      var oldValue = colaborador.valor;
      //@ts-ignore
      setColaboradores(colaboradores.filter(e => colaborador.id != e.id))
      recalcularParcialColaborador(oldValue, 0);
    }
  }

  function removeColaboradorFormatter(cell: any, row: any) {
    return (
      <>
        < div className="col-auto" >
          <Button
            className="btn-sm"
            color="danger"
            onClick={(e) => {
              e.preventDefault()
              setColaboradorRemoved(row)
            }}
            title='Excluir'
          >
            {/* @ts-ignore */}
            <i class="fas fa-trash"></i>
          </Button>
        </div >
      </>
    )
  }

  useEffect(() => {
    //@ts-ignore
    if (colaboradorEditPeriodo && colaboradorEditPeriodo.id > 0) {
      handleEditPeriodoColaborador(colaboradorEditPeriodo)
    }

  }, [colaboradorEditPeriodo])

  function handleEditPeriodoColaborador(colaborador: any) {
    if (colaborador) {
      setShowPeriodosModal(true);
    }
  }

  function editPeriodoColaboradorFormatter(cell: any, row: any) {
    return (
      <>
        < div className="col-auto" >
          <Button
            className="btn-sm"
            // @ts-ignore
            color="secondary"
            onClick={(e) => {
              e.preventDefault()
              setColaboradorEditPeriodo(row)
            }}
            title='Editar Metas por Período'
          >
            {/* @ts-ignore */}
            <i class="far fa-calendar-alt"></i> Editar Período
          </Button>
        </div >
      </>
    )
  }

  const [columnsProdutos] = useState([
    {
      dataField: 'acoes',
      formatter: (cell: any, row: any) => acoesFormatterProduto(cell, row),
      editable: false
    },
    {
      dataField: 'produto.nome',
      text: 'Nome',
      sort: true,
      editable: false
    },
    {
      dataField: 'valor',
      text: 'Valor',
      type: 'number',
      validator: (newValue: number, row: any, column: any) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'O campo só aceita números e ponto para separação de centavos em caso de valor monetário.'
          };
        }
        return true;
      }
    }
  ])

  const [columnsColaboradores] = useState([
    {
      dataField: 'remove',
      formatter: (cell: any, row: any) => removeColaboradorFormatter(cell, row),
      editable: false
    },
    {
      dataField: 'colaborador.name',
      text: 'Nome',
      sort: false,
      editable: false
    },
    {
      dataField: 'valor',
      text: 'Valor',
      type: 'number',
      validator: (newValue: number, row: any, column: any) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'O campo só aceita números e ponto para separação de centavos em caso de valor monetário.'
          };
        }
        return true;
      }
    },
    {
      dataField: 'edit_periodo',
      formatter: (cell: any, row: any) => editPeriodoColaboradorFormatter(cell, row),
      editable: false
    }
  ])

  function collapsesToggle(collapse: any) {
    //@ts-ignore
    let openedCollapses = state.openedCollapses;
    //@ts-ignore
    if (openedCollapses.includes(collapse)) {
      setState({
        openedCollapses: []
      });
    } else {
      setState({
        //@ts-ignore
        openedCollapses: [collapse]
      });
    }
  };

  useEffect(() => {
    if (meta_id) loadMeta()
  }, [meta_id])

  useEffect(() => {
    if (unidade && unidade == 'Q') {
      if (valor && valor > 0) {
        //@ts-ignore
        setValor(parseInt(valor));
      }
    }
  }, [unidade])


  function recalcularParcialProduto(oldValue: number, newValue: number) {
    setSomaValorProdutos((somaValorProdutos - oldValue + newValue))
  }

  function recalcularParcialColaborador(oldValue: number, newValue: number) {
    setSomaValorColaboradores((somaValorColaboradores - oldValue + newValue))
  }

  function handleDistruibuirPorProdutos() {
    if (valor && valor > 0) {
      if (unidade) {
        if (window.confirm('Deseja realmente distribuir por igual a Meta para o(s) produto(s) adicionado(s)?')) {
          setSomaValorProdutos(0);

          let soma = 0;
          let divisaoMeta = (valor / produtos.length)

          console.log('########')
          console.log('HANDLE DIST PRODUTO')

          console.log({ divisaoMeta })

          let valorTratadoByUnidade = (unidade == 'Q') ? Math.trunc(divisaoMeta) : parseFloat(divisaoMeta.toFixed(2));

          console.log({ valorTratadoByUnidade })

          for (const produto of produtos) {
            // @ts-ignore
            produto.valor = valorTratadoByUnidade
            // @ts-ignore
            soma += produto.valor;
          }

          console.log({ soma })

          setSomaValorProdutos(soma)

          //@ts-ignore
          setProdutos([...produtos]);

          if (valorTratadoByUnidade != divisaoMeta) {
            (valorTratadoByUnidade < divisaoMeta) ?
              //window.alert(`A distribuição não foi exata, ocasionando saldo positivo. Confira a distruibuição e adicione este saldo a algum(ns) produto(s) manualmente.`)
              notify('warning', `A distribuição não foi exata, ocasionando saldo positivo. Confira a distruibuição e adicione este saldo a algum(ns) produto(s) manualmente.`)
              :
              //window.alert(`A distribuição não foi exata, ocasionando saldo negativo de alguns centavos. Confira a distruibuição e reduza a meta de algum(ns) produto(s) manualmente.`)
              notify('warning', `A distribuição não foi exata, ocasionando saldo negativo de alguns centavos. Confira a distruibuição e reduza a meta de algum(ns) produto(s) manualmente.`)
          }

        }

      } else {
        notify('danger', 'Unidade ainda não Definida')
      }
    } else {
      notify('danger', 'Meta ainda não Definida')
    }
  }


  function handleDistruibuirPorColaboradores() {
    if (valor && valor > 0) {
      if (unidade) {
        if (window.confirm('Deseja realmente distribuir por igual a Meta para o(s) colaborador(es) adicionado(s)?')) {
          setSomaValorColaboradores(0);
          let soma = 0;
          let divisaoMeta = (valor / colaboradores.length)

          console.log('########')
          console.log('HANDLE DIST COLAB')

          console.log({ divisaoMeta })

          let valorTratadoByUnidade = (unidade == 'Q') ? Math.trunc(divisaoMeta) : parseFloat(divisaoMeta.toFixed(2));

          console.log({ valorTratadoByUnidade })

          for (const colaborador of colaboradores) {
            // @ts-ignore
            colaborador.valor = valorTratadoByUnidade
            // @ts-ignore
            soma += colaborador.valor;
          }

          console.log({ soma })

          setSomaValorColaboradores(soma)

          //@ts-ignore
          setColaboradores([...colaboradores]);

          if (valorTratadoByUnidade != divisaoMeta) {
            (valorTratadoByUnidade < divisaoMeta) ?
              notify('warning', `A distribuição não foi exata, ocasionando saldo positivo. Confira a distruibuição e adicione este saldo a algum(ns) colaborador(es) manualmente.`)
              :
              notify('warning', `A distribuição não foi exata, ocasionando saldo negativo de alguns centavos. Confira a distruibuição e reduza a meta de algum(ns) colaborador(es) manualmente.`)
          }

        }

      } else {
        notify('danger', 'Unidade ainda não Definida')
      }
    } else {
      notify('danger', 'Meta ainda não Definida')
    }
  }


  useEffect(() => {
    setMes(meta.mes)
    setAno(meta.ano)
    setUnidade(meta.unidade)
    //@ts-ignore
    if (meta.unidade && meta.unidade == 'Q') {
      if (meta.valor && meta.valor > 0) {
        //@ts-ignore
        setValor(parseInt(meta.valor));
      }
    } else {
      setValor(meta.valor)
    }

    setProdutos(meta.produtos);
    setColaboradores(meta.colaboradores);

    if (meta.produtos && meta.produtos.length > 0) {
      var value = 0;
      var produtos = meta.produtos
      for (const produto of produtos) {
        value += parseFloat(produto.valor)
      }
      setSomaValorProdutos(value)
    }


    if (meta.colaboradores && meta.colaboradores.length > 0) {
      var value = 0;
      var colaboradores = meta.colaboradores
      for (const colaborador of colaboradores) {
        value += parseFloat(colaborador.valor)
      }
      setSomaValorColaboradores(value)
    }

    setTipoProduto(meta.tipo_produto_id)

  }, [meta])

  useEffect(() => {
    if (tipoProduto && tipoProduto > 0) {
      loadProdutos(tipoProduto)
    }
  }, [tipoProduto])


  useEffect(() => {
    if (produtos) {
      if (tipoProduto && tipoProduto > 0) {
        loadProdutos(tipoProduto)
      }
    }
  }, [produtos])


  useEffect(() => {
    if (colaboradores) {
      loadColaboradores()
    }
  }, [colaboradores])


  useEffect(() => {
    (async () => {
      if (meses.length == 0) await loadMeses()
      if (tiposProdutosList.length === 0) loadTiposProdutos()
      if (colaboradoresList.length === 0) loadColaboradores()
    })()
  }, [])

  async function loadMeses() {
    const listMeses = [
      { id: 1, nome: 'Janeiro' },
      { id: 2, nome: 'Fevereiro' },
      { id: 3, nome: 'Março' },
      { id: 4, nome: 'Abril' },
      { id: 5, nome: 'Maio' },
      { id: 6, nome: 'Junho' },
      { id: 7, nome: 'Julho' },
      { id: 8, nome: 'Agosto' },
      { id: 9, nome: 'Setembro' },
      { id: 10, nome: 'Outubro' },
      { id: 11, nome: 'Novembro' },
      { id: 12, nome: 'Dezembro' }];
    //@ts-ignore
    setMeses(listMeses);
  }

  function getAnoAtual() {
    const now = new Date();
    return now.getFullYear();
  }

  async function loadTiposProdutos() {
    try {
      const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/tipos-produtos`)
      setTipoProdutosList(response.data)
    } catch (error) {
      console.log(error)
      notify('error', 'Não foi possível carregar tipos de produtos')
    }
  }

  async function loadColaboradores() {
    try {
      const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/colaboradores`, {
        params: {
          equipes: [-1]
        }
      }
      )
      if (response.data) {

        const data = await response.data

        if (colaboradores && colaboradores.length > 0) {
          //@ts-ignore
          setColaboradoresList(data.filter(e => !colaboradores.find(colab => colab.user_id == e.id)))
        } else {
          setColaboradoresList(data)
        }

      }
    } catch (error) {
      console.log(error)
      notify('danger', 'Não foi possível carregar os colaboradores')
    }
  }

  async function loadProdutos(tipoProduto: any) {
    if (produtos && produtos.length > 0) {
      //@ts-ignore  
      if (tipoProduto && (tipoProduto == produtos[0].produto.tipo_produto_id)) {
        try {
          const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/produtos/`, {
            params: {
              situacao: 'A',
              limit: 1000,
              tipoProduto
            }
          })
          const data = await response.data.data

          if (produtos && produtos.length > 0) {
            //@ts-ignore
            setProdutosList(data.filter(e => !produtos.find(prod => prod.produto_id == e.id)))
          } else {
            setProdutosList(data)
          }

          setTipoProdutoOld(tipoProduto);

        } catch (error) {
          console.log(error)
          notify('error', 'Não foi possível carregar produtos')
        }

      } else {
        //@ts-ignore
        if (tipoProduto && (tipoProduto != produtos[0].produto.tipo_produto_id)) {
          notify('error', 'Você não pode alterar o tipo de produto quando há produtos de um tipo diferente já adicionados');
          if (tipoProdutoOld) {
            setTipoProduto(tipoProdutoOld)
          }
        }
      }
    } else {

      try {
        const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/produtos/`, {
          params: {
            situacao: 'A',
            limit: 1000,
            tipoProduto
          }
        })
        const data = await response.data.data

        setProdutosList(data)
        setTipoProdutoOld(tipoProduto);
      } catch (error) {
        console.log(error)
        notify('error', 'Não foi possível carregar produtos')
      }

    }
  }

  async function loadMeta() {
    try {
      const response = await api.get(`marketing/empresas/${empresaSelecionada?.id}/metas/${meta_id}`)
      const data = await response.data
      setMeta(data)
    } catch (error) {
      notify('error', 'Não foi possível carregar meta')
      console.error(error)
    }
  }

  async function handleSave() {
    setErros({} as FormError)
    setSaving(true)
    if (meta.id) {
      await update()
    } else {
      await insert()
    }
    //setSaving(false)
  }

  function afterSave(msg: string) {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
        title={msg}
        onConfirm={() => history.goBack()}
        confirmBtnBsStyle="success"
        showConfirm={false}
        btnSize=""
      />
    );
    setTimeout(history.goBack, 2000);
  };

  async function insert() {
    try {
      const newMeta = bind()
      if (validarMeta(newMeta)) {
        const response = await api.post(`marketing/empresas/${empresaSelecionada?.id}/metas`,
          {
            ...newMeta
          })
        const data = await response.data
        setMeta(data)
        afterSave('Meta Salva');
      }
    } catch (error) {
      console.error(error)
      notify('danger', 'Não foi possível salvar Meta')
      //@ts-ignore
      throwError(error.response)
    } finally {
      setSaving(false)
    }
  }

  async function update() {
    try {
      const newMeta = bind()
      if (validarMeta(newMeta)) {
        const response = await api.put(`marketing/empresas/${empresaSelecionada?.id}/metas/${meta.id}`,
          {
            ...newMeta,
          })
        const data = await response.data
        setMeta(data)
        afterSave('Meta Alterada');
      }
    } catch (error) {
      console.error(error)
      notify('danger', 'Não foi possível alterar Meta')
      //@ts-ignore
      throwError(error.response)
    } finally {
      setSaving(false)
    }
  }

  function throwError(response: AxiosResponse) {
    if (response && response.status == 400) {
      if (response.data.length) {
        const responseError = {} as FormError
        for (let e of response.data) {
          //@ts-ignore
          responseError[e.field] = e.message
        }
        setErros(responseError)
      }
    }
  }

  function validarMeta(meta: Meta) {

    if (meta) {
      //IF META TRUE

      if (meta.valor > 0) {
        //IF META VALOR > 0 TRUE

        if (meta.produtos && meta.produtos.length > 0) {
          //IF DISTRIBUIÇÂO POR PRODUTOS OK

          if (meta.colaboradores && meta.colaboradores.length > 0) {
            //IF DISTRIBUIÇÂO POR COLABORADORES OK')
            if (meta.unidade) {
              //IF UNIDADE')
              //@ts-ignore
              if (meta.unidade == 'Q') {
                //IF UNIDADE == Q')
                //VALIDANDO DISTRIBUIÇÃO COLABORADORES')
                //@ts-ignore
                if (somaValorProdutos != parseInt(somaValorProdutos)) {
                  //SE VALORES NÂO INTEIROS EM PRODUTOS')
                  notify('danger', 'Os valores de metas informados NA DISTRIBUIÇÃO POR PRODUTOS devem ser números inteiros quando a Unidade escolhida for QUANTIDADE')
                  return false
                } else {
                  console.log('quantidade')
                  console.log({ somaValorProdutos })
                  console.log(meta.valor)
                  if (somaValorProdutos != meta.valor) {
                    //SE VALOR DIFERENTE DE SOMATORIA PARCIAL PRODUTO')
                    notify('danger', 'O valor de metas informado DEVE SER IGUAL a soma dos valores da DISTRIBUIÇÃO POR PRODUTOS')
                    return false
                  } else {
                    //VALIDANDO DISTRIBUIÇÃO COLABORADORES')
                    //@ts-ignore
                    if (somaValorColaboradores != parseInt(somaValorColaboradores)) {
                      //SE VALORES NÂO INTEIROS EM COLABORADORES')
                      notify('danger', 'Os valores de metas informados NA DISTRIBUIÇÃO POR COLABORADORES devem ser números inteiros quando a Unidade escolhida for QUANTIDADE')
                      return false
                    } else {
                      if (somaValorColaboradores != meta.valor) {
                        //SE VALOR DIFERENTE DE SOMATORIA PARCIAL COLABORADORES')
                        notify('danger', 'O valor de metas informado DEVE SER IGUAL a soma dos valores da DISTRIBUIÇÃO POR COLABORADORES')
                        return false
                      } else {
                        for (const colab of meta.colaboradores) {
                          if (colab.valor <= 0) {
                            notify('danger', `A meta definida para ${colab.colaborador.name} deve ser maior que ZERO`)
                            return false
                          } else {
                            console.log('##### VALIDANDO PERIODOS #####')

                            if (colab && colab.periodos && colab.periodos.length > 0) {
                              var periodos = colab.periodos
                              var somaValoresPeriodo = 0
                              for (const periodo of periodos) {
                                somaValoresPeriodo += parseFloat(periodo.valor)

                                if (!(!moment(periodo.data_inicio).isBefore(firstDay)
                                  && !moment(periodo.data_inicio).isAfter(lastDay)
                                  && !moment(periodo.data_fim).isBefore(firstDay)
                                  && !moment(periodo.data_fim).isAfter(lastDay))) {
                                  notify('danger', `O(s) período(s) definido(s) para ${colab.colaborador.name} não conrresponde(m) ao mês/ano definido para a Meta`)
                                  return false
                                }
                              }
                              if (somaValoresPeriodo <= 0) {
                                notify('danger', `A soma das metas por período de ${colab.colaborador.name} deve ser maior que ZERO`)
                                return false
                              } else {
                                //@ts-ignore
                                if (somaValoresPeriodo != parseInt(somaValoresPeriodo)) {
                                  //SE VALORES NÂO INTEIROS EM PRODUTOS')
                                  notify('danger', `Os valores de metas informados NA DISTRIBUIÇÃO POR PERÍODO de ${colab.colaborador.name} devem ser números inteiros quando a Unidade escolhida for QUANTIDADE`)
                                  return false
                                } else {
                                  if (somaValoresPeriodo != parseInt(colab.valor)) {
                                    //SE VALOR DIFERENTE DE SOMATORIA PARCIAL COLABORADORES')
                                    notify('danger', `A meta informada para ${colab.colaborador.name} DEVE SER IGUAL a soma dos valores da DISTRIBUIÇÃO POR PERÍODO`)
                                    return false
                                  }
                                }
                              }
                            } else {
                              notify('danger', `A meta por período de ${colab.colaborador.name} deve ser definida`)
                              return false
                            }
                          }
                        }

                        for (const prod of meta.produtos) {
                          if (prod.valor <= 0) {
                            notify('danger', 'A meta definida para um produto deve ser maior que ZERO')
                            return false
                          }
                        }

                        return true
                      }
                    }
                  }
                }
              } else {
                //IF UNIDADE == M')
                //VALIDANDO DISTRIBUIÇÃO COLABORADORES')
                //@ts-ignore
                if (parseFloat(somaValorProdutos).toFixed(2) != parseFloat(meta.valor).toFixed(2)) {
                  //SE VALOR DIFERENTE DE SOMATORIA PARCIAL PRODUTO')
                  notify('danger', 'O valor de metas informado DEVE SER IGUAL a soma dos valores da DISTRIBUIÇÃO POR PRODUTOS')
                  return false
                } else {
                  //VALIDANDO DISTRIBUIÇÃO COLABORADORES')
                  //@ts-ignore
                  if (parseFloat(somaValorColaboradores).toFixed(2) != parseFloat(meta.valor).toFixed(2)) {
                    //SE VALOR DIFERENTE DE SOMATORIA PARCIAL COLABORADORES')
                    notify('danger', 'O valor de metas informado DEVE SER IGUAL a soma dos valores da DISTRIBUIÇÃO POR COLABORADORES')
                    return false
                  } else {
                    for (const colab of meta.colaboradores) {
                      if (colab.valor <= 0) {
                        notify('danger', `A meta definida para ${colab.colaborador.name} deve ser maior que ZERO`)
                        return false
                      } else {
                        console.log('##### VALIDANDO PERIODOS #####')

                        if (colab && colab.periodos && colab.periodos.length > 0) {
                          var periodos = colab.periodos
                          var somaValoresPeriodo = 0
                          for (const periodo of periodos) {
                            somaValoresPeriodo += parseFloat(periodo.valor)
                            if (!(!moment(periodo.data_inicio).isBefore(firstDay)
                              && !moment(periodo.data_inicio).isAfter(lastDay)
                              && !moment(periodo.data_fim).isBefore(firstDay)
                              && !moment(periodo.data_fim).isAfter(lastDay))) {
                              notify('danger', `O(s) período(s) definido(s) para ${colab.colaborador.name} não conrresponde(m) ao mês/ano definido para a Meta`)
                              return false
                            }
                          }
                          if (somaValoresPeriodo <= 0) {
                            notify('danger', `A soma das metas por período de ${colab.colaborador.name} deve ser maior que ZERO`)
                            return false
                          } else {

                            if (somaValoresPeriodo.toFixed(2) != parseFloat(colab.valor).toFixed(2)) {
                              //SE VALOR DIFERENTE DE SOMATORIA PARCIAL COLABORADORES')
                              notify('danger', `A meta informada para ${colab.colaborador.name} DEVE SER IGUAL a soma dos valores da DISTRIBUIÇÃO POR PERÍODO`)
                              return false
                            }
                          }

                        } else {
                          notify('danger', `A meta por período de ${colab.colaborador.name} deve ser definida`)
                          return false
                        }

                      }
                    }

                    for (const prod of meta.produtos) {
                      if (prod.valor <= 0) {
                        notify('danger', 'A meta definida para um produto deve ser maior que ZERO')
                        return false
                      }
                    }
                    return true
                  }
                }
              }
            } else {
              //IF UNIDADE NÃO DEFINIDA')
              notify('danger', 'Defina a Unidade da Meta (Quantidade ou Monetária)')
              return false;
            }
          } else {
            //IF DISTRIBUIÇÂO DE COLABORADORES NÃO REALIZADA')
            notify('danger', 'Defina a Distribuição por Colaboradores')
            return false;
          }
        } else {
          //IF DISTRIBUIÇÂO DE PRODUTOS NÃO REALIZADA')
          notify('danger', 'Defina a Distribuição por Produtos')
          return false;
        }

      } else {
        //IF META VALOR <= 0')
        notify('danger', 'Defina o valor da Meta')
        return false;
      }
    } else {
      //IF META NÃO DEFINIDA')
      notify('danger', 'Meta não Definida')
      return false;
    }

    console.log('Se este texto está aparecendo, algo deixou de ser validado')
    return false;
  }

  function bind(): Meta {
    const meta = {} as Meta;

    //@ts-ignore
    meta.mes = mes;
    //@ts-ignore
    meta.ano = ano;
    meta.tipo_produto_id = tipoProduto;
    meta.unidade = unidade;
    //@ts-ignore
    meta.valor = valor;
    meta.empresa_id = empresaSelecionada?.id as number;

    meta.produtos = [];
    if (produtos && produtos.length > 0) {
      for (const produto of produtos) {
        meta.produtos.push(produto)
      }
    }

    meta.colaboradores = [];
    if (colaboradores && colaboradores.length > 0) {
      for (const colaborador of colaboradores) {
        meta.colaboradores.push(colaborador)
      }
    }

    return meta;
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {msg}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      zIndex: 1060,
      autoDismiss: 8
    };
    notificationAlert?.current?.notificationAlert(options);
  };

  function handleSavePeriodos() {
    notify('success', 'Periodos Configurados')
    setShowPeriodosModal(false)
    setColaboradorEditPeriodo(null)
  }

  function ConfigModal({ }) {
    const onClose = () => {
      setShowPeriodosModal(false)
      setColaboradorEditPeriodo(null)
    }
    return (
      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={showPeriodosModal}
        toggle={onClose}
        style={{
          zIndex: 998,
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Meta por Período
            <br />
            {/* @ts-ignore */}
            <small className='text-muted'>{colaboradorEditPeriodo ? colaboradorEditPeriodo.colaborador.name : null}</small>
          </h5>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <h4 style={{ textAlign: 'center' }}>
            Editar Períodos
            <Button
              color="secondary"
              id="tooltip-998822"
              outline
              size="sm"
              type="button">
              ?
            </Button>
            <UncontrolledPopover placement="top" target="tooltip-998822">
              <PopoverBody>
                Subdivida a meta dos seus colaboradores, fracionando-a por períodos dentro de um mês
              </PopoverBody>
            </UncontrolledPopover>
          </h4>
          <PeriodosConfigs
            notify={notify}
            meta={meta}
            firstDay={firstDay}
            lastDay={lastDay}
            colaborador={colaboradorEditPeriodo}
            unidade={unidade}
            onSuccess={handleSavePeriodos}
            onBack={onClose}
          />
        </div>
      </Modal>
    )
  }

  return (
    <>

      <NotificationAlert ref={notificationAlert} />

      {alert}

      <ConfigModal />

      <Container
        className='mt--6'
        fluid
      >
        <Card>
          <CardHeader
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
            }}>
            <h1>{meta.id ? 'Alterar Meta' : 'Nova Meta'}</h1>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xl={2} lg={2} md={4} sm={4}>
                <FormGroup>
                  <label className="form-control-label" >
                    Mês*
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      options={{
                        placeholder: "Selecione..."
                      }}
                      //@ts-ignore
                      onSelect={(e) => setMes(e.target.value)}
                      value={mes}
                      data={
                        //@ts-ignore
                        meses.map((item) => ({ id: item.id, text: item.nome }))
                      }
                    />
                  </InputGroup>
                  <small className="text-danger">
                    {erros.mes || ""}
                  </small>
                </FormGroup>
              </Col>

              <Col xl={2} lg={2} md={3} sm={3}>
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Ano*
                  </label>

                  <InputGroup className="input-group-alternative">
                    <Input
                      id="example-number-input"
                      type="number"
                      min={getAnoAtual() - 20}
                      max={getAnoAtual() + 20}
                      //@ts-ignore
                      value={ano}
                      //@ts-ignore
                      onChange={e => setAno(e.target.value)}
                    />
                  </InputGroup>
                  <small className="text-danger">
                    {erros.ano || ""}
                  </small>
                </FormGroup>

              </Col>

              <Col xl={6} lg={6} md={5} sm={5}>
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Tipos de produtos*
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      options={{
                        placeholder: "Selecione..."
                      }}
                      value={tipoProduto}
                      //@ts-ignore
                      onSelect={(e) => setTipoProduto(e.target.value)}
                      //@ts-ignore
                      data={tiposProdutosList.map((item) => ({ id: item.id, text: item.nome }))}
                    />
                  </InputGroup>
                  <small className="text-danger">
                    {erros.tipo_produto_id || ""}
                  </small>
                </FormGroup>

              </Col>

            </Row>

            <Row>

              <Col xl={2} lg={2} md={4} sm={4}>
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Unidade*
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Select2
                      className="input-group-alternative"
                      options={{
                        placeholder: "Selecione..."
                      }}
                      //@ts-ignore
                      onSelect={(e) => setUnidade(e.target.value)}
                      value={unidade}
                      data={[{
                        id: 'M', text: 'Monetária'
                      },
                      {
                        id: 'Q', text: 'Quantidade'
                      }
                      ]}
                    />
                  </InputGroup>
                  <small className="text-danger">
                    {erros.unidade || ""}
                  </small>
                </FormGroup>

              </Col>

              <Col xl={2} lg={2} md={3} sm={3}>
                <FormGroup>
                  <label className="form-control-label" >
                    Valor*
                  </label>
                  {(unidade && unidade == 'M') &&
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>R$</InputGroupText>
                      </InputGroupAddon>
                      <CurrencyInput
                        className='form-control'
                        value={valor}
                        placeholder="Valor do produto..."
                        //@ts-ignore
                        onChangeEvent={(e, maskedvalue, floatvalue) => setValor(floatvalue)}
                      />
                    </InputGroup>
                  }
                  {(!unidade || unidade == 'Q') &&
                    <Input
                      placeholder='Valor...'
                      className="form-control"
                      type='number'
                      //@ts-ignore
                      value={valor}
                      //@ts-ignore
                      onChange={e => setValor(parseInt(e.target.value))}
                    />
                  }
                  <small className="text-danger">
                    {erros.valor || ""}
                  </small>
                </FormGroup>
              </Col>

            </Row>

            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <>
                  <div className="accordion">

                    <Card className="card-plain">
                      <CardHeader
                        role="tab"
                        onClick={() => collapsesToggle('collapseOne')}
                        //@ts-ignore
                        aria-expanded={state.openedCollapses.includes('collapseOne')}
                      >
                        <h5 className="mb-0">Distribuição por produtos</h5>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        //@ts-ignore
                        isOpen={state.openedCollapses.includes('collapseOne')}
                      >
                        <CardBody>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column'
                            }}
                          >
                            <Row>

                              <Col lg={6} md={6}>
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="example-number-input"
                                  >
                                    Produtos*
                                  </label>
                                  <InputGroup className="mb-3">
                                    <div
                                      style={{
                                        width: '400px'
                                      }}
                                    >
                                      <Select2
                                        className="form-control"
                                        //@ts-ignore
                                        onSelect={(e) => setProdutoSelected(e.target.value)}
                                        //options={clienteOptions()}
                                        value={produtoSelected}
                                        //@ts-ignore
                                        data={produtosList.map((item) => ({ id: item.id, text: item.nome }))}
                                      />
                                    </div>
                                    <InputGroupAddon addonType="append">
                                      <Button
                                        color="primary"
                                        type="button"
                                        outline
                                        //@ts-ignore
                                        onClick={handleAddProduto}
                                        className="btn-icon btn-3"
                                      >
                                        <span className="btn-inner--icon">
                                          <i className="ni ni-fat-add"></i>
                                        </span>
                                        <span className="btn-inner--text">Adicionar</span>
                                      </Button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </FormGroup>
                              </Col>


                              <Col lg={3} md={3}
                                style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                                <Button
                                  style={{ height: 46 }}
                                  color="primary"
                                  type="button"
                                  outline
                                  //@ts-ignore
                                  onClick={handleAddAllProdutos}
                                  className="btn-icon btn-3"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="ni ni-fat-add"></i>
                                  </span>
                                  <span className="btn-inner--text">Adicionar Todos</span>
                                </Button>
                              </Col>

                              <Col lg={3} md={3}
                                style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                                <Button
                                  style={{ height: 46 }}
                                  color="primary"
                                  type="button"
                                  outline
                                  //@ts-ignore
                                  onClick={handleDistruibuirPorProdutos}
                                  className="btn-icon btn-3"
                                  disabled={!(produtos && produtos.length > 0)}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fas fa-divide"></i>
                                  </span>
                                  <span className="btn-inner--text">Distribuir</span>
                                </Button>
                              </Col>

                            </Row>
                          </div>

                          <TableProduto
                            produtos={produtos}
                            columnsProdutos={columnsProdutos}
                            unidade={unidade}
                            valor={valor}
                            somaValorProdutos={somaValorProdutos}
                            beforeSaveCellProduto={beforeSaveCellProduto}
                            handleRedistruibuirPorProdutos={handleDistruibuirPorProdutos}
                          >
                          </TableProduto>

                        </CardBody>
                      </Collapse>
                      <small className="text-danger" style={{ marginLeft: '1.5rem' }}>
                        {erros.produtos || ""}
                      </small>
                    </Card>

                    <Card className="card-plain">
                      <CardHeader
                        role="tab"
                        onClick={() => collapsesToggle('collapseTwo')}
                        //@ts-ignore
                        aria-expanded={state.openedCollapses.includes('collapseTwo')}
                      >
                        <h5 className="mb-0">Distribuição por colaboradores</h5>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        //@ts-ignore
                        isOpen={state.openedCollapses.includes('collapseTwo')}
                      >
                        <CardBody>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column'
                            }}
                          >
                            <Row>
                              <Col lg={6} md={6}>
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="example-number-input"
                                  >
                                    Colaboradores*
                                  </label>
                                  <InputGroup className="mb-3">
                                    <div
                                      style={{
                                        width: '400px'
                                      }}
                                    >
                                      <Select2
                                        className="form-control"
                                        //@ts-ignore
                                        onSelect={(e) => setColaboradorSelected(e.target.value)}
                                        //options={clienteOptions()}
                                        value={colaboradorSelected}
                                        //@ts-ignore
                                        data={colaboradoresList.map((item) => ({ id: item.id, text: item.name }))}
                                      />
                                    </div>
                                    <InputGroupAddon addonType="append">
                                      <Button
                                        color="primary"
                                        type="button"
                                        outline
                                        //@ts-ignore
                                        onClick={handleAddColaborador}
                                        className="btn-icon btn-3"
                                      >
                                        <span className="btn-inner--icon">
                                          <i className="ni ni-fat-add"></i>
                                        </span>
                                        <span className="btn-inner--text">Adicionar</span>
                                      </Button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col lg={3} md={3}
                                style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                                <Button
                                  style={{ height: 46 }}
                                  color="primary"
                                  type="button"
                                  outline
                                  //@ts-ignore
                                  onClick={handleAddAllColaboradores}
                                  className="btn-icon btn-3"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="ni ni-fat-add"></i>
                                  </span>
                                  <span className="btn-inner--text">Adicionar Todos</span>
                                </Button>
                              </Col>

                              <Col lg={3} md={3}
                                style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                                <Button
                                  style={{ height: 46 }}
                                  color="primary"
                                  type="button"
                                  outline
                                  //@ts-ignore
                                  onClick={handleDistruibuirPorColaboradores}
                                  className="btn-icon btn-3"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fas fa-divide"></i>
                                  </span>
                                  <span className="btn-inner--text">Distribuir</span>
                                </Button>
                              </Col>

                            </Row>
                          </div>

                          <TableColaborador
                            colaboradores={colaboradores}
                            columnsColaboradores={columnsColaboradores}
                            unidade={unidade}
                            valor={valor}
                            somaValorColaboradores={somaValorColaboradores}
                            beforeSaveCellColaborador={beforeSaveCellColaborador}
                            handleRedistruibuirPorColaboradores={handleDistruibuirPorColaboradores}
                          >
                          </TableColaborador>

                        </CardBody>
                      </Collapse>
                      <small className="text-danger" style={{ marginLeft: '1.5rem' }}>
                        {erros.colaboradores || ""}
                      </small>
                    </Card>

                  </div>
                </>
              </Col>
            </Row>


            <Row>
              <Col>
                <div className="float-right ">
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    voltar
                  </Button>
                  <Button
                    // disabled={saving || (meta && meta.id > 0)}
                    disabled={saving}
                    color="primary"
                    onClick={handleSave}
                  >
                    {
                      <Spinner
                        hidden={!saving}
                        className="mr-2"
                        color="light"
                        size="sm"
                      />
                    }
                    Salvar
                  </Button>

                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container >
    </>
  )
}

export default Form;