import styled from 'styled-components'
import {
    Button
} from "reactstrap";

export const TextContainer = styled.p`
    
    :hover{
        background-color: #EEE;
        cursor: pointer;
    }
`

export const ButtonHistorico = styled(Button)`
    position: absolute;
    right: 1px;
    bottom: -5px
`