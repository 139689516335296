import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interaction from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt-br";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardText,
  CardSubtitle,
} from "reactstrap";
import chroma from "chroma-js";
// import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import EventModal from "./components/EventModal";
import api from "../../services/api";
import { InfoToolTip } from "../Tooltip";

const CustomCalendar = (props) => {
  const { newEvent, tipoAgendamento, nomeCliente, oportunidadeId } = props;
  const [events, setEvents] = useState([]);
  const [alert, setAlert] = useState(null);
  const [modalAdd, setModalAdd] = useState(() =>
    tipoAgendamento?.id ? true : false
  );

  const [eventId, setEventId] = useState(undefined);
  const [currentDate, setCurrentDate] = useState(null);
  const [startingDate, setStartingDate] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [metricasProduto, setMetricasProdutos] = useState("");

  const calendarRef = useRef(null);

  function openModal() {
    setModalAdd(true);
  }

  function closeModal() {
    setEventId(undefined);
    setModalAdd(false);
  }

  useEffect(() => {
    createCalendar();
    loadAgendamentos();
  }, []);

  useEffect(() => {
    document.querySelector("#calendar").innerHTML = "";
    createCalendar();
  }, [events]);

  useEffect(() => {
    criarMarcaAgendamentoOportunidade();
  }, [calendarRef.current]);

  async function loadAgendamentos() {
    try {
      setIsLoading(true);
      const { data } = await api.get(`marketing/agendamentos`);
      if (newEvent) {
        const prodOpData = await api.get(
          `marketing/agendamentos/produto-oportunidade/${newEvent?.produto_oportunidade_id}`
        );
        setMetricasProdutos(prodOpData.data);
      }

      setEvents(
        data.map((evento) => {
          return {
            title: evento.titulo,
            start: evento.data_inicio,
            end: evento.data_fim,
            color: evento.cor,
            id: evento.id,
            isConcluido: !!evento.data_conclusao,
            produto_oportunidade_id: evento.produto_oportunidade_id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function criarMarcaAgendamentoOportunidade() {
    if (!newEvent?.data_agendamento) return;
    const cData = newEvent?.data_agendamento.slice(0, 10);

    const element = document.querySelector(`.fc-day-top[data-date="${cData}"]`);
    if (element) {
      element.style.background = "#ded8d8";
    }
  }

  const createCalendar = () => {
    const calendar = new Calendar(document.getElementById("calendar"), {
      plugins: [interaction, dayGridPlugin, timeGridPlugin],
      defaultView: "dayGridMonth",
      slotDuration: { minutes: 15 },
      slotLabelInterval: "00:15",
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
      },
      eventLimit: 3,
      selectable: true,
      selectHelper: true,
      editable: false,
      events: events,
      locale: ptLocale,
      eventOverlap: true,
      aspectRatio: 1.5,
      displayEventTime: false,
      allDaySlot: false,
      locale: "pt-br",
      minTime: "06:00",
      maxTime: "23:59",
      nowIndicator: true,
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        // daysOfWeek: [ 1, 2, 3, 4 ], // Monday - Thursday

        startTime: "06:00",
        endTime: "22:00",
      },
      // Add new event
      select: (info) => {
        setStartingDate(info.start);
        openModal();
      },
      // Edit calendar event action
      eventClick: ({ event }) => {
        setEventId(event.id);
        openModal();
      },
      eventLimitClick: function (cellInfo) {
        calendar.gotoDate(cellInfo.date);
        calendar.changeView("timeGridDay");
      },
      eventRender: (e) => {
        const dColor =
          chroma(e.event.backgroundColor).luminance() > 0.5
            ? "#131313"
            : "#fff";
        const spanTitle = e.el.querySelector(".fc-title");
        spanTitle.style.color = dColor;

        if (newEvent && 
          newEvent.produto_oportunidade_id ==
          e.event.extendedProps.produto_oportunidade_id
        ) {
          const agendamentoCount = `(${metricasProduto.total_agendamentos}/${metricasProduto.quantidade_produtos}) `;
          spanTitle.textContent = agendamentoCount + spanTitle.textContent;
        }

        const concluido = e.event.extendedProps.isConcluido;
        if (concluido) {
          e.el.classList.add("evento-concluido");
        }
      },
    });
    calendarRef.current = calendar;
    calendar.render();
    setCurrentDate(calendar.view.title);
  };

  const changeView = (newView) => {
    if (calendarRef.current) {
      calendarRef.current.changeView(newView);
      setCurrentDate(calendarRef.current.view.title);
    }
  };

  return (
    <>
      {alert}
      <Card className="card-calendar">
        <CardHeader className="">
          <Row className="align-items-center justify-content-between py-4">
            <Col lg="4">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0 mr-1 capitalize-first-letter">
                {currentDate}
              </h6>
            </Col>

            {newEvent && (
              <Col className="text-center">
                <span>Agendamento referente ao produto: </span>
                <br />
                <span style={{ fontWeight: "bold" }}>{newEvent?.produto}</span>
              </Col>
            )}

            <Col className="mt-3 mt-lg-0 text-lg-right" lg="4">
              <Button
                className="fullcalendar-btn-prev btn-neutral"
                color="default"
                onClick={() => {
                  calendarRef.current.prev();
                  setCurrentDate(calendarRef.current.view.title);
                  criarMarcaAgendamentoOportunidade();
                }}
                size="sm"
              >
                <i className="fas fa-angle-left" />
              </Button>
              <Button
                className="fullcalendar-btn-next btn-neutral"
                color="default"
                onClick={() => {
                  calendarRef.current.next();
                  setCurrentDate(calendarRef.current.view.title);
                  criarMarcaAgendamentoOportunidade();
                }}
                size="sm"
              >
                <i className="fas fa-angle-right" />
              </Button>
              <Button
                className=""
                color="primary"
                data-calendar-view="month"
                onClick={() => {
                  changeView("dayGridMonth");
                  criarMarcaAgendamentoOportunidade();
                }}
                size="sm"
              >
                Mês
              </Button>
              <Button
                className=""
                color="primary"
                data-calendar-view="basicWeek"
                onClick={() => {
                  changeView("dayGridWeek");
                  criarMarcaAgendamentoOportunidade();
                }}
                size="sm"
              >
                Semana
              </Button>
              <Button
                className=""
                color="primary"
                data-calendar-view="basicDay"
                onClick={() => {
                  changeView("timeGridDay");
                  criarMarcaAgendamentoOportunidade();
                }}
                size="sm"
              >
                Dia
              </Button>
            </Col>
          </Row>
          {newEvent && (
            <Row className="d-flex justify-content-center">
              <Col lg={3}>
                <Card>
                  <CardBody>
                    <CardSubtitle className="d-flex justify-content-between">
                      <span>Quantidade</span>
                      <span id="Quantidade">
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </CardSubtitle>
                    <CardText className="d-flex justify-content-between align-items-center">
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                        {metricasProduto?.quantidade_produtos}
                      </span>
                      <span style={{ fontSize: "1.5rem" }}>
                        <i className="fas fa-box"></i>
                      </span>
                    </CardText>
                  </CardBody>
                </Card>
                <InfoToolTip
                  message="Quantidade total deste produto para esta oportunidade."
                  target="Quantidade"
                />
              </Col>
              <Col lg={3}>
                <Card>
                  <CardBody>
                    <CardSubtitle className="d-flex justify-content-between">
                      <span>Agendados</span>
                      <span id="Agendados">
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </CardSubtitle>
                    <CardText className="d-flex justify-content-between align-items-center">
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                        {metricasProduto?.total_agendamentos}
                      </span>
                      <span style={{ fontSize: "1.5rem" }}>
                        <i className="fas fa-clock"></i>
                      </span>
                    </CardText>
                  </CardBody>
                </Card>
                <InfoToolTip
                  message="Quantidade de agendamentos feitos para este produto."
                  target="Agendados"
                />
              </Col>
              <Col lg={3}>
                <Card>
                  <CardBody>
                    <CardSubtitle className="d-flex justify-content-between">
                      <span>Concluídos</span>
                      <span id="Concluidos">
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </CardSubtitle>
                    <CardText className="d-flex justify-content-between align-items-center">
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                        {metricasProduto?.total_agendamentos_concluidos}
                      </span>
                      <span style={{ fontSize: "1.5rem" }}>
                        <i className="fas fa-calendar-check"></i>
                      </span>
                    </CardText>
                  </CardBody>
                </Card>
                <InfoToolTip
                  message="Agendamentos para este produto que já foram cumpridos ou realizados."
                  target="Concluidos"
                />
              </Col>
              <Col lg={3}>
                <Card>
                  <CardBody>
                    <CardSubtitle className="d-flex justify-content-between">
                      <span>Pendentes</span>
                      <span id="Pendentes">
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </CardSubtitle>
                    <CardText className="d-flex justify-content-between align-items-center">
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                        {+metricasProduto?.total_agendamentos -
                          +metricasProduto?.total_agendamentos_concluidos}
                      </span>
                      <span style={{ fontSize: "1.5rem" }}>
                        <i className="fas fa-calendar-times"></i>
                      </span>
                    </CardText>
                  </CardBody>
                </Card>
                <InfoToolTip
                  message="Agendamentos para este produto que precisam ser executados."
                  target="Pendentes"
                />
              </Col>

              {/* <Col>
              <Button color="success" outline disabled>
                <span>Concluídas</span>
                <Badge
                  color="success"
                  className="badge-md badge-circle badge-floating border-white"
                >
                  4
                </Badge>
              </Button>
            </Col> */}
            </Row>
          )}
        </CardHeader>
        <CardBody className="p-0">
          {events && <div id="calendar" className="calendar" />}
        </CardBody>
      </Card>
      <EventModal
        visible={modalAdd}
        toggleModal={setModalAdd}
        onSave={loadAgendamentos}
        eventId={eventId}
        onClose={closeModal}
        startDate={startingDate}
        agendamento={newEvent}
        tipoAgendamento={tipoAgendamento}
        nomeCliente={nomeCliente}
        oportunidadeId={oportunidadeId}
        produtoOportunidadeId={newEvent?.produto_oportunidade_id}
      />
    </>
  );
};

export default CustomCalendar;
