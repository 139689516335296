import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../../../../services/api";
import { Empresa, SiteEmpresa } from "../../../../../entities/Common";
import classnames from "classnames";
import List from "./List";
import Form from "./Form";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
// @ts-ignore
import NotificationAlert from "react-notification-alert";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import EmpresaContext from "../../../../../contexts/Empresa";
import { Sites as ISites } from "../../../../security/Users/me/components/Paginas";
import useModal from "./modals/useModal";
import { SelecionarModeloModal } from "./modals/SelecionarModeloModal";
import { EditarModeloModal } from "./modals/EditarModeloModal";

export interface ModeloSite {
  id: number;
  link: string;
  tipo_modelo: "afiliacao" | "indicacao";
  params_map: string;
}

interface ParametroSite {
  id: number;
  chave: string;
  valor: string;
  descricao: string;
  usuario_modelo_site_id: string;
}

interface ModeloSiteParams {
  id: string;
  modelo_site_id: number;
  parametroSite: ParametroSite[];
  site: ModeloSite;
}
export interface UsuarioModeloSite extends ModeloSiteParams {
  user_empresa_id: number;
}
export interface EmpresaModeloSite extends ModeloSiteParams {
  empresa_id: number;
}

const Sites: React.FC = () => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [sites, setSites] = useState(new Array<SiteEmpresa>());
  const [showForm, setShowForm] = useState(false);
  const [activeTab, setTabActive] = useState("indicacao");
  const notificationAlert = useRef<any>();

  const [modelosSites, setModelosSites] = useState<ModeloSite[]>([]);
  const [paginaIndicacao, setPaginaIndicacao] = useState<EmpresaModeloSite>();
  const [isModalVendedorVisible, setIsModalVendedorVisible] = useModal();
  const [isEditModalVendedorVisible, setIsEditModalVendedorVisible] =
    useModal();

  async function load() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/sites-empresas`
      );
      setSites(await response.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar os sites da empresa");
    }
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  async function carregarModelosSites() {
    try {
      const { data: sites } = await api.get<ModeloSite[]>(
        "/marketing/modelos-sites"
      );
      setModelosSites(sites);
    } catch (error) {
      console.error(
        "Ocorreu um erro ao tentar carregar os modelos dos sites.\n",
        error
      );
    }
  }

  async function carregarPaginaIndicacao() {
    try {
      const { data: siteEmpresa } = await api.get<EmpresaModeloSite>(
        "/marketing/modelos-sites/empresa"
      );

      setPaginaIndicacao(siteEmpresa);
    } catch (error) {
      console.error("Houve um erro ao carregar os dados do site.", error);
    }
  }

  useEffect(() => {
    carregarModelosSites();
    carregarPaginaIndicacao();
    load();
  }, []);

  function hideForm() {
    setShowForm(false);
  }

  function handleShowForm() {
    setShowForm(true);
  }

  return (
    <>
      {alert}

      <SelecionarModeloModal
        isOpen={isModalVendedorVisible}
        onClose={setIsModalVendedorVisible}
        pages={modelosSites.filter((site) => site.tipo_modelo == "indicacao")}
        reload={carregarPaginaIndicacao}
        currentSiteId={paginaIndicacao?.modelo_site_id}
        siteUsuario={paginaIndicacao}
      />

      <EditarModeloModal
        isOpen={isEditModalVendedorVisible}
        onClose={setIsEditModalVendedorVisible}
        reload={carregarPaginaIndicacao}
        siteEmpresa={paginaIndicacao}
      />

      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Sites" parentName="Minha empresa" />
      <div className="container-fluid mt--6">
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <h1>Sites</h1>
              </CardHeader>
              <CardBody outline body>
                <Row>
                  <Col xs={12} lg={12} md={12} sm={12}>
                    <div className="nav-wrapper">
                      <Nav
                        className="nav-fill flex-column flex-md-row"
                        pills
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames("mb-sm-3 mb-md-0", {
                              active: activeTab == "indicacao",
                            })}
                            href="#"
                            onClick={() => setTabActive("indicacao")}
                            role="tab"
                            // disabled={!campanha.id}
                          >
                            Programa de indicação
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            href="#"
                            role="tab"
                            className={classnames("mb-sm-3 mb-md-0", {
                              active: activeTab == "site",
                            })}
                            onClick={() => setTabActive("site")}
                          >
                            Sites
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <hr />
                    <div>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="site">
                          {empresaSelecionada && (
                            <Form
                              empresa={empresaSelecionada}
                              hide={hideForm}
                              notify={notify}
                              success={load}
                              show={showForm}
                              site={{} as SiteEmpresa}
                            />
                          )}
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              type="button"
                              className="btn-icon btn-3"
                              onClick={handleShowForm}
                            >
                              <span className="btn-inner--icon">
                                <i className="ni ni-fat-add"></i>
                              </span>
                              <span className="btn-inner--text">Novo Site</span>
                            </Button>
                          </div>

                          {empresaSelecionada && (
                            <List
                              sites={sites}
                              empresa={empresaSelecionada}
                              notify={notify}
                              reLoad={load}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="indicacao">
                          <Row>
                            <Col xs={12} sm={6}>
                              <Card>
                                <CardImg src={`${paginaIndicacao?.site.link}preview.png`} />
                                <CardBody>
                                  <CardTitle tag="h3">
                                    Programa de Indicação
                                  </CardTitle>
                                  <CardText>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={setIsModalVendedorVisible}
                                    >
                                      Selecionar Modelo
                                    </Button>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={setIsEditModalVendedorVisible}
                                      disabled={!paginaIndicacao?.id}
                                    >
                                      Editar Modelo
                                    </Button>
                                  </CardText>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Sites;
