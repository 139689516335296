import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import Pagination from "../../../../components/Pagination";
import EmpresaContext from "../../../../contexts/Empresa";
import moment, { now } from "moment";
import { SubmitContainer } from "../Encerrar/styles";
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import api from "../../../../services/api";
import { usePersistedState } from "../../../../hooks";

// import { Container } from './styles';

interface ReagendamentoProps {
  show: boolean;
  lastSearch: any;
  onClose(): void;
  onSuccess: any;
  notify: any;
  oportunidades: any | null;
}

const Reagendar: React.FC<ReagendamentoProps> = ({
  show,
  onClose,
  oportunidades,
  onSuccess,
  notify,
  lastSearch,
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [pageProperties, setPageProperties] = useState<any>({});
  const [notEncaminhar, setNotEncaminhar] = useState<any>([]);
  const [datasEscolhidas, setDatasEscolhidas] = useState<any>([]);
  const [datasPossiveis, setDatasPossiveis] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(0);
  const [erros, setErros] = useState<any>({});
  const [alert, setAlert] = useState<any>(null);
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllDates, setCheckAllDates] = useState(false);
  const [limpouDataAgendamento, setLimpouDataAgendamento] = usePersistedState(
    "limpouDataAgendamento",
    false
  );

  // const [dataAgendamentoInicial, setDataAgendamentoInicial] = usePersistedState(
  //   (() => {
  //     const now = new Date();
  //     return new Date(now.getFullYear(), now.getMonth(), now.getDay() + 1);
  //   })()
  // );
  const [dataAgendamentoInicial, setDataAgendamentoInicial] = useState<any>(null);
  const [dataAgendamentoFinal, setDataAgendamentoFinal] = useState<any>(null);
  // const [dataAgendamentoInicial, setDataAgendamentoInicial] = usePersistedState(
  //   (() => {
  //     if (!limpouDataAgendamento) {
  //       const now = new Date();
  //       return new Date(now.getFullYear(), now.getMonth(), now.getDay()+1);
  //     }
  //     return null;
  //   })()
  // );
  // const [dataAgendamentoFinal, setDataAgendamentoFinal] = usePersistedState(
  //   "dataAgendamentoFinal",
  //   (() => {
  //     if (!limpouDataAgendamento) return new Date();
  //     return null;
  //   })()
  // );
  // const [dataAgendamentoFinal, setDataAgendamentoFinal] = usePersistedState(
  //   "dataAgendamentoFinal",
  //   (() => {
  //     if (!limpouDataAgendamento) return new Date();
  //     return null;
  //   })()
  // );

  useEffect(() => {
    setNotEncaminhar([]);
    setPage(0);
    if (show) setPageProperties(oportunidades);
  }, [show]);

  useEffect(() => {
    countDays(dataAgendamentoInicial, dataAgendamentoFinal);
    setDatasEscolhidas(datasPossiveis.map((dat: any) => dat.dateFormate));
  }, [dataAgendamentoInicial, dataAgendamentoFinal]);

  useEffect(() => {
    if (checkAll) {
      setNotEncaminhar(pageProperties.data.map((oport: any) => oport.id));
    } else {
      setNotEncaminhar([]);
    }
  }, [checkAll]);
  useEffect(() => {
    setDatasEscolhidas(datasPossiveis.map((dat: any) => dat.dateFormate));
  }, [datasPossiveis]);

  useEffect(() => {
    if (checkAllDates) {
      setDatasEscolhidas(datasPossiveis.map((dat: any) => dat.dateFormate));
    } else {
      setDatasEscolhidas([]);
    }
  }, [checkAllDates]);

  function handleSelectItem(oportunidadeId: number) {
    let list = [...notEncaminhar];
    if (notEncaminhar.includes(oportunidadeId)) {
      const index = list.indexOf(oportunidadeId);
      list.splice(index, 1);
    } else {
      list.push(oportunidadeId);
    }
    setNotEncaminhar(list);
  }
  function handleSelectItemDate(dateFormate: Date) {
    let list = [...datasEscolhidas];
    if (datasEscolhidas.includes(dateFormate)) {
      const index = list.indexOf(dateFormate);
      list.splice(index, 1);
    } else {
      list.push(dateFormate);
    }
    setDatasEscolhidas(list);
  }

  async function handleReagendar() {
    setSaving(true);
    setAlert(null);
    try {
      const oportunidades = pageProperties?.data
        ?.filter((oportunidade: any) => notEncaminhar.includes(oportunidade.id))
        ?.map((oportunidade: any) => oportunidade.id);
      await api.post(
        `/common/empresas/${empresaSelecionada?.id}/oportunidades/reagendar-oportunidades`,
        {
          oportunidadesIds: oportunidades,
          datas_agendamento: datasEscolhidas,
        }
      );
      onClose();
      showSuccess("Oportunidades Reagendadas");
    } catch (error) {
      console.log(error);
      if (notify) notify("danger", "Não foi possível reagendar oportunidades");
    }
    setSaving(false);
  }

  function showSuccess(msg: any) {
    onSuccess();
    setAlert(
      <ReactBSAlert
        success
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        showConfirm={false}
        timeout={2000}
      />
    );

    if (notify)
      notify("success", `${notEncaminhar.length} oportunidades reagendadas`);
    setTimeout(() => onClose(), 2000);
  }

  function handleClose() {
    if (!saving) onClose();
  }

  function handleLimparDataAgendamento() {
    setDataAgendamentoFinal(null);
    setDataAgendamentoInicial(null);
    setLimpouDataAgendamento(true);
  }

  function countDays(startDate: Date, endDate: Date) {
    let currentDate = moment(startDate);
    const end = moment(endDate);
    const datesSelect:any = [];
    while (currentDate <= end) {
      const weekday = currentDate.format("dddd");
      datesSelect.push({
        date: currentDate.format("DD/MM/YYYY"),
        weekday: weekday.charAt(0).toUpperCase() + weekday.slice(1),
        dateFormate: currentDate.toISOString(),
      });
      currentDate = currentDate.add(1, "days");
    }
    console.log(datesSelect);

    setDatasPossiveis(datesSelect);
  }

  return (
    <>
      {show && (
        <Modal
          className="modal-dialog-centered"
          isOpen={show}
          toggle={handleClose}
          size="lg"
        >
          <ModalHeader toggle={handleClose}>
            <h5 className="modal-title" id="exampleModalLabel">
              Reagendar Oportunidades
            </h5>
          </ModalHeader>
          <ModalBody>
            {alert}
            {loading ? (
              <Progress animated value="100" color="info" />
            ) : (
              <>
                <Row
                  className="align-items-center px-4 pb-3"
                  style={{
                    border: "1px solid #c1c1c173",
                    borderRadius: "8px",
                  }}
                >
                  <Col xs="12" lg="12" sm="12" md="12">
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-around",
                        alignItems: "center",
                        padding: 13,
                      }}
                    >
                      <FormGroup style={{ flex: 1, paddingRight: 4 }}>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Agendamento inicial
                        </label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Data inicial",
                              style: {
                                width: "100px",
                              },
                            }}
                            isValidDate={(currentDate) =>
                              currentDate.isAfter(moment())
                            }
                            value={moment(dataAgendamentoInicial)}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                dataAgendamentoInicial &&
                                dataAgendamentoFinal &&
                                dataAgendamentoInicial._d + "" ===
                                  currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                dataAgendamentoInicial &&
                                dataAgendamentoFinal &&
                                new Date(dataAgendamentoInicial._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(dataAgendamentoFinal._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                dataAgendamentoFinal &&
                                dataAgendamentoFinal._d + "" ===
                                  currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={(e) => setDataAgendamentoInicial(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup style={{ flex: 1, paddingLeft: 4 }}>
                        <label
                          className="form-control-label"
                          htmlFor="example-number-input"
                        >
                          Agendamento final
                        </label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Data final",
                              style: {
                                width: "100px",
                              },
                            }}
                            isValidDate={(currentDate) =>
                              currentDate.isAfter(moment())
                            }
                            value={moment(dataAgendamentoFinal)}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                dataAgendamentoInicial &&
                                dataAgendamentoFinal &&
                                dataAgendamentoInicial._d + "" ===
                                  currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                dataAgendamentoInicial &&
                                dataAgendamentoFinal &&
                                new Date(dataAgendamentoInicial._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(dataAgendamentoFinal._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                dataAgendamentoFinal &&
                                dataAgendamentoFinal._d + "" ===
                                  currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={(e) => setDataAgendamentoFinal(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <Button
                        className="ml-2"
                        color="secondary"
                        outline
                        title="Limpar datas de agendamento"
                        size="sm"
                        onClick={handleLimparDataAgendamento}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-trash" />
                        </span>
                      </Button>
                    </div>
                  </Col>
                  {datasPossiveis && (
                    <Col xs="12" lg="12" sm="12" md="12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <small>
                          {`${datasEscolhidas.length} datas selecionadas`}
                        </small>
                        <div
                          className="custom-control custom-checkbox custom-checkbox-primary"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {/* @ts-ignore */}
                          <small check
                            style={{
                              lineHeight: "22px",
                            }}
                          >
                            Todas as datas
                          </small>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            onClick={() => setCheckAllDates(!checkAllDates)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        {datasPossiveis.map((data: any, key: number) => (
                          <div key={key} className="col-lg-4">
                            <FormGroup check inline>
                              <Input
                                type="checkbox"
                                checked={datasEscolhidas.includes(
                                  data.dateFormate
                                )}
                                onClick={() =>
                                  handleSelectItemDate(data.dateFormate)
                                }
                              />
                              <div className="checklist-primary">
                                <h5 className="checklist-title mb-0">
                                  {data.date}
                                  {" - "}
                                  {data.weekday == "Domingo" ? (
                                    <span
                                      style={{
                                        color: "#646464c7",
                                      }}
                                    >
                                      {data.weekday}
                                    </span>
                                  ) : (
                                    data.weekday
                                  )}
                                </h5>
                              </div>
                            </FormGroup>
                          </div>
                        ))}
                      </div>
                    </Col>
                  )}
                  <Col lg="12" className="mt-3">
                    <SubmitContainer>
                      <Button
                        onClick={handleReagendar}
                        color="primary"
                        disabled={
                          saving ||
                          notEncaminhar.length <= 0 ||
                          dataAgendamentoInicial > dataAgendamentoFinal
                        }
                      >
                        {saving && <Spinner size="sm" className="mr-2" />}
                        Reagendar
                      </Button>
                    </SubmitContainer>
                    <small className="text-danger">
                      {erros.oportunidades || ""}
                    </small>
                  </Col>
                </Row>
                <br />
                <ListGroup data-toggle="checklist" flush>
                  <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                    <FormGroup
                      check
                      className="form-check-inline"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <small>
                        {`${notEncaminhar.length} oportunidades selecionadas`}
                      </small>
                      <div
                        className="custom-control custom-checkbox custom-checkbox-primary"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {/* @ts-ignore */}
                        <small check>Todas</small>
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          onClick={() => setCheckAll(!checkAll)}
                        />
                      </div>
                    </FormGroup>
                  </ListGroupItem>
                  {pageProperties?.data
                    ?.slice(page * 6, (page + 1) * 6)
                    ?.map((oportunidade: any, key: number) => (
                      <ListGroupItem
                        key={key}
                        className="checklist-entry flex-column align-items-start py-4 px-4"
                      >
                        <div className="checklist-item checklist-item-primary">
                          <div className="checklist-primary">
                            <h5 className="checklist-title mb-0">
                              {oportunidade.cliente.nome}
                            </h5>
                            <small>{`Colaborador: ${
                              oportunidade?.user?.name || ""
                            }`}</small>
                            <br />
                            <small>
                              {`Data de agendamento: ${
                                moment(oportunidade?.data_agendamento).format(
                                  "D/MM/YYYY, h:mm:ss a"
                                ) || ""
                              }`}
                            </small>
                          </div>
                          <div>
                            <FormGroup check inline>
                              <Input
                                type="checkbox"
                                style={{ borderColor: "#525f7f" }}
                                checked={notEncaminhar.includes(
                                  oportunidade.id
                                )}
                                onClick={() =>
                                  handleSelectItem(oportunidade.id)
                                }
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                </ListGroup>
                <small>{`${pageProperties?.data?.length} oportunidades carregadas`}</small>
                {pageProperties.data && (
                  <Pagination
                    pageProperties={{
                      ...pageProperties,
                      perPage: 6,
                      page: page + 1,
                      lastPage: Math.ceil(pageProperties.data.length / 6),
                    }}
                    load={(pageProperties: any) => {
                      setPage(pageProperties?.page - 1);
                    }}
                  />
                )}
              </>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Reagendar;
