import React, { useState, useEffect } from 'react';

import api from "../../../../services/api";

import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";

export default ({ notify, empresa, ...props }) => {

  const [urlNps, setUrlNps] = useState(null)
  const [urlGoogleAvaliavao, setUrlGoogleAvaliavao] = useState(null)
  const [erros, setErros] = useState({})

  useEffect(() => {
    if (!urlNps) loadUrlNps()
  }, [])


  function handleSaveUrl() {
    console.log("Método handleSaveUrl")
    update()
  }

  async function update() {
    console.log("Método Update")
    try {
      const response = await api.put(`/common/empresas/${empresa.id}/nps/updateurlnps`, {
        url_nps: urlNps,
        url_google_avaliacao: urlGoogleAvaliavao
      })
      updateErros(null)
      notify('success', 'Empresa alterada com sucesso')
      loadUrlNps()
    } catch (error) {
      console.log(error)
      if (notify) notify('danger', 'Não foi possível salvar empresa')
      updateErros(error.response.data)
    }
  }

  function updateErros(error) {
    if (error) {
      const errors = {}
      for (let e of error) {
        errors[e.field] = e.message
      }
      setErros(errors)
    } else {
      setErros({})
    }
  }

  async function loadUrlNps() {
    try {
      const response = await api.get(`/common/empresas/${empresa.id}`)
      if (response.data) {
        setUrlNps(response.data.url_nps)
        setUrlGoogleAvaliavao(response.data.url_google_avaliacao)
      }
    } catch (error) {
      console.log(error)
      notify('danger', 'Não foi possível carregar a Url NPS')
    }
  }

  return (
    <>
      <Row>
        <Col lg={12} md={12}>
          <FormGroup>
            {/* <>
              Aqui você pode configurar a url que será usada como link nas malas diretas do tipo NPS (Pesquisa de Satisfação).
              <br></br>
              Você deve inserir a url que corresponde à página responsável por realizar a pesquisa com seus clientes.
              <br></br>
              Use TAGs para parametizar dados que serão preenchidos automaticamente durante o processo de envio de uma Mala Direta do tipo NPS.
              <br></br>
              Ex. de Tags: {'{TAG_CLIENTE_ID}, {TAG_EMPRESA_ID}, {TAG_MALA_DIRETA_ID}'}
              <br></br>
              Ex. de Url: {'https://playnee.com/empresa/nps?cliente_id={TAG_CLIENTE_ID}&empresa_id={TAG_EMPRESA_ID}&mala_direta_id={TAG_MALA_DIRETA_ID}'}
            </> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example-number-input"
            >
              Url NPS
                        </label>
            <Input
              className="form-control"
              placeholder=""
              type="text"
              value={urlNps}
              onChange={({ target }) => setUrlNps(target.value)}
              disabled = {true}
            />
            <small class="text-danger">
              {erros.urlNps || ''}
            </small>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12}>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example-number-input"
            >
              Url Google Avaliação
                        </label>
            <Input
              className="form-control"
              placeholder=""
              type="text"
              value={urlGoogleAvaliavao}
              onChange={({ target }) => setUrlGoogleAvaliavao(target.value)}
            />
            <small class="text-danger">
              {erros.urlGoogleAvaliavao || ''}
            </small>
          </FormGroup>
        </Col>
      </Row>

      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          color="primary"
          type="button"
          className="btn-icon btn-3"
          onClick={() => handleSaveUrl()}
        >
          <span className="btn-inner--text">Salvar</span>
        </Button>
      </div>
    </>
  );
}
