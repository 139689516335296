import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import moment from 'moment'

import {
    chartExample3,
} from "../../../../../variables/charts";
import { Bar } from "react-chartjs-2";
import { SpinnerContainer } from '../styles'
import {
    Spinner,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
} from "reactstrap";

function ProjecaoOpotunidades({ notify, mes, ano, equipe, colaborador, campanha }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [loading, setLoading] = useState(false)
    const [emptyValue, setEmptyValue] = useState(null)

    useEffect(() => {
        load()
    }, [mes, ano, equipe, colaborador, campanha])


    async function load() {
        setLoading(true)
        try {
            const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/parcial-projecao-conversao-oportunidades-mes`, {
                params: {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    campanha_id: campanha
                }
            })
            setItens(await response.data)
        } catch (error) {
            console.error(error)
            notify('Não foi possível carregar grafico de campanhas')
        }
        setLoading(false)
    }

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function createChartData() {

        //console.log(itens)

        const datasets = []

        const labels = itens.map(item => moment(item.mesBase).format('MMM/YYYY'))

        const dataConvertidas = itens.map(item => parseFloat(item.oportunidades_convertidas_mes))
        const dataMesAnterior = itens.map(item => parseFloat(item.oportunidades_convertidas_mesanterior))
        const dataAnoAnterior = itens.map(item => parseFloat(item.oportunidades_convertidas_anoanterior))

        const dataTotal = itens.map(item => parseFloat(0))
        
        

        return {
            labels,
            datasets: [
                {
                    fill: false,
                    label: 'Convertidas',
                    borderColor: '#2dce89',
                    backgroundColor: '#2dce89',
                    data: dataConvertidas,
                    barPercentage: 0.5,
                    barThickness: 6,
                    maxBarThickness: 8,
                    minBarLength: 2
                },
                {
                    label: 'Projeção',
                    backgroundColor: '#5e72e4',
                    borderColor: '#5e72e4',
                    data: dataTotal,
                    fill: false,
                    type: 'line'
                },
                {
                    label: 'Mês Anterior',
                    backgroundColor: '#fbff00',
                    borderColor: '#fbff00',
                    data: dataMesAnterior,
                    fill: false,
                    type: 'line'
                },
                {
                    label: 'Ano Anterior',
                    backgroundColor: '#ff0800',
                    borderColor: '#ff0800',
                    data: dataAnoAnterior,
                    fill: false,
                    type: 'line'
                }

            ]
        }
    }


    return (
        <Card >
            <CardHeader>
                <h5 class="h3 mb-0">Projeção de Oportunidades</h5>
                <h6 c lass="surtitle">Oportunidades Convertidas, Comparadas à Projeção e aos Desempenhos do Mês Anterior e do Mesmo Período no Ano Anterior</h6>
            </CardHeader>
            <CardBody>
                <div className="">
                    {
                        loading ?
                            <SpinnerContainer>
                                <Spinner
                                    color='primary'
                                    size='lg'
                                    style={{
                                        alignSelf: 'center'
                                    }}
                                />
                            </SpinnerContainer>
                            :
                            <div className='chart'>
                                <Bar
                                    data={createChartData()}
                                    //options={chartExample3.options}
                                    //className="chart-canvas"
                                />
                            </div>
                    }
                </div>


            </CardBody>
        </Card>
    )
}

export default ProjecaoOpotunidades;
