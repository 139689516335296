import React, { useContext, useEffect, useRef, useState } from 'react';
import NotificationAlert from 'react-notification-alert';
import { Button, Col, Row } from 'reactstrap';

import api from '../../../../services/api';
import AdicionarGrupoModal from './AdicionarGrupoModal';
import Table from './Table';
import EmpresaContext from '../../../../contexts/Empresa';

export default function GrupoEmpresa({
  empresaId,
  history,
  hiddeTabs = {},
  externaTabActive,
  minhaEmpresa,
  ...props
}: any) {
  const { empresaSelecionada, empresas } = useContext(EmpresaContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [empresasGrupo, setEmpresasGrupo] = useState([]);
  const [pageProperties, setPageProperties] = useState({
    total: '0',
    perPage: 9999,
    page: 1,
    lastPage: 1,
    loading: false,
    sort: new Map([]),
  });
  const [lastSearch, setLastSearch] = useState({});

  const [modalVisible, setModalVisible] = useState(false);
  const notificationRef = useRef<NotificationAlert>(null);

  function notify(type, msg) {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    if (notificationRef.current)
      notificationRef.current.notificationAlert(options);
  }

  // async function updatePageProperties(response) {
  //   const { total, perPage, page, lastPage } = await response.data;
  //   setPageProperties({
  //     ...pageProperties,
  //     total,
  //     perPage,
  //     page,
  //     lastPage,
  //     loading: false,
  //   });
  // }

  async function fetchEmpresasGrupo() {
    try {
      const { data: empresas } = await api.get(
        `common/empresas/${empresaSelecionada?.id}/empresas-grupo`
      );

      setEmpresasGrupo(empresas)
    } catch (error) {
      console.error(error);
    }
  }

  // async function loadEmpresas(
  //   page = 1,
  //   limit = 10,
  //   sortField = 'created_at',
  //   sortOrder = 'desc',
  //   filters = lastSearch
  // ) {
  //   try {
  //     setPageProperties({
  //       ...pageProperties,
  //       loading: true,
  //     });
  //     const response = await api.get(`common/empresas/`, {
  //       params: {
  //         page,
  //         limit,
  //         sortField,
  //         sortOrder,
  //         ...filters,
  //       },
  //     });
  //     setEmpresas(response.data);
  //     await updatePageProperties(response);

  //   } catch (err) {
  //     console.log(err);
  //     notify('danger', 'Houve um problema ao carregar as empresas.');
  //   }
  // }

  // const handleTableChange = async (
  //   type,
  //   { page, sizePerPage, sortField, sortOrder }
  // ) => {
  //   try {
  //     if (type == 'sort') {
  //       const value = pageProperties.sort.get(sortField);
  //       const newOrder = (value || 'asc') == 'desc' ? 'asc' : 'desc';
  //       const map = pageProperties.sort;
  //       map.set(sortField, newOrder);
  //       sortOrder = newOrder;
  //     }
  //     await loadEmpresas(
  //       page == 0 ? 1 : page,
  //       sizePerPage,
  //       sortField,
  //       sortOrder
  //     );
  //   } catch (error) {
  //     notify('danger', 'Houve um problema ao carregar as empresas.');
  //   }
  // };

  useEffect(() => {
    fetchEmpresasGrupo();
  }, []);

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationRef} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <AdicionarGrupoModal
          // @ts-ignore
          empresas={empresas?.filter((empresa) => !empresasGrupo.some(eg => eg.id == empresa.id))}
          onSuccess={fetchEmpresasGrupo}
          show={modalVisible}
          notify={notify}
          onClose={() => setModalVisible(false)}
        />
        <Button
          color="primary"
          type="button"
          className="btn-icon btn-3"
          onClick={() => setModalVisible((prevState) => !prevState)}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-fat-add"></i>
          </span>
          <span className="btn-inner--text">Adicionar</span>
        </Button>
      </div>
      <Row>
        <Col>
          <Table
            //@ts-ignore
            empresas={empresasGrupo ? empresasGrupo : []}
            notify={notify}
            // onTableChange={handleTableChange}
            history={props.history}
            pageProperties={pageProperties}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
}
