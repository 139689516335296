import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

import api from "../../../../../../services/api";
import { SelecionarModeloModal } from "./modals/SelecionarModeloModal";
import useModal from "./modals/useModal";
import { EditarModeloModal } from "./modals/EditarModeloModal";
import EmpresaContext from "../../../../../../contexts/Empresa";

export interface ModeloSite {
  id: number;
  link: string;
  tipo_modelo: "afiliacao" | "indicacao";
  params_map: string;
}

interface ParametroSite {
  id: number;
  chave: string;
  valor: string;
  descricao: string;
  usuario_modelo_site_id: string;
}

interface ModeloSiteParams {
  id: string;
  modelo_site_id: number;
  parametroSite: ParametroSite[];
  site: ModeloSite;
}
export interface UsuarioModeloSite extends ModeloSiteParams {
  user_empresa_id: number;
}
export interface EmpresaModeloSite extends ModeloSiteParams {
  empresa_id: number;
}

interface PaginaIndicacaoProps {
  preview: string;
  link: string;
}

export interface Sites {
  afiliacao: UsuarioModeloSite;
  indicacao: PaginaIndicacaoProps
}

export function Paginas() {
  const [modelosSites, setModelosSites] = useState<ModeloSite[]>([]);
  const [paginaAfiliacao, setPaginaAfilicao] = useState<UsuarioModeloSite>();
  const [paginaIndicacao, setPaginaIndicacao] = useState<PaginaIndicacaoProps>();
  const [isModalVendedorVisible, setIsModalVendedorVisible] = useModal();
  const [isEditModalVendedorVisible, setIsEditModalVendedorVisible] =
    useModal();

  const { empresaSelecionada } = useContext(EmpresaContext);

  async function carregarModelosSites() {
    try {
      const { data: sites } = await api.get<ModeloSite[]>(
        "/marketing/modelos-sites"
      );
      setModelosSites(sites);
    } catch (error) {
      console.error(
        "Ocorreu um erro ao tentar carregar os modelos dos sites.\n",
        error
      );
    }
  }

  async function carregarSiteUsuario() {
    try {
      const { data } = await api.get<Sites>("/marketing/modelos-sites/usuario");

      setPaginaAfilicao(data.afiliacao);

      setPaginaIndicacao(data.indicacao);
    } catch (error) {
      console.error("Houve um erro ao carregar os dados do site.", error);
    }
  }

  useEffect(() => {
    carregarModelosSites();
    carregarSiteUsuario();
  }, []);

  const previewVendedor = paginaAfiliacao?.site?.link;

  return (
    <>
      <SelecionarModeloModal
        isOpen={isModalVendedorVisible}
        onClose={setIsModalVendedorVisible}
        pages={modelosSites.filter((site) => site.tipo_modelo == "afiliacao")}
        reload={carregarSiteUsuario}
        currentSiteId={paginaAfiliacao?.modelo_site_id}
        siteUsuario={paginaAfiliacao}
      />

      <EditarModeloModal
        isOpen={isEditModalVendedorVisible}
        onClose={setIsEditModalVendedorVisible}
        usuarioSite={paginaAfiliacao}
      />
      <Row>
        <Col>
          <h2>
            Minhas páginas na{" "}
            <span className="text-capitalize">
              {empresaSelecionada?.nome.toLowerCase()}
            </span>
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Card>
            <CardImg
              src={
                previewVendedor
                  ? previewVendedor + "preview.png"
                  : "https://internal.playnee.com/vendedor/capa.png"
              }
            />
            <CardBody>
              <CardTitle tag="h3">Página do Vendedor - Programa de Afiliação</CardTitle>
              <CardText>
                <Button
                  color="primary"
                  size="sm"
                  disabled={!paginaAfiliacao?.id}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      previewVendedor + `?UMS=${paginaAfiliacao?.id}`
                    );
                  }}
                >
                  Copiar Link
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  onClick={setIsModalVendedorVisible}
                >
                  Selecionar Modelo
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  onClick={setIsEditModalVendedorVisible}
                  disabled={!paginaAfiliacao?.id}
                >
                  Editar Modelo
                </Button>
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} sm={6}>
          <Card>
            <CardImg src={paginaIndicacao?.preview} />
            <CardBody>
              <CardTitle tag="h3">Programa de Indicação</CardTitle>
              <CardText>
                <Button
                  color="primary"
                  size="sm"
                  disabled={!paginaIndicacao}
                  onClick={() => {
                    navigator.clipboard.writeText(paginaIndicacao!.link);
                  }}
                >
                  Copiar Link
                </Button>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
