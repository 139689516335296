import React, { useState, useEffect, useContext } from 'react';
import { Oportunidade } from "../../../../entities/Marketing";
import { MotivoEncerramento } from "../../../../entities/Common";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
//@ts-ignore
import Select2 from "react-select2-wrapper";
import {
    Button,
    Modal,
    FormGroup,
} from "reactstrap";

interface IChecagem {
    oportunidade?: Oportunidade,
    onChecado(motivo: Number): void,
    notify(type: string, text: string): void
}

const Checagem: React.FC<IChecagem> = ({
    oportunidade,
    onChecado,
    notify
}) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [showModal, setShowModal] = useState(false)
    const [motivos, setMotivos] = useState<Array<MotivoEncerramento>>(new Array<MotivoEncerramento>())
    const [motivoId, setMotivoId] = useState<number | undefined>(oportunidade?.motivo_encerramento_id)

    useEffect(() => {
        if (motivos.length === 0) {
            loadMotivos()
        }
    }, [])

    useEffect(() => {
        setMotivoId(oportunidade?.motivo_encerramento_id)
    }, [oportunidade])

    useEffect(() => {
        setMotivoId(oportunidade?.motivo_encerramento_id)
    }, [showModal])

    function isNew() {
        return !(oportunidade && oportunidade.id)
    }

    async function loadMotivos() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/motivos-encerramentos`,
                {
                    params: {
                        aplicacoes: ['abertura', 'null']
                    }
                })
            setMotivos(await response.data)
        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível carregar motivos')
        }
    }

    async function handlerChecarOportunidade(e: React.MouseEvent) {
        e.preventDefault()
        if (!motivoId) return
        try {
            await api.post(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidade?.id}/checar-oportunidades`,
                {
                    motivo_encerramento_id: motivoId
                })
            if (notify) notify('success', 'Oportunidade Checada')
            setShowModal(false)
            onChecado(motivoId)
        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível checar oportunidade')
        }
    }

    function isMarcado() {
        return oportunidade?.motivoEncerramento?.aplicacao === 'abertura' ||
            (!oportunidade?.data_encerramento && !oportunidade?.motivoEncerramento?.aplicacao && oportunidade?.motivo_encerramento_id)
    }

    return (
        <>
            {
                !isNew() &&
                <Button
                    title={isMarcado() ?
                        oportunidade?.motivoEncerramento?.nome : "Adicione um ponto de checagem"}
                    color="link"
                    size='sm'
                    onClick={() => setShowModal(true)}>
                    <i className={isMarcado() ? 'fas fa-bookmark' : "far fa-bookmark"}></i>
                </Button>
            }
            <Modal
                className="modal-dialog-centered modal-success"
                size="md"
                isOpen={showModal}
                toggle={() => setShowModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Checar Oportunidade
                </h6>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="fas fa-question-circle ni-3x" />
                        <h4 className="heading mt-4"></h4>
                        <p>
                            Selecione o motivo pelo qual esta oportuniade não avançou
                  </p>
                        <FormGroup>
                            <Select2
                                className="form-control"
                                // @ts-ignore
                                onSelect={(e) => setMotivoId(e.target.value)}
                                options={{
                                    placeholder: 'Selecione um motivo...'
                                }}
                                value={motivoId}
                                data={motivos.map((item) => ({ id: item.id, text: item.nome }))}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setShowModal(false)}
                    >
                        Fechar
                </Button>
                    <Button
                        onClick={handlerChecarOportunidade}
                        className="btn-white"
                        color="default"
                        type="button">
                        Checar
                </Button>
                </div>
            </Modal>
        </>
    )
}

export default Checagem;