import React, { useState, useEffect } from "react";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import {
  Spinner,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import "./styles.css";

export default ({ itens, pageProperties, notify, ...props }) => {
  const headerStyle = { fontSize: 9 };
  const { ExportCSVButton } = CSVExport;

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (itens && itens.length > 0) {
      const cols = [
        ...new Set(
          itens
            .map((obj) => {
              return Object.entries(obj).map((e) => e[0]);
            })
            .flat()
        ),
      ];

      const newItens = [...itens];

      var newColumns = cols.map((motivo) => {
        console.log({ motivo });
        if (motivo == "colaborador") {
          return {
            dataField: "colaborador",
            text: "Cliente",
            sort: true,
            sortFunc: (a, b, order) => {
              if (order === "asc") return a.localeCompare(b);
              else return b.localeCompare(a);
            },
            formatter: (cell, row) => <>{row[motivo] ?? 0}</>,
            headerFormatter: () => (
              <>
                {motivo}
                <span
                  className="order-4 position-absolute"
                  style={{ translate: "26px 26px" }}
                ></span>
              </>
            ),
            headerAlign: "center",
            align: "center",
            headerStyle: (cell, row, rowIndex, colIndex) => ({
              position: "sticky",
              left: 0,
              zIndex: 1,
            }),
            style: (cell, row, rowIndex, colIndex) => ({
              position: "sticky",
              left: 0,
              backgroundColor: "#fff",
            }),
            footer: "Total",
            csvText: motivo,
            footerStyle: { position: "sticky", textAlign: "center", left: 0 },
          };
        }
        return {
          dataField: motivo,
          text: motivo,
          sort: true,
          formatter: (cell, row) => <>{row[motivo] ?? 0}</>,
          csvFormatter: (cell, row, rowIndex) => cell ?? 0,
          headerFormatter: () => (
            <>
              {motivo}
              <span
                className="order-4 position-absolute"
                style={{ translate: "24px 22px" }}
              ></span>
            </>
          ),
          headerAlign: "center",
          align: "center",
          headerStyle,
          csvText: motivo,
          footer: (columnData, index) => {
            console.log({ columnData });
            return columnData.reduce(
              (acc, item) => parseFloat(acc) + parseFloat(item ?? 0),
              0
            );
          },
          footerStyle: {
            textAlign: "center",
          },
        };
      });

      setColumns([...newColumns]);
    }
  }, [itens]);

  return (
    <>
      {itens.length && columns.length ? (
        // <Table
        //   columns={columns}
        //   data={itens}
        //   pageProperties={{ perPage: 5000 }}
        //   onTableChange={() => {}}
        // />
        <ToolkitProvider
          data={itens}
          columns={columns}
          keyField={"id"}
          exportCSV={{
            fileName: "custom.xls",
            separator: ";",
            noAutoBOM: false,
            blobType: "text/xls;charset=ansi",
          }}
        >
          {(props) => (
            <div className="py-4">
              <UncontrolledDropdown
                group
                size="sm"
                direction="down"
                style={{ position: "absolute", top: -15, right: 30 }}
              >
                <DropdownToggle color="link">
                  <i className="fas fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="#">
                    <ExportCSVButton
                      {...props.csvProps}
                      style={{
                        backgroundColor: "transparent",
                        padding: 0,
                        border: 0,
                        width: "100%",
                        color: "#212529",
                        textAlign: "start",
                      }}
                    >
                      <i className="fas fa-file-export"></i>
                      <span className="ml-1">Exportar</span>
                    </ExportCSVButton>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
                sort={{
                  sortFunc: (a, b, order) => {
                    if (order === "asc") return a - b;
                    else return b - a;
                  },
                }}
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {props.loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            "nenhum dado encontrado"
          )}
        </div>
      )}
    </>
  );
};
