import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";
import AuthContext from "../../../../contexts/Auth";
import moment from 'moment'
import {
    Publicacao,
    ComentarioPublicacao
} from '../../../../entities/Marketing'

import Avatar from '../../../../components/Avatar'
import {
    Button,
    Form,
    Input,
    Media,
    InputGroupAddon,
    InputGroup,
    Spinner,
} from "reactstrap";

interface IComentarios {
    publicacao: Publicacao,
    onError(msg: string): void
}

const Index: React.FC<IComentarios> = ({ publicacao, onError }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const { user } = useContext(AuthContext)
    const [comentarios, setComentarios] = useState(new Array<ComentarioPublicacao>())
    const [textoComentario, setTextoComentario] = useState('')
    const [saving, setSaving] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (publicacao && publicacao.id) loadComentarios()
    }, [publicacao])

    async function loadComentarios() {
        setLoading(true)
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacao.id}/comentarios`);
            const data = await response.data
            setComentarios(data)
        } catch (err) {
            onError("Não foi possível carregar as ponderações.")
        }
        setLoading(false)
    }

    async function handleEnviarComentario() {
        const dados = {
            publicacao_id: publicacao.id,
            texto: textoComentario,
            tipo: "atividade",
        }
        setSaving(true)
        try {
            await api.post(`/common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacao.id}/comentarios`, dados);
            setTextoComentario('')
            await loadComentarios()
        } catch (error) {
            onError("Não foi possível enviar essa ponderação.")
        }
        setSaving(false)
    }

    return (
        <>
            <div className="mb-1">
                <Media className="align-items-center">
                    <Avatar
                        user={user}
                        className='rounded-circle avatar-lg mr-2'
                    />
                    <Media body>
                        <Form>
                            <InputGroup>
                                <Input
                                    placeholder="Escreva seu comentário..."
                                    rows="3"
                                    type="textarea"
                                    value={textoComentario}
                                    onChange={({ target }) => setTextoComentario(target.value)}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button
                                        className="btn-icon btn-2 "
                                        outline
                                        disabled={saving || textoComentario.length === 0}
                                        onClick={handleEnviarComentario}
                                        title='Enviar'
                                        color="primary"
                                        type="button">
                                        {
                                            saving ?
                                                <Spinner
                                                    size='sm'
                                                    color='primary'
                                                />
                                                :
                                                <span className="btn-inner--icon">
                                                    <i className="ni ni-send" />
                                                </span>
                                        }
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Form>
                    </Media>
                </Media>
                <hr />
                {
                    comentarios.map((item, key) => (
                        <>
                            <Media className="media-comment" key={key}>
                                <Avatar
                                    user={item.user}
                                    className='media-comment-avatar rounded-circle'
                                />
                                <Media style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            flex: 1
                                        }}
                                        className="media-comment-text"
                                    >
                                        <h6 className="h5 mt-0">
                                            {item?.user?.name}
                                            <small className="ml-2 text-muted">
                                                {
                                                    moment(item.created_at).format('DD-MM-YYYY HH:mm')
                                                }
                                            </small>
                                        </h6>

                                        <p className="text-sm lh-160">
                                            <div dangerouslySetInnerHTML={{ __html: item.texto }} />
                                        </p>
                                        <div className="icon-actions">
                                            <span
                                                style={{
                                                    textTransform: 'capitalize',
                                                    fontSize: 12
                                                }}
                                                className={item.tipo === 'atividade' ? 'text-primary' : 'text-warning'}
                                            >
                                                {item.tipo}
                                            </span>
                                        </div>
                                    </div>
                                </Media>
                            </Media>
                        </>
                    ))
                }
            </div>
        </>
    )
}

export default Index;