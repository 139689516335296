import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Card,
  CardBody,
  CardTitle,
  CardText,
  ModalFooter,
  Button,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  InputGroupText,
} from "reactstrap";
import { MessageBubble } from "../../MsgBox/styles";
import { useWhatsappContext } from "../../../../../../contexts/Whatsapp";
import { IContact } from "../../../types";
import api from "../../../../../../services/api";

interface TemplateModalProps {
  isOpen: boolean;
  isNewContact?: boolean;
  toggle: () => void;
  waId?: string;
  clienteId?: number;
  name?: string;
}

export default function TemplateModal({
  isOpen,
  toggle,
  waId,
  clienteId,
  name,
  isNewContact = false,
}: TemplateModalProps) {
  const [templates, setTemplates] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numero, setNumero] = useState("");
  const [nomeContato, setNomeContato] = useState("");
  const [templateSelecionada, setTemplateSelecionada] = useState<any>(null);

  const { sendTemplateMessage, selectedAccount } = useWhatsappContext();

  async function sleep(delay = 500) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  async function handleSendTemplate() {
    if (!selectedAccount) return;
    const destinatario = bindDestinatario(
      numero,
      selectedAccount.id,
      isNewContact,
      name,
      clienteId
    );

    setIsLoading(true);
    try {
      sendTemplateMessage(templateSelecionada, destinatario);
      await sleep(1000);
    } catch (error) {
      console.error("Erro ao enviar template:", error);
    } finally {
      setIsLoading(false);
      toggle();
    }
  }

  function bindDestinatario(
    numero: string,
    account_id: string | number,
    isNewContact: boolean,
    name: string | undefined,
    clienteId: string | number | undefined
  ) {
    let cleanInput = numero.replace(/[^0-9]/g, "");
    let ddd = cleanInput.slice(0, 2);
    let num = cleanInput.slice(2);

    if (num.length > 8) {
      num = num.slice(1);
    }
    const cliente = {
      wa_id: `55${ddd}${num}`,
      account_id: account_id,
    };

    if (isNewContact) {
      cliente["name"] = nomeContato;
      cliente["cliente_id"] = null;
    } else {
      cliente["name"] = name;
      cliente["cliente_id"] = clienteId;
    }

    return cliente;
  }

  async function getMessageTemplates() {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `/meta/webhooks/whatsapp/${selectedAccount?.id}/templates`
      );

      setTemplates(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isOpen && selectedAccount) {
      getMessageTemplates();
      setNumero(waId ?? "");
    }

    return () => {
      setTemplateSelecionada(null);
      setNumero("");
      setNomeContato("");
    };
  }, [isOpen]);

  const disabled = (isNewContact && (!numero || !nomeContato ))|| !templateSelecionada;

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} scrollable>
      <ModalHeader toggle={toggle}>Mensagens Padrão</ModalHeader>

      <ModalBody className="pt-0">
        {isLoading && <Spinner size="sm" />}
        {templates.length === 0 && !isLoading && (
          <p>Nenhuma mensagem padrão encontrada.</p>
        )}
        {isNewContact && (
          <Row className="sticky-top bg-white">
            <Col lg={12} md={12}>
              <Label className="form-control-label">Nome do contato</Label>
              <InputGroup className="mb-3">
                <Input
                  placeholder="Digite o nome aqui..."
                  value={nomeContato}
                  onChange={(event) => setNomeContato(event.target.value)}
                />
              </InputGroup>

              <Label className="form-control-label">Novo número</Label>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>+ 55</InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Digite o número aqui..."
                  value={numero}
                  maxLength={11}
                  onChange={(event) => setNumero(event.target.value)}
                />
              </InputGroup>
            </Col>
          </Row>
        )}
        <div>
          {!isLoading &&
            templates?.map((template: any) => (
              <Card
                key={template.name}
                onClick={() => {
                  setTemplateSelecionada(template);
                }}
                style={{
                  cursor: "pointer",
                  border:
                    template?.name === templateSelecionada?.name
                      ? "2px solid blue"
                      : "2px solid transparent",
                }}
              >
                <CardBody>
                  <CardTitle>{template.name}</CardTitle>
                  <CardText>
                    <MessageBubble $isSent={true}>
                      <div
                        className="box--message"
                        style={{ position: "relative" }}
                      >
                        <p className="message--text">
                          {template.components[0]?.text}
                        </p>
                      </div>
                      <div className="box--timestamp">
                        <small className="message--timestamp">
                          {new Date().toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </small>
                      </div>
                    </MessageBubble>
                  </CardText>
                </CardBody>
              </Card>
            ))}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Voltar
        </Button>
        <Button
          color="primary"
          onClick={handleSendTemplate}
          disabled={disabled}
        >
          Enviar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
