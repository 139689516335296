import React, { useState, useEffect } from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { usePersistedState } from "../../../../hooks";

function SelectPreset(value, onChange, listaPresets, estagios) {
  const [preset, setPreset] = useState(value || null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  function changePreset(preset) {
    setPreset(preset);
  }

  useEffect(() => {
    if (preset) {
      onChange(preset);
    }
  }, [preset]);

  useEffect(() => {
    if (!value) {
      setPreset(null);
    }
  }, [value]);


  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <Dropdown
        style={{
          paddingRight: 4,
        }}
        group
        size="sm"
        direction="down"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle caret disabled={false} color={"primary"} style={{}}>
          <i className="fa fa-star" style={{ marginRight: 5 }} />
          {preset ? preset.nome : "Favoritos"}
        </DropdownToggle>
        <DropdownMenu right>
          {listaPresets.map((item, key) => {
            if (item.dados?.isNested) {
              return (
                <UncontrolledDropdown
                  size="sm"
                  direction="right"
                  style={{ display: "block" }}
                  group
                >
                  <DropdownItem divider />
                  <DropdownToggle
                    style={{
                      fontSize: "14px",
                      width: "100%",
                      textAlign: "initial",
                      backgroundColor: "transparent",
                      paddingLeft: "16px",
                      fontWeight: "500",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Por estágio</span>
                    <span>&#8250;</span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {estagios?.map((e, key) => {
                      return (
                        <DropdownItem
                          onClick={() => {
                            const newPreset = { ...item };
                            newPreset.dados.estagios = [e.id];
                            changePreset(newPreset);
                            toggle();
                          }}
                          key={key}
                        >
                          {e?.nome}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            }

            return (
              <DropdownItem
                key={key}
                onClick={(e) => {
                  e.preventDefault();
                  changePreset(item);
                }}
              >
                {item.nome}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default SelectPreset;
