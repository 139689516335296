import React, { useEffect } from 'react';
import { Acao } from '../../../../../../../entities/Mentoring';
import { User } from '../../../../../../../entities/Security';

import FieldQuem from "./FieldQuem";
import List from "../../../../../../../components/lists/Users";
import {
    Row,
    Col
} from "reactstrap";

type Props = {
    acao: Acao,
    users: Array<User>
    addUser(user: User): void,
    removeUser(user: User): void
}

const UserQuem: React.FC<Props> = ({ acao, users, addUser, removeUser }) => {


    return (
        <Row>
            <Col>
                <FieldQuem
                    acao={acao}
                    onSuccessInsert={addUser}
                    onSuccessDelete={removeUser}
                    usersAdded={users}
                />
                <List
                    itens={users}
                    handleDelete={removeUser}
                />


            </Col>
        </Row>
    )
}

export default UserQuem;