import React, { useState, useEffect } from 'react';
import api from "../../../../../../services/api";

import AddMotivoEncerramento from './AddMotivoEncerramento';
import List from './List';
import { Button } from "reactstrap";


export default ({ notify, empresa, ...props }) => {
    const [motivosEncerramentos, setMotivosEquipes] = useState([]);
    const [showAddMotivoEncerramento, setShowAddMotivoEncerramento] = useState(false);

    useEffect(() => {
        if (motivosEncerramentos.length === 0) loadMotivosEncerramentos();
    }, [empresa])

    async function loadMotivosEncerramentos() {
        try {
            const response = await api.get(`/common/empresas/${empresa.id}/motivos-encerramentos`);
            setMotivosEquipes(await response.data);
        } catch (error) {
            console.error(error);
            notify('danger', 'Não foi possível carregar os motivos');
        }
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center'
                }}
            >
                <AddMotivoEncerramento
                    empresa={empresa}
                    motivosEncerramentos={motivosEncerramentos.map(motivoEncerramento => motivoEncerramento.id)}
                    onSuccess={loadMotivosEncerramentos}
                    show={showAddMotivoEncerramento}
                    notify={notify}
                    close={() => setShowAddMotivoEncerramento(false)}
                />
                <Button
                    color="primary"
                    type="button"
                    className="btn-icon btn-3"
                    onClick={() => setShowAddMotivoEncerramento(!showAddMotivoEncerramento)}
                >
                    <span className="btn-inner--icon">
                        <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Adicionar</span>
                </Button>
            </div>
            <List
                motivosEncerramentos={motivosEncerramentos}
                empresa={empresa}
                reload={loadMotivosEncerramentos}
                notify={notify}
            />
        </>
    );
}
