import React, { useState } from 'react';
import Empresa from '../../../../../../entities/Common/Empresa';
import Pagamento from '../../../../../../entities/Common/Pagamento';
import api from '../../../../../../services/api';
import moment from 'moment';

import Form from "../Form";
import Frame from '../Frame'

import {
    Button,
    ListGroupItem,
    ListGroup,
    Row,
} from "reactstrap";
import { url } from 'inspector';


// import { Container } from './styles';

type Props = {
    pagamento: Pagamento,
    notify(type: string, msg: string): void,
    onDeleted(): void,
    empresa: Empresa
}

const Item: React.FC<Props> = ({
    pagamento,
    notify,
    onDeleted,
    empresa
}) => {

    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [showFrame, setShowFrame] = useState(false)

    function hideForm() {
        setShowForm(false)
    }

    function handleShowForm(event: React.MouseEvent) {
        event.preventDefault()
        setShowForm(true)
    }


    function hideFrame() {
        setShowFrame(false)
    }

    function handleShowFrame() {
        setShowFrame(true)
    }

    async function handleDelete() {
        // Exibindo uma mensagem para confirmação se quer deletar.
        const ok = window.confirm("Deseja realmente apagar este pagamento?")
        if (ok == false) return
        try {
            setLoading(true)
            await api.delete(`/Common/Empresas/${pagamento.empresa_id}/pagamentos/${pagamento.id}`)
            await onDeleted()

        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível remover o pagamento')
        } finally {
            setLoading(false)
        }
    }

    async function handleBotaoPagamento() {
        try {
            let url_api = pagamento.empresa_pagamento?.url_api

            console.log(url_api)

            if (url_api) {
                console.log('alterando url')
                url_api = url_api.replace('${orderId}', pagamento.pagamento_id)
            }
            console.log(url_api)

            window.open(url_api, "_blank")

        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível abrir a página de pagamento')
        }
    }

    async function handleBotaoPagamentoNovo() {
        try {
            const return_api = pagamento.return_api
            console.log({ return_api })

            //@ts-ignore
            const userInfo = JSON.parse(pagamento.return_api);
            //@ts-ignore
            const links = userInfo?.links
            console.log({ links })
            //@ts-ignore
            const linkAproval = links.filter(item => item.rel == 'approval_url')
            console.log({ linkAproval })

            const url = linkAproval[0].href
            console.log({ url })

            if (url) {
                console.log('Abrindo PayPal')
                window.open(url, "_blank")
            } else {
                notify('warning', 'Página de pagamento não encontrada')
            }


        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível abrir a página de pagamento')
        }
    }

    async function handleCheckOrder() {
        console.log('CHECAR PAGAMENTO FRONT')
        await api.get(`/Common/Empresas/${pagamento.empresa_id}/pagamentos/checarpagamento/${pagamento.id}`)

    }

    
    async function handleExecutarPagamento() {
        console.log('EXECUTAR PAGAMENTO FRONT')
        await api.get(`/Common/Empresas/${pagamento.empresa_id}/pagamentos/executarpagamento/${pagamento.id}`)

        onDeleted()

    }

    return (
        <>
            <Form
                empresa={empresa}
                hide={hideForm}
                notify={notify}
                show={showForm}
                pagamento={pagamento}
                success={onDeleted}
            />
            {/* <Frame
                empresa={empresa}
                hide={hideFrame}
                notify={notify}
                success={onDeleted}
                show={showFrame}
                pagamento={pagamento}
            /> */}
            {/* <Frame
            /> */}
            <ListGroupItem
                className="list-group-item-action px-0"
                style={{ flexDirection: 'row', display: 'flex', flex: 1, alignItems: 'center' }}
            >
                <Row
                    style={{ flex: 1 }}
                    className="align-items-center"
                    onClick={handleShowForm}
                >
                    <div className="col" style={{ marginLeft: 10 }}>
                        <h4 className="mb-0">
                            <a href="#" onClick={e => e.preventDefault()}>
                                {pagamento.nome}
                            </a>
                        </h4>
                        <small>
                            Meio de pagamento: {pagamento.empresa_pagamento?.nome}
                        </small>
                        <br />
                        <small>
                            Criado por: {pagamento.user?.name}
                        </small>
                        <br />
                        <small>
                            Gerado em: {moment(pagamento.created_at).format("DD/MM/YYYY HH:mm")}
                        </small>
                    </div>

                    <div className="col">
                        {pagamento.dt_pagamento ?
                            <>
                                <span
                                    className={`text-success mr-2`}
                                >
                                    ●
                                </span>
                                <small>
                                    Pago em: {moment(pagamento.dt_pagamento).format("DD/MM/YYYY HH:mm")}
                                </small>
                            </>
                            :
                            <>
                                <span
                                    className={`text-warning mr-2`}
                                >
                                    ●
                                </span>
                                <small>
                                    Pagamento Pendente
                                </small>
                            </>
                        }

                        <br />

                        {pagamento.dt_inicio_vigencia ?
                            <>
                                <span
                                    // valida se o pagamento ainda está vigente
                                    className={`text-${moment(new Date()).isBetween(moment(pagamento.dt_inicio_vigencia), moment(pagamento.dt_fim_vigencia)) ? 'success' : 'danger'} mr-2`}
                                >
                                    ●
                                </span>
                                <small>
                                    {`Vigência: ${moment(pagamento.dt_inicio_vigencia).format("DD/MM/YYYY")} a ${moment(pagamento.dt_fim_vigencia).format("DD/MM/YYYY")}`}

                                </small>
                            </>
                            :
                            <>
                                <span
                                    className={`text-warning mr-2`}
                                >
                                    ●
                                </span>
                                <small>
                                    Vigência ainda indefinida
                                </small>
                            </>
                        }

                        <br />

                        {pagamento.situacao === 'I' &&

                            <>
                                <span
                                    className={`text-danger mr-2`}
                                >
                                    ●
                                </span>
                                <small>Status: Inativo</small>
                            </>
                        }

                    </div>
                </Row>

                {/* <div className="col-auto">

                    {!pagamento.dt_pagamento && pagamento.empresa_pagamento?.url_logo &&
                        <div className="col" style={{ textAlign: 'right' }}>
                            <a href="#" onClick={handleBotaoPagamento}>
                                <img className="mr-4" src={pagamento.empresa_pagamento?.url_logo} alt={'Abrir Gestão de Pagamento do(a) ' + pagamento.empresa_pagamento?.nome} />
                            </a>

                        </div>
                    }

                </div> */}

                <div className="col-auto">


                    <Button
                        className="btn-sm"
                        disabled={loading || pagamento.dt_pagamento ? true : false || pagamento.status_checkout ? true : false}
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault()
                            // handleBotaoPagamento()
                            handleShowFrame();
                            //handleBotaoPagamentoNovo()
                        }}
                    >
                        <i className="fab fa-paypal" title={'Abrir ' + pagamento.empresa_pagamento?.nome}></i>
                    </Button>


                    <Button
                        className="btn-sm"
                        disabled={loading}
                        color="success"
                        onClick={(e) => {
                            e.preventDefault()
                            handleCheckOrder()
                        }}
                    >
                        <i className="fas fa-search" title='Checar Ordem de Pagamento'></i>
                    </Button>

                    <Button
                        className="btn-sm"
                        disabled={loading}
                        color="warning"
                        onClick={(e) => {
                            e.preventDefault()
                            handleExecutarPagamento()
                        }}
                    >
                        <i className="fas fa-search" title='Executar Pagamento'></i>
                    </Button>


                    <Button
                        className="btn-sm"
                        disabled={loading || pagamento.dt_pagamento ? true : false || pagamento.status_checkout ? true : false}
                        color="danger"
                        onClick={(e) => {
                            e.preventDefault()
                            handleDelete()
                        }}
                    >
                        <i className="fas fa-trash" title='Excluir Ordem de Pagamento'></i>
                    </Button>

                </div>

            </ListGroupItem>
            <Frame
                empresa={empresa}
                hide={hideFrame}
                notify={notify}
                success={handleExecutarPagamento}
                show={showFrame}
                pagamento={pagamento}
            />

        </>
    );
}

export default Item;