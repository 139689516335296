import React, { useState, useContext, useEffect, useRef } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from '../../../../services/api'
import { AxiosResponse } from 'axios';
import {
  useParams,
  useHistory,
} from "react-router-dom";
import { PlanoEstrategico } from '../../../../entities/Mentoring';
import { } from './styles';
//@ts-ignore
import Select2 from "react-select2-wrapper";
import ReactDatetime from "react-datetime";
import ReactQuill from "react-quill";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Spinner,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Input,
  CardHeader,
  Button
} from "reactstrap";
import { string } from 'prop-types';
import QuestoesAlvo from './QuestoesAlvo';
import { verificarTamanhoConteudo } from '../../../../utils';


export type FormError = {
  nome?: string,
  descricao?: string,
  data_inicio: Date,
  data_conclusao: Date
}

const Form: React.FC = ({ }) => {

  const { id: plano_estrategico_id } = useParams() as { id?: number }
  const history = useHistory()
  const { empresaSelecionada } = useContext(EmpresaContext)

  const [planoEstrategico, setPlanoEstrategico] = useState({} as PlanoEstrategico)
  const [nome, setNome] = useState<string>('')
  const [descricao, setDescricao] = useState<string>('')
  const [dataInicio, setDataInicio] = useState<any>()
  const [dataConclusao, setDataConclusao] = useState<any>()

  const [alert, setAlert] = useState<any>(undefined)
  const [erros, setErros] = useState<FormError>({} as FormError)
  const [saving, setSaving] = useState(false)

  const notificationAlert = useRef<NotificationAlert>(null)

  useEffect(() => {
    console.log(plano_estrategico_id)
    if (plano_estrategico_id) loadPlanoEstrategico()
  }, [plano_estrategico_id])

  useEffect(() => {
    setNome(planoEstrategico.nome)
    setDescricao(planoEstrategico.descricao)
    setDataInicio(planoEstrategico.data_inicio ? new Date(planoEstrategico.data_inicio) : null)
    setDataConclusao(planoEstrategico.data_conclusao ? new Date(planoEstrategico.data_conclusao) : null)
  }, [planoEstrategico])

  async function loadPlanoEstrategico() {
    try {
      const response = await api.get(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos/${plano_estrategico_id}`)
      const data = await response.data
      setPlanoEstrategico(data)
    } catch (error) {
      notify('error', 'Não foi possível carregar plano estratégico')
      console.error(error)
    }
  }

  async function handleSave() {
    if (
      verificarTamanhoConteudo(descricao) 
    ) {
      notify(
        "danger",
        "Por favor, reduza o tamanho das imagens ou o comprimento do conteúdo nos editores de texto."
      );
      return;
    }
    setErros({} as FormError)
    setSaving(true)
    if (planoEstrategico.id) {
      await update()
    } else {
      await insert()
    }
    setSaving(false)
  }

  function afterSave(msg: string) {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
        title={msg}
        onConfirm={() => history.goBack()}
        confirmBtnBsStyle="success"
        showConfirm={false}
        btnSize=""
      />
    );
    setTimeout(history.goBack, 2000);
  };

  async function insert() {
    try {
      const newPlano = bind()
      const response = await api.post(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos`,
        {
          ...newPlano
        })
      const data = await response.data
      setPlanoEstrategico(data)
      afterSave('Plano Estratégico Salvo');
    } catch (error) {
      console.error(error)
      notify('danger', 'Não foi possível salvar Plano Estratégico')
      //@ts-ignore
      throwError(error.response)
    } finally {
      setSaving(false)
    }
  }

  async function update() {
    try {
      const newPlano = bind()
      const response = await api.put(`mentoring/empresas/${empresaSelecionada?.id}/planos-estrategicos/${planoEstrategico.id}`,
        {
          ...newPlano,
        })
      //notify('success', 'Plano Estratégico alterado')      
      const data = await response.data
      setPlanoEstrategico(data)
      afterSave('Plano Estratégico Alterado');
    } catch (error) {
      console.error(error)
      notify('danger', 'Não foi possível alterar Plano Estratégico')
      //@ts-ignore
      throwError(error.response)
    } finally {
      setSaving(false)
    }
  }

  function throwError(response: AxiosResponse) {
    if (response && response.status == 400) {
      if (response.data.length) {
        const responseError = {} as FormError
        for (let e of response.data) {
          //@ts-ignore
          responseError[e.field] = e.message
        }
        setErros(responseError)
      }
    }
  }

  function bind(): PlanoEstrategico {
    const plano = {} as PlanoEstrategico;

    plano.nome = nome;
    plano.descricao = descricao;
    plano.data_inicio = dataInicio as Date;
    plano.data_conclusao = dataConclusao as Date;
    plano.empresa_id = empresaSelecionada?.id as number;

    return plano;
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {msg}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3
    };
    notificationAlert?.current?.notificationAlert(options);
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      {alert}
      <Container
        className='mt--6'
        fluid
      >
        <Card>
          <CardHeader
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
            }}>
            <h1>{planoEstrategico.id ? 'Alterar Plano Estratégico' : 'Novo Plano Estratégico'}</h1>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm={12} md={6} lg={6}
              >
                <FormGroup>
                  <label className="form-control-label" >
                    Nome*
                  </label>
                  <Input
                    placeholder='Nome...'
                    className="form-control"
                    value={nome}
                    onChange={e => setNome(e.target.value)}
                  />
                  <small className="text-danger">
                    {erros.nome || ""}
                  </small>
                </FormGroup>
              </Col>
            </Row>


            <Row>
              <Col sm={12} md={6} lg={6}>
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Data Início*
                  </label>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Selecione quando o plano inicia...",
                    }}
                    value={dataInicio}
                    dateFormat="DD/MM/YYYY"
                    timeFormat='HH:mm'
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        dataInicio &&
                        dataConclusao &&
                        dataInicio._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        dataInicio &&
                        dataConclusao &&
                        new Date(dataInicio._d + "") <
                        new Date(currentDate._d + "") &&
                        new Date(dataConclusao._d + "") >
                        new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        dataConclusao &&
                        dataConclusao._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={e => setDataInicio(e)}
                  />
                  {/* @ts-ignore */}
                  <small class="text-danger">
                    {erros.data_inicio || ''}
                  </small>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Data Final*
                  </label>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Selecione quando o plano termina...",
                    }}
                    value={dataConclusao}
                    dateFormat="DD/MM/YYYY"
                    timeFormat='HH:mm'
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        dataInicio &&
                        dataConclusao &&
                        dataInicio._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        dataInicio &&
                        dataConclusao &&
                        new Date(dataInicio._d + "") <
                        new Date(currentDate._d + "") &&
                        new Date(dataConclusao._d + "") >
                        new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        dataConclusao &&
                        dataConclusao._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={e => setDataConclusao(e)}
                  />
                  {/* @ts-ignore */}
                  <small class="text-danger">
                    {erros.data_conclusao || ''}
                  </small>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Descrição*
                  </label>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic"],
                        ["link", "blockquote"],
                        [
                          {
                            list: "ordered"
                          },
                          {
                            list: "bullet"
                          }
                        ]
                      ]
                    }}
                    value={descricao || ''}
                    onChange={e => setDescricao(e)}
                  />
                  {/* @ts-ignore */}
                  <small class="text-danger">
                    {erros.descricao || ''}
                  </small>
                </FormGroup>
              </Col>
            </Row>

            {(planoEstrategico && planoEstrategico.id > 0) &&

              <QuestoesAlvo
                planoEstrategico={planoEstrategico}
                notify={notify}
                history={history}
              >
              </QuestoesAlvo>

            }

            <Row>
              <Col>
                <div className="float-right ">
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    voltar
                  </Button>
                  <Button
                    disabled={saving}
                    color="primary"
                    onClick={handleSave}
                  >
                    {
                      <Spinner
                        hidden={!saving}
                        className="mr-2"
                        color="light"
                        size="sm"
                      />
                    }
                    Salvar
                  </Button>

                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container >
    </>
  )
}

export default Form;