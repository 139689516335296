import React, { useState, useEffect, useContext } from "react";
import EmpresaContext from "../../../../../contexts/Empresa";
import api from "../../../../../services/api";
import { SpinnerContainer } from "../styles";
import Select2 from "react-select2-wrapper";
import "./style.css";
import {
  Card,
  CardBody,
  CardHeader,
  Spinner,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap";

function FunilVendas({
  notify,
  mes,
  ano,
  equipe,
  colaborador,
  campanha,
  funil,
  setFunil,
  cardHeight,
}) {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [itens, setItens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [funilData, setFunilData] = useState([]);
  const [funisList, setFunisList] = useState([]);

  useEffect(() => {
    load();
  }, [mes, ano, equipe, colaborador, campanha, funil, empresaSelecionada]);

  useEffect(() => {
    createChartData();
  }, [itens]);

  useEffect(() => {
    (async () => {
      if (funisList.length === 0) await loadFunis();
    })();
  }, []);

  async function loadFunis() {
    const response = await api.get(
      `common/empresas/${empresaSelecionada?.id}/funis`
    );
    if (response.data) {
      response.data.unshift({ id: -1, nome: "- Todos -" });
      setFunisList(response.data);
    }
  }

  async function load() {
    setLoading(true);
    try {
      const response = await api.get(
        `/dashboards/empresas/${empresaSelecionada.id}/funis`,
        {
          params: {
            mes,
            ano,
            equipe,
            colaborador,
            campanha_id: campanha,
            funil_id: funil,
          },
        }
      );

      setItens(await response.data);
    } catch (error) {
      console.error(error);
      notify("Não foi possível carregar gráfico de campanhas");
    }
    setLoading(false);
  }

  function findColor(textColor) {
    if (textColor === "primary") {
      return "#5E72E4";
    } else if (textColor === "info") {
      return "#11CDEF";
    } else if (textColor === "danger") {
      return "#DE2E2E";
    } else if (textColor === "default") {
      return "#172b4d";
    } else if (textColor === "secondary") {
      return "#f4f5f7";
    } else if (textColor === "success") {
      return "#2dce89";
    } else if (textColor === "warning") {
      return "#fb6340";
    } else {
      return textColor;
    }
  }

  function createChartData() {
    var basePercentual = 0;
    try {
      basePercentual = itens[0].valor;
    } catch (error) {}

    const data = itens.map((item) => ({
      cor: item.cor,
      name: item.estagio.nome,
      value: parseInt(item.valor),
      percentual: parseFloat(((item.valor / basePercentual) * 100).toFixed(2)),
    }));


    const color = itens.map((item) => findColor(item.cor));
    setFunilData(data);
    const config = {
      pallette: data.map((e) => e.cor),
      data,
      chartHeight: 500,
      getToolTip: (row) => {
        return {};
      },
    };
    setOptions(config);
  }

  const calculateClipPathCoordinates = (index, totalItems) => {
    const topRightY = ((totalItems - index) / totalItems) * 100;
    const topLeftY = 100 - topRightY;
    const bottomRightY = ((totalItems - index - 1) / totalItems) * 100;
    const bottomLeftY = 100 - bottomRightY;

    return `0 0, 100% 0, 100% 100%, ${bottomRightY}% 100%`;
  };
  const getCRate = (index) => {
    const anterior = funilData[index - 1];
    const atual = funilData[index];
    if (!anterior || !atual) return parseFloat(0);
    if (anterior.value == 0 && atual.value == 0) return parseFloat(0);
    return (100 - (atual.value / anterior.value) * 100).toFixed(2);
  };

  const totalItems = funilData.length;

  return (
    <Card>
      <CardHeader>
        <h5 className="h3 mb-0">Funil de vendas</h5>
        <h6 className="surtitle">Funil de vendas das oportunidades</h6>
      </CardHeader>
      <CardBody>
        <div>
          <InputGroup className="input-group-alternative">
            <Select2
              className="input-group-alternative"
              defaultValue="-1"
              options={{
                placeholder: "Selecione um funil...",
              }}
              onSelect={({ target }) => setFunil(target.value)}
              value={funil}
              data={funisList.map((item) => ({ id: item.id, text: item.nome }))}
            />
          </InputGroup>
        </div>
        <div className="">
          {loading ? (
            <SpinnerContainer>
              <Spinner
                color="primary"
                size="lg"
                style={{ alignSelf: "center" }}
              />
            </SpinnerContainer>
          ) : (
            <div className="">
              {/* <FunnelChart {...options} /> */}
              <div
                className="funil-container"
                style={{
                  clipPath: `polygon(0 0, 100% 0, 60% 90%, 60% 100%, 40% 100%, 40% 90%)`,
                }}
              >
                {funilData.map((item, index) => {
                  const clipPathCoordinates = calculateClipPathCoordinates(
                    index,
                    totalItems
                  );

                  return (
                    <div
                      className="funil-step"
                      key={index}
                      id={`tooltip-${index}`}
                      style={{
                        opacity: item.value != 0 ? 1 : 0.5,
                        backgroundColor: item.cor,
                        width: `100%`,
                        cursor: "pointer",
                      }}
                    >
                      <div>
                        <p>
                          {item.value} ({Number(item.percentual) || 0}%)
                        </p>
                        <p> {item.name}</p>
                      </div>
                      <UncontrolledTooltip
                        placement="right"
                        target={`tooltip-${index}`}
                      >
                        taxa de perda {getCRate(index)}% em relação ao estágio
                        anterior
                      </UncontrolledTooltip>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default FunilVendas;
