export const userStorageKey = "@playnee-user";
export const authStorageKey = "@playnee-token";
export const empresaStorageKey = "@playnee-empresa";

export const user = JSON.parse(localStorage.getItem(userStorageKey));

export const setUser = (user) => {
  localStorage.setItem(userStorageKey, JSON.stringify(user));
};

export const hasPermission = (permission) => {
  const userLocal = JSON.parse(localStorage.getItem(userStorageKey));

  if (!userLocal) return false;
  let permissions = userLocal.roles
    .map((item) => item.permissions)
    .map((item) => item.find((it) => it.slug == permission));
  for (let p of permissions) {
    if (p && p.slug) return true;
  }
  return false;
};

export const bindErrors = (error) => {
  if (error && error instanceof Array) {
    const errors = {};
    for (let e of error) {
      errors[e.field] = e.message;
    }
    return errors;
  } else if (error.message) {
    return error.message;
  } else {
    return "Não foi possível realizar solicitação";
  }
};

export const getContrastYIQ = (hexcolor) => {
  var r = parseInt(hexcolor.substr(1, 2), 16);
  var g = parseInt(hexcolor.substr(3, 2), 16);
  var b = parseInt(hexcolor.substr(5, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  var color = yiq >= 128 ? "black" : "white";

  return color;
};

export function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";
  str += Object.keys(Object.entries(objArray)[0][1]).join(";");
  str += "\r\n";
  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ";";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
}
export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  console.log(items.length);
  var csv = convertToCSV(items);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function verificarTamanhoConteudo(htmlString, maxSize = 5 * 1024 * 1024) {
  // Calcular o tamanho do texto
  const tamanhoTexto = new Blob([htmlString]).size;

  // Criar um documento DOM temporário a partir da string HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Encontrar todas as imagens no conteúdo
  const imagens = doc.querySelectorAll("img");
  let tamanhoImagens = 0;

  imagens.forEach((img) => {
    // Para imagens em Base64
    if (img.src.startsWith("data:image")) {
      const parts = img.src.split(",");
      const decodedData = atob(parts[1]); // Decodifica Base64 para binário
      tamanhoImagens += new Blob([decodedData]).size;
    }
  });

  // Verifica se o conteúdo total excede o tamanho máximo
  const tamanhoTotal = tamanhoTexto + tamanhoImagens;
  // if (tamanhoTotal > 5242880) {
  //   return false;
  // }
  // return true;

  return tamanhoTotal > maxSize;
}