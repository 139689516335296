import React, { useState, useRef, useEffect, useContext } from "react";
//@ts-ignore
import classnames from "classnames";
import api from "../../../../../services/api";
import AuthContext from "../../../../../contexts/Auth";
import SimpleHeader from "../../../../../components/Headers/SimpleHeader";

import TipoProdutoForm from "./TiposProdutos";
import Produtos from "./Produtos";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import { HeaderContainer } from "./styles";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Nav,
  Row,
  Col,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import EmpresaContext from "../../../../../contexts/Empresa";

const ConfiguracoesVendas: React.FC<any> = ({ hiddeTabs, history, ...props }) => {

  const [empresa, setEmpresa] = useState({});
  const [tabActive, setTabActive] = useState("produtos");
  const [alert, setAlert] = useState(null);
  const notificationAlert = useRef<any>();
  const { empresaSelecionada } = useContext<any>(EmpresaContext)
  const { hasPermission, hasRole } = useContext(AuthContext);

  var externaTabActive = props.location.state?.externaTabActive ? props.location.state.externaTabActive : null;

  useEffect(() => {
    if (externaTabActive && externaTabActive.length > 0) {
      setTabActive(externaTabActive);
    }
  }, [externaTabActive]);

  const notify = (type:any, msg:string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text" style={{ zIndex: 100 }}>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    if (notificationAlert.current)
      notificationAlert.current.notificationAlert(options);
  };

  function onEmpresaChange(empresa:any) {
    setEmpresa(empresa ? empresa : {});
  }

  const CustomNavItem = ({ children, disabled, name }:any) => {
    return (
      <>
          <NavItem>
            <NavLink
              href="#"
              role="tab"
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabActive === name,
              })}
              onClick={() => setTabActive(name)}
              disabled={disabled}
            >
              {children}
            </NavLink>
          </NavItem>
        
      </>
    );
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <SimpleHeader name="Produtos" parentName="Minha empresa" />
      <div className="container-fluid mt--6">
        <Card>
          <CardHeader>
            <HeaderContainer>
                <h1>Produtos</h1>
            </HeaderContainer>
          </CardHeader>
          <CardBody outline body>
            <Row>
              <Col xs={12} lg={12} md={12} sm={12}>
                <div className="nav-wrapper">
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    pills
                    role="tablist"
                  >
                    <CustomNavItem name="produtos" disabled={!empresaSelecionada.id}>
                      <>
                        <i className="fas fa-box mr-2" />
                        Produtos
                      </>
                    </CustomNavItem>
                    <CustomNavItem name="tipo_produto" disabled={!empresaSelecionada.id}>
                      <>
                        <i className="fas fa-box mr-2" />
                        Tipos de produtos
                      </>
                    </CustomNavItem>
                  </Nav>
                </div>
                <hr className="mt-0" />
                <div>
                  <TabContent activeTab={tabActive}>
                  <TabPane tabId="produtos">
                      {empresaSelecionada.id && (
                        <Produtos
                          {...props}
                          history={history}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>
                    <TabPane tabId="tipo_produto">
                      {empresaSelecionada.id && (
                        <TipoProdutoForm
                          {...props}
                          history={history}
                          onEmpresaChange={onEmpresaChange}
                          notify={notify}
                          empresa={empresaSelecionada}
                        />
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
export default ConfiguracoesVendas;
