import React, { useState, useEffect } from 'react';

import classnames from "classnames";
import {
    Button,
    ButtonGroup,
} from "reactstrap";

function SelectRankingMode(value, onChange, periodo) {

    const [visoes] = useState([
        { id: 'periodo', text: 'Por Período' },
        { id: 'mensal', text: 'Mensal' }
    ])

    return (
        <>
            {periodo &&
                <ButtonGroup className="btn-group-toggle" size='sm' data-toggle="buttons" style={{margin: -5}}>
                    {
                        visoes.map((item, key) => (
                            <Button
                                className={classnames({ active: value == item.id })}
                                color="secondary"
                                onClick={() => onChange(item.id)}>
                                <input
                                    autoComplete="off"
                                    name="options"
                                    type="radio"
                                    value={value == item.id}
                                />
                                {item.text}
                            </Button>
                        ))
                    }
                </ButtonGroup>
            }
        </>
    )
}

export default SelectRankingMode;