import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  ListGroupItem,
  ListGroup,
  Spinner,
  Progress,
} from "reactstrap";
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import Pagination from "../../../../components/Pagination";
import EmpresaContext from "../../../../contexts/Empresa";
import { SubmitContainer } from "./styles";
// @ts-ignore
import Select2 from "react-select2-wrapper";
import ModalEncerrar from "./ModalEncerrar";

// import { Container } from './styles';

interface EncerrarProps {
  show: boolean;
  lastSearch: any;
  onClose(): void;
  onSuccess: any;
  notify: any;
  oportunidades: any | null;
}

interface oportunidadeProps {
  id: number;
}
const Encerrar: React.FC<EncerrarProps> = ({
  show,
  lastSearch,
  onClose,
  onSuccess,
  notify,
  oportunidades,
}) => {
  const [pageProperties, setPageProperties] = useState<any>({});
  const [notEncaminhar, setNotEncaminhar] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showModalEncerrar, setShowModalEncerrar] = useState(false);
  const [page, setPage] = useState(0);
  const [erros, setErros] = useState<any>({});
  const [alert, setAlert] = useState<any>(null);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    setNotEncaminhar([]);
    setPage(0);
    if (show) setPageProperties(oportunidades);
  }, [show]);

  useEffect(() => {
    if (checkAll) {
      setNotEncaminhar(
        pageProperties.data.map((oport: oportunidadeProps) => oport.id)
      );
    } else {
      setNotEncaminhar([]);
    }
  }, [checkAll]);

  function handleEncerrar() {
    setShowModalEncerrar(true);
  }

  function handleSelectItem(oportunidadeId: number) {
    let list = [...notEncaminhar];
    if (notEncaminhar.includes(oportunidadeId)) {
      const index = list.indexOf(oportunidadeId);
      list.splice(index, 1);
    } else {
      list.push(oportunidadeId);
    }
    setNotEncaminhar(list);
  }
  function onCloseEncerramento() {
    setShowModalEncerrar(false);
    onClose();
  }

  return (
    <>
      {show && (
        <Modal
          className="modal-dialog-centered"
          isOpen={show}
          toggle={onClose}
          size="lg"
        >
          <ModalHeader toggle={onClose}>
            <h5 className="modal-title" id="exampleModalLabel">
              Encerrar Oportunidades
            </h5>
          </ModalHeader>
          <ModalBody>
            {alert}
            {loading ? (
              <Progress animated value="100" color="info" />
            ) : (
              <>
                <ModalEncerrar
                  showModalEncerrar={showModalEncerrar}
                  onCloseEncerramento={onCloseEncerramento}
                  oportunidades={notEncaminhar}
                  notify={notify}
                  onSuccess={onSuccess}
                />
                <SubmitContainer>
                  <Button
                    onClick={handleEncerrar}
                    color="primary"
                    disabled={saving || notEncaminhar.length <= 0}
                  >
                    {saving && <Spinner size="sm" className="mr-2" />}
                    Encerrar
                  </Button>
                </SubmitContainer>
                <small className="text-danger">
                  {erros.oportunidades || ""}
                </small>
                <br />
                <ListGroup data-toggle="checklist" flush>
                  <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                    <FormGroup
                      check
                      className="form-check-inline"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <small>
                        {`${notEncaminhar.length} oportunidades selecionadas`}
                      </small>
                      <div
                        className="custom-control custom-checkbox custom-checkbox-primary"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {/* @ts-ignore */}
                        <small check>Todas</small>
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          onClick={() => setCheckAll(!checkAll)}
                        />
                      </div>
                    </FormGroup>
                  </ListGroupItem>
                  {pageProperties?.data
                    ?.slice(page * 6, (page + 1) * 6)
                    ?.map((oportunidade: any, key: number) => (
                      <ListGroupItem
                        key={key}
                        className="checklist-entry flex-column align-items-start py-4 px-4"
                      >
                        <div className="checklist-item checklist-item-primary">
                          <div className="checklist-primary">
                            <h5 className="checklist-title mb-0">
                              {oportunidade.cliente.nome}
                            </h5>
                            <small>{`Colaborador: ${
                              oportunidade?.user?.name || ""
                            }`}</small>
                          </div>
                          <div>
                            <FormGroup check inline>
                              <Input
                                type="checkbox"
                                style={{ borderColor: "#525f7f" }}
                                checked={notEncaminhar.includes(
                                  oportunidade.id
                                )}
                                onClick={() =>
                                  handleSelectItem(oportunidade.id)
                                }
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                </ListGroup>
                <small>{`${pageProperties?.data?.length} oportunidades carregadas`}</small>
                {pageProperties.data && (
                  <Pagination
                    pageProperties={{
                      ...pageProperties,
                      perPage: 6,
                      page: page + 1,
                      lastPage: Math.ceil(pageProperties.data.length / 6),
                    }}
                    load={(pageProperties: any) => {
                      setPage(pageProperties?.page - 1);
                    }}
                  />
                )}
              </>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Encerrar;
