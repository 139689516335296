import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";

import { Empresa } from "../../../../../entities/Common";
import EmpresaContext from "../../../../../contexts/Empresa";
import AuthContext from "../../../../../contexts/Auth";

//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
//@ts-ignore
import Select2 from "react-select2-wrapper";
import api from "../../../../../services/api";
import Users from "../Users";
import List from "../../Equipes/List";
import EquipeForm from "../../../../marketing/Campanhas/Anuncios/Equipes/New";
import EquipeList from "../../../../marketing/Campanhas/Anuncios/Equipes/ListGroup";

import {
  Button,
  Row,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  CardHeader,
  Container,
  Card,
  CardBody,
  CardFooter,
  Input,
  Spinner,
} from "reactstrap";
import { now } from "moment";

interface ErrorProps {
  cliente_id: number;
  nome: string;
  email: string;
  sexo: string;
  telefones: string;
  campanha_id: number;
  anuncio_id: number;
  funil_padrao_id: number;
  equipes: any;
}

interface Props {
  modalAberto: boolean;
  toggle(): void;
  colaboradorAnuncioSelecionado: any;
  onSuccess: any;
  notify(type: string, msg: string): void;
}
const AnuncioColaborador: React.FC<Props> = ({
  modalAberto,
  toggle,
  colaboradorAnuncioSelecionado,
  onSuccess,
  notify,
}) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [erros, setErros] = useState<ErrorProps>();
  const [campanhas, setCampanhas] = useState<any>([]);
  const [equipes, setEquipes] = useState<any>([]);
  const [anuncios, setAnuncios] = useState<any>([]);
  const [campanha, setCampanha] = useState<any>(null);
  const [anuncioNome, setAnuncioNome] = useState<any>(
    colaboradorAnuncioSelecionado.name
  );
  const [saving, setSaving] = useState(false);
  const [funil, setFunil] = useState(null);
  const [funis, setFunis] = useState([]);
  const [alert, setAlert] = useState<any>();

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: "calc(2.75rem + 2px)",
    }),
  };

  useEffect(() => {
    if (funis.length === 0) loadFunis();
  }, []);

  useEffect(() => {
    console.log({ colaboradorAnuncioSelecionado });
    loadCampanhas();
  }, []);

  async function loadFunis() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/funis`
      );
      setFunis(response.data);
      setTimeout(() => {
        if (response.data.length == 1) {
          setFunil(response.data[0].id);
        }
      }, 100);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar funis");
    }
  }

  useEffect(() => {
    if (campanha && campanha.id > 0) {
      loadAnuncios(campanha.id);
    } else {
      setAnuncios([]);
    }
  }, [campanha]);

  async function loadCampanhas() {
    const response = await api.get(
      `marketing/campanha/${empresaSelecionada?.id}`,
      {
        params: {
          situacao: "ativo",
          afiliacao: true,
        },
      }
    );
    setCampanhas(response.data);
  }

  async function loadAnuncios(campanha_id: any) {
    if (campanha_id) {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/campanhas/${campanha_id}/anuncios`,
        {
          params: {
            situacao: "ativo",
          },
        }
      );
      setAnuncios(response.data);
    }
  }

  async function handleSave() {
    setSaving(true);
    await insert();
    setSaving(false);
  }

  const successAlert = (msg: any) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
        title={msg}
        //@ts-ignore
        onConfirm={() => toggle()}
        confirmBtnBsStyle="success"
        showConfirm={false}
        btnSize=""
      />
    );
    //@ts-ignore
    setTimeout((e) => toggle(), 2000);
  };

  async function insert() {
    try {
      const dataHoje = new Date();
      await api.post(
        `/common/empresas/${empresaSelecionada?.id}/campanhas/${campanha.id}/anuncios-afiliados`,
        {
          nome: anuncioNome,
          dt_fim: new Date(dataHoje.setFullYear(dataHoje.getFullYear() + 10)),
          dt_inicio: new Date(),
          equipes: equipes.map((item: any) => item.equipe.id),
          orientacao_id: 2,
          situacao: "ativo",
          funil_padrao_id: funil,
          usuario_afiliado_id: colaboradorAnuncioSelecionado.id,
        }
      );
      successAlert("Anúncio adicionado com sucesso!");
    } catch (error) {
      console.error(error);
      if (notify) notify("danger", "Não foi possível adicionar anúncio");
      //@ts-ignore
      if (error.response) updateErros(error.response.data);
    }
  }

  async function loadEquipes() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/campanhas/${anuncios?.campanha_id}/anuncios/${anuncios?.id}/equipes`
      );
      setEquipes(await response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível buscar equipes");
    }
  }

  async function handleDelete(equipeAnuncio: any) {
    if (window.confirm(`Deseja remover a equipe?`)) {
      try {
        if (anuncios && anuncios.id) {
          await api.delete(
            `/common/empresas/${empresaSelecionada?.id}/campanhas/${anuncios?.campanha_id}/anuncios/${anuncios.id}/equipes/${equipeAnuncio.id}`
          );
          notify("success", "Equipe removida");
          await loadEquipes();
        } else {
          setEquipes(
            equipes.filter(
              (item: any) => item.equipe.id !== equipeAnuncio.equipe.id
            )
          );
          await loadEquipes();
        }
      } catch (error) {
        console.error(error);
        notify("danger", "Não foi possível remover Equipe");
      }
    }
  }

  async function onAddedEquipe(equipeAnuncio: any) {
    if (anuncios && anuncios.id) {
      await loadEquipes();
    } else {
      const itens = [...equipes];
      itens.push(equipeAnuncio);
      setEquipes(itens);
    }
  }

  //@ts-ignore
  function updateErros(error) {
    if (error) {
      const errors = {};
      for (let e of error) {
        //@ts-ignore
        errors[e.field] = e.message;
      }
      setErros(error);
    } else {
      setErros(error);
    }
  }
  return (
    <>
      {alert}
      <Modal
        // size="lg"
        className="modal-dialog-centered p-0"
        isOpen={modalAberto}
        toggle={toggle}
        backdrop
      >
        <ModalBody className="p-0">
          <Container className="p-0">
            <Card className="m-0">
              <CardHeader>
                <h2>Novo anúncio do colaborador</h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <Row>
                      <Col sm="12" md="12" lg="12">
                        <FormGroup>
                          <legend className="w-auto" style={{ margin: 0 }}>
                            <label className="form-control-label">
                              Campanha*
                            </label>
                          </legend>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            styles={customStyles}
                            placeholder={
                              campanhas.length
                                ? "Selecione uma Campanha"
                                : "Não existe campanha de afiliação."
                            }
                            options={campanhas.map((item: any) => ({
                              value: item.id,
                              label: item.nome,
                              id: item.id,
                            }))}
                            value={campanha}
                            onChange={(e) => {
                              setCampanha(e);
                            }}
                          />
                          <small className="text-danger">
                            {erros?.campanha_id ? erros.campanha_id : ""}
                          </small>
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="12" lg="12">
                        <FormGroup>
                          <legend className="w-auto" style={{ margin: 0 }}>
                            <label className="form-control-label">
                              Anúncio*
                            </label>
                          </legend>
                          <Input
                            className="form-control"
                            // placeholder={colaboradorAnuncioSelecionado.name}
                            disabled={!campanha}
                            type="text"
                            value={anuncioNome}
                            onChange={(e) => setAnuncioNome(e.target.value)}
                          />
                          <small className="text-danger">
                            {erros?.anuncio_id ? erros.anuncio_id : ""}
                          </small>
                        </FormGroup>
                        <Row>
                          <Col sm="12" md="12" lg="12">
                            <FormGroup>
                              <label className="form-control-label">
                                Funil*
                              </label>
                              <Select2
                                onSelect={(e: any) => {
                                  setFunil(e.target.value);
                                  console.log(e.target.value);
                                }}
                                options={{
                                  placeholder: "Selecione o funil...",
                                }}
                                disabled={!campanha}
                                value={funil}
                                data={funis.map((item: any) => ({
                                  id: item.id,
                                  text: item.nome,
                                }))}
                              />
                              <small className="text-danger">
                                {erros?.funil_padrao_id || ""}
                              </small>
                            </FormGroup>
                          </Col>
                          <Col sm="12" md="12" lg="12">
                            <EquipeForm
                              equipesAdicionadas={equipes}
                              anuncio={anuncios}
                              onAddedEquipe={onAddedEquipe}
                            />
                            <small className="text-danger">
                              {erros?.equipes || ""}
                            </small>
                            <EquipeList
                              equipes={equipes}
                              handleDelete={handleDelete}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="float-right">
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggle}
                  >
                    Voltar
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={saving}
                    onClick={handleSave}
                  >
                    {saving && (
                      <Spinner size="sm" color="secondary" className="mr-2" />
                    )}
                    Salvar e Fechar
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AnuncioColaborador;
