import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import api from "../../../../../../../services/api";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";
import { Button, FormGroup, Modal, Row, Col } from "reactstrap";
import EmpresaContext from "../../../../../../../contexts/Empresa";
import { Equipe } from "../../../../../../../entities/Common";
import "./styles.css";
interface EquipeSelecionada {
  value: string | number;
  label: string;
}

export default function AddEquipeModal({ close, show, empresaId, accountId }) {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [equipesDaAccount, setEquipesDaAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [equipeSelecionada, setEquipeSelecionada] =
    useState<EquipeSelecionada | null>(null);
  const [equipes, setEquipes] = useState<Equipe[]>([]);
  const notificationAlert = useRef();

  const [columns, setColumns] = useState([
    {
      dataField: "acoes",
      formatter: (cell, row) => acoesFormatter(cell, row),
    },
    {
      dataField: "nome",
      text: "Nome",
      sort: true,
    },
  ]);

  async function loadEquipes() {
    try {
      const { data } = await api.get<Equipe[]>(
        `/common/empresas/${empresaSelecionada?.id}/equipes`
      );
      const equipesCaptadoras = data.filter(
        // @ts-ignore
        (equipe) => equipe.captadora === "A"
      );
      setEquipes(equipesCaptadoras);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar equipes");
    }
  }

  async function loadEquipesDaAccount(account_id: string) {
    try {
      // buscar todas as equipes de uma account específica
      // essas equipes serão exibidas na tabela
      setIsLoading(true);
      const { data } = await api.get(`/meta/accounts-equipes/${account_id}`);

      setEquipesDaAccount(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!accountId) return;
    loadEquipesDaAccount(accountId);
  }, [accountId]);

  useEffect(() => {
    loadEquipes();
  }, []);

  const equipeOptions = equipes?.filter(
    (eq) =>
      !equipesDaAccount.find(
        // @ts-ignore
        (eqa) => eqa.equipe_captacao_id == eq.id
      )
  );

  /**
   * remove a linha na tabela meta.accounts_equipes_captacao
   */

  async function removerEquipeDeAccount(equipe: any) {
    try {
      setIsLoading(true);
      await api.delete(`/meta/accounts-equipes/${equipe.account_id}/ec/${equipe.equipe_captacao_id}`);
      loadEquipesDaAccount(equipe.account_id);
      notify("success", "Equipe removida.");
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível remover colaborador");
    }
    setIsLoading(false);
  }

  /**
   * inserir uma linha na tabela meta.accounts_equipes_captacao
   */
  async function addEquipeToAccount() {
    if (!equipeSelecionada) return;
    try {
      setIsLoading(true);
      await api.post("/meta/accounts-equipes", {
        account_id: accountId,
        equipe_captacao_id: equipeSelecionada.value,
      });
      setEquipeSelecionada(null);
      // Recarregar os dados da tabela
      loadEquipesDaAccount(accountId);
      notify("success", "Equipe adicionada.");
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível adicionar equipe");
    } finally {
      setIsLoading(false);
    }
  }

  function acoesFormatter(row, cell) {
    return (
      <>
        <div className="btn-group" role="group" aria-label="Basic example">
          <Button
            className="btn-sm"
            color="danger"
            onClick={() => removerEquipeDeAccount(cell)}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </div>
      </>
    );
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert?.current)
      //@ts-ignore
      notificationAlert.current.notificationAlert(options);
  }

  /**
   * Estilos para o componente de Select
   */
  const customStyles = {
    container: (provided) => ({ ...provided, flex: 1 }),
    option: (provided) => ({ ...provided, zIndex: 100, fontSize: "14px" }),
    menu: (provided) => ({ ...provided, zIndex: 100 }),
    control: (provided) => ({
      ...provided,
      minHeight: "36px",
      fontSize: "14px",
    }),
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>

      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={show}
        toggle={close}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Equipes na Account
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={close}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <>
            <Row>
              <Col
                lg={12}
                md={12}
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                  display: "flex",
                }}
              >
                <FormGroup className="w-75">
                  <label
                    className="form-control-label"
                    htmlFor="example-number-input"
                  >
                    Equipe
                  </label>
                  <div className="mb-3">
                    <Select
                      placeholder="Selecione a Equipe"
                      styles={customStyles}
                      onChange={(item) => {
                        setEquipeSelecionada(item);
                      }}
                      value={equipeSelecionada}
                      options={equipeOptions?.map((item) => ({
                        value: item.id,
                        label: item.nome,
                      }))}
                    />
                  </div>
                </FormGroup>
                <div>
                  <Button
                    color="primary"
                    type="button"
                    outline
                    onClick={addEquipeToAccount}
                    className="btn-icon btn-3"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add"></i>
                    </span>
                    <span className="btn-inner--text">Adicionar</span>
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="py-4 table-responsive">
                <ToolkitProvider
                  data={equipesDaAccount}
                  keyField="nome"
                  // @ts-ignore
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div className="py-4 custom-table-height">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </>
        </div>
        <div className="modal-footer">
          <Row>
            <Col>
              <div className="float-right ">
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={close}
                >
                  Fechar
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
