import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center
`;

