import React, { useEffect, useState, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";

import SelectDesigner from '../SelectDesigner'
import Avatar from '../../../../components/Avatar'
import {
    Button,
} from "reactstrap";


export default ({ designer, publicacao, onDesignerChanged, notify }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [nome, setNome] = useState(null)
    const [showSelectDesigner, setShowSelectDesigner] = useState(false)
    const [designers, setDesigners] = useState([])

    useEffect(() => {
        if (designers.length === 0) loadDesigners()
    }, [])

    async function loadDesigners() {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/designers-disponiveis`)
            setDesigners(response.data)
        } catch (err) {
            notify('danger', 'Não foi possível carregar designers da empresa.')
        }
    }

    async function changeDesigner(data) {
        try {
            await api.patch(`/common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacao.id}/designers`, {
                designer_responsavel_id: data.id
            })
            notify('success', 'Designer alterado')
            setShowSelectDesigner(false)
            onDesignerChanged(data)
        } catch (err) {
            if (err.response) {
                switch (err.response.status) {
                    case 403:
                        notify('danger', 'Você não pode realizar essa operação.')
                        break;
                    default:
                        notify('danger', 'Não foi possível realizar alteração.')
                        break;
                }
            } else {
                notify('danger', 'Não foi possível realizar alteração.')
            }

        }
    }

    return (
        <>
            {
                designer && designer.id ?
                    <>
                        <Button
                            title='Designer'
                            style={{
                                borderRadius: 100,
                                padding: "0 2px 0 2px",
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'center'
                            }}
                            onMouseEnter={() => {
                                setNome(designer.name)
                            }}
                            onMouseLeave={() => {
                                setNome(null)
                            }}
                            onClick={() => {
                                setShowSelectDesigner(true)
                            }}
                            size={'sm'}
                            color="secondary"
                        >
                            <Avatar user={designer} className={` avatar-xs ${nome ? 'mr-2' : ''}`} />
                            <div style={{ alignSelf: 'center' }}>
                                {nome}
                            </div>
                        </Button>
                    </>
                    :
                    <>
                        <Button
                            size={'sm'}
                            color="link"
                            onClick={() => {
                                setShowSelectDesigner(true)
                            }}
                            title='Adicione um designer a essa publicação'
                        >
                            <div
                                style={{ alignSelf: 'center', textDecoration: 'underline' }}
                                className='text-muted'>
                                Designer
                            </div>
                        </Button>
                    </>
            }

                <SelectDesigner
                    show={showSelectDesigner}
                    onHidden={() => setShowSelectDesigner(false)}
                    desigerSelecionado={designer ? designer.id : null}
                    designers={designers}
                    onConfirm={changeDesigner}
                />
        </>
    );
}
