import React, { useState, useEffect, useRef, useContext } from 'react';
import EmpresaContext from "../../../../../../contexts/Empresa";
import api from "../../../../../../services/api";
import { bindErrors } from "../../../../../../utils";

import Select2 from "react-select2-wrapper";
import NotificationAlert from "react-notification-alert";
import {
    Button,
    FormGroup,
    Row,
    Col,
    UncontrolledPopover,
    PopoverBody
} from "reactstrap";

function New({ equipesAdicionadas, onAddedEquipe, anuncio }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [equipes, setEquipes] = useState([])
    const [equipe, setEquipe] = useState(null)
    const [saving, setSaving] = useState(false)

    const [erros, setErros] = useState({})
    const notificationAlert = useRef()

    useEffect(() => {
        loadEquipes()
    }, [equipesAdicionadas])

    async function loadEquipes() {
        setEquipe(null)
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada.id}/equipes?captadora=A`)
            const list = await response.data
            //carrega apenas as equipes que não foram adicionadas a campanha
            setEquipes(list.filter(equipe => !equipesAdicionadas.find(item => item.equipe.id == equipe.id)))
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível realisar consulta de equipes')
        }
    }

    const notify = (type, msg) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text" style={{ width: '100%' }}>
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            closeButton: false,
            autoDismiss: 3
        };
        if (notificationAlert.current) notificationAlert.current.notificationAlert(options);
    };


    async function addEquipe() {
        try {
            if (anuncio && anuncio.id) {
                await api.post(`/common/empresas/${empresaSelecionada.id}/campanhas/${anuncio.campanha_id}/anuncios/${anuncio?.id}/equipes`,
                    {
                        equipe_id: equipe
                    })
                onAddedEquipe()
            } else {
                if (onAddedEquipe) {
                    const find = equipes.find(item => item.id == equipe)
                    onAddedEquipe({ equipe: { ...find } })
                }
            }
            setErros({})
        } catch (error) {
            if (error.response && error.response.data) {
                const erros = bindErrors(error.response.data)
                erros instanceof String ? notify('danger', erros) : setErros(erros)
            } else {
                notify('danger', 'Não foi possível adicionar equipe')
            }
        }
    }

    return (
        <>

            <NotificationAlert ref={notificationAlert} />
            <>
                <Row>
                    <Col lg={12} md={12}
                    >
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="example-number-input"
                            >
                                Equipe
                                <Button
                                    color="secondary"
                                    id="tooltip-998822"
                                    outline
                                    size="sm"
                                    type="button">
                                    ?
                         </Button>
                                <UncontrolledPopover placement="top" target="tooltip-998822">
                                    <PopoverBody>
                                        Adicione equipes de captação ao seu anúncio para distribuir as oportunidades geradas entre os seus colaboradores
                            </PopoverBody>
                                </UncontrolledPopover>
                            </label>

                            <Select2
                                maximumInputLength={100}
                                className="form-control"
                                onSelect={(e) => setEquipe(e.target.value)}
                                data={equipes.map(item => ({ text: item.nome, id: item.id }))}
                                value={equipe}
                            />
                            <small class="text-danger">
                                {erros.equipe_id || ''}
                            </small>
                        </FormGroup>
                        <div
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                flex: 1,
                                display: 'flex'
                            }}>
                            <div>
                                <Button
                                    color="primary"
                                    type="button"
                                    outline
                                    onClick={addEquipe}
                                    className="btn-icon btn-3"
                                >
                                    <span className="btn-inner--icon">
                                        <i className="ni ni-fat-add"></i>
                                    </span>
                                    <span className="btn-inner--text">Adicionar</span>
                                </Button>

                            </div>
                        </div>

                    </Col>
                </Row>
            </>

        </>
    )
}

export default New;