import { QRCodeSVG } from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import EmpresaContext from '../../../../contexts/Empresa';
import IAnuncio from '../../../../entities/Marketing/Anuncio';
import { ApiAccess, User } from '../../../../entities/Security';
import api from '../../../../services/api';

interface PaginaProgramaIndicacao {
  indicacao: { preview: string; link: string };
}

interface Props {
  colaborador: User;
  setLink(value: string): void;
}
const CartaoColaborador: React.FC<Props> = ({ colaborador, setLink }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [apiAccess, setApiAccess] = useState<ApiAccess>({} as ApiAccess);
  const [linksiteIndicacao, setLinkSiteIndicacao] = useState<any>(null);
  const [anuncioColaborador, setAnuncioColaborador] = useState<IAnuncio>();

  useEffect(() => {
    loadAnuncioColaborador();
    loadApiAccess();
    carregarSiteUsuario();
  }, []);

  useEffect(() => {
    setLink(getLink());
  }, [linksiteIndicacao, apiAccess, anuncioColaborador]);

  async function loadApiAccess() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/api-access`
      );
      setApiAccess(response?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: "calc(2.75rem + 2px)",
    }),
  };

  function getLink() {
    return linksiteIndicacao;
  }

  async function carregarSiteUsuario() {
    try {
      const {
        data: { indicacao },
      } = await api.get<PaginaProgramaIndicacao>(
        "/marketing/modelos-sites/usuario"
      );

      setLinkSiteIndicacao(indicacao.link);
    } catch (error) {
      console.error("Houve um erro ao carregar os dados do site.", error);
    }
  }
  async function loadAnuncioColaborador() {
    const response = await api.get(
      `marketing/anuncios/usuario-afiliado-id/${colaborador.id}`
    );
    setAnuncioColaborador(response.data.length ? response.data[0].id : null);
  }

  return (
    <>
      <Container className="p-0">
        <Card className="mt-3">
          <CardBody>
            {!anuncioColaborador && (
              <h2>
                Não existe um anúncio do programa de indicação configurado para
                o colaborador(a)
                {/* aaaa */}.
              </h2>
            )}

            {anuncioColaborador && (
              <>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <Card
                      className="m-auto"
                      style={{
                        width: "250px",
                        background: "linear-gradient(10deg, #cbf7ff, #12ccf0)",
                        height: "406px",
                        padding: "16px",
                        filter: "drop-shadow(2px 2px 4px #c1c1c1)",
                      }}
                    >
                      <Row>
                        <img
                          className="mb-3"
                          src={colaborador.avatar_url}
                          style={{
                            borderRadius: "98px",
                            width: "128px",
                            height: "128px",
                            objectFit: "cover",
                            margin: "auto",
                          }}
                        />
                      </Row>
                      <Row className="m-auto">
                        <h2>{colaborador.name}</h2>
                      </Row>
                      <Row>
                        <Col sm="12" md="12" lg="12" className="text-center">
                          <QRCodeSVG value={getLink()} />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <img
                          className="mb-3"
                          src={empresaSelecionada?.logo_url}
                          style={{
                            width: "100px",
                            maxHeight: "100px",
                            margin: "auto",
                            objectFit: "contain",
                          }}
                        />
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
export default CartaoColaborador;
