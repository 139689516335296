import { ListGroupItem } from "reactstrap";
import styled from "styled-components";

interface Props {
  isSelected: boolean;
}

export const Container = styled(ListGroupItem)<Props>`
  cursor: pointer;
  padding: 8px 4px;
  min-height: 72px;
  font-size: 0.85rem;

  background-color: ${({ isSelected }) => (isSelected ? "#cecece40" : "#fff")};

  .drop-toggle {
    border: none;
    background: transparent;
    box-shadow: none;
    margin-right: .5rem;
  }

  .drop-menu {
    width: 400px;
  }

  .contact {
    &--info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }

    &-name {
      margin-right: 8px;
      font-weight: 600;
    }

    &-badge {
      color: #fff;
    }

    &-message {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: .75rem;
      font-weight: normal;
    }
  }
`;

export const FilterChip = styled.button`
  padding-block: 6px;
  border-top-left-radius: 42px;
  border-bottom-right-radius: 42px;
  border-top-right-radius: 42px;
  border-bottom-left-radius: 42px;
  font-weight: 400;
  padding-right: 12px;
  border-left-width: 0;
  font-family: inherit;
  border-right-width: 0;
  border-top-width: 0;
  font-size: .75rem;
  cursor: pointer;
  padding-left: 12px;
  line-height: 1.333;
  transition: all .2s ease-in;
  background-color: #e5e5e5;
  color: #4b4b4c;
  position: relative;

  &.current {
    background-color: #16caee;
    color: #fff;
  }
`