import React from 'react';
import { Container } from 'reactstrap';

import CustomCalendar from '../../../components/FullCalendar';
import SimpleHeader from '../../../components/Headers/SimpleHeader';

const Calendario: React.FC = ({ props }: any) => {
  return (
    <>
      <SimpleHeader name="Calendário" parentName="Vendas" {...props} />
      <Container className="mt--6" fluid>
        <CustomCalendar />
      </Container>
    </>
  );
};

export default Calendario;
