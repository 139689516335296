import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  SetStateAction,
} from "react";
import api from "../../../../../services/api";
import EmpresaContext from "../../../../../contexts/Empresa";
import { useHistory } from "react-router-dom";
import Select from "react-select";

//@ts-ignore
import Select2 from "react-select2-wrapper";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
//@ts-ignore
import InputMask from "react-input-mask";

import {
  Spinner,
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  CardFooter,
  CardHeader,
  CardBody,
  Card,
} from "reactstrap";
import { user } from "../../../../../utils";
import AuthContext from "../../../../../contexts/Auth";
import IAnuncio from "../../../../../entities/Marketing/Anuncio";
import ICampanha from "../../../../../entities/Marketing/Campanha";

interface Props {
  anuncioId: number;
  clienteIndicadorId: number;
  hideModal(): void;
  data: any;
}
interface ErrorProps {
  cliente_id: number;
  nome: string;
  email: string;
  sexo: string;
  telefones: string;
  campanha_id: number;
  anuncio_id: number;
}
const Form: React.FC<Props> = ({
  anuncioId,
  clienteIndicadorId,
  hideModal,
  data,
}) => {
  const { user } = useContext(AuthContext);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [cliente, setCliente] = useState<any>();
  const [erros, setErros] = useState<ErrorProps>();
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState<any>();
  const history = useHistory();

  const [nome, setNome] = useState<any>();
  const [sexo, setSexo] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [telefone, setTelefone] = useState<any>(null);
  const [campanhas, setCampanhas] = useState<any>([]);
  const [anuncios, setAnuncios] = useState<any>([]);
  const [campanha, setCampanha] = useState<any>();
  const [defCampanha, setDefCampanha] = useState<any>();
  const [anuncio, setAnuncio] = useState<any>(null);
  const [anuncioUsuarioAfiliado, setAnuncioUsuarioAfiliado] =
    useState<IAnuncio>();
  const [descricao, setDescricao] = useState<any>(
    "Cliente indicado por: " + data.cliente.nome
  );

  useEffect(() => {
    loadCampanhas();
  }, []);

  useEffect(() => {
    campanhas.length && loadAnuncioUsuarioAfiliado();
  }, [campanhas]);

  const [sexos] = useState([
    { id: "F", text: "Feminino" },
    { id: "M", text: "Masculino" },
    { id: "O", text: "Outro" },
  ]);

  const notificationAlert = useRef(null);

  const notify = (type: string, msg: string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    // @ts-ignore
    if (notificationAlert && notificationAlert.current)
      // @ts-ignore
      notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (campanha && campanha.id > 0) {
      loadAnuncios(campanha.id);
      // console.log("O que tem dentro de data:", data);

      // loadAnuncioUsuarioAfiliado(2);
    } else {
      setAnuncios([]);
      setAnuncio(null);
    }
  }, [campanha]);

  async function loadCampanhas() {
    const response = await api.get(
      `marketing/campanha/${empresaSelecionada?.id}`,
      {
        params: {
          situacao: "ativo",
        },
      }
    );
    setCampanhas(response.data);
    loadAnuncioUsuarioAfiliado();
  }

  async function loadAnuncios(campanha_id: any) {
    if (campanha_id) {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/campanhas/${campanha_id}/anuncios`,
        {
          params: {
            situacao: "ativo",
            id: anuncioUsuarioAfiliado?.id,
          },
        }
      );
      setAnuncios(response.data);
    }
  }
  async function loadAnuncioUsuarioAfiliado() {
    const response = await api.get(
      `marketing/anuncios/usuario-afiliado-id/${data.user_id}`
    );
    setAnuncioUsuarioAfiliado(response.data[0]);
    console.log("usuario afiliado:", response.data[0]);

    if (response.data[0] && campanhas.length) {
      const minhaCampanha = campanhas.find(
        (e: any) => e.id == response.data[0].campanha_id
      );
      setCampanha({
        value: minhaCampanha.id,
        label: minhaCampanha.nome,
        id: minhaCampanha.id,
      });
      setAnuncio({
        value: response.data[0].id,
        label: response.data[0].nome,
        id: response.data[0].id,
      });
      console.log("minha campanha:", minhaCampanha);
    }
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: "calc(2.75rem + 2px)",
    }),
  };

  function formatarTelefone(formatTelefone: string) {
    if (!telefone) {
      return;
    }
    formatTelefone = formatTelefone.replace(/[^0-9]/g, "");

    let ddd = formatTelefone.substring(0, 2);
    let numero = formatTelefone.substring(2, formatTelefone.length);

    return { ddd, numero };
  }

  async function handleSave() {
    setSaving(true);
    try {
      //@ts-ignore
      setErros({});
      const formData = {
        nome,
        sexo,
        email,
        tipo_pessoa: "F",
        telefones: [formatarTelefone(telefone)],
        anuncio_id: anuncio.id,
        cliente_indicador_id: clienteIndicadorId,
        user_id: user?.id,
        descricao: descricao,
        criador_id: user?.id,
      };
      console.table(formData);
      const url = `/marketing/oportunidades/oportunidade-simplificada`;
      console.log({ formData });
      await api.post(url, formData);
      successAlert("Oportunidade adicionada com sucesso!");
    } catch (error) {
      console.error(error);
      //@ts-ignore
      if (error.response && error.response.status == 400) {
        //@ts-ignore
        const response = error.response;
        notify("danger", "Alguns campos estão inválidos");
        if (response.data.length) {
          const responseError: Record<string, any> = {};
          for (let e of response.data) {
            responseError[e.field] = e.message;
          }
          setErros(responseError as any);
        }
      } else {
        notify("danger", "Não foi possível salvar oportunidade.");
      }
    }
    setSaving(false);
  }

  const successAlert = (msg: any, cliente = {}) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
        title={msg}
        //@ts-ignore
        onConfirm={() => hideModal()}
        confirmBtnBsStyle="success"
        showConfirm={false}
        btnSize=""
      />
    );
    //@ts-ignore
    setTimeout((e) => hideModal(), 2000);
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      {alert}
      <Container className="p-0">
        <Card className="m-0">
          <CardHeader>
            <h2>Nova Oportunidade Simplificada</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="12" md="12" lg="12">
                <Row>
                  <Col sm="12" md="12" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Nome*</label>
                      </legend>
                      <Input
                        placeholder="Informe o nome da pessoa..."
                        className="form-control"
                        // value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                      <small className="text-danger">{erros?.nome || ""}</small>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="12" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Sexo*</label>
                      </legend>
                      <Select2
                        className="form-control"
                        onSelect={(e: any) => setSexo(e.target.value)}
                        value={sexo}
                        data={sexos}
                      />
                      <small className="text-danger">{erros?.sexo || ""}</small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="12" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Email</label>
                      </legend>
                      <Input
                        placeholder={"Email..."}
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <small className="text-danger">
                        {erros?.email || ""}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="12" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Telefone*</label>
                      </legend>
                      <InputMask
                        placeholder={"Telefone..."}
                        className="form-control"
                        mask="(99) 99999-9999"
                        value={telefone}
                        onChange={(e: any) => setTelefone(e.target.value)}
                      />
                      <small className="text-danger">
                        {erros?.telefones || ""}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Campanha*</label>
                      </legend>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable
                        isSearchable
                        isDisabled={false}
                        styles={customStyles}
                        placeholder={"Selecione uma Campanha"}
                        options={campanhas.map((item: any) => ({
                          value: item.id,
                          label: item.nome,
                          id: item.id,
                        }))}
                        value={campanha}
                        onChange={(e: any) => {
                          setCampanha(e);
                        }}
                      />
                      <small className="text-danger">
                        {erros?.campanha_id ? erros.campanha_id : ""}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Anúncio*</label>
                      </legend>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable
                        isSearchable
                        isDisabled={!campanha}
                        placeholder={"Selecione um Anúncio"}
                        styles={customStyles}
                        options={anuncios.map(
                          (item: { id: any; nome: any }) => ({
                            value: item.id,
                            label: item.nome,
                            id: item.id,
                          })
                        )}
                        value={anuncio}
                        onChange={(e) => {
                          setAnuncio(e);
                        }}
                      />
                      <small className="text-danger">
                        {erros?.anuncio_id ? erros.anuncio_id : ""}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="6">
                    <FormGroup>
                      <legend className="w-auto" style={{ margin: 0 }}>
                        <label className="form-control-label">Descrição*</label>
                      </legend>
                      <Input
                        className="form-control"
                        styles={customStyles}
                        disabled={true}
                        type="text"
                        value={descricao}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <div className="float-right">
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={hideModal}
              >
                Voltar
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={saving}
                onClick={handleSave}
              >
                {saving && (
                  <Spinner size="sm" color="secondary" className="mr-2" />
                )}
                Salvar e Fechar
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};
export default Form;
