/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";

import api from "../../../../services/api";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { axios } from "axios";

import {
  UncontrolledPopover,
  PopoverBody,
  Button,
  Modal,
  ListGroupItem,
  ListGroup,
  Badge,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { element } from "prop-types";

export default ({ notify, empresa, ...props }) => {
  const [destinos, setDestinos] = useState([]);
  const [destinosEmpresa, setDestinosEmpresa] = useState([]);
  const [destinoConfig, setDestinoConfig] = useState(null);
  const [fbStatus, setFbStatus] = useState(false);
  const [connectedPages, setConnectedPages] = useState([]);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (destinos.length === 0) loadDestinos();

    console.log({
      TOKEN_FB: new URLSearchParams(window.location.search).get("code"),
    });
  }, []);

  useEffect(() => {
    if (empresa && empresa.id) loadDestinosEmpresas();
  }, [empresa]);

  async function checkFacebookStatus() {
    const response = await api.get("common/canais/facebook");
    const { is_valid, pages } = response.data;
    setFbStatus(is_valid);
    setConnectedPages(pages);
  }

  useEffect(() => {
    checkFacebookStatus();
  }, []);

  async function loadDestinosEmpresas() {
    try {
      const response = await api.get(`common/empresas/${empresa.id}/destinos`);
      setDestinosEmpresa(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar destinos");
    }
  }

  async function onFacebookResponse({ accessToken }) {
    console.log({ accessToken });
    if (!accessToken) {
      notify("danger", "Não conseguimos concluir a operação");
      return;
    }
    try {
      await api.patch(`common/empresas/${empresa.id}/updatefacebooktoken`, {
        token: accessToken,
      });
      await getMyPages(accessToken);
      setDestinoConfig(null);
      notify("success", "Operação realizada");
      loadDestinosEmpresas();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível marcar destino");
    }
  }

  async function getMyPages(accessToken) {
    try {
      const data = await fetch(
        `https://graph.facebook.com/me/accounts?access_token=${accessToken}&fields=access_token,id,%20name&limit=10000`
      ).then((e) => e.json().then((r) => r.data));
      for (const page of data) {
        fetch(
          `https://graph.facebook.com/${page.id}/subscribed_apps?subscribed_fields=leadgen&access_token=${page.access_token}`,
          {
            method: "POST",
          }
        )
          .then((e) => console.log(`listen ${page.name}`))
          .catch(console.error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadDestinos() {
    try {
      const response = await api.get(`marketing/destinos`, {
        params: {
          situacao: "A",
        },
      });
      setDestinos(response.data);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar destinos");
    }
  }

  async function addDestino(destino) {
    try {
      await api.post(`common/empresas/${empresa.id}/destinos`, {
        destino_id: destino.id,
      });
      loadDestinosEmpresas();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível marcar destino");
    }
  }

  async function removeDestino(destino) {
    try {
      await api.delete(`common/empresas/${empresa.id}/destinos/${destino.id}`);
      loadDestinosEmpresas();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível desmarcar destino");
    }
  }

  function ListItem({ destino, key }) {
    const [loading, setLoading] = useState(false);

    return (
      <ListGroupItem
        key={key}
        className="checklist-entry flex-column align-items-start py-4 px-4"
      >
        <div className="checklist-item checklist-item-primary">
          <div className="checklist-primary">
            <h5 className="checklist-title mb-0">
              <i class={destino.icone}></i> &nbsp;
              {destino.nome}
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* {destino.canal.nome == "Facebook ADS" && (
              <Button
                className="btn-icon btn-2"
                size="sm"
                color="secondary"
                outline
                type="button"
                onClick={() => setDestinoConfig(destino.id)}
                disabled={
                  !destinosEmpresa
                    .map((item) => item.pivot.destino_id)
                    .includes(destino.id)
                }
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-settings-gear-65" />
                </span>
              </Button>
            )} */}
            <div className="custom-control custom-checkbox custom-checkbox-primary">
              {loading ? (
                <i class="fas fa-circle-notch fa-spin fa-lg primary mr-2"></i>
              ) : (
                <>
                  <input
                    className="custom-control-input"
                    id={`checklist-${destino.id}`}
                    type="checkbox"
                    checked={destinosEmpresa
                      .map((item) => item.pivot.destino_id)
                      .includes(destino.id)}
                    onClick={(e) => {
                      setLoading(true);
                      if (e.target.checked) {
                        addDestino(destino);
                      } else {
                        removeDestino(destino);
                      }
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`checklist-${destino.id}`}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </ListGroupItem>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        <FacebookLogin
          appId="735161440956284"
          autoLoad={false}
          icon={<i class="fab fa-facebook mr-4"></i>}
          size="small"
          version="3.1"
          onFailure={(e) => {
            console.log({ onFailure: e }, alert(`Falhou`));
          }}
          textButton="Login com Facebook"
          // whatsapp perm
          scope="pages_show_list,instagram_basic,leads_retrieval,pages_read_engagement,ads_management,pages_manage_ads,pages_manage_metadata,whatsapp_business_management,whatsapp_business_messaging,business_management"
          // prod branch
          // scope="pages_show_list,instagram_basic,pages_read_engagement,ads_management,pages_manage_ads"
          // dev branch
          // scope="ads_management,leads_retrieval, pages_show_list,pages_read_engagement,pages_manage_ads"
          fields="public_profile"
          render={(renderProps) => (
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button
                size="md"
                className="btn-icon"
                color="facebook"
                type="button"
                onClick={renderProps.onClick}
              >
                <span className="btn-inner--icon">
                  <i className="fab fa-facebook" />
                </span>
                <span className="btn-inner--text">
                  Conceder acesso ao Facebook
                </span>
              </Button>
              <Button
                color="secondary"
                id="tooltip-998822"
                outline
                size="sm"
                type="button"
              >
                ?
              </Button>
              <UncontrolledPopover placement="top" target="tooltip-998822">
                <PopoverBody>
                  Precisamos que você autorize o Playnee a acessar sua conta do
                  Facebook.
                  <br />
                  Isso permite que nossas integrações com a plataforma funcionem
                  sem problemas.
                  <br />
                  Se deseja usar a <b>captação de oportunidades</b> e/ou{" "}
                  <b>publicações automáticas</b> no Facebook, você precisa
                  conceder este acesso.
                </PopoverBody>
              </UncontrolledPopover>
              <div className="d-flex align-items-center">
                <Badge color={fbStatus ? "success" : "danger"}>
                  {fbStatus ? "Conectado" : "Não conectado"}
                </Badge>
              </div>
              <div className="ml-4">
                {fbStatus && (
                  <Button color="primary" onClick={toggle}>
                    Páginas Conectadas
                  </Button>
                )}
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle}>Páginas Conectadas</ModalHeader>
                  <ModalBody>
                    <ListGroup style={{ height: "60vh", overflowY: "scroll" }}>
                      {connectedPages.map((element) => (
                        <ListGroupItem key={element}>{element}</ListGroupItem>
                      ))}
                    </ListGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                      Voltar
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          )}
          callback={(response) => {
            console.log({ facebookLoginResponse: response });
            onFacebookResponse(response);
          }}
        />
      </div>

      <ListGroup data-toggle="checklist" flush>
        {destinos.map((item, key) => (
          <ListItem destino={item} key={key} />
        ))}
      </ListGroup>
    </>
  );
};
