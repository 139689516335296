import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../contexts/Empresa'

import api from "../../../services/api";

// import { Container } from './styles';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    ButtonGroup,
    Button,
} from 'reactstrap'
import { func } from 'prop-types';

export default ({
    publicacao,
    hideBack = false,
    hideNext = false,
    className,
    nextColor = 'default',
    ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [fluxo, setFluxo] = useState({})
    const [alert, setAlert] = useState(<></>)

    useEffect(() => {
        loadFaseAtual()
    }, [])

    async function loadFaseAtual() {
        try {
            const response = await api.get("/common/fluxos/vigente");
            const data = response.data;
            setFluxo(data)
        } catch (err) {
            throwError("Não foi possível carregar a fase inicial da publicação.")
        }
    }


    function throwError(err) {
        if (props.onError) {
            props.onError(err)
        }
    }

    async function changeFase(acao, publicacaoId = publicacao.id) {
        try {
            const response = await api.put(`common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacaoId}/fases/${acao}`);
            const publicacao = response.data
            if (props.onChangeSuccess) {
                props.onChangeSuccess(publicacao)
            }
        } catch (err) {
            console.error(err);
            throwError('Não foi possível alterar fase da publicação.')
        }
        setAlert(null)
    }

    function createBtnFaseAtual() {
        const faseAtual = publicacao ?
            {
                id: publicacao.faseAtual.id,
                nome: publicacao.faseAtual.nome,
            } : fluxo.faseInicial;
        return (
            <Button
                style={{
                    "pointer-events": "none"
                }}
                id={`fase_atual_${faseAtual.id}`}
                variant="primary"
                size="sm"
                active
            >{
                    faseAtual.nome
                }
            </Button>
        )
    }

    function hideAlert() {
        setAlert(null)
    }

    const confirmAlertUpdateFase = (
        title,
        msg,
        confirmFunction,
        confirmButtonLabel = "Ok",
        confirmButtonColor = "primary"
    ) => {
        setAlert(
            <ReactBSAlert
                warning
                closeOnClickOutside={false}
                style={{ display: "block", marginTop: "-100px" }}
                title={title}
                onConfirm={confirmFunction}
                onCancel={() => hideAlert()}
                showCancel
                confirmBtnBsStyle="primary"
                confirmBtnText="Sim"
                cancelBtnText="cancelar"
                btnSize=""
            >
                {msg}
            </ReactBSAlert>
        );
    };

    function createBtnFaseAntecessora() {
        if (publicacao && publicacao.faseAntecessora && publicacao.faseAntecessora.id) {
            const faseAntecessora = publicacao.faseAntecessora
            const onChangeFase = changeFase
            const publicacaoId = publicacao.id
            return (
                <Button
                    variant="outline-secondary"
                    size="lg"
                    id={`fase_ancessora_${faseAntecessora.id}`}
                    onClick={e =>
                        confirmAlertUpdateFase("Atualizar fase",
                            "Deseja realmente voltar para a fase de " + faseAntecessora.nome + "?",
                            () => { onChangeFase('voltar-fase', publicacaoId) },
                            "Sim",
                            "primary"
                        )
                    }
                ><i class="fas fa-caret-left mr-2"></i>{faseAntecessora.nome}</Button>
            )
        }
    }

    function createBtnFaseSucessora() {
        if (publicacao && publicacao.faseSucessora && publicacao.faseSucessora.id) {
            const faseSucessora = publicacao.faseSucessora
            const onChangeFase = changeFase
            const publicacaoId = publicacao.id
            return (
                <Button
                    color={nextColor}
                    size="lg"
                    id={`fase_sucessora_${faseSucessora.id}`}
                    onClick={e =>
                        confirmAlertUpdateFase("Atualizar fase",
                            "Deseja realmente avancar para a fase de " + faseSucessora.nome + "?",
                            () => onChangeFase('avancar-fase', publicacaoId),
                            "Sim",
                            "primary"
                        )
                    }
                >{faseSucessora.nome}<i class="fas fa-caret-right ml-2"></i></Button>
            )
        }
    }

    return (
        <>
            {alert}
            <ButtonGroup aria-label="" size="sm" className={className}>
                {/* só mostra se o fluso existir */}
                {fluxo.id && !hideBack && createBtnFaseAntecessora()}
                {fluxo.id && createBtnFaseAtual()}
                {fluxo.id && !hideNext && createBtnFaseSucessora()}

            </ButtonGroup>
        </>
    );
}
