import React, { useState, useEffect, useContext, useRef } from 'react';
import EmpresaContext from "../../../../../../contexts/Empresa";
import {
    Acao, UserQuem, Objetivo, Estrategia, GrupoEstrategico
} from "../../../../../../entities/Mentoring";

import UserQuemContainer from './UserQuem'
// import { Container } from './styles';
import ReactDatetime from "react-datetime";
//@ts-ignore
import Select2 from "react-select2-wrapper";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
//@ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import { CenterContainer } from '../styles';

import {
    Button,
    Spinner,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col,
    ListGroupItem,
    UncontrolledTooltip
} from "reactstrap";
import api from '../../../../../../services/api';
import { User } from '../../../../../../entities/Security';
import moment from 'moment';

type Props = {
    notaId?: number,
    show: boolean,
    hide(): void,
    onSuccess(): Promise<void>,
    acao: Acao
}

type Error = {
    o_que?: string,
    quando?: string,
    como?: string,
    situacao?: string,
    users?: string
}

const Form: React.FC<Props> = ({
    notaId,
    show,
    hide,
    onSuccess,
    acao
}) => {

    const { empresaSelecionada } = useContext(EmpresaContext)

    const [oQue, setOQue] = useState('')
    const [quando, setQuando] = useState(new Date())
    const [como, setComo] = useState('')
    const [situacao, setSituacao] = useState<'nao_iniciado' | 'iniciado' | 'concluido' | 'nao_executado'>('nao_iniciado')
    const [users, setUsers] = useState(new Array<User>())
    const [user, setUser] = useState<User | undefined>()

    const [grupoEstrategico, setGrupoEstrategico] = useState<Estrategia | null>(null)
    const [estrategia, setEstrategia] = useState<Estrategia | null>(null)
    const [objetivo, setObjetivo] = useState<Objetivo | null>(null)

    const [gruposEstrategicos, setGruposEstrategicos] = useState([])
    const [estrategias, setEstrategias] = useState([])
    const [objetivos, setObjetivos] = useState([])

    const [erros, setErros] = useState({} as Error)

    const [saving, setSaving] = useState(false);
    const notificationAlert = useRef<NotificationAlert>(null);
    const [showConfirm, setShowConfirm] = useState(false)

    const [loadingObjetivos, setLoadingObjetivos] = useState(false)

    useEffect(() => {
        // if (!gruposEstrategicos || gruposEstrategicos.length === 0) loadGruposEstrategicos()
        console.log('USE EFFECT GERAL')
        if (!objetivos || objetivos.length === 0) loadObjetivos()
    }, [])

    useEffect(() => {
        setOQue(acao.o_que)
        setQuando(acao.quando ? new Date(acao.quando) : new Date())
        setComo(acao.como)
        setSituacao(acao.situacao)
        setUsers(acao.users ? acao.users as Array<User> : new Array<User>())

    }, [acao])

    async function loadObjetivos() {
        console.log('LOAD Objetivos')
        try {
            setLoadingObjetivos(true)
            setObjetivo(null)
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/objetivos`, {
                params: {
                    estrategia_id: estrategia ? estrategia.id : null,
                    grupo_estrategico_id: grupoEstrategico ? grupoEstrategico.id : null
                }
            })

            if (response && response.data) {
                setObjetivos(await response.data)
            } else {
                setObjetivos([])
            }
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos carregar os objetivos')
        }
        setLoadingObjetivos(false)
    }

    async function handleSave() {
        setErros({})
        setSaving(true);
        if (acao && acao.id) {
            await update()
        } else {
            await insert();
        }
        setSaving(false);
    }

    async function insert() {
        try {
            await api.post(`mentoring/empresas/${empresaSelecionada?.id}/notas/${notaId}/acoes`, {
                o_que: oQue,
                quando,
                como,
                situacao,
                users: users.map(item => (item.id)),
                // nota_id: notaId
                objetivo_id: objetivo ? objetivo.id : null
            }
            )

            onSuccess()
            hideModal()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos inserir uma ação!')
            //@ts-ignore
            if (error.response) updateErros(error.response.data)
        }
    }

    async function addUser(user: User) {
        if (users.find((item) => { return item.id == user.id })) {
            notify('warning', 'Usuário já adicionado anteriormente!')
            return
        }

        try {
            if (acao && acao.id) {
                await insertQuem(user)
            } else {
                const newList = [...users];
                newList.push(user)
                setUsers(newList)
            }

        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos inserir o usuário')
        }
    }

    function confirmRemove(user: User) {
        setUser(user)
        setShowConfirm(true)
    }

    async function removeUser() {
        if (!users.find((item) => { return item.id == user?.id })) {
            notify('warning', 'Usuário já removido anteriormente!')
            return
        }

        if (acao && acao.id) {
            try {
                await removeUserFromServer(user?.id as number)
                let newList = [...users];
                newList = newList.filter((item) => { return item.id != user?.id });
                setUsers(newList)
                setUser(undefined)
                setShowConfirm(false)

            } catch (error) {
                //@ts-ignore
                console.error(error.response)
                //@ts-ignore
                if (error.response.data.message) {
                    //@ts-ignore
                    notify('warning', error.response.data.message)
                    setShowConfirm(false)
                } else {
                    notify('danger', 'Não conseguimos remover o usuário')
                }
            }

        } else {
            try {
                let newList = [...users];
                newList = newList.filter((item) => { return item.id != user?.id });
                setUsers(newList)
                setUser(undefined)
                setShowConfirm(false)
                notify('success', 'Usuário Removido.')
            } catch (error) {
                console.error(error)
                notify('danger', 'Não conseguimos remover o usuário')
            }

        }

    }

    async function removeUserFromServer(userId: number) {
        try {
            await api.delete(`common/empresas/${empresaSelecionada?.id}/objetivos/${objetivo?.id}/acoes/${acao.id}/users/${userId}`)
            notify('success', 'Usuário removido da Ação.')

        } catch (error) {
            throw error
        }
    }

    async function update() {
        try {
            await api.put(`common/empresas/${empresaSelecionada?.id}/objetivos/${acao.objetivo_id}/acoes/${acao.id}`, {
                o_que: oQue,
                quando,
                como,
                situacao,
                users: users.map(item => (item.id))
            })

            onSuccess()
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos atualizar a ação.')
        }
    }

    async function insertQuem(user: User) {
        try {
            await api.post(`common/empresas/${empresaSelecionada?.id}/objetivos/${objetivo?.id}/acoes/${acao.id}/users`, {
                user_id: user.id
            })

            const { data } = await api.get(`common/empresas/${empresaSelecionada?.id}/objetivos/${objetivo?.id}/acoes/${acao.id}/users`)
            const listUsers = data.map((userSelect: UserQuem) => {
                return userSelect.user
            })
            setUsers(listUsers)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não conseguimos adicionar o usuário nesta ação.')
        }
    }



    function updateErros(error: any) {
        if (error && error instanceof Array) {
            const errors = {}
            for (let e of error) {
                //@ts-ignore
                errors[e.field] = e.message
            }
            setErros(errors)
        } else {
            setErros({})
        }
    }

    function notify(type: string, msg: string) {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (notificationAlert) notificationAlert?.current?.notificationAlert(options);
    };

    function hideModal() {
        setOQue('')
        setQuando(new Date())
        setComo('')
        setSituacao('nao_iniciado')
        setUsers(new Array<User>())
        setUser(undefined)

        setObjetivo(null);
        setEstrategia(null);
        setGrupoEstrategico(null);
        hide()
    }

    function handleVoltar() {
        if (objetivo) {
            setObjetivo(null)
        } else {
            if (estrategia) {
                setEstrategia(null)
            } else {
                if (grupoEstrategico) {
                    setGrupoEstrategico(null)
                }
            }
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            size='lg'
            isOpen={show}
            toggle={hideModal}
            backdrop="static"
        >
            <ReactBSAlert
                custom
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                title=''
                onConfirm={() => removeUser()}
                onCancel={() => setShowConfirm(false)}
                showCancel
                confirmBtnBsStyle="primary"
                confirmBtnText="Remover"
                cancelBtnText="cancelar"
                btnSize=""
                show={showConfirm}
            >
                {`Deseja remover esse participante da ação?`}
            </ReactBSAlert>

            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>

            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    {acao && acao.id ? 'Alterar Ação' : 'Nova Ação'}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={hideModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body" style={{ paddingTop: 0 }}>
                {!acao || !acao.id &&
                    <>
                        <h5 style={{ marginBottom: 0 }}>
                            {/* {`Grupo Estratégico: ${grupoEstrategico ? grupoEstrategico.nome : ''}`} */}
                            {`Grupo Estratégico: ${objetivo && objetivo.estrategia && objetivo.estrategia.grupoEstrategico ? objetivo.estrategia.grupoEstrategico.nome : ''}`}

                        </h5>
                        <h5 style={{ marginBottom: 0 }}>
                            {/* {`Estratégia: ${estrategia ? estrategia.nome : ''}`} */}
                            {`Estratégia: ${objetivo && objetivo.estrategia ? objetivo.estrategia.nome : ''}`}
                        </h5>
                        {/* @ts-ignore */}
                        <h5 style={{ marginBottom: 0 }} id={'objetivoDescricaoTooltip'}>
                            {`Objetivo: ${objetivo ? '#' + objetivo.id : ''}`}
                        </h5>
                        {/* @ts-ignore */}
                        <UncontrolledTooltip placement="bottom-start" target={'objetivoDescricaoTooltip'} dangerouslySetInnerHTML={{ __html: objetivo && objetivo.descricao ? objetivo.descricao : ''}}>
                        </UncontrolledTooltip>
                    </>
                }

                {(!acao || !acao.id) && !objetivo &&
                    <>
                        <h4 style={{ marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
                            {(!acao || !acao.id) && !objetivo ? 'Escolha um Objetivo' : ''}
                        </h4>

                        {loadingObjetivos ?
                            <CenterContainer>
                                <Spinner
                                    size='lg'
                                    color='primary'
                                />
                            </CenterContainer>
                            :
                            <div style={{ maxHeight: '50vh', overflow: 'auto', overflowX: 'hidden' }}>
                                {
                                    objetivos?.map(obj => (
                                        <ListGroupItem
                                            className="px-0"
                                        >
                                            <Row className="align-items-center">

                                                <Col >
                                                    {/* @ts-ignore */}
                                                    <div className="pl-2" id={`objetivo-${obj.id}`}>
                                                        {/* @ts-ignore */}
                                                        <h5 style={{ marginBottom: 0 }}>
                                                            {/* @ts-ignore */}
                                                            {`Grupo Estratégico: ${obj?.estrategia?.grupoEstrategico?.nome}`}
                                                        </h5>

                                                        <h5 style={{ marginBottom: 0 }}>
                                                            {/* @ts-ignore */}
                                                            {`Estratégia: ${obj?.estrategia?.nome}`}
                                                        </h5>

                                                        <h5 style={{ marginBottom: 0 }} title='Data da criação do Objetivo'>
                                                            {/* @ts-ignore */}
                                                            Vigência: {moment(obj.created_at).format('LLL')}
                                                        </h5>

                                                        {/* @ts-ignore */}
                                                        {obj?.data_conclusao &&
                                                            <h5
                                                                style={{ marginBottom: 0 }}
                                                                title='Data da Conclusão do Objetivo'
                                                            >
                                                                {/* @ts-ignore */}
                                                                Conclusão: {moment(obj.data_conclusao).format('LLL')}
                                                            </h5>
                                                        }

                                                    </div>
                                                    {/* @ts-ignore */}
                                                    <UncontrolledTooltip placement="bottom-start" target={`objetivo-${obj.id}`} dangerouslySetInnerHTML={{ __html: obj.descricao }}>
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col className="col-auto">
                                                    <Button
                                                        color="link"
                                                        type="button"
                                                        onClick={() => setObjetivo(obj)}
                                                    >
                                                        escolher
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    ))
                                }
                            </div>
                        }
                    </>
                }


                {/* Se Objetivo Escolhido */}
                {((acao && acao.id) || (objetivo && objetivo.id && objetivo.id > 0)) &&
                    <div>
                        <Row>
                            <Col
                                lg='6'
                                xl='6'
                                md='12'
                                sm='12'
                            >
                                <Row>
                                    <Col
                                        className='col-12'
                                    >
                                        <FormGroup>
                                            <legend className="w-auto" style={{ margin: 0 }}>
                                                <label
                                                    className="form-control-label"
                                                >
                                                    O que*
                                                </label>
                                            </legend>
                                            <Input
                                                className="form-control"
                                                placeholder="O que..."
                                                type="text"
                                                value={oQue}
                                                onChange={(e) => setOQue(e.target.value)}
                                            />
                                            <small className="text-danger">
                                                {erros?.o_que || ""}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                    <Col
                                        className='col-12'
                                    >
                                        <FormGroup>
                                            <legend className="w-auto" style={{ margin: 0 }}>
                                                <label
                                                    className="form-control-label"
                                                >
                                                    Quando*
                                                </label>
                                            </legend>
                                            <InputGroup className="input-group" >
                                                <ReactDatetime
                                                    closeOnSelect={true}
                                                    locale={'pt-br'}
                                                    value={quando}
                                                    inputProps={{
                                                        placeholder: "Quando...",
                                                    }}
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat='HH:mm'
                                                    //@ts-ignore
                                                    onChange={e => setQuando(e)}
                                                />
                                                <InputGroupAddon addonType="append"  >
                                                    <InputGroupText
                                                        style={{
                                                            padding: '10px'
                                                        }}>
                                                        <i className="far fa-calendar-alt" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <small className="text-danger">
                                                {erros?.quando || ""}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                    <Col
                                        className='col-12'
                                    >
                                        <FormGroup>
                                            <legend className="w-auto" style={{ margin: 0 }}>
                                                <label
                                                    className="form-control-label"
                                                >
                                                    Como*
                                                </label>
                                            </legend>
                                            <Input
                                                className="form-control"
                                                placeholder="Como..."
                                                type="textarea"
                                                value={como}
                                                onChange={(e) => setComo(e.target.value)}
                                            />
                                            <small className="text-danger">
                                                {erros?.como || ""}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                    <Col
                                        className='col-12'
                                    >
                                        <FormGroup>
                                            <legend className="w-auto" style={{ margin: 0 }}>
                                                <label
                                                    className="form-control-label"
                                                >
                                                    Situação*
                                                </label>
                                            </legend>
                                            <Select2
                                                className="form-control"
                                                //@ts-ignore
                                                onSelect={(e) => setSituacao(e.target.value)}
                                                value={situacao}
                                                data={[
                                                    { id: 'nao_iniciado', text: 'Não iniciado' },
                                                    { id: 'iniciado', text: 'Iniciado' },
                                                    { id: 'concluido', text: 'Concluído' },
                                                    { id: 'nao_executado', text: 'Não Executado', }
                                                ]}
                                            />
                                            <small className="text-danger">
                                                {erros?.situacao || ""}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col>
                                <Row>
                                    <Col
                                        className='col-12'
                                    >
                                        <UserQuemContainer
                                            acao={acao}
                                            addUser={addUser}
                                            removeUser={confirmRemove}
                                            users={users}
                                        />
                                        <small className="text-danger">
                                            {erros?.users || ""}
                                        </small>
                                    </Col>
                                </Row>

                            </Col>

                        </Row>

                    </div>
                }

            </div>

            <div className="modal-footer">
                <Button
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={hideModal}
                >
                    Fechar
                </Button>

                {(!acao || !acao.id) && (objetivo || estrategia || grupoEstrategico) &&
                    <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={handleVoltar}
                    >
                        Voltar
                    </Button>
                }

                {((acao && acao.id) || objetivo) &&
                    <Button
                        color="primary"
                        type="button"
                        disabled={saving}
                        onClick={handleSave}>
                        <Spinner
                            hidden={!saving}
                            className="mr-2"
                            color="light"
                            size="sm"
                        />
                        Salvar
                    </Button>
                }
            </div>
        </Modal>
    )
}

export default Form;