import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";

interface CountdownTimerProps {
  serverTime: string;
  startTime: string | null;
  durationHours: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  serverTime,
  startTime,
  durationHours,
}) => {
  const [timeLeft, setTimeLeft] = useState<string>("");
  const [timeRemaining, setTimeRemaining] = useState<number>(0);

  useEffect(() => {
    if (!startTime) return;
    // Convert serverTime and startTime to Date objects
    const serverDate = new Date(serverTime);
    const startDate = new Date(startTime);

    // Calculate end time
    const endDate = new Date(
      startDate.getTime() + durationHours * 60 * 60 * 1000
    );

    const updateTimer = () => {
      const currentDate = new Date();
      const elapsed = currentDate.getTime() - serverDate.getTime();
      const now = new Date(serverDate.getTime() + elapsed);

      const timeRemainingMs = endDate.getTime() - now.getTime();
      setTimeRemaining(timeRemainingMs);

      if (timeRemainingMs <= 0) {
        setTimeLeft("Encerrada");
      } else {
        const hours = Math.floor(timeRemainingMs / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeRemainingMs % (1000 * 60 * 60)) / (1000 * 60)
        );

        setTimeLeft(
          `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}`
        );
      }
    };

    // Update the timer every second
    updateTimer(); // initial call
    const timerInterval = setInterval(updateTimer, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerInterval);
  }, [serverTime, startTime, durationHours]);

  if (!startTime) return null;

  let timerColor = "success";
  if (timeRemaining <= 0) {
    timerColor = "danger";
  } else if (timeRemaining > 0 && timeRemaining < 2) {
    timerColor = "warning";
  } 
  
  return (
    <Badge color={timerColor}>
      <span style={styles.timer}>{timeLeft}</span>
    </Badge>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  timer: {
    color: "#333",
    fontSize: ".65rem",
  },
};

export default CountdownTimer;
