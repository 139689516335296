import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalFooter,
  PopoverBody,
  Row,
  Spinner,
  UncontrolledPopover,
} from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import api from '../../../../../../../../services/api';
import ReactQuill from 'react-quill';

import Select2 from 'react-select2-wrapper';
import NotificationAlert from 'react-notification-alert';
import CurrencyInput from 'react-currency-input';
import ITipoProduto from '../../../../../../../../entities/Marketing/TipoProduto';
import IProduto from '../../../../../../../../entities/Marketing/Produto';
import { Dropzone } from '../../../../../../../../components/Dropzone';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface NovoProdutoModalProps {
  isVisible: boolean;
  toggleModal: () => void;
  empresaId: number;
  onReload: () => void;
}

enum Situacao {
  'A',
  'I',
}

interface FileProps {
  name: string;
  preview: string;
  path: string;
  id: number;
}

const reactQuillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

const reactQuillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

export default function NovoProdutoModal({
  isVisible,
  toggleModal,
  empresaId,
  onReload,
}: NovoProdutoModalProps) {
  const [nome, setNome] = useState('');
  const [tipoProduto, setTipoProduto] = useState<{
    id: number;
    text: string;
  } | null>(null);
  const [situacao, setSituacao] = useState<Situacao>(0);
  const [preco, setPreco] = useState<number | null>(null);
  const [descricao, setDescricao] = useState('');
  const [tiposProdutos, setTiposProdutos] = useState<ITipoProduto[]>([]);
  const [files, setFiles] = useState<FileProps[]>([]);
  const [saving, setSaving] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [preview, setPreview] = useState({
    show: false,
    url: '',
    index: 0,
  });

  const notificationAlert = useRef<NotificationAlert>(null);

  useEffect(() => {
    loadTiposProdutos();
  }, []);

  async function loadTiposProdutos() {
    try {
      const { data } = await api.get(
        `/common/empresas/${empresaId}/tipos-produtos`,
        {
          params: {
            situacao: 'A',
          },
        }
      );
      setTiposProdutos(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmit() {
    try {
      setSaving(true);
      const {
        data: { id },
      } = await api.post<IProduto>(
        `/common/empresas/${empresaId}/produtos`,
        bind()
      );
      if (files.length > 0) {
        await insertFiles(id);
      }

      notify('success', 'Produto salvo com sucesso!');
      onReload();
      toggleModal();
    } catch (error) {
      notify('danger', 'Ocorreu um erro ao tentar cadastrar o produto.');
      console.error(error);
    } finally {
      setSaving(false);
    }
  }

  function onClose() {
    setFiles([]);
    setNome('');
    setDescricao('');
    setSituacao(0);
    setTipoProduto(null);
    setPreco(null);
  }

  async function insertFiles(produtoId: number) {
    try {
      if (files && files.length > 0) {
        for (let file of files) {
          let formData = new FormData();
          // @ts-ignore
          formData.append('arquivo', file);
          console.log({ file, selectedFile });
          const isCoverFile = file.path == selectedFile;
          await api.post(
            `common/empresas/${empresaId}/produtos/${produtoId}/imagens?cover=${isCoverFile}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function bind() {
    return {
      nome,
      situacao,
      tipo_produto_id: tipoProduto,
      preco,
      descricao,
    };
  }

  const nextPreview = (currentIndex: number) => {
    return currentIndex + 1 > files.length - 1 ? 0 : currentIndex + 1;
  };

  const prevPreview = (currentIndex: number) => {
    return currentIndex - 1 < 0 ? files.length - 1 : currentIndex - 1;
  };

  const handleRadioChange = (fileId) => {
    console.log({ fileId });
    setSelectedFile(fileId);
  };

  const lightboxPreview = () => {
    if (files.length === 0) return;
    const file = files[preview.index];
    const nextFile = files[nextPreview(preview.index)];
    const prevFile = files[prevPreview(preview.index)];

    const urlNext = nextFile.preview;
    const urlPrev = prevFile.preview;
    return (
      <>
        {preview.show && (
          <Lightbox
            mainSrc={file.preview}
            nextSrc={urlNext}
            prevSrc={urlPrev}
            reactModalStyle={{ overlay: { zIndex: 1051 } }}
            onCloseRequest={() => setPreview({ ...preview, show: false })}
            onMovePrevRequest={() =>
              setPreview({
                ...preview,
                url: urlPrev,
                index: prevPreview(preview.index),
              })
            }
            discourageDownloads={false}
            imageTitle={file.name || file.path}
            onMoveNextRequest={() =>
              setPreview({
                ...preview,
                url: urlNext,
                index: nextPreview(preview.index),
              })
            }
          />
        )}
      </>
    );
  };

  const notify = (type, msg) => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 3,
    };
    if (notificationAlert.current)
      notificationAlert.current.notificationAlert(options);
  };

  function dropzoneCallback(acceptedFiles) {
    let f = files;
    const newFile = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    f = f.concat(newFile);
    setFiles(f);
  }

  function handleRemove(file: FileProps) {
    const newFiles = files.filter((item) => item.path != file.path);
    setFiles(newFiles);
  }

  const thumbs = files?.map((file, key) => (
    <ListGroupItem
      className=" px-0"
      style={{
        paddingTop: 0,
        border: 0,
      }}
    >
      <Row className=" align-items-center" key={key}>
        <Col
          onClick={() =>
            setPreview({
              show: true,
              index: key,
              url: file.preview,
            })
          }
          style={{
            cursor: 'pointer',
          }}
          className=" col-auto"
        >
          <div className=" avatar">
            <img
              alt={file.name}
              className=" avatar-img rounded"
              src={file.preview}
            />
          </div>
        </Col>
        <Col
          className="col ml-2"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: '0',
          }}
        >
          <div className="">
            <h4
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              className=" mb-1"
              data-dz-name
            >
              {file.path}
            </h4>
          </div>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <Label check className="mr-4">
            Imagem de capa
          </Label>
          <Input
            type="radio"
            name="coverImage"
            value={file.path}
            checked={selectedFile == file.path}
            onChange={(event) => handleRadioChange(event.currentTarget.value)}
          />
        </Col>
        <Col className=" col-auto">
          <Button size="sm" color="danger" onClick={(e) => handleRemove(file)}>
            <i className="fas fa-trash" />
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  ));

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      {alert}
      <Modal
        onClosed={onClose}
        className="modal-dialog-centered modal-lg"
        isOpen={isVisible}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Novo Produto
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <label className="form-control-label">Nome*</label>
                <Input
                  className="form-control"
                  placeholder="Nome do produto..."
                  type="text"
                  value={nome}
                  onChange={({ target }) => setNome(target.value)}
                />
                {/* <small className="text-danger">{erros.nome || ''}</small> */}
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <label className="form-control-label">Tipo produto*</label>
                <Select2
                  className="form-control"
                  value={tipoProduto}
                  options={{
                    placeholder: 'Selecione um tipo produto...',
                  }}
                  onSelect={(e: any) => setTipoProduto(e.target.value)}
                  data={tiposProdutos?.map((item) => ({
                    id: item.id,
                    text: item.nome,
                  }))}
                />
                {/* <small class="text-danger">
                      {erros.tipo_produto_id || ''}
                    </small> */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Valor*</label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>R$</InputGroupText>
                  </InputGroupAddon>
                  <CurrencyInput
                    className="form-control"
                    value={preco}
                    placeholder="Valor do produto..."
                    onChangeEvent={(e, maskedvalue, floatvalue) =>
                      setPreco(floatvalue)
                    }
                  />
                </InputGroup>
                {/* <small class="text-danger">{erros.preco || ''}</small> */}
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <label className="form-control-label">Situação*</label>
                <Select2
                  className="form-control"
                  value={situacao}
                  defaultValue="A"
                  options={{
                    placeholder: 'Selecione a situação...',
                  }}
                  onSelect={(e) => setSituacao(e.target.value)}
                  data={[
                    { id: 'A', text: 'Ativo' },
                    { id: 'I', text: 'Inativo' },
                  ]}
                />
                {/* <small class="text-danger">{erros.situacao || ''}</small> */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormGroup>
                <label className="form-control-label">
                  Descrição
                  <Button
                    color="secondary"
                    id="desc-tooltip"
                    outline
                    size="sm"
                    type="button"
                  >
                    ?
                  </Button>
                  <UncontrolledPopover placement="top" target="desc-tooltip">
                    <PopoverBody>
                      Utilize este campo para descrever o produto.
                    </PopoverBody>
                  </UncontrolledPopover>
                </label>
                <ReactQuill
                  value={descricao || ''}
                  theme="snow"
                  modules={reactQuillModules}
                  formats={reactQuillFormats}
                  onChange={(content) => setDescricao(content)}
                />
                {/* <small className="text-danger">{erros.descricao || ''}</small> */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <FormGroup>
                {lightboxPreview()}
                <Dropzone callback={dropzoneCallback} accept="image/*" multiple>
                  <label style={{ cursor: 'pointer' }}>
                    'Solte seus arquivos aqui...'
                  </label>
                </Dropzone>
                <ListGroup
                  className=" dz-preview dz-preview-multiple list-group-lg list"
                  style={{
                    paddingTop: 12,
                  }}
                  flush
                >
                  {thumbs}
                </ListGroup>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <ModalFooter>
          <Row>
            <Col>
              <div className="float-right ">
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModal}
                >
                  Voltar
                </Button>
                <Button
                  disabled={saving}
                  color="primary"
                  onClick={handleSubmit}
                >
                  <Spinner
                    hidden={!saving}
                    className="mr-2"
                    color="light"
                    size="sm"
                  />
                  Salvar
                </Button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
}
