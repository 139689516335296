import React, { useState, useContext } from 'react';
import moment from 'moment';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import { hasPermission } from '../../../utils';

import { useHistory } from "react-router-dom";
import EmpresaContext from "../../../contexts/Empresa";
import api from '../../../services/api';

import { User } from '../../../entities/Security';
import Avatares from '../../../components/timeLines/Pdca/Estrategias/Item/Avatares';

import { MenuComportamento } from '../../../components/Menus'

// reactstrap components
import {
    Button,
    Badge,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";

export default ({ malasDiretas, pageProperties, notify, history, ...props }) => {

    const { empresaSelecionada, changeEmpresaSelecionada, empresas } = useContext(EmpresaContext)
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [malaToEdit, setMalaToEdit] = useState(null);

    const dataFormatter = (cell, row) => {
        return (
            <>
                {moment(cell).format('DD/MM/YYYY HH:mm')}
            </>
        )
    }

    function situacaoFormatter(cell, row) {
        let situacao = cell
        let color = 'secondary'
        switch (cell) {
            case 'concluido':
                situacao = 'Concluído'
                color = 'success'
                break;
            case 'em-andamento':
                situacao = 'Em Andamento'
                color = 'secondary'
                break;
            case 'cancelado':
                situacao = 'Cancelado'
                color = 'warning'
                break;
            case '':
                color = 'danger'
                break;
        }
        return (
            <>
                <Badge color={color} className="badge-lg" pill>
                    {situacao}
                </Badge>
            </>
        )
    }

    function nomeDescricaoFormatter(cell, row) {
        let nome = cell
        let descricao = row.descricao

        return (
            <div title={descricao}>
                {nome}
            </div>
        )
    }

    function envioSmsFormatter(cell, row) {
        let enviarSms = row.enviar_sms
        let gerados = row.qtd_sms_gerados
        let enviados = row.qtd_sms_enviados
        let resultado = ''
        let tipoEnvio = ''

        let color = 'secondary'

        if (enviarSms) {
            tipoEnvio = '.'
            color = 'success'
            resultado = 'Gerados: ' + gerados + ' | Enviados: ' + enviados
        } else {
            tipoEnvio = '.'
            color = 'danger'
        }

        return (

            <>
                <Badge color={color} className="badge-lg" pill>
                    {tipoEnvio}
                </Badge> {resultado}
            </>
        )
    }


    const envioEmailFormatter = (cell, row) => {
        let enviarEmail = row.enviar_email
        let gerados = row.qtd_emails_gerados
        let enviados = row.qtd_emails_enviados
        let resultado = ''
        let tipoEnvio = ''

        let color = 'secondary'

        if (enviarEmail) {
            tipoEnvio = '.'
            color = 'success'
            resultado = 'Gerados: ' + gerados + ' | Enviados: ' + enviados
        } else {
            tipoEnvio = '.'
            color = 'danger'
        }

        return (

            <>
                <Badge color={color} className="badge-lg" pill>
                    {tipoEnvio}
                </Badge> {resultado}
            </>
        )
    }

    function envioFormatter(tipo, cell, row) {

        let isEnviar = tipo == 'sms' ? row.enviar_sms : row.enviar_email

        let gerados = tipo == 'sms' ? row.qtd_sms_gerados : row.qtd_emails_gerados
        let enviados = tipo == 'sms' ? row.qtd_sms_enviados : row.qtd_emails_enviados
        let resultado = ''
        let tipoEnvio = ''

        let color = 'secondary'

        if (isEnviar) {
            tipoEnvio = '.'
            color = 'success'
            resultado = 'Gerados: ' + gerados + ' | Enviados: ' + enviados
        } else {
            tipoEnvio = '.'
            color = 'danger'
        }

        return (

            <>
                <Badge color={color} className="badge-lg" pill>
                    {tipoEnvio}
                </Badge> {resultado}
            </>
        )
    }

    async function inativarMalaDiretaToEdit(malaDireta) {
        try {
            await api.put(`common/empresas/${empresaSelecionada.id}/malas-diretas/${malaDireta.id}/inativar`)
            history.push(`/admin/malas-diretas/${malaDireta.id}/edit`)
        } catch (error) {
            console.error(error)
        }
    }

    async function handlerEditar(malaDireta) {
        if (malaDireta) {
            await inativarMalaDiretaToEdit(malaDireta)
        }
    }

    function handlerConfirmarEdicao(cell, row) {
        if (row.situacao == 'ativo') {
            setMalaToEdit(row)
            setShowModalEditar(true);
        }else{
            history.push(`/admin/malas-diretas/${row.id}/edit`)
        }

    }

    function handlerCancelarEdicao() {
        setMalaToEdit(null)
        setShowModalEditar(false);
    }


    function acoesFormatter(row, column) {
        return (
            <MenuComportamento
                actions={[
                    {
                        label: 'Ver/Alterar',
                        icon: 'far fa-edit',
                        onClick: () => handlerConfirmarEdicao(row, column)
                    }
                ]}
            />
        )
    }



    const [columns] = useState([
        {
            dataField: 'acoes',
            formatter: (cell, row) => acoesFormatter(cell, row,)
        },
        {
            dataField: "nome",
            text: 'Nome',
            sort: true,
            formatter: (cell, row) => nomeDescricaoFormatter(cell, row),
        },
        {
            dataField: 'created_at',
            text: 'Data Criação',
            sort: true,
            formatter: (cell, row) => dataFormatter(cell),
        },
        {
            dataField: 'data_agendamento',
            text: 'Data Agendamento',
            sort: true,
            formatter: (cell, row) => dataFormatter(cell),
        },
        {
            dataField: "origem",
            text: 'Origem',
            sort: true,
        },
        {
            dataField: 'enviar_sms',
            text: 'Envio Sms',
            formatter: (cell, row) => envioFormatter('sms', cell, row),
            sort: true
        },
        {
            dataField: 'enviar_email',
            text: 'Envio Email',
            formatter: (cell, row) => envioFormatter('email', cell, row),
            sort: true
        },
        {
            dataField: 'situacao',
            text: 'Situação',
            formatter: (cell, row) => situacaoFormatter(cell),
            sort: true
        }
    ])


    const NoDataIndication = () => (
        <div className="spinner">
            <div className="rect1" />
            <div className="rect2" />
            <div className="rect3" />
            <div className="rect4" />
            <div className="rect5" />
        </div>
    );

    const RemotePagination = ({ loading, data, page, sizePerPage, onTableChange, totalSize }) => (
        <div>
            <PaginationProvider
                pagination={
                    paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                        alwaysShowAllBtns: true,
                        showTotal: true,
                        withFirstAndLast: true,
                        paginationTotalRenderer: (from, to, size) => {
                            const margin = {
                                paddingLeft: '4px'
                            }
                            return (
                                <span className="react-bootstrap-table-pagination-total" style={margin}>
                                    Exibindo {from} a {to} do total de {totalSize}.
                                </span>
                            )
                        },
                        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                    Exibir{" "}
                                    {
                                        <select
                                            name="datatable-basic_length"
                                            value={currSizePerPage}
                                            aria-controls="datatable-basic"
                                            className="form-control form-control-sm"
                                            onChange={e => onSizePerPageChange(e.target.value)}
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    }{" "}
                                    itens.
                                </label>
                            </div>
                        )
                    })
                }
            >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <div className="py-4 table-responsive">
                            <BootstrapTable
                                remote
                                loading={loading}
                                keyField="id"
                                noDataIndication={() => <NoDataIndication />}
                                bootstrap4={true}
                                bordered={false}
                                data={data}
                                columns={columns}
                                noDataIndication="Nenhum item encontrado"
                                onTableChange={onTableChange}
                                overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(150, 150, 150, 0.5)' }) } })}
                                {...paginationTableProps}
                            />
                        </div>
                    )
                }
            </PaginationProvider>
        </div>
    );


    return (
        <>
            <Modal
                className="modal-dialog-centered modal-warning"
                size="md"
                isOpen={showModalEditar}
                toggle={() => setShowModalEditar(!showModalEditar)}

            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                        Editar Mala-Direta
                    </h6>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                        <i className="fas fa-exclamation-circle ni-3x" />
                        <h4 className="heading mt-4"></h4>
                        <p>
                            Esta Mala-Direta será inativada temporariamente para prosseguir a edição. Você realmente deseja prosseguir?
                        </p>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => handlerCancelarEdicao()}
                    >
                        Fechar
                    </Button>
                    <Button
                        onClick={() => handlerEditar(malaToEdit)}
                        className="btn-white"
                        color="default"
                        type="button">
                        Editar
                    </Button>
                </div>
            </Modal>

            {
                malasDiretas && pageProperties &&
                <RemotePagination
                    data={malasDiretas}
                    page={pageProperties.page}
                    sizePerPage={pageProperties.perPage}
                    totalSize={pageProperties.total}
                    onTableChange={props.onTableChange}
                    loading={pageProperties.loading}
                />
            }
        </>
    );
}
