
import React, { useState, useEffect, useContext, Component } from 'react'
import AsyncBoard from 'react-trello'
import Board from 'react-trello'
import RealtimeBoard from 'react-trello'
import ReactBSAlert from "react-bootstrap-sweetalert";
import EmpresaContext from "../../../contexts/Empresa";
import api from "../../../services/api";
import { DivCard } from "./styles"

import {
    Spinner,
    Badge,
    Progress
} from "reactstrap";

export default ({
    lanes,
    onChangeEstagioExterno,
    onChangeMotivoEncerramentoExterno,
    handleLoadMoreCards,
    lastSearch,
    pageProperties,
    notify,
    loading,
    readOnly,
    ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [alert, setAlert] = useState(null)
    const [alertCrossSelling, setAlertCrossSelling] = useState(null)

    const [oportunidadeId, setOportunidadeId] = useState(undefined)
    const [listaOportunidades, setListaOportunidades] = useState({ lanes: [] })
    const [eventBus, setEventBus] = useState()

    const [runRefresh, setRunRefresh] = useState(false)

    const successAlert = msg => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px", maxWidth: "500px" }}
                title={msg}
                confirmBtnBsStyle="success"
                showConfirm={false}
                btnSize=""
            />
        );
        setTimeout(() => setAlert(null), 2000);
    };

    const confirmAlertUpdateFase = (
        title,
        msg,
        confirmFunction,
        cancelFunction,
        confirmButtonLabel = "Ok",
        confirmButtonColor = "primary"
    ) => {
        setAlert(
            <ReactBSAlert
                warning
                closeOnClickOutside={false}
                title={title}
                onCancel={cancelFunction}
                onConfirm={confirmFunction}
                showCancel
                confirmBtnColor={confirmButtonColor}
                confirmBtnText={confirmButtonLabel}
                cancelBtnText='Cancelar'
                btnSize=""
            >
                {msg}
            </ReactBSAlert>
        );
    };

    const confirmAlertCrossSelling = (
        title,
        msg,
        confirmFunction,
        confirmButtonLabel = "Ok",
        confirmButtonColor = "primary"
    ) => {
        setAlertCrossSelling(
            <ReactBSAlert
                warning
                closeOnClickOutside={false}
                title={title}
                onCancel={() => setAlertCrossSelling(null)}
                onConfirm={confirmFunction}
                showCancel
                confirmBtnColor={confirmButtonColor}
                confirmBtnText={confirmButtonLabel}
                cancelBtnText='Não'
                btnSize=""
            >
                {msg}
            </ReactBSAlert>
        );
    };

    function onChangeEstagio(oportunidadeId, proximoEstagio) {
        confirmAlertUpdateFase(
            'Alterar estágio',
            <>Deseja realmente alterar o estágio dessa oportunidade?</>,
            () => changeToEstagio(oportunidadeId, proximoEstagio), () => cancelChangeEstagio(), 'Sim'
        )
    }

    function cancelChangeEstagio() {
        setAlert(null);
        onChangeEstagioExterno()
    }

    async function changeToEstagio(oportunidadeId, nextEstagioId) {
        try {
            const response = await api.put(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/avancar-oportunidades`,
                {
                    estagio_id: nextEstagioId
                })

            const data = await response.data
            const data_encerramento = await data.data_encerramento
            const motivoEncerramento = await data.motivoEncerramento

            if (onChangeMotivoEncerramentoExterno && (data_encerramento && motivoEncerramento)) onChangeMotivoEncerramentoExterno(motivoEncerramento)

            if (onChangeEstagioExterno) onChangeEstagioExterno()

            successAlert("Estágio alterado")

            if (nextEstagioId == 3) {

                var valid = await thisOportunidadeContemProdutosDeclinados(oportunidadeId);
                if (valid) {
                    onChangeEstagioToVenda(oportunidadeId)
                }

            }

        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível alterar estagio')
        }
    }

    async function thisOportunidadeContemProdutosDeclinados(oportunidadeId) {
        try {
            const response = await api.get(`/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidadeId}/produtos_declinados`)
            var prodDeclidados = response.data
            var hasProdutosDeclinados = (prodDeclidados && prodDeclidados.length > 0);
            return hasProdutosDeclinados;
        } catch (error) {
            notify('danger', 'Não foi possivel carregar produtos declinados')
            return false;
        }
    }

    function onChangeEstagioToVenda(oportunidadeId) {
        confirmAlertCrossSelling(
            'Criar Cross-Selling',
            <>Deseja criar uma nova oportunidade a partir dos produtos declinados desta oportunidade encerrada?</>,
            () => criarNovaOportunidadeCrossSelling(oportunidadeId), 'Sim'
        )
    }

    function goToNewCrossSelling(oportunidadeId) {
        props.history.push('/admin/oportunidades/new', { oportunidadeToCrossSelling: oportunidadeId, tipoCrossSelling: 'produtos_declinados' })
    }

    async function criarNovaOportunidadeCrossSelling(oportunidadeId) {
        try {
            goToNewCrossSelling(oportunidadeId);
        } catch (error) {
            console.log(error)
            if (notify) notify('danger', 'Não foi possível abrir nova oportunidade')
        }
    }


    const shouldReceiveNewData = (nextData) => {
        // console.log('OnDataChange')
        // console.log('New card has been added')
        // console.log(nextData)
    }

    const handleCardAdd = (card, laneId) => {
        // console.log(`New card added to lane ${laneId}`)
        // console.dir(card)
    }

    const handleCardClick = (oportunidadeId) => {
        // console.log(`CLICOU NO CARD`)
        // console.log(oportunidadeId)
        goToEdit(oportunidadeId)
    }

    async function handleLaneScroll(requestedPage, laneId) {
        // console.log(`SCROLL INFINITO`)
        // console.log(requestedPage)
        // console.log(laneId)

        return await handleLoadMoreCards(requestedPage,
            10,
            "data_agendamento",
            "desc",
            lastSearch,
            laneId)

    }

    function goToEdit(id) {
        props.history.push(`/admin/oportunidades/${new Number(id)}/edit`)
    }

    const handleDragStart = (cardId, laneId) => {
        // console.log('drag started')
        // console.log(`cardId: ${cardId}`)
        // console.log(`laneId: ${laneId}`)
    }

    const handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {
        // console.log('drag ended')
        // console.log(`cardId: ${cardId}`)
        // console.log(`sourceLaneId: ${sourceLaneId}`)
        // console.log(`targetLaneId: ${targetLaneId}`)

        if (sourceLaneId != targetLaneId) {
            if (sourceLaneId != 3) {
                onChangeEstagio(cardId, targetLaneId)
            } else {
                notify('danger', 'Para mover Oportunidades em Estágio de Venda é necessário restaurá-la. Edite a Oportunidade para isso.')
                if (onChangeEstagioExterno) onChangeEstagioExterno()
            }
        }
    }

    const CustomLaneHeader = ({ label, cards, title, barColor, current, target }) => {

        return (
            <div>
                <div
                    style={{ height: 5, marginBottom: 5, backgroundColor: barColor, borderRadius: 3 }}
                >
                </div>

                <header
                    style={{
                        borderBottom: '2px solid #c5c5c5',
                        paddingBottom: 6,
                        marginBottom: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                    <div style={{ fontSize: 14, fontWeight: 'bold' }}>{title}</div>

                </header>
            </div>
        )
    }

    const CustomCard = props => {

        // console.log({props})

        return (
            <DivCard
                {...props}
                draggable={props.cardDraggable}
            >
                <header
                    style={{
                        borderBottom: '1px solid #eee',
                        paddingBottom: 6,
                        marginBottom: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        color: props.cardColor
                    }}>
                    <div style={{ fontSize: 14, fontWeight: 'bold' }}>{props.title}</div>
                    <div style={{ fontSize: 11 }}>{props.dueOn}</div>
                </header>
                <div style={{ fontSize: 14, color: '#4C4C4C' }}>
                    <div style={{ fontWeight: 'bold' }}>{props.subTitle}</div>
                    <div style={{ padding: '5px 0px' }} dangerouslySetInnerHTML={{ __html: props.description }} ></div>
                    <div style={{ marginTop: 10, textAlign: 'center', color: props.cardColor, fontSize: 15, fontWeight: 'bold' }}>
                        {props.escalationText}
                    </div>
                    {props.tags && props.tags.length > 0 &&
                        <div
                            style={{
                                borderTop: '1px solid #eee',
                                paddingTop: 6,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                flexWrap: 'wrap'
                            }}>
                            {props.tags.map(tag => (
                                <Badge
                                    id={tag.tagId}
                                    color={tag.bgcolor}
                                    className="badge-lg"
                                    pill
                                >
                                    {tag.title}
                                </Badge>
                            ))}
                        </div>
                    }

                </div>
            </DivCard>
        )
    }

    const components = {
        LaneHeader: CustomLaneHeader,
        Card: CustomCard
    };


    return (
        <>
            {alert}
            {alertCrossSelling}

            {/* <AsyncBoard */}
            {/* <Board */}
            {/* <RealtimeBoard */}
            <RealtimeBoard
                components={components}
                data={{lanes}}
                onCardClick={handleCardClick}
                onCardAdd={handleCardAdd}
                onDataChange={shouldReceiveNewData}
                eventBusHandle={setEventBus}
                handleDragStart={handleDragStart}
                handleDragEnd={handleDragEnd}
                hideCardDeleteIcon={true}
                onLaneScroll={handleLaneScroll}
                style={{
                    fontFamily: 'inherit',
                    backgroundColor: '#ffffff',
                    height: 'auto',
                    paddingLeft: 0
                }}
                tagStyle={{
                    fontSize: '85%'
                }}
            />
        </>
    );


}
