import React, { useState } from 'react';
import Table from '../../../components/Table'
import { MenuComportamento } from '../../../components/Menus'


export default ({ personas, pageProperties, onTableChange, notify, ...props }) => {

    const [columns, setColumns] = useState([
        {
            dataField: 'acoes',
            formatter: (cell, row) => acoesFormatter(cell, row, this)
        },
        {
            dataField: "nome",
            text: 'Nome',
        },
    ])

    function goToEdit(id) {
        props.history.push(`/admin/personas/${new Number(id)}/edit`)
    }

    const acoesFormatter = (cell, row, context) => {
        return (
            <MenuComportamento
                actions={[{
                    label: 'Alterar',
                    icons: 'far fa-edit',
                    onClick: () => goToEdit(row.id)
                }]}
            />
        )
    }



    return (
        <>
            <Table
                columns={columns}
                data={personas}
                pageProperties={pageProperties}
                onTableChange={onTableChange}
            />
        </>
    );
}

