import React, { useState, useRef, useContext } from "react";
import EmpresaContext from "../../../../../../contexts/Empresa";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import api from "../../../../../../services/api";
//@ts-ignore
import NotificationAlert from "react-notification-alert";
import Filters from "./Filters";

import { PoliticaPrivacidade } from "../../../../../../entities/Common";

import Table from "./Table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "../../../../../../components/Headers/SimpleHeader";

const PoliticasPrivacidade: React.FC = ({}) => {
  const history = useHistory();
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [politicasPrivacidade, setPoliticasPrivacidade] = useState(
    new Array<PoliticaPrivacidade>()
  );
  const [pageProperties, setPageProperties] = useState({
    total: "0",
    perPage: 10,
    page: 1,
    lastPage: 1,
    loading: false,
  });
  const [lastSearch, setLastSearch] = useState({});
  const notificationAlert = useRef<NotificationAlert>(null);

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlert?.current?.notificationAlert(options);
  }

  function handleNewPolitica(e: React.MouseEvent) {
    e.preventDefault();
    history.push("/admin/politicas-privacidade/new");
  }

  async function updatePageProperties(response: AxiosResponse) {
    const { total, perPage, page, lastPage } = await response.data;
    setPageProperties({
      total,
      perPage,
      page,
      lastPage,
      loading: false,
    });
  }

  async function loadPoliticasPrivacidade(
    page = 1,
    limit = 10,
    sortField = "nome",
    sortOrder = "asc",
    filters = lastSearch
  ) {
    try {
      setPageProperties({
        ...pageProperties,
        loading: true,
      });
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/politicas-privacidade/`,
        {
          params: {
            page,
            limit,
            sortField,
            sortOrder,
            ...filters,
          },
        }
      );
      setPoliticasPrivacidade(response.data.data);
      await updatePageProperties(response);
    } catch (err) {
      //@ts-ignore
      console.log(err.response);
      notify(
        "danger",
        "Houve um problema ao carregar as Politicas de Privacidade."
      );
    }
  }

  async function handleTableChange(
    type: string,
    sortProperties: {
      page: number;
      sizePerPage: number;
      sortField: string;
      sortOrder: string;
    }
  ): Promise<void> {
    try {
      await loadPoliticasPrivacidade(
        sortProperties.page == 0 ? 1 : sortProperties.page,
        sortProperties.sizePerPage,
        sortProperties.sortField,
        sortProperties.sortOrder
      );
    } catch (error) {
      notify(
        "danger",
        "Houve um problema ao carregar as Politicas de Privacidade."
      );
    }
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <div className="container">
        <Row>
          <Col>
            <Filters
              title={<h1>Politicas de Privacidade</h1>}
              data={politicasPrivacidade}
              notify={notify}
              load={(filters: object) => {
                setLastSearch(filters);
                loadPoliticasPrivacidade(1, 10, "nome", "asc", filters);
              }}
            />

            <Row>
              <Col xs="12">
                <span>
                  <Button
                    color="primary"
                    type="button"
                    onClick={handleNewPolitica}
                    size="sm"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add" />
                    </span>
                    Nova Política
                  </Button>
                </span>
              </Col>

              <Col>
                <Table
                  politicasPrivacidade={politicasPrivacidade || []}
                  notify={notify}
                  onTableChange={handleTableChange}
                  pageProperties={pageProperties}
                  loading={loading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PoliticasPrivacidade;
