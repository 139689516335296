import React, { useState, useContext } from 'react';
import EmpresaContext from "../../../../contexts/Empresa";
import moment from 'moment';
import Table from '../../../../components/Table'
import TimeTrack from '../../../../components/TimeTracker'
import Avatar from '../../../../components/Avatar'
import { MenuComportamento } from '../../../../components/Menus'

// reactstrap components
import {
    Badge,
    Button
} from "reactstrap";


export default ({ usuarios, pageProperties, onTableChange, notify, ...props }) => {

    const { empresaSelecionada, changeEmpresaSelecionada, empresas } = useContext(EmpresaContext)
    const [columns] = useState([
        {
            dataField: 'acoes',
            formatter: (cell, row) => acoesFormatter(cell, row, this)
        },
        {
            dataField: 'designer_responsavel_id',
            text: 'Designer',
            align: 'center',
            headerAlign: 'center',
            formatter: (row, column) => designerFormatter(row, column),
            sort: true
        },
        {
            dataField: "empresa.nome",
            text: 'Empresa',
            align: 'center',
            headerAlign: 'center',
            sort: false
        },
        {
            dataField: 'faseAtual.nome',
            text: 'Fase Atual',
            align: 'center',
            headerAlign: 'center',
            sort: false
        },
        {
            dataField: "data_postagem",
            text: 'Postagem',
            formatter: (field, publicacao) => tempoRestantePostagemFormatter(publicacao),
            align: 'center',
            headerAlign: 'center',
            sort: true
        },
        {
            text: 'Em criação',
            formatter: (field, publicacao) => tempoEmCriacaoFormatter(publicacao),
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "marketing.historicos_publicacoes.created_at",
            text: 'Duração da criação',
            formatter: (field, publicacao) => tempoEstimadoCriacaoFormatter(publicacao),
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: 'trafego.nome',
            text: 'Tráfego',
            align: 'center',
            headerAlign: 'center',
            sort: true,
        },
        {
            dataField: 'data_postagem',
            text: 'Data Postagem',
            formatter: (field, publicacao) => dateFormatter(field),
            align: 'center',
            headerAlign: 'center',
            sort: true,
        },
        {
            dataField: "titulo",
            text: 'Título',
            sort: true
        },
        {
            dataField: "formato.nome",
            text: 'Formato',
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: 'situacao',
            text: 'Situação',
            formatter: (row, column) => situacaoFormatter(row, column),
            align: 'center',
            headerAlign: 'center',
            sort: true,
        },
    ])


    const dateFormatter = (field) => {
        const dt = new Date(field)
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return (
            <>
                {dt.toLocaleDateString('pt-br', options)}
            </>
        )
    }

    const designerFormatter = (cell, row) => (
        <>
            {
                row.designer_responsavel_id &&
                <Avatar
                    title={row.designerResponsavel.name}
                    user={row.designerResponsavel}
                    className='avatar-xs'
                />
            }
        </>
    )

    const tempoEstimadoCriacaoFormatter = ({ formato: { calibragem }, empresa }) => (
        <>
            {
                (calibragem && calibragem.length > 0) ?
                    <Badge
                        className="badge-lg"
                        color='secondary'
                        pill>
                        {`${calibragem[0].dias} dias`}
                    </Badge>
                    :
                    <label title="Talvez a empresa não possua calibragens configuradas" className="text-muted">-</label>
            }
        </>
    )

    const tempoRestantePostagemFormatter = ({ data_postagem }) => (
        <Badge
            className="badge-lg"
            color='secondary'
            pill>
            {moment().to(moment(data_postagem))}
        </Badge>
    )

    const isUtrapassado = (calibragem, foiParaCriacaoEm, data_postagem) => {
        if (calibragem && foiParaCriacaoEm) {
            const postagem = moment(data_postagem)
            const newFoiParaCriacao = moment(foiParaCriacaoEm)
            newFoiParaCriacao.add(calibragem, 'days')
            return newFoiParaCriacao.isAfter(postagem) || moment().isAfter(newFoiParaCriacao)
        }
        return false
    }

    const tempoEmCriacaoFormatter = ({ historicos, formato, empresa_id, data_postagem }) => {
        const calibragem = formato.calibragem.find(item => item.empresa_id = empresa_id)
        const foiParaCriacaoEm = historicos.length > 0 ? moment(historicos[0].created_at) : null
        if (historicos.length === 0) return <label className="text-muted">Não está em criação</label>
        return (
            <>
                {
                    (historicos.length > 0 && calibragem)
                        ?
                        < Badge
                            className="badge-lg"
                            color={isUtrapassado(calibragem, foiParaCriacaoEm, data_postagem) ? 'warning' : 'secondary'}
                            pill >
                            {moment().to(foiParaCriacaoEm)}
                        </Badge >
                        : <label title="Talvez a empresa não possua calibragens configuradas" className="text-muted">-</label>
                }
            </>
        )

    }

    const timetrackFormatter = (publicacao) => {
        const historicos = publicacao.historicos
        const calibragem = publicacao.formato.calibragem.find(item => item.empresa_id = publicacao.empresa_id)
        if (historicos.length > 0 && calibragem) {
            const date = new Date(historicos[0].created_at)
            let tempoLimite = moment(date)
            tempoLimite = tempoLimite.add(calibragem.dias, 'days')
            return (
                <TimeTrack
                    size='sm'
                    time={(new Date()).getTime()}
                    timeDestiny={tempoLimite}
                />
            )
        }
        return (
            <label title="Talvez a empresa não possua calibragens configuradas" className="text-muted">-</label>
        )

    }

    const acoesFormatter = (cell, row, context) => {

        return (
            <MenuComportamento
                actions={[
                    {
                        label: 'Ver/Alterar',
                        icon: 'far fa-edit',
                        onClick: () => goToEdit(row)
                    }
                ]}
            />
        )
    }


    const situacaoFormatter = (cell, row) => {
        let situacao = ''
        let color = 'secondary'
        switch (row.situacao) {
            case 'A':
                situacao = 'Ativo'
                color = 'success'
                break;
            case 'C':
                situacao = 'Cancelada'
                color = 'danger'
                break;
            case 'P':
                situacao = 'Ponderada'
                color = 'warning'
                break;
        }
        return (

            <>
                <Badge color={color} className="badge-lg" pill>
                    {situacao}
                </Badge>
            </>
        )
    }


    function goToEdit(publicacao) {
        const empresa = empresas.find(item => item.id === publicacao.empresa_id)
        changeEmpresaSelecionada(empresa)
        props.history.push(`/admin/publicacoes/${new Number(publicacao.id)}/edit`)
    }



    return (
        <>
            <Table
                columns={columns}
                data={usuarios}
                pageProperties={pageProperties}
                onTableChange={onTableChange}
            />
        </>
    );
}

