import React, { useState, useEffect } from 'react';
import api from "../../../../services/api";
import { Empresa } from "../../../../entities/Common";
import { ApiAccess } from "../../../../entities/Security";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";

import Emails from './Emails'
import Sms from "./CrendenciaisMensagens";
import {
    Container,
    ButtonContainer,
    InfoToken,
    LabelToken
} from './styles';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Row,
    Col,
    PopoverBody,
    UncontrolledPopover,
    UncontrolledTooltip
} from 'reactstrap'

interface IIntegracoes {
    empresa?: Empresa,
    onEmpresaChange(): void,
    notify(type: string, msg: string): void
}

const Integracoes: React.FC<IIntegracoes> = ({
    empresa,
    onEmpresaChange,
    notify
}) => {

    const [showEmailForm, setShowEmalForm] = useState(false)
    const [apiAccess, setApiAccess] = useState<ApiAccess>({} as ApiAccess)
    const [showSmsForm, setShowSmsForm] = useState(false)
    const [textCopiar, setTextCopiar] = useState('Copiar token')

    useEffect(() => {
        if (empresa && empresa.id) loadApiAccess()
    }, [empresa])

    function hanleNewEmail(e: React.MouseEvent) {
        e.preventDefault()
        setShowEmalForm(true)
    }

    function handleNewSms(e: React.MouseEvent) {
        e.preventDefault()
        setShowSmsForm(true)
    }


    async function loadApiAccess() {
        try {
            const response = await api.get(`/common/empresas/${empresa?.id}/api-access`)
            setApiAccess(response?.data)
        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível carregar empresa')
        }
    }


    return (
        <>
            <Container>
                <ButtonContainer>
                    <UncontrolledDropdown group>
                        <DropdownToggle caret color="primary">
                            <span className="btn-inner--icon">
                                <i className="ni ni-fat-add"></i>
                            </span>
                            <span className="btn-inner--text">Novo</span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href="#pablo" onClick={hanleNewEmail}>
                                Email
                            </DropdownItem>
                            <DropdownItem href="#pablo" onClick={handleNewSms}>
                                SMS / Whatsapp
                                </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <InfoToken id='token-9922'>
                        <i className="fas fa-info-circle"></i>
                    </InfoToken>
                    <UncontrolledPopover placement="top" target="token-9922">
                        <PopoverBody>
                            Este é o token da sua empresa, você pode usá-lo para integrar outras aplicações ao Playnee.<br />
                            <CopyToClipboard
                                text={apiAccess?.token}
                                onCopy={() => setTextCopiar('Copiado')}
                            >
                                <LabelToken
                                    className='text-muted'
                                    id='tolltip-token-uhuhuh'
                                >
                                    {apiAccess?.token}
                                </LabelToken>
                            </CopyToClipboard>
                            <UncontrolledTooltip
                                delay={0}
                                placement="top"
                                target="tolltip-token-uhuhuh"
                            >
                                {textCopiar}
                            </UncontrolledTooltip>
                        </PopoverBody>
                    </UncontrolledPopover>
                </ButtonContainer>
                <Row className='mt-4'>
                    <Col>
                        <Emails
                            showNewEmail={showEmailForm}
                            hiddeNewEmail={() => setShowEmalForm(false)}
                            empresa={empresa}
                            notify={notify}
                            onEmpresaChange={onEmpresaChange}
                        />
                    </Col>
                    <Col>
                        <Sms
                            showNewCredencial={showSmsForm}
                            setShowNewCredencial={setShowSmsForm}
                            hiddeNewCredencial={() => setShowSmsForm(false)}
                            empresa={empresa}
                            notify={notify}
                            onEmpresaChange={onEmpresaChange}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Integracoes;