import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import EmpresaContext from "../../../../contexts/Empresa";
import moment from "moment";
import { usePersistedState } from "../../../../hooks";

import Filters from "../../../../components/Headers/Filters";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import Select from "react-select";
import {
  CardBody,
  Row,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import useReports from "../useReports";

const firstDayOfCadastro = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth());
};

export default ({ title, load, history, notify, ...props }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [dataCadastroInicial, setDataCadastroInicial] = usePersistedState(
    "dataCadastroInicial",
    firstDayOfCadastro()
  );
  const [dataCadastroFinal, setDataCadastroFinal] = usePersistedState(
    "dataCadastroFinal",
    moment()
  );
  const [equipes, setEquipes] = usePersistedState("equipes", []);
  const [campanha, setCampanha] = usePersistedState("campanha", null);
  const [anuncio, setAnuncio] = usePersistedState("anuncio", null);

  const [campanhas, setCampanhas] = useState([]);
  const [anuncios, setAnuncios] = useState([]);
  const [equipesList, setEquipesList] = useState([]);
  const [monitorClearFilters, setMonitorClearFilters] = useState(undefined);

  const [empresasGrupo, setEmpresasGrupo] = useState([]);
  const [empresaGrupoId, setEmpresaGrupoId] = useState(null);
  const { fetchEmpresasGrupo, fetchEquipes, fetchAnuncios, fetchCampanhas } = useReports();

  const equipesRef = useRef(null);

  useEffect(() => {
    loadEmpresasGrupo();
  }, []);
  
  useEffect(() => {
    if (empresaGrupoId) {
      loadEquipes();
      loadCampanhas();
    }
  }, [empresaGrupoId])

  useEffect(() => {
    if (monitorClearFilters) {
      search();
    }
  }, [monitorClearFilters]);


  useEffect(() => {
    if (equipesList && equipesList.length > 0) {
      fillEquipes();
    }
  }, [equipesList]);

  useEffect(() => {
    if (equipes.length === 0) fillEquipes();
  }, [equipes]);

  function fillEquipes() {
    if (equipesList && equipesList.length > 0) {
      setEquipes(equipesList.map((item) => item.id));
    }
  }

  function handleMultipleSelect(target, state) {
    const array = Array.from(target.selectedOptions);
    state(array.map((item) => item.value));
  }

  async function loadEmpresasGrupo() {
    const empresas = await fetchEmpresasGrupo(empresaSelecionada.id);
    setEmpresasGrupo(empresas);
  }

  async function loadCampanhas() {
    const campanhas = await fetchCampanhas(empresaGrupoId)
    setCampanhas(campanhas);
  }

  async function loadEquipes() {
    const equipes = await fetchEquipes(empresaGrupoId);
    setEquipesList(equipes);
  }

  async function loadAnuncios(campanha_id) {
    setCampanha(campanha_id);
    setAnuncio(null);

    const anuncios = await fetchAnuncios(campanha_id, empresaGrupoId)
    setAnuncios(anuncios);
    // setAnuncios(Array.from(data));
  }
  
  async function search() {
    await load({
      dataCadastroInicial,
      dataCadastroFinal,
      equipes,
      campanha,
      anuncios: anuncio && anuncio > 0 ? [anuncio] : [],
    }, empresaGrupoId);
  }

  async function handleClearFilter() {
    setDataCadastroInicial(firstDayOfCadastro());
    setDataCadastroFinal(new Date());
    setEquipes([]);
    setCampanha(null);
    setAnuncio(null);
    setEmpresaGrupoId(null)
  }

  const customStyles = {
    container: (provided) => ({ ...provided, flex: 1 }),
    control: (provided) => ({ ...provided, minHeight: "46px" }),
  };

  return (
    <>
      <Filters
        title={title}
        onSearch={search}
        clearFilters={handleClearFilter}
        onFiltersClead={setMonitorClearFilters}
      >
        <CardBody>
          <Row className="mb-4">
            <Col xs="auto" lg="6" sm="12" md="12">
              <fieldset className="border p-2">
                <legend className="w-auto" style={{ margin: 0 }}>
                  <label className="form-control-label">Cadastro</label>
                </legend>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Início
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "Data inicial",
                            style: {
                              width: "100px",
                            },
                          }}
                          value={moment(dataCadastroInicial)}
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              dataCadastroInicial._d + "" ===
                                currentDate._d + ""
                            ) {
                              classes += " start-date";
                            } else if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              new Date(dataCadastroInicial._d + "") <
                                new Date(currentDate._d + "") &&
                              new Date(dataCadastroFinal._d + "") >
                                new Date(currentDate._d + "")
                            ) {
                              classes += " middle-date";
                            } else if (
                              dataCadastroFinal &&
                              dataCadastroFinal._d + "" === currentDate._d + ""
                            ) {
                              classes += " end-date";
                            }
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          onChange={(e) => setDataCadastroInicial(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Fim
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "Data final",
                            style: {
                              width: "100px",
                            },
                          }}
                          style={{ width: "100px" }}
                          value={moment(dataCadastroFinal)}
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              dataCadastroInicial._d + "" ===
                                currentDate._d + ""
                            ) {
                              classes += " start-date";
                            } else if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              new Date(dataCadastroInicial._d + "") <
                                new Date(currentDate._d + "") &&
                              new Date(dataCadastroFinal._d + "") >
                                new Date(currentDate._d + "")
                            ) {
                              classes += " middle-date";
                            } else if (
                              dataCadastroFinal &&
                              dataCadastroFinal._d + "" === currentDate._d + ""
                            ) {
                              classes += " end-date";
                            }
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          onChange={(e) => setDataCadastroFinal(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>
          <Row className="py-4">
          <Col xs="12" sm="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Empresa
                </label>
                <InputGroup className="input-group-alternative">
                  <Select2
                    defaultValue="-1"
                    onSelect={(e) => setEmpresaGrupoId(e.target.value)}
                    options={{
                      placeholder: 'Selecione uma empresa',
                    }}
                    value={empresaGrupoId}
                    data={empresasGrupo.map((item) => ({
                      id: item.id,
                      text: item.nome,
                    }))}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="12" sm="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Equipes
                </label>
                <InputGroup className="">
                  <Select
                    closeMenuOnSelect={false}
                    placeholder="Selecione suas equipes..."
                    styles={customStyles}
                    isMulti
                    ref={equipesRef}
                    onChange={(values) => {
                      console.log({ values });
                      setEquipes(values.map((e) => e.value));
                    }}
                    options={equipesList.map((item) => ({
                      value: item.id,
                      label: item.nome,
                    }))}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="12" sm="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Campanha
                </label>
                <InputGroup className="input-group-alternative">
                  <Select2
                    defaultValue="-1"
                    onSelect={(e) => loadAnuncios(e.target.value)}
                    options={{
                      placeholder: "Selecione uma campanha...",
                    }}
                    value={campanha}
                    data={campanhas.map((item) => ({
                      id: item.id,
                      text: item.nome,
                    }))}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="12" sm="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Anúncio
                </label>
                <InputGroup className="input-group-alternative">
                  <Select2
                    defaultValue="-1"
                    options={{
                      placeholder: "Selecione uma anúncio...",
                    }}
                    onSelect={(e) => setAnuncio(e.target.value)}
                    value={anuncio}
                    data={anuncios.map((item) => ({
                      id: item.id,
                      text: item.nome,
                    }))}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Filters>
    </>
  );
};
