import React, { useState, useEffect, useContext } from 'react';
import ReactDatetimeClass from 'react-datetime';

import EmpresaContext from "../../../contexts/Empresa";
import api from "../../../services/api";
import { usePersistedState } from '../../../hooks';
import moment from 'moment';
 
import Select2 from "react-select2-wrapper";
import Filters from '../../../components/Headers/Filters';
import {
    Input,
    Row,
    Col,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
} from "reactstrap";

export default ({ title, notify, load, ...props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)

    const [empresasList, setEmpresasList] = useState([])
    const [empresasAcoes, setEmpresasAcoes] = usePersistedState('empresasAcoes', [])

    const [userAcoes, setUsersAcoes] = usePersistedState('usersAcoes', [])
    const [usersComboBox, setUsersComboBox] = useState([])

    const [situacaoAcoes, setSituacaoAcoes] = usePersistedState('situacaoAcoes', [])

    const [estrategiaAcoes, setEstrategiaAcoes] = usePersistedState('estrategiaAcoes', null)
    const [estrategias, setEstrategias] = useState([])

    const [grupoEstrategicoAcoes, setGrupoEstrategicoAcoes] = usePersistedState('grupoEstrategicoAcoes', null)
    const [gruposEstrategicos, setGruposEstrategicos] = useState([])

    const [dataAgendamentoInicialAcoes, setDataAgendamentoInicialAcoes] = usePersistedState('dataAgendamentoInicialAcoes', getDataAgendamentoInicialAcoes())
    const [dataAgendamentoFinalAcoes, setDataAgendamentoFinalAcoes] = usePersistedState('dataAgendamentoFinalAcoes', getDataAgendamentoFinalAcoes())

    //Flag para definir tempo de execução
    const [runLoad, setRunLoad] = useState(props.search ? props.search : true)
    const [monitorClearFilters, setMonitorClearFilters] = useState(undefined)

    useEffect(() => {
        if (monitorClearFilters) search()
    }, [monitorClearFilters])

    useEffect(() => {
        if (runLoad) {
            search()
            setRunLoad(false)
        }
    }, [runLoad])

    useEffect(() => {
        if (empresasList && empresasList.length === 0) loadEmpresas()        
        if (gruposEstrategicos && gruposEstrategicos.length === 0) loadGruposEstrategicos()
    }, [])

    useEffect(() => {
        if (empresasAcoes && empresasAcoes.length > 0) {
            loadUserByEmpresaBox()
        } else {
            loadUser()
        }

    }, [empresasAcoes])

    useEffect(() => {
        loadEstrategias()
    }, [grupoEstrategicoAcoes])

    function getDataAgendamentoInicialAcoes() {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth());
    }

    function getDataAgendamentoFinalAcoes() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    async function loadEmpresas() {
        try {
            const response = await api.get('security/sessions/empresas')
            if (response.data) {
                setEmpresasList(response.data)
            }
        } catch (error) {
            console.log(error)
            throwError('Não foi possível carregar empresas')
        }
    }


    async function loadUser() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/colaboradores`)
            setUsersComboBox(await response.data)
        } catch (error) {
            console.error(error)
        }
    }

    async function loadUserByEmpresaBox() {
        try {
            const response = await api.get(`common/empresas/${empresaSelecionada?.id}/colaboradoresbyempresas`,
                {
                    params: {
                        empresa_id: empresasAcoes,
                        empresas: empresasAcoes
                    }
                }
            )
            setUsersComboBox(await response.data)
        } catch (error) {
            console.error(error)
        }
    }

    async function loadEstrategias() {
        if (!grupoEstrategicoAcoes) {
            setEstrategiaAcoes(null)
            setEstrategias([])
            return
        }
        try {
            const response = await api.get(`mentoring/grupos-estrategicos/${grupoEstrategicoAcoes}/estrategias`)
            setEstrategias(await response.data)
        } catch (error) {
            console.error(error)
        }
    }

    async function loadGruposEstrategicos() {
        try {
            const response = await api.get(`/mentoring/grupos-estrategicos`)
            const data = await response.data
            setGruposEstrategicos(data)
        } catch (error) {
            console.error(error)
        }
    }


    function throwError(text) {
        if (notify) notify('danger', text)
    }

    async function search() {
        await load({
            empresa_id: empresasAcoes ? empresasAcoes : [],
            colaboradores: userAcoes ? userAcoes : [],
            situacoes: situacaoAcoes ? situacaoAcoes : [],
            grupoestrategico_id: grupoEstrategicoAcoes,
            estrategia_id: estrategiaAcoes,
            data_inicio: dataAgendamentoInicialAcoes,
            data_fim: dataAgendamentoFinalAcoes
        })
    }

    function handleMultipleSelect(target, state) {
        const array = Array.from(target.selectedOptions);
        state(array.map((item) => item.value));
    }

    async function clearFilters() {
        setDataAgendamentoInicialAcoes(getDataAgendamentoInicialAcoes);
        setDataAgendamentoFinalAcoes(getDataAgendamentoFinalAcoes);   

        setEmpresasAcoes([]);
        setUsersAcoes([]);
        loadUser();
        setSituacaoAcoes([]);

        setGrupoEstrategicoAcoes(0);
        setEstrategiaAcoes(0);
        
    }

    return (
        <>
            <Filters
                onSearch={search}
                title={title}
                clearFilters={clearFilters}
                onFiltersClead={setMonitorClearFilters}
            >
                <Row>
                    <Col xs={4}>
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="example-number-input"
                            >
                                Empresas
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    multiple
                                    onSelect={(e) => handleMultipleSelect(e.target, setEmpresasAcoes)}
                                    onUnselect={(e) => handleMultipleSelect(e.target, setEmpresasAcoes)}
                                    options={{
                                        placeholder: "Selecione as Empresas"
                                    }}
                                    value={empresasAcoes}
                                    data=
                                    {                         
                                        empresasList.sort(function (a, b) { return (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0) }).map((item) => (
                                            {
                                                id: item.id,
                                                text: item.nome
                                            }
                                        ))
                                    }
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>


                    <Col xs={4}>
                        <FormGroup>
                            <Label for="exampleSelect">Colaboradores</Label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    multiple
                                    onSelect={(e) => handleMultipleSelect(e.target, setUsersAcoes)}
                                    onUnselect={(e) => handleMultipleSelect(e.target, setUsersAcoes)}
                                    options={{
                                        placeholder: "Selecione colaboradores"
                                    }}
                                    value={userAcoes}
                                    data={
                                        //usersComboBox.map((item) => ({ id: item.id, text: item.name }))
                                        usersComboBox.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0) }).map((item) => (
                                            {
                                                id: item.id,
                                                text: item.name
                                            }
                                        ))
                                    }
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col xs={4}>
                        <FormGroup>
                            <Label for="exampleSelect">Situação</Label>
                            <Select2
                                className="form-control"
                                //@ts-ignore
                                //onSelect={(e) => setSituacao(e.target.value)}
                                multiple
                                onSelect={(e) => handleMultipleSelect(e.target, setSituacaoAcoes)}
                                onUnselect={(e) => handleMultipleSelect(e.target, setSituacaoAcoes)}
                                value={situacaoAcoes}
                                options={{
                                    placeholder: "Selecione Situações"
                                }}
                                data={[
                                    { id: 'nao_iniciado', text: 'Não Iniciado' },
                                    { id: 'iniciado', text: 'Iniciado' },
                                    { id: 'concluido', text: 'Concluído' },
                                    { id: 'nao_executado', text: 'Não Executado', }
                                ]}
                            />
                        </FormGroup>
                    </Col>
                
                </Row>

                <Row>

                <Col xs="6" lg="6" sm="6" md="6">
                        <fieldset className="border p-2">
                            <legend className="w-auto" style={{ margin: 0 }}>
                                <label
                                    className="form-control-label"
                                >
                                    Estratégia
                                        </label>
                            </legend>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="exampleSelect"> Grupo Estratégico</Label>
                                        <Select2
                                            className="form-control"
                                            //@ts-ignore
                                            onSelect={(e) => setGrupoEstrategicoAcoes(e.target.value)}
                                            value={grupoEstrategicoAcoes}
                                            data={
                                                //gruposEstrategicos.map(item => ({ id: item.id, text: item.nome }))
                                                gruposEstrategicos.sort(function (a, b) { return (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0) }).map((item) => (
                                                    {
                                                        id: item.id,
                                                        text: item.nome
                                                    }
                                                ))
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="exampleSelect">Estratégia</Label>
                                        <Select2
                                            className="form-control"
                                            //@ts-ignore
                                            onSelect={(e) => setEstrategiaAcoes(e.target.value)}
                                            value={estrategiaAcoes}
                                            data={
                                                //estrategias.map(item => ({ id: item.id, text: item.nome }))
                                                estrategias.sort(function (a, b) { return (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0) }).map((item) => (
                                                    {
                                                        id: item.id,
                                                        text: item.nome
                                                    }
                                                ))

                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>

                    <Col xs="auto" lg="6" sm="6" md="6">
                        <fieldset className="border p-2">
                            <legend className="w-auto" style={{ margin: 0 }}>
                                <label
                                    className="form-control-label"
                                >
                                    Quando
                                        </label>
                            </legend>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example-number-input"
                                        >
                                            Início
                                        </label>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: "Início",
                                                    style: {
                                                        width: '100px'
                                                    }
                                                }}
                                                //COMENTADO PORQUE É TESTE DE DATA
                                                value={moment(dataAgendamentoInicialAcoes)}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat={false}
                                                renderDay={(props, currentDate, selectedDate) => {
                                                    let classes = props.className;
                                                    if (
                                                        dataAgendamentoInicialAcoes &&
                                                        dataAgendamentoFinalAcoes &&
                                                        dataAgendamentoInicialAcoes._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " start-date";
                                                    } else if (
                                                        dataAgendamentoInicialAcoes &&
                                                        dataAgendamentoFinalAcoes &&
                                                        new Date(dataAgendamentoInicialAcoes._d + "") <
                                                        new Date(currentDate._d + "") &&
                                                        new Date(dataAgendamentoFinalAcoes._d + "") >
                                                        new Date(currentDate._d + "")
                                                    ) {
                                                        classes += " middle-date";
                                                    } else if (
                                                        dataAgendamentoFinalAcoes &&
                                                        dataAgendamentoFinalAcoes._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " end-date";
                                                    }
                                                    return (
                                                        <td {...props} className={classes}>
                                                            {currentDate.date()}
                                                        </td>
                                                    );
                                                }}
                                                onChange={e => setDataAgendamentoInicialAcoes(e)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example-number-input"
                                        >
                                            Fim
                                                </label>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: "Fim",
                                                    style: {
                                                        width: '100px'
                                                    }
                                                }}
                                                value={moment(dataAgendamentoFinalAcoes)}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat={false}
                                                renderDay={(props, currentDate, selectedDate) => {
                                                    let classes = props.className;
                                                    if (
                                                        dataAgendamentoInicialAcoes &&
                                                        dataAgendamentoFinalAcoes &&
                                                        dataAgendamentoInicialAcoes._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " start-date";
                                                    } else if (
                                                        dataAgendamentoInicialAcoes &&
                                                        dataAgendamentoFinalAcoes &&
                                                        new Date(dataAgendamentoInicialAcoes._d + "") <
                                                        new Date(currentDate._d + "") &&
                                                        new Date(dataAgendamentoFinalAcoes._d + "") >
                                                        new Date(currentDate._d + "")
                                                    ) {
                                                        classes += " middle-date";
                                                    } else if (
                                                        dataAgendamentoFinalAcoes &&
                                                        dataAgendamentoFinalAcoes._d + "" === currentDate._d + ""
                                                    ) {
                                                        classes += " end-date";
                                                    }
                                                    return (
                                                        <td {...props} className={classes}>
                                                            {currentDate.date()}
                                                        </td>
                                                    );
                                                }}
                                                onChange={e => setDataAgendamentoFinalAcoes(e)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>

                </Row>

            </Filters>
        </>
    );

}