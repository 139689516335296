import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../../../contexts/Empresa";
import api from "../../../../../services/api";
import { Acao } from "../../../../../entities/Mentoring";

import { CenterContainer } from './styles';
import Form from "./Form";
import List from "../../../../../components/lists/Acoes";
import {
    Button,
    Row,
    Col,
} from "reactstrap";

type Props = {
    objetivoId?: number,
    notify(type: string, msg: string): void,
    itens: Array<Acao>,
    reload(): Promise<void>
}

const Acoes: React.FC<Props> = ({ objetivoId, notify, itens, reload }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)

    const [loading, setLoading] = useState(false)
    const [showFormAcao, setShowFormAcao] = useState(false)
    const [acaoEmAlteracao, setAcaoEmAlteracao] = useState({} as Acao)

    useEffect(() => {
        console.log(showFormAcao)
    }, [showFormAcao])

    useEffect(() => {
        console.log(acaoEmAlteracao)
    }, [acaoEmAlteracao])


    async function hanldeDelete(acao: Acao) {
        if (!window.confirm('Deseja remover está ação?')) return
        setLoading(true)
        try {
            await api.delete(`/common/empresas/${empresaSelecionada?.id}/objetivos/${objetivoId}/acoes/${acao.id}`)
            notify('success', 'Ação removida')
            await reload()
        } catch (error) {
            console.log(error)
            notify('danger', 'Não foi possível remover ação')
        } finally {
            setLoading(false)
        }
    }

    function handleShowFormAcao() {
        setShowFormAcao(true)
    }

    async function handleHide() {
        await reload()
        setShowFormAcao(false)
        setAcaoEmAlteracao({} as Acao)
    }

    function handleEdit(acao: Acao) {
        setAcaoEmAlteracao(acao)
        setShowFormAcao(true)
    }


    return objetivoId?
        <>
            < Form
                acao={acaoEmAlteracao}
                hide={handleHide}
                onSuccess={reload}
                show={showFormAcao}
                objetivoId={objetivoId}
            />
            <CenterContainer>
                <h3>
                    Ações
                </h3>
                <Button
                    size='sm'
                    color='primary'
                    onClick={handleShowFormAcao}
                >
                    + Novo
                </Button>
            </CenterContainer>
            <List
                loading={loading}
                itens={itens}
                handleDelete={hanldeDelete}
                handleEdit={handleEdit}
            />
        </>:<></>

}

export default Acoes