import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../../../../../services/api";
import EmpresaContext from "../../../../../../contexts/Empresa";

import Form from "./Form";
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
// @ts-ignore
import NotificationAlert from "react-notification-alert";
import { Button, ListGroupItem, ListGroup, Modal, Row, Col, Tooltip, UncontrolledTooltip } from "reactstrap";
import { ButtonContainer } from "../styles";
enum StatusSES {
  Pending = "Pendente",
  Success = "Sucesso",
  Failed = "Falha",
  TemporaryFailure = "Falha Temporária",
  NotStarted = "Não Verificado",
  Empty = " "
}
const statusColors = {
  [StatusSES.Pending]: "primary",
  [StatusSES.Success]: "success",
  [StatusSES.Failed]: "danger",
  [StatusSES.TemporaryFailure]: "warning",
  [StatusSES.NotStarted]: "secondary",
  [StatusSES.Empty]: "secondary"
};


const Emails: React.FC = () => {
  const [emails, setEmails] = useState([]);
  const [alert, setAlert] = useState<any>(null);
  const [showHelp, setShowHelp] = useState(false);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const notificationAlert = useRef<any>();
  const [showEmailForm, setShowEmailForm] = useState(false);

  useEffect(() => {
    loadEmails();
  }, []);


  async function loadEmails() {
    try {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/emails`
      );
      setEmails(response.data);

    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar emails");
    }
  }

  async function removeEmail({ email }: any) {
    try {
      await api.delete(
        `common/empresas/${empresaSelecionada?.id}/emails/${email}`
      );
      loadEmails();
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível remover email");
    }
  }

  function notify(type: any, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  function handleNewEmail(e: React.MouseEvent) {
    e.preventDefault();
    setShowEmailForm(true);
  }


  function ListItem({ email, key }: any) {
    const [loading, setLoading] = useState(false);
    const [isVerificado, setIsVerificado] = useState(false);
    const [status, setStatus] = useState<StatusSES>(StatusSES.Empty)
    useEffect(() => {

      checkEmailValidationStatus()
    }, [])


    async function invalidate() {
      try {
        await api.delete(
          `common/emails/${email.email}`
        );
        checkEmailValidationStatus()
      } catch (error) {
        console.error(error);
        notify("danger", "Não foi possível invalidar email");
      } finally {
        setLoading(false)
      }
    }
    async function revalidate() {
      try {
        await api.get(
          `common/emails/${email.email}/revalidate`
        );
        checkEmailValidationStatus()

      } catch (error) {
        console.error(error);
        notify("danger", "Não foi possível revalidar o email");
      } finally {
        setLoading(false)
      }
    }
    async function checkEmailValidationStatus() {
      try {
        const data = await api.get(
          `common/emails/${email.email}/status`
        );
        console.log({ data });

        // @ts-ignore
        const stts = StatusSES[data.data?.VerificationStatus as keyof typeof StatusSES] || StatusSES.NotStarted
        setStatus(stts)
        setIsVerificado(stts == StatusSES.Success)
      } catch (error) {
        console.error(error);
        notify("danger", "Não foi possível verificar email");
      } finally {
        setLoading(false)
      }
    }

    return (
      <ListGroupItem
        key={key}
        className="checklist-entry flex-column align-items-start py-4 px-4"
      >
        <div
          className={`checklist-item checklist-item-${statusColors[status]}`}
        >
          <div className="checklist-primary">
            <h5 className="checklist-title mb-0">{email.email} </h5>
            <small
              title={
                isVerificado
                  ? "Email verificado"
                  : "Este email ainda não foi verificado. Por favor, acesse sua caixa de entrada."
              }
            >
              {status}
            </small>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >

            <div className="custom-control custom-checkbox custom-checkbox-primary">
              {loading ? (
                <i className="fas fa-circle-notch fa-spin fa-lg primary mr-2"></i>
              ) : (
                <>
                  <div className="btn-group" role="group">
                    <Button
                        id={'Tooltip-1' + email.id}
                        className="btn-sm"
                        color="primary"
                        onClick={(e) => {
                          if (window.confirm("Deseja verificar o Email?")) {
                            setLoading(true);
                            revalidate();
                          }
                        }}
                      >
                        <i className="fas fa-sync pr-2"></i>
                        Verificar
                      </Button>

                      <UncontrolledTooltip
                        placement="left-end"
                        target={'Tooltip-1' + email.id}
                      >
                        Envie e-mail de verificação da Amazon para:  {email.email}
                      </UncontrolledTooltip>

                    </div>
                    <div className="btn-group" role="group">
                      <Button
                        id={'Tooltip-2' + email.id}
                        className="btn-sm"
                        color="warning"
                        onClick={(e) => {
                          if (window.confirm("Deseja invalidar o Email?")) {
                            setLoading(true);
                            invalidate()
                          }
                        }}
                      >
                        <i className="fas fa-ban pr-2"></i> Invalidar
                      </Button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target={'Tooltip-2' + email.id}
                      >
                        Impossibilite que este endereço envie e-mail através do Playnee.
                      </UncontrolledTooltip>
                    </div>
                    <div className="btn-group" role="group">

                      <Button
                        id={'Tooltip-3' + email.id}

                      className="btn-sm"
                      color="danger"
                      onClick={(e) => {
                        if (window.confirm("Deseja remover o Email?")) {
                          setLoading(true);
                          removeEmail(email);
                        }
                      }}
                    >
                        <i className="fas fa-trash pr-2"></i> Excluir
                    </Button>
                      <UncontrolledTooltip
                        placement="right-end"
                        target={'Tooltip-3' + email.id}
                      >
                        Exclua esse e-mail do Playnee
                      </UncontrolledTooltip>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ListGroupItem>
    );
  }

  const ModalHelp = ({ show, onHidden }: any) => (
    <Modal className="modal-dialog-centered" isOpen={show} toggle={onHidden}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Ajuda
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onHidden}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body jusify-content-center">
        Para que você possa utilizar o endereço de email da sua empresa como
        remente dos emails enviados por <strong>mala direta</strong>,
        precisaremos verificar se esse email é realmente seu.
        <br />
        O processo é simples, adicione o email da sua empresa e em alguns
        instantes você irá receber um email dos nossos servidores Amazon
        solicitando a verificação. Basta clicar no link no corpo do email e o
        email estará verificado.
        <br />
        <strong>
          O endereço de email informado será usado apenas como remetente dos
          envios da mala direta
        </strong>
        .
      </div>
      <div className="modal-footer">
        <Button
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onHidden}
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );

  const ButtonHelp = () => (
    <>
      <Button
        color="link"
        id="tooltip-99882211"
        outline
        size="sm"
        onClick={() => setShowHelp(true)}
        type="button"
      >
        ?
      </Button>
    </>
  );

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <ModalHelp show={showHelp} onHidden={() => setShowHelp(false)} />
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "end",
        }}
        className="mb-4"
      >
        <h2>Emails</h2>
        <ButtonHelp />
      </div>
      <div className="pb-5">
        <ButtonContainer>
          <Button caret color="primary" onClick={handleNewEmail}>
            <span className="btn-inner--icon">
              <i className="ni ni-fat-add"></i>
            </span>
            <span className="btn-inner--text">Novo</span>
          </Button>
        </ButtonContainer>
      </div>
      <Form
        show={showEmailForm}
        onHidden={() => setShowEmailForm(false)}
        empresa={empresaSelecionada}
        onInserted={(data: any) => {
          setShowEmailForm(false)
          notify("success", "Email adicionado com sucesso.");
          loadEmails();
          setAlert(
            <ReactBSAlert
              success
              onConfirm={() => setAlert(null)}
              onCancel={() => setAlert(null)}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {`Abra a caixa de entrada do email ${data.email} para fazer a verificação do mesmo.`}
            </ReactBSAlert>
          );
        }}
        notify={notify}
      />
      <ListGroup className="list " flush>
        {emails.map((item, key) => (
          <ListItem email={item} key={key} />
        ))}
      </ListGroup>
    </>
  );
};
export default Emails;
