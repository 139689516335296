import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Badge } from "reactstrap";
import Table from '../../../components/Table'
import { MenuComportamento } from '../../../components/Menus'
import { PlanoEstrategico } from '../../../entities/Mentoring';


// import { Container } from './styles';
interface Props {
  planosEstrategicos: Array<PlanoEstrategico>,
  pageProperties: any,
  onTableChange(type: string, sortProperties: { page: number, sizePerPage: number, sortField: string, sortOrder: string }): Promise<void>,
  notify(type: string, msg: string): void,
  loading: boolean
}

const TablePlanos: React.FC<Props> = ({ planosEstrategicos, pageProperties, onTableChange, notify, loading, ...props }) => {
  const history = useHistory()

  const [columns] = useState([
    {
      dataField: 'acoes',
      formatter: (cell: any, row: any) => acoesFormatter(cell, row)
    },
    {
      dataField: "nome",
      text: 'Nome',
    },
    {
      dataField: 'data_inicio',
      text: 'Data Início',
      sort: true,
      formatter: (cell: any, row: any) => dataFormatter(cell, row)
    },
    {
      dataField: 'data_conclusao',
      text: 'Data Conclusão',
      sort: true,
      formatter: (cell: any, row: any) => dataFormatter(cell, row)
    },
    {
      situacao: "status",
      text: 'Status',
      formatter: (row: any, column: any) => situacaoFormater(row, column),
      align: 'center',
      headerAlign: 'center',
    }
  ])


  function getStatus(row: any) {
    var status = 'Não identificado'

    if (row) {
      if (row.qtd_questoes > 0) {
        if (row.qtd_questoes > row.qtd_questoes_alcançadas) {
          status = 'Não Alcançado'
        } else {
          status = 'Alcançado'
        }
      } else {
        status = 'Não Iniciado'
      }
    }

    return status
  }

  function getColorStatus(row: any) {
    var color = 'secondary'

    if (row) {
      if (row.qtd_questoes > 0) {
        if (row.qtd_questoes > row.qtd_questoes_alcançadas) {
          color = 'warning'
        } else {
          color = 'success'
        }
      } else {
        color = 'danger'
      }
    }

    return color
  }

  const situacaoFormater = (cell: any, row: any ) => (
    <>
      <Badge
        id={`denc-${row.id}`}
        color={getColorStatus(row)}
        className="badge-lg"
        pill
      >
        {getStatus(row)}
      </Badge>
    </>
  )


  function goToEdit(id: number) {
    history.push(`/admin/planos-estrategicos/${id}/edit`)
  }

  const dataFormatter = (cell: any, row: any) => {
    const dt = new Date(cell)
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    {/* @ts-ignore */ }
    const data = dt.toLocaleDateString('pt-br', options);

    return (
      <>
        {data}
      </>
    )
  }

  const acoesFormatter = (cell: any, row: { id: any; }) => {
    return (
      <MenuComportamento
        actions={[{
          label: 'Alterar',
          icon: 'far fa-edit',
          onClick: () => goToEdit(row.id)
        }]}
      />
    )
  }



  return (
    <>
      <Table
        columns={columns}
        data={planosEstrategicos}
        pageProperties={pageProperties}
        onTableChange={onTableChange}
        notify={undefined}
        rowEvents={undefined} />
    </>
  );
}

export default TablePlanos;