import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import Offcanvas from "../components/Offcanvas";
// import { useEditarModeloModal } from "./useEditarModeloModal";
import {
  EmpresaModeloSite,
  UsuarioModeloSite,
} from "../../../../../security/Users/me/components/Paginas";

import ReactBSAlert from "react-bootstrap-sweetalert";
import api from "../../../../../../services/api";

interface EditarModeloModalProps {
  onClose: () => void;
  siteEmpresa: UsuarioModeloSite | EmpresaModeloSite | undefined;
  reload: () => Promise<void>;
}

interface FormDataProps {
  [key: string]: {
    value: string;
    description: string | undefined;
  };
}

interface ParamsMapProps {
  id: number;
  chave: string;
  valor: string;
  descricao: string;
  usuario_modelo_site_id: string;
}

interface EditarModeloModalProps {
  onClose: () => void;
  isOpen: boolean;
  siteEmpresa: UsuarioModeloSite | EmpresaModeloSite | undefined;
}

export function EditarModeloModal({
  isOpen,
  onClose,
  siteEmpresa,
  reload,
}: EditarModeloModalProps) {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formData, setFormData] = useState<FormDataProps>({});
  const [paramsMap, setParamsMap] = useState<ParamsMapProps[]>([]);
  const [alert, setAlert] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [iframeURLQueryParams, setIframeURLQueryParams] = useState("");

  function handleClose() {
    clearParamsPreview();
    setFormData({});
    onClose();
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, dataset } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: {
        value,
        description: dataset.description,
      },
    }));
  };

  function onParamsPreview() {
    let queryParams = "";

    Object.entries(formData).forEach(([name, { value }]) => {
      queryParams += `&${name}=${value}`;
    });

    setIframeURLQueryParams(`${queryParams}`);
  }

  function clearParamsPreview() {
    setIframeURLQueryParams("");
  }

  async function onSubmit() {
    const formDataArray = Object.entries(formData).map(
      ([name, { value, description }]) => ({
        chave: name,
        valor: value,
        descricao: description,
      })
    );

    try {
      setIsLoading(true);

      await api.post("/marketing/modelos-sites/parametros", {
        siteParams: formDataArray,
        empresa_modelo_site_id: siteEmpresa?.id,
      });

      reload();

      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block" }}
          title="Sucesso!"
          onConfirm={() => {
            handleClose();
            setAlert(null);
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Página editada com sucesso!
        </ReactBSAlert>
      );
    } catch (error) {
      console.error("Houve um erro ao tentar fazer a requisição.", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (siteEmpresa?.id) {
      setParamsMap(siteEmpresa.parametroSite);
    }
  }, [siteEmpresa]);

  return (
    <>
      {alert}
      <Modal isOpen={isOpen} toggle={handleClose} style={{ maxWidth: "90%" }}>
        <ModalHeader toggle={handleClose}>Editar Modelo</ModalHeader>
        <ModalBody className="pt-0">
          <div style={{ position: "relative", overflow: "hidden" }}>
            <iframe
              style={{ width: "100%", height: "80vh" }}
              id="myIframe"
              // src={
              //   usuarioSite?.site.tipo_modelo == "vendedor"
              //     ? `http://127.0.0.1:5173/?UMS=${usuarioSite?.id}${iframeURLQueryParams}`
              //     : `http://127.0.0.1:5174/?UMS=${usuarioSite?.id}${iframeURLQueryParams}`
              // }
              src={`${siteEmpresa?.site?.link}?UMS=${siteEmpresa?.id}${iframeURLQueryParams}`}
            ></iframe>
            <Offcanvas
              toggle={() => setIsFormVisible(!isFormVisible)}
              isOpen={isFormVisible}
              onClose={() => setIsFormVisible(!isFormVisible)}
            >
              <Form>
                {paramsMap?.map((input) => (
                  <FormGroup key={input.id}>
                    <Label>{input.descricao}</Label>
                    <Input
                      type="text"
                      id={input.id.toString()}
                      name={input.chave}
                      onChange={handleInputChange}
                      defaultValue={input.valor || ""}
                      data-description={input.descricao}
                    />
                  </FormGroup>
                ))}
                <div className="d-flex justify-content-between">
                  <Button size="sm" type="button" onClick={onParamsPreview}>
                    Visualizar
                  </Button>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={onSubmit}
                    disabled={isLoading}
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            </Offcanvas>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
