import React, { useState, useEffect, useContext } from "react";
import EmpresaContext from "../../../../contexts/Empresa";
import api from "../../../../services/api";

import { bindErrors } from "../../../../utils";

import { SubmitContainer, InputColor } from "./styles";

import ReactBSAlert from "react-bootstrap-sweetalert";
import Pagination from "../../../../components/Pagination";
import Select2 from "react-select2-wrapper";
import {
  Button,
  ListGroupItem,
  Row,
  Col,
  ListGroup,
  Modal,
  Spinner,
  Badge,
  Progress,
  FormGroup,
  InputGroup,
  Input,
  Label,
} from "reactstrap";

function Encaminhar({
  show,
  lastSearch,
  onClose,
  onSuccess,
  notify,
  oportunidades,
}) {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [equipe, setEquipe] = useState(null);
  const [membros, setMembros] = useState([]);
  const [encaminharPara, setEncaminharPara] = useState([]);
  const [pageProperties, setPageProperties] = useState({});
  const [notEncaminhar, setNotEncaminhar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(0);
  const [equipesList, setEquipesList] = useState([]);
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(null);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    setNotEncaminhar([]);
    setEquipe(null);
    loadEquipes();
    setPage(0);
    if (show) setPageProperties(oportunidades);
  }, [show]);

  useEffect(() => {
    if (checkAll) {
      setNotEncaminhar(pageProperties.data.map((oport) => oport.id));
    } else {
      setNotEncaminhar([]);
    }
  }, [checkAll]);

  useEffect(() => {
    loadMembros(equipe);
  }, [equipe]);

  useEffect(() => {
    setEncaminharPara(null);
  }, [membros]);

  async function loadMembros(equipe_id) {
    if (!equipe_id) {
      setMembros([]);
      return;
    }
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/equipes/${equipe_id}/membros`
      );
      setMembros(await response.data);
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível carregar colaboradores da equipe");
    }
  }

  async function loadEquipes() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/equipes`
      );
      setEquipesList(await response.data);
      setEncaminharPara(null);
      setMembros([]);
    } catch (error) {
      console.error(error);
      notify("danger", "Não foi possível carregar equipes");
    }
  }

  function handleMultipleSelect(target, state) {
    const array = Array.from(target.selectedOptions);
    state(array.map((item) => item.value));
  }

  // async function loadOportunidades() {
  //   setLoading(true)
  //   try {
  //     const response = await api.get(`common/empresas/${empresaSelecionada?.id}/oportunidades`, {
  //       params: {
  //         ...lastSearch,
  //         limit: 5000
  //       }
  //     })
  //     setPageProperties(await response.data)
  //   } catch (err) {
  //     console.error(err)
  //     notify('danger', 'Houve um problema ao carregar as Oportunidades.');
  //   }
  //   setLoading(false)
  // }

  function handleEncaminhar() {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title={`Encaminhar ${notEncaminhar.length} oportunidade(s)?`}
        onConfirm={encaminhar}
        onCancel={() => setAlert(null)}
        showCancel
        reverseButtons={true}
        confirmBtnBsStyle="primary"
        confirmBtnText="Sim"
        cancelBtnBsStyle="link"
        cancelBtnText="Não"
        btnSize=""
      ></ReactBSAlert>
    );
  }

  async function encaminhar() {
    setSaving(true);
    setAlert(null);
    try {
      const oportunidades = pageProperties?.data
        ?.filter((oportunidade) => notEncaminhar.includes(oportunidade.id))
        ?.map((oportunidade) => oportunidade.id);
      await api.post(
        `common/empresas/${empresaSelecionada.id}/oportunidades/distribuir-oportunidades`,
        {
          destinatarios: encaminharPara.map(e=>parseInt(e)),
          oportunidades,
        }
      );
      showSuccess("Oportunidades encaminhadas");
    } catch (error) {
      console.error(error);
      if (error.response) {
        const errorMessage = bindErrors(error.response.data);
        if (errorMessage) {
          errorMessage instanceof String
            ? showError(errorMessage)
            : setErros(errorMessage);
        } else {
          showError("Não foi possível encaminhar oportunidade");
        }
      }
    }
    setSaving(false);
  }

  function showSuccess(msg) {
    onSuccess();
    setAlert(
      <ReactBSAlert
        success
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        showConfirm={false}
        timeout={2000}
      />
    );

    setTimeout(() => onClose(), 2000);
  }

  function showError(msg) {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        {msg}
      </ReactBSAlert>
    );
  }

  function handleClose() {
    if (!saving) onClose();
  }

  function handleSelectItem(oportunidadeId) {
    let list = [...notEncaminhar];
    if (notEncaminhar.includes(oportunidadeId)) {
      const index = list.indexOf(oportunidadeId);
      list.splice(index, 1);
    } else {
      list.push(oportunidadeId);
    }
    setNotEncaminhar(list);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={show}
        toggle={handleClose}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Encaminhar Oportunidades
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {alert}
          {loading ? (
            <Progress animated value="100" color="info" />
          ) : (
            <>
              <Row className="mb-4">
                <Col xs="12" lg="6" sm="12" md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example-number-input"
                    >
                      Equipes*
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Select2
                        onSelect={({ target }) => setEquipe(target.value)}
                        options={{
                          placeholder: "Selecione uma equipe...",
                        }}
                        value={equipe}
                        data={equipesList.map((item) => ({
                          id: item.id,
                          text: item.nome,
                        }))}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="12" lg="6" sm="12" md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example-number-input"
                    >
                      Encaminhar para
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Select2
                        options={{
                          placeholder: "Selecione um colaborador...",
                        }}
                        onSelect={({ target }) =>
                          handleMultipleSelect(target, setEncaminharPara)
                        }
                        onUnselect={({ target }) =>
                          handleMultipleSelect(target, setEncaminharPara)
                        }
                        multiple
                        // onSelect={(e) => setEncaminharPara(e.target.value)}
                        value={encaminharPara}
                        data={membros.map((item) => ({
                          id: item.user_id,
                          text: item.user.name,
                        }))}
                      />
                    </InputGroup>
                    <small class="text-danger">
                      {erros.destinatario_id || ""}
                    </small>
                  </FormGroup>
                </Col>
              </Row>

              <SubmitContainer>
                <Button
                  onClick={handleEncaminhar}
                  color="primary"
                  disabled={
                    saving || notEncaminhar.length <= 0 || !encaminharPara
                  }
                >
                  {saving && <Spinner size="sm" className="mr-2" />}
                  Encaminhar
                </Button>
              </SubmitContainer>
              <small class="text-danger">{erros.oportunidades || ""}</small>
              <br />
              <ListGroup data-toggle="checklist" flush>
                <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                  <FormGroup
                    check
                    className="form-check-inline"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <small>
                      {`${notEncaminhar.length} oportunidades selecionadas`}
                    </small>
                    <div
                      className="custom-control custom-checkbox custom-checkbox-primary"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <small check>Todas</small>
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        onClick={() => setCheckAll(!checkAll)}
                      />
                    </div>
                  </FormGroup>
                </ListGroupItem>
                {pageProperties?.data
                  ?.slice(page * 6, (page + 1) * 6)
                  ?.map((oportunidade, key) => (
                    <ListGroupItem
                      key={key}
                      className="checklist-entry flex-column align-items-start py-4 px-4"
                    >
                      <div className="checklist-item checklist-item-primary">
                        <div className="checklist-primary">
                          <h5 className="checklist-title mb-0">
                            {oportunidade.cliente.nome}
                          </h5>
                          <small>{`Colaborador: ${
                            oportunidade?.user?.name || ""
                          }`}</small>
                        </div>
                        <div>
                          {/* <div className="custom-control custom-checkbox custom-checkbox-primary">
                            <InputColor
                              className="custom-control-input"
                              checked={notEncaminhar.includes(oportunidade.id)}
                              type="checkbox"
                            />
                            <label
                              onClick={() => handleSelectItem(oportunidade.id)}
                              className="custom-control-label"
                              check
                            />

                          </div> */}
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              style={{ borderColor: "#525f7f" }}
                              checked={notEncaminhar.includes(oportunidade.id)}
                              onClick={() => handleSelectItem(oportunidade.id)}
                            />
                            {/* <Label 
                             onClick={() => handleSelectItem(oportunidade.id)}
                             className="custom-control-label"
                             check>
                            </Label> */}
                          </FormGroup>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))}
              </ListGroup>
              <small>{`${pageProperties?.data?.length} oportunidades carregadas`}</small>
              {pageProperties.data && (
                <Pagination
                  pageProperties={{
                    ...pageProperties,
                    perPage: 6,
                    page: page + 1,
                    lastPage: Math.ceil(pageProperties.data.length / 6),
                  }}
                  load={(pageProperties) => {
                    setPage(pageProperties?.page - 1);
                  }}
                />
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Encaminhar;
