import React, { useRef, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../contexts/Empresa'
import AuthContext from '../../../../contexts/Auth'

import Select2 from "react-select2-wrapper";
import {
    PopoverBody,
    UncontrolledPopover,
    Button,
    FormGroup,
    InputGroup,
} from "reactstrap";

export default ({
    onFuncionarioChenged,
    funcionarioId,
    erros = {} }) => {

    const { auth } = useContext(AuthContext)
    const { empresaSelecionada } = useContext(EmpresaContext)
    const funcionarioRef = useRef()

    useEffect(() => {
        funcionarioRef.current.props.data.pop()
    }, [])

    function funcionarioOptions() {
        const option = {
            placeholder: "Selecione um colaborador",
        }
        option['ajax'] = {
            delay: 250,
            url: `${process.env.REACT_APP_API_URL}/common/empresas/${empresaSelecionada.id}/colaboradores?roles[]=comercial&roles[]=relacionamento`,
            dataType: 'json',
            headers: {
                Authorization: `Bearer ${auth.token}`
            },
            processResults: function (data) {
                return {
                    results: data.map((item) => ({ id: item.id, text: `${item.name}, ${item.email}` }))
                };
            }
        }
        return option
    }

    return (
        <FormGroup>
            <legend class="w-auto" >
                <label
                    className="form-control-label"
                >
                    Colaborador
                <Button
                        id='id_button_help_colaborador'
                        size='sm'
                        color='secondary'
                        outline
                    >
                        ?
                </Button>
                    <UncontrolledPopover placement="top" target={'id_button_help_colaborador'}>
                        <PopoverBody>
                            Ao adicionar um colaborador, você irá adicionar está oportunidade na lista de oportunidades do mesmo.
                        </PopoverBody>
                    </UncontrolledPopover>
                </label>
            </legend>
            <InputGroup >
                <Select2
                    ref={funcionarioRef}
                    focus={true}
                    className="form-control"
                    onSelect={(e) => {
                        onFuncionarioChenged(e.target.value)
                    }}
                    options={funcionarioOptions()}
                    value={funcionarioId}
                />
            </InputGroup>
            <small class="text-danger">
                {erros.user_id || ""}
            </small>
        </FormGroup>
    )
}