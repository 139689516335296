import React, { useState, useRef, useEffect, useContext } from 'react';
import Axios from 'axios'
import { usePersistedState } from '../../../../hooks'

import AuthContext from "../../../../contexts/Auth";
import EmpresaContext from "../../../../contexts/Empresa";
import { v1 as uuidv1 } from 'uuid';
import api from "../../../../services/api";
import NotificationAlert from "react-notification-alert";
import Table from '../Table'
import Kanban from '../Kanban'
import Filters from './Filters'
import EncaminharOportunidades from '../Encaminhar'
import Export from '../Export'
import Sumario from '../Sumario'

// reactstrap components
import {
    Modal,
    Button,
    UncontrolledTooltip,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Spinner
} from "reactstrap";
// core components

import SimpleHeader from '../../../../components/Headers/SimpleHeader'
let cancelApi = undefined

export default function Oportunidades({ readOnly = false, ...props }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const { hasPermission, hasRole } = useContext(AuthContext)
    const [currentPage, setCurrentPage] = usePersistedState('page', 1)
    const [currentPerPage, setCurrentPerPage] = usePersistedState('perPage', 10)
    const [currentSortField, setCurrentSortField] = usePersistedState('sortFieldOportunidade', 'data_encerramento')
    const [currentSortOrder, setCurrentSortOrder] = usePersistedState('sortOrderOportunidade', 'desc')
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [oportunidades, setOportunidades] = useState([])
    const [oportunidadesKanban, setOportunidadesKanban] = useState([])
    const [pageProperties, setPageProperties] = useState({
        total: "0",
        perPage: currentPerPage,
        page: currentPage,
        lastPage: 1,
        loading: false,
        sort: new Map([])
    })
    const [lastSearch, setLastSearch] = usePersistedState('lastSearchOportunidade', {})
    const [showEncaminhamento, setShowEncaminhamento] = useState(false)
    const [showExport, setShowExport] = useState(false)
    const [showSumario, setShowSumario] = useState(false)
    const notificationAlert = useRef();

    const [visaoMode, setVisaoMode] = usePersistedState('visaoModeOportunidade', 'lista')
    const [changeVisaoModeStartLoad, setChangeVisaoModeStartLoad] = useState(false)

    useEffect(() => {
        async function runLoadCards() {
            if (oportunidadesKanban && oportunidadesKanban.length > 0) {

                for (const lane of oportunidadesKanban) {
                    await loadCardsKanban(1,
                        10,
                        "data_encerramento",
                        "desc",
                        lastSearch,
                        lane)
                }
            }
        }

        runLoadCards()

    }, [oportunidadesKanban])


    useEffect(() => {
        if (!props.search && changeVisaoModeStartLoad) {
            loadOportunidades(currentPage, currentPerPage, currentSortField, currentSortOrder, lastSearch)
        }
        setChangeVisaoModeStartLoad(true);

    }, [visaoMode])

    function notify(type, msg) {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span data-notify="message">
                        {msg}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        if (notificationAlert) notificationAlert.current.notificationAlert(options);
    };

    function onNewClicked(e) {
        e.preventDefault();
        props.history.push('oportunidades/new')
    }

    async function updatePageProperties(response) {
        const { total, perPage, page, lastPage } = await response.data
        await setPageProperties({
            ...pageProperties,
            total,
            perPage,
            page,
            lastPage,
            loading: false,
        })
    }

    async function loadCardsKanban(page = 1,
        limit = 10,
        sortField = "data_encerramento",
        sortOrder = "desc",
        filters = lastSearch,
        lane) {

        if (visaoMode == 'kanban' && lane) {

            setLoading(true)

            try {
                setPageProperties({
                    ...pageProperties,
                    loading: true
                })
                const cancelSource = Axios.CancelToken.source()
                cancelApi = cancelSource
                const url = `common/empresas/${empresaSelecionada?.id}/oportunidadeskanban`
                const response = await api.get(url, {
                    cancelToken: cancelSource.token,
                    params: {
                        page,
                        limit,
                        sortField,
                        sortOrder,
                        ...filters,
                        estagioLane: lane.id
                    }
                })

                const cards = await response.data
                lane.cards = cards

            } catch (err) {
                console.error(err)
                if (err.message === 'Request canceled') return
                notify('danger', 'Houve um problema ao carregar as Oportunidades.');
            }
            setLoading(false)

        } else {
            notify('danger', 'Falha no load da Visão Kanban (Validacao).');
        }
    }

    async function loadMoreCardsKanban(page = 0,
        limit = 10,
        sortField = "data_encerramento",
        sortOrder = "desc",
        filters = lastSearch,
        laneId = 0) {

        if (visaoMode == 'kanban' && page && page > 0 && laneId && laneId > 0) {

            try {
                setPageProperties({
                    ...pageProperties,
                    loading: true
                })
                const cancelSource = Axios.CancelToken.source()
                cancelApi = cancelSource
                const url = `common/empresas/${empresaSelecionada?.id}/oportunidadeskanban`
                const response = await api.get(url, {
                    cancelToken: cancelSource.token,
                    params: {
                        page,
                        limit,
                        sortField,
                        sortOrder,
                        ...filters,
                        estagioLane: laneId
                    }
                })

                const moreCards = await response.data

                return moreCards

            } catch (err) {
                console.error(err)
                if (err.message === 'Request canceled') return
                notify('danger', 'Houve um problema ao carregar as Oportunidades.');
                return []
            }

        } else {
            notify('danger', 'Falha no load da Visão Kanban (Validacao).');
            return []
        }
    }


    async function loadOportunidades(page = 1,
        limit = 22,
        sortField = "data_encerramento",
        sortOrder = "desc",
        filters = lastSearch) {

        setLoading(true)
        setCurrentPage(page)
        setCurrentPerPage(limit)
        setCurrentSortField(sortField)
        setCurrentSortOrder(sortOrder)
        try {
            setPageProperties({
                ...pageProperties,
                loading: true
            })
            const cancelSource = Axios.CancelToken.source()
            cancelApi = cancelSource
            const url = visaoMode == 'kanban' ? `common/empresas/${empresaSelecionada?.id}/estagioskanban` : `common/empresas/${empresaSelecionada?.id}/oportunidades`
            const response = await api.get(url, {
                cancelToken: cancelSource.token,
                params: {
                    page,
                    limit,
                    sortField,
                    sortOrder,
                    ...filters
                }
            })

            if (visaoMode == 'kanban') {
                setOportunidadesKanban(await response.data)
            } else {
                setOportunidades(await response.data)
            }

            await updatePageProperties(response)
        } catch (err) {
            console.error(err)
            if (err.message === 'Request canceled') return
            notify('danger', 'Houve um problema ao carregar as Oportunidades.');
        }
        setLoading(false)
    }

    const handleTableChange = async (type, { page, sizePerPage, sortField, sortOrder }) => {
        try {
            if (type == 'sort') {
                sortField = sortField.replace('__meta__.', '')
                const value = pageProperties.sort.get(sortField)
                const newOrder = (value || 'asc') == 'desc' ? 'asc' : 'desc'
                const map = pageProperties.sort
                map.set(sortField, newOrder)
                sortOrder = newOrder
                setCurrentSortField(sortField)
                setCurrentSortOrder(sortOrder)
            } else if (type == 'pagination') {
                sortField = currentSortField
                sortOrder = currentSortOrder
            }
            if (cancelApi) {
                try {
                    cancelApi.cancel('Request canceled')
                } catch (error) {

                }
            }
            await loadOportunidades(page == 0 ? 1 : page, sizePerPage, sortField, sortOrder)
        } catch (error) {
            notify('danger', 'Houve um problema ao carregar as Oportunidades.');
        }
    }

    const handleNovaMalaDireta = async (e) => {
        e.preventDefault();
        const reference = uuidv1();

        await saveTemporaryRecipients(reference);

        props.history.push('malas-diretas/new/' + reference);
    }


    const saveTemporaryRecipients = async (reference) => {
        try {
            showMalaDiretaProgress()
            await api.post(`common/empresas/${empresaSelecionada?.id}/destinatarios-malas-diretas-temporarios`, {
                ...lastSearch,
                referencia_id: reference
            });
            await new Promise(resolver => setTimeout(resolver, 1000))
            setAlert(null)
        } catch (error) {
            if (error.response && error.response.data.message) {
                notify('danger', error.response.data.message);
            } else {
                notify('danger', 'Houve um problema ao carregar os destinatários da mala direta.');
            }
            setAlert(null)
            throw error
        }
    }

    function showMalaDiretaProgress() {
        setAlert(<Modal
            className="modal-dialog-centered modal-primary"
            contentClassName="bg-gradient-primary"
            isOpen={true}
        >
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">
                    Mala Direta
                </h6>
            </div>
            <div className="modal-body">
                <div className="py-3 text-center">
                    <i className="ni ni-bell-55 ni-3x" />
                    <h4 className="heading mt-4 mb-4">Carregando Destinatários</h4>
                    <p>
                        <Spinner
                            color='secondary'
                        />
                    </p>
                </div>
            </div>
        </Modal>)
    }

    function handleEncaminharOportunidades() {
        setShowEncaminhamento(true)
    }

    function handleExportarOportunidades() {
        setShowExport(true)
    }

    function handleVerSumario() {
        setShowSumario(true)
    }

    function onCloseEncaminhamento() {
        setShowEncaminhamento(false)
    }

    function onEncaminhamentoRealizado() {
        loadOportunidades()
    }

    const AdditionalOptions = ({
        hasPermission,
        handleNovaMalaDireta,
        handleEncaminharOportunidades,
        handleExportarOportunidades,
        handleVerSumario }) => {

        const [menu, setMenu] = useState([])

        useEffect(() => {
            createDropMenus()
        }, [])

        function createDropMenus() {
            const menus = []
            if (hasPermission('gerenciar-mala-direta')) {
                menus.push(<li key='gerenciar-mala-direta'>
                    <DropdownItem href="#pablo" onClick={handleNovaMalaDireta}>
                        <i className="fas fa-suitcase"></i>
                        {'Nova mala direta'}
                    </DropdownItem>
                </li>)
            }
            if (hasPermission('encaminhar-todas-oportunidades')) {
                menus.push(<li key={'encaminhar-todas-oportunidades'}>
                    <DropdownItem href="#pablo" onClick={handleEncaminharOportunidades}>
                        <i className="far fa-share-square"></i>
                        {'Encaminhar oportunidades'}
                    </DropdownItem>
                </li>)
            }
            if (hasRole('gestor_comercial') || hasRole('comercial_restritivo')) {
                menus.push(<li key={'exportar-oportunidades'}>
                    <DropdownItem href="#pablo" onClick={handleExportarOportunidades}>
                        <i className="fas fa-file-export"></i>
                        {'Exportar'}
                    </DropdownItem>
                </li>)
            }
            if (hasRole('administrador_empresa') || hasRole('gestor_comercial') || hasRole('comercial_restritivo')) {
                menus.push(<li key={'ver-sumario'}>
                    <DropdownItem href="#pablo" onClick={handleVerSumario}>
                        <i className="fas fa-eye"></i>
                        {'Ver sumário'}
                    </DropdownItem>
                </li>)
            }

            setMenu(menus)
        }

        return (<div>
            {
                menu.length > 0 &&
                <UncontrolledDropdown group size='sm' direction='down'>
                    <DropdownToggle color="link">
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        {menu}
                    </DropdownMenu>
                </UncontrolledDropdown>
            }

        </div>
        )
    }

    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <EncaminharOportunidades
                show={showEncaminhamento}
                onClose={onCloseEncaminhamento}
                onSuccess={onEncaminhamentoRealizado}
                lastSearch={lastSearch}
                notify={notify}
            />
            <Export
                show={showExport}
                onClose={() => setShowExport(false)}
                search={lastSearch}
                isConversao={true}
            />
            <Sumario
                show={showSumario}
                onClose={() => setShowSumario(false)}
                search={lastSearch}
                isConversao={true}
            />
            <SimpleHeader name="Listagem de conversões" parentName="Conversões" {...props} />
            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 100,
                                }}>
                                <Filters
                                    {...props}
                                    title={<h1>Conversões</h1>}
                                    // data={oportunidades}
                                    load={(filters, clearPagination, clearSort) => {
                                        setLastSearch(filters)
                                        loadOportunidades(
                                            clearPagination ? 1 : currentPage,
                                            clearPagination ? 10 : currentPerPage,
                                            clearSort ? "data_encerramento" : currentSortField,
                                            clearSort ? "desc" : currentSortOrder,
                                            filters)
                                    }}
                                    visaoMode={visaoMode}
                                    setVisaoMode={setVisaoMode}
                                    notify={notify}
                                />
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                            justifyContent: 'flex-end',
                                            padding: 4
                                        }}>
                                            {(() => {
                                                if (empresaSelecionada?.id == 0) {
                                                    const a =
                                                        <>
                                                            <UncontrolledTooltip
                                                                delay={0}
                                                                placement="bottom"
                                                                target="tooltip0983764373524743">
                                                                Primeiro selecione uma empresa...
                                                            </UncontrolledTooltip>
                                                        </>
                                                    return a
                                                }
                                            })()}
                                            <AdditionalOptions
                                                hasPermission={hasPermission}
                                                oportunidades={oportunidades}
                                                handleNovaMalaDireta={handleNovaMalaDireta}
                                                handleEncaminharOportunidades={handleEncaminharOportunidades}
                                                handleExportarOportunidades={handleExportarOportunidades}
                                                handleVerSumario={handleVerSumario}
                                            />

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {visaoMode == 'kanban'
                                            ?
                                            <Kanban
                                                readOnly={readOnly}
                                                lanes={oportunidadesKanban}
                                                onChangeEstagioExterno={loadOportunidades}
                                                handleLoadMoreCards={loadMoreCardsKanban}
                                                lastSearch={lastSearch}
                                                notify={notify}
                                                history={props.history}
                                                pageProperties={{ ...pageProperties, loading }}
                                                loading={loading}
                                            ></Kanban>
                                            :
                                            <Table
                                                readOnly={readOnly}
                                                oportunidades={oportunidades ? oportunidades.data : []}
                                                notify={notify}
                                                onTableChange={handleTableChange}
                                                history={props.history}
                                                pageProperties={{ ...pageProperties, loading }}
                                                loading={loading}
                                                isConversao={true}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}