import React from 'react';
import moment from 'moment';
import {
    Acao
} from "../../../../entities/Mentoring";
import { fixSituacaoAcao, getSituacaoFontConfig} from "../../../../entities/Mentoring/Acao";
import { User } from '../../../../entities/Security';

// import { Container } from './styles';
import Avatares from "../../../timeLines/Pdca/Estrategias/Item/Avatares";
import {
    Badge,
    Button,
    ListGroupItem,
    ListGroup,
    Progress,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

type Props = {
    acao: Acao,
    handleDelete(item: Acao): Promise<void>,
    loading?: boolean,
    handleEdit(acao: Acao): void
}

const Item: React.FC<Props> = ({ acao, handleDelete, loading, handleEdit }) => {

    


    return (
        <ListGroupItem className=" py-4 px-4 ">
            <Row className={`align-items-center `}>
                <div className="col-auto">
                    <Button
                        className="btn-sm"
                        disabled={loading}
                        color="danger"
                        onClick={() => handleDelete(acao)}
                    >
                        <i className="fas fa-trash"></i>
                    </Button>
                </div>
                <Col
                    className="col ml--2"
                    sm='auto'
                    md={3}
                    lg={3}
                    xl={3}
                >
                    <h4
                        className="mb-0"
                        title='O que'
                    >
                        <span
                            className={` mr-2`}
                            id={`tooltip-situacao-${acao.id}`}
                            style={getSituacaoFontConfig(acao.situacao)}
                        >
                            ●
                            </span>
                        <a href="#" onClick={(e) => {
                            e.preventDefault()
                            handleEdit(acao)
                        }}>
                            {acao.o_que}
                        </a>
                        <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            style={{ textTransform: 'capitalize' }}
                            target={`tooltip-situacao-${acao.id}`}
                        >
                            {/* {acao.situacao.replace('_', ' ')} */}
                            {fixSituacaoAcao(acao.situacao)}
                        </UncontrolledTooltip>
                    </h4>
                    <small
                        title='Quando'
                    >
                        {moment(acao.quando).format('LL')}
                    </small>
                    <Avatares
                        users={acao.users || new Array<User>()}
                        center={false}
                    />
                </Col>
                <Col
                    className="col ml--2"
                >
                    <small
                        title='Como'
                    >
                        {acao.como}
                    </small>
                </Col>

            </Row>
        </ListGroupItem>
    )
}

export default Item;