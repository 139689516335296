import React, { useEffect, useState, useContext } from 'react';
import api from '../../../../services/api'
import EmpresaContext from '../../../../contexts/Empresa'
import { usePersistedState } from '../../../../hooks'

import {
    Container,
    Gradient,
    LoadingContainer,
} from './styles';

import CampanhasPie from '../charts/Campanhas'
import ParcialAnuncios from '../charts/ParcialAnuncios'
import FunilVendas from '../charts/FunilVendas'
import OpotunidadesMesPorEstagioFunilAnoAtualVsAnterior from '../charts/OpotunidadesMesPorEstagioFunilAnoAtualVsAnterior'
import ParcialOpotunidadesMes from '../charts/ParcialOpotunidadesMes'
import ProjecaoOportunidades from '../charts/ProjecaoOportunidades'
import ParcialColaboradores from '../charts/ParcialColaboradores'

import {
    Container as BootstrapContainer,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Spinner
} from "reactstrap";

interface IGraficos {
    notify(type: string, msg: string): void,
    mes: string,
    ano: string,
    equipe: any,
    campanha: any,
    colaborador: any,
    setCampanha: any,
    props: any
}

const Graficos: React.FC<IGraficos> = ({ notify, mes, ano, equipe, campanha, colaborador, setCampanha, props }) => {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [loading, setLoaging] = useState(false)

    const [funil, setFunil] = usePersistedState('funilDashboard', null)

    const [cardHeightFunil, setCardHeightFunil] = useState()

    return (
        <>
            <Container
            // className='header d-flex align-items-center'
            >

                <BootstrapContainer
                    fluid
                    className='mt-2'
                >

                    <Row className='mt-4'>
                        <Col lg='4' md='4' sm='12' xs='12'>
                            <CampanhasPie
                                notify={notify}
                                mes={mes}
                                ano={ano}
                                equipe={equipe}
                                campanha={campanha}
                                colaborador={colaborador}
                                setCampanha={setCampanha}
                            />
                        </Col>
                        <Col lg='8' md='8' sm='12' xs='12'>
                            <ParcialAnuncios
                                notify={notify}
                                mes={mes}
                                ano={ano}
                                equipe={equipe}
                                colaborador={colaborador}
                                campanha={campanha}
                                history={props?.history}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <ParcialOpotunidadesMes
                                notify={notify}
                                mes={mes}
                                ano={ano}
                                equipe={equipe}
                                colaborador={colaborador}
                                campanha={campanha}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col lg='6' md='6' sm='12' xs='12'>
                            <FunilVendas
                                notify={notify}
                                mes={mes}
                                ano={ano}
                                equipe={equipe}
                                colaborador={colaborador}
                                campanha={campanha}
                                funil={funil}
                                setFunil={setFunil}
                                cardHeight={cardHeightFunil}
                            />
                        </Col>
                        <Col lg='6' md='6' sm='12' xs='12'>
                            <OpotunidadesMesPorEstagioFunilAnoAtualVsAnterior
                                notify={notify}
                                mes={mes}
                                ano={ano}
                                equipe={equipe}
                                colaborador={colaborador}
                                campanha={campanha}
                                funil={funil}                                
                                setCardHeight={setCardHeightFunil}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <ProjecaoOportunidades
                                notify={notify}
                                mes={mes}
                                ano={ano}
                                equipe={equipe}
                                colaborador={colaborador}
                                campanha={campanha}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col lg='12' md='12' sm='12' xs='12'>
                            <ParcialColaboradores
                                notify={notify}
                                mes={mes}
                                ano={ano}
                                equipe={equipe}
                                colaborador={colaborador}
                                campanha={campanha}
                            />
                        </Col>
                    </Row>

                </BootstrapContainer>
            </Container>
        </>
    )
}

export default Graficos;