import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Spinner, UncontrolledAlert } from "reactstrap";

import AuthContext from "../../../../contexts/Auth";
import EmpresaContext from "../../../../contexts/Empresa";
// import { Oportunidade } from '../../../../entities/Marketing';
import api from "../../../../services/api";
import { exportCSVFile } from "../../../../utils";
import { Link } from "react-router-dom";
import { Oportunidade } from "../../../../entities/Marketing";

interface IExport {
  search: object;
  show: boolean;
  onClose(): void;
  isConversao?: boolean;
}

const Export: React.FC<IExport> = ({ search, show, onClose, isConversao }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { hasRole } = useContext(AuthContext);
  const [alert, setAlert] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [clickedBtn, setClickedBtn] = useState<string | null>(null);

  const formatarComentarios = (comentarios) => {
    if (!comentarios) return null;
  
    return comentarios
      .replace(/<[^>]*>/g, "") // Remove tags HTML
      .replace(/&nbsp;/g, " ") // Substitui &nbsp; por um espaço
      .replace(/&lt;/g, "<") // Substitui &lt; por <
      .replace(/&gt;/g, ">") // Substitui &gt; por >
      .replace(/&amp;/g, "&") // Substitui &amp; por &
      .replace(/"/g, "'") // Substitui aspas duplas por aspas simples
      .replace(/;/g, " - "); // Substitui ponto e vírgula por -
  };

  async function loadOportunidades(
    exportType: string | null = null,
    button: string | null = null
  ) {
    setLoading(true);
    setClickedBtn(button);
    try {
      const response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/oportunidades/exportar`,
        {
          params: {
            ...search,
            limit:
              hasRole("administrador") || hasRole("administrador_empresa")
                ? "all"
                : 200,
            exportType,
          },
        }
      );

      const { data: oportunidades } = response;
      const parsedOportunidades = oportunidades.map((oportunidade: Oportunidade) => ({
        ...oportunidade,
        comentarios: formatarComentarios(oportunidade.comentarios),
      }));

      exportCSVFile(
        null,
        parsedOportunidades,
        `oportunidades${exportType ? "-analítico" : ""}`
      );
    } catch (error) {
      console.error(error);
      showAlert("Não conseguimos carregar as oportunidades");
    } finally {
      setClickedBtn(null);
      setLoading(false);
    }
  }

  function showAlert(texto: string) {
    setAlert(texto);
    setTimeout(() => setAlert(undefined), 5000);
  }

  return (
    <>
      <Modal
        size="sm"
        className="modal-dialog-centered"
        isOpen={show}
        toggle={onClose}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Exportar Oportunidades
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {alert && (
            <UncontrolledAlert className="alert-danger" fade={true}>
              <span className="alert-inner--icon">!</span>{" "}
              <span className="alert-inner--text">{alert}</span>
            </UncontrolledAlert>
          )}
          <>
            <div className="modal-body">
              <div
                style={{
                  textAlign: "center",
                }}
                className="mb-4"
              >
                <br></br>
                <h3>Selecione um modo de exportação</h3>
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  type="button"
                  color="primary"
                  onClick={() => loadOportunidades(null, "S")}
                  disabled={loading && clickedBtn !== "S"}
                >
                  {loading && clickedBtn === "S" ? (
                    <Spinner size="sm" color="secondary" className="mr-2" />
                  ) : (
                    <i className="fas fa-download mr-2"></i>
                  )}
                  Sintético
                </Button>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => loadOportunidades("analitico", "A")}
                  disabled={loading && clickedBtn !== "A"}
                >
                  {loading && clickedBtn === "A" ? (
                    <Spinner size="sm" color="secondary" className="mr-2" />
                  ) : (
                    <i className="fas fa-download mr-2"></i>
                  )}
                  Analítica
                </Button>
              </div>
            </div>
          </>
        </div>
        <div className="modal-footer w-full d-flex justify-content-between">
          <Button
            color="link"
            id="tooltip-99882211"
            outline
            size="sm"
            onClick={() =>
              window.open(
                "https://ajuda.playnee.com/artigos/exportacao-de-oportunidades",
                "_blank"
              )
            }
            type="button"
          >
            Preciso de Ajuda
          </Button>
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Fechar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Export;
