import React from 'react';
import {
    List as ListType
} from "../index";
import {
    Acao
} from "../../../entities/Mentoring";

import Item from "./Item";
import {
    Badge,
    Button,
    ListGroupItem,
    ListGroup,
    Progress,
    Row,
    Col
} from "reactstrap";

type Props = ListType<Acao> & {
    handleDelete(acao: Acao): Promise<void>,
    loading?: boolean,
    handleEdit(acao:Acao):void
}

const List: React.FC<Props> = ({ itens, handleDelete, loading, handleEdit }) => {
    return (
        <ListGroup className="list" flush>
            {
                itens?.map((acao, key) => (
                    <Item
                        acao={acao}
                        key={key}
                        loading={loading}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                    />
                ))
            }
        </ListGroup>
    )
}

export default List;