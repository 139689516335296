import React, { useEffect, useRef, useState } from 'react';
import api from "../../../../services/api";
// @ts-ignore
import NotificationAlert from "react-notification-alert";
import EmpresaContext from "../../../../contexts/Empresa"

import {
    ListType
} from "../List/ListType";


import Table from "./Table";
import {
    Badge,
    Button,
    ListGroupItem,
    ListGroup,
    Progress,
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { Acao } from '../../../../entities/Mentoring';
import SimpleHeader from '../../../../components/Headers/SimpleHeader'
import BootstrapTable from 'react-bootstrap-table-next';
import Filters from './Filters';



type Props = ListType<Acao> & {
    loading?: boolean,
}

const List: React.FC<Props> = ({ itens, loading, ...props }) => {

    const notificationAlert = useRef();
    
    const [paramsAcoes, setParamsAcoes] = useState({})
    const [acoes, setAcoes] = useState<Array<Acao>>([])

    useEffect(() => {
        getAcoes()
    }, [paramsAcoes])

    async function getAcoes() {
        console.log({paramsAcoes});
        try {
            const response = await api.get(`/mentoring/acoes`,
                {
                    params: paramsAcoes
                }
            )
            setAcoes(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const NoDataIndication = () => (
        <div className="spinner">
            <div className="rect1" />
            <div className="rect2" />
            <div className="rect3" />
            <div className="rect4" />
            <div className="rect5" />
        </div>
    );

    return (
        <>
            {alert}
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <SimpleHeader name="Listagem de Ações" parentName="Ações" {...props} />
            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader style={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                            }}>
                                <Filters
                                    title='Ações de PDCAs'                                    
                                    setParamsAcoes={setParamsAcoes}
                                />
                            </CardHeader>
                            <CardBody style={{ paddingTop: 0 }}>
                                <Row>
                                    <Col>
                                        <div className=" table-responsive">
                                            <Table acoes={acoes} />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default List;