import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import NotificationAlert from "react-notification-alert";

import SimpleHeader from "../../../../../components/Headers/SimpleHeader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import api from "../../../../../services/api";
import TipoTarefaModal from "./TipoTarefaModal";

export interface TipoAgendamento {
  id: number;
  nome: string;
  descricao: string;
  situacao: boolean;
  cor: string;
  is_public: boolean;
  padrao: "A" | "I";
  icon: string;
}

const ConfiguracaoAgenda: React.FC = () => {
  const [alert, setAlert] = useState<any>(null);
  const [tarefas, setTarefas] = useState<TipoAgendamento[]>([]);
  const [tarefaSelecionada, setTarefaSelecionada] =
    useState<TipoAgendamento | null>(null);
  const [tabActive, setTabActive] = useState("tarefas");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [situacao, setSituacao] = useState("A");

  const notificationAlert = useRef<any>();

  const openModal = (tarefa: TipoAgendamento | undefined = undefined) => {
    if (tarefa) setTarefaSelecionada(tarefa);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setTarefaSelecionada(null);
    setIsModalVisible(false);
  };

  const [columns] = useState([
    {
      dataField: "acoes",
      formatter: (cell, row) => acoesFormatter(cell, row),
    },
    {
      dataField: "nome",
      text: "Nome",
      sort: true,
    },
    {
      dataField: "situacao",
      text: "Situação",
      sort: true,
      formatter: (cell, row) => situacaoFormatter(cell, row),
    },
    {
      dataField: "favorito",
      text: "Favorito",
      sort: true,
      formatter: (cell, row) => favoritoFormatter(cell, row),
    },
  ]);

  function situacaoFormatter(cell, row) {
    return row.situacao_na_empresa == "I" ? "Inativo" : "Ativo";
  }

  function favoritoFormatter(cell, row) {
    return row.favorito ? "Sim" : "Não";
  }

  function acoesFormatter(cell, row) {
    const isActive = row.situacao_na_empresa == "A";
    const isFavorito = row.favorito;
    const actions = [
      {
        label: isFavorito ? "Remover dos Favoritos" : "Favoritar",
        icon: isFavorito ? "far fa-star" : "fas fa-star",
        onClick: () => handleFavoritar(row),
      },
      {
        label: isActive ? "Inativar" : "Ativar",
        icon: isActive ? "fas fa-times" : "fas fa-plus",
        onClick: () => handleSituacaoTarefa(row),
      },
    ];

    row.padrao == "I" &&
      actions.unshift({
        label: "Editar Tarefa",
        icon: "fas fa-edit",
        onClick: () => openModal(row),
      });

    return (
      <>
        <UncontrolledDropdown direction="right">
          <DropdownToggle
            className="btn-icon-only text-primary"
            href="#"
            role="link"
            size="sm"
            color=""
            onClick={(event) => event.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {actions.map((action, key) => (
              <DropdownItem
                key={key}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  action.onClick();
                }}
              >
                <i className={action.icon}></i>
                {action.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }

  useEffect(() => {
    loadTiposAgendamentos();
  }, [situacao]);

  async function loadTiposAgendamentos() {
    try {
      let url = new URLSearchParams();
      situacao && url.set("situacao", situacao);
      const { data } = await api.get(
        `marketing/tipos-agendamentos-empresas?${url.toString()}`
      );
      const tiposAgendamentosEmpresa = data.map((tae) => ({
        ...tae.tipoAgendamento,
        situacao_na_empresa: tae.situacao,
        favorito: tae.favorito,
      }));
      setTarefas(tiposAgendamentosEmpresa);
    } catch (error) {
      console.log(error);
    }
  }

  function handleSituacaoTarefa(tarefa) {
    const isActive = tarefa.situacao_na_empresa == "A";
    confirmAlert(`Deseja atualizar o tipo de tarefa?`, "danger", () =>
      updateSituacaoTarefa(tarefa.id, isActive ? "I" : "A")
    );
  }

  function handleFavoritar(tarefa) {
    const favorito = tarefa.favorito;
    confirmAlert(
      `Deseja ${
        favorito ? "remover dos favoritos" : "favoritar"
      } o tipo de tarefa?`,
      "danger",
      () => updateFavoritos(tarefa.id, !favorito)
    );
  }

  function confirmAlert(message, alertColor, onConfirm) {
    setAlert(
      <Modal
        className={`modal-dialog-centered modal-${alertColor}`}
        contentClassName={`bg-gradient-${alertColor}`}
        isOpen={true}
        toggle={() => setAlert(null)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Atenção
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setAlert(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="fas fa-question-circle ni-3x" />
            <br />
            <br />
            <p>{message}</p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            type="button"
            onClick={onConfirm}
          >
            Sim
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setAlert(null)}
          >
            Fechar
          </Button>
        </div>
      </Modal>
    );
  }

  async function updateSituacaoTarefa(id: number, situacao: "A" | "I") {
    try {
      await api.patch(`marketing/tipos-agendamentos-empresas/${id}`, {
        situacao: situacao,
      });
      loadTiposAgendamentos();

      notify("success", "Tarefa Atualizada.");
    } catch (error) {
      console.log(error);
      notify("danger", "Não foi possível atualizar a tarefa");
    } finally {
      setAlert(null);
    }
  }

  async function updateFavoritos(id: number, favorito: boolean) {
    try {
      await api.patch(`marketing/tipos-agendamentos-empresas/favoritos/${id}`, {
        favorito: favorito,
        tipo_agendamento_id: id,
      });

      loadTiposAgendamentos();

      notify("success", "Tarefa Atualizada.");
    } catch (e) {
      if (typeof e === "string") {
        e.toUpperCase();
      } else if (e instanceof Error) {
        // @ts-ignore
        notify("danger", e.response.data?.message);
      }
    } finally {
      setAlert(null);
    }
  }

  function notify(type: string, msg: string) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    if (notificationAlert) notificationAlert.current.notificationAlert(options);
  }

  const situacoes = [
    { id: "", text: "Todas" },
    { id: "A", text: "Ativas" },
    { id: "I", text: "Inativas" },
  ];

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>

      <SimpleHeader name="Configurações de Agenda" parentName="Minha Empresa" />

      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="sticky-top">
            <h1>Configurações de Agenda</h1>
          </CardHeader>

          <CardBody outline body>
            <Row>
              <Col xs={12} lg={12} md={12} sm={12}>
                {/* <div className="nav-wrapper">
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        role="tab"
                        className={classnames("mb-sm-3 mb-md-0 mt-2", {
                          active: tabActive === "tarefas",
                        })}
                        onClick={() => setTabActive("tarefas")}
                      >
                        Tipos de Tarefas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        role="tab"
                        className={classnames("mb-sm-3 mb-md-0 mt-2", {
                          active: tabActive === "outros",
                        })}
                        onClick={() => setTabActive("outros")}
                      >
                        Outros
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div> */}
                <div className="mb-4">
                  <h2>Tipos de Tarefas</h2>
                </div>
                {/* <hr className="mt-0" /> */}
                <div>
                  <TabContent activeTab={tabActive}>
                    <TabPane tabId="tarefas">
                      <Row>
                        <Col lg={12} md={12}>
                          <Button
                            color="primary"
                            type="button"
                            className="btn-icon btn-3"
                            size="sm"
                            onClick={() => openModal()}
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-fat-add"></i>
                            </span>
                            <span className="btn-inner--text">Novo</span>
                          </Button>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "flex-end",
                              marginBottom: -10,
                            }}
                          >
                            <ButtonGroup
                              className="btn-group-toggle mt-4 py--4"
                              size="sm"
                              data-toggle="buttons"
                            >
                              {situacoes.map((item, key) => (
                                <Button
                                  key={key}
                                  className={classnames({
                                    active: situacao == item.id,
                                  })}
                                  color="secondary"
                                  onClick={() => setSituacao(item.id)}
                                >
                                  {item.text}
                                </Button>
                              ))}
                            </ButtonGroup>
                          </div>

                          <Row>
                            <Col className="col-12">
                              <ToolkitProvider
                                data={tarefas}
                                keyField="name"
                                //@ts-ignore
                                columns={columns}
                                search
                              >
                                {(props) => (
                                  <div className="py-4">
                                    <BootstrapTable
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="outros">Nada pra ver aqui</TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>

      <TipoTarefaModal
        onClose={closeModal}
        onReload={loadTiposAgendamentos}
        isOpen={isModalVisible}
        tipoTarefaSelecionada={tarefaSelecionada}
      />
    </>
  );
};

export default ConfiguracaoAgenda;
