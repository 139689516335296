import React, { useEffect, useRef, useState } from "react";
// reactstrap components
import { Button, ListGroupItem, Col } from "reactstrap";
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import api from "../../../../../services/api";
import NotificationAlert from "react-notification-alert";
import { Dropzone } from "../../../../../components/Dropzone";

export interface PropMidia {
  name: string;
  url: string;
  path: string;
  id: number;
}

export interface DropzonesProps {
  midias: PropMidia[];
  setMidias(value: any): void;
}

const Dropzones: React.FC<DropzonesProps> = ({ midias, setMidias }) => {
  const [alertS, setAlert] = useState<any>();
  const notificationAlert = useRef<any>(null);

  useEffect(() => {
    if (midias?.length > 0) {
      setMidias(midias);
    }
  }, [midias]);

  const hideAlert = () => {
    setAlert(null);
  };

  const confirmedAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Arquivo excluído!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="primary"
        confirmBtnText="Ok"
        btnSize=""
      >
        O seu arquivo foi removido.
      </ReactBSAlert>
    );
  };

  const handleDeleteMediaFile = async (id: number) => {
    try {
      await api.delete(`common/midias/${id}`);
    } catch (err) {
      console.log(err);
    }
  };

  const confirmAlert = (file: PropMidia) => {
    const callback = () => {
      if (file.id) {
        handleDeleteMediaFile(file.id);
      }

      const newFiles = midias?.filter((item) => item.path != file.path);
      setMidias(newFiles);
      confirmedAlert();
    };
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Você está certo disso?"
        onConfirm={() => callback()}
        onCancel={hideAlert}
        showCancel
        confirmBtnBsStyle="danger"
        confirmBtnText="Sim, pode excluir!"
        cancelBtnBsStyle="secondary"
        cancelBtnText="Cancelar"
        btnSize=""
      >
        Essa ação não pode ser revertida!
      </ReactBSAlert>
    );
  };

  function dzCallback(acceptedFiles: any) {
    const newFile = acceptedFiles.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });
    if (newFile.length === 0) return;
    setMidias((e: any) => {
      console.log(e);
      const arr = e || [];

      return [...arr, ...newFile] as unknown as PropMidia;
    });
  }

  const Thumbs: React.FC<{ data: PropMidia[] }> = ({ data }) => {
    const lista = data?.map((file: PropMidia, key: number) => {
      return (
        <ListGroupItem className="my-2 px-2" style={{ borderWidth: "2px" }}>
          <div className="py-2 d-flex justify-content-between" key={key}>
            <Col
              className="col ml-2"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingLeft: "0",
              }}
            >
              <div className="">
                <h4
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  className=" mb-1"
                  data-dz-name
                >
                  {file.name}
                </h4>
                <span className="text-muted mb-0">
                  <a
                    download="59784"
                    href={file.url}
                    className="small btn-inner--text text-muted mb-0"
                    title="Download"
                  >
                    <span className="btn-inner--icon mr-2">
                      <i className="fas fa-download text-muted"></i>
                    </span>
                  </a>
                </span>
              </div>
            </Col>
            <Col className="col-auto d-flex align-items-center justify-content-center">
              <Button
                size="sm"
                color="danger"
                onClick={() => confirmAlert(file)}
              >
                <i className="fas fa-trash" />
              </Button>
            </Col>
          </div>
        </ListGroupItem>
      );
    });

    return <>{lista}</>;
  };

  return (
    <>
      {alertS}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <div>
        <Dropzone callback={dzCallback} accept={undefined} multiple>
          <label style={{ cursor: "pointer" }}>
            'Solte seus arquivos aqui...'
          </label>
        </Dropzone>
      </div>
      <Thumbs data={midias} />
    </>
  );
};

export default Dropzones;
