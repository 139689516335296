import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from '../../../../../contexts/Empresa'
import api from "../../../../../services/api";
import { usePersistedState } from '../../../../../hooks'

import {
    // global options for the charts
    BarParcial,
} from "../../../../../variables/charts";
import { Bar } from "react-chartjs-2";
import { SpinnerContainer } from '../styles'
import Select2 from "react-select2-wrapper";
import {
    Spinner,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    InputGroup,
    FormGroup,
    PopoverBody,
    UncontrolledPopover,
} from "reactstrap";

function ParcialColaboradores({ notify, mes, ano, equipe, colaborador, campanha }) {

    const { empresaSelecionada } = useContext(EmpresaContext)
    const [itens, setItens] = useState([])
    const [loading, setLoading] = useState(false)
    const [emptyValue, setEmptyValue] = useState(null)

    const [dados, setDados] = useState({})

    const [byEstagio, setByEstagio] = useState(true)
    const [funil, setFunil] = usePersistedState('funilParcialColaborador', null)
    const [funisList, setFunisList] = useState([])

    useEffect(() => {
        loadGrafico()
    }, [mes, ano, equipe, colaborador, campanha, empresaSelecionada])

    useEffect(() => {
        (async () => {
            if (funisList.length === 0) await loadFunis()
        })()
    }, [])

    useEffect(() => {
        if (!byEstagio || byEstagio == 'false') {
            setFunil(null)
        }
        loadGrafico()
    }, [byEstagio])

    useEffect(() => {
        createChartData()
    }, [itens])

    useEffect(() => {
        if (byEstagio) {
            loadGrafico()
        }
    }, [funil])

    async function loadFunis() {
        const response = await api.get(`common/empresas/${empresaSelecionada?.id}/funis`)
        if (response.data) {
            response.data.unshift({ id: -1, nome: '- Todos -' })
            setFunisList(response.data)
        }
    }

    async function loadGrafico() {
        setLoading(true)
        try {
            const response = await api.get(`/dashboards/empresas/${empresaSelecionada.id}/parcial-colaboradores-situacao-estagio`, {
                params: {
                    mes,
                    ano,
                    equipe,
                    colaborador,
                    campanha_id: campanha,
                    byEstagio,
                    funil_id: funil
                }
            })
            setItens(await response.data)
        } catch (error) {
            console.error(error)
            notify('danger', 'Não foi possível carregar gráfico de Parcial Colaboradores')
        }
        setLoading(false)
    }

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function findColor(textColor) {
        if (textColor === 'primary') {
            return '#5E72E4'
        } else if (textColor === 'info') {
            return '#11CDEF'
        } else if (textColor === 'danger') {
            return '#DE2E2E'
        } else if (textColor === 'default') {
            return '#172b4d'
        } else if (textColor === 'secondary') {
            return '#f4f5f7'
        } else if (textColor === 'success') {
            return '#2dce89'
        } else if (textColor === 'warning') {
            return '#fb6340'
        } else {
            return textColor
        }
    }

    function createChartData() {
        const datasets = []
        const colaboradores = itens.map(colaboradores => colaboradores);
        const labels = colaboradores.map(colaborador => colaborador.name)

        if (!byEstagio || byEstagio == 'false') {
            datasets.push({
                label: 'Oportunidades Perdidas',
                backgroundColor: '#f5365c',
                borderColor: '#f5365c',
                data: itens.map(anuncio => parseFloat(anuncio.oportunidades_perdidadas))
            })

            datasets.push({
                label: 'Oportunidades em Negociação',
                backgroundColor: '#5e72e4',
                borderColor: '#5e72e4',
                data: itens.map(anuncio => parseFloat(anuncio.oportunidades_em_negociacao))
            })

            datasets.push({
                label: 'Oportunidades Convertidas',
                backgroundColor: '#2dce89',
                borderColor: '#2dce89',
                data: itens.map(anuncio => parseFloat(anuncio.oportunidades_convertidas))
            })
        } else {

            if (colaboradores && colaboradores.length > 0) {

                const funilBase = colaboradores[0].funil

                for (var index in funilBase) {
                    datasets.push({
                        label: funilBase[index].estagio.nome,
                        backgroundColor: findColor(funilBase[index].cor),
                        borderColor: findColor(funilBase[index].cor),
                        data: colaboradores.map(colaborador => colaborador.funil[index].valor)
                    })
                }
            }
        }

        setDados({
            labels,
            datasets
        })
    }


    return (
        <Card >
            <CardHeader>
                <h5 class="h3 mb-0">Colaboradores</h5>
                <h6 class="surtitle">Parcial dos Colaboradores</h6>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col lg="3" sm="3" md="3">
                        <FormGroup>
                            <label className="form-control-label">
                                Tipo
                                <Button
                                    color="secondary"
                                    id="tooltip8762793492233"
                                    outline
                                    size="sm"
                                    type="button">
                                    ?
                                </Button>
                                <UncontrolledPopover placement="top" target="tooltip8762793492233">
                                    <PopoverBody>
                                        Gráfico por Situação ou Estágios
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </label>
                            <div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" checked={!byEstagio} onChange={({ target }) => setByEstagio(!target.checked)}></input>
                                    <label class="custom-control-label" for="customRadioInline1">Situação</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" checked={byEstagio} onChange={({ target }) => setByEstagio(target.checked)}></input>
                                    <label class="custom-control-label" for="customRadioInline2">Estágios</label>
                                </div>
                            </div>
                        </FormGroup>

                    </Col>
                    {byEstagio &&
                        <Col sm="6" md="6" lg="6">
                            <label className="form-control-label">
                                Funil
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Select2
                                    className="input-group-alternative"
                                    defaultValue="-1"
                                    options={{
                                        placeholder: "Selecione um funil..."
                                    }}
                                    onSelect={({ target }) => setFunil(target.value)}
                                    value={funil}
                                    data={funisList.map((item) => ({ id: item.id, text: item.nome }))}
                                />
                            </InputGroup>
                        </Col>
                    }
                </Row>

                <div className="">
                    {
                        loading ?
                            <SpinnerContainer>
                                <Spinner
                                    color='primary'
                                    size='lg'
                                    style={{
                                        alignSelf: 'center'
                                    }}
                                />
                            </SpinnerContainer>
                            :
                            <div className='chart'>
                                <Bar
                                    data={{ ...dados }}
                                    options={BarParcial.options}
                                    className="chart-canvas"
                                />
                            </div>
                    }
                </div>


            </CardBody>
        </Card>
    )
}

export default ParcialColaboradores;